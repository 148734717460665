import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Callback from '../Callback/Callback';
import SSOCallback from '../Callback/SSOCallBack';

const CallbackLayout = () => {
  return (
    <div className="callback-container">
      <Switch>
        <Route exact path="/callback/google">
          <Callback integration="google" />
        </Route>
        <Route exact path="/callback/jira">
          <Callback integration="jira" />
        </Route>
        <Route exact path="/callback/microsoft">
          <Callback integration="microsoft" />
        </Route>
        <Route exact path="/callback/zoom">
          <Callback integration="zoom" />
        </Route>
        <Route exact path="/callback/salesforce">
          <Callback integration="salesforce" />
        </Route>
        <Route exact path="/callback/hubspot">
          <Callback
            integration="hubspot"
            text="Token generation and data pull in Progress..."
          />
        </Route>
        <Route exact path="/callback/zoho">
          <Callback
            integration="zoho"
            text="Token generation and data pull in Progress..."
          />
        </Route>
        <Route exact path="/callback/zohobugs">
          <Callback integration="zohobugs" />
        </Route>
        <Route exact path="/callback/sso">
          <SSOCallback integration="sso" text="Verifying User..." />
        </Route>
      </Switch>
    </div>
  );
};

export default CallbackLayout;
