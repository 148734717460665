import { useCallback } from 'react';
import { ReactComponent as RedirectSVG } from './../../Icons/blueRedirect.svg';
import { ReactComponent as CollabratorSVG } from '../../Icons/NewImage/collabrator.svg';
import Button from '../../common/Button';
import { checkCollabrator } from '../utils/pipelineUtils';

const DealNameCellRenderer = ({
  handleTitleClick,
  existingUserList,
  dashboard,
  isSalesUser,
  redirect,
  redirecting,
  isProductUser,
  selectedUserName,
  ...props
}) => {
  const cellValue = props?.valueFormatted
    ? props?.valueFormatted
    : props?.value;

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userName = selectedUserName || userDetails?.userName;

  const onTitleClick = useCallback(
    () => handleTitleClick({ ...props?.data, tab: 'table' }),
    [handleTitleClick]
  );

  const redirectBtnClicked = () => {
    redirect(props);
  };

  return (
    <div className="table-deal-name-cell">
      <div className="pipelineTable-collabrator-icon flex-align-center">
        {cellValue &&
          checkCollabrator(
            existingUserList,
            userName,
            props?.data?.presalesUserDetails?.email
          ) && <CollabratorSVG />}
      </div>
      <div className="table-deal-name" onClick={onTitleClick}>
        {cellValue}
      </div>
      {props?.data &&
        dashboard &&
        !isSalesUser &&
        !isProductUser &&
        redirect && (
          <Button
            icon
            className="redirect-btn table-redirectBtn"
            type="text"
            onClick={redirectBtnClicked}
            disabled={redirecting}
          >
            <RedirectSVG />
          </Button>
        )}
    </div>
  );
};

export default DealNameCellRenderer;
