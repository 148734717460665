import React from 'react';

const CustomPinnedRowRenderer = (props) => {
  return (
    <span style={props.style}>
      {props.value !== props.node.id ? props.value : props.data.pinned}
    </span>
  );
};

export default CustomPinnedRowRenderer;
