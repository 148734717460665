import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Input,
  Segmented,
  Select,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import KanbanCard from '../../Pipeline/components/KanbanCard';
import useDebounce from '../../hooks/useDebounce';
import DualRingLoader from '../../common/DualRingLoader';
import PreskaleMultiSelect from '../../AdminSettings/PreskaleMultiSelect';
import {
  useFetchAllDealTypesQuery,
  useFetchCRMStagesQuery,
  useFetchSalesOwnersQuery,
  useGetDealSummaryMutation,
} from '../accountsSlice';
import { DATE_OPERATOR_OPTIONS } from '../../Constant/FilterOperators';
import { getDateByOperator } from '../../Utils/DateUtils';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import NoContent from '../commons/NoContent';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { Text } = Typography;

const SyncDrawerDealList = ({ onCardClick }) => {
  const containerRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedTab, setSelectedTab] = useState('My Deals');
  const [salesOwner, setSalesOwner] = useState(null);
  const [type, setType] = useState(null);
  const [salesStage, setSalesStage] = useState(null);
  const prevPage = useRef(0);
  const [dealInfo, setDealInfo] = useState([]);
  const [expectedCloseDate, setExpectedCloseDate] = useState(null);
  const [expectedCloseDateRange, setExpectedCloseDateRange] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm ?? null, 800);
  const [getAllDealSummary, { data: deals, isLoading: loadingDealSummary }] =
    useGetDealSummaryMutation();
  const { data: dealTypes, isLoading: dealTypeLoading } =
    useFetchAllDealTypesQuery();
  const { data: salesOwners, isLoading: salesOwnerLoading } =
    useFetchSalesOwnersQuery();

  const { data: salesStages, isLoading: salesStageLoading } =
    useFetchCRMStagesQuery();

  const setDefaultFilter = () => {
    const salesOwner =
      JSON.parse(localStorage.getItem('salesOwner')) ??
      salesOwners?.map((data) => data?.value);

    setSalesOwner(salesOwner);

    const salesStage =
      JSON.parse(localStorage.getItem('salesStage')) ??
      salesStages?.stages?.map((data) => data?.value);

    setSalesStage(salesStage);

    const type =
      JSON.parse(localStorage.getItem('type')) ??
      dealTypes?.map((data) => data?.value);
    setType(type);

    const expectedCloseDate =
      JSON.parse(localStorage.getItem('expectedCloseOperator')) ??
      DATE_OPERATOR_OPTIONS[0].value;
    setExpectedCloseDate(expectedCloseDate);

    let range;
    if (expectedCloseDate === 'CUSTOM') {
      const persistedRange = JSON.parse(
        localStorage.getItem('expectedCloseDateRange')
      );
      range = {
        fromDate: persistedRange?.[0],
        toDate: persistedRange?.[1],
      };
      setExpectedCloseDateRange(persistedRange);
    } else if (expectedCloseDate !== 'ALL') {
      const dateRange = getDateByOperator(expectedCloseDate);
      range = {
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
      };
    }
    getAllDealSummary({
      dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
      searchTerm: debouncedSearchTerm,
      salesStage: salesStage,
      salesOwner: salesOwner,
      type: type,
      expectedCloseDate: range,
      page: 0,
    })
      .unwrap()
      .then((data) => {
        setDealInfo(data?.data?.records);
        prevPage.current = 0;
      });
  };

  useEffect(() => {
    setDefaultFilter();
  }, [salesOwners, salesStages, dealTypes]);

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      getAllDealSummary({
        dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
        searchTerm: debouncedSearchTerm,
        salesStage: salesStage,
        salesOwner: salesOwner,
        type: type,
        expectedCloseDate: {
          fromDate: expectedCloseDateRange?.[0],
          toDate: expectedCloseDateRange?.[1],
        },
        page: 0,
      })
        .unwrap()
        .then((data) => {
          setDealInfo(data?.data?.records);
          prevPage.current = 0;
        });
    }
  }, [debouncedSearchTerm]);

  const handleChange = useCallback((event) => {
    setSearchTerm(event.target.value || '');
  }, []);

  const handleSalesStageChange = useCallback(
    (value) => {
      const dateRange = getDateByOperator(expectedCloseDate);
      setSalesStage(value?.map((data) => data?.value));
      localStorage.setItem(
        'salesStage',
        JSON.stringify(value?.map((data) => data?.value))
      );
      getAllDealSummary({
        dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
        searchTerm: debouncedSearchTerm,
        salesStage: value.map((data) => data?.value),
        salesOwner: salesOwner,
        type: type,
        page: prevPage.current,
        expectedCloseDate: {
          fromDate:
            expectedCloseDate === 'CUSTOM'
              ? expectedCloseDateRange?.[0]
              : dateRange?.fromDate,
          toDate:
            expectedCloseDate === 'CUSTOM'
              ? expectedCloseDateRange?.[1]
              : dateRange?.toDate,
        },
      })
        .unwrap()
        .then((data) => {
          setDealInfo(data?.data?.records);
          prevPage.current = 0;
        });
    },
    [
      debouncedSearchTerm,
      salesOwner,
      type,
      expectedCloseDateRange,
      expectedCloseDate,
    ]
  );

  const handleTypeChange = useCallback(
    (value) => {
      const dateRange = getDateByOperator(expectedCloseDate);
      setType(value?.map((data) => data?.value));
      localStorage.setItem(
        'type',
        JSON.stringify(value?.map((data) => data?.value))
      );
      getAllDealSummary({
        dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
        searchTerm: debouncedSearchTerm,
        salesStage: salesStage,
        salesOwner: salesOwner,
        type: value.map((data) => data?.value),
        page: 0,
        expectedCloseDate: {
          fromDate:
            expectedCloseDate === 'CUSTOM'
              ? expectedCloseDateRange?.[0]
              : dateRange?.fromDate,
          toDate:
            expectedCloseDate === 'CUSTOM'
              ? expectedCloseDateRange?.[1]
              : dateRange?.toDate,
        },
      })
        .unwrap()
        .then((data) => {
          setDealInfo(data?.data?.records);
          prevPage.current = 0;
        });
    },
    [
      debouncedSearchTerm,
      salesStage,
      salesOwner,
      expectedCloseDateRange,
      expectedCloseDate,
    ]
  );

  const handleExpectedCloseDateChange = (value) => {
    setExpectedCloseDate(value);
    localStorage.setItem('expectedCloseOperator', JSON.stringify(value));
    if (value !== 'CUSTOM') {
      setExpectedCloseDateRange([null, null]);
      const dateRange = getDateByOperator(value);
      let expectedCloseDate = {
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
      };
      getAllDealSummary({
        dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
        searchTerm: debouncedSearchTerm,
        salesStage: salesStage,
        salesOwner: salesOwner,
        type: type,
        expectedCloseDate: expectedCloseDate,
        page: 0,
      })
        .unwrap()
        .then((data) => {
          setDealInfo(data?.data?.records);
          prevPage.current = 0;
        });
    }
  };

  const handleSalesOwnerChange = useCallback(
    (value) => {
      const dateRange = getDateByOperator(expectedCloseDate);
      setSalesOwner(value?.map((data) => data?.value));
      localStorage.setItem(
        'salesOwner',
        JSON.stringify(value?.map((data) => data?.value))
      );
      getAllDealSummary({
        dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
        searchTerm: debouncedSearchTerm,
        salesStage: salesStage,
        salesOwner: value.map((data) => data?.value),
        type: type,
        expectedCloseDate: {
          fromDate:
            expectedCloseDate === 'CUSTOM'
              ? expectedCloseDateRange?.[0]
              : dateRange?.fromDate,
          toDate:
            expectedCloseDate === 'CUSTOM'
              ? expectedCloseDateRange?.[1]
              : dateRange?.toDate,
        },
        page: 0,
      })
        .unwrap()
        .then((data) => {
          setDealInfo(data?.data?.records);
          prevPage.current = 0;
        });
    },
    [
      debouncedSearchTerm,
      salesStage,
      type,
      expectedCloseDateRange,
      expectedCloseDate,
    ]
  );

  const onCustomFilterChange = (date, dateString) => {
    setExpectedCloseDateRange(dateString);
    localStorage.setItem('expectedCloseDateRange', JSON.stringify(dateString));
    getAllDealSummary({
      dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
      searchTerm: debouncedSearchTerm,
      salesStage: salesStage,
      salesOwner: salesOwner,
      type: type,
      expectedCloseDate: {
        fromDate: dateString[0],
        toDate: dateString[1],
      },
      page: 0,
    })
      .unwrap()
      .then((data) => {
        setDealInfo(data?.data?.records);
        prevPage.current = 0;
      });
  };

  const handleScroll = (e) => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (
      Math.ceil(scrollTop) + Math.ceil(clientHeight) >=
        Math.ceil(scrollHeight) &&
      dealInfo?.length < deals?.data?.totalRecords
    ) {
      getAllDealSummary({
        dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
        searchTerm: debouncedSearchTerm,
        salesStage: salesStage,
        salesOwner: salesOwner,
        type: type,
        page: prevPage.current + 1,
        expectedCloseDate: {
          fromDate: expectedCloseDateRange?.[0],
          toDate: expectedCloseDateRange?.[1],
        },
      })
        .unwrap()
        .then((data) => {
          if (data?.data?.records) {
            if (dealInfo?.length) {
              setDealInfo([...dealInfo, ...data?.data?.records]);
            } else {
              setDealInfo(data?.data?.records);
            }
          }
        });
      prevPage.current = prevPage.current + 1;
    }
  };

  const resetToDefault = () => {
    localStorage.removeItem('salesStage');
    localStorage.removeItem('type');
    localStorage.removeItem('expectedCloseOperator');
    localStorage.removeItem('salesOwner');
    localStorage.removeItem('expectedCloseDateRange');
    setSalesOwner(salesOwners?.map((data) => data?.value));
    setSalesStage(salesStages?.stages?.map((data) => data?.value));
    setType(dealTypes.map((data) => data?.value));
    setExpectedCloseDate(DATE_OPERATOR_OPTIONS[0].value);
    setExpectedCloseDateRange([null, null]);
    setSearchTerm('');
    getAllDealSummary({
      dealStatus: selectedTab === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
      searchTerm: debouncedSearchTerm,
      salesStage: salesStages?.stages?.map((data) => data?.value),
      salesOwner: salesOwners?.map((data) => data?.value),
      type: dealTypes.map((data) => data?.value),
      expectedCloseDate: {},
      page: 0,
    })
      .unwrap()
      .then((data) => {
        setDealInfo(data?.data?.records);
        prevPage.current = 0;
      });
  };

  const dateStringToDates = (dateStrings) => {
    if (!dateStrings || dateStrings.length !== 2) {
      return [null, null];
    }
    return [
      dateStrings[0] ? dayjs(new Date(dateStrings[0])) : null,
      dateStrings[1] ? dayjs(new Date(dateStrings[1])) : null,
    ];
  };

  return (
    <>
      <Flex className="width-full height-full">
        <Flex vertical className="width-50 p-1em">
          <Segmented
            options={['My Deals', 'Unassigned Deals']}
            value={selectedTab}
            onChange={(value) => {
              setSelectedTab(value);
              getAllDealSummary({
                dealStatus: value === 'My Deals' ? 'ASSIGNED' : 'UNASSIGNED',
                searchTerm: debouncedSearchTerm,
                salesStage: salesStage,
                salesOwner: salesOwner,
                type: type,
                expectedCloseDate: {
                  fromDate: expectedCloseDateRange?.[0],
                  toDate: expectedCloseDateRange?.[1],
                },
                page: 0,
              })
                .unwrap()
                .then((data) => {
                  setDealInfo(data?.data?.records);
                  containerRef.current.scrollTo(0, 0);
                });
              prevPage.current = 0;
            }}
          />
          {loadingDealSummary && prevPage.current === 0 ? (
            <div className="loader-container--suspense">
              <DualRingLoader dark />
            </div>
          ) : (
            <div
              className="app-kanban-view sync-drawer-sidebar mt-20"
              onScroll={handleScroll}
              ref={containerRef}
            >
              {dealInfo && dealInfo?.length ? (
                dealInfo?.map((deal, index) => (
                  <React.Fragment key={index}>
                    <KanbanCard
                      name={deal.dealName}
                      accountInfo={[{ name: deal?.accountName }]}
                      presalesUserDetails={{
                        displayName: deal?.presalesUser?.displayName,
                      }}
                      handleCardClick={() => {
                        onCardClick(deal);
                      }}
                      isCRMStageView="preskale"
                      crmStage={deal?.status}
                      {...deal}
                    />
                  </React.Fragment>
                ))
              ) : (
                <NoContent
                  message="No Opportunities Found"
                  className="no-content"
                />
              )}
            </div>
          )}
        </Flex>
        <Divider type="vertical" className="height-full" />
        <Flex vertical className="width-50 p-1em sync-drawer-sidebar" gap={26}>
          {dealTypeLoading || salesStageLoading || salesOwnerLoading ? (
            <div className="loader-container--suspense">
              <DualRingLoader dark />
            </div>
          ) : (
            <>
              <Flex vertical gap={6}>
                <Text strong>Search Opportunity</Text>
                <Input
                  prefix={<SearchSVG />}
                  className="mr-30"
                  value={searchTerm}
                  onChange={handleChange}
                  placeholder="Search Opportunity"
                />
              </Flex>
              <Flex vertical gap={6}>
                <Text strong>Sales Owner</Text>
                <PreskaleMultiSelect
                  placeholder="Select an owner"
                  value={salesOwner}
                  options={salesOwners ?? []}
                  onChange={handleSalesOwnerChange}
                  maxTagCount={1}
                />
              </Flex>
              <Flex vertical gap={6}>
                <Text strong>Sales Stage</Text>
                <PreskaleMultiSelect
                  placeholder="Select a stage"
                  options={salesStages?.stages ?? []}
                  value={salesStage}
                  onChange={handleSalesStageChange}
                  maxTagCount={1}
                />
              </Flex>
              <Flex vertical gap={6}>
                <Text strong>Type</Text>
                <PreskaleMultiSelect
                  placeholder="Select a type"
                  options={dealTypes ?? []}
                  value={type}
                  onChange={handleTypeChange}
                  maxTagCount={1}
                />
              </Flex>
              <Flex vertical gap={6}>
                <Text strong>Expected Close Date</Text>
                <Select
                  placeholder="Select a type"
                  options={DATE_OPERATOR_OPTIONS}
                  onChange={handleExpectedCloseDateChange}
                  value={expectedCloseDate}
                />
              </Flex>
              {expectedCloseDate === 'CUSTOM' && (
                <Flex align="center" gap={10}>
                  <DatePicker.RangePicker
                    onChange={onCustomFilterChange}
                    value={dateStringToDates(expectedCloseDateRange)}
                  />
                </Flex>
              )}
              <Button
                type="link"
                className="mr-10 ml-auto"
                onClick={resetToDefault}
              >
                <Text underline className="text-primary">
                  Reset to Default
                </Text>
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default SyncDrawerDealList;
