export const STATUS_OPTIONS = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'IN_ACTIVE', label: 'Inactive' },
];

export const REPORT_OPTIONS = [
  {
    label: 'Deal Velocity Reports',
    key: 'dealVelocityReports',
    value: 'is_deal_velocity_reports_enabled',
  },
  {
    label: 'Solution Analytics',
    key: 'solutionAnalytics',
    value: 'is_solution_analytics_enabled',
  },
  {
    label: 'Productgap Analytics',
    key: 'productGapAnalytics',
    value: 'is_productgap_analytics_enabled',
  },
  {
    label: 'Calendar Analytics',
    key: 'calendarAnalytics',
    value: 'is_calendar_analytics_enabled',
  },
];

export const NAV_ITEMS = [
  {
    title: 'User Preference',
    subSections: [
      {
        label: 'Common Preference',
        id: 'common_preference',
        reference: 'commonPreference',
      },
      {
        label: 'Task Notification',
        id: 'task_notification',
        reference: 'taskNotification',
      },
      {
        label: 'Weekly Meeting Notification',
        id: 'weekly_meeting_notification',
        reference: 'weeklyMeeting',
      },
      {
        label: 'Monthly Meeting Notification',
        id: 'monthly_meeting_notification',
        reference: 'monthlyMeeting',
      },
      {
        label: 'Deal Breaker Notification',
        id: 'deal_breaker_notification',
        reference: 'dealBreaker',
      },
    ],
  },
  {
    title: 'User Permissions',
    subSections: [
      {
        label: 'Reports',
        id: 'reports',
        reference: 'reports',
      },
    ],
  },
];
