import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { formatCount } from '../../Utils/Revenue';
import DualRingLoader from '../../common/DualRingLoader';
import customNoRowsOverlay from '../utils/customNoRowsOverlay';
import {
  useLazyCheckPipelineBatchQuery,
  useUpdateAllRecordsInPipelineMutation,
  useUpdatePipelineTableMutation,
} from '../../Accounts/accountsSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DOMAIN_URL } from '../../_helpers/axios';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import NumericCellEditor from '../utils/NumericCellEditor.js';
import { generateUUID, getFilteredCustomFieldData } from '../../Utils/common';
import {
  isSalesUser as findIsSalesUser,
  isProductUser as findIsProductUser,
} from '../../Utils/common';
import { useFetchAllSectionsDataQuery } from '../../features/CustomSections/CustomSectionSlice';
import { toast } from 'react-toastify';
import { CustomHeaderComponent } from './CustomHeaderComponent';
import { CRM_FIELDS, DEFAULT_CRM_STAGES } from '../../Constant/FilterOperators';
import DealNameCellRenderer from './DealNameCellRenderer';
import { useGetAllCompanyUsersQuery } from '../../app/api/usersSlice';
import { CustomMultiSelectCellEditor } from '../utils/CustomMultiSelectCellEditor';
import CustomPinnedRowRenderer from '../../features/calendar/PinnedRowRenderer';
import { BulkUpdatePopup } from '../../features/calendar/BulkUpdatePopup';
import Button from '../../common/Button';
import { AgGridCustomSelect } from '../../features/calendar/AgGridCustomSelect';
import AgGridDatePicker from '../utils/AgGridDatePicker.js';
import AgGridOverlay from '../../common/AgGridOverlay.js';
import SelectAllNotification from './SelectAllNotification.js';
import {
  fetchTableApi,
  selectRefetchTableApi,
  selectedColumn,
} from '../pipelineSlice.js';
import { extendedCustomSectionSlice } from '../../features/CustomSections/CustomSectionSlice';
import htmlRenderer from './htmlRenderer.js';
import AgGridQuillEditor from './AgGridQuillEditor.js';
import { formatDate, getFormattedDate } from '../../Utils/DateUtils.js';

const SORT_DIRECTIONS = {
  asc: 'ASC',
  desc: 'DESC',
};

const COLUMN_HEADERS = {
  name: 'deal_name',
  crmStage: 'status',
  dealValue: 'deal_value',
  'dealOwner.displayName': 'owner.display_name',
  expectedCloseDate: 'expected_close',
  'presalesUserDetails.displayName': 'presales_user.display_name',
  presalesUpdatedDate: 'presalesUpdatedDate',
};

const PipelineTable = ({
  filter,
  userId,
  selectedUserName,
  stages,
  handleTitleClick,
  filteredCrmFields,
  filteredCustomFields,
  isCRMStageView,
  refreshForCreateOpportunity,
  setRefreshForCreateOpportunity,
  teamFilter,
  crmStages,
}) => {
  const gridRef = useRef(null);
  const processingToastId = 'processing-table';
  const mandatoryFieldToastId = 'field-mandatory';
  const dispatch = useDispatch();
  const idToken = localStorage.getItem('idToken');
  const isSalesUser = findIsSalesUser();
  const isProductUser = findIsProductUser();
  const selectedView = useSelector(selectedColumn);
  const refetchTableApi = useSelector(selectRefetchTableApi);
  const gridStyle = useMemo(() => ({ height: 'inherit', width: '100%' }), []);
  const axios = useAxiosPrivate();

  const [sortField, setSortField] = useState('last_updated_on');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [fields, setFields] = useState([]);
  const [customSectionOptions, setCustomSectionOptions] = useState({});
  const [type, setType] = useState([]);
  const [mandatoryFieldsData, setMandatoryFieldsData] = useState([]);
  const [filteredList, setFilteredList] = useState(null);
  const [filteredCustomList, setFilteredCustomList] = useState(null);
  const [writeBackFields, setWriteBackFields] = useState([]);

  const updatedRows = useRef({});
  const rowsToUpdateAllRecords = useRef({});
  const totalRows = useRef(0);
  const [totalSelected, setTotalSelected] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [openSelectAllNotification, setOpenSelectAllNotification] =
    useState(false);
  const disableClosingSelectAllNotification = useRef(false);
  const [processId, setProcessId] = useState(null);
  // const [tableLoadError, setTableLoadError] = useState(false);
  const [save, setSave] = useState(false);

  // * Is needed to track state without it being affected by closures within ag-grid
  // ! Everytime any of the state listed changes this has to be updated too
  // TODO: Calling the methods using useEffect will fix the above issue without the need of this data
  const currentTableState = useRef({
    selectAll: false,
    totalSelected: false,
    openSelectAllNotification: false,
    customSectionOptions: {},
    type: [],
  });

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const { data: existingUserList } = useGetAllCompanyUsersQuery();
  const { data: allSections } = useFetchAllSectionsDataQuery();

  const [updatePipelineTable, { isLoading: isApplying }] =
    useUpdatePipelineTableMutation();
  const [updateAllRecords, { isLoading: isApplyingToAllRecords }] =
    useUpdateAllRecordsInPipelineMutation();

  const loadingOverlayComponent = useMemo(() => {
    return AgGridOverlay;
  }, []);

  const processingErrorMsg =
    'Something went wrong during processing. Please refresh after sometime to check updates or contact support';

  useEffect(() => {
    // * If the user navigates away from the page and comes back, check if there is an ongoing bulk save
    const processIdFromLS = localStorage.getItem('processId');
    if (!processIdFromLS) {
      return;
    }
    setProcessId(processIdFromLS);
    checkBatchProcess({ processId: processIdFromLS })
      .unwrap()
      .catch(() => {
        setProcessId(null);
        localStorage.removeItem('processId');
        toast.error(processingErrorMsg);
        toast.dismiss(processingToastId);
      });
  }, []);

  useEffect(() => {
    // * Remove toast on redirection
    return () => {
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    const fieldsList = [...filteredCrmFields];
    const filteredCrmFieldlist = fieldsList?.map((val) => val.value);
    const crmFields = CRM_FIELDS.map((data) => data.value);
    if (filteredCrmFields[0]?.value === 'ALL') {
      setFilteredList([...crmFields]);
    } else {
      setFilteredList([...filteredCrmFieldlist]);
    }
  }, [filteredCrmFields]);

  useEffect(() => {
    if (isApplying || isApplyingToAllRecords || processId || isProcessing) {
      // * Resetting state since the table will refresh after the above actions(conditions) are done
      setRefreshForCreateOpportunity(null);
      return;
    }
    refreshData();
  }, [refreshForCreateOpportunity]);

  useEffect(() => {
    const fieldsList = [...filteredCustomFields];
    const filteredCustomFieldList = fieldsList?.map((data) => data.label);
    if (filteredCustomFields[0]?.value === 'ALL') {
      setFilteredCustomList([...fields]);
    } else {
      setFilteredCustomList([...filteredCustomFieldList]);
    }
  }, [filteredCustomFields, fields]);

  useEffect(() => {
    const {
      filteredFields,
      customSectionlabelValue,
      typeMapData,
      mandatoryFields,
      crmField,
    } = getFilteredCustomFieldData(allSections);
    setCustomSectionOptions(customSectionlabelValue);
    setWriteBackFields(crmField);
    //Format => columnName:type(select,radio,text,textArea,number)
    setType(typeMapData);
    setMandatoryFieldsData(mandatoryFields);

    //CustomSection column names
    setFields(filteredFields);
  }, [allSections]);

  useEffect(() => {
    if (isApplying || isApplyingToAllRecords || processId || isProcessing) {
      toast.warn('Please wait till Bulk Update is complete');
      return;
    }
    refreshData();
    gridRef?.current?.api?.hideOverlay();
    // * Reset any unsaved bulk selection
    hideButtons();
  }, [filter, userId, sortField, sortOrder, isCRMStageView, crmStages]);

  // Refetch Table api when any notes action is performed
  // and update refetchTableApi flag to false.
  useEffect(() => {
    if (refetchTableApi === true) {
      refreshData();
      dispatch(fetchTableApi(false));
    }
  }, [refetchTableApi]);

  const defaultColDef = useMemo(() => {
    return {
      flex: '1',
      resizable: true,
      sortable: true,
      comparator: () => 0,
      cellStyle: () => ({
        borderRight: '1px solid #d8e4fd',
      }),
    };
  }, []);

  const datasource = {
    rowCount: undefined,
    getRows(params) {
      let { startRow } = params;
      const savedPipelineFilter = JSON.parse(
        localStorage.getItem('pipeline_filter')
      );
      let pipelineFilter = filter;
      const filteredCrmStages = savedPipelineFilter?.stage;
      if (!filteredCrmStages) {
        pipelineFilter.stage =
          crmStages && crmStages.map((stage) => stage?.value);
      }

      const bodyJson = {
        page: startRow / 20,
        size: 20,
        filter: pipelineFilter,
        userId: userId || undefined,
        direction: sortOrder,
        field: sortField,
        crmStageView: isCRMStageView === 'crm',
        teamFilter,
        pipelineId: JSON.parse(localStorage.getItem('pipeline'))?.value ?? null,
      };
      const body = JSON.stringify(bodyJson);
      let url = `stage/getRecentDeals`;
      const config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'X-TENANT-ID': JSON.parse(localStorage.getItem('user')).companyId,
        },
      };
      axios
        .post(`${DOMAIN_URL}${url}`, body, config)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          const data = res?.face1;
          if (bodyJson.page === 0) {
            // * Total records will be available only on the first call
            totalRows.current = res.totalRecords;
          }
          if (data) {
            let responseData = data.map((res) => {
              let resCopy = { ...res };
              resCopy?.customFieldValue?.map((response) => {
                resCopy[response.label] = response.input;
              });
              return resCopy;
            });
            if (!totalRows.current) {
              gridRef?.current?.api?.showNoRowsOverlay();
            }
            if (totalRows.current) {
              // if (tableLoadError) {
              //   setTableLoadError(false);
              // }
              gridRef.current?.api?.hideOverlay();
            }
            params.successCallback(responseData, totalRows.current);
          }
        })
        .catch(() => {
          params.failCallback();
          // setTableLoadError(true);
          // gridRef.current?.api?.showLoadingOverlay();
          toast.error('Something went wrong when fetching data');
        });
    },
  };

  const [checkBatchProcess, { data: processData, isFetching: isProcessing }] =
    useLazyCheckPipelineBatchQuery({
      pollingInterval: processId ? 5000 : 0,
    });

  const onGridReady = (params) => {
    params?.api?.setDatasource(datasource);
  };
  const refreshData = () => {
    gridRef?.current?.api?.setDatasource(datasource);
  };

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      // message: tableLoadError ? 'Something went wrong when fetching data' : '',
      loadingMessage:
        processId || isProcessing
          ? `Processing updates... ${
              !processData?.data?.numberOfRecords
                ? 0
                : Math.round(
                    ((typeof processData?.data?.numberOfItemsProcessed ===
                    'number'
                      ? processData?.data?.numberOfItemsProcessed
                      : 0) /
                      processData?.data?.numberOfRecords) *
                      100
                  )
            }%`
          : isApplying || isApplyingToAllRecords
          ? 'Updating...'
          : '',
    };
  }, [isApplying, isApplyingToAllRecords, processId, isProcessing]);

  useEffect(() => {
    // * Stop polling on complete or on error and update table
    if (
      processData?.data?.status === 'COMPLETED' ||
      processData?.data?.status === 'ERROR'
    ) {
      setProcessId(null);
      localStorage.removeItem('processId');
      if (processData?.data?.status === 'ERROR') {
        toast.error('Update incomplete. Please contact support.', {
          isLoading: false,
          closeButton: true,
          closeOnClick: false,
          autoClose: false,
          toastId: processingToastId,
        });
      }
      if (processData?.data?.status === 'COMPLETED') {
        toast.success('Update completed', {
          isLoading: false,
          closeButton: true,
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          toastId: processingToastId,
        });
      }
      // Refresh custom section field options
      dispatch(
        extendedCustomSectionSlice.util.invalidateTags([
          { type: 'CustomSection', id: 'allSections' },
        ])
      );
      // * Reload table
      refreshData();
    }
  }, [processData?.data]);

  const noRowsOverlayComponent = useMemo(() => {
    return customNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'No Data',
    };
  }, []);

  const displayEditButtons = (event, node, updateTotalRecords) => {
    if (!updateTotalRecords) {
      updatedRows.current[node?.data?.id ?? event.data.id] = node ?? event.node;
    }
    gridRef.current?.api?.setPinnedTopRowData(showButtons());
  };

  const onSortChange = (params) => {
    const sortModel = params?.api?.sortController?.getSortModel();

    let field;
    let direction;
    if (sortModel[0]) {
      field = COLUMN_HEADERS[sortModel[0].colId];
      direction = SORT_DIRECTIONS[sortModel[0].sort];
    }

    if (sortModel.length > 0) {
      setSortField(field);
      setSortOrder(direction);
    } else {
      setSortField('last_updated_on');
      setSortOrder('DESC');
    }
  };

  const memoizedCellRenderer = (props) => {
    return (
      <>
        <DealNameCellRenderer
          handleTitleClick={handleTitleClick}
          dashboard={configFeatures?.data?.navPages?.dashboard}
          existingUserList={existingUserList}
          isSalesUser={isSalesUser}
          isProductUser={isProductUser}
          selectedUserName={selectedUserName}
          {...props}
        />
      </>
    );
  };
  const formatCountMemoized = useCallback((value) => {
    if (value) {
      return formatCount(value);
    }
  }, []);

  const IndexCellRenderer = useCallback((props) => {
    if (props.value !== undefined) {
      return parseInt(props?.value) + 1;
    } else if (props?.value === undefined) {
      return <DualRingLoader dark />;
    }
  }, []);

  const storeTotalRecUpdatedData = (field, node) => {
    if (field === 'currentPreskaleStageName') {
      rowsToUpdateAllRecords.current[field] = node.data.presalesStage;
    } else {
      rowsToUpdateAllRecords.current[field] = node.data[field];
    }
  };

  const startBatchProcess = (response) => {
    setSave(false);
    if (response?.message === 'Batch Process Started') {
      toast.info(response?.message, {
        hideProgressBar: true,
        closeButton: false,
        autoClose: 1000,
      });
    } else {
      toast.info(response?.message);
    }
    hideButtons();
    const processId = response?.data?.id;
    if (processId) {
      setProcessId(processId);
      localStorage.setItem('processId', processId);
      checkBatchProcess({ processId })
        .unwrap()
        .catch(() => {
          setProcessId(null);
          localStorage.removeItem('processId');
          toast.error(processingErrorMsg);
          toast.dismiss(processingToastId);
        });
    }

    if (
      response?.data?.status === 'COMPLETED' ||
      response?.data?.status === 'ERROR' ||
      response?.message === 'Deal Updated'
    ) {
      // Refresh custom section field options
      dispatch(
        extendedCustomSectionSlice.util.invalidateTags([
          { type: 'CustomSection', id: 'allSections' },
        ])
      );
      // * Reload table
      refreshData();
    }
  };

  const applyUpdates = () => {
    if (currentTableState?.current?.totalSelected) {
      const body = {
        filter,
        teamFilter,
        userId: userId || undefined,
        pipelineId: JSON.parse(localStorage.getItem('pipeline'))?.value ?? null,
      };
      const fieldValues = {};
      for (let field in rowsToUpdateAllRecords.current) {
        if (field === 'currentPreskaleStageName') {
          body.presalesStage = rowsToUpdateAllRecords.current[field];
        }
        if (field === 'crmStage') {
          body[field] = rowsToUpdateAllRecords.current[field];
        }
        if (field !== 'currentPreskaleStageName' && field !== 'crmStage') {
          if (Array.isArray(rowsToUpdateAllRecords.current[field])) {
            fieldValues[field] = rowsToUpdateAllRecords.current[field].map(
              (value) => ({
                label: value,
                value,
                key: value,
              })
            );
          } else {
            fieldValues[field] = rowsToUpdateAllRecords.current[field];
          }
          body.fieldValues = fieldValues;
        }
      }
      // console.log('total record update', body);
      // return;
      updateAllRecords(body)
        .unwrap()
        .then(startBatchProcess)
        .catch(() => {
          toast.error(`Some updates failed!`);
        });
      return;
    }
    const rows = updatedRows.current;
    const body = [];
    for (let key in rows) {
      let updated = {};
      if (
        rows[key].data.prevPreskaleStageName &&
        rows[key].data.prevPreskaleStageName !==
          rows[key].data.currentPreskaleStageName
      ) {
        updated.presalesStage = rows[key].data.presalesStage;
      }
      if (
        rows[key].data.prevCrmStage &&
        rows[key].data.prevCrmStage !== rows[key].data.crmStage
      ) {
        updated.crmStage = rows[key].data.crmStage;
      }
      if (rows[key].data.prevFieldValues) {
        let fieldValues = {};
        for (let csKey in rows[key].data.prevFieldValues) {
          if (
            JSON.stringify(rows[key].data.prevFieldValues[csKey]) !==
            JSON.stringify(rows[key].data[csKey])
          ) {
            if (
              Array.isArray(rows[key].data[csKey]) &&
              rows[key].data[csKey].length > 0
            ) {
              fieldValues[csKey] = rows[key].data[csKey].map((value) => ({
                label: value,
                value,
                key: value,
              }));
            } else {
              fieldValues[csKey] = rows[key].data[csKey];
            }
          }
        }
        if (Object.keys(fieldValues).length > 0) {
          updated.fieldValues = fieldValues;
        }
      }
      if (Object.keys(updated).length > 0) {
        updated.accountId = rows[key].data.accountId;
        updated.dealId = rows[key].data.dealId;
        body.push(updated);
      }
    }
    if (!body.length && !totalSelected) {
      toast.info('No changes to update!');
      return;
    }
    // console.log('single/bulk', body);
    // return;
    updatePipelineTable(body)
      .unwrap()
      .then(startBatchProcess)
      .catch(() => {
        toast.error('Update failed!');
      });
  };

  useEffect(() => {
    // * Disable table and show status
    if (isApplying || isApplyingToAllRecords || processId || isProcessing) {
      gridRef.current?.api?.showLoadingOverlay();
    }
    if (!isApplying && !isApplyingToAllRecords && !processId && !isProcessing) {
      gridRef.current?.api?.hideOverlay();
    }
  }, [isApplying, isApplyingToAllRecords, processId, isProcessing]);

  useEffect(() => {
    console.log('save-data', save);
    if (save) {
      applyUpdates();
    }
  }, [save]);

  function showButtons() {
    // * Referring to ref state to avoid inconsistency because of closures within ag-grid
    const selected = currentTableState?.current?.totalSelected
      ? totalRows?.current
      : gridRef.current?.api?.getSelectedRows().length;
    return [
      {
        pinned: (
          <section className="sync-button-container">
            <p className="alert-text">
              {selected ? (
                <strong>
                  {selected} out of {totalRows.current} deals selected.
                </strong>
              ) : null}{' '}
              Click Save to update data{selected ? '.' : ''}
            </p>
            <div className="save-btns">
              <Button
                loading={isApplying || isApplyingToAllRecords}
                disabled={isApplying || isApplyingToAllRecords}
                type="secondary"
                onClick={hideButtons}
              >
                Cancel
              </Button>
              <Button
                loading={isApplying || isApplyingToAllRecords}
                disabled={isApplying || isApplyingToAllRecords}
                type="primary"
                onClick={() => setSave(true)}
              >
                Save
              </Button>
            </div>
          </section>
        ),
      },
    ];
  }

  const rollbackRowChanges = (node) => {
    const data = { ...node.data };
    // * Remove from stored state
    if (updatedRows.current[data.id]) {
      delete updatedRows.current[data.id];
    }
    if (
      !node.data?.prevPreskaleStageName &&
      !node.data?.prevCrmStage &&
      !node.data?.prevFieldValues
    ) {
      // * Do nothing if there are no changes
      return;
    }

    // * Rollback changes made to the node
    if (data.prevPreskaleStageName) {
      data.currentPreskaleStageName = data.prevPreskaleStageName;
      data.prevPreskaleStageName = null;
      data.presalesStage = '';
    }
    if (data.prevCrmStage) {
      data.crmStage = data.prevCrmStage;
      data.prevCrmStage = null;
    }
    if (data.prevFieldValues) {
      for (let field in data.prevFieldValues) {
        if (data.prevFieldValues[field] === undefined) {
          delete data[field];
        }
        if (data.prevFieldValues[field]) {
          data[field] = data.prevFieldValues[field];
        }
      }
      data.prevFieldValues = null;
    }

    node.setData(data);
  };

  function hideButtons() {
    const rows = Object.values(updatedRows.current);
    // * Rollback changes
    // * For selected nodes rollback is done on deselect event
    rows.forEach(rollbackRowChanges);
    updatedRows.current = {};
    rowsToUpdateAllRecords.current = {};
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.setPinnedTopRowData([]);
    /*
     * We are referring state from useRef to avoid inconsistent
     * UI behavior because of state being refered from closures
     * used by ag-grid. This happens when we initially edit a couple
     * of cells and then select all using the header checkbox.
     * From here on, when we attempt to use the buttons in the pinned row,
     * the onClick functions contain previous values from by closures
     */
    if (currentTableState?.current?.selectAll) {
      setSelectAll(false);
      currentTableState.current.selectAll = false;
    }
    if (currentTableState?.current?.totalSelected) {
      gridRef.current?.api.forEachNode(rollbackRowChanges);
      setTotalSelected(false);
      currentTableState.current.totalSelected = false;
    }
    if (currentTableState?.current?.openSelectAllNotification) {
      setOpenSelectAllNotification(false);
      currentTableState.current.openSelectAllNotification = false;
    }
    return [];
  }

  const toggleUpdateButtons = () => {
    const selected = gridRef.current?.api.getSelectedNodes();
    if (selected.length > 0) {
      // * Update selected deal count in UI
      gridRef.current?.api?.setPinnedTopRowData(showButtons());
    }
    if (
      selected.length === 0 &&
      Object.keys(updatedRows.current).length === 0
    ) {
      // * Hide update buttons
      gridRef.current?.api?.setPinnedTopRowData([]);
    }
  };

  const [uncheckedRow, setUncheckedRow] = useState(null);
  const revertToMultiEditOnUncheck = (row) => {
    setUncheckedRow(row);
  };

  useEffect(() => {
    if (uncheckedRow) {
      // * Changed data
      const data = {
        singleEdit: true, // Passed when there is only one selected row to edit
      };
      gridRef?.current?.api.forEachNode((node) => {
        // * Store all selected rows to updatedRows.current
        if (node.selected) {
          data.params = {
            data: node.data,
            node,
          };
          // * For non-cs fields(cs - custom sections)
          for (let field in rowsToUpdateAllRecords.current) {
            if (field === 'crmStage') {
              data.option = {
                label: node.data[field],
                value: rowsToUpdateAllRecords.current[field],
              };
              editorCallback('crmStage', 'prevCrmStage')(data);
            }
            if (field === 'currentPreskaleStageName') {
              data.option = {
                label: node.data[field],
                value: rowsToUpdateAllRecords.current[field],
              };
              editorCallback(
                'currentPreskaleStageName',
                'prevPreskaleStageName'
              )(data);
            }
            // * For cs fields
            if (field !== 'crmStage' && field !== 'currentPreskaleStageName') {
              data.option = rowsToUpdateAllRecords.current[field];
              customSectionsCallback(field)(data);
            }
          }
          displayEditButtons(data.params, node);
        }
      });
      rowsToUpdateAllRecords.current = {};
      setUncheckedRow(null);
    }
  }, [uncheckedRow]);

  const onRowSelected = useCallback((row) => {
    // * Update selected count if the save/cancel pinned row is visible
    if (gridRef?.current?.api?.getPinnedTopRow(0)) {
      gridRef.current.api.setPinnedTopRowData(showButtons());
    }
    const rows = Object.values(updatedRows.current);
    // * Unchecking a column in total records bulk edit mode
    if (!row.node.selected && currentTableState.current.totalSelected) {
      if (currentTableState.current.selectAll) {
        setSelectAll(false);
        currentTableState.current.selectAll = false;
      }
      if (currentTableState.current.totalSelected) {
        setTotalSelected(false);
        currentTableState.current.totalSelected = false;
      }
      if (currentTableState.current.openSelectAllNotification) {
        setOpenSelectAllNotification(false);
        currentTableState.current.openSelectAllNotification = false;
      }
      rollbackRowChanges(row.node);
      revertToMultiEditOnUncheck(row);
    }
    // * Rollback made changes when a row is deselected in normal/bulk mode
    if (!row.node.selected && rows.length) {
      if (currentTableState.current.selectAll) {
        setSelectAll(false);
        currentTableState.current.selectAll = false;
      }
      if (currentTableState.current.openSelectAllNotification) {
        setOpenSelectAllNotification(false);
        currentTableState.current.openSelectAllNotification = false;
      }
      rollbackRowChanges(row.node);
      toggleUpdateButtons();
    }
  }, []);

  const getRowHeight = () => 45;

  const storeInitialValue = (data, prevField, field, newValue) => {
    if (!data[prevField]) {
      data[prevField] = data[field];
    }
    data[field] = newValue?.label;
    if (field === 'currentPreskaleStageName') {
      data.presalesStage = newValue?.value;
    }
  };

  const editorCallback = (field, prevField) => (updatedData) => {
    if (updatedData.singleEdit) {
      const data = { ...updatedData.params.data };
      storeInitialValue(data, prevField, field, updatedData.option);
      updatedData.params.node.setData(data);
      updatedRows.current[data.id] = updatedData.params.node;
    }

    if (!updatedData.singleEdit) {
      let totalRecordsUpdateNode;
      gridRef.current?.api.forEachNode((node) => {
        if (node.selected) {
          const data = { ...node.data };
          storeInitialValue(data, prevField, field, updatedData.option);
          node.setData(data);
          if (!updatedData.updateTotalRecords) {
            updatedRows.current[data.id] = node;
          }
          if (updatedData.updateTotalRecords && !totalRecordsUpdateNode) {
            totalRecordsUpdateNode = node;
          }
        }
      });
      if (updatedData.updateTotalRecords) {
        storeTotalRecUpdatedData(field, totalRecordsUpdateNode);
      }
    }
    gridRef.current?.api?.setPinnedTopRowData(showButtons());
  };

  const csCellEditorParams = (field) => (params) => {
    const editorParams = {
      type: type[field],
      bulkSelectValue: params.value,
      numberOfSelectedCheckbox: totalSelected
        ? totalRows.current
        : params.api.getSelectedRows()?.length ?? 0,
      selectAllRecords: totalSelected,
      onCellClicked: () => {
        if (
          currentTableState?.current?.openSelectAllNotification &&
          !disableClosingSelectAllNotification.current
        ) {
          setOpenSelectAllNotification(false);
          currentTableState.current.openSelectAllNotification = false;
        }
      },
      callback: customSectionsCallback(field),
    };
    return type[field] === 'multiselect' ||
      type[field] === 'userdefineddropdown'
      ? {
          values: customSectionOptions[field],
          isMultiselect: type[field] === 'multiselect' ? true : false,
          ...editorParams,
        }
      : editorParams;
  };

  const storeInitialCSValue = (data, field) => {
    // * Store initial data
    if (data.prevFieldValues && !data.prevFieldValues.hasOwnProperty(field)) {
      data.prevFieldValues[field] = data[field];
    }
    if (!data.prevFieldValues) {
      data.prevFieldValues = { [field]: data[field] };
    }
  };

  const multipleOptionValueSetter = (field, data, newValue) => {
    // * Check is field has any options from custom sections
    // * Handle field type of 'select' and 'radio'
    // * We display select component for both types
    if (
      customSectionOptions[field] &&
      type[field] !== 'multiselect' &&
      type[field] !== 'userdefineddropdown'
    ) {
      storeInitialCSValue(data, field);
      data[field] = newValue?.value;
    }
  };

  const multiselectValueSetter = (field, data, newValue) => {
    // * Handle 'multiselect' field type
    if (
      type[field] === 'multiselect' ||
      type[field] === 'userdefineddropdown'
    ) {
      storeInitialCSValue(data, field);
      data[field] = newValue;
    }
  };

  const remainingTypeValueSetter = (field, data, newValue) => {
    // * Handle rest of the field types: text, textarea, number, date
    if (
      !customSectionOptions[field] &&
      type[field] !== 'multiselect' &&
      type[field] !== 'userdefineddropdown'
    ) {
      storeInitialCSValue(data, field);
      data[field] =
        type[field] === 'number' &&
        newValue !== null &&
        typeof newValue !== 'undefined' &&
        typeof newValue !== 'number'
          ? Number(newValue)
          : newValue;
    }
  };

  const csValueSetter = (field) => (params) => {
    if (params.node.isSelected()) {
      // * Exit value setter if bulk edit. Doing this because this is unreliable and does not initially trigger during a bulk edit(checked row)
      return;
    }
    if (!params.node.isSelected()) {
      params.node.setSelected(true);
    }
    // * For single edit changes
    const mandatory = mandatoryFieldsData[field];
    const clearedData = Array.isArray(params.newValue)
      ? !params.newValue.length
      : !params.newValue;
    const prevValue = params.data.prevFieldValues?.hasOwnProperty(field)
      ? params.data.prevFieldValues[field]
      : params.data[field];
    /*
     *  Checking if a row is mandatory
     *  and if it initally had values(before edit)
     *  and if the values were cleared
     */
    if (prevValue && mandatory && clearedData) {
      toast.warn(`${field} cannot be empty`, {
        toastId: mandatoryFieldToastId,
      });
      return false;
    }
    /*
     *  Making changes to the row data,
     *  store original values in a temp state,
     *  and display save/cancel buttons
     *
     *  If a value is falsy in new value we are changing it to undefined
     *  because empty cells are undefined in aggrid
     *  This helps check the condition if a value has been changed
     *
     *  Initially when the field was not null and now the user opts to clear out, null will be passed.
     *  Else undefined, meaning the cell was empty initially and no change is detected
     */

    multipleOptionValueSetter(
      field,
      params.data,
      params.newValue?.length === 0
        ? prevValue
          ? null
          : undefined
        : params.newValue
    );
    multiselectValueSetter(
      field,
      params.data,
      params.newValue?.length === 0
        ? prevValue
          ? null
          : undefined
        : params.newValue
    );
    remainingTypeValueSetter(
      field,
      params.data,
      params.newValue || (prevValue ? null : undefined)
    );
    displayEditButtons(params);
    return true;
  };

  const customSectionsCallback = (field) => (updatedData) => {
    const mandatory = mandatoryFieldsData[field];
    const clearedData = Array.isArray(updatedData.option)
      ? !updatedData.option.length
      : !updatedData.option;
    const prevValue = updatedData.params.data.prevFieldValues?.hasOwnProperty(
      field
    )
      ? updatedData.params.data.prevFieldValues[field]
      : updatedData.params.data[field];

    if (updatedData.singleEdit) {
      /*
       *  Checking if a row is mandatory
       *  and if it initally had values(before edit)
       *  and if the values were cleared
       */
      if (prevValue && mandatory && clearedData) {
        toast.warn(`${field} cannot be empty`, {
          toastId: mandatoryFieldToastId,
        });
        return false;
      }

      const data = { ...updatedData.params.data };
      /*
       *  Making changes to the row data,
       *  store original values in a temp state,
       *  and display save/cancel buttons
       *
       *  If a value is falsy in new value we are changing it to undefined
       *  because empty cells are undefined in aggrid
       *  This helps check the condition if a value has been changed
       *
       * Initially when the field was not null and now the user opts to clear out, null will be passed.
       * Else undefined, meaning the cell was empty initially and no change is detected
       */
      multipleOptionValueSetter(
        field,
        data,
        !updatedData.option || updatedData.option?.length === 0
          ? prevValue
            ? null
            : undefined
          : updatedData.option
      );
      multiselectValueSetter(
        field,
        data,
        !updatedData.option || updatedData.option?.length === 0
          ? prevValue
            ? null
            : undefined
          : updatedData.option
      );
      remainingTypeValueSetter(
        field,
        data,
        updatedData.option || (prevValue ? null : undefined)
      );
      updatedData.params.node.setData(data);
      displayEditButtons(updatedData.params, updatedData.params.node);
    }
    if (!updatedData.singleEdit) {
      let totalRecordsUpdateNode;
      gridRef.current?.api.forEachNode((node) => {
        if (node.selected) {
          /*
           *  Checking if a row is mandatory
           *  and if it initally had values(before edit)
           *  and if the values were cleared
           */
          if (prevValue && mandatory && clearedData) {
            toast.warn(`${field} cannot be empty`, {
              toastId: mandatoryFieldToastId,
            });
            return false;
          }
          const data = { ...node.data };
          /*
           *  Making changes to the row data,
           *  store original values in a temp state,
           *  and display save/cancel buttons
           *
           *  If a value is falsy in new value we are changing it to undefined
           *  because empty cells are undefined in aggrid
           *  This helps check the condition if a value has been changed
           *
           *  Initially when the field was not null and now the user opts to clear out, null will be passed.
           *  Else undefined, meaning the cell was empty initially and no change is detected
           */
          multipleOptionValueSetter(
            field,
            data,
            updatedData.option?.length === 0
              ? prevValue
                ? null
                : undefined
              : updatedData.option
          );
          multiselectValueSetter(
            field,
            data,
            updatedData.option?.length === 0
              ? prevValue
                ? null
                : undefined
              : updatedData.option
          );
          remainingTypeValueSetter(
            field,
            data,
            updatedData.option || (prevValue ? null : undefined)
          );
          node.setData(data);
          if (updatedData.updateTotalRecords && !totalRecordsUpdateNode) {
            totalRecordsUpdateNode = node;
          }
          displayEditButtons(
            updatedData.params,
            node,
            updatedData.updateTotalRecords
          );
        }
      });
      // * Save data to update in its state
      if (updatedData.updateTotalRecords) {
        storeTotalRecUpdatedData(field, totalRecordsUpdateNode);
      }
      return true;
    }
  };

  const highlightChangedCells = (prevKey, currentKey) => (params) => {
    // * Check if the cell has been changed and apply background color
    let isCellChanged = false;
    // * We add a optional chaining operator after params.data because this method runs before params.data is defined
    if (prevKey === 'prevFieldValues') {
      isCellChanged =
        params.data?.[prevKey] &&
        params.data?.[prevKey].hasOwnProperty(currentKey)
          ? JSON.stringify(params.data?.[prevKey]?.[currentKey]) !==
            JSON.stringify(params.data?.[currentKey])
          : false;
    } else {
      isCellChanged = params.data?.[prevKey]
        ? params.data[prevKey] !== params.data?.[currentKey]
        : false;
    }

    return {
      backgroundColor: isCellChanged ? 'rgb(250, 255, 189)' : null,
      borderRight: '1px solid #d8e4fd',
    };
  };

  const onSelect = () => {
    return [
      () => {
        setTotalSelected(true);
        currentTableState.current.totalSelected = true;
        if (
          Object.keys(rowsToUpdateAllRecords.current).length ||
          Object.keys(updatedRows.current).length
        ) {
          gridRef?.current?.api?.setPinnedTopRowData(showButtons());
        } else {
          gridRef?.current?.api?.setPinnedTopRowData([]);
        }
        return Object.keys(updatedRows.current).length;
      },
      () => {
        if (Object.keys(updatedRows.current).length) {
          const rows = Object.values(updatedRows.current);
          // * Rollback changes
          rows.forEach(rollbackRowChanges);
          updatedRows.current = {};
        }
        setTotalSelected(true);
        currentTableState.current.totalSelected = true;
        if (
          Object.keys(rowsToUpdateAllRecords.current).length ||
          Object.keys(updatedRows.current).length
        ) {
          gridRef?.current?.api?.setPinnedTopRowData(showButtons());
        } else {
          gridRef?.current?.api?.setPinnedTopRowData([]);
        }
      },
    ];
  };

  const onPaginationChanged = () => {
    if (selectAll && totalSelected) {
      gridRef?.current?.api?.forEachNode((node) => {
        if (!node.isSelected()) {
          node.setSelected(true);
          // * Hightlight cells
          // * For non-cs fields(cs - custom sections)
          const data = { ...node.data };
          for (let field in rowsToUpdateAllRecords.current) {
            if (field === 'crmStage') {
              const option = {
                label: rowsToUpdateAllRecords.current[field],
                value: rowsToUpdateAllRecords.current[field],
              };
              storeInitialValue(data, 'prevCrmStage', field, option);
            }
            if (field === 'currentPreskaleStageName') {
              const option = stages?.filter(
                (val) => rowsToUpdateAllRecords.current[field] === val.id
              );
              const value = {
                label: option[0].name,
                value: option[0].id,
              };
              storeInitialValue(data, 'prevPreskaleStageName', field, value);
            }
            // * For cs fields
            if (field !== 'crmStage' && field !== 'currentPreskaleStageName') {
              const prevValue = data?.prevFieldValues?.hasOwnProperty(field)
                ? data.prevFieldValues[field]
                : data[field];
              let newValue = rowsToUpdateAllRecords.current[field];
              if (type[field] === 'select' || type[field] === 'radio') {
                newValue = {
                  label: rowsToUpdateAllRecords.current[field],
                  value: rowsToUpdateAllRecords.current[field],
                };
              }
              multipleOptionValueSetter(
                field,
                data,
                newValue?.length === 0
                  ? prevValue
                    ? null
                    : undefined
                  : newValue
              );
              multiselectValueSetter(
                field,
                data,
                newValue?.length === 0
                  ? prevValue
                    ? null
                    : undefined
                  : newValue
              );
              remainingTypeValueSetter(
                field,
                data,
                newValue || (prevValue ? null : undefined)
              );
            }
          }
          node.setData(data);
        }
      });
    }
  };
  return (
    <>
      {openSelectAllNotification && (
        <SelectAllNotification
          selected={gridRef.current.api.getSelectedRows()?.length}
          totalRows={totalRows.current}
          clearSelection={hideButtons}
          cancelUpdate={() => {
            setTotalSelected(false);
            currentTableState.current.totalSelected = false;
            gridRef?.current?.api?.setPinnedTopRowData(showButtons());
          }}
          allSelected={totalSelected}
          onSelect={onSelect}
          handleClose={() => {
            setOpenSelectAllNotification(false);
            currentTableState.current.openSelectAllNotification = false;
          }}
          disableClose={(status) => {
            // * Disable closing popup and editing rows when warning is displayed
            disableClosingSelectAllNotification.current = status;
            if (status) {
              gridRef?.current?.api?.showLoadingOverlay();
            }
            if (!status) {
              gridRef?.current?.api?.hideOverlay();
            }
          }}
        />
      )}
      <div className="pipeline-table">
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            cacheBlockSize={20}
            rowModelType={'infinite'}
            cacheOverflowSize={20}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={500}
            infiniteInitialRowCount={1}
            onGridReady={onGridReady}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            components={{
              numericCellEditor: NumericCellEditor,
            }}
            loadingOverlayComponent={loadingOverlayComponent}
            loadingOverlayComponentParams={loadingOverlayComponentParams}
            onSortChanged={onSortChange}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={onRowSelected}
            getRowHeight={getRowHeight}
            pinnedTopRowData={[]}
            onPaginationChanged={onPaginationChanged}
            stopEditingWhenCellsLoseFocus={true}
          >
            <AgGridColumn
              headerName=""
              field="checkbox"
              maxWidth={52}
              pinned="left"
              sortable={false}
              resizable={false}
              checkboxSelection={true}
              showDisabledCheckboxes={true}
              headerComponent={CustomHeaderComponent}
              headerComponentParams={{
                masterCheckboxValue: selectAll,
                handleMasterCheckbox: (e) => {
                  setSelectAll(e.target.checked);
                  currentTableState.current.selectAll = e.target.checked;
                  if (e.target.checked) {
                    gridRef.current.api.forEachNode((node) => {
                      node.setSelected(true);
                    });
                    setOpenSelectAllNotification(true);
                    currentTableState.current.openSelectAllNotification = true;
                    if (gridRef?.current?.api?.getPinnedTopRow(0)) {
                      gridRef.current.api.setPinnedTopRowData(showButtons());
                    }
                  }
                  if (!e.target.checked) {
                    gridRef.current.api.deselectAll();
                    if (totalSelected) {
                      setTotalSelected(false);
                      currentTableState.current.totalSelected = false;
                    }
                    if (openSelectAllNotification) {
                      setOpenSelectAllNotification(false);
                      currentTableState.current.openSelectAllNotification = false;
                    }
                  }
                },
              }}
              colSpan={(params) => {
                if (params.node.rowPinned) {
                  // have all pinned columns span 6 columns
                  return 5;
                } else {
                  // all other rows should be just normal
                  return 1;
                }
              }}
              cellRendererSelector={(params) => {
                if (params.node.rowPinned) {
                  return {
                    component: CustomPinnedRowRenderer,
                    params: {
                      style: {
                        backgroundColor: '#447BF2',
                        lineHeight: 'initial',
                        height: 'inherit',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      },
                    },
                  };
                } else {
                  // rows that are not pinned don't use any cell renderer
                  return undefined;
                }
              }}
              cellStyle={(params) => {
                if (params.node.rowPinned) {
                  return {
                    padding: 0,
                    border: 0,
                    width: 'calc(100vw - 52px) !important',
                  };
                } else {
                  return {
                    borderRight: '1px solid #d8e4fd',
                  };
                }
              }}
              // it is important to have node.id here, so that when the id changes (which happens
              // when the row is loaded) then the cell is refreshed.
              valueGetter={(params) => params?.node?.id}
            />
            <AgGridColumn
              headerName="S.No."
              maxWidth={100}
              pinned="left"
              sortable={false}
              resizable={false}
              // it is important to have node.id here, so that when the id changes (which happens
              // when the row is loaded) then the cell is refreshed.
              valueGetter={(params) => params?.node?.id}
              cellRenderer={IndexCellRenderer}
            />
            {filteredList?.includes('name') && (
              <AgGridColumn
                field="name"
                headerName="Deal Name"
                minWidth={400}
                pinned="left"
                cellRenderer={memo(memoizedCellRenderer)}
              />
            )}
            {selectedView?.value === 'NOTES' && (
              <AgGridColumn
                field="notes"
                headerName="Recently Added Notes"
                minWidth={400}
                sortable={false}
                cellEditorPopup={true}
                cellEditor={AgGridQuillEditor}
                cellRenderer={htmlRenderer}
                valueGetter={(params) => {
                  return params.data?.notes && params?.data?.notes?.description;
                }}
                valueSetter={(params) => {
                  if (params?.data?.notes) {
                    params.data.notes.description = params.newValue;
                  } else if (params?.newValue?.id) {
                    const note = {
                      description: params.newValue?.description,
                      plainText: params.newValue?.plainText,
                      id: params.newValue?.id,
                    };
                    params.data.notes = note;
                  }
                  return params;
                }}
                editable={(params) =>
                  !params.node.isSelected() && !isSalesUser && !isProductUser
                    ? true
                    : false
                }
                cellEditorParams={(params) => {
                  return {
                    addNote: params?.data?.notes ? false : true,
                  };
                }}
              />
            )}
            {filteredList?.includes('crmStage') && (
              <AgGridColumn
                field="crmStage"
                headerName="Crm Stage"
                minWidth={150}
                editable={(params) =>
                  DEFAULT_CRM_STAGES?.length > 0 &&
                  !isSalesUser &&
                  params?.data?.preskaleCreated &&
                  !isProductUser
                }
                sortable={false}
                valueGetter={(params) => {
                  return params?.data?.crmStage;
                }}
                valueSetter={(params) => {
                  if (params.node.isSelected()) {
                    // * Exit value setter if bulk edit. Doing this because this is unreliable and does not initially trigger during a bulk edit(checked row)
                    return;
                  }
                  if (!params.node.isSelected()) {
                    params.node.setSelected(true);
                  }
                  // * Don't do anything if there is no change in value
                  if (
                    params.oldValue === params.newValue.label ||
                    params.oldValue === params.newValue
                  ) {
                    return;
                  }
                  storeInitialValue(
                    params.data,
                    'prevCrmStage',
                    'crmStage',
                    params.newValue
                  );
                  displayEditButtons(params, params.node);
                  return true;
                }}
                cellEditorParams={(params) => {
                  return {
                    values: DEFAULT_CRM_STAGES,
                    bulkSelectValue: params.value
                      ? {
                          label: params.value,
                          value: params.value,
                        }
                      : '',
                    numberOfSelectedCheckbox: totalSelected
                      ? totalRows.current
                      : params.api.getSelectedRows()?.length ?? 0,
                    selectAllRecords: totalSelected,
                    onCellClicked: () => {
                      if (
                        currentTableState?.current?.openSelectAllNotification &&
                        !disableClosingSelectAllNotification.current
                      ) {
                        setOpenSelectAllNotification(false);
                        currentTableState.current.openSelectAllNotification = false;
                      }
                    },
                    callback: editorCallback('crmStage', 'prevCrmStage'),
                  };
                }}
                singleClickEdit="true"
                cellEditorSelector={(params) => {
                  return {
                    component: params?.node?.isSelected()
                      ? BulkUpdatePopup
                      : AgGridCustomSelect,
                    popup: true,
                  };
                }}
                cellStyle={highlightChangedCells('prevCrmStage')}
              />
            )}
            {filteredList?.includes('dealValue') && (
              <AgGridColumn
                field="dealValue"
                headerName="Deal Value"
                cellRenderer={(props) => {
                  return formatCountMemoized(props.value);
                }}
                minWidth={150}
              />
            )}
            {filteredList?.includes('dealOwner.displayName') && (
              <AgGridColumn
                field="dealOwner.displayName"
                headerName="Sales Owner"
                minWidth={150}
              />
            )}
            {filteredList?.includes('expectedCloseDate') && (
              <AgGridColumn
                field="expectedCloseDate"
                headerName="Close Date"
                minWidth={150}
              />
            )}
            {filteredList?.includes('presalesUpdatedDate') && (
              <AgGridColumn
                field="presalesUpdatedDate"
                headerName="Presales Updated At"
                minWidth={150}
                cellRenderer={(props) => {
                  return getFormattedDate(props.value);
                }}
              />
            )}
            {filteredList?.includes('presalesUserDetails.displayName') && (
              <AgGridColumn
                field="presalesUserDetails.displayName"
                headerName="Presales Owner"
                minWidth={150}
              />
            )}
            {filteredList?.includes('presalesStage.name') && (
              <AgGridColumn
                field="currentPreskaleStageName"
                headerName="Presales Stage"
                minWidth={200}
                editable={stages?.length > 0 && !isSalesUser && !isProductUser}
                sortable={false}
                valueGetter={(params) => {
                  return params?.data?.currentPreskaleStageName;
                }}
                valueSetter={(params) => {
                  if (params.node.isSelected()) {
                    // * Exit value setter if bulk edit. Doing this because this is unreliable and does not initially trigger during a bulk edit(checked row)
                    return;
                  }
                  if (!params.node.isSelected()) {
                    params.node.setSelected(true);
                  }
                  // * Don't do anything if there is no change in value
                  if (
                    params.oldValue === params.newValue.label ||
                    params.oldValue === params.newValue
                  ) {
                    return;
                  }
                  storeInitialValue(
                    params.data,
                    'prevPreskaleStageName',
                    'currentPreskaleStageName',
                    params.newValue
                  );
                  displayEditButtons(params, params.node);
                  return true;
                }}
                cellEditorParams={(params) => {
                  const value = stages?.filter(
                    (val) => params.value === val.name
                  );
                  return {
                    values: stages?.map((val) => ({
                      label: val.name,
                      value: val.id,
                    })),
                    bulkSelectValue: value.length
                      ? {
                          label: value[0].name,
                          value: value[0].id,
                        }
                      : '',
                    numberOfSelectedCheckbox: totalSelected
                      ? totalRows.current
                      : params.api.getSelectedRows()?.length ?? 0,
                    selectAllRecords: totalSelected,
                    onCellClicked: () => {
                      if (
                        currentTableState?.current?.openSelectAllNotification &&
                        !disableClosingSelectAllNotification.current
                      ) {
                        setOpenSelectAllNotification(false);
                        currentTableState.current.openSelectAllNotification = false;
                      }
                    },
                    callback: editorCallback(
                      'currentPreskaleStageName',
                      'prevPreskaleStageName'
                    ),
                  };
                }}
                cellEditorSelector={(params) => {
                  return {
                    component: params?.node?.isSelected()
                      ? BulkUpdatePopup
                      : AgGridCustomSelect,
                    popup: true,
                  };
                }}
                cellStyle={highlightChangedCells(
                  'prevPreskaleStageName',
                  'currentPreskaleStageName'
                )}
                singleClickEdit="true"
              />
            )}
            {configFeatures?.data?.featureList?.is_custom_section_enabled &&
              selectedView?.value !== 'NOTES' &&
              fields.map((field) => {
                if (filteredCustomList.includes(field)) {
                  return customSectionOptions[field] &&
                    type[field] !== 'multiselect' &&
                    type[field] !== 'userdefineddropdown' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      minWidth={200}
                      editable={!isSalesUser && !isProductUser}
                      sortable={false}
                      singleClickEdit="true"
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField: writeBackFields[field],
                      }}
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={(params) => {
                        const options = customSectionOptions[field]?.map(
                          (val) => {
                            return {
                              label: val,
                              value: val,
                            };
                          }
                        );
                        return {
                          values: options,
                          bulkSelectValue: params.value
                            ? {
                                label: params.value,
                                value: params.value,
                              }
                            : '',
                          numberOfSelectedCheckbox: totalSelected
                            ? totalRows.current
                            : params.api.getSelectedRows()?.length ?? 0,
                          selectAllRecords: totalSelected,
                          onCellClicked: () => {
                            if (
                              currentTableState?.current
                                ?.openSelectAllNotification
                            ) {
                              setOpenSelectAllNotification(false);
                              currentTableState.current.openSelectAllNotification = false;
                            }
                          },
                          callback: customSectionsCallback(field),
                        };
                      }}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : AgGridCustomSelect,
                          popup: true,
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                    />
                  ) : !customSectionOptions[field] && type[field] === 'text' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      minWidth={200}
                      maxWidth={600}
                      editable={!isSalesUser && !isProductUser}
                      sortable={false}
                      singleClickEdit="true"
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField: writeBackFields[field],
                      }}
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={csCellEditorParams(field)}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : 'agTextCellEditor',
                          popup: !!params?.node?.isSelected(),
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                    />
                  ) : !customSectionOptions[field] &&
                    filteredCustomList.includes(field) &&
                    type[field] === 'textarea' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      minWidth={200}
                      maxWidth={600}
                      editable={!isSalesUser && !isProductUser}
                      sortable={false}
                      singleClickEdit="true"
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField: writeBackFields[field],
                      }}
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={csCellEditorParams(field)}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : 'agLargeTextCellEditor',
                          popup: true,
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                    />
                  ) : !customSectionOptions[field] && type[field] === 'date' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      minWidth={150}
                      maxWidth={150}
                      editable={!isSalesUser && !isProductUser}
                      resizable={false}
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField: writeBackFields[field],
                      }}
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={csCellEditorParams(field)}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : AgGridDatePicker,
                          popup: true,
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                      singleClickEdit="true"
                    />
                  ) : !customSectionOptions[field] &&
                    type[field] === 'number' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField:
                          writeBackFields && writeBackFields[field],
                      }}
                      minWidth={150}
                      editable={!isSalesUser && !isProductUser}
                      sortable={false}
                      singleClickEdit="true"
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={csCellEditorParams(field)}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : 'numericCellEditor',
                          popup: true,
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                    />
                  ) : type[field] === 'multiselect' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField:
                          writeBackFields && writeBackFields[field],
                      }}
                      minWidth={150}
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={csCellEditorParams(field)}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : CustomMultiSelectCellEditor,
                          popup: true,
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                      editable={!isSalesUser && !isProductUser}
                      sortable={false}
                      singleClickEdit="true"
                    />
                  ) : type[field] === 'userdefineddropdown' ? (
                    <AgGridColumn
                      key={generateUUID()}
                      field={field}
                      headerName={field}
                      headerComponent={CustomHeaderComponent}
                      headerComponentParams={{
                        mandatoryField: mandatoryFieldsData[field],
                        writeBackField:
                          writeBackFields && writeBackFields[field],
                      }}
                      minWidth={150}
                      valueSetter={csValueSetter(field)}
                      cellEditorParams={csCellEditorParams(field)}
                      cellEditorSelector={(params) => {
                        return {
                          component: params?.node?.isSelected()
                            ? BulkUpdatePopup
                            : CustomMultiSelectCellEditor,
                          popup: true,
                        };
                      }}
                      cellStyle={highlightChangedCells(
                        'prevFieldValues',
                        field
                      )}
                      editable={!isSalesUser && !isProductUser}
                      sortable={false}
                      singleClickEdit="true"
                    />
                  ) : null;
                }
              })}
          </AgGridReact>
        </div>
      </div>
    </>
  );
};

export default PipelineTable;
