import React, { useState } from 'react';
import SideNavigator from './SideNavigation';
import ProfileRightPanel from './ProfileRightPanel';
import OutcomeAutomation from './OutcomeAutomation';
import NavigatorTitles, { subTitles } from './constants';
import PageLayout from '../Layout/PageLayout';
import ErrorBoundary from '../Pipeline/components/ErrorBoundary';

const Index = () => {
  const [activePage, setActivePage] = useState('');

  // Tracks active tab change triggered by both sidebar selection and scrolling
  const [activeSection, setActiveSection] = useState(subTitles[0]?.id);

  // Tracks active tab change specifically triggered by sidebar tab click
  const [selectedSection, setSelectedSection] = useState(null);

  const handleSidebarItemChange = (section) => {
    setActiveSection(section);
  };

  return (
    <PageLayout
      headerMainContent={
        activePage !== 'outcome' ? (
          <div className="flex-center text-lg text-weight-semi-bold">
            My Profile Settings
          </div>
        ) : null
      }
    >
      <>
        {activePage === 'outcome' ? (
          <OutcomeAutomation setActivePage={setActivePage} />
        ) : (
          <div className="admin-settings">
            <SideNavigator
              navigationContent={NavigatorTitles}
              onSidebarItemClick={handleSidebarItemChange}
              activeSection={activeSection}
              setSelectedSection={setSelectedSection}
              setActivePage={setActivePage}
              activePage={activePage}
            />
            <div className="flex width-20"></div>
            <ErrorBoundary>
              <ProfileRightPanel
                activePage={activePage}
                setActivePage={setActivePage}
                selectedSection={selectedSection}
                onSidebarItemClick={handleSidebarItemChange}
                setActiveSection={setActiveSection}
              />
            </ErrorBoundary>
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default Index;
