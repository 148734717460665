import React from 'react';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import { DateFormat } from '../../Utils/DayJs';
import { createMarkup } from '../../Utils/common';
import { ProductGapRatingReadOnly } from '../../common/Rating';
// import { ReactComponent as AddSVG } from '../../Icons/Plus.svg';
import { ReactComponent as EditSVG } from '../../Icons/Pencil.svg';
import { ReactComponent as DeleteSVG } from '../../Icons/Delete.svg';
import { ReactComponent as InfoSVG } from '../../Icons/infoCircle.svg';
import Button from '../../common/Button';
import PreskaleCard from '../../common/PreskaleCard';

const ProductGapItem = ({
  productGap,
  // isPresentationView,
  // handleAddNoteOpen,
  editProductGap,
  deleteProductGap,
}) => {
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });

  return (
    <PreskaleCard
      className="usecase-item product-gap-item"
      header={
        <div className="usecase-title--container">{productGap.module}</div>
      }
    >
      {productGap?.usecases?.map((usecase, i) => {
        return (
          <section key={usecase.id}>
            {i !== 0 && <hr className="horizontal" />}
            <div className="usecase-details">
              <div className="usecase-details--header">
                <div className="usecase-breadcrumbs">
                  <div className="usecase-index"> #{i + 1} </div>
                  <div className="breadcrumb-item">
                    {Array.isArray(usecase?.pgItem) && usecase?.pgItem.length
                      ? usecase?.pgItem[0]?.feature?.label
                      : '--'}
                  </div>
                  <span>{'>'}</span>
                  <div className="breadcrumb-item title">
                    {Array.isArray(usecase?.pgItem) && usecase?.pgItem.length
                      ? usecase?.pgItem[0]?.title
                      : '--'}
                  </div>
                  {Array.isArray(usecase?.pgItem) &&
                    usecase?.pgItem.length &&
                    usecase?.pgItem[0]?.jiraIssueKey && (
                      <span className="tag">
                        {usecase?.pgItem[0]?.jiraIssueKey}
                      </span>
                    )}
                </div>
                <div className="actions--container">
                  {/* {productGap.status !== 'COMPLETED' &&
                    !isClosedAccount &&
                    !isPresentationView &&
                    handleAddNoteOpen && (
                      <Button
                        icon
                        className="btn-icon-only"
                        type="ghost"
                        onClick={() => handleAddNoteOpen(usecase)}
                      >
                        <AddSVG />
                      </Button>
                    )} */}
                  {editProductGap && (
                    <Button
                      icon
                      className="btn-icon-only"
                      type="ghost"
                      onClick={(ev) => editProductGap(usecase, ev)}
                    >
                      <EditSVG />
                    </Button>
                  )}

                  {deleteProductGap && (
                    <Button
                      icon
                      className="btn-icon-only"
                      type="ghost"
                      onClick={(ev) => deleteProductGap(usecase, ev)}
                    >
                      <DeleteSVG />
                    </Button>
                  )}
                </div>
              </div>

              <div>
                <div className="usecase-body">
                  <div className="usecase-body--content">
                    <div
                      className="usecase-content"
                      dangerouslySetInnerHTML={createMarkup(usecase?.useCase)}
                    />
                  </div>
                </div>
                <div className="usecase-footer">
                  <ProductGapRatingReadOnly rating={usecase.rating} />

                  <div
                    className={`tag ${
                      usecase.productGapType === 'New Request'
                        ? 'new-request--tag'
                        : 'enhancement--tag'
                    } info-tag ml-auto`}
                  >
                    <InfoSVG />
                    <div className="type">{usecase.productGapType}</div>
                  </div>

                  <div className="usecase-user">
                    {userMap[usecase.createdBy] &&
                      userMap[usecase.createdBy].name}{' '}
                  </div>

                  <div className="usecase-date">
                    {DateFormat(usecase.createdDate, 'DD MMM, YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </PreskaleCard>
  );
};

export default ProductGapItem;
