import { DATERANGE_IN_QUARTERS } from '../Constant/FilterOperators';

export const DATERANGE_OPTIONS = (isRangeSelected) => [
  {
    value: '',
    label: 'Show all',
    className: !isRangeSelected ? 'is-selected' : '',
  },
  ...DATERANGE_IN_QUARTERS,
  {
    label: 'Custom',
    value: 'custom',
  },
];
