export const roles = [
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 2,
    label: 'Manager',
  },
  {
    value: 3,
    label: 'User',
  },
  {
    value: 4,
    label: 'Sales',
  },
  {
    value: 5,
    label: 'Product',
  },
];
