import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
if (
  process.env.REACT_APP_ENV === 'SANDBOX' ||
  process.env.REACT_APP_ENV === 'DEVELOPMENT' ||
  process.env.REACT_APP_ENV === 'PRODUCTION'
) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
}

const MIXPANEL_EVENTS = {
  'task/add-task': {
    tag: 'task_created',
    event: 'New Task created',
  },
  'notes/add': {
    tag: 'note_created',
    event: 'Notes added',
  },
  'criteria/add-parent-criteria': {
    tag: 'successitem_created',
    event: 'Success item added',
  },
  'criteria/add': {
    tag: 'successitem_created',
    event: 'Success item added',
  },
  'meetings/save-additional-event-information': {
    tag: 'meeting_tagged',
    event: 'New Meeting synced',
  },
  'meetings/save-event-list-view': {
    tag: 'meeting_tagged',
    event: 'New Meeting synced',
  },
};

export const trackEvent = (res, url) => {
  if (res?.status === 201 && MIXPANEL_EVENTS[url]) {
    try {
      mixpanel.track(MIXPANEL_EVENTS[url].tag, {
        event: MIXPANEL_EVENTS[url].event,
      });
    } catch {
      console.error(`Cannot add ${MIXPANEL_EVENTS[url].tag} to mixpanel`);
    }
  }
};

export default mixpanel;
