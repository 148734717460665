import React, { useState, useCallback } from 'react';
import { formatCount } from '../../Utils/Revenue';
import { ReactComponent as CollabratorSVG } from '../../Icons/NewImage/collabrator.svg';
import { ReactComponent as PreskaleCreatedSVG } from '../../Icons/Unsyncable.svg';
import { isSalesUser as findIsSalesUser } from '../../Utils/common';
import ToolTip from '../../common/ToolTip';
import { Typography } from 'antd';

const { Text } = Typography;

const KanbanCard = ({
  isCRMStageView,
  handleCardClick,
  isProductUser,
  ...props
}) => {
  const isSalesUser = findIsSalesUser();
  const onCardClick = useCallback(
    (data) => handleCardClick(data),
    [handleCardClick]
  );

  const [showTitleTooltip, setShowTitleTooltip] = useState(false);
  const [showAccountNameTooltip, setShowAccountNameTooltip] = useState(false);
  const [showUserTooltip, setShowUserTooltip] = useState(false);
  const [showCrmStageTooltip, setShowCrmStageTooltip] = useState(false);

  const showTooltip = (setTooltipVisible) => (e) => {
    var element = e.target;
    if (element.offsetWidth < element.scrollWidth) {
      setTooltipVisible(true);
      return element.innerText;
    } else {
      return '';
    }
  };
  const hideTooltip = (tooltip, setTooltipVisible) => (e) => {
    if (!tooltip) {
      return;
    }
    setTooltipVisible(false);
  };

  return (
    <div
      className="app-kanban-card kanban-card"
      title=""
      onClick={() => onCardClick(props)}
    >
      <div className="title-group">
        {props.preskaleCreated && (
          <ToolTip text="Preskale created deal">
            <div className="flex">
              <PreskaleCreatedSVG />
            </div>
          </ToolTip>
        )}
        <Text strong type="primary" ellipsis={{ tooltip: props.name }}>
          {props.name}
        </Text>
      </div>
      <div className="kanban-card-body">
        <ToolTip
          text={
            Array.isArray(props.accountInfo) && props.accountInfo.length > 0
              ? props.accountInfo[0].name
              : '-x-'
          }
          showTooltip={showAccountNameTooltip}
        >
          <div
            className="kanban-card-content-highlight"
            data-for="kanbanCards"
            onMouseEnter={showTooltip(setShowAccountNameTooltip)}
            onMouseLeave={hideTooltip(
              showAccountNameTooltip,
              setShowAccountNameTooltip
            )}
          >
            {Array.isArray(props.accountInfo) && props.accountInfo.length > 0
              ? props.accountInfo[0].name
              : '-x-'}
          </div>
        </ToolTip>
        <div className="acc-deal-value">
          {!props.dealValue && props.dealValue !== 0
            ? '-x-'
            : formatCount(props.dealValue, true)}
        </div>
        <span className="user-info">
          {props.collabratedDeal && !isSalesUser && (
            <ToolTip text="Collaborated deal">
              <span className="flex">
                <CollabratorSVG />
              </span>
            </ToolTip>
          )}
          <ToolTip
            text={props?.presalesUserDetails?.displayName}
            showTooltip={showUserTooltip}
            contentClassname="width-full"
          >
            <div
              className="acc-presales-user"
              onMouseEnter={showTooltip(setShowUserTooltip)}
              onMouseLeave={hideTooltip(showUserTooltip, setShowUserTooltip)}
              data-for="kanbanCards"
            >
              {props?.presalesUserDetails?.displayName ?? '-x-'}
            </div>
          </ToolTip>
        </span>
        <section className="crm-info">
          <ToolTip
            text={
              isCRMStageView === 'crm'
                ? props.currentPreskaleStageName
                : props.crmStage
            }
            showTooltip={showCrmStageTooltip}
            contentClassname="width-full"
          >
            <div
              className="crm-stage"
              onMouseEnter={showTooltip(setShowCrmStageTooltip)}
              onMouseLeave={hideTooltip(
                showCrmStageTooltip,
                setShowCrmStageTooltip
              )}
              data-for="kanbanCards"
            >
              {(isCRMStageView === 'crm'
                ? props.currentPreskaleStageName
                : props.crmStage) || '-x-'}
            </div>
          </ToolTip>
        </section>
      </div>
    </div>
  );
};

export default KanbanCard;
