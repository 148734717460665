import axios from 'axios';
import authenticationService from '../_services/authentication.service';

const isProduction = process.env.REACT_APP_IS_DEVELOPMENT ? false : true;
console.log('isProduction', isProduction, process.env.REACT_APP_IS_DEVELOPMENT); // Remove in next iteration

const DOMAIN_NAME =
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? 'https://appdemo.preskale.com'
    : process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'https://trial.preskale.com'
    : process.env.REACT_APP_ENV === 'GITHUB'
    ? 'https://github.preskale.com'
    : 'https://app.preskale.com';

const LOCAL_API_URL = 'http://localhost:9004/';

const LOCAL_USER_URL = 'http://localhost:9002/api/user/';

const JIRA_LOCAL_API_URL = 'http://localhost:9005/';

const PROD_BUSINESS_API_URL = authenticationService?.PROD_BUSINESS_API_URL;

const PROD_USER_URL = authenticationService?.PROD_USER_URL;

const DOMAIN_URL = !isProduction ? LOCAL_API_URL : PROD_BUSINESS_API_URL;

const USER_DOMAIN_URL = !isProduction ? LOCAL_USER_URL : PROD_USER_URL;

const PROD_DATA_ENGINEERING_URL = DOMAIN_NAME + '/api/de/';

const DE_DOMAIN_URL = !isProduction
  ? JIRA_LOCAL_API_URL
  : PROD_DATA_ENGINEERING_URL;

const REFRESH_TOKEN_URL = 'refresh';

let idToken = localStorage.getItem('idToken') || '';
const refreshToken = localStorage.getItem('refreshToken') || '';

class AxiosService {
  get(url, cancelToken, isDataEngineering, params) {
    const profileData = JSON.parse(localStorage.getItem('user'));
    const companyId = profileData?.companyId || null;
    // let { userDetails: { companyId } } = authenticationService;
    const operator = url.includes('?') ? '&' : '?';
    url = `${url}${operator}company_id=${companyId}`;
    const option = {
      url: isDataEngineering ? `${DE_DOMAIN_URL}${url}` : `${DOMAIN_URL}${url}`,
      // url: `${DOMAIN_URL}${url}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
      cancelToken: cancelToken,
    };

    return axios(option)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  post(
    url,
    requestData,
    companyIdFromInput,
    userIdFromInput,
    isDataEngineering
  ) {
    const profileData = JSON.parse(localStorage.getItem('user'));
    const companyId = profileData?.companyId;
    if (!companyIdFromInput) {
      companyIdFromInput = companyId;
    }

    let dataObject = {};

    if (isDataEngineering) {
      dataObject = requestData;
      dataObject.company_id = companyIdFromInput;
    } else {
      dataObject = {
        company_id: companyIdFromInput,
        producer: requestData,
      };
    }

    if (userIdFromInput) {
      dataObject.user_id = userIdFromInput;
    }
    const option = {
      url: isDataEngineering ? `${DE_DOMAIN_URL}${url}` : `${DOMAIN_URL}${url}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataObject,
    };

    return axios(option)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  postWithToken(url, requestData) {
    idToken = authenticationService.getTokenData();
    const option = {
      url: `${DOMAIN_URL}${url}`,
      // url: `http://localhost:9002/api/business/${url}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Control-Allow-Origin': DOMAIN_NAME,
      },
      data: requestData,
    };

    return axios(option)
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        if (error && error.response && error.response.status === 401) {
          let res = await this.postRefreshToken();
          if (res.status === 200) {
            return await this.postWithToken(url, requestData);
          } else {
            // TODO: Check any usage of this file and remove it
            // return {
            //   msg: 'REFRESH_TOKEN_EXPIRED',
            //   status: 100,
            // };
          }
        }
      });
  }

  async getWithToken(url) {
    idToken = authenticationService.getTokenData();
    const option = {
      url: `${DOMAIN_URL}${url}`,
      // url: `http://localhost:9002/api/business/${url}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Control-Allow-Origin': DOMAIN_NAME,
      },
    };

    return await axios(option)
      .then((response) => {
        return response.data;
      })
      .catch(async (error) => {
        if (error && error.response && error.response.status === 401) {
          let res = await this.postRefreshToken();
          if (res.status === 200) {
            return await this.getWithToken(url);
          } else {
            // TODO: Check any usage of this file and remove it
            // return {
            //   msg: 'REFRESH_TOKEN_EXPIRED',
            //   status: 100,
            // };
          }
        }
      });
  }

  async postRefreshToken() {
    console.log('inside prt');
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const option = {
      url: `${USER_DOMAIN_URL}${REFRESH_TOKEN_URL}`,
      // url: `http://localhost:9002/api/user/v1${REFRESH_TOKEN_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userName: userDetails?.cognitoUserName,
        refreshToken: refreshToken,
      },
    };

    return axios(option)
      .then((response) => {
        if (response.status === 200) {
          authenticationService.setIdToken(response.data.data.idToken);
          authenticationService.setRefreshedIdToken(response.data.data.idToken);
          return response;
        }
      })
      .catch((error) => {
        return error;
      });
  }

  postCompany(url, data) {
    const options = {
      url: `${DOMAIN_URL}${url}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    };

    return axios(options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default new AxiosService();
