import { useState, useEffect, useRef } from 'react';
import useClickAwayListener from './useClickAwayListener';

const useCloseComponentOnOutsideClick = (excludeClassName) => {
  // * Hide component on clicking outside * //
  const containerRef = useRef(null);
  const componentBlur = useClickAwayListener(containerRef, excludeClassName);
  const [openComponent, setOpenComponent] = useState(false);
  useEffect(() => {
    if (componentBlur) {
      setOpenComponent(false);
    }
  }, [componentBlur]);
  // * ------------------------------------------ * //
  return {
    containerRef,
    openComponent,
    setOpenComponent,
  };
};

export default useCloseComponentOnOutsideClick;
