import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  useLazyGetAllTeamsByCompanyIdQuery,
  useLazyUpdateTimeZoneQuery,
} from './adminOpsSlice';
import { AgGridReact } from 'ag-grid-react';
import { AgGridCustomSelect } from '../features/calendar/AgGridCustomSelect';
import { TIME_ZONES } from '../Constant/TimeZones';
import { toast } from 'react-toastify';
import { ReactComponent as SearchSVG } from '../Icons/NewImage/search.svg';

const TeamsManagement = ({ companyId }) => {
  const gridRef = useRef();
  const filterTextBoxRef = useRef(null);
  const [teams, { data: allTeamsByCompanyId }] =
    useLazyGetAllTeamsByCompanyIdQuery();
  const [updateTimezone] = useLazyUpdateTimeZoneQuery();
  const TIME_ZONES_LABEL_VALUE = TIME_ZONES.map((timezone) => ({
    label: timezone,
    value: timezone,
  }));

  useEffect(() => {
    teams({
      companyId: companyId,
    });
  }, [companyId]);

  const columnDefs = [
    {
      headerName: '#',
      maxWidth: 100,
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: (params) => params?.node?.id,
      cellRenderer: (props) => {
        if (props.value !== undefined) {
          return parseInt(props?.value) + 1;
        } else if (props?.value === undefined) {
          return <DualRingLoader dark />;
        }
      },
    },
    { headerName: 'Team Name', field: 'teamName' },
    {
      headerName: 'No. of Team Members',
      field: 'teamMembers',
      cellRenderer: (props) => {
        return props?.data?.teamMembers?.length;
      },
    },
    {
      headerName: 'Time Zone',
      field: 'timeZone',
      editable: true,
      cellEditor: AgGridCustomSelect,
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          values: TIME_ZONES_LABEL_VALUE,
        };
      },
      valueGetter: (params) => {
        const timeZoneValue = TIME_ZONES_LABEL_VALUE?.filter(
          (data) => params.data.timeZone === data.label
        );
        return timeZoneValue[0]?.label;
      },
      valueSetter: (params) => {
        if (params?.newValue && params?.oldValue !== params?.newValue?.label) {
          const newData = { ...params.data };
          newData.timeZone = params?.newValue?.label;
          params.node.setData(newData);
          return true;
        }
      },
    },
  ];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(filterTextBoxRef?.current?.value);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      cellStyle: () => ({
        borderRight: '1px solid #d8e4fd',
      }),
      sortable: true,
    };
  }, []);

  const onCellEditingStopped = (params) => {
    if (params?.newValue && params?.oldValue !== params?.newValue?.label) {
      updateTimezone({
        companyId: companyId,
        timezone: params?.data?.timeZone,
        teamId: params?.data?.id,
      })
        .then((data) => {
          toast.success('Updated Successfully');
        })
        .catch((error) => {
          toast.error('Unable to save time zone');
        });
    }
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="teams-management">
      <div className={`search-container teams-search-container`}>
        <span>
          <SearchSVG className="searchsvg" />
        </span>
        <input
          type="text"
          ref={filterTextBoxRef}
          id="filter-text-box"
          className="company-searchbox"
          placeholder="Filter..."
          onInput={onFilterTextBoxChanged}
        />
      </div>
      <div className="teams-table">
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            domLayout={'autoHeight'}
            columnDefs={columnDefs}
            rowData={allTeamsByCompanyId?.data}
            enableCellTextSelection={true}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            onCellEditingStopped={onCellEditingStopped}
            singleClickEdit={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamsManagement;
