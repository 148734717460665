export const SELECT_STYLES = {
  option: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '16px',
    color: '#152B47',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '16px',
    color: '#2F4E74',
    opacity: 0.5,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '16px',
    color: '#152B47',
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: '1px solid #c7d6e6',
    borderRadius: '10px',
    padding: '0.3rem 1rem',
    width: '100%',
    height: '50px',
  }),
};

export const ROLES = [
  {
    label: 'Sales / Account Manager',
    value: 'Sales / Account Manager',
  },
  {
    label: 'Presales',
    value: 'Presales',
  },
  {
    label: 'Product Manager',
    value: 'Product Manager',
  },
  {
    label: 'Customer Success Manager',
    value: 'Customer Success Manager',
  },
  {
    label: 'Revenue',
    value: 'Revenue',
  },
];
export const TEAM_SIZE = [
  {
    label: '1-10',
    value: '1-10',
  },
  {
    label: '11-25',
    value: '11-25',
  },
  {
    label: '26-50',
    value: '26-50',
  },
  {
    label: '51-100',
    value: '51-100',
  },
  {
    label: '100+',
    value: '100+',
  },
];
export const CURRENT_PG_SETUP = [
  { label: "We don't track gaps", value: "We don't track gaps" },
  { label: 'Slack / Teams Channel', value: 'Slack / Teams Channel' },
  { label: 'Sheets / Excels', value: 'Sheets / Excels' },
  { label: 'Product management tools', value: 'Product management tools' },
  { label: 'CRM Custom app', value: 'CRM Custom app' },
  { label: 'Others', value: 'Others' },
];
export const SOURCE = [
  { label: 'Google', value: 'Google' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'PreSales community', value: 'PreSales community' },
  { label: 'G2', value: 'G2' },
  { label: 'Others', value: 'Others' },
];
export const EMBEDS_BY_ROLE = {
  'Sales / Account Manager': [
    'https://www.loom.com/embed/bcc1dc6d7d9e4bc99867c6b8ee3fab32?sid=edc08008-c758-40de-8055-f0e1acf3cec7',
  ],
  Presales: [
    'https://www.loom.com/embed/480cc5c73a0948b298f35403a413e308?sid=70baaa6f-0fdf-4948-8e0c-e0b7b692c965',

    'https://www.loom.com/embed/bcc1dc6d7d9e4bc99867c6b8ee3fab32?sid=edc08008-c758-40de-8055-f0e1acf3cec7',
  ],
  'Product Manager': [
    'https://www.loom.com/embed/bffc9c752e7041a987c63cf0e1abeccb?sid=a925a008-4d24-4d6e-b477-8a6b7685f54e',
  ],
  'Customer Success Manager': [
    'https://www.loom.com/embed/bcc1dc6d7d9e4bc99867c6b8ee3fab32?sid=edc08008-c758-40de-8055-f0e1acf3cec7',
  ],
  Revenue: [
    'https://www.loom.com/embed/69c172c84e024aac8aee9f800de09643?sid=1277353f-e2fc-4c7e-9541-65a68283b397',
  ],
};
