const ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];
//adding metric suffix
const formatCount = (revenue, showDollar) => {
  revenue = revenue * 1;
  if (typeof revenue === 'number' && !isNaN(revenue)) {
    const value = Math.floor(revenue);
    for (let index = 0; index < ranges.length; index++) {
      if (value >= ranges[index].divider) {
        //fixed to 2 decimal digits and replacing  .00 with ''
        let cost = `${
          (value / ranges[index].divider)
            .toFixed(2)
            .replace(/[.,](00)|0$/, '')
            .toString() + ranges[index].suffix
        }`;
        return `${showDollar ? '$' : ''}` + `${cost}`; //adding $
      }
    }
    return `${showDollar ? '$' : ''}` + `${value.toString()}`;
  } else {
    return 'N/A';
  }
};
export { formatCount };
