import Zoom from '../../Icons/Integrations/zoom.png';
import Freshsales from '../../Icons/Integrations/freshsales.png';
import GCal from '../../Icons/Integrations/gcal.png';
import Productboard from '../../Icons/Integrations/productboard.png';
import Zoho from '../../Icons/Integrations/zoho.png';
import Aha from '../../Icons/Integrations/aha.png';
import SalesForce from '../../Icons/Integrations/salesforce.png';
import Outlook from '../../Icons/Integrations/outlook2.png';
import Hubspot from '../../Icons/Integrations/hubspot1.jpg';
import Jira from '../../Icons/Integrations/jira.png';
import { ReactComponent as SalesForceSVG } from '../../Icons/Integrations/Salesforce.svg';
import { ReactComponent as HubspotSVG } from '../../Icons/Integrations/Hubspot.svg';
import { ReactComponent as ZohoSVG } from '../../Icons/Integrations/Zoho.svg';
import { ReactComponent as ZohoBugsSVG } from '../../Icons/zoho-logo.svg';
import { ReactComponent as JIRASVG } from '../../Icons/Integrations/jira.svg';
import { ReactComponent as PRODUCTBOARDVG } from '../../Icons/Integrations/productboard.svg';

import { ActivateButton } from './components';
import authenticationService from '../../_services/authentication.service';
import { AUTH_DOMAIN_NAME, SALESFORCE_URL } from '../../_helpers/axios';
import { MS_ADMIN_SCOPES, MS_USER_SCOPES } from './constants';

export const getSrc = (name) => {
  if (name === 'Salesforce') {
    return SalesForce;
  } else if (name === 'Google Calendar') {
    return GCal;
  } else if (name === 'Jira') {
    return Jira;
  } else if (name === 'Freshsales') {
    return Freshsales;
  } else if (name === 'Outlook') {
    return Outlook;
  } else if (name === 'Hubspot') {
    return Hubspot;
  } else if (name === 'Productboard') {
    return Productboard;
  } else if (name === 'Aha') {
    return Aha;
  } else if (name === 'Zoho') {
    return Zoho;
  } else {
    return Zoom;
  }
};

export const getSVG = (name) => {
  switch (name) {
    case 'Salesforce':
      return <SalesForceSVG />;
    case 'Zoho':
      return <ZohoSVG />;
    case 'Hubspot':
      return <HubspotSVG />;
    case 'Jira':
      return <JIRASVG />;
    case 'Productboard':
      return <PRODUCTBOARDVG />;
    case 'Zoho Bugs':
      return <ZohoBugsSVG width={14} height={14} />;
  }
};

export const showButton = (
  condition,
  activate = { handleClick: () => {}, content: 'Activate', disable: false },
  deactivate = { handleClick: () => {}, content: 'Deactivate', disable: false }
) =>
  condition ? (
    <ActivateButton
      handleClick={deactivate.handleClick}
      content={deactivate.content}
      disabled={deactivate.disable}
    />
  ) : (
    <ActivateButton
      url={activate?.url}
      handleClick={activate.handleClick}
      content={activate.content}
      disabled={activate.disable}
    />
  );

export const getConnectUrl = (integration, userDetails) => {
  const profileData = JSON.parse(localStorage.getItem('user'));
  const userId = profileData?.userId;
  const companyId = profileData?.companyId;
  const DOMAIN = process.env.REACT_APP_IS_DEVELOPMENT
    ? 'https://appdemo.preskale.com'
    : AUTH_DOMAIN_NAME;
  const clientId =
    process.env.REACT_APP_IS_DEVELOPMENT ||
    AUTH_DOMAIN_NAME === 'https://appdemo.preskale.com'
      ? process.env.REACT_APP_APPDEMO_CLIENT_ID
      : AUTH_DOMAIN_NAME === 'https://trial.preskale.com'
      ? process.env.REACT_APP_TRIAL_CLIENT_ID
      : process.env.REACT_APP_DEFAULT_CLIENT_ID;
  const SCOPE = MS_ADMIN_SCOPES;

  const urls = {
    google: `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/tasks.readonly%20https://www.googleapis.com/auth/calendar.events.readonly&access_type=offline&include_granted_scopes=false&response_type=code&state=${userId}:${companyId}&redirect_uri=${DOMAIN}/callback/google&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com&prompt=consent`,

    Jira: `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20manage%3Ajira-webhook%20manage%3Ajira-data-provider%20offline_access&redirect_uri=${DOMAIN}/callback/jira&state=${userId}:${companyId}&response_type=code&prompt=consent`,

    microsoft: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MICROSOFTONLINE_CLIENT_ID}&response_type=code&redirect_uri=${DOMAIN}/callback/microsoft&response_mode=query&scope=https://graph.microsoft.com/${SCOPE}+offline_access&prompt=consent&state=${userId}:${companyId}`,
    Zoom: `https://zoom.us/oauth/signin?client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=https%3A%2F%2F${authenticationService.DOMAIN}.preskale.com%2Fcallback%2Fzoom&response_type=code&state=${userId}:${companyId}`,

    Salesforce: `${SALESFORCE_URL}/services/oauth2/authorize?client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${DOMAIN}/callback/salesforce&response_type=code&scope=refresh_token%20api&state=${userId}:${companyId}`,

    Hubspot: `https://app.hubspot.com/oauth/authorize?client_id=${
      DOMAIN === 'https://app.preskale.com' ||
      DOMAIN === 'https://github.preskale.com'
        ? process.env.REACT_APP_HUBSPOT_APP_CLIENT_ID
        : process.env.REACT_APP_HUBSPOT_CLIENT_ID
    }&redirect_uri=${DOMAIN}/callback/hubspot&scope=crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.owners.read%20crm.objects.line_items.read%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.deals.write&state=${userId}:${companyId}`,

    Zoho: `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.coql.READ+ZohoCRM.modules.all+ZohoCRM.settings.ALL+ZohoCRM.users.all+ZohoCRM.settings.pipeline.READ&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&access_type=offline&redirect_uri=${DOMAIN}/callback/zoho&state=${userId}:${companyId}`,
    ZohoBugs: `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBugTracker.bugs.READ,ZohoBugTracker.portals.READ,ZohoBugTracker.projects.READ&client_id=${process.env.REACT_APP_ZOHOBUGS_CLIENT_ID}&response_type=code&access_type=offline&redirect_uri=${DOMAIN}/callback/zohobugs&state=${userId}:${companyId}&prompt=consent`,
  };

  return urls[integration];
};
