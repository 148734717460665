import React, { useEffect, useState } from 'react';
// import CrossSVG from '../Icons/accounts-close.svg';

function Banner(props){
    
    const [message,setMessage] = useState(props.message);
    const [showBanner,isShowBanner] = useState(props.showBanner);

    // const handleBannerShow = (value) =>{
    //     isShowBanner(value);
    // };

    useEffect(() => {
        setMessage(props.message);
        isShowBanner(props.showBanner);
    }, [props.message,props.showBanner]);

    return (
        <>
        <div className={`data-sync-banner ${showBanner ? 'show-element' : ''}`}>
            <span>{message}</span>
            {/* <span className='banner-close-icon'><CrossSVG onClick={() => handleBannerShow(false)}/></span> */}
        </div>
        </>
    );
}
 export default Banner;