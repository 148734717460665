import React, { useState } from 'react';
import Button from '../../Button';
import { ReactComponent as FilterSVG } from '../../../Icons/NewImage/filter.svg';
import LibraryFilterDrawer from './LibraryFilterDrawer';

const LibraryFilter = ({
  filterFor = 'filter',
  name = 'Filter',
  handleSubmit = () => {},
  handleReset,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const handleClose = () => setShowFilter(false);
  const handleFilterToggle = () => {
    if (!showFilter) {
      // * Close modules filter if already open
      const modulesFilter = document.getElementsByClassName(
        'preskale-modules--filterOverlay'
      );
      if (modulesFilter.length) {
        modulesFilter[0].click();
      }
    }
    setShowFilter((prev) => !prev);
  };
  return (
    <>
      <Button
        type="ghost"
        className="pipeline-filter-btn"
        onClick={handleFilterToggle}
      >
        <div className="flex-align-center gap-default">
          <FilterSVG />
          {name}
        </div>
      </Button>
      {showFilter && (
        <LibraryFilterDrawer
          filterFor={filterFor}
          showFilters={showFilter}
          onCancel={handleClose}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
      )}
    </>
  );
};

export default LibraryFilter;
