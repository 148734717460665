import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Redirect, useLocation } from 'react-router-dom';
import { useCallbackQuery } from '../app/api/dataEngineeringService';

const Callback = ({
  integration,
  text = 'Token generation is in Progress...',
}) => {
  const location = useLocation();
  const { data, error, isSuccess } = useCallbackQuery(
    { integration, search: location?.search },
    {
      skip: !location?.search,
    }
  );

  useEffect(() => {
    if (data) {
      if (data?.token_generated) {
        toast.success(data?.msg);
        const integrations = localStorage.getItem('active_integration_sync')
          ? JSON.parse(localStorage.getItem('active_integration_sync'))
          : [];
        integrations.push(integration?.toUpperCase());
        localStorage.setItem(
          'active_integration_sync',
          JSON.stringify(integrations)
        );
      } else {
        toast.error(data?.msg);
      }
    }
  }, [data]);

  useEffect(() => {
    console.warn('error', error);
    toast.error(error?.data?.error || error?.error);
  }, [error]);

  return isSuccess && data?.token_generated ? (
    <Redirect to="/" />
  ) : (
    <div>
      <h1>{text}</h1>
    </div>
  );
};

export default Callback;
