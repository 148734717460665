import React, { useEffect, useState, useMemo, useRef } from 'react';
import { ReactComponent as EnableSVG } from '../../Icons/Enable.svg';
import { ReactComponent as DisableSVG } from '../../Icons/Disable.svg';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from '../../common/Button';
import {
  saveProductGap,
  useAddProductGapItemMutation,
  useEnableDisableProductGapItemMutation,
  useUpdateProductGapItemStatusMutation,
  useUpdateProductGapItemTitleMutation,
} from '../ProductGap/productGapSlice';
import NoModulePgData from './NoModulePgData';
import { toast } from 'react-toastify';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import { useCheckIsJiraIntegratedQuery } from '../../app/api/usersSlice';
import AgGridTooltip from '../../common/AgGridTooltip';
import AssociateToJiraButton from '../ProductGap/AssociateToJira';
import { useDispatch } from 'react-redux';
import Select from 'react-dropdown-select';
import AgGridLoader from '../../common/AgGridLoader';
import AgGridSelect from '../../common/AgGridSelect';

export default function AddUpdateProductGap({
  productGapId,
  productGapTitle,
  selectedModuleFeature,
  productGapItems,
  loading,
}) {
  const gridRef = useRef(null);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [productGapName, setProductGapName] = useState('');
  const [newProductGapItem, setNewProductGapItem] = useState('');
  const [isSavingProductGap, setIsSavingProductGap] = useState(false);
  const [selectedProductGapType, setSelectedProductGapType] = useState([
    {
      label: 'Enhancements/Bugs',
      value: 'Enhancements/Bugs',
    },
  ]);

  const productGapType = [
    {
      label: 'Enhancements/Bugs',
      value: 'Enhancements/Bugs',
    },
    {
      label: 'New Request',
      value: 'New Request',
    },
  ];
  const [enableDisablePgi, { isLoading: enableDisablePgiLoading }] =
    useEnableDisableProductGapItemMutation();
  const [updatePgiStatus] = useUpdateProductGapItemStatusMutation();
  const [
    updateProductGapItemTitle,
    { isLoading: updateProductGapItemLoading },
  ] = useUpdateProductGapItemTitleMutation();
  const [addProductGapItem, { isLoading: addProductGapItemLoading }] =
    useAddProductGapItemMutation();

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !localStorage.getItem('user'),
  });

  const { data: isJiraIntegrated } = useCheckIsJiraIntegratedQuery();

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 80,
    }),
    []
  );

  const getRowId = (params) => params.data.pgId;

  const _updateData = (params) => () => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.newValue;
    params.api.applyTransaction({ update: [data] });
  };

  const _onFail = (params) => () => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.oldValue;
    params.api.applyTransaction({ update: [data] });
  };

  const columnDefs = [
    {
      field: 'title',
      flex: 2,
      headerName: 'Product Gap Item Title',
      editable: true,
      cellStyle: { color: '#447BF2', fontWeight: 600 },
      valueSetter: (params) => {
        if (params?.newValue) {
          handleUpdateProductGapItemTitle(
            params?.data?.pgId,
            params?.newValue,
            _updateData(params),
            _onFail(params)
          );
          return true;
        }
      },
    },
    {
      field: 'edit',
      width: 60,
      maxWidth: 60,
      headerName: 'Edit Product Gap Item',
      tooltipValueGetter: () => ({ value: 'Edit item' }),
      tooltipComponentParams: () => ({
        value: 'Edit item',
      }),
      cellRenderer: (params) => {
        return (
          <button
            className="height-inherit"
            onClick={() => handleUpdateProductGapItem(params)}
          >
            <EditSVG />
          </button>
        );
      },
    },
    {
      field: 'status',
      editable: (params) => !!params.data.total_usecase,
      cellEditorSelector: () => ({ component: AgGridSelect, popup: true }),
      headerName: 'Status',
      valueGetter: (params) => {
        return params?.data?.status;
      },
      valueSetter: (params) => {
        if (!params.newValue || params.oldValue === params.newValue) {
          return true;
        }
        handleUpdateStatus(params);
        return true;
      },
      singleClickEdit: true,
      cellEditorParams: {
        values: [
          { label: 'Active', value: 'Active' },
          { label: 'Resolved', value: 'Resolved' },
        ],
      },
    },
    {
      field: 'disabled',
      width: 70,
      maxWidth: 70,
      headerName: 'Enable/Disable',
      tooltipValueGetter: (params) => ({
        value: `${
          params?.data?.disabled === 'true' ? 'Enable' : 'Disable'
        } Product gap item`,
      }),
      tooltipComponentParams: (params) => ({
        value: `${
          params?.data?.disabled === 'true' ? 'Enable' : 'Disable'
        } Product gap item`,
      }),
      cellRenderer: (params) => {
        return (
          <button
            className="height-inherit"
            onClick={() => handleUpdateDisable(params)}
          >
            {params?.data?.disabled === 'true' ? <EnableSVG /> : <DisableSVG />}
          </button>
        );
      },
    },
    {
      field: 'jira_title',
      minWidth: 180,
      hide:
        selectedProductGapType[0]?.value === 'Enhancements/Bugs'
          ? configFeatures?.data?.enhancementBugIntegration !== 'jira'
          : configFeatures?.data?.newRequestIntegration !== 'jira',
      headerName: 'Jira',
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        value: !isJiraIntegrated ? 'Jira not connected' : '',
      }),
      cellRenderer: (params) => {
        return (
          <>
            <AssociateToJiraButton
              btnText={
                params?.data?.jira_title
                  ? params?.data?.jira_title
                  : 'Associate Jira ticket'
              }
              isJiraReporterDisable={
                configFeatures?.data?.jiraReporterDisable === true
              }
              btnVariant="text"
              icon={false}
              disabled={!isJiraIntegrated}
              styles={{ fontWeight: 'bold', textDecoration: 'underline' }}
              onOpen={() =>
                dispatch(
                  saveProductGap({
                    id: params?.data?.pgId,
                  })
                )
              }
              onClose={() => dispatch(saveProductGap({}))}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setProductGapName(productGapTitle);
  }, [productGapTitle]);

  const handleAddProductGapItem = () => {
    setIsSavingProductGap(true);
    let duplicatePgItem = productGapItems?.find(
      (item) =>
        item?.title?.toLowerCase()?.trim() ===
        newProductGapItem?.toLowerCase()?.trim()
    );
    if (duplicatePgItem) {
      toast.warning('Product Gap already present!');
      setIsSavingProductGap(false);
      return;
    }
    if (handleValidation()) {
      let productGapItemJson = {
        title: newProductGapItem,
        productGapType: selectedProductGapType[0]?.value,
        feature: {
          value: selectedModuleFeature?.featureId,
          label: selectedModuleFeature?.featureTitle,
        },
        module: {
          value: selectedModuleFeature?.moduleId,
          label: selectedModuleFeature?.moduleTitle,
        },
        productGap: {
          value: productGapId,
          label: productGapName,
        },
      };
      addProductGapItem(productGapItemJson)
        .unwrap()
        .then(() => {
          setNewProductGapItem('');
          toast.success('Product Gap Item added successfully!');
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Product Gap Item not added!');
        })
        .finally(() => {
          setIsSavingProductGap(false);
        });
    } else {
      setIsSavingProductGap(false);
    }
  };

  const _updatePGEnableDisable = (params) => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.data.disabled === 'false' ? 'true' : 'false';
    params.api.applyTransaction({ update: [data] });
  };

  const _rollbackPGEnableDisable = (params) => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.data.disabled;
    params.api.applyTransaction({ update: [data] });
  };

  const handleUpdateDisable = (params) => {
    // * Optimistically update value
    _updatePGEnableDisable(params);
    enableDisablePgi({ pgItemId: params?.data?.pgId })
      .unwrap()
      .then(() => {
        toast.success('Product Gap Item updated successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Product Gap Item not updated!');
        // * Rollback in case of failure
        _rollbackPGEnableDisable(params);
      });
  };

  const _updateStatus = (params) => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params?.newValue;
    params.api.applyTransaction({ update: [data] });
  };

  const _rollbackStatus = (params) => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params?.oldValue;
    params.api.applyTransaction({ update: [data] });
  };

  const handleUpdateStatus = (params) => {
    _updateStatus(params);
    updatePgiStatus({
      pgItemId: params?.data?.pgId,
      status: params?.newValue?.toLowerCase(),
    })
      .unwrap()
      .then(() => {
        toast.success('Product Gap Item status updated successfully!');
      })
      .catch((error) => {
        _rollbackStatus(params);
        console.warn('error', error);
        toast.error('Error: Product Gap Item status not updated!');
      });
  };

  const handleUpdateProductGapItemTitle = (id, title, updateData, onFail) => {
    let duplicatePgItem = productGapItems?.find(
      (item) =>
        item?.title?.toLowerCase()?.trim() === title?.toLowerCase()?.trim()
    );
    if (duplicatePgItem) {
      toast.warning('Product Gap already present!');
      return;
    }
    // * Optimistically update value
    updateData();
    updateProductGapItemTitle({ id, title })
      .unwrap()
      .then(() => {
        toast.success('Product Gap Item updated successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Product Gap Item not updated!');
        // * Rollback in case of failure
        onFail();
      });
  };

  const handleUpdateProductGapItem = (params) => {
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: params.columnApi.getDisplayedCenterColumns()[0].colId,
    });
  };

  const handleValidation = () => {
    let isFormValid = true;
    const formErrors = {};
    if (!newProductGapItem) {
      isFormValid = false;
      formErrors['productGapItem'] = 'Field cannot be empty';
    }
    setFormErrors(formErrors);
    return isFormValid;
  };

  const loadingOverlayComponent = useMemo(() => {
    return AgGridLoader;
  }, []);

  useEffect(() => {
    if (loading) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [loading]);

  return (
    <div className="add-module-pg">
      <div className="form module-pg-header">
        <div className="text-weight-semi-bold">
          <label> Product Gap ({productGapItems?.length}) </label>
          <div className="form-field flex gap-default mt-10">
            <input
              disabled={addProductGapItemLoading}
              className="module-pg-title flex"
              placeholder="Enter new product gap"
              value={newProductGapItem}
              onChange={(event) => setNewProductGapItem(event?.target?.value)}
            />
            <div className="product-gap-type-select">
              <Select
                options={productGapType}
                onChange={(option) => setSelectedProductGapType(option)}
                values={selectedProductGapType}
              />
            </div>
            <Button
              type="outline"
              className="form-button"
              onClick={handleAddProductGapItem}
              disabled={isSavingProductGap || !newProductGapItem}
            >
              Add Product Gap
            </Button>
          </div>
        </div>
        <span className="error">{formErrors['displayName']}</span>
      </div>

      <div className="features-pgi-list">
        {!productGapItems || productGapItems?.length < 1 ? (
          addProductGapItemLoading ||
          updateProductGapItemLoading ||
          enableDisablePgiLoading ? (
            <div className="loader-container">
              <AgGridLoader />
            </div>
          ) : (
            <NoModulePgData message="No Product Gap Items added" />
          )
        ) : (
          <div className="ag-theme-alpine">
            <AgGridReact
              key="pg-table"
              ref={gridRef}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={productGapItems}
              stopEditingWhenCellsLoseFocus={true}
              loadingOverlayComponent={loadingOverlayComponent}
              tooltipShowDelay={1000}
              tooltipHideDelay={2000}
            />
          </div>
        )}
      </div>
    </div>
  );
}
