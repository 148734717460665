import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-dropdown-select';
import Button from '../../common/Button';
import { SuccessItemsRating } from '../../common/Rating';
import { ReactComponent as DeleteSVG } from '../../Icons/Delete.svg';
import RichTextEditor from '../../common/RichTextEditor';
import {
  useLazyFetchModuleByModuleIdQuery,
  useLazyFetchModulesFeaturesQuery,
} from '../ModulesManager/moduleFeaturesSlice';
import { handleFormValidation, noData } from '../../Utils/common';
import {
  useAddSolutionMutation,
  useFetchProductGapAnalyticsQuery,
  useLazyDeleteUsecaseQuery,
  useUpdateSolutionMutation,
} from './solutionSlice';
import mixpanel from 'mixpanel-browser';
import { saveSelectedModule, solutionState } from './solutionSlice';
import { solutionErrors } from '../../Constant/Playbook';
import DOMPurify from 'dompurify';
import { getSelectedTeam } from '../../Pipeline/utils/pipelineUtils';

const AddUsecase = ({
  account,
  handleCancel,
  handleSuccess = () => {},
  usecase,
  moduleId, // * Module id associated with the usecase
  isEdit,
  isAdd,
  fromManageUsecase,
}) => {
  const { selectedModule } = useSelector(solutionState);
  const dispatch = useDispatch();
  const [usecaseComments, setUsecaseComments] = useState(
    isEdit ? usecase?.usecase : ''
  );
  const [rating, setRating] = useState(isEdit ? usecase?.rating : undefined);
  const [associatedFeatures, setAssociatedFeatures] = useState(
    isEdit && usecase?.moduleFeatures?.length > 0 ? usecase?.moduleFeatures : []
  );
  const [moduleTitle, setModuleTitle] = useState(
    isEdit ? usecase?.moduleTitle : ''
  );
  const [allModules, setAllModules] = useState([]);

  const [selectedCompetitor, setSelectedCompetitor] = useState(
    isEdit && usecase?.competitors?.length > 0 ? usecase?.competitors : []
  );
  const [selectedImpactsWhom, setSelectedImpactsWhom] = useState(
    isEdit && usecase?.impactsWhom?.length > 0 ? usecase?.impactsWhom : []
  );

  const [formErrors, setFormErrors] = useState({});
  const [seeAllFeatures, setSeeAllFeatures] = useState(false);
  const numberOfFeatures = 8;

  const [addSolution] = useAddSolutionMutation();
  const [getFeatures, { features }] = useLazyFetchModuleByModuleIdQuery({
    selectFromResult: ({ data }) => {
      const features = useMemo(
        () =>
          data?.data?.moduleFeatures?.filter((feature) => {
            return feature.label !== 'New Feature';
          }),
        [data?.data]
      );
      return { features: features || [], data };
    },
  });
  const [updateSolution] = useUpdateSolutionMutation();
  const [moduleFeature] = useLazyFetchModulesFeaturesQuery();
  const [deleteUsecase] = useLazyDeleteUsecaseQuery();

  const { data: pgAnalyticsData } = useFetchProductGapAnalyticsQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return { data: data?.data };
      },
    }
  );

  const validateField = (fieldName, fieldValue) => {
    if ((Array.isArray(fieldValue) && !fieldValue.length) || !fieldValue) {
      setFormErrors((prev) => {
        const errors = { ...prev };
        errors[fieldName] = solutionErrors[fieldName];
        return errors;
      });
    } else {
      setFormErrors((prev) => {
        const errors = { ...prev };
        errors[fieldName] = '';
        return errors;
      });
    }
  };

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    if (
      Array.isArray(selectedModule) &&
      selectedModule.length &&
      selectedModule[0].value
    ) {
      getFeatures({
        moduleId: selectedModule[0].value,
      });
    }
  }, [selectedModule]);

  useEffect(() => {
    validateField('associatedFeatures', associatedFeatures);
  }, [associatedFeatures]);

  const handleUsecaseCancel = () => {
    setFormErrors({});
    setAssociatedFeatures([]);
    handleCancel();
  };

  const handleDeleteUsecase = () => {
    deleteUsecase({
      id: usecase?.id,
      accountId: account?.accountId,
      dealId: account?.dealId,
    })
      .unwrap()
      .then(() => {
        handleSuccess(selectedModule);
        handleUsecaseCancel();
      });
  };

  const selectedTeam = getSelectedTeam();

  const getModules = () => {
    moduleFeature({ selectedTeam })
      .unwrap()
      .then((res) => {
        let modules = res?.map((module) => {
          return {
            value: module.id,
            label: module.moduleTitle,
            disabled: module.disabled,
          };
        });
        setAllModules(modules);
        if (!isAdd) selectModule(modules);
      });
  };

  const getFeaturesByModule = (moduleValue) => {
    if (!moduleValue) {
      console.warn('Cannot get features. No selected module!');
      return;
    }
    getFeatures({
      moduleId: moduleValue,
    });
  };

  const addFeature = (featureId, featureTitle) => {
    let index = associatedFeatures.findIndex(
      (feature) => feature.value === featureId
    );

    const clonedAssociatedFeatures = JSON.parse(
      JSON.stringify(associatedFeatures)
    );

    if (index !== -1) {
      clonedAssociatedFeatures.splice(index, 1);
    } else {
      clonedAssociatedFeatures.push({ value: featureId, label: featureTitle });
    }
    setAssociatedFeatures([...clonedAssociatedFeatures]);
  };

  const selectModule = (moduleList) => {
    let index = moduleList.findIndex(
      (module) =>
        module.value === (selectedModule[0] && selectedModule[0].value) || null
    );
    if (fromManageUsecase && !moduleList[index]?.disabled) {
      dispatch(saveSelectedModule([moduleList[index]]));
    } else if (!fromManageUsecase) {
      dispatch(saveSelectedModule([moduleList[index]]));
    }
    if (moduleList[index]) {
      setModuleTitle(moduleList[index]?.label);
    }
  };

  const onModuleChange = (option) => {
    getFeaturesByModule(option[0]?.value);
    dispatch(saveSelectedModule(option));
    validateField('selectedModule', option);
    if (option[0]?.value === moduleId) {
      // * Checking if the selected module is the same as the module originally associated with the usecase
      setAssociatedFeatures(
        isEdit && usecase?.moduleFeatures?.length > 0
          ? usecase?.moduleFeatures
          : []
      );
      return;
    }
    setAssociatedFeatures([]);
  };

  const handleAddUpdateUsecase = () => {
    const formValidation = handleFormValidation(
      features && features.length > 0
        ? {
            selectedModule,
            associatedFeatures,
            rating,
            usecaseComments:
              usecaseComments === '<p><br></p>' ? null : usecaseComments,
          }
        : {
            selectedModule,
            rating,
            usecaseComments:
              usecaseComments === '<p><br></p>' ? null : usecaseComments,
          },
      null,
      null
    );

    if (formValidation?.isFormValid) {
      if (isEdit) {
        handleEditUsecase();
      } else {
        handleAddUsecase();
      }
    } else {
      for (let key in formValidation?.formErrors) {
        if (solutionErrors[key]) {
          formValidation.formErrors[key] = solutionErrors[key];
        }
      }
      formValidation?.formErrors && setFormErrors(formValidation?.formErrors);
    }
  };

  const handleEditUsecase = () => {
    if (!selectedModule[0].value) {
      console.warn('Cannot edit usecase. No selected module!');
      return;
    }
    updateSolution({
      body: {
        id: usecase?.id,
        usecase: usecaseComments,
        moduleId: selectedModule[0].value,
        moduleTitle: selectedModule[0]?.label
          ? selectedModule[0]?.label
          : moduleTitle,
        moduleFeatures: associatedFeatures,
        rating: rating,
        competitors: selectedCompetitor,
        impactsWhom: selectedImpactsWhom,
        accountId: account?.accountId,
        dealId: account?.dealId,
      },
    })
      .unwrap()
      .then(() => {
        handleSuccess(selectedModule);
        handleUsecaseCancel();
      });
  };

  const handleAddUsecase = () => {
    if (
      !Array.isArray(selectedModule) ||
      !selectedModule[0] ||
      !selectedModule[0].value ||
      !selectedModule[0].label
    ) {
      console.warn('Cannot add module. No selected module!');
      return;
    }
    addSolution({
      usecase: usecaseComments,
      accountId: account?.accountId,
      dealId: account?.dealId,
      moduleId: selectedModule[0].value,
      moduleTitle: selectedModule[0]?.label
        ? selectedModule[0]?.label
        : moduleTitle,
      moduleFeatures: associatedFeatures,
      rating: rating,
      competitors: selectedCompetitor,
      impactsWhom: selectedImpactsWhom,
    })
      .unwrap()
      .then(() => {
        handleSuccess(selectedModule);
        handleUsecaseCancel();
        try {
          mixpanel.track('solutions_added', { event: 'Solutions Added' });
        } catch {
          console.error('Cannot add event to mixpanel');
        }
      });
  };

  return (
    <div className="modal-left-pane form">
      <div className="modal-header pg-header">
        <h4 className="modal-title-pg">Solutions</h4>
      </div>
      <div className="modal-body">
        <div className="required-field">
          <label className="pg-label"> Module </label>
          <div className="module-select form">
            <div className="form-field">
              <Select
                options={allModules}
                values={selectedModule}
                onChange={onModuleChange}
                keepSelectedInList={false}
              />
              <span className="error">{formErrors['selectedModule']}</span>
            </div>
          </div>
        </div>
        <div className="usecase required-field">
          <div className="usecase-heading mb-10">Use-case </div>
          <div className="usecase-editor">
            <RichTextEditor
              value={usecaseComments}
              onChange={(obj) => {
                const comments = DOMPurify.sanitize(obj);
                setUsecaseComments(comments);
                validateField(
                  'usecaseComments',
                  comments === '<p><br></p>' ? null : comments
                );
              }}
            />
          </div>
        </div>
        <div className="error">{formErrors.usecaseComments}</div>
        <div
          className={`associated-features form ${
            seeAllFeatures ? 'mb-10' : ''
          }`}
        >
          <div className="flex">
            <div className="feature-heading required-field mb-5">
              {' '}
              {features && features?.length > 0 && (
                <>
                  <label>Associated Features </label>
                  <span className="ml-10">
                    ({associatedFeatures?.length}/{features?.length})
                  </span>
                </>
              )}
            </div>
            {features && features?.length > numberOfFeatures && (
              <div
                className="pointer text-primary ml-auto"
                onClick={() => setSeeAllFeatures(!seeAllFeatures)}
              >
                {seeAllFeatures ? 'Show less...' : 'Show all features'}
              </div>
            )}
          </div>
          <div
            className={`feature-parent form-field vertical-scroll ${
              seeAllFeatures && 'feature-parent-height'
            }`}
          >
            {features &&
              features?.map(
                (feature, i) =>
                  !feature.disabled && (
                    <span
                      key={`feature${feature?.value}`}
                      className={`feature m-5 b-4 p-4 pointer
                      ${
                        associatedFeatures.find(
                          (ft) => ft?.value === feature?.value
                        )
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => addFeature(feature?.value, feature?.label)}
                    >
                      {feature?.label}
                    </span>
                  )
              )}
            <div className="error">
              {features &&
                features?.length > 0 &&
                formErrors['associatedFeatures']}
            </div>
          </div>
        </div>

        {/* if features length is more than numberOfFeatures, and for the expanded view we're hiding below divs. */}

        {!seeAllFeatures && (
          <div className="mb-10">
            <span className="flex-align-center pointer flex-wrap required-field">
              <label className="add-icon flex mr-20">
                Solution Pulse Score{' '}
              </label>
              <span className="use-case--rating">
                <SuccessItemsRating
                  updateRating={rating}
                  test={(rating) => {
                    setRating(rating);
                    validateField('rating', rating);
                  }}
                />
              </span>
            </span>
            <div className="error">{formErrors.rating}</div>
          </div>
        )}

        {!seeAllFeatures && (
          <div className="form">
            <div className="pg-additional-details flex flex-wrap justify-space-between">
              <div className="form-field">
                <label className="solution-label">Competitors</label>
                <Select
                  create={true}
                  noDataRenderer={() => noData()}
                  options={pgAnalyticsData?.competitors}
                  values={selectedCompetitor}
                  onChange={(option) => setSelectedCompetitor(option)}
                  multi={true}
                  dropdownPosition="auto"
                />
              </div>

              <div className="form-field">
                <label className="solution-label"> Linked Persona </label>
                <Select
                  create={true}
                  noDataRenderer={() => noData()}
                  options={pgAnalyticsData?.impactsWhom}
                  values={selectedImpactsWhom}
                  onChange={(option) => setSelectedImpactsWhom(option)}
                  multi={true}
                  dropdownPosition="auto"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {!seeAllFeatures && (
        <div className="action-btn flex-justify-end mt-10">
          {isEdit && (
            <div className="mr-auto delete-icon">
              <Button
                icon
                className="btn-icon-only"
                type="ghost"
                onClick={handleDeleteUsecase}
              >
                <DeleteSVG />
              </Button>
            </div>
          )}

          <Button
            id="task-popup-cancel-button"
            type="secondary"
            onClick={handleUsecaseCancel}
          >
            Cancel
          </Button>
          <Button
            id="task-popup-add-button"
            type="primary"
            className="ml-5"
            onClick={handleAddUpdateUsecase}
          >
            {isEdit ? 'Update Use-case' : 'Add Use-case'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddUsecase;
