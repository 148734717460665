import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Salesforce from '../Salesforce/index';

const SalesforceLayout = () => {
  return (
    <Router>
      <Helmet titleTemplate="%s | Preskale" defaultTitle="Preskale">
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://preskale.com" />
      </Helmet>
      <div className="salesforce-layout--container ml-0">
        <section className="main-container">
          <Switch>
            <Route
              exact
              path="/salesforce/:accountId/:dealId"
              headerTitle="Salesforce Page"
            >
              <Salesforce />
            </Route>
          </Switch>
        </section>
      </div>
    </Router>
  );
};
export default SalesforceLayout;
