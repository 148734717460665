export const CONFIG_REPORTS = {
  is_performance_utilisation_enabled: {
    key: 'performance_and_utilization',
    label: 'Performance and Utilisation',
  },
  is_influence_enabled: {
    key: 'influence',
    label: 'Influence',
  },
  is_manager_analytics_enabled: {
    key: 'manager_analytics',
    label: 'Manager Dashboard',
  },
  is_product_gap_enabled: {
    key: 'product_gap',
    label: 'Product Gap',
  },
  is_custom_section_enabled: {
    key: 'custom_sections',
    label: 'Custom Sections',
  },
  is_calendar_enabled: {
    key: 'calendar',
    label: 'Calendar',
  },
  is_deal_velocity_reports_enabled: {
    key: 'deal_velocity_reports',
    label: 'Deal Velocity Reports',
  },
  is_productgap_analytics_enabled: {
    key: 'productgap_analytics',
    label: 'Product Gap Analytics',
  },
  is_solution_analytics_enabled: {
    key: 'solution_analytics',
    label: 'Solution Analytics',
  },
  is_calendar_analytics_enabled: {
    key: 'calendar_analytics',
    label: 'Calendar Analytics',
  },
};
