import React from 'react';
import { useState } from 'react';
import BestResourceView from './BestResourceView';
import DealInformationContainer from './DealInformationContainer';
import UtilizationTable from './UtilizationTable';
import {
  useFetchCustomSectionByIdQuery,
  useFetchResourceRequestsQuery,
} from './RAMSlice';
import { useEffect } from 'react';
import Loader from '../../common/Loader';
import CustomForm from '../../features/CustomSections/CustomForm';
import PageLayout from '../../Layout/PageLayout';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import DealSummaryCard from '../../Accounts/commons/DealSummaryCard';

const RequestAssistanceManager = () => {
  const [activeCard, setActiveCard] = useState({});
  const [filteredRequests, setFilteredRequests] = useState([]);
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const [crmType, setCrmType] = useState('');
  useEffect(() => {
    if (configFeatures) {
      setCrmType(configFeatures.data?.crm?.toUpperCase());
    }
  }, [configFeatures]);

  const { data: resourceRequests, isFetching: requestFetching } =
    useFetchResourceRequestsQuery(
      { crmType },
      {
        skip: !crmType,
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ data, isFetching }) => ({
          data: data?.data && Array.isArray(data.data) ? data.data : [],
          isFetching,
        }),
      }
    );

  const params = { sectionId: activeCard.sectionId };

  const { data: customSection } = useFetchCustomSectionByIdQuery(params, {
    skip: !activeCard?.sectionId,
    selectFromResult: ({ data }) => ({
      data: data && data.data ? data.data : [],
    }),
  });

  const handleResourceFilter = (dealId) => {
    const newlyFilteredRequests = resourceRequests.filter(
      (requestData) => requestData.dealId !== dealId
    );
    setFilteredRequests(newlyFilteredRequests);
    setActiveCard(
      newlyFilteredRequests && newlyFilteredRequests.length
        ? newlyFilteredRequests[0]
        : {}
    );
  };

  const onCardClick = (deal) => {
    setActiveCard(deal);
  };

  useEffect(() => {
    if (resourceRequests && resourceRequests.length) {
      setActiveCard(resourceRequests[0]);
      setFilteredRequests(resourceRequests);
    }
  }, [resourceRequests]);

  return (
    <div className="assistance-manager">
      {requestFetching ? (
        <Loader />
      ) : (
        <PageLayout
          contentClassname="request-assistance"
          sidebarSize="M"
          sidebar={
            <div className="left-pane">
              <div className="heading-container">
                <h2 className="heading_level4 text-dark header">
                  New Resource Requests
                </h2>
              </div>
              <div className="content-container">
                {filteredRequests && filteredRequests.length
                  ? filteredRequests.map((requestData) => {
                      return (
                        <DealSummaryCard
                          key={requestData.id}
                          dealData={requestData}
                          activeCard={activeCard}
                          onCardClick={onCardClick}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          }
        >
          <div className="main-container">
            <BestResourceView
              activeCard={activeCard}
              handleResourceFilter={handleResourceFilter}
              crmType={crmType}
            />
            <DealInformationContainer activeCard={activeCard} />
            <UtilizationTable ownerId={activeCard.ownerId} />
            {activeCard &&
            customSection &&
            Object.keys(customSection).length ? (
              <CustomForm
                section={customSection}
                deal={activeCard}
                sort={false}
                hiddenView={true}
                readOnly={true}
              />
            ) : null}
          </div>
        </PageLayout>
      )}
    </div>
  );
};

export default RequestAssistanceManager;
