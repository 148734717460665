import { useState, useEffect, useRef } from 'react';
import useDebounce from './useDebounce';

export const useIntersectionObserver = (refs, active) => {
  const [activeComponent, setActiveComponent] = useState(null);
  const observerRef = useRef(null);
  const debouncedElement = useDebounce(activeComponent, 300);

  useEffect(() => {
    try {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          const entry = entries.filter((entry) => entry.isIntersecting);
          let activeTab;
          entry.filter((data) => {
            if (data.target.id === active) {
              activeTab = data.target.id;
            }
          });
          if (activeTab) {
            setActiveComponent(activeTab);
          } else if (entry && entry?.length) {
            setActiveComponent(entry[0]?.target?.id);
          }
        },
        { threshold: 0.8 }
      );
    } catch (error) {
      console.error('error while handling intersection : ', error);
    }
  }, [active]);

  useEffect(() => {
    try {
      for (const [section, ref] of Object.entries(refs)) {
        if (ref.current) {
          observerRef?.current?.observe(ref.current);
        }
      }
      return () => {
        observerRef.current.disconnect();
      };
    } catch (error) {
      console.error('error while setting observer : ', error);
    }
  }, [refs]);

  return debouncedElement;
};
