export const getUsersLabelValuePair = (userList) => {
  const userData = userList?.map((user) => ({
    label: user?.name,
    value: user?.userId,
    sendMeetingNotification: user?.sendMeetingNotification,
    sendTaskNotification: user?.sendTaskNotification,
    sendMonthlyMeetingNotification: user?.sendMonthlyMeetingNotification,
    sendDealBreakerNotification: user?.sendDealBreakerNotification,
    includeInMeetingTable: user?.includeInMeetingTable,
    reports: user?.reports,
  }));
  return userData;
};

export const getOptedOutcomesLabelValuePair = (outcomesForNotification) => {
  const optedOutcomesLabelValuePair = outcomesForNotification?.data?.map(
    (data) => {
      return {
        label: data?.title,
        value: data?.id,
      };
    }
  );
  return optedOutcomesLabelValuePair;
};
