import React from 'react';
import { Button, Flex, Space, Typography } from 'antd';
import Loader from '../../common/Loader';
import { formatCount } from '../../Utils/Revenue';
import { createMarkup } from '../../Utils/common';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import { ProductGapRatingReadOnly } from '../../common/Rating';
import { ReactComponent as RedirectSVG } from '../../Icons/NewImage/redirect.svg';

const { Text } = Typography;

const PgDrawerRightPane = ({
  productGapUseCases,
  productGapUseCasesLoading,
  selectedOpportunity,
  searchQuery,
  setActiveDeal,
}) => {
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  return (
    <div className="pg-usecases-right-panel">
      {productGapUseCasesLoading ? (
        <Loader />
      ) : (
        <>
          <div className="pg-item-tab--pgUseCases pg-drawer-item-height">
            <div className="pgItems">
              {Array.isArray(productGapUseCases?.data) &&
                productGapUseCases?.data
                  .filter((productGap) => {
                    const productGapItem = selectedOpportunity
                      ? productGap.dealId === selectedOpportunity
                      : true;

                    const useCaseContainsQuery =
                      !searchQuery ||
                      productGap?.useCase
                        .toLowerCase()
                        .includes(searchQuery?.toLowerCase());

                    return productGapItem && useCaseContainsQuery;
                  })
                  ?.map((productGap) => {
                    return (
                      <div key={productGap.id} className="criteria-list">
                        <Flex>
                          <Space
                            onClick={() => setActiveDeal(productGap?.dealId)}
                          >
                            <Text type="primary" strong className="pointer">
                              {productGap?.dealName}
                            </Text>
                            <Button
                              icon={<RedirectSVG />}
                              className="br-none fill-colors-primary"
                            />
                          </Space>
                          <Flex className="mr-10 ml-auto" gap={20}>
                            <div className="usecase-footer">
                              <span className="usecase-rating">
                                <ProductGapRatingReadOnly
                                  rating={productGap.rating}
                                />
                              </span>
                            </div>
                            <Text>
                              {`$${formatCount(productGap?.dealValue)}`}
                            </Text>
                            <Text strong>
                              {userMap[productGap?.createdBy] &&
                                userMap[productGap?.createdBy].name}{' '}
                            </Text>
                          </Flex>
                        </Flex>
                        <div
                          className="rtf-text use-cases-comments"
                          dangerouslySetInnerHTML={createMarkup(
                            productGap?.useCase
                          )}
                        ></div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PgDrawerRightPane;
