import { filterSelectStyles } from '../../Constant/ProuductGapDropdowns';
import { DATE_RANGE } from '../../Constant/FilterOperators';

export const STATUS_COLOUR = {
  OVERDUE: {
    color: 'warning',
    label: 'Overdue',
  },
  OPEN: {
    color: 'primary',
    label: 'Open',
  },
  COMPLETED: {
    color: 'success',
    label: 'Completed',
  },
};

export const DATE_RANGE_ALL = [
  {
    value: null,
    label: 'All',
  },
  ...DATE_RANGE,
];

export const SELECT_STYLES = {
  ...filterSelectStyles,
  control: (baseStyles) => ({
    ...baseStyles,
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    minHeight: '30px',
    height: '30px',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: '0 6px',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '2px 0 2px 0',
  }),
};
