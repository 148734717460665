import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

export const fetchInactiveIntegrations = (
  service,
  handleSuccess,
  handleError
) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.users.getInactiveConnections,
    onSuccess: handleSuccess,
    onError: handleError,
  });

export const checkIsJiraIntegrated = (service, handleSuccess, handleError) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.users.checkJiraIntegration,
    onSuccess: handleSuccess,
    onError: handleError,
  });
