import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AddUser from '../Company/AddUser';
import { FeatureAllocation } from './FeatureAllocation';
import TeamsManagement from './TeamsManagement';

export const AdminOpsTabs = ({ companyData, companyStatus }) => {
  return (
    <Tabs>
      <TabList className="react-tabs__tab-list">
        <Tab>Preskale Features</Tab>
        <Tab>User Management</Tab>
        <Tab>Teams Management</Tab>
      </TabList>

      <TabPanel>
        <FeatureAllocation companyId={companyData?.id} />
      </TabPanel>

      <TabPanel>
        <AddUser
          adminOps={true}
          companyId={companyData?.id}
          status={companyStatus?.value}
        />
      </TabPanel>

      <TabPanel>
        <TeamsManagement companyId={companyData?.id} />
      </TabPanel>
    </Tabs>
  );
};
