import React, { useState } from 'react';
import Button from '../../common/Button';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';

const SelectAllNotification = ({
  selected,
  allSelected,
  onSelect,
  totalRows,
  clearSelection,
  handleClose = () => {},
  cancelUpdate = () => {},
  disableClose = () => {},
}) => {
  const [warningTriggered, setWarningTriggered] = useState(false);

  const handleClick = () => {
    const [currentSelection, proceed] = onSelect();
    if (currentSelection()) {
      setWarningTriggered(true);
      disableClose(true);
    } else {
      proceed();
    }
  };

  const handleProceed = () => {
    const [_, proceed] = onSelect();
    setWarningTriggered(false);
    disableClose(false);
    proceed();
  };

  const handleClear = () => {
    clearSelection();
  };

  const handleCancel = () => {
    cancelUpdate();
    handleClose();
    setWarningTriggered(false);
    disableClose(false);
  };

  const noOfItems = warningTriggered || allSelected ? totalRows : selected;

  return (
    <div className="BulkSelectAllNotification">
      {console.log(
        warningTriggered
          ? 'cancelUpdate'
          : allSelected
          ? 'handleClear'
          : 'handleClick'
      )}
      {console.log('allSelected', allSelected)}
      <span className="flex-align-center justify-space-between">
        <span className="flex-align-center">
          {noOfItems} {noOfItems === 1 ? 'item' : 'items'} selected.{' '}
          <Button
            type="link-underline"
            onClick={
              warningTriggered
                ? handleCancel
                : allSelected
                ? handleClear
                : handleClick
            }
          >
            {warningTriggered || allSelected
              ? 'Clear selection'
              : `Select all ${totalRows} items?`}
          </Button>
        </span>
        {!warningTriggered && (
          <Button
            icon={true}
            type="ghost"
            className="btn-icon-only drawer-btn"
            onClick={handleClose}
          >
            <CrossSVG />
          </Button>
        )}
      </span>
      {warningTriggered && (
        <div className="">
          <em className="warning-text">
            Note: Currently selected items will be discarded
          </em>
          <div className="confirm-btn-container">
            <Button type="primary" onClick={handleProceed}>
              Confirm Selection
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectAllNotification;
