import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import { colourStyles } from '../Constant/MultiSelectStylesAndComponents';

const AgGridSelect = forwardRef((props, ref) => {
  const selectedValueRef = useRef();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 200,
    }),
    ...colourStyles,
  };

  const handleValueChange = (option) => {
    selectedValueRef.current = option.value;
    props.stopEditing();
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValueRef?.current;
    },
  }));

  return (
    <Select
      value={{ label: props.value, value: props.value }}
      onChange={handleValueChange}
      options={props.values}
      styles={customStyles}
      menuPosition="fixed"
    />
  );
});
export default AgGridSelect;
