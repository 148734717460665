import React, { Suspense, lazy } from 'react';
import ReactModal from 'react-modal';
import DualRingLoader from './DualRingLoader';
const CalendarModalContent = lazy(() => import('./CalendarModalContent'));

const CalendarModal = ({
  showModal,
  closeModal,
  data,
  index = 0,
  hideHeader,
  users,
}) => {
  return (
    <ReactModal
      isOpen={showModal}
      className="Modal confirm-box calendar-modal"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <Suspense
        fallback={
          <div className="loader-container--suspense">
            <DualRingLoader dark />
          </div>
        }
      >
        <CalendarModalContent
          closeModal={closeModal}
          data={data}
          index={index}
          hideHeader={hideHeader}
          users={users}
        />
      </Suspense>
    </ReactModal>
  );
};

export default CalendarModal;
