import React from 'react';
import { NavLink } from 'react-router-dom';

const NavProfileMenu = React.forwardRef(
  ({ profileData, companyInfo, logout }, ref) => {
    return (
      <div ref={ref} className="profile-dropdown">
        <div className="profile-name">
          <div className="title">{profileData?.userName}</div>
          <div className="title">
            {companyInfo.response?.data && companyInfo.response?.data?.name}
          </div>
          <div className="sub-title"> {profileData?.email}</div>
        </div>

        <div className="profile-action">
          <NavLink to="/user/profile"> Profile Edit </NavLink>
          {/* {profileData?.roles?.includes('admin') ? (
            <NavLink to="/configurecompany"> Configure Company </NavLink>
          ) : null} */}
          <a href="#" onClick={logout}>
            {' '}
            Signout{' '}
          </a>
        </div>
      </div>
    );
  }
);

export default NavProfileMenu;
