// import { createEntityAdapter } from '@reduxjs/toolkit';
import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { logout } from '../../Authorize/authSlice';
import { extendedAccountsSlice } from '../../Accounts/accountsSlice';

const productGapAdapter = createEntityAdapter();
const initialState = productGapAdapter.getInitialState();

const productGapSliceInitialState = {
  selectedProductGapType: [],
  selectedModule: [],
  selectedFeature: [],
  selectedProductGap: {},
  title: '',
  toRetainValue: false,
  newPGTitle: '',
  refetchPgUsecases: false,
};

const productGapSlice = createSlice({
  name: 'productGap',
  initialState: productGapSliceInitialState,
  reducers: {
    saveSelectedProductGapType(state, action) {
      state.selectedProductGapType = action.payload;
    },
    saveModule(state, action) {
      state.selectedModule = action.payload;
    },
    saveFeature(state, action) {
      state.selectedFeature = action.payload;
    },
    saveProductGap(state, action) {
      state.selectedProductGap = action.payload;
    },
    saveProductGapItem(state, action) {
      state.title = action.payload;
    },
    saveNewPGTitle(state, action) {
      // * New PG title before submission
      state.newPGTitle = action.payload;
    },
    saveRetainValueBoolean(state, action) {
      state.toRetainValue = action.payload;
    },
    saveRefetchPgUsecase(state, action) {
      state.refetchPgUsecases = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = productGapSliceInitialState;
      return state;
    });
  },
});

const pessimisticAddProductGap = async (body, { dispatch, queryFulfilled }) => {
  const bodyCopy = { ...body };
  // * Optimistic updates
  const patchResult = dispatch(
    extendedAccountsSlice.util.updateQueryData(
      'fetchAccountSummary',
      {
        dealId: body?.dealId,
      },
      (draft) => {
        // console.log('draft.totalProductGaps1', draft.totalProductGaps);
        let pgPulse = draft.totalProductGaps * draft.productGapPulseScore;
        draft.totalProductGaps = draft.totalProductGaps + 1;
        if (body?.rating) {
          // console.log('pgPulse', pgPulse);
          // console.log('body.rating', body.rating);
          // console.log('draft.totalProductGaps2', draft.totalProductGaps);
          // console.log(
          //   'cal',
          //   Math.floor((pgPulse + body.rating) / draft.totalProductGaps)
          // );
          draft.productGapPulseScore = Math.floor(
            (pgPulse + body.rating) / draft.totalProductGaps
          );
        }
      }
    )
  );
  try {
    const { data } = await queryFulfilled;
    // * Pessimistic updates
    dispatch(
      extendedProductGapSlice.util.updateQueryData(
        'fetchAllProductGaps',
        {
          dealId: body?.dealId,
        },
        (draft) => {
          const pgItemData = [
            {
              feature: bodyCopy.feature,
              module: bodyCopy.module,
              productGap: bodyCopy.productGap,
              title: bodyCopy.title,
              jiraIssueKey: bodyCopy.jiraIssueKey,
              productGapType: bodyCopy.productGapType,
            },
          ];
          bodyCopy['pgItem'] = pgItemData;
          bodyCopy['id'] = data?.data?.id;
          bodyCopy['createdDate'] = data?.data?.createdDate;
          bodyCopy['updatedDate'] = data?.data?.updatedDate;
          bodyCopy['rating'] = data?.data?.rating;
          draft.data = [...draft.data, bodyCopy];
        }
      )
    );
  } catch {
    patchResult.undo();
  }
};

const updateProductGapResult = async (body, { dispatch, queryFulfilled }) => {
  const patchResult = dispatch(
    extendedProductGapSlice.util.updateQueryData(
      'fetchAllProductGaps',
      {
        dealId: body?.dealId,
      },
      (draft) => {
        const updated =
          Array.isArray(draft?.data) &&
          draft.data.map((productGap) => {
            if (productGap.id === body?.id) {
              productGap.useCase = body.useCase;
              productGap.plainText = body.plainText;
              productGap.rating = body.rating;
              productGap.competitors = body.competitors;
              productGap.impactsWhom = body.impactsWhom;
            }
            return productGap;
          });
        draft.data = updated;
      }
    )
  );
  try {
    await queryFulfilled;
  } catch {
    patchResult.undo();
  }
};

export const extendedProductGapSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchItemsByModule: builder.query({
      query: ({ dealId, moduleId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.getPgItemsUnderModule}?deal_id=${dealId}&module_id=${moduleId}`;
      },
    }),

    addProductGap: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.add,
        method: 'POST',
        body,
      }),
      onQueryStarted: pessimisticAddProductGap,
      invalidatesTags: (result) => [
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        {
          type: 'AccountSummary',
          id: `${result?.data?.dealId}`,
        },
        { type: 'Solutions', id: 'competitors-persona' },
      ],
    }),

    updateProductGap: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.update,
        method: 'POST',
        body,
      }),
      onQueryStarted: updateProductGapResult,
      invalidatesTags: (result, err, arg) => [
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
        { type: 'Solutions', id: 'competitors-persona' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
      ],
    }),

    deleteProductGap: builder.query({
      query: ({ id, dealId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.delete}?id=${id}&deal_id=${dealId}`;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedProductGapSlice.util.updateQueryData(
            'fetchAllProductGaps',
            {
              dealId: body?.dealId,
            },
            (draft) => {
              draft.data =
                Array.isArray(draft?.data) &&
                draft.data.filter((item) => body?.id !== item.id);
            }
          )
        );
        const tagsToInvalidate = [
          {
            type: 'AccountSummary',
            id: `${body?.dealId}`,
          },
        ];
        try {
          await queryFulfilled;
          dispatch(businessService.util.invalidateTags(tagsToInvalidate));
        } catch {
          patchResult.undo();
          dispatch(businessService.util.invalidateTags(tagsToInvalidate));
        }
      },
    }),

    fetchAllProductGaps: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.getByAccount}?deal_id=${dealId}`;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'ProductGap', id: 'allPg' },
        {
          type: 'ProductGap',
          id: `deal_id=${arg?.dealId}`,
        },
      ],
    }),

    fetchProductGapByFeature: builder.query({
      query: ({ moduleId, featureId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.getPgUnderFeatures}?module_id=${moduleId}&feature_id=${featureId}`;
      },
    }),

    fetchRecentlyAddedPgItems: builder.query({
      query: ({ selectedTeam }) => {
        return `${
          URLS_BUSINESS_SERVICE.productGap.getRecentlyAddedPgItems
        }?team_id=${selectedTeam ? selectedTeam?.join(',') : ''}`;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'ProductGap', id: 'recentlyAddedPg' },
      ],
    }),

    fetchJiraProjects: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.productGap.getJiraProjects;
      },
    }),

    fetchJiraStatus: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.productGap.getJiraStatus;
      },
    }),

    fetchJiraIssueTypes: builder.query({
      query: ({ projectId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.getJiraIssueTypes}?project_id=${projectId}`;
      },
    }),

    fetchJiraIssue: builder.query({
      query: ({ issueId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.getJiraIssue}?jira_issue_id=${issueId}`;
      },
    }),

    fetchJiraIssueComments: builder.query({
      query: ({ issueId }) => {
        return `${URLS_BUSINESS_SERVICE.productGap.getJiraCommentsForIssue}?jira_issue_id=${issueId}`;
      },
    }),

    fetchJiraIssuesOnSearch: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.getJiraIssuesFromSearch,
        method: 'POST',
        body,
      }),
    }),

    fetchJiraUsers: builder.query({
      query: () => URLS_BUSINESS_SERVICE.productGap.getJiraUsers,
    }),

    addJiraProductGap: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.addJira,
        method: 'POST',
        body,
      }),
      onQueryStarted: pessimisticAddProductGap,
      invalidatesTags: (result, err, arg) => [
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
      ],
    }),

    updateJiraProductGap: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.updateJira,
        method: 'POST',
        body,
      }),
      onQueryStarted: updateProductGapResult,
      invalidatesTags: (result, err, arg) => [
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
      ],
    }),

    getProductGapModulesAndItems: builder.query({
      query: () => URLS_BUSINESS_SERVICE.productGap.modulesAndItems,
      providesTags: () => [
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
      ],
      transformResponse: (responseData) => {
        const normalizedData = productGapAdapter.setAll(
          initialState,
          responseData?.data
        );
        return { normalizedData, data: responseData?.data };
      },
    }),

    getPGItemsByFeatureList: builder.query({
      query: ({ featureIDList }) =>
        `${URLS_BUSINESS_SERVICE.productGap.getPGItemByFeatureId}?feature_id=${featureIDList}`,
      providesTags: () => [{ type: 'ProductGap', id: 'getPGItemsByFeature' }],
    }),

    getRevenueBlocked: builder.query({
      query: (id) =>
        `${URLS_BUSINESS_SERVICE.productGap.getRevenueBlocked}?product_gap_item_id=${id}`,
      transformResponse: (res) =>
        res?.data ? res.data?.revenue_blocked : null,
      providesTags: () => [{ type: 'ProductGap', id: 'revenueBlocked' }],
    }),

    getProductGapUseCases: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.useCases,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: 'ProductGap', id: 'recentlyAddedPg' },
      ],
    }),

    enableDisableProductGapItem: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.enableDisablePgi,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Modules', id: 'pgByFeatureId' }],
    }),

    updateProductGapItemStatus: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.updateStatus,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Modules', id: 'pgByFeatureId' }],
    }),

    updateProductGapItemTitle: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.updateProductGapItemTitle,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'pgByFeatureId' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),

    addProductGapItem: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.addProductGapItem,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'pgByFeatureId' },
        { type: 'ProductGap', id: 'getPgItemsCount' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),

    updateProductGapItemJira: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.productGap.updatePgItemJira,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'pgByFeatureId' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),

    getProductGapItemsCount: builder.query({
      query: () => URLS_BUSINESS_SERVICE.productGap.getPgItemsCount,
      providesTags: () => [{ type: 'ProductGap', id: 'getPgItemsCount' }],
    }),
  }),
});

export const {
  useFetchItemsByModuleQuery,
  useAddProductGapMutation,
  useUpdateProductGapMutation,
  useLazyDeleteProductGapQuery,
  useLazyFetchAllProductGapsQuery,
  useFetchAllProductGapsQuery,
  useLazyFetchProductGapByFeatureQuery,
  useFetchProductGapByFeatureQuery,
  useFetchRecentlyAddedPgItemsQuery,
  useFetchJiraIssueTypesQuery,
  useFetchJiraProjectsQuery,
  useFetchJiraStatusQuery,
  useFetchJiraIssueQuery,
  useLazyFetchJiraIssueQuery,
  useFetchJiraIssueCommentsQuery,
  useFetchJiraIssuesOnSearchMutation,
  useFetchJiraUsersQuery,
  useAddJiraProductGapMutation,
  useUpdateJiraProductGapMutation,
  useGetProductGapModulesAndItemsQuery,
  useGetProductGapUseCasesMutation,
  useEnableDisableProductGapItemMutation,
  useUpdateProductGapItemStatusMutation,
  useUpdateProductGapItemTitleMutation,
  useAddProductGapItemMutation,
  useUpdateProductGapItemJiraMutation,
  useGetRevenueBlockedQuery,
  useGetProductGapItemsCountQuery,
  useGetPGItemsByFeatureListQuery,
  useLazyGetPGItemsByFeatureListQuery,
} = extendedProductGapSlice;

export const productGapState = (state) => state.productGap;
export const {
  saveSelectedProductGapType,
  saveModule,
  saveFeature,
  saveProductGap,
  saveProductGapItem,
  saveRetainValueBoolean,
  saveNewPGTitle,
  saveRefetchPgUsecase,
} = productGapSlice.actions;
export default productGapSlice.reducer;
