import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { trackEvent } from '../_helpers/mixpanel';

const useAxios = (
  defaultAxiosInstance,
  { initialState, cancellable } = { initialState: [], cancellable: true }
) => {
  const [response, setResponse] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [controller, setController] = useState();

  const reset = () => {
    setResponse(initialState);
    setError('');
  };

  const makeAxiosCall = async ({
    axiosInstance = defaultAxiosInstance,
    method,
    url,
    data,
    requestConfig = {},
    onSuccess,
    onError,
  }) => {
    try {
      setLoading(true);
      let cntrl;
      if (cancellable) {
        cntrl = new AbortController();
        setController(cntrl);
      }
      const config = cancellable
        ? {
            ...requestConfig,
            signal: cntrl.signal,
          }
        : requestConfig;
      const res =
        data ||
        method.toLowerCase() === 'post' ||
        method.toLowerCase() === 'put' ||
        method.toLowerCase() === 'patch'
          ? await axiosInstance[method](url, data || {}, config)
          : await axiosInstance[method](url, config);
      setResponse(res?.data);
      if (onSuccess) {
        onSuccess(res?.data);
      }
      // Mixpanel analytics tracking
      trackEvent(res, url);
    } catch (err) {
      console.log(err);
      console.log('err', url);
      console.warn(`ERROR: ${err?.message}`);
      if (err?.response?.data?.status === 400) {
        console.warn('Something went wrong.');
      }
      if (err?.response?.data?.status === 500) {
        console.warn('Something went wrong on our end.');
      }
      if (err?.code === 'ERR_CANCELED') {
        console.warn('Request cancelled.');
      }
      if (err?.code === 'ERR_NETWORK') {
        toast.error('Please check your network connection.');
      }
      if (
        !onError &&
        err?.code !== 'ERR_CANCELED' &&
        err?.code !== 'ERR_NETWORK' &&
        err?.response?.data?.status !== 401
      ) {
        toast.error('Something went wrong');
      }
      setError(err);
      if (onError && (err?.response?.data?.error || err?.code)) {
        onError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => controller && controller.abort();
  }, [controller]);

  return { response, reset, error, loading, makeAxiosCall };
};

export default useAxios;
