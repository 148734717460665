import React from 'react';
import { useEffect } from 'react';
import emptyHyphen from '../../_helpers/empty-hyphen';
import { useFetchDealInfoForRAMQuery } from './RAMSlice';

const DealInformationContainer = ({ activeCard }) => {
  const params = { dealId: activeCard.dealId };
  const { data: dealInformation } = useFetchDealInfoForRAMQuery(params, {
    skip: !activeCard?.dealId,
    selectFromResult: ({ data }) => ({
      data: data && data.data ? data.data : [],
    }),
  });

  return (
    <div className="deal-information-container">
      <div className="deal-header">{dealInformation?.dealName}</div>
      <div className="deal-statistics">
        <span className="deal-statistics-child font-bold">
          {dealInformation?.accountName}
        </span>
        <span className="deal-statistics-child">
          ${dealInformation?.dealValue}
        </span>
        <span className="deal-statistics-child font-bold deal-date">
          {dealInformation?.estimatedCloseDate}
        </span>
        <span className="deal-statistics-child">
          Industry :{' '}
          <span className="font-bold">
            {emptyHyphen(dealInformation?.industry)}
          </span>
        </span>
        <span className="deal-statistics-child">
          Region :{' '}
          <span className="font-bold">
            {emptyHyphen(dealInformation?.region)}
          </span>
        </span>
        <span className="deal-statistics-child">
          Product :{' '}
          <span className="font-bold">
            {emptyHyphen(dealInformation?.product)}
          </span>
        </span>
      </div>
    </div>
  );
};
export default DealInformationContainer;
