import React from 'react';
import ReactModal from 'react-modal';
import Button from '../common/Button';
import authenticationService from '../_services/authentication.service';
import { toast } from 'react-toastify';

const ResetConfirmationModal = ({
  user,
  showModal,
  handleClose = () => {},
}) => {
  const handleCancel = () => {
    handleClose();
  };
  const resetPassword = () => {
    authenticationService
      .changePassword(user?.email, null, user.name)
      .then(() => {
        toast.success('Password Changed Successfully');
      });
    handleCancel();
  };

  return (
    <ReactModal
      isOpen={showModal}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Reset Confirmation</h4>
      </div>

      <div className="modal-body">
        Are you sure you want to reset the password?
      </div>

      <div className="modal-footer flex-justify-end">
        <Button type="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="danger" className="ml-5" onClick={resetPassword}>
          Reset
        </Button>
      </div>
    </ReactModal>
  );
};

export default ResetConfirmationModal;
