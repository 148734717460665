import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactDropdown from 'react-dropdown';
import NoContent from '../../Accounts/commons/NoContent';
import Table from '../../common/Table';
import { UTILIZATION_TABLE_DATE_OPERATORS } from '../../Constant/FilterOperators';
import { getDateByOperator } from '../../Utils/DateUtils';
import { useFetchUtilizationReportQuery } from './RAMSlice';
import { formatCount } from '../../Utils/Revenue';
import emptyHyphen from '../../_helpers/empty-hyphen';
import { ReactComponent as CalendarSVG } from '../../Icons/Calendar.svg';
import CalendarModal from '../../common/CalendarModal';

const UtilizationTable = ({ ownerId }) => {
  const [metricsParams, setMetricsParams] = useState({
    ownerId,
    fromDate: '',
    toDate: '',
    dateFilter: '',
  });

  const [dateFilter, setDateFilter] = useState(
    UTILIZATION_TABLE_DATE_OPERATORS[0].value
  );
  const [showCalendarView, setShowCalendarView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const resourceAssistanceMetricsColumn = [
    {
      Header: 'Sales Engineer',
      accessor: 'presalesEngineer',
      Cell: ({ row }) => (
        <span className="flex-align-center gap-1">
          <span>{emptyHyphen(row.original.presalesEngineer)}</span>
          <div
            className="cal-icon"
            onClick={() => handleCalendarView(true, row.index)}
          >
            <CalendarSVG />
          </div>
        </span>
      ),
    },
    {
      Header: 'Utilization Rate',
      accessor: 'utilizationRate',
      headerClassName: 'pink-bg',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {<span>{row.original.utilizationRate}%</span>}
        </span>
      ),
    },
    {
      Header: 'Newly Assigned Deals',
      accessor: 'assignedDealCount',
      headerClassName: 'pink-bg',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {<span>{emptyHyphen(row.original.assignedDealCount)}</span>}
        </span>
      ),
    },
    {
      Header: 'Active Deals',
      accessor: 'activeDealCount',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {<span>{emptyHyphen(row.original.activeDealCount)}</span>}
        </span>
      ),
    },
    {
      Header: 'Number of deals with given AE',
      accessor: 'dealCountWithSalesPerson',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {<span>{emptyHyphen(row.original.dealCountWithSalesPerson)}</span>}
        </span>
      ),
    },
    {
      Header: 'Conversion Rate with given AE(%)',
      accessor: 'conversionRateWithSalesPerson',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {<span>{`${row.original.conversionRateWithSalesPerson}%`}</span>}
        </span>
      ),
    },
    {
      Header: 'ARPU($)',
      accessor: 'averageRevenue',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {
            <span>
              {emptyHyphen(formatCount(row.original.averageRevenue, true))}
            </span>
          }
        </span>
      ),
    },
  ];

  useEffect(() => {
    const dateOperator = getDateByOperator(
      UTILIZATION_TABLE_DATE_OPERATORS[0].value
    );
    const params = { ...metricsParams };
    params.fromDate = dateOperator.fromDate;
    params.toDate = dateOperator.toDate;
    params.ownerId = ownerId;
    params.dateFilter = UTILIZATION_TABLE_DATE_OPERATORS[0].value;
    if (ownerId) {
      setMetricsParams(params);
    }
  }, [ownerId]);

  const { data: utilizationReportMetrics, isLoading } =
    useFetchUtilizationReportQuery(metricsParams, {
      skip: !metricsParams.ownerId && !metricsParams.fromDate,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => ({
        data: data?.data && Array.isArray(data.data) ? data.data : [],
      }),
    });

  const handleCalendarView = (value, index) => {
    setShowCalendarView(value);
    if (index === null) {
      setActiveIndex(null);
      return;
    }
    setActiveIndex(index);
  };

  const onDropdownSelect = (option) => {
    const dateOperator = getDateByOperator(option.value);
    const params = { ...metricsParams };
    params.fromDate = dateOperator.fromDate;
    params.toDate = dateOperator.toDate;
    params.ownerId = ownerId;
    params.dateFilter = option.value;
    setMetricsParams(params);
  };

  return (
    <>
      <div className="table-container">
        <div className="flex">
          <h2 className="heading_level4 text-dark header">
            Team Utilization and Statistics
          </h2>
          <div className="flex-grow"></div>
          <div>
            <ReactDropdown
              options={UTILIZATION_TABLE_DATE_OPERATORS}
              value={dateFilter}
              onChange={onDropdownSelect}
            />
          </div>
        </div>
        {utilizationReportMetrics && (
          <Table
            data={utilizationReportMetrics}
            columns={resourceAssistanceMetricsColumn}
            className="assistance-metrics-table"
          />
        )}
        {!utilizationReportMetrics ||
          (utilizationReportMetrics.length === 0 && (
            <NoContent className="no-data-table" message="No data to display" />
          ))}
      </div>
      {showCalendarView && (
        <CalendarModal
          showModal={showCalendarView}
          closeModal={handleCalendarView}
          data={utilizationReportMetrics}
          index={activeIndex}
        />
      )}
    </>
  );
};

export default UtilizationTable;
