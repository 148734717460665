import React, { useEffect, useState } from 'react';
import CopyText from '../../common/CopyText';
import mixpanel from 'mixpanel-browser';
import { ReactComponent as AddSVG } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as DeleteSVG } from '../../Icons/NewImage/delete.svg';

import AddContactModal from './AddContactModal';
import DeletePopup from '../../common/Modals/DeletePopup';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAxios from '../../hooks/useAxios';
import {
  deleteExternalContact,
  getExternalContacts,
} from '../../_services/common.service';
import { Button } from 'antd';

const ExternalContacts = ({ user, accountId, dealId, readOnly = false }) => {
  const axios = useAxiosPrivate();
  const { response: externalContactsRes, ...fetchRelatedContacts } =
    useAxios(axios);
  const deleteContact = useAxios(axios);
  const [showAddContact, setShowAddContact] = useState(false);
  const [externalContacts, setExternalContacts] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getExternalContacts(fetchRelatedContacts, dealId);
  }, [dealId]);

  useEffect(() => {
    setExternalContacts(externalContactsRes);
  }, [externalContactsRes]);

  const handleContactPaneOpen = async (event) => {
    if (event && typeof event.stopPropagation() === 'function')
      event.stopPropagation();
    setShowAddContact(true);
    try {
      mixpanel.track('externalcollab_added', {
        event: 'Collaborator External',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  const handleExternalContactDelete = (contactId) => {
    const contactsCopy = [...externalContacts];
    const filteredList = contactsCopy.filter(
      (contact) => contact.id !== contactId
    );
    setExternalContacts(filteredList);
    setShowDeleteModal(false);
  };

  const handleDelete = (item) => {
    console.log('click delete', item);
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="parent-container">
        <div className="heading-container">
          <div className="header">External - Contacts</div>
          {!readOnly && (
            <button
              onClick={handleContactPaneOpen}
              className="btn btn-icon btn-transparent"
            >
              <span className="add-icon">
                <AddSVG />
              </span>
              <span className="btn-txt"> Add new </span>
            </button>
          )}
        </div>
        <div className="content-container">
          <table>
            <tr>
              <th className="width-40">Name</th>
              <th>Email</th>
              <th></th>
            </tr>
            {externalContacts.length > 0 &&
              externalContacts?.map((contact) => (
                <tr key={contact?.id} className="content-row">
                  <td>{contact.displayName}</td>
                  <td className="contact-email">
                    <span className="flex">
                      <CopyText content={contact.email} />
                    </span>
                  </td>
                  {!readOnly && (
                    <td className="content-actions">
                      {contact.addedInternally && (
                        <Button
                          icon={<DeleteSVG />}
                          onClick={() => handleDelete(contact.id)}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </table>
        </div>
      </div>
      {showAddContact && (
        <AddContactModal
          setShowAddContact={setShowAddContact}
          showAddContact={showAddContact}
          externalContacts={externalContacts}
          setExternalContacts={setExternalContacts}
          dealId={dealId}
          accountId={accountId}
          user={user}
        />
      )}
      {showDeleteModal && (
        <DeletePopup
          showModal={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          actionComponents={[
            <Button key="back" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                deleteExternalContact(deleteContact, dealId, selectedItem, () =>
                  handleExternalContactDelete(selectedItem)
                )
              }
              danger
            >
              Delete
            </Button>,
          ]}
        />
      )}
    </>
  );
};
export default ExternalContacts;
