import React, { useEffect } from 'react';
import { Form, Typography } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import RichTextEditor from '../RichTextEditor';
import { convertHTMLtoPlainText } from '../../Utils/common';

dayjs.extend(localeData);
const { Text } = Typography;

const AddNotesMainContent = ({ initialValues, form, action }) => {
  useEffect(() => {
    if (initialValues && action === 'update') {
      form.setFieldsValue({
        'notes-description': initialValues?.description,
      });
    }
  }, [initialValues, form]);

  return (
    <Form.Item
      className="mt-30"
      label={<Text strong>Notes description</Text>}
      name="notes-description"
      rules={[
        {
          required: true,
          message: 'Description field is Required',
          validator: (_, value) => {
            if (!value) {
              return Promise.reject('Description field is Required');
            }
            if (value && !convertHTMLtoPlainText(value)) {
              return Promise.reject('Description field is Required');
            }
            if (value && convertHTMLtoPlainText(value)) {
              return Promise.resolve();
            }
          },
        },
      ]}
    >
      <RichTextEditor className="mention-wrapper description" />
    </Form.Item>
  );
};

export default AddNotesMainContent;
