import React, { useEffect } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import PGManager from './ProductGapManager';
import useDataPersistance from '../hooks/useDataPersistance';
import { FeatureRouteAccess } from '../common/routes/FeatureRouteAccess';
import {
  isAdminOrManager as findIsAdminOrManager,
  isProductUser as findIsProductUser,
} from '../Utils/common';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';

const ProductGapManager = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { getStorageData } = useDataPersistance();

  const { data: config } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  useEffect(() => {
    const defaultTab = getStorageData('pgManager');
    // Replacing default pathname
    if (location.pathname === '/libraries') {
      history.replace(`/libraries/${defaultTab ? defaultTab : 'productgaps'}`);
    }
  }, [location]);

  const hasAccess = (config, matchTab) => {
    const tab = matchTab?.match?.params?.tab;
    const isAdminOrManager = findIsAdminOrManager();
    const isProductUser = findIsProductUser();
    const accessRules = {
      productgaps: true,
      solutions: config?.data?.featureList?.is_solution_usecase_manager_enabled,
      manage: isAdminOrManager || isProductUser,
      integrations: isAdminOrManager || isProductUser,
    };
    return accessRules[tab];
  };

  return (
    <FeatureRouteAccess
      path={`${path}/:tab`}
      featureAccess={(params) => hasAccess(config, params)}
      landingPage={config?.data?.landingPage}
      component={<PGManager companyConfig={config} />}
    />
  );
};

export default ProductGapManager;
