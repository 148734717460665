import React from 'react';

import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { ReactComponent as InfoSVG } from '../Icons/Info-circle.svg';

export default function PreskaleToolTip(props) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ trigger: 'click' });

  return (
    <div>
      <button
        className="info-icon-container btn btn-transparent"
        ref={setTriggerRef}
      >
        <InfoSVG className="info-icon" />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `tooltip-container ${props.customClassName ?? ''}`,
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {props.toolTipMessage}
          {props.children}
        </div>
      )}
    </div>
  );
}
