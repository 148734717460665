import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddSVG } from '../../Icons/Add-White.svg';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as BackSVG } from '../../Icons/ArrowLeft.svg';
import {
  useFetchModulesFeaturesQuery,
  useFetchProductGapByFeatureIdQuery,
} from './moduleFeaturesSlice';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  selectUserMap,
  useCheckIsJiraIntegratedQuery,
  useGetUsersQuery,
} from '../../app/api/usersSlice';
import AddUpdateModule from './AddUpdateModule';
import Button from '../../common/Button';
import AddUpdateProductGap from './AddUpdateProductGap';
import NoModulePgData from './NoModulePgData';
import { DEFAULT_ALL } from '../../Constant/FilterOperators';
import SearchField from '../../Accounts/commons/SearchField';
import Accordion from './Accordion';
import styles from '../../styles/features/_moduleProductGapManager.module.scss';
import {
  saveProductGap,
  useGetProductGapItemsCountQuery,
} from '../ProductGap/productGapSlice';
import AgGridTooltip from '../../common/AgGridTooltip';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import {
  getActivePulseScore,
  getActiveUsecase,
  getTotalUsecase,
} from '../../Utils/ManageModuleFeaturesUtils';
import { getFilterKey } from '../../Utils/libraryUtils';
import { useDispatch } from 'react-redux';
import useDataPersistance from '../../hooks/useDataPersistance';
import AssociateToJiraButton from '../ProductGap/AssociateToJira';
import emptyHyphen from '../../_helpers/empty-hyphen';
import AgGridLoader from '../../common/AgGridLoader';
import ToolTip from '../../common/ToolTip';
import ProductGapUsecaseDrawer from '../ProductGapDetailView/ProductGapUsecaseDrawer';
import { generateUUID } from '../../Utils/common';

export default function ManageModuleFeatures() {
  const gridRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectModule, setSelectModule] = useState();
  const [isModuleView, setIsModuleView] = useState(false);
  const [isNewModule, setIsNewModule] = useState(false);
  const [isProductGapView, setIsProductGapView] = useState(false);
  const [editProductGapDetails, setEditProductGapDetails] = useState({});
  const [selectedModuleFeature, setSelectedModuleFeature] = useState({});
  const [selectedFeatureId, setSelectedFeatureId] = useState();
  const [updatedModuleData, setUpdatedModuleData] = useState();
  const [activeFeature, setActiveFeature] = useState();
  const [filterProductGap, setFilterProductGap] = useState();
  const [pgTitleUpdated, setPgTitleUpdated] = useState();
  const [newModuleFeature, setNewModuleFeature] = useState();
  const [featureEnableDisable, setFeatureEnableDisable] = useState();
  const [openPgDrawer, setOpenPgDrawer] = useState(false);
  const [selectedPg, setSelectedPg] = useState([]);

  const { setStorageData } = useDataPersistance();

  const [expandSearchPg, setExpandSearchPg] = useState(false);

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !localStorage.getItem('user'),
  });

  const { data: pgItemsCount } = useGetProductGapItemsCountQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data && data.data ? data.data : [],
    }),
  });

  const { data: isJiraIntegrated } = useCheckIsJiraIntegratedQuery();

  const { data: modulesFeatures, isFetching: loadingModulesAndFeatures } =
    useFetchModulesFeaturesQuery({});

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 75,
      suppressMovable: true,
    }),
    []
  );

  const handlePgLibraryRedirect = (params) => {
    const productGapItems = {
      label: params?.data?.title,
      value: params?.data?.pgId,
    };

    const feature = {
      label: selectedModuleFeature?.featureTitle,
      value: selectedModuleFeature?.featureId,
    };

    const module = {
      label: selectedModuleFeature?.moduleTitle,
      value: selectedModuleFeature?.moduleId,
    };

    // Update persisted data of pg library's modules and features filter
    // with selected pg related info, to see updated values after redirection.
    const storedData = localStorage.getItem(getFilterKey('pgUseCases'));
    let filtersFromLS = storedData ? JSON.parse(storedData) : {};
    filtersFromLS.modules = [module];
    filtersFromLS.features = [feature];
    filtersFromLS.pgItems = [productGapItems];
    filtersFromLS.itemIds = [selectedModuleFeature?.featureId];

    setStorageData('pgUseCases', filtersFromLS);
    history.push('/libraries/productgaps');
  };

  const columnDefs = [
    {
      field: 'title',
      minWidth: 250,
      headerName: 'Product Gap Item',
      tooltipField: 'title',
      cellStyle: () => {
        return {
          cursor: 'pointer',
          color: '#447bf2',
          fontWeight: '600',
          textDecoration: 'underline',
        };
      },
      onCellClicked: (params) => {
        setOpenPgDrawer(true);
        setSelectedPg(params?.data);
      },
    },
    {
      field: 'added_by',
      headerName: 'Added By',
      minWidth: 80,
      valueGetter: (params) => userMap[params?.data?.added_by]?.name,
    },
    {
      field: 'active_usecase',
      headerName: 'Active Use-cases',
      valueGetter: (params) => getActiveUsecase(params, true),
      cellStyle: (params) => getActiveUsecase(params, false),
    },
    {
      field: 'total_usecase',
      headerName: 'Total Use-cases',
      valueGetter: (params) => getTotalUsecase(params, true),
      cellStyle: (params) => getTotalUsecase(params, false),
    },
    {
      field: 'total_rating',
      cellStyle: (params) => getActivePulseScore(params, false),
      valueGetter: (params) => getActivePulseScore(params, true),
      flex: 1.5,
      minWidth: 170,
      headerName: 'Active Pulse Score',
    },
    { field: 'status', headerName: 'Status', minWidth: 100 },

    {
      field: 'jira_title',
      minWidth: 180,
      hide:
        configFeatures?.data?.newRequestIntegration !== 'jira' &&
        configFeatures?.data?.enhancementBugIntegration !== 'jira',
      flex: 1,
      headerName: 'Ticket ID',
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        value: !isJiraIntegrated
          ? params?.data?.jira_title
            ? ''
            : 'Jira not connected'
          : '',
      }),
      cellRenderer: (params) => {
        return configFeatures?.data?.newRequestIntegration !== 'jira' &&
          configFeatures?.data?.enhancementBugIntegration !== 'jira' ? (
          emptyHyphen(null)
        ) : params?.data?.jira_title ? (
          params?.data?.jira_title
        ) : (
          <AssociateToJiraButton
            btnText="Associate Jira ticket"
            btnVariant="text"
            icon={false}
            disabled={!isJiraIntegrated}
            isJiraReporterDisable={
              configFeatures?.data?.jiraReporterDisable === true
            }
            onOpen={() => dispatch(saveProductGap({ id: params?.data?.pgId }))}
            onClose={() => dispatch(saveProductGap({}))}
            styles={{ fontWeight: 'bold', textDecoration: 'underline' }}
          />
        );
      },
    },
  ];

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });

  const { data: productGap, isFetching: productGapLoading } =
    useFetchProductGapByFeatureIdQuery({
      featureId: selectedFeatureId,
    });

  useEffect(() => {
    let productGapOptions = [...DEFAULT_ALL];
    if (productGap && productGap.length > 0) {
      productGap.forEach((pg, i) => {
        productGapOptions.push({
          label: pg?.pgTitle,
          value: pg?.pgId,
          index: i,
        });
        if (pgTitleUpdated && pg?.pgId === pgTitleUpdated) {
          setEditProductGapDetails({
            id: pg?.pgId,
            title: pg?.pgTitle,
            status: pg?.disabled,
          });
        }
      });
    }
    setFilterProductGap(productGap);
  }, [productGap]);

  const handleFeatureChange = (featureId) => {
    setActiveFeature(featureId);
    setSelectedFeatureId(featureId);
    setEditProductGapDetails({
      id: '',
      title: '',
      status: '',
    });

    setIsModuleView(false);
    setIsProductGapView(false);
  };

  useEffect(() => {
    if (updatedModuleData) {
      let res = modulesFeatures.find(
        (item) => item?.id === updatedModuleData?.id
      );
      setSelectModule(res);
    }
    if (newModuleFeature) {
      let res = modulesFeatures.find((item) => item?.id === newModuleFeature);
      setSelectModule(res);
    }
    if (featureEnableDisable) {
      let res = modulesFeatures.find(
        (item) => item?.id === featureEnableDisable
      );
      setSelectModule(res);
      setFeatureEnableDisable('');
    }
  }, [modulesFeatures]);

  const handleSelectModule = (selectedModuleData) => {
    setSelectModule(selectedModuleData);
    setIsModuleView(true);
    setIsNewModule(false);
    setUpdatedModuleData('');
    setNewModuleFeature('');
  };

  const handleFeatureUpdated = (moduleParams) => {
    handleSelectModule(moduleParams);
    setFeatureEnableDisable(moduleParams?.id);
  };

  const editProductGap = () => {
    setIsModuleView(false);
    setIsProductGapView(true);
  };

  const handleAddModule = () => {
    setIsNewModule(true);
    setSelectModule({});
    setIsModuleView(true);
    setUpdatedModuleData('');
    setNewModuleFeature('');
  };

  const handleAddNewProductGap = () => {
    setIsProductGapView(true);
    setEditProductGapDetails({
      id: '',
      title: '',
      status: '',
    });
  };

  const handleClose = () => {
    setIsModuleView(false);
    setIsProductGapView(false);
  };

  const moduleUpdated = (updatedModule) => {
    setUpdatedModuleData(updatedModule);
  };

  const moduleAdded = (moduleId) => {
    setNewModuleFeature(moduleId);
  };

  const handlePgSearch = (event) => {
    if (!event.target.value) {
      setFilterProductGap(productGap);
      return;
    }
    const searchResponse = productGap.filter((pg) =>
      pg?.title?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilterProductGap(searchResponse);
  };

  const pgUpdated = (data) => {
    setPgTitleUpdated(data);
  };

  const loadingOverlayComponent = useMemo(() => {
    return AgGridLoader;
  }, []);

  useEffect(() => {
    if (!productGapLoading) {
      gridRef?.current?.api?.hideOverlay();
    }
    if (productGapLoading) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [productGapLoading]);

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  return (
    <>
      <div className={styles.manageModules_container}>
        <div className={styles.left_panel}>
          <div className={styles.left_panel__header}>
            <div className={styles.modules_title}>Modules & Features</div>
            <ToolTip text="Add module">
              <Button
                onClick={handleAddModule}
                icon
                className={`${styles.mdl_btn} btn-icon-only`}
              >
                <AddSVG />
              </Button>
            </ToolTip>
          </div>

          <div className={styles.accordion}>
            {modulesFeatures &&
              modulesFeatures?.map((moduleFeature, i) => (
                <div key={`${moduleFeature?.id}`}>
                  <Accordion
                    activeModule={
                      selectedModuleFeature?.moduleId || modulesFeatures[0]?.id
                    }
                    accordionData={moduleFeature}
                    featureChange={handleFeatureChange}
                    selectModule={handleSelectModule}
                    selectedModuleFeature={(data) =>
                      setSelectedModuleFeature(data)
                    }
                    activeFeature={activeFeature}
                    pgItemsCount={pgItemsCount}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className={styles.right_panel}>
          <div className={styles.right_panel_header}>
            <div
              className={`${styles.modules_title} flex-align-center gap-default`}
            >
              {(isProductGapView || isModuleView) && (
                <Button
                  onClick={handleClose}
                  icon
                  type="text"
                  className={`${styles.back_btn} btn-icon-only`}
                >
                  <BackSVG />
                </Button>
              )}
              {isModuleView
                ? 'Add Modules & Features'
                : isProductGapView
                ? 'Manage Product Gaps'
                : 'Product Gaps'}
            </div>
            <div className="gap-default flex">
              {!isModuleView && !isProductGapView && (
                <ToolTip text="Edit product gap">
                  <Button
                    onClick={editProductGap}
                    icon
                    className={`${styles.mdl_btn} btn-icon-only`}
                    disabled={!productGap || productGap?.length < 1}
                  >
                    <EditSVG />
                  </Button>
                </ToolTip>
              )}
              {!isModuleView && !isProductGapView && (
                <ToolTip text="Add product gap">
                  <Button
                    onClick={handleAddNewProductGap}
                    icon
                    className={`${styles.mdl_btn} btn-icon-only`}
                  >
                    <AddSVG />
                  </Button>
                </ToolTip>
              )}
              {!isModuleView && !isProductGapView && !expandSearchPg && (
                <Button
                  onClick={() => setExpandSearchPg(true)}
                  icon
                  className={`${styles.mdl_btn} btn-icon-only`}
                >
                  <SearchSVG />
                </Button>
              )}

              {!isModuleView && !isProductGapView && expandSearchPg && (
                <SearchField
                  placeholder="Search Product Gaps"
                  onChange={handlePgSearch}
                  className={styles.search_expand}
                />
              )}
            </div>
          </div>
          {
            <div className={styles.modules_pg_content}>
              {isModuleView ? (
                <AddUpdateModule
                  isNewModule={isNewModule}
                  selectedModule={selectModule}
                  featureUpdated={handleFeatureUpdated}
                  moduleUpdated={moduleUpdated}
                  moduleAdded={moduleAdded}
                  allModules={modulesFeatures}
                  loadingAllModules={loadingModulesAndFeatures}
                />
              ) : isProductGapView ? (
                <AddUpdateProductGap
                  productGapId={editProductGapDetails?.id}
                  productGapTitle={editProductGapDetails?.title}
                  productGapStatus={editProductGapDetails?.status}
                  selectedModuleFeature={selectedModuleFeature}
                  productGapItems={productGap}
                  pgUpdated={pgUpdated}
                  loadingAllModules={loadingModulesAndFeatures}
                />
              ) : (
                <div className={styles.module_features}>
                  {!productGap || productGap?.length < 1 ? (
                    <div className="avc">
                      <NoModulePgData message="No Data" />
                    </div>
                  ) : (
                    <div className="manage-modules-table">
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                          ref={gridRef}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          tooltipShowDelay={0}
                          tooltipHideDelay={2000}
                          overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444;">No data to show</span>'
                          }
                          loadingOverlayComponent={loadingOverlayComponent}
                          rowData={filterProductGap}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          }
        </div>
      </div>
      {openPgDrawer && (
        <ProductGapUsecaseDrawer
          openDrawer={openPgDrawer}
          setOpenPgDrawer={setOpenPgDrawer}
          selectedPg={selectedPg}
          setSelectedPg={setSelectedPg}
          selectedModuleFeature={selectedModuleFeature}
          companyConfig={configFeatures}
          key={() => generateUUID()} //force re-render to clear persisted prev props by antd.
        />
      )}
    </>
  );
}
