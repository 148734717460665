import { businessService } from '../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

export const extendedReportsSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchEmbedUrl: builder.query({
      query: (dashboardType) => {
        return `${URLS_BUSINESS_SERVICE.reports.getEmbedUrl}?dashboardType=${dashboardType}`;
      },
    }),
    fetchReports: builder.query({
      query: () => URLS_BUSINESS_SERVICE.company.reports,
    }),
    fetchCustomReport: builder.query({
      query: () => URLS_BUSINESS_SERVICE.reports.generateCustomExtract,
    }),
  }),
});

export const {
  useFetchEmbedUrlQuery,
  useFetchReportsQuery,
  useLazyFetchCustomReportQuery,
} = extendedReportsSlice;
