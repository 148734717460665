import { URLS_BUSINESS_SERVICE } from "../Constant/urls";

export const getTrendsEvents = (service, data) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.events,
    data,
  });
};

export const getTrendsOutcomes = (service, data) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.outcomes,
    data,
  });
};

export const getTrendsSuccessItems = (service, data) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.successItems,
    data,
  });
};

export const getTrendsProductGap = (service, data) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.productGaps,
    data,
  });
};

export const getManagerOpportunities = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.opportunityManager,
    data,
    onSuccess: handleSuccess,
  });
};

export const getManagerSalesTrends = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.salesManager,
    data,
    onSuccess: handleSuccess,
  });
};

export const getManagerTrendsSuccessItems = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.successItemsManager,
    data,
    onSuccess: handleSuccess,
  });
};

export const getManagerTrendsEvents = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.eventsManager,
    data,
    onSuccess: handleSuccess,
  });
};

export const getManagerTrendsOutcomes = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: "post",
    url: URLS_BUSINESS_SERVICE.reports.outcomesManager,
    data,
    onSuccess: handleSuccess,
  });
};
