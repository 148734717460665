import React from 'react';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import styles from '../styles/pages/signup.module.scss';

const CalendarEmbed = ({ handleEventScheduled, values }) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      handleEventScheduled();
    },
  });
  const prefill = {
    email: values.email ?? '',
    name: values.fullName ?? '',
  };
  return (
    <section className={styles.calendar_container}>
      <InlineWidget
        url="https://calendly.com/theajayjay/onboard?hide_event_type_details=1&hide_gdpr_banner=1"
        prefill={prefill}
      />
    </section>
  );
};

export default CalendarEmbed;
