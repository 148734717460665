import React from 'react';
import emptyHyphen from '../../../_helpers/empty-hyphen';
import { createMarkup } from '../../../Utils/common';
import { ReactComponent as EditSVG } from '../../../Icons/NewImage/edit.svg';
import { ReactComponent as DeleteSVG } from '../../../Icons/NewImage/delete.svg';
import { ReactComponent as CheckSVG } from '../../../Icons/NewImage/checkIcon.svg';
import { ReactComponent as ReopenSVG } from '../../../Icons/Refresh.svg';
import { selectUserMap, useGetUsersQuery } from '../../../app/api/usersSlice';
import Item from '../Item';

const STATUS_COLOUR = {
  OVERDUE: {
    color: 'warning',
    label: 'Overdue',
  },
  OPEN: {
    color: 'primary',
    label: 'Open',
  },
  COMPLETED: {
    color: 'success',
    label: 'Completed',
  },
};

const TaskItem = ({
  item,
  handleComplete,
  handleUpdate,
  handleDelete,
  handleReopen,
  isReopen = true,
  isReadOnly,
}) => {
  const { description, dealName, status, dueDate } = item;
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  const markComplete = () => handleComplete(item);
  const updateTask = () => handleUpdate(item);
  const deleteTask = () => handleDelete(item);
  const reopenTask = () => handleReopen(item);
  return (
    <Item
      description={<span dangerouslySetInnerHTML={createMarkup(description)} />}
      footer={{
        start: (
          <>
            <div
              className={`chip_filled_container chip_color__${STATUS_COLOUR[status].color}`}
            >
              <span className="chip_label">{STATUS_COLOUR[status].label}</span>
            </div>
            <div>{emptyHyphen(dealName)}</div>
            <div>{userMap[item.userId] && userMap[item.userId].name}</div>
          </>
        ),
        end: (
          <>
            <div>{userMap[item.createdBy] && userMap[item.createdBy].name}</div>
            <div>{dueDate}</div>
          </>
        ),
      }}
      extraActions={
        status !== 'COMPLETED'
          ? [
              {
                key: 'mark-complete',
                onClick: markComplete,
                icon: <CheckSVG />,
              },
              {
                key: 'update',
                onClick: updateTask,
                icon: <EditSVG />,
              },
              {
                key: 'delete',
                onClick: deleteTask,
                icon: <DeleteSVG />,
              },
            ]
          : isReopen && status === 'COMPLETED'
          ? [
              {
                key: 'reopen',
                onClick: reopenTask,
                icon: <ReopenSVG />,
                className: 'reopen-btn',
              },
            ]
          : []
      }
      editable={!isReadOnly}
    />
  );
};

export default TaskItem;
