import React, { useEffect, useState } from 'react';

import ReactModal from 'react-modal';
import Avatar from '../../common/Avatar';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  addExternalContact,
  getRelatedContacts,
} from '../../_services/common.service';

const AddContactModal = ({
  user,
  accountId,
  dealId,
  externalContacts,
  setExternalContacts,
  showAddContact,
  setShowAddContact,
}) => {
  const axios = useAxiosPrivate();
  const { response: relatedContacts, ...fetchRelatedContacts } =
    useAxios(axios);
  const addExternalContactService = useAxios(axios);

  useEffect(() => {
    if (accountId) {
      getRelatedContacts(fetchRelatedContacts, accountId, dealId);
    }
  }, []);

  const [selectedContactList, setSelectedContactList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [contact, setContact] = useState({ displayName: '', email: '' });

  const handleInputChange = (key, event) => {
    event.persist();
    setContact((prevState) => {
      prevState[key] = event.target.value;
      return {
        ...prevState,
      };
    });
  };

  const handleCheckBoxChange = (contact) => {
    const contactListCopy = [...selectedContactList];
    const indx = contactListCopy.indexOf(contact);
    if (indx > -1) {
      contactListCopy.splice(indx, 1);
    } else {
      contactListCopy.push(contact);
    }
    setSelectedContactList(contactListCopy);
  };

  const handleAddContacts = (addContact) => {
    if (handleFormValidation()) {
      const contactPayLoad = { ...contact };
      contactPayLoad.addedBy = {};
      contactPayLoad.addedBy.id = user?.userId;
      contactPayLoad.addedBy.email = user?.email;
      contactPayLoad.addedBy.displayName = user?.name;
      contactPayLoad.addedInternally = true;
      contactPayLoad.dealId = [dealId];
      contactPayLoad.accountId = accountId;
      contactPayLoad.email = contact?.email?.toLowerCase();
      // const contactListCopy = [...selectedContactList];
      // contactListCopy.push(contactPayLoad);
      const externalContact = {
        contact: contactPayLoad,
        accountId: accountId,
        dealId: dealId,
        relatedContacts: selectedContactList,
      };
      addExternalContact(addExternalContactService, externalContact, (data) => {
        if (data) {
          let contactsCopy = [...externalContacts];
          contactsCopy = contactsCopy.concat(data);
          setExternalContacts(contactsCopy);
          if (addContact) {
            const contactCopy = { displayName: '', email: '' };
            setContact(contactCopy);
            setSelectedContactList([]);
            if (accountId) {
              getRelatedContacts(fetchRelatedContacts, accountId, dealId);
            }
          }
        }
        setShowAddContact(addContact);
      });
    }
  };

  const handleFormValidation = () => {
    let isFormValid = true;
    if (selectedContactList.length > 0) {
      return isFormValid;
    }
    const formErrorsNew = {};
    if (!contact.email) {
      isFormValid = false;
      formErrorsNew['email'] = 'Field cannot be empty';
    }
    if (!contact.displayName) {
      isFormValid = false;
      formErrorsNew['name'] = 'Field cannot be empty';
    }

    const emailRE =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let matchedText = contact.email.match(emailRE);
    if (contact.email && !matchedText) {
      isFormValid = false;
      formErrorsNew['email'] = 'Please enter a valid email id';
    }
    setFormErrors(formErrorsNew);
    return isFormValid;
  };

  return (
    <ReactModal
      isOpen={showAddContact}
      className="Modal confirm-box contacts-add "
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Add Contacts</h4>
      </div>

      <div className="modal-body form">
        <div className="form-field ">
          <div className="mb-20">
            <div className="flex-align-center">
              <label> Contact Name </label>
              <input
                placeholder="Contact Name"
                value={contact.displayName}
                onChange={(event) => handleInputChange('displayName', event)}
              />
            </div>
            <span className="error">{formErrors['displayName']}</span>
          </div>
          <div className="mb-20">
            <div className="flex-align-center">
              <label> Contact Email </label>
              <input
                type="email"
                placeholder="Contact Email"
                value={contact.email}
                onChange={(event) => handleInputChange('email', event)}
              />
            </div>
            <span className="error">{formErrors['email']}</span>
          </div>
        </div>
      </div>

      <div className="modal-footer footer">
        <div className="flex-justify-end">
          <button
            className="btn btn-secondary"
            onClick={() => setShowAddContact(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleAddContacts(false)}
          >
            Add
          </button>
        </div>
        <div
          className="btn-link secondary-button-container"
          onClick={() => handleAddContacts(true)}
        >
          Save and Add Another
        </div>
      </div>
      <div className="related-contacts-container">
        <div className="header">Related Contacts</div>
        {relatedContacts.length > 0 &&
          relatedContacts.map((contact) => (
            <div key={contact.email} className="flex-align-center mb-20">
              <input
                type="checkbox"
                onChange={() => handleCheckBoxChange(contact)}
              />
              <Avatar size="small" userName={contact.displayName} />
              <div className="flex-align-center ">
                <span className="font-bold">{contact.displayName}</span>
                <span>({contact.email})</span>
              </div>
            </div>
          ))}
      </div>
    </ReactModal>
  );
};
export default AddContactModal;
