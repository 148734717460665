import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import Button from './Button';
import { ReactComponent as CrossSVG } from '../Icons/accounts-close.svg';
import { ReactComponent as RedirectSVG } from '../Icons/redirectWhite.svg';
import { ReactComponent as ArrowLeftSVG } from '../Icons/ArrowLeft.svg';

const Drawer = ({
  showDrawer,
  closeDrawer,
  title,
  description,
  children,
  className = '',
  overlayClassName = '',
  headerClassName = '',
  redirect,
  redirecting,
  onRedirect,
  bodyClassName = '',
  extra,
  custom,
  backToPreviousState,
  backToPreviousClassName = '',
  closeOnOverlayClick = true,
  closeOnEsc = true,
  shouldCloseOnOverlayEscClick = () => {},
  showHeader = true,
}) => {
  const [bodyHeight, setBodyHeight] = useState();
  const measuredHeaderRef = useCallback(
    (node) => {
      if (node !== null && title) {
        const height = Math.round(node.getBoundingClientRect().height);
        if (bodyHeight !== height) {
          setBodyHeight(height);
        }
      }
    },
    [title]
  );
  const updateBodyRef = useCallback(
    (node) => {
      if (!bodyHeight) {
        return;
      }
      if (node !== null) {
        const container = document.getElementsByClassName(
          'presentationViewDrawer'
        );
        let containerHeight;
        if (container.length) {
          containerHeight = container[0].clientHeight;
          node.style.height = `${containerHeight - bodyHeight}px`;
        }
      }
    },
    [bodyHeight]
  );

  return (
    <ReactModal
      isOpen={showDrawer}
      className={`drawer ${className}`}
      overlayClassName={`Overlay Overlay-taskButtons details-modal-overlay ${overlayClassName}`}
      ariaHideApp={false}
      onRequestClose={shouldCloseOnOverlayEscClick}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      shouldCloseOnEsc={closeOnEsc}
    >
      {showHeader && (
        <div className="drawer-header" ref={measuredHeaderRef}>
          {backToPreviousState && (
            <Button
              onClick={backToPreviousState}
              icon
              type="text"
              className="btn-icon-only fill-colors-primary"
            >
              <ArrowLeftSVG
                className={`drawer-previous-icon ${backToPreviousClassName}`}
              />
            </Button>
          )}
          <div className="drawer-header--textContainer width-50">
            <h4 className={`drawer-header--title ${headerClassName}`}>
              {title}
            </h4>
            {description && (
              <div className="drawer-header--description">{description}</div>
            )}
          </div>
          <div className="flex-align-center flex-justify-end width-50">
            {custom}
            {redirect && (
              <div>
                <Button
                  icon
                  type="primary"
                  className="btn-icon-only drawer-btn mr-10"
                  onClick={(ev) => onRedirect(ev)}
                  loading={redirecting}
                  disabled={redirecting}
                >
                  <RedirectSVG />
                </Button>
              </div>
            )}
            {extra}
            {closeDrawer && (
              <Button
                icon={true}
                type="ghost"
                className="btn-icon-only drawer-btn"
                onClick={closeDrawer}
              >
                {' '}
                <CrossSVG />
              </Button>
            )}
          </div>
        </div>
      )}
      <div
        ref={updateBodyRef}
        className={`drawer-body${
          !showHeader ? '--no-header' : ''
        } ${bodyClassName}`}
      >
        {children}
      </div>
    </ReactModal>
  );
};

export default Drawer;
