import React from 'react';
import DualRingLoader from './DualRingLoader';

const Metric = ({
  loading = false,
  fetching = false,
  title = '',
  value,
  className = '',
  classNames = { container: '', title: '', value: '' },
}) => {
  return (
    <div
      className={
        classNames.container
          ? `${classNames.container} ${className}`
          : `metric--item ${className}`
      }
    >
      <div className={classNames.title ? classNames.title : 'metric--title'}>
        {title}
      </div>
      {loading ? (
        <DualRingLoader dark />
      ) : (
        <div
          className={`${fetching ? 'font-grey' : ''} ${
            classNames.value ? classNames.value : 'metric--value'
          }`}
        >
          {value ?? '--'}
        </div>
      )}
    </div>
  );
};

export default Metric;
