import React, { useEffect, useState, useMemo, useRef } from 'react';
import { ReactComponent as EnableDisableSVG } from '../../Icons/EnableDisable.svg';
import { ReactComponent as EnableSVG } from '../../Icons/Enable.svg';
import { ReactComponent as DisableSVG } from '../../Icons/Disable.svg';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from '../../common/Button';
import {
  useAddFeatureMutation,
  useAddModulesFeaturesMutation,
  useEnableDisableFeatureMutation,
  useEnableDisableModuleMutation,
  useUpdateFeatureMutation,
  useUpdateModuleMutation,
} from './moduleFeaturesSlice';
import NoModulePgData from './NoModulePgData';
import { toast } from 'react-toastify';
import AgGridLoader from '../../common/AgGridLoader';
import AgGridTooltip from '../../common/AgGridTooltip';

export default function AddUpdateModule({
  isNewModule,
  selectedModule,
  featureUpdated,
  moduleUpdated,
  moduleAdded,
  allModules,
  loadingAllModules,
}) {
  const gridRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [moduleName, setModuleName] = useState('');
  const [status, setStatus] = useState(false);
  const [newFeature, setNewFeatue] = useState('');

  const [updateFeatureCallback, { isLoading: updatingFeature }] =
    useUpdateFeatureMutation();
  const [enableDisableFeature] = useEnableDisableFeatureMutation();

  const [enableDisableModule] = useEnableDisableModuleMutation();

  const [updateModule, { isLoading: updatingModule }] =
    useUpdateModuleMutation();

  const [addFeature, { isLoading: loadingFeature }] = useAddFeatureMutation();
  const [addModule, { isLoading: loadingModule }] =
    useAddModulesFeaturesMutation();
  const [newModuleId, setNewModuleId] = useState();

  const getRowId = (params) => params.data.value;

  const _updateData = (params) => () => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.newValue;
    params.api.applyTransaction({ update: [data] });
  };

  const _onFail = (params) => () => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.oldValue;
    params.api.applyTransaction({ update: [data] });
  };

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(
    () => ({
      tooltipComponent: AgGridTooltip,
    }),
    []
  );

  const columnDefs = [
    {
      field: 'label',
      flex: 2,
      headerName: 'Feature Title',
      editable: true,
      valueSetter: (params) => {
        if (params?.newValue) {
          updateFeatureAPI(
            selectedModule?.id,
            params?.data?.value,
            params?.newValue,
            _updateData(params),
            _onFail(params)
          );
          return true;
        }
      },
      cellStyle: { color: '#447BF2', fontWeight: 600 },
    },
    {
      field: 'edit',
      flex: 0.3,
      headerName: 'Edit Feature',
      tooltipValueGetter: () => ({ value: 'Edit feature' }),
      tooltipComponentParams: () => ({
        value: 'Edit feature',
      }),
      cellRenderer: (params) => {
        return (
          <button onClick={() => handleUpdateFeature(params)}>
            <EditSVG />
          </button>
        );
      },
    },
    {
      field: 'status',
      flex: 0.3,
      headerName: 'Enable/Disable',
      tooltipValueGetter: (params) => ({
        value: `${
          params?.data?.disabled === true ? 'Enable' : 'Disable'
        } Feature`,
      }),
      tooltipComponentParams: (params) => ({
        value: `${
          params?.data?.disabled === true ? 'Enable' : 'Disable'
        } Feature`,
      }),
      cellRenderer: (params) => {
        return (
          <button onClick={() => handleEnableDisableFeature(params)}>
            {params?.data?.disabled === true ? <EnableSVG /> : <DisableSVG />}
          </button>
        );
      },
    },
  ];

  const handleEnableDisableModule = (makeEnable, oldValue) => {
    // * Optimistically updating field
    setStatus(makeEnable ? 'enable' : 'disable');
    enableDisableModule({
      moduleId: selectedModule?.id ? selectedModule?.id : newModuleId,
      makeEnable,
    })
      .unwrap()
      .then((res) => {
        toast.success('Module updated successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Module not updated!');
        // * Rollback in case of failure
        setStatus(oldValue);
      });
  };

  const handleValidation = () => {
    let isFormValid = true;
    const formErrors = {};
    if (!newFeature || newFeature === '') {
      isFormValid = false;
      formErrors['featureName'] = 'Field cannot be empty';
    }
    setFormErrors(formErrors);
    return isFormValid;
  };

  const handleUpdateFeature = (params) => {
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: params.columnApi.getDisplayedCenterColumns()[0].colId,
    });
  };

  const handleAddFeature = () => {
    let duplicateFeature = selectedModule?.features?.find(
      (feature) =>
        feature?.label?.toLowerCase()?.trim() ===
        newFeature?.toLowerCase()?.trim()
    );
    if (duplicateFeature) {
      toast.warning('Feature already present!');
      return;
    }
    if (handleValidation()) {
      addFeature({
        moduleId: selectedModule?.id ? selectedModule?.id : newModuleId,
        featureTitle: newFeature,
      })
        .unwrap()
        .then(() => {
          featureUpdated(selectedModule);
          if (newModuleId) {
            moduleAdded(newModuleId);
          } else {
            moduleUpdated(selectedModule);
          }
          toast.success('Feature added successfully!');
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Feature not added!');
        });
    }
  };

  const handleAddUpdateModule = () => {
    const duplicate = allModules.find(
      (module) =>
        module?.moduleTitle?.toLowerCase()?.trim() ===
        moduleName?.toLowerCase()?.trim()
    );
    if (duplicate) {
      toast.warning('Module already present!');
      return;
    }

    if (isNewModule) {
      addModule({
        moduleTitle: moduleName,
      })
        .unwrap()
        .then((res) => {
          toast.success('Module added successfully!');
          setNewModuleId(res?.data?.id);
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Module not added!');
        });
    } else {
      updateModule({
        moduleId: selectedModule?.id,
        moduleTitle: moduleName,
      })
        .unwrap()
        .then(() => {
          toast.success('Module updated successfully!');
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Module not updated!');
        });
    }
  };

  const _updateFeatureStatus = (params) => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = !params.data.disabled;
    params.api.applyTransaction({ update: [data] });
  };

  const _rollbackFeatureStatus = (params) => {
    let data = { ...params.data };
    let field = params.colDef.field;
    data[field] = params.data.disabled;
    params.api.applyTransaction({ update: [data] });
  };

  const handleEnableDisableFeature = (params) => {
    // * Optimistically update value
    _updateFeatureStatus(params);
    enableDisableFeature({
      moduleId: selectedModule?.id,
      featureId: params.data.value,
      makeEnable: params.data.disabled,
    })
      .unwrap()
      .then(() => {
        toast.success('Feature updated successfully!');
        featureUpdated(selectedModule);
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Feature not updated!');
        // * Rollback in case of failure
        _rollbackFeatureStatus(params);
      });
  };

  const updateFeatureAPI = (
    moduleId,
    featureId,
    featureTitle,
    updateData,
    onFail
  ) => {
    let duplicateFeature = selectedModule?.features?.find(
      (feature) =>
        feature?.label?.toLowerCase()?.trim() ===
        featureTitle?.toLowerCase()?.trim()
    );
    if (duplicateFeature) {
      toast.warning('Feature already present!');
      return;
    }
    // * Optimistically update value
    updateData();
    updateFeatureCallback({ moduleId, featureId, featureTitle })
      .unwrap()
      .then(() => {
        featureUpdated(selectedModule);
        moduleUpdated(selectedModule);
        toast.success('Feature updated successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Feature not updated!');
        // * Rollback in case of failure
        onFail();
      });
  };

  useEffect(() => {
    setStatus(selectedModule?.disabled === true ? 'disable' : 'enable');
    setModuleName(selectedModule?.label ? selectedModule?.label : '');
    setNewFeatue('');
  }, [selectedModule]);

  const loadingOverlayComponent = useMemo(() => {
    return AgGridLoader;
  }, []);

  useEffect(() => {
    if (!loadingAllModules) {
      gridRef?.current?.api?.hideOverlay();
    }
    if (loadingAllModules) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [loadingAllModules]);

  return (
    <div className="add-module-pg">
      <div className="modal-body form flex module-pg-header">
        <div className="form-field text-weight-semi-bold flex">
          <div>
            <label> Module Name </label>
            <div className="flex">
              <input
                className="module-pg-title"
                placeholder="Enter module name"
                value={moduleName}
                onChange={(event) => setModuleName(event?.target?.value)}
              />

              <div className="module-pg-title">
                <Button
                  className="ml-20 mt-2"
                  type="outline"
                  onClick={handleAddUpdateModule}
                  disabled={!moduleName || loadingModule || updatingModule}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="form-field text-weight-semi-bold">
          <div className="module-pg-status">
            <label>
              {' '}
              <span className="mt-2">
                <EnableDisableSVG />
              </span>{' '}
              <span className="ml-5">Status </span>{' '}
            </label>
            <div className="flex-align-center mt-5 mt-10 gap-4px">
              <input
                type="radio"
                name="jira-issue"
                checked={status === 'enable'}
                className="jira-item-radio ml-2"
                value="enable"
                onChange={() => handleEnableDisableModule(true, status)}
              />{' '}
              Enable
              <input
                type="radio"
                name="jira-issue"
                checked={status === 'disable'}
                className="jira-item-radio ml-20"
                value="disable"
                onChange={() => handleEnableDisableModule(false, status)}
              />{' '}
              Disable
            </div>
          </div>
        </div>
      </div>

      <div className="modal-body form module-pg-header">
        <span className="error">{formErrors['featureName']}</span>
        <div className="form-field flex text-weight-semi-bold">
          <div>
            <label>
              {' '}
              Features{' '}
              {selectedModule?.features &&
                `(${selectedModule?.features?.length})`}{' '}
            </label>
            <div className="flex">
              <input
                className="module-pg-title flex"
                placeholder="Enter feature name"
                value={newFeature}
                onChange={(event) => setNewFeatue(event?.target?.value)}
              />
              <div className="ml-20 mt-2">
                <Button
                  type="outline"
                  onClick={handleAddFeature}
                  disabled={!newFeature || loadingFeature || updatingFeature}
                >
                  Add Feature
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-pgi-list features-list">
        {!selectedModule ||
        !selectedModule?.features ||
        selectedModule?.features?.length < 1 ? (
          <NoModulePgData message="No features added" />
        ) : (
          <div className="ag-theme-alpine">
            <AgGridReact
              key="features-table"
              ref={gridRef}
              getRowId={getRowId}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={selectedModule?.features}
              loadingOverlayComponent={loadingOverlayComponent}
              tooltipShowDelay={1000}
              tooltipHideDelay={2000}
            />
          </div>
        )}
      </div>
    </div>
  );
}
