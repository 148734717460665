import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

export const fetchCrmStages = (
  service,
  handleSuccess,
  pipelineId = null,
  handleError = () => {}
) => {
  const profileData = JSON.parse(localStorage.getItem('user'));
  const companyId = profileData?.companyId;
  return service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.accounts.crmStages}?company_id=${companyId}${
      pipelineId ? `&pipeline_id=${pipelineId}` : ''
    }`,
    onSuccess: handleSuccess,
    onError: handleError,
  });
};
