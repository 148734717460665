import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { PRODUCT_GAP_TYPE } from '../../Constant/FilterOperators';
import { useFetchModulesFeaturesQuery } from '../ModulesManager/moduleFeaturesSlice';
import AssociateToJiraButton from './AssociateToJira';
import { useCheckIsJiraIntegratedQuery } from '../../app/api/usersSlice';
import { noData } from '../../Utils/common';
import { pgSelectStyles } from '../../Constant/ProuductGapDropdowns';
import { getSelectedTeam } from '../../Pipeline/utils/pipelineUtils';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';

const NewProductGapForm = ({
  formik,
  integrationType,
  onClose,
  onNext,
  productGaps,
}) => {
  const selectedTeam = getSelectedTeam();
  const { data: parentProductGap } = useFetchModulesFeaturesQuery(
    { selectedTeam },
    {
      selectFromResult: ({ data }) => {
        const options = useMemo(() => {
          return data?.filter((module) => !module.disabled); // * Filter out disabled modules
        }, [data]);
        return { data: options };
      },
    }
  );

  const { data: isJiraIntegrated } = useCheckIsJiraIntegratedQuery(undefined, {
    skip: integrationType !== 'jira',
  });

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !localStorage.getItem('user'),
  });

  const [existsInDeal, setExistsInDeal] = useState(false);

  const pgTitleExistsInDeal = (title) => {
    let AlreadyInDeal = false;
    Array.isArray(productGaps.data) &&
      productGaps.data.forEach((item) => {
        Array.isArray(item.pgItem) &&
          item.pgItem.forEach((pg) => {
            if (
              pg.title?.toLowerCase().trim() === title?.toLowerCase().trim()
            ) {
              AlreadyInDeal = true;
            }
          });
      });
    setExistsInDeal(AlreadyInDeal);
    return AlreadyInDeal;
  };

  const handleAssociatePGtoJira = (values) => {
    formik.setValues({
      ...formik.values,
      ...values,
    });
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleNext = () => {
    formik.setTouched(
      { type: true, module: true, feature: true, productGapItem: true },
      true
    );

    formik.validateForm();
    if (
      !formik.values.type.label ||
      !formik.values.module.label ||
      !formik.values.feature.label ||
      !formik.values.productGapItem
    ) {
      return;
    }
    const invalidPG = pgTitleExistsInDeal(formik.values.productGapItem);
    if (invalidPG) {
      return;
    }

    onNext(formik.values.productGapItem);
  };

  return (
    <div className="create_new_pg_form">
      <label className="form_label">
        Type
        <div className="form_field_control">
          <div className="form_field_container">
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isClearable
              className="form_select"
              styles={pgSelectStyles}
              noOptionsMessage={() => noData()}
              value={formik.values.type}
              options={PRODUCT_GAP_TYPE}
              onChange={(option) => {
                if (!option) {
                  formik.setFieldValue('type', { label: '' });
                  return;
                }
                formik.setFieldValue('type', option);
              }}
              menuPlacement="auto"
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.type && Boolean(formik.errors.type) && (
            <span className="form_error">{formik.errors.type}</span>
          )}
        </div>
      </label>
      <label className="form_label">
        Module
        <div className="form_field_control">
          <div className="form_field_container">
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isClearable
              className="form_select"
              styles={pgSelectStyles}
              noOptionsMessage={() => noData()}
              value={formik.values.module}
              options={parentProductGap ?? []}
              onChange={(option) => {
                if (!option) {
                  formik.setValues({
                    ...formik.values,
                    module: { label: '' },
                    feature: { label: '' },
                  });
                  return;
                }
                formik.setValues({
                  ...formik.values,
                  module: option,
                  feature: { label: '' },
                });
              }}
              menuPlacement="auto"
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.module && Boolean(formik.errors.module) && (
            <span className="form_error">{formik.errors.module}</span>
          )}
        </div>
      </label>
      <label className="form_label">
        Feature
        <div className="form_field_control">
          <div className="form_field_container">
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              isClearable
              className="form_select"
              styles={pgSelectStyles}
              noOptionsMessage={() =>
                noData(
                  !formik.values.module.label
                    ? 'Please select module'
                    : 'No options'
                )
              }
              value={formik.values.feature}
              options={
                // * Filter out New Feature and disabled features
                formik.values.module?.features?.filter((feature) => {
                  return !feature.disabled && feature.label !== 'New Feature';
                }) ?? []
              }
              onChange={(option) => {
                if (!option) {
                  formik.setFieldValue('feature', { label: '' });
                  return;
                }
                formik.setFieldValue('feature', option);
              }}
              menuPlacement="auto"
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.feature && Boolean(formik.errors.feature) && (
            <span className="form_error">{formik.errors.feature}</span>
          )}
        </div>
      </label>
      <label className="form_label">
        Product Gap
        <div className="form_field_control">
          <div className="form_field_container">
            <input
              aria-invalid="true"
              aria-describedby="pg-item-helper-text"
              id="pg-item"
              name="productGapItem"
              type="text"
              className="form_field"
              placeholder="Enter Product Gap"
              value={formik.values.productGapItem}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></input>
          </div>
          {formik.touched.productGapItem &&
            Boolean(formik.errors.productGapItem) && (
              <span className="form_error">{formik.errors.productGapItem}</span>
            )}
        </div>
      </label>
      {integrationType === 'jira' && isJiraIntegrated && (
        <AssociateToJiraButton
          onSelect={handleAssociatePGtoJira}
          isJiraReporterDisable={
            configFeatures?.data?.jiraReporterDisable === true
          }
        />
      )}
      {formik?.values?.jiraIssueId && (
        <p className="form_error alert-message--info">
          {formik?.values?.jiraIssueKey} has been selected.
        </p>
      )}
      {existsInDeal && (
        <span className="form_error">Product gap already in deal</span>
      )}
      <div className="button_group">
        <button className="btn_cancel" onClick={handleClose} type="button">
          Cancel
        </button>
        <button onClick={handleNext} className="btn_next" type="button">
          Next
        </button>
      </div>
      {(formik.touched.type && !formik.errors.type) ||
      (formik.touched.module && !formik.errors.module) ||
      (formik.touched.module && !formik.errors.module) ||
      formik.values.productGapItem ? (
        <p className="form_error form_error__next">Click Next to add usecase</p>
      ) : null}
    </div>
  );
};

export default NewProductGapForm;
