import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ManageUsecase from './ManageUsecase';
import {
  saveSelectedModule,
  useCompleteSolutionMutation,
  useReopenSolutionMutation,
} from './solutionSlice';
import { toast } from 'react-toastify';
import TimeSpentPopUp from './TimeSpentPopUp';
import ManageAndEditUsecase from './ManageAndEditUsecase';
import SolutionUseCaseItem from './SolutionUseCaseItem';
import {
  selectSelectedCard,
  updateDealStage,
} from '../../Pipeline/pipelineSlice';

const SolutionUsecaseList = ({
  list = [],
  filters = {
    modules: { all: true },
    features: { all: true },
    score: { all: true },
    searchTerm: '',
  },
  account,
  moduleList,
  getSolution,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const selectedCard = useSelector(selectSelectedCard);
  const [completeSolution] = useCompleteSolutionMutation();
  const [reopenSolution] = useReopenSolutionMutation();
  const [timeSpentPopUp, setTimeSpentPopUp] = useState(false);
  const [showManageUsecaseModal, setShowManageUsecaseModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState({});
  const [editModuleId, setEditModuleId] = useState();
  const [editUsecases, setEditUsecases] = useState({});
  const [showEditUsecaseModal, setShowEditUsecaseModal] = useState(false);

  const closeEditUsecaseModalWhileDelete = () => {
    setShowEditUsecaseModal(false);
    getSolution();
    dispatch(saveSelectedModule([]));
  };

  const handleSuccessUseCaseAction = () => {
    if (selectedCard?.cardId) {
      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
        })
      );
    }
    closeEditUsecaseModalWhileDelete();
  };

  const handleSuccessManageUseCase = () => {
    if (selectedCard?.cardId) {
      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
        })
      );
    }
  };

  const closeEditUsecaseModal = () => {
    setShowManageUsecaseModal(false);
    getSolution();
    dispatch(saveSelectedModule([]));
  };

  const handleManageUsecases = (moduleId) => {
    setEditModuleId(moduleId);
    setShowManageUsecaseModal(true);
  };

  const handleCompleteSolution = (moduleId, usecase) => {
    const usecaseResponseBody = {
      ...usecase,
      moduleId: moduleId,
      accountId: account?.accountId,
      dealId: account?.dealId,
    };
    completeSolution(usecaseResponseBody)
      .unwrap()
      .then((res) => {
        if (res?.status) {
          handleSuccessUseCaseAction();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleReopenSolution = (moduleId, usecase) => {
    const usecaseResponseBody = {
      ...usecase,
      moduleId: moduleId,
      accountId: account?.accountId,
      dealId: account?.dealId,
    };
    reopenSolution(usecaseResponseBody)
      .unwrap()
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleTimeSpentPopoverCancel = () => {
    setTimeSpentPopUp(false);
  };

  const handleTimeSpentOpenPopUp = (solution) => {
    setSelectedSolution(solution);
    setTimeSpentPopUp(true);
  };

  const editUsecase = (usecase, solution) => {
    dispatch(
      saveSelectedModule([{ label: solution.moduleTitle, value: solution.id }])
    );
    setSelectedSolution(solution);
    setEditUsecases(usecase);
    setShowEditUsecaseModal(true);
  };

  return (
    <>
      <div className="solution-list--container">
        {Array.isArray(list) &&
          list.length > 0 &&
          list.map((solution) => {
            let solutionCopy = { ...solution };
            let filteredUsecases = solution.usecases
              .filter(
                (usecase) =>
                  (usecase.moduleFeatures.length &&
                    usecase.moduleFeatures.some(
                      (feature) =>
                        filters?.features[`${solution.id}-${feature?.value}`] ||
                        filters.features.all
                    )) ||
                  (!usecase.moduleFeatures.length && filters.features.all)
              )
              .filter(
                (usecase) =>
                  filters?.score[usecase?.rating] || filters.score.all
              )
              .filter(({ usecase = '' }) =>
                filters.searchTerm
                  ? usecase &&
                    usecase
                      .toLowerCase()
                      .includes(filters.searchTerm.toLowerCase())
                  : true
              );
            solutionCopy.usecases = filteredUsecases;

            return solutionCopy?.usecases?.length > 0 &&
              (filters?.modules.all || filters?.modules[solution?.id]) ? (
              <SolutionUseCaseItem
                key={solution?.id}
                solution={solutionCopy}
                handleEdit={readOnly ? false : editUsecase}
                handleReopen={readOnly ? false : handleReopenSolution}
                handleComplete={readOnly ? false : handleCompleteSolution}
                handleTimeSpent={readOnly ? false : handleTimeSpentOpenPopUp}
                handleManageUsecases={readOnly ? false : handleManageUsecases}
              />
            ) : null;
          })}
      </div>
      {showManageUsecaseModal && (
        <ManageUsecase
          account={account}
          showManageUsecaseModal={showManageUsecaseModal}
          handleAddModuleCancel={closeEditUsecaseModal}
          moduleId={editModuleId}
          modules={moduleList}
          handleSuccessManageUseCase={handleSuccessManageUseCase}
        />
      )}
      <div>
        {showEditUsecaseModal && (
          <ManageAndEditUsecase
            account={account}
            selectedSolution={selectedSolution}
            moduleList={moduleList}
            editUsecases={editUsecases}
            showEditUsecaseModal={showEditUsecaseModal}
            handleCancelAddUsecase={closeEditUsecaseModalWhileDelete}
            handleSuccessUseCase={handleSuccessUseCaseAction}
          />
        )}
      </div>

      <div>
        {timeSpentPopUp && (
          <TimeSpentPopUp
            timeSpentPopUp={timeSpentPopUp}
            selectedSolution={selectedSolution}
            account={account}
            handlePopUpCancel={handleTimeSpentPopoverCancel}
            handleSuccess={handleSuccessUseCaseAction}
          />
        )}
      </div>
    </>
  );
};

export default SolutionUsecaseList;
