import React, { useMemo, useEffect, useState, Suspense } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ProductGap from '../ProductGap/ProductGap';
import Solution from '../Solution/index';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import { isAdminOrManager } from '../../Utils/common';
import Loader from '../../common/Loader';
import Button from '../../common/Button';
import DualRingLoader from '../../common/DualRingLoader';
import OverviewSection from './OverviewSection';
import OverviewMetrics from './OverviewMetrics';
import Alert from '../../common/Alert';
import EvaluationPlan from '../CheckList/EvaluationPlan';
import useDealDetails from '../../hooks/useDealDetails';
import { selectUserNameMap, useGetUsersQuery } from '../../app/api/usersSlice';
import Collaborators from '../../Accounts/Collabrators/Collabrators';
import { saveSelectedDealDetail } from '../../Pipeline/pipelineSlice';
import { useDispatch } from 'react-redux';
import DealTasks from './Tasks/DealTasks';
import DealNotes from './Notes/DealNotes';
import { useLazyReSyncCalendarOpportunityEventsQuery } from '../calendar/calendarSlice';
import mixpanel from 'mixpanel-browser';
import {
  useFetchGetTeamsByManagerQuery,
  useFetchPipelineAccessibilityQuery,
} from '../../app/api/teamsSlice';
import { isSameTeamAsPresalesUser } from '../../Pipeline/utils/pipelineUtils';
import { toast } from 'react-toastify';
import CalendarTabContent from './CalendarTabContent';
import DealIssues from '../IssueManager/DealIssuesView';

const TabContent = ({ children, isLoading, isError, onReload, dealId }) => {
  return (
    <>
      {isLoading || isError ? (
        <div className="loader-container">
          {isLoading ? <DualRingLoader dark /> : null}
          {isError && (
            <Alert type="danger" className="width-50vw">
              <div className="config-error">
                Something went wrong!
                <Button onClick={onReload}>Reload</Button>
              </div>
            </Alert>
          )}
        </div>
      ) : !dealId ? null : (
        children
      )}
    </>
  );
};

export default function PresentationView({
  dealId,
  presalesUser,
  showHeader,
  salesforceView = false,
  reloadLaneOnSEUserChange,
  selectedUser,
}) {
  const dispatch = useDispatch();

  const {
    data: configFeatures,
    isLoading: loadingConfig,
    isError: configError,
    refetch: refetchConfig,
  } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const [reSync] = useLazyReSyncCalendarOpportunityEventsQuery();
  const {
    data: userNameMap,
    isLoading: userNameMapLoading,
    isError: userNameMapError,
  } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError }) => {
      // console.log('data', data);
      const users = useMemo(() => selectUserNameMap(data), [data?.entities]);
      return {
        data: users?.data ?? {},
        isLoading,
        isError,
      };
    },
  });
  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();
  const { data: newTeams } = useFetchGetTeamsByManagerQuery(undefined, {
    skip: !isAdminOrManager() && pipelineAccessibility?.data !== 'user',
  });

  const {
    dealDetails,
    error: isError,
    refetch: fetchDealDetails,
    loading: dealDetailsLoading,
    fetching: fetchingDealDetails,
  } = useDealDetails(dealId, salesforceView);

  useEffect(() => {
    dispatch(saveSelectedDealDetail(dealDetails));
  }, [dealDetails]);

  const isReadOnly = (
    presalesUserEmail,
    collaboratedDeal,
    salesforceView,
    pipelineAccessibility
  ) => {
    // TODO: Remove salesforceView attribute and comments in the next iteration if no longer needed
    const profileData = JSON.parse(localStorage.getItem('user'));
    const userEmail = profileData?.email;
    const roles = profileData?.roles;
    // if (salesforceView) {
    // * Checks if the deal belongs to the user or if the user is a collaborator in the deal
    if (presalesUserEmail === userEmail || collaboratedDeal) {
      return false;
    }
    const isTeamMate = isSameTeamAsPresalesUser(
      newTeams?.data,
      presalesUserEmail,
      userEmail
    );
    // * If the above condition is not met, checks if the user is team mates with the deal owner in any of the teams
    if (!isTeamMate) {
      return true;
    }
    // * If the above two conditions are not met
    if (
      pipelineAccessibility === 'user' ||
      roles?.includes('admin') ||
      roles?.includes('manager')
    ) {
      return false;
    }
    // }
    // return roles?.includes('sales') || roles?.includes('product');
    // * If none of the above conditions are met
    return true;
  };

  const onTabSelect = (_, __, e) => {
    if (e.target.innerText === 'Product Gap') {
      try {
        mixpanel.track('pg_tab_accessed', {
          event: 'Product gap tab accessed',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
    }
  };

  const handleCalendarAutomationSync = () => {
    reSync({
      accountId: dealDetails?.accountId,
      dealId: dealDetails?.dealId,
    })
      .then((data) => {
        toast.success('Calendar opportunity events sync started');
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Calendar opportunity events sync failed');
      });
  };

  return (
    <div
      className={`presentation-view ${
        salesforceView ? 'salesforce-presentation-view' : ''
      }`}
    >
      {showHeader && (
        <div className="presentation-view--header">
          <h1 className="heading_level3">{dealDetails?.name}</h1>
          <h4 className="heading_level5 account-name">
            {dealDetails?.accountName}
          </h4>
        </div>
      )}
      <OverviewMetrics
        accountId={dealDetails?.accountId}
        dealId={dealId}
        loading={dealDetailsLoading}
      />
      {loadingConfig ? (
        <Loader />
      ) : configError ? (
        <Alert type="danger" className="m-20">
          <div className="config-error">
            Something went wrong!
            <Button onClick={refetchConfig}>Reload</Button>
          </div>
        </Alert>
      ) : (
        <Tabs
          className="react-tabs presentation_view--tabs"
          onSelect={onTabSelect}
        >
          <TabList className="react-tabs__tab-list primary-section presentation_tab--list">
            {configFeatures?.data?.featureList?.is_overview_enabled && (
              <Tab>Overview</Tab>
            )}
            {configFeatures?.data?.featureList?.is_calendar_enabled && (
              <Tab>Calendar & Activity</Tab>
            )}
            {dealDetails?.evaluationPlanPresent &&
              configFeatures?.data?.featureList?.is_evaluation_plan_enabled && (
                <Tab>Templates</Tab>
              )}
            {configFeatures?.data?.featureList?.is_solution_enabled && (
              <Tab>Solutions</Tab>
            )}
            {configFeatures?.data?.featureList?.is_product_gap_enabled &&
              configFeatures?.data?.navPages?.product_gap_use_case && (
                <Tab>Product Gap</Tab>
              )}
            {configFeatures.data?.featureList?.is_issue_manager_enabled && (
              <Tab>Issues</Tab>
            )}
            <Tab>Collaborators</Tab>
            <Tab>Tasks</Tab>
            <Tab>Notes</Tab>
          </TabList>
          <div className="presentation_view--tabPanelContainer">
            <>
              {configFeatures?.data?.featureList?.is_overview_enabled && (
                <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                  <TabContent
                    isLoading={userNameMapLoading || dealDetailsLoading}
                    isError={isError}
                    onReload={fetchDealDetails}
                    dealId={dealDetails?.dealId}
                  >
                    <OverviewSection
                      userNameMapError={userNameMapError}
                      salesforceView={salesforceView}
                      reloadLaneOnSEUserChange={reloadLaneOnSEUserChange}
                      dealDetails={dealDetails}
                      fetchDealDetails={fetchDealDetails}
                      presalesUserId={
                        isAdminOrManager() ||
                        pipelineAccessibility?.data === 'user'
                          ? userNameMap[
                              presalesUser ?? dealDetails?.presalesUser
                            ]
                          : null
                      }
                      loading={fetchingDealDetails}
                      dealDetailsError={isError}
                      readOnly={isReadOnly(
                        dealDetails?.presalesUser?.email,
                        dealDetails?.collabratedDeal,
                        salesforceView,
                        pipelineAccessibility?.data
                      )}
                      selectedUser={selectedUser}
                    />
                  </TabContent>
                </TabPanel>
              )}
              {configFeatures?.data?.featureList?.is_calendar_enabled && (
                <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                  <TabContent
                    isLoading={userNameMapLoading || dealDetailsLoading}
                    isError={isError}
                    onReload={fetchDealDetails}
                    dealId={dealDetails?.dealId}
                  >
                    <CalendarTabContent
                      dealDetails={dealDetails}
                      handleCalendarAutomationSync={
                        handleCalendarAutomationSync
                      }
                      isReadOnly={isReadOnly(
                        dealDetails?.presalesUser?.email,
                        dealDetails?.collabratedDeal,
                        salesforceView,
                        pipelineAccessibility?.data
                      )}
                    />
                  </TabContent>
                </TabPanel>
              )}
              {dealDetails?.evaluationPlanPresent &&
                configFeatures?.data?.featureList
                  ?.is_evaluation_plan_enabled && (
                  <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                    <TabContent
                      isLoading={dealDetailsLoading}
                      isError={isError}
                      onReload={fetchDealDetails}
                      dealId={dealDetails?.dealId}
                    >
                      <EvaluationPlan
                        deal={dealDetails}
                        readOnly={isReadOnly(
                          dealDetails?.presalesUser?.email,
                          dealDetails?.collabratedDeal,
                          salesforceView,
                          pipelineAccessibility?.data
                        )}
                      />
                    </TabContent>
                  </TabPanel>
                )}
              {configFeatures?.data?.featureList?.is_solution_enabled && (
                <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                  <TabContent
                    isLoading={dealDetailsLoading}
                    isError={isError}
                    onReload={fetchDealDetails}
                    dealId={dealDetails?.dealId}
                  >
                    <Solution
                      account={dealDetails}
                      readOnly={isReadOnly(
                        dealDetails?.presalesUser?.email,
                        dealDetails?.collabratedDeal,
                        salesforceView,
                        pipelineAccessibility?.data
                      )}
                    />
                  </TabContent>
                </TabPanel>
              )}
              {configFeatures?.data?.featureList?.is_product_gap_enabled &&
                configFeatures?.data?.navPages?.product_gap_use_case && (
                  <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                    <TabContent
                      isLoading={dealDetailsLoading}
                      isError={isError}
                      onReload={fetchDealDetails}
                      dealId={dealDetails?.dealId}
                    >
                      <ProductGap
                        account={dealDetails}
                        isPresentationView={true}
                        readOnly={isReadOnly(
                          dealDetails?.presalesUser?.email,
                          dealDetails?.collabratedDeal,
                          salesforceView,
                          pipelineAccessibility?.data
                        )}
                      />
                    </TabContent>
                  </TabPanel>
                )}
              {configFeatures.data?.featureList?.is_issue_manager_enabled && (
                <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                  <TabContent
                    isLoading={dealDetailsLoading}
                    isError={isError}
                    onReload={fetchDealDetails}
                    dealId={dealDetails?.dealId}
                  >
                    <DealIssues
                      account={dealDetails}
                      isPresentationView={true}
                      readOnly={isReadOnly(
                        dealDetails?.presalesUser?.email,
                        dealDetails?.collabratedDeal,
                        salesforceView,
                        pipelineAccessibility?.data
                      )}
                    />
                  </TabContent>
                </TabPanel>
              )}
              <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                <TabContent
                  isLoading={dealDetailsLoading}
                  isError={isError}
                  onReload={fetchDealDetails}
                  dealId={dealDetails?.dealId}
                >
                  <Collaborators
                    dealId={dealDetails?.dealId}
                    accountId={dealDetails?.accountId}
                    presalesUser={dealDetails?.presalesUser}
                    readOnly={isReadOnly(
                      dealDetails?.presalesUser?.email,
                      dealDetails?.collabratedDeal,
                      salesforceView,
                      pipelineAccessibility?.data
                    )}
                  />
                </TabContent>
              </TabPanel>

              <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                <TabContent
                  isLoading={dealDetailsLoading}
                  isError={isError}
                  onReload={fetchDealDetails}
                  dealId={dealDetails?.dealId}
                >
                  <DealTasks
                    dealId={dealDetails?.dealId}
                    accountId={dealDetails?.accountId}
                    dealDetails={dealDetails}
                    readOnly={isReadOnly(
                      dealDetails?.presalesUser?.email,
                      dealDetails?.collabratedDeal,
                      salesforceView,
                      pipelineAccessibility?.data
                    )}
                  />
                </TabContent>
              </TabPanel>
              <TabPanel className="react-tabs__tab-panel presentation_view--tabPanel">
                <TabContent
                  isLoading={dealDetailsLoading}
                  isError={isError}
                  onReload={fetchDealDetails}
                  dealId={dealDetails?.dealId}
                >
                  <DealNotes
                    dealId={dealDetails?.dealId}
                    accountId={dealDetails?.accountId}
                    dealDetails={dealDetails}
                    readOnly={isReadOnly(
                      dealDetails?.presalesUser?.email,
                      dealDetails?.collabratedDeal,
                      salesforceView,
                      pipelineAccessibility?.data
                    )}
                  />
                </TabContent>
              </TabPanel>
            </>
          </div>
        </Tabs>
      )}
    </div>
  );
}
