import React, { useCallback, useMemo, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import AddUpdatePgDisplay from './AddUpdatePgDisplay';
import MainForm from './MainForm';
import { useFormik } from 'formik';
import { ReactComponent as CloseIcon } from '../../Icons/accounts-close.svg';
import DOMPurify from 'dompurify';
import { convertHTMLtoPlainText } from '../../Utils/common';
import {
  productGapState,
  useGetRevenueBlockedQuery,
  useUpdateJiraProductGapMutation,
  useUpdateProductGapMutation,
} from './productGapSlice';
import { useSelector } from 'react-redux';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';

const UpdateProductGap = ({
  showModal,
  account,
  isJiraIntegrated,
  handleProductGapsAfterUpdating,
  handleCloseModal,
}) => {
  const [integrationType, setIntegrationType] = useState(null);
  const { selectedProductGap: productGap } = useSelector(productGapState);
  const { data: avgRevBlocked } = useGetRevenueBlockedQuery(
    productGap?.productGapItemId,
    { skip: !productGap?.productGapItemId }
  );
  const { data: configCompanyResponse } = useFetchCompanyConfigDataQuery(
    undefined,
    {
      skip: !JSON.parse(localStorage.getItem('user')),
      selectFromResult: ({ data }) => ({ data: data?.data }),
    }
  );
  const [update] = useUpdateProductGapMutation();
  const [jiraUpdate] = useUpdateJiraProductGapMutation();
  const validation = (values) => {
    const requiredFields = ['usecase', 'rating'];
    const errors = {};
    requiredFields.forEach((key) => {
      if (key === 'usecase') {
        if (!values[key] || values[key] === '<p><br></p>') {
          errors[key] = 'Required';
        }
      } else if (!values[key]) {
        errors[key] = 'Required';
      }
    });
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      usecase:
        integrationType === 'jira' && productGap.plainText
          ? productGap.plainText
          : productGap.useCase === '<p><br></p>' || !productGap.useCase
          ? ''
          : productGap.useCase,
      rating: productGap.rating ?? '',
      competitors: productGap.competitors ?? [],
      impactsWhom: productGap.impactsWhom ?? [],
    },
    enableReinitialize: true,
    validate: validation,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      const sanitizedUsecase = DOMPurify.sanitize(values.usecase);
      const data = {
        id: productGap.id,
        accountId: account?.accountId,
        dealId: account?.dealId,
        productGapType: productGap.productGapType,
        module: productGap.pgItem[0]?.module,
        feature: productGap.pgItem[0]?.feature,
        title: productGap.pgItem[0]?.title,
        productGapItem: {
          value: productGap.pgItem[0]?.title,
          label: productGap.pgItem[0]?.title,
        },
        rating: values.rating,
        useCase: sanitizedUsecase,
        plainText: convertHTMLtoPlainText(sanitizedUsecase),
        competitors: values.competitors,
        impactsWhom: values.impactsWhom,
      };

      if (isJiraIntegrated) {
        data.jiraCommentId = productGap.issueCommentId;
        jiraUpdate(data)
          .unwrap()
          .then(() => {
            handleProductGapsAfterUpdating(data);
          });
      } else {
        update(data)
          .unwrap()
          .then(() => {
            handleProductGapsAfterUpdating(data);
          });
      }
    },
  });

  const formikValues = useMemo(() => {
    return {
      type: { label: productGap?.productGapType },
      module: productGap?.pgItem[0]?.module,
      feature: productGap?.pgItem[0]?.feature,
    };
  }, [productGap]);

  const handleClose = useCallback((_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    handleCloseModal('update');
  }, []);

  useEffect(() => {
    if (!productGap?.productGapType) {
      setIntegrationType(null);
      return;
    }
    productGap?.productGapType === 'New Request'
      ? setIntegrationType(configCompanyResponse.newRequestIntegration)
      : setIntegrationType(configCompanyResponse.enhancementBugIntegration);
  }, [
    productGap?.productGapType,
    configCompanyResponse?.newRequestIntegration,
    configCompanyResponse?.enhancementBugIntegration,
  ]);

  return (
    <ReactModal
      isOpen={showModal}
      className="product-gap-form pg_update_form"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <section className="pg-root">
        <section className="pg-right p-26">
          {/* HEADER */}
          <div className="header-container">
            <h3 className="title">Update Product Gap</h3>
            <button
              className="btn-pg-close"
              type="button"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
              <span className="ripple"></span>
            </button>
          </div>

          <AddUpdatePgDisplay
            formikValues={formikValues}
            avgRevenueBlocked={avgRevBlocked}
          />
          <form onSubmit={formik.handleSubmit}>
            <MainForm
              formik={formik}
              handleClose={handleClose}
              dealValue={account?.dealValue}
              integrationType={integrationType}
            />
          </form>
        </section>
      </section>
    </ReactModal>
  );
};

export default UpdateProductGap;
