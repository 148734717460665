import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

const OutcomeTagsPercentage = ({
  isAdminOrManager,
  percentageAutomated,
  totalCount,
  pipelineAccessibility,
  adminSettings,
}) => {
  const overallSplitColors = ['#36b37e', '#de350b'];
  const percentage =
    Array.isArray(percentageAutomated) &&
    Math.floor((percentageAutomated[0].value / totalCount) * 100);

  return (
    <>
      {!adminSettings && (
        <div
          className={`title-description ${
            isAdminOrManager || pipelineAccessibility?.data === 'user'
              ? 'mt-20'
              : 'mt-40'
          } `}
        >
          Helps users manage Calendar Preferences
        </div>
      )}
      <div className="automation-percentage">
        <ResponsiveContainer width="5%" height={30}>
          <PieChart>
            <Pie
              data={percentageAutomated}
              outerRadius={15}
              fill="#8884d8"
              dataKey="value"
            >
              {percentageAutomated &&
                percentageAutomated.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={overallSplitColors[index]}
                  />
                ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {
          <div>
            <div className="bold-text">
              {percentage !== 0 && percentage !== 100 && <>Only </>}
              {percentage}% of your Calendar Tagging Operation is Automated
            </div>
            {percentage !== 100 && (
              <div>
                Please fill in all the Title Tags below to{' '}
                <span className="bold-text text-underline">
                  Completely Automate
                </span>{' '}
                your operations!
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default OutcomeTagsPercentage;
