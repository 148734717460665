import React from 'react';
import { productGapState } from './productGapSlice';
import { useSelector } from 'react-redux';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import { formatCount } from '../../Utils/Revenue';
import { createMarkup } from '../../Utils/common';
import ToolTip from '../../common/ToolTip';

const RATING_TEXT = {
  1: 'Requested',
  2: 'Good to have',
  3: 'Must have',
  4: 'Critical',
  5: 'Deal Breaker',
};
const RATING_COLOR = {
  1: 'requested',
  2: 'good',
  3: 'musthave',
  4: 'critical',
  5: 'dealbreaker',
};

const UseCasesList = ({ usecases }) => {
  const { selectedProductGap } = useSelector(productGapState);
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });

  return (
    <ul className="usecase_list_container vertical-scroll">
      {Array.isArray(usecases) && usecases.length > 0 ? (
        usecases.map((usecase) => {
          return (
            <li className="listItem_root" key={usecase.id}>
              <div className="listItem">
                <div className="listItemText_root">
                  <div className="listItemText_root_container">
                    <ToolTip
                      key={usecase.id}
                      text={usecase?.dealName}
                      placement="top"
                    >
                      <h4 className="list_subtitle2">{usecase?.dealName}</h4>
                    </ToolTip>
                    <div className="list_extra gap-default">
                      <p className="list_subtitle2">
                        {!usecase?.dealValue && usecase?.dealValue !== 0
                          ? '--'
                          : formatCount(usecase?.dealValue, true)}
                      </p>
                      <p className="list_subtitle2">
                        {userMap[usecase.createdBy] &&
                          userMap[usecase.createdBy].name}
                      </p>
                      <div
                        className={`chip_filled_container chip_color__${
                          RATING_COLOR[usecase.rating]
                        }`}
                      >
                        <span className="chip_label">
                          {usecase.rating
                            ? RATING_TEXT[usecase.rating]
                            : 'No rating'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="listItemBody_root">
                    <p
                      className="list_body2"
                      dangerouslySetInnerHTML={createMarkup(usecase?.useCase)}
                    ></p>
                    <div className="listItem_footer">
                      <p className="list_caption">
                        {selectedProductGap?.module?.label}
                      </p>
                      <hr className="divider_vertical" />
                      <p className="list_caption">
                        {selectedProductGap?.feature?.label}
                      </p>
                      <hr className="divider_vertical" />
                      <p className="list_caption">{usecase.createdDate}</p>
                    </div>
                  </div>
                </div>
                <span className="ripple"></span>
              </div>
            </li>
          );
        })
      ) : (
        <li className="listItem_root_empty">
          You are the first user to add use-case
        </li>
      )}
    </ul>
  );
};

export default UseCasesList;
