import React, { useEffect } from 'react';
import {
  useRouteMatch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import useDataPersistance from '../../hooks/useDataPersistance';
import RequestForm from './RequestForm';

const Index = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { getStorageData } = useDataPersistance();

  useEffect(() => {
    const defaultTab = getStorageData('requestForm');
    // Replacing default pathname
    if (location.pathname === '/requestForm') {
      history.replace(
        `/requestForm/${defaultTab ? defaultTab : 'allrequests'}`
      );
    }
  }, [location]);

  return (
    <Route path={`${path}/:tab`}>
      <RequestForm />
    </Route>
  );
};

export default Index;
