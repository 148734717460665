import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { selectAuthenticationState } from '../Authorize/authSlice';
import { useSelector } from 'react-redux';

const RequireAuth = ({ children, component, ...rest }) => {
  const authenticated = useSelector(selectAuthenticationState);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const REDIRECT_URLS = {
          '/pipeline/kanban': true,
          '/manager/ram': true,
          '/requestform/rrf': true,
          '/dashboard/calendar': true,
          '/dealDetails/': true,
          '/salesforce/': true,
        };
        let sendLocation = false;
        if (
          !authenticated &&
          REDIRECT_URLS[location?.pathname?.toLowerCase()]
        ) {
          sendLocation = true;
        }
        if (
          !authenticated &&
          !REDIRECT_URLS[location?.pathname] &&
          (location?.pathname?.startsWith('/dealDetails/') ||
            location?.pathname?.startsWith('/salesforce/'))
        ) {
          sendLocation = true;
        }
        return authenticated && location?.state?.from?.from?.pathname ? (
          <Redirect
            to={{
              pathname: location?.state?.from?.from?.pathname,
            }}
          />
        ) : authenticated ? (
          <>{component || children}</>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              from: sendLocation ? location : null,
            }}
          />
        );
      }}
    />
  );
};

export default RequireAuth;
