import React, { useState, useEffect } from 'react';
import Chart from './Chart';
import Select from 'react-select';
import useDebounce from '../hooks/useDebounce';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxios from '../hooks/useAxios';
import { useLazyFetchProductGapByFeatureQuery } from '../features/ProductGap/productGapSlice';
import { useFetchModulesFeaturesQuery } from '../features/ModulesManager/moduleFeaturesSlice';

const ProductGapCharts = ({ reports }) => {
  const axios = useAxiosPrivate();
  const [chartsInfo, setChartsInfo] = useState(null);
  const [chartError, setChartError] = useState({});
  const [features, setFeatures] = useState([]);
  const [productGaps, setProductGaps] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedProductGap, setSelectedProductGap] = useState(null);
  const [filter, setFilter] = useState({});
  const debouncedFilter = useDebounce(filter, 2000);
  const { loading: modulesLoading, ...modules } = useAxios(axios);

  const [getByFeature, { isFetching }] = useLazyFetchProductGapByFeatureQuery();
  const { data: moduleData } = useFetchModulesFeaturesQuery({});
  const commonCharts = [
    'Product Pulse',
    'Average Contract Revenue',
    'Potential Blocked Revenue',
    'Pulse Break down',
    'Won vs Loss Revenue',
    'Opportunity and PG Details',
    'Revenue Delta',
    'Module Details',
  ];

  //no filter
  const initialGraphs = ['Module', 'Opportunity value vs Module Impact value'];

  //Filter:module
  const selectedModuleCharts = [
    'Features',
    'Opportunity value vs Feature impact value',
  ];

  //Filter : module,feature
  const selectedFeatureCharts = [
    'Product Gap',
    'Opportunity value vs PG Impact value',
  ];

  //Filter : module,feature,productgap
  const selectedPGCharts = [
    'Product Gap Item',
    'Opportunity value vs PG Item impact value',
  ];

  const handleChartError = (chartID) => (err) =>
    setChartError((prev) => ({ ...prev, [chartID]: err }));

  const handleOnChange = (type) => (option) => {
    if (!option?.label) {
      if (type === 'module') {
        setSelectedModule(null);
        setSelectedFeature(null);
        setSelectedProductGap(null);

        updateFilter({
          module: null,
          feature: null,
          product_gap: null,
        });
        return;
      }
      if (type === 'feature') {
        setSelectedFeature(null);
        setSelectedProductGap(null);
        updateFilter({
          feature: null,
          product_gap: null,
        });
        return;
      }
      if (type === 'productGap') {
        setSelectedProductGap(null);
        updateFilter({
          product_gap: null,
        });
        return;
      }
      return;
    }

    if (type === 'module') {
      setSelectedModule(option);
      setFeatures(option.features || []);
      updateFilter({ module: option.label, feature: null, product_gap: null });

      //re-setting the value to null when module is being changed
      setSelectedFeature(null);
      setSelectedProductGap(null);
      setProductGaps([]);
    }

    if (type === 'feature') {
      setSelectedFeature(option);
      updateFilter({
        feature: option?.label,
        product_gap: null,
      });
      setSelectedProductGap(null);
      getProductGapByFeature(selectedModule.value, option.value);
    }

    if (type === 'productGap') {
      setSelectedProductGap(option);
      updateFilter({
        product_gap: option.label,
      });
    }
  };

  //api call to get productGaps based based on selectedFeature
  const getProductGapByFeature = (moduleId, featureId) => {
    getByFeature({
      moduleId: moduleId,
      featureId: featureId,
    })
      .unwrap()
      .then((res) => {
        setProductGaps(res.data);
      });
  };

  const updateFilter = (values = {}) => {
    const filterCopy = { ...filter };
    for (let key in values) {
      if (!values[key]) {
        filterCopy[key] && delete filterCopy[key];
      } else {
        filterCopy[key] = values[key];
      }
    }
    setFilter(filterCopy);
  };

  useEffect(() => {
    if (!reports || !Array.isArray(reports)) {
      return;
    }
    const charts = {};
    reports.forEach(({ module, chart_id, chart_url }) => {
      charts[module] = { chartID: chart_id, chartURL: chart_url };
    });
    setChartsInfo(charts);
  }, [reports]);

  return (
    <div className="dashboard-reporting">
      <div className="flex gap-default mb-30">
        <div>
          <label className="flex mb-10"> Module</label>
          <Select
            isClearable={true}
            isSearchable={false}
            isLoading={modulesLoading}
            placeholder="Select module"
            className="dropdown"
            options={moduleData}
            value={selectedModule}
            onChange={handleOnChange('module')}
          />
        </div>

        <div>
          <label className="flex mb-10"> Feature</label>
          <Select
            isClearable={true}
            isSearchable={false}
            placeholder="Select feature"
            options={features}
            value={selectedFeature}
            onChange={handleOnChange('feature')}
            isDisabled={!selectedModule}
            className="dropdown"
          />
        </div>

        <div>
          <label className="flex mb-10"> Product Gap </label>
          <Select
            isClearable={true}
            isSearchable={false}
            placeholder="Select productGap"
            options={productGaps}
            value={selectedProductGap}
            onChange={handleOnChange('productGap')}
            isDisabled={!selectedFeature || !selectedModule}
            isLoading={isFetching}
            className="dropdown"
          />
        </div>
      </div>
      <div className="charts-container">
        {/* chart for no filter */}
        {chartsInfo &&
          !filter.module &&
          initialGraphs.map((module, index) => {
            return !chartError[chartsInfo[module]?.chartID] &&
              chartsInfo[module]?.chartURL ? (
              <div
                className={index === 0 ? 'width-45' : 'width-55'}
                key={module}
              >
                <Chart
                  height={index === 0 ? '400px' : '500px'}
                  width="100%"
                  filter={debouncedFilter}
                  chartURL={chartsInfo[module]?.chartURL}
                  chartID={chartsInfo[module]?.chartID}
                  chartError={chartError[chartsInfo[module]?.chartID]}
                  setChartError={handleChartError(chartsInfo[module]?.chartID)}
                />
              </div>
            ) : (
              <div key={module} className="alert">
                Failed to load chart: Invalid chart url
              </div>
            );
          })}

        {/* chart for module filter*/}
        {chartsInfo &&
          filter?.module &&
          !filter?.product_gap &&
          !filter.feature &&
          selectedModuleCharts.map((module, index) => {
            return !chartError[chartsInfo[module]?.chartID] &&
              chartsInfo[module]?.chartURL ? (
              <div
                className={index === 0 ? 'width-45' : 'width-55'}
                key={module}
              >
                <Chart
                  height={index === 0 ? '300px' : '400px'}
                  width="100%"
                  filter={debouncedFilter}
                  chartURL={chartsInfo[module]?.chartURL}
                  chartID={chartsInfo[module]?.chartID}
                  chartError={chartError[chartsInfo[module]?.chartID]}
                  setChartError={handleChartError(chartsInfo[module]?.chartID)}
                />
              </div>
            ) : (
              <div key={module} className="alert">
                Failed to load chart: Invalid chart url
              </div>
            );
          })}

        {/* chart for module and feature filter */}
        {chartsInfo &&
          filter?.module &&
          filter?.feature &&
          !filter?.product_gap &&
          selectedFeatureCharts?.map((module, index) => {
            return !chartError[chartsInfo[module]?.chartID] &&
              chartsInfo[module]?.chartURL ? (
              <div
                className={index === 0 ? 'width-45' : 'width-55'}
                key={module}
              >
                <Chart
                  height={index === 0 || index === 1 ? '400px' : '600px'}
                  width="100%"
                  filter={debouncedFilter}
                  chartURL={chartsInfo[module]?.chartURL}
                  chartID={chartsInfo[module]?.chartID}
                  chartError={chartError[chartsInfo[module]?.chartID]}
                  setChartError={handleChartError(chartsInfo[module]?.chartID)}
                />
              </div>
            ) : (
              <div key={module} className="alert">
                Failed to load chart: Invalid chart url
              </div>
            );
          })}

        {/* chart for module,fetaure,productGap filter */}
        {chartsInfo &&
          filter.module &&
          filter.feature &&
          filter?.product_gap &&
          selectedPGCharts.map((module, index) => {
            return !chartError[chartsInfo[module]?.chartID] &&
              chartsInfo[module]?.chartURL ? (
              <div
                className={index === 0 ? 'width-45' : 'width-55'}
                key={module}
              >
                <Chart
                  height="400px"
                  width="100%"
                  filter={debouncedFilter}
                  chartURL={chartsInfo[module]?.chartURL}
                  chartID={chartsInfo[module]?.chartID}
                  chartError={chartError[chartsInfo[module]?.chartID]}
                  setChartError={handleChartError(chartsInfo[module]?.chartID)}
                />
              </div>
            ) : (
              <div key={module} className="alert">
                Failed to load chart: Invalid chart url
              </div>
            );
          })}

        {/* This chart will render for all filters */}
        {chartsInfo &&
          commonCharts.map((module, index) => {
            return !chartError[chartsInfo[module]?.chartID] &&
              chartsInfo[module]?.chartURL ? (
              <div
                className={
                  index === 0 || index === 1 || index === 2
                    ? 'width-30'
                    : index === 3
                    ? 'width-45'
                    : index == 4
                    ? 'width-55'
                    : 'width-full'
                }
                key={module}
              >
                <Chart
                  height={
                    index === 0 || index === 1 || index === 2
                      ? '300px'
                      : '400px'
                  }
                  width="100%"
                  filter={debouncedFilter}
                  chartURL={chartsInfo[module]?.chartURL}
                  chartID={chartsInfo[module]?.chartID}
                  chartError={chartError[chartsInfo[module]?.chartID]}
                  setChartError={handleChartError(chartsInfo[module]?.chartID)}
                />
              </div>
            ) : (
              <div key={module} className="alert">
                Failed to load chart: Invalid chart url
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductGapCharts;
