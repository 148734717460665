import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'quill-mention';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';

import getRtfProperties from '../Utils/GetRtfProperties';
import MyLink from './ReactQuillLink';
import { convertHTMLtoPlainText } from '../Utils/common';

ReactQuill.Quill.register(MyLink);

const RichTextEditor = ({
  placeholder,
  value,
  onChange,
  activeAccounts,
  className = '',
}) => {
  const { modules, formats } = useMemo(() => {
    return getRtfProperties(activeAccounts);
  }, []);
  return (
    <div className={className}>
      <ReactQuill
        placeholder={placeholder}
        value={value && convertHTMLtoPlainText(value) ? value : null}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default RichTextEditor;
