const PIPELINE_ACCESSIBILITY = [
  {
    label: 'Manager',
    value: 'manager',
  },
  {
    label: 'User',
    value: 'user',
  },
];

export { PIPELINE_ACCESSIBILITY };
