import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import ReactDOM from 'react-dom';
import React from 'react';

const ToolTip = ({
  text,
  children,
  trigger = 'hover',
  placement = 'auto',
  contentClassname = '',
  tooltipClass = '',
  showTooltip,
  portal,
  portalClass,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ trigger, placement, visible: showTooltip });

  return (
    <>
      <span className={contentClassname} ref={setTriggerRef}>
        {children}
      </span>

      {visible && portal ? (
        ReactDOM.createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: `tooltip-container${
                portalClass ? ` ${portalClass}` : ''
              }`,
            })}
          >
            {text}
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </div>,
          portal
        )
      ) : visible ? (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `tooltip-container ${tooltipClass}`,
          })}
        >
          {text}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      ) : null}
    </>
  );
};

export default ToolTip;
