import React, {
  useMemo,
  useRef,
  useState,
  Suspense,
  lazy,
  useEffect,
} from 'react';
import ButtonGroup from './ButtonGroup';
import Button from '../common/Button';
import Select from 'react-select';
import { ReactComponent as SettingsSVG } from '../Icons/NewImage/settings.svg';
import CalendarView from '../features/calendar/Calendar';
import {
  saveSelectedCalendarEvent,
  useLazyReSyncCalendarAutoTagQuery,
} from '../features/calendar/calendarSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as CalendarViewSVG } from '../Icons/NewImage/calender.svg';
import { ReactComponent as ListViewSVG } from '../Icons/NewImage/list.svg';
import { ReactComponent as ArrowLeft } from '../Icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../Icons/ArrowRight.svg';
import { ReactComponent as FilterIcon } from '../Icons/NewFilter2.svg';
import { ReactComponent as TeamIcon } from '../Icons/Team.svg';
import { ReactComponent as SyncSVG } from '../Icons/Sync.svg';
import { ReactComponent as SyncCalendarSVG } from '../Icons/sync-calendar.svg';
import TasksDashboard from '../common/tasks/TasksDashboard';
import SideNav from './SideNav';
import SearchField from '../Accounts/commons/SearchField';
import { useParams, useHistory } from 'react-router-dom';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import { isAdminOrManager, isProductUser, isSalesUser } from '../Utils/common';
import { useFetchAllTeamsQuery } from '../app/api/teamsSlice';
import CalendarModal from '../common/CalendarModal';
import Drawer from '../common/Drawer';
import DualRingLoader from '../common/DualRingLoader';
import { commonStyles } from '../Pipeline/utils/PipelineDropdownStyles';
import { toast } from 'react-toastify';
import {
  getDateByOperator,
  getDateByOperatorForFilter,
} from '../Utils/DateUtils';
import {
  DATE_RANGE_WITH_CUSTOM,
  DATE_TYPE_OPTIONS,
  MEETING_EVENT,
  MEETING_STATUS,
} from '../Constant/MeetingFilterOptions';
import mixpanel from 'mixpanel-browser';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useCloseComponentOnOutsideClick from '../hooks/useCloseComponentOnOutsideClick';
import { useGetParentContributionQuery } from '../app/api/contributionSlice';
import ToolTip from '../common/ToolTip';
import { useLazyPullEventsFromCalendarQuery } from '../app/api/dataEngineeringService';
const ListView = lazy(() => import('../features/calendar/ListView'));
const CalendarFilter = lazy(() =>
  import('../features/calendar/CalendarFilter')
);
const CalendarPreferences = lazy(() =>
  import('../features/calendar/CalendarPreferences')
);
const CalendarList = lazy(() => import('../features/calendar/CalendarList'));
const Meetings = lazy(() => import('../common/WorkbooksMeetings'));
const DealPresentationView = lazy(() =>
  import('../features/DealPresentationView')
);

const customToggleDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    ...commonStyles,
    outline: state.isFocused ? 0 : null,
    '&:focus': {
      borderColor: '#E2EBF3',
    },
    minHeight: '32px',
    height: '32px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : '',
    color: '#152b47',
    cursor: 'pointer',
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    height: '32px',
  }),
};

const DashboardMain = ({ path }) => {
  const { tab, dealID, recent } = useParams();
  const activeTab = !tab
    ? localStorage.getItem('calendarView') ?? 'calendar'
    : tab;
  const history = useHistory();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const [updateCalendar, setUpdateCalendar] = useState();
  const [showCalendarView, setShowCalendarView] = useState(false);
  const [activeWorkbook, setActiveWorkbook] = useState('tasks');
  const [preferences, setPreferences] = useState(false);

  const {
    containerRef: filtersContainerRef,
    openComponent: showFilterModal,
    setOpenComponent: setShowFilterModal,
  } = useCloseComponentOnOutsideClick('custom-event-title');

  const handleShowCalendarView = () => {
    setShowCalendarView(true);
    try {
      mixpanel.track('activity_dashboard_team_calendar', {
        event: 'Team calendar accessed',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };
  const handleCloseCalendarView = () => {
    setShowCalendarView(false);
  };

  const hidePreferences = () => {
    if (preferences) {
      setPreferences(false);
    }
  };
  const togglePreferences = () => setPreferences(!preferences);

  useEffect(() => {
    if (activeTab === 'calendar' && activeWorkbook) {
      return;
    }
    if (activeTab === 'calendar' && !activeWorkbook) {
      setActiveWorkbook('tasks');
      return;
    }
    if (activeTab === 'table' && !activeWorkbook) {
      return;
    }
    if (activeTab === 'table' && activeWorkbook) {
      setActiveWorkbook(null);
      return;
    }
  }, [activeTab]);

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  const [pullEvents] = useLazyPullEventsFromCalendarQuery();

  const [reSync] = useLazyReSyncCalendarAutoTagQuery();

  const handleCalendarAutomationSync = () => {
    reSync()
      .then((data) => {
        toast.success('Calendar automation sync started');
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Calendar automation sync failed');
      });
  };

  const { data: users } = useFetchAllTeamsQuery(undefined, {
    skip: !isAdminOrManager(),
    selectFromResult: ({ data }) => {
      const users = useMemo(() => {
        const userDetails = localStorage.getItem('user');
        let userId;
        if (userDetails) {
          userId = JSON.parse(localStorage.getItem('user'))?.userId;
        }
        return Array.isArray(data?.data) && data.data.length
          ? data.data[0].teamMembers.filter((user) => user.id !== userId)
          : [];
      }, [data?.data]);
      return {
        data: users,
      };
    },
  });

  const { data: contributionMap, outcomesOptions } =
    useGetParentContributionQuery(undefined, {
      selectFromResult: ({ data }) => {
        const options = useMemo(() => {
          let optionValues = [];
          optionValues =
            data?.entities &&
            Object.values(data?.entities).map((outcome) => ({
              label: outcome?.title,
              value: outcome?.id,
            }));
          return optionValues;
        }, [data]);
        return {
          data,
          outcomesOptions: options ?? [],
        };
      },
    });

  useEffect(() => {
    if (activeTab === 'table') {
      setActiveWorkbook(!showFilterModal ? null : 'calendar_info');
    }
  }, [showFilterModal]);

  const openCalendarInSidebar = (calendarEventInfo, selectedTab) => {
    const calendarEventInfoCopy = { ...calendarEventInfo };
    if (calendarEventInfo && activeTab === 'table') {
      setShowFilterModal(true);
    }
    if (calendarEventInfo && activeWorkbook !== 'calendar_info') {
      setActiveWorkbook('calendar_info');
    }
    if (!calendarEventInfo) {
      setActiveWorkbook('meetings');
    }
    if (calendarEventInfo && selectedTab) {
      calendarEventInfoCopy.selectedTab = selectedTab;
    }
    dispatch(saveSelectedCalendarEvent(calendarEventInfoCopy));
  };

  const [openCalendarFilter, setOpenCalendarFilter] = useState(false);
  const [searchParticipantEmail, setSearchParticipantEmail] = useState();
  const searchParticipant = (e) => {
    const searchValue = e.target.value;
    if (!searchValue) {
      setSearchParticipantEmail('');
    }
    setSearchParticipantEmail(e.target.value);
  };

  const [meetingFilter, setMeetingFilter] = useState({});

  useEffect(() => {
    const outcomeFilter = outcomesOptions?.map((outcome) => outcome?.value);
    if (recent === 'recently-created') {
      const { fromDate: from, toDate: to } = getDateByOperator(
        DATE_RANGE_WITH_CUSTOM[4].value
      );
      const { fromDate, toDate } = getDateByOperatorForFilter(
        DATE_RANGE_WITH_CUSTOM[4].value
      );
      try {
        mixpanel.track('recently_created_accessed', {
          event: 'Accessed recently created view',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      const filterMeeting = {
        startDate: null,
        endDate: null,
        createdDateFrom: from,
        createdDateTo: to,
        meetingStatus: [MEETING_STATUS[2]],
        meetingEvent: [MEETING_EVENT[1]],
        dateRangeType: [DATE_TYPE_OPTIONS[1]],
      };

      setMeetingFilter(filterMeeting);
      const filterMeetingForLS = {
        ...filterMeeting,
        startDate: fromDate,
        endDate: toDate,
      };
      localStorage.setItem(
        'calendar_table_filter',
        JSON.stringify(filterMeetingForLS)
      );
      return;
    }

    if (recent === 'untaggedtoday') {
      const { fromDate: from, toDate: to } = getDateByOperator(
        DATE_RANGE_WITH_CUSTOM[1].value
      );
      const { fromDate, toDate } = getDateByOperatorForFilter(
        DATE_RANGE_WITH_CUSTOM[1].value
      );
      const filterMeeting = {
        startDate: from,
        endDate: to,
        createdDateFrom: null,
        createdDateTo: null,
        meetingStatus: [MEETING_STATUS[2]],
        meetingEvent: [MEETING_EVENT[0]],
        dateRangeType: [DATE_TYPE_OPTIONS[0]],
        dateRange: DATE_RANGE_WITH_CUSTOM[1],
        outcomes: outcomeFilter,
      };
      setMeetingFilter(filterMeeting);
      const filterMeetingForLS = {
        ...filterMeeting,
        startDate: fromDate,
        endDate: toDate,
      };
      localStorage.setItem(
        'calendar_table_filter',
        JSON.stringify(filterMeetingForLS)
      );
      return;
    }
  }, [outcomesOptions]);

  useEffect(() => {
    if (recent !== 'recently-added' && recent !== 'untaggedtoday') {
      const { fromDate: from, toDate: to } = getDateByOperator(
        DATE_RANGE_WITH_CUSTOM[4].value
      );

      const localStoreData = localStorage.getItem('calendar_table_filter');
      if (localStoreData) {
        const filterMeeting = JSON.parse(localStoreData);
        let filterMeetingFromLS = {
          ...filterMeeting,
          startDate: filterMeeting.startDate
            ? format(
                sub(parseISO(filterMeeting.startDate), {
                  days: 1,
                }),
                'yyyy-MM-dd'
              )
            : null,
          endDate: filterMeeting.endDate
            ? format(
                add(parseISO(filterMeeting.endDate), {
                  days: 1,
                }),
                'yyyy-MM-dd'
              )
            : null,
          createdDateFrom: filterMeeting.createdDateFrom
            ? format(
                sub(parseISO(filterMeeting.createdDateFrom), {
                  days: 1,
                }),
                'yyyy-MM-dd'
              )
            : null,
          createdDateTo: filterMeeting.createdDateTo
            ? format(
                add(parseISO(filterMeeting.createdDateTo), {
                  days: 1,
                }),
                'yyyy-MM-dd'
              )
            : null,
        };
        setMeetingFilter(filterMeetingFromLS);
        return;
      }
      const filterMeeting = {
        startDate: from,
        endDate: to,
        createdDateFrom: null,
        createdDateTo: null,
        meetingStatus: [MEETING_STATUS[0]],
        meetingEvent: [MEETING_EVENT[0]],
        outcomes: [],
        dateRangeType: [DATE_TYPE_OPTIONS[0]],
        dateRange: DATE_RANGE_WITH_CUSTOM[4],
      };
      setMeetingFilter(filterMeeting);
    }
  }, []);

  const handleWorkbookChange = (workbook) => {
    if (openCalendarFilter) {
      setOpenCalendarFilter(false);
    }
    if (
      activeTab === 'table' &&
      activeWorkbook &&
      workbook === activeWorkbook
    ) {
      setActiveWorkbook(null);
      return;
    }
    setActiveWorkbook(workbook);
  };
  const [workbookMeetings, setWorkbookMeetings] = useState([]);
  const handleMeetingItemClick = (meeting) => {
    openCalendarInSidebar(meeting);
  };

  const [calendarViewTitle, setCalendarViewTitle] = useState('');

  const showCalendarViewTitle = () => {
    const calendarApi = calendarRef?.current?.getApi();
    const viewData = calendarApi?.getCurrentData();
    setCalendarViewTitle(viewData?.viewTitle);
  };

  useEffect(() => {
    showCalendarViewTitle();
  }, []);

  const toggleOptions = [
    {
      label: (
        <div className="toggle-button">
          <CalendarViewSVG /> Calendar View
        </div>
      ),
      value: 'calendar',
      onClick: () => {
        history.push('/dashboard/calendar');
        setOpenCalendarFilter(false);
        hidePreferences();
      },
    },
    {
      label: (
        <div className="toggle-button">
          <ListViewSVG /> Table View
        </div>
      ),
      value: 'table',
      onClick: () => {
        history.push('/dashboard/table');
        hidePreferences();
      },
    },
  ];
  const getData = (calendarApi) => {
    const viewTitle = calendarApi?.getCurrentData()?.viewTitle;
    setCalendarViewTitle(viewTitle);
    setUpdateCalendar(viewTitle);
  };
  const daterangeOptions = [
    {
      label: <ArrowLeft />,
      value: 'Prev',
      onClick: () => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi.prev();
        getData(calendarApi);
      },
    },
    {
      label: <span className="text-primary">Today</span>,
      value: 'Today',
      onClick: () => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi.today();
        getData(calendarApi);
      },
    },
    {
      label: <ArrowRight />,
      value: 'Next',
      onClick: () => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi.next();
        getData(calendarApi);
      },
    },
  ];

  const switchView = (selectedOption) => {
    selectedOption?.onClick();
    setUpdateCalendar(selectedOption);
    if (selectedOption.value === 'calendar') {
      localStorage.setItem('calendarView', 'calendar');
      try {
        mixpanel.track('activity_dashboard_calendar_clicked', {
          event: 'Calendar view clicked',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      return;
    }
    if (selectedOption.value === 'table') {
      localStorage.setItem('calendarView', 'table');
      try {
        mixpanel.track('activity_dashboard_table_clicked', {
          event: 'Table view clicked',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      return;
    }
  };

  const handleCalendarFilter = () => {
    setOpenCalendarFilter(!openCalendarFilter);
    if (activeWorkbook) {
      setActiveWorkbook(null);
    }
  };

  const handleCalendarSync = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    toast.success('Calendar event sync initiated');
    pullEvents({
      companyId: user?.companyId,
      userId: user?.userId,
      calendar: configFeatures?.data?.calendar,
    }).catch((error) => {
      console.error(error);
      toast.error('Calendar event sync failed');
    });
  };

  return (
    <div className="dashboard_main">
      <section
        className={`main_content ${activeTab === 'table' ? 'full-width' : ''}`}
      >
        <section className="header_content">
          <section className="left-group">
            <Select
              options={toggleOptions}
              onChange={switchView}
              value={
                activeTab === 'calendar' ? toggleOptions[0] : toggleOptions[1]
              }
              styles={customToggleDropdownStyles}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
            />
            {isAdminOrManager() && (
              <ToolTip
                text="View Team's Calendar"
                tooltipClass="dashboard-tooltip"
              >
                <Button
                  className={`btn-team ${
                    showCalendarView ? 'btn-team--active' : ''
                  }`}
                  icon
                  type="text"
                  onClick={handleShowCalendarView}
                >
                  <TeamIcon />
                  <span className="btn-team-text">View Team's Calendar</span>
                </Button>
              </ToolTip>
            )}
            {isAdminOrManager() && (
              <ToolTip
                text="Run calendar Auto-tag"
                tooltipClass="dashboard-tooltip"
              >
                <Button
                  className={`btn-team`}
                  icon
                  type="text"
                  onClick={handleCalendarAutomationSync}
                >
                  <SyncSVG />
                  <span className="btn-team-text">Run calendar Auto-tag</span>
                </Button>
              </ToolTip>
            )}
            {!isProductUser() && !isSalesUser() && (
              <ToolTip text="Sync Calendar">
                <Button
                  icon
                  type="text"
                  className="btn-filter btn-icon-only fill-none"
                  onClick={handleCalendarSync}
                >
                  <SyncCalendarSVG />
                </Button>
              </ToolTip>
            )}
          </section>
          <section className="right-group">
            {activeTab === 'calendar' && (
              <>
                <h4 className="view-title">{calendarViewTitle}</h4>
                <ButtonGroup
                  options={daterangeOptions}
                  defaultValue={daterangeOptions[1].value}
                  containerClassname="date-range-group"
                  showActive={false}
                />
              </>
            )}
            {activeTab === 'table' && (
              <>
                <SearchField
                  placeholder="Search participant's Email"
                  onChange={(e) => searchParticipant(e)}
                  className="calendar-table--search"
                />
                <Button
                  className={`btn-filter btn-icon-only ${
                    openCalendarFilter ? 'btn-filter--active' : ''
                  }`}
                  onClick={handleCalendarFilter}
                  icon
                  type="text"
                >
                  <FilterIcon />
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                togglePreferences();
                if (openCalendarFilter) {
                  setOpenCalendarFilter(false);
                }
              }}
              icon
              className={`btn-preferences btn-icon-only ${
                preferences ? 'btn-preferences--active' : ''
              }`}
              type="text"
            >
              <SettingsSVG />
            </Button>
          </section>
        </section>
        <section className="body_content">
          {preferences && (
            <Suspense
              fallback={
                <div className="loader-container--suspense">
                  <DualRingLoader dark />
                </div>
              }
            >
              <CalendarPreferences />
            </Suspense>
          )}
          {activeTab === 'calendar' && !preferences && (
            <CalendarView
              dashboard
              showEventDetails={openCalendarInSidebar}
              height="100%"
              ref={calendarRef}
              updateCalendar={updateCalendar}
              resetUpdateCalendar={setUpdateCalendar}
            />
          )}
          {activeTab === 'table' && !preferences && (
            <Suspense
              fallback={
                <div className="loader-container--suspense">
                  <DualRingLoader dark />
                </div>
              }
            >
              <ListView
                dashboard
                participants={searchParticipantEmail}
                meetingFilter={meetingFilter}
                showEventDetails={openCalendarInSidebar}
              />
            </Suspense>
          )}
        </section>
      </section>
      <section
        className={`side_content ${
          !activeWorkbook && !openCalendarFilter ? 'hide' : ''
        } ${activeTab === 'table' ? 'floating_content' : ''}`}
      >
        <section className={`side_content__main`}>
          {activeWorkbook === 'tasks' && (
            <TasksDashboard isReadOnly={isSalesUser() || isProductUser()} />
          )}
          {activeWorkbook === 'calendar_info' &&
            (activeTab === 'calendar' ||
              (showFilterModal && activeTab === 'table')) && (
              <Suspense
                fallback={
                  <div className="loader-container--suspense">
                    <DualRingLoader dark />
                  </div>
                }
              >
                <CalendarList
                  showEventDetails={openCalendarInSidebar}
                  ref={activeTab === 'table' ? filtersContainerRef : null}
                  handleClose={() => setShowFilterModal(false)}
                />
              </Suspense>
            )}
          {activeWorkbook === 'meetings' && (
            <Suspense
              fallback={
                <div className="loader-container--suspense">
                  <DualRingLoader dark />
                </div>
              }
            >
              <Meetings
                handleClick={handleMeetingItemClick}
                workbookMeetings={workbookMeetings}
                setWorkbookMeetings={setWorkbookMeetings}
                handleClose={() => setActiveWorkbook(null)}
                isTableView={activeTab === 'table' ? true : false}
              />
            </Suspense>
          )}
          {openCalendarFilter && (
            <Suspense
              fallback={
                <div className="loader-container--suspense">
                  <DualRingLoader dark />
                </div>
              }
            >
              <CalendarFilter
                filter={(data) => setMeetingFilter(data)}
                close={() => setOpenCalendarFilter(false)}
                contributionMap={contributionMap}
                outcomesOptions={outcomesOptions}
              />
            </Suspense>
          )}
        </section>
      </section>
      <SideNav
        handleWorkbookChange={handleWorkbookChange}
        activeWorkbook={activeWorkbook}
      />
      {path === '/dealDetails' && dealID && (
        <Suspense
          fallback={
            <Drawer showDrawer={true} className="presentationViewDrawer">
              <div className="loader-container--suspense">
                <DualRingLoader dark />
              </div>
            </Drawer>
          }
        >
          <DealPresentationView
            showPresentationView={
              path === '/dealDetails' && dealID ? true : false
            }
            closePresentationView={() => {
              activeTab === 'calendar'
                ? history.push('/dashboard/calendar')
                : history.push('/dashboard/table');
            }}
            dealId={dealID}
            refreshView={(deal) =>
              history.replace(`/dealDetails/${deal.dealId}`)
            }
            iscrmAccount={configFeatures?.data?.crm}
          />
        </Suspense>
      )}
      {showCalendarView && (
        <CalendarModal
          showModal={showCalendarView}
          closeModal={handleCloseCalendarView}
          users={users}
          hideHeader
        />
      )}
    </div>
  );
};

export default DashboardMain;
