import React from 'react';
import { useFetchAccountSummaryQuery } from '../../Accounts/accountsSlice';
import { useFetchActivitySummaryQuery } from '../../app/api/activitiesSlice';
import { PULSE_SCORE_DROPDOWN } from '../../Constant/ProductGapUseCases';
import { SuccessItemsRatingReadOnly } from '../../common/Rating';
import { ReactComponent as TimeSVG } from '../../Icons/Clock-blue.svg';
import DualRingLoader from '../../common/DualRingLoader';
import Metric from '../../common/Metric';
import { getFormattedTime } from '../../Utils/DateUtils';
import format from 'date-fns/format';
import add from 'date-fns/add';

const OverviewMetrics = ({ accountId = '', dealId = '', loading }) => {
  const {
    data: accountSummary,
    isLoading: accountSummaryLoading,
    isFetching: accountSummaryFetching,
  } = useFetchAccountSummaryQuery(
    { dealId },
    {
      skip: !dealId,
    }
  );
  const {
    data: activitySummary,
    isLoading: activitySummaryLoading,
    isFetching: activitySummaryFetching,
  } = useFetchActivitySummaryQuery(
    {
      dealId,
      from: '',
      to: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
    },
    {
      skip: !dealId,
    }
  );
  const metricClassNames = {
    container: 'summary--item',
    title: 'summary--title',
    value: 'summary--value',
  };

  const meetingValue = (meetings, meetingTime) => {
    return (
      <>
        {meetings}
        <span className="tag tag-light-gray opp-cell">
          <TimeSVG />
          {getFormattedTime(meetingTime)}
        </span>
      </>
    );
  };

  const getProductGapScoreClass = (score) =>
    score <= 1
      ? 'pg-score-one'
      : score <= 2
      ? 'pg-score-two'
      : score <= 3
      ? 'pg-score-three'
      : score <= 4
      ? 'pg-score-four'
      : score <= 5
      ? 'pg-score-five'
      : 'off';

  return loading ? (
    <div className="loader-container">
      <DualRingLoader dark />
    </div>
  ) : (
    <div className="account-activity-summary--container">
      <Metric
        loading={accountSummaryLoading}
        fetching={accountSummaryFetching}
        title="Solution Use-cases"
        value={accountSummary?.totalCriteria}
        classNames={metricClassNames}
      />
      <Metric
        loading={accountSummaryLoading}
        fetching={accountSummaryFetching}
        title="Solution Pulse Score"
        value={
          <>
            {accountSummary?.successPulseScore}/5
            <SuccessItemsRatingReadOnly
              rating={accountSummary?.successPulseScore ?? '--'}
            />
          </>
        }
        classNames={metricClassNames}
      />
      <Metric
        loading={accountSummaryLoading}
        fetching={accountSummaryFetching}
        title="Product gap Use-cases"
        value={accountSummary?.totalProductGaps}
        classNames={metricClassNames}
      />
      <Metric
        loading={accountSummaryLoading}
        fetching={accountSummaryFetching}
        title="Product gap Pulse Score"
        value={
          <>
            {accountSummary?.productGapPulseScore}/5
            <span
              className={getProductGapScoreClass(
                accountSummary?.productGapPulseScore
              )}
            >
              {PULSE_SCORE_DROPDOWN[accountSummary?.productGapPulseScore]}
            </span>
          </>
        }
        classNames={metricClassNames}
      />
      <Metric
        loading={activitySummaryLoading}
        fetching={activitySummaryFetching}
        title="External Meetings"
        value={meetingValue(
          activitySummary?.externalMeetings,
          activitySummary?.externalMeetingTime
        )}
        classNames={metricClassNames}
      />
      <Metric
        loading={activitySummaryLoading}
        fetching={activitySummaryFetching}
        title="Internal Meetings"
        value={meetingValue(
          activitySummary?.internalMeetings,
          activitySummary?.internalMeetingTime
        )}
        classNames={metricClassNames}
      />
    </div>
  );
};

export default OverviewMetrics;
