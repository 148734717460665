import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { selectAuthenticationState } from '../Authorize/authSlice';
import { useSelector } from 'react-redux';

const PublicRoute = ({ children, component: Component, ...rest }) => {
  const authenticated = useSelector(selectAuthenticationState);

  return (
    <Route
      {...rest}
      render={(props) =>
        !authenticated ? (
          children || <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
