import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Select, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { PIPELINE_ACCESSIBILITY } from '../../Constant/PipelineAccessibilityConstants';
import { USER_STATUS } from '../constants';
import {
  extendedTeamSlice,
  useFetchActiveInactiveTeamsQuery,
  useUpdateGeneralSettingsMutation,
} from '../../Settings/Teams/teamSlice';
import PreskaleMultiSelect from '../PreskaleMultiSelect';
import { useLazyFetchPipelinesQuery } from '../../Accounts/accountsSlice';

const { Text } = Typography;

const GeneralSettings = ({ teamData }) => {
  const dispatch = useDispatch();
  const [accessibility, setAccessibility] = useState(null);
  const [teamStatus, setTeamStatus] = useState(null);
  const [updateGeneralSettings, { isLoading: savingGeneralSettings }] =
    useUpdateGeneralSettingsMutation();
  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const { data: teams } = useFetchActiveInactiveTeamsQuery();

  const [getAllPipelines, { data: pipelines }] = useLazyFetchPipelinesQuery();

  useEffect(() => {
    getAllPipelines();
  }, []);

  useEffect(() => {
    if (teamData) {
      const pipelineAccessibility = PIPELINE_ACCESSIBILITY.filter(
        (data) => data.value === teamData?.pipelineAccessibility
      );
      const status = teamData?.active ? USER_STATUS[1] : USER_STATUS[2];

      setAccessibility(pipelineAccessibility);
      setTeamStatus(status);

      if (!pipelines) {
        return;
      }

      const pipeline = pipelines?.filter((data) => {
        if (teamData?.pipelines?.includes(data?.value)) {
          return data;
        }
      });
      setSelectedPipelines(pipeline);
    }
  }, [teamData, pipelines]);

  const onChange = (e, field) => {
    let body = {};
    switch (field) {
      case 'accessibility':
        setAccessibility(e?.value);
        body = {
          teamId: teamData?.id,
          updatedField: 'pipelineAccessibility',
          pipelineAccessibility: e?.value,
        };
        break;
      case 'pipelines':
        setSelectedPipelines(e);
        body = {
          teamId: teamData?.id,
          updatedField: 'pipelines',
          pipelines: e?.map((data) => data?.value),
        };
        break;
      case 'status':
        setTeamStatus(e?.value);
        body = {
          teamId: teamData?.id,
          updatedField: 'status',
          active: e.value === 'IN_ACTIVE' ? false : true,
        };
        break;
      default:
        break;
    }
    if (body) {
      updateGeneralSettings(body)
        .unwrap()
        .then((data) => {
          toast.success('Updated Successfully!');
          dispatch(
            extendedTeamSlice.util.invalidateTags([
              { type: 'Team', id: 'fetchTeamById' },
            ])
          );
          if (field === 'status') {
            dispatch(
              extendedTeamSlice.util.invalidateTags([
                { type: 'Team', id: 'fetchActiveInactiveTeams' },
              ])
            );
          }
        });
    }
  };

  return (
    <Flex vertical gap={24} className="general-settings">
      <Flex>
        <Text className="text-primary min-width-300" strong>
          Pipeline Accessibility
        </Text>
        <Select
          labelInValue
          className="width-200"
          value={accessibility}
          options={PIPELINE_ACCESSIBILITY || []}
          onChange={(e) => onChange(e, 'accessibility')}
        />
      </Flex>
      {pipelines &&
      pipelines?.length &&
      !(pipelines?.length === 1 && !pipelines[0]?.label) ? (
        <Flex>
          <Text className="text-primary min-width-200" strong>
            Associated Pipeline
          </Text>
          <PreskaleMultiSelect
            className="width-200"
            options={pipelines}
            value={selectedPipelines}
            onChange={(e) => onChange(e, 'pipelines')}
            restrictDeselect={true}
            message="Atleast one pipeline has to be selected"
          />
        </Flex>
      ) : null}
      <Flex>
        <Text className="text-primary min-width-200" strong>
          Status
        </Text>
        <Select
          labelInValue
          value={teamStatus}
          className="width-200"
          options={USER_STATUS.slice(1) || []}
          onChange={(e) => onChange(e, 'status')}
          disabled={teams?.data?.length === 1 && teamData?.active}
        />
      </Flex>
    </Flex>
  );
};

export default GeneralSettings;
