import React, { useState, useEffect } from 'react';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import PreskaleCard from '../../common/PreskaleCard';
import { SuccessItemsRatingReadOnly } from '../../common/Rating';
import { ReactComponent as UsecasesSVG } from '../../Icons/Usecases.svg';
import { ReactComponent as CheckIconSVG } from '../../Icons/NewImage/checkIcon.svg';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { ReactComponent as ReopenSVG } from '../../Icons/NewImage/reopen.svg';
import { ReactComponent as TimeSpentSVG } from '../../Icons/NewImage/timespent.svg';
import { createMarkup } from '../../Utils/common';
import Button from '../../common/Button';

const SolutionUseCaseItem = ({
  solution,
  handleEdit,
  handleReopen,
  handleComplete,
  handleTimeSpent,
  handleManageUsecases,
}) => {
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });

  const getFormattedTime = (metric) => {
    return metric ? `${Math.floor(metric / 60)}h ${metric % 60}m` : '0h 0m';
  };

  return (
    <PreskaleCard
      className="usecase-item solution-usecase-item"
      header={
        <>
          <div className="usecase-title--container">
            {solution?.moduleTitle}
          </div>
          <div className="solution-actions--container">
            <div className="mr-16">
              {solution?.usecases[0]?.status === 'COMPLETED' ? (
                <span className="tag tag-green ml-10">COMPLETED</span>
              ) : null}
              {solution?.usecases[0]?.timeSpent ? (
                <span className="tag tag-dark timeSpent ml-10">
                  {getFormattedTime(solution.usecases[0].timeSpent)}
                </span>
              ) : null}
            </div>
            <span className="flex-align-center pointer">
              {solution.usecases[0]?.status === 'COMPLETED'
                ? handleReopen && (
                    <Button
                      icon
                      className="btn-icon-only ml-6"
                      type="ghost"
                      onClick={() =>
                        handleReopen(solution?.id, solution.usecases[0])
                      }
                    >
                      <ReopenSVG />
                    </Button>
                  )
                : handleComplete && (
                    <Button
                      icon
                      className="btn-icon-only ml-6"
                      type="ghost"
                      onClick={() =>
                        handleComplete(solution?.id, solution.usecases[0])
                      }
                    >
                      <CheckIconSVG />
                    </Button>
                  )}

              {handleTimeSpent && (
                <Button
                  icon
                  className="btn-icon-only ml-6"
                  type="ghost"
                  onClick={() => handleTimeSpent(solution)}
                >
                  <TimeSpentSVG />
                </Button>
              )}

              {handleManageUsecases && (
                <Button
                  icon
                  className="btn-icon-only ml-6"
                  type="ghost"
                  onClick={() => handleManageUsecases(solution?.id)}
                >
                  <UsecasesSVG />
                </Button>
              )}
            </span>
          </div>
        </>
      }
    >
      {solution?.usecases?.map((usecase, i) => {
        return (
          <section key={usecase.id}>
            {i !== 0 && <hr className="horizontal" />}
            <div className="usecase-details">
              <div className="usecase-body">
                <div className="usecase-body--content">
                  <div className="usecase-index"> #{i + 1} </div>
                  <div
                    className="usecase-content"
                    dangerouslySetInnerHTML={createMarkup(usecase?.usecase)}
                  />
                </div>
                {handleEdit && (
                  <Button
                    icon
                    className="btn-icon-only ml-20"
                    type="ghost"
                    onClick={() => handleEdit(usecase, solution)}
                  >
                    <EditSVG />
                  </Button>
                )}
              </div>
              <div className="usecase-footer">
                <span className="usecase-rating">
                  <SuccessItemsRatingReadOnly rating={usecase?.rating} />
                </span>
                <div className="usecase-features">
                  {usecase?.moduleFeatures.map((feature, i) => (
                    <span
                      className="tag usecase-feature--tag"
                      key={`usecase-feature${i}`}
                    >
                      {feature?.label}
                    </span>
                  ))}
                </div>
                <div className="usecase-user mr-20 ml-auto">
                  {userMap[usecase.createdBy] &&
                    userMap[usecase.createdBy].name}
                </div>
                <div className="usecase-date mt-2">{usecase?.createdDate}</div>
              </div>
            </div>
          </section>
        );
      })}
    </PreskaleCard>
  );
};

export default SolutionUseCaseItem;
