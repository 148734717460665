import React from 'react';
import { useFetchJiraIssueQuery } from './productGapSlice';
import { DateRelativeTime } from '../../Utils/DayJs';

const JiraInfo = ({ data = {}, formik }) => {
  const { data: jiraDetails } = useFetchJiraIssueQuery(
    { issueId: data?.jiraIssueId || formik?.values?.jiraIssueId },
    { skip: !data?.jiraIssueId?.length && !formik?.values?.jiraIssueId }
  );
  return data?.jiraIssueId?.length || formik?.values?.jiraIssueId ? (
    <div className="jirainfo_root_container">
      <div className="jirainfo_root">
        <div className="info_container">
          <div className="info_group">
            <span className="info_label">IssueID:</span>{' '}
            <span className="info_description">
              {data?.jiraIssueKey || formik?.values?.jiraIssueKey}
            </span>
          </div>
          <div className="info_group">
            <span className="info_label">Issue Title:</span>
            <span className="info_description">
              {data?.jiraTitle || formik?.values?.jiraTitle}
            </span>
          </div>
          <div className="info_group">
            <span className="info_label">Status:</span>
            <span className="info_description">
              {jiraDetails?.data?.status?.name}
            </span>
          </div>
          <div className="info_group">
            <span className="info_label">Project Name:</span>
            <span className="info_description">
              {jiraDetails?.data?.project?.name}
            </span>
          </div>
        </div>
        {jiraDetails?.data?.updated_at && (
          <span className="info-caption">
            Updated {DateRelativeTime(jiraDetails?.data?.updated_at)}
          </span>
        )}
      </div>
    </div>
  ) : (
    <div className="jira_alert_root_container" role="alert">
      <div className="alert_icon">
        <svg
          className="alert_svg_icon_root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ReportProblemOutlinedIcon"
        >
          <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
        </svg>
      </div>
      <div className="alert_message">
        No Jira information associated with the product gap
      </div>
    </div>
  );
};

export default JiraInfo;
