import React, { useState } from 'react';
import { useFormik } from 'formik';
import DualRingLoader from '../common/DualRingLoader';
import LandingForm from './LandingForm';
import SecondaryForm from './SecondaryForm';
import {
  useLazyCheckExistingUsersQuery,
  useSignupUserMutation,
} from '../app/api/usersSlice';
import StorylaneContainer from './StorylaneContainer';
import CalendarEmbed from './CalendarEmbed';
import styles from '../styles/pages/signupForm.module.scss';
import { EMAIL_PROVIDERS } from '../Constant/CommonEmailProviders';
import { useCreateFreshsalesLeadMutation } from '../app/api/dataEngineeringService';

const MainContent = ({ step, handleNext }) => {
  const [checkExistingUser] = useLazyCheckExistingUsersQuery();
  const [createLead] = useCreateFreshsalesLeadMutation();
  const [submitDetails] = useSignupUserMutation();
  const [userError, setUserError] = useState(null);

  const validateFields = (values, requiredFields) => {
    const errors = {};
    requiredFields.forEach((field) => {
      if (!values[field]) {
        if (field === 'fullName') {
          errors[field] = 'Please enter full name';
        }
        if (field === 'email') {
          errors[field] = `Please enter ${field}`;
        }
        if (field !== 'fullName' && field !== 'email') {
          errors[field] = 'Please select an option';
        }
      }
      if (step === 2) {
        return errors;
      }
      if (field === 'email' && values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Entered email is not valid';
        } else {
          const domain = values.email.split('@')[1];
          const isNotWorkEmail = EMAIL_PROVIDERS.some(
            (provider) => provider === domain
          );
          if (isNotWorkEmail) {
            errors.email = 'Please enter your work email';
          }
        }
      }
    });
    return errors;
  };

  const handleSubmit = (values) => {
    if (step === 1) {
      checkExistingUser(values.email)
        .unwrap()
        .then((res) => {
          if (!res.status) {
            setUserError(
              <span>
                This email already exists. If you need any assistance, please
                reach out to{' '}
                <a href="mailto:support@preskale.com">support@preskale.com</a>
              </span>
            );
            return;
          }
          createLead({ fullName: values.fullName, email: values.email });
          handleNext();
        })
        .catch(() => {
          setUserError('Something went wrong!');
        })
        .finally(() => formik.setSubmitting(false));
      return;
    }
    const data = {
      fullName: values.fullName,
      email: values.email,
      role: values.role.value,
      teamSize: values.teamSize.value,
      productGapTracker: values.productGapTracker.value,
      referralSource: values.referralSource.value,
    };
    if (step === 2) {
      submitDetails(data)
        .unwrap()
        .then(() => {
          handleNext();
        })
        .catch(() => setUserError('Something went wrong!'))
        .finally(() => formik.setSubmitting(false));
      return;
    }
    formik.setSubmitting(false);
    handleNext();
  };
  const validation = (values) => {
    if (userError) {
      setUserError(null);
    }
    const initialCheck = ['fullName', 'email'];
    const requiredFields = [
      ...initialCheck,
      'role',
      'teamSize',
      'productGapTracker',
      'referralSource',
    ];
    if (step === 1) {
      return validateFields(values, initialCheck);
    }
    return validateFields(values, requiredFields);
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      role: '',
      teamSize: '',
      productGapTracker: '',
      referralSource: '',
    },
    validate: validation,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const handleEventScheduled = () => {
    formik.resetForm();
    handleNext();
  };

  const renderContent = (step) => {
    switch (step) {
      case 1:
      case 2:
        return (
          <form
            className={styles.form_container}
            onSubmit={formik.handleSubmit}
          >
            {step === 1 ? (
              <LandingForm
                values={formik.values}
                handleChange={formik.handleChange}
                errors={{ touched: formik.touched, errors: formik.errors }}
              />
            ) : (
              <SecondaryForm
                values={formik.values}
                handleChange={formik.setFieldValue}
                errors={{ touched: formik.touched, errors: formik.errors }}
              />
            )}
            {userError && (
              <section className={styles.alert}>{userError}</section>
            )}
            <button
              key={step === 1 ? 'emailform' : 'detailsform'}
              className={styles.next_button}
              type="submit"
              disabled={formik.isSubmitting}
            >
              <span className={formik.isSubmitting ? styles.faded_text : ''}>
                Next
              </span>
              {formik.isSubmitting && (
                <span className={styles.loader}>
                  <DualRingLoader />
                </span>
              )}
            </button>
          </form>
        );
      case 3:
        return (
          <StorylaneContainer
            role={formik.values.role?.value}
            handleNext={handleNext}
          />
        );
      case 4:
        return (
          <CalendarEmbed
            handleEventScheduled={handleEventScheduled}
            values={formik.values}
          />
        );
      default:
        return null;
    }
  };

  return renderContent(step);
};

export default MainContent;
