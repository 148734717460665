import DOMPurify from 'dompurify';
import { Typography } from 'antd';
import NoContent from '../Accounts/commons/NoContent';
import { CONFIG_REPORTS } from '../Constant/Reports';
import { logout } from '../Authorize/authSlice';
import { businessService } from '../app/api/businessService';
import { userService } from '../app/api/userService';
import { dataEngineeringService } from '../app/api/dataEngineeringService';

const { Text } = Typography;

export const logoutAndResetState = (dispatch, history) => {
  if (!dispatch || !history) {
    console.warn('Please call with both the arguments.');
    return;
  }
  //* reset redux cache state on logout
  dispatch(logout());
  dispatch(businessService.util.resetApiState());
  dispatch(userService.util.resetApiState());
  dispatch(dataEngineeringService.util.resetApiState());
};

export const constructQueryString = (queryKey, data, condition) => {
  let queryString = `&${queryKey}=`;
  data.forEach((element) => {
    if (
      queryKey === 'deal_id' &&
      !condition &&
      element?.value &&
      element?.value?.dealId
    ) {
      queryString = queryString + element.value.dealId + ',';
    }
    if (
      queryKey === 'deal_id' &&
      condition === 'taskmanager' &&
      element?.value &&
      element.value !== 'ALL'
    ) {
      queryString = queryString + element.value + ',';
    }
    if (queryKey === 'userIds' && element) {
      const value = condition === 'teamMembers' ? element?.value : element?.id;
      queryString = queryString + value + ',';
    }
  });
  queryString = queryString.slice(0, -1);
  return queryString;
};

export const toQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const handleCompaniesForDropdown = (data, self) => {
  let companyObject =
    Array.isArray(data) &&
    data?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  self.setState({ companies: companyObject });
};

export const isAdminOrManager = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  return (
    userDetails?.roles &&
    (userDetails?.roles.includes('admin') ||
      userDetails?.roles.includes('manager'))
  );
};
export const isSalesUser = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  return userDetails?.roles && userDetails?.roles.includes('sales');
};
export const isProductUser = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  return userDetails?.roles && userDetails?.roles.includes('product');
};

export const isSalesOwnerOrManagerOrAdmin = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const salesOwner =
    userDetails.roles.includes('sales') &&
    !userDetails.roles.includes('product');
  const manager = userDetails?.roles.includes('manager');
  const admin = userDetails?.roles.includes('admin');
  return salesOwner || manager || admin;
};

export const isAdminOrManagerOrProduct = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  if (
    userDetails.roles.includes('admin') ||
    userDetails.roles.includes('manager') ||
    userDetails.roles.includes('product')
  ) {
    return true;
  } else return false;
};

export const isAdminUser = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  return (
    userDetails?.roles &&
    (userDetails?.roles.includes('admin') ||
      userDetails?.roles.includes('Admin'))
  );
};

export const convertHTMLtoPlainText = (value) => {
  let parser = document.createElement('div');
  parser.innerHTML = value;
  return parser.textContent;
};

//to sanitize the text containing html tags and add 'target' to open links on new tab
export const createMarkup = (text) => {
  const sanitizedText = DOMPurify.sanitize(text);
  return {
    __html: sanitizedText.replace(/href/g, "target='_blank' href"),
  };
};

export const getCurrentPreskaleStage = (data, dealDetail) => {
  const response = [];
  const stages =
    Array.isArray(data) &&
    data.map((stage) => {
      return {
        label: stage.name,
        value: stage.id,
      };
    });
  response[0] = stages;
  if (!dealDetail?.additionalDetails?.presalesStage) {
    console.warn('Missing presales stage!');
  }
  if (stages && stages?.length) {
    const stage = stages.filter(
      (stage) =>
        stage &&
        stage.label?.toLowerCase() ===
          dealDetail?.additionalDetails.presalesStage?.toLowerCase()
    );
    if (stage && stage.length) {
      response[1] = stage[0];
    }
  }
  return response;
};

export const getBackgroundColor = (event) => {
  if (event.eventType === 'EXTERNAL') return 'tag-violet';
  if (event.eventType === 'INTERNAL' && event.opportunity == null)
    return 'tag-purple';
  if (event.eventType === 'INTERNAL') return 'tag-green';
};

//filter outcomelabel from list using outcomeid
export const filterOutcome = (outcomeList, contribution) => {
  const outcome = outcomeList?.filter((data) => data.value === contribution);
  return outcome ? outcome[0]?.label : {};
};

export const mapRoute = (route) => {
  let url = '';
  switch (route) {
    case 'Dashboard':
      url = '/dashboard';
      break;
    case 'ManagerDashboard':
      url = '/manager';
      break;
    case 'Accounts':
      url = '/pipeline';
      break;
    case 'Statistics':
      url = '/reports';
      break;
    case 'ProductGapUseCases':
      url = '/libraries';
      break;
    case 'RequestForm':
      url = '/requestForm';
      break;
    case 'AdminOps':
      url = '/adminOps';
      break;
  }
  return url;
};

export const handleFormValidation = (
  requiredFields,
  isUpdate,
  checkMentions
) => {
  let formErrors = {};
  let isFormValid = true;
  for (let key in requiredFields) {
    if (key === 'description') {
      //check if a deal is tagged to the task
      const dealMention = requiredFields[key].includes('class="mention"');
      if (checkMentions && !isUpdate && !dealMention) {
        isFormValid = false;
        formErrors[key] = 'Please use @ to add account';
      }
    }
    if (
      !requiredFields[key] ||
      (Array.isArray(requiredFields[key]) && requiredFields[key].length === 0)
    ) {
      isFormValid = false;
      formErrors[key] = 'Field cannot be empty';
      if (key === 'issueId' && !isUpdate) {
        formErrors['title'] =
          formErrors['title'] + ' and should be associated with a jira ticket';
      }
    }
  }
  return { isFormValid, formErrors };
};

export const noData = (message = 'No data found') => {
  return <NoContent className="nodata_found" message={message} />;
};

export const getDefaultTab = (configFeatures, reportsForUser, reports) => {
  let tabs = [];
  if (!reports) {
    return [];
  }
  let modules = reports?.map((data) => data.module);
  if (configFeatures?.featureList?.is_performance_utilisation_enabled) {
    tabs.push(CONFIG_REPORTS['is_performance_utilisation_enabled']);
  }
  if (configFeatures?.featureList?.is_influence_enabled) {
    tabs.push(CONFIG_REPORTS['is_influence_enabled']);
  }
  if (
    configFeatures?.featureList?.is_product_gap_enabled &&
    modules?.includes('Product Pulse')
  ) {
    tabs.push(CONFIG_REPORTS['is_product_gap_enabled']);
  }
  if (
    configFeatures?.featureList?.is_custom_section_enabled &&
    modules?.includes('deals_custom_section')
  ) {
    tabs.push(CONFIG_REPORTS['is_custom_section_enabled']);
  }
  if (
    configFeatures?.navPages?.dashboard &&
    modules?.includes('Total Events')
  ) {
    tabs.push(CONFIG_REPORTS['is_calendar_enabled']);
  }
  if (
    configFeatures?.featureList?.is_manager_analytics_enabled &&
    reportsForUser?.managerAnalytics &&
    isAdminOrManager()
  ) {
    tabs.push(CONFIG_REPORTS['is_manager_analytics_enabled']);
  }
  if (
    configFeatures?.featureList?.is_deal_velocity_reports_enabled &&
    reportsForUser?.dealVelocityReports
  ) {
    tabs.push(CONFIG_REPORTS['is_deal_velocity_reports_enabled']);
  }
  if (
    configFeatures?.featureList?.is_product_gap_enabled &&
    configFeatures?.featureList?.is_productgap_analytics_enabled &&
    reportsForUser?.productGapAnalytics
  ) {
    tabs.push(CONFIG_REPORTS['is_productgap_analytics_enabled']);
  }
  if (
    configFeatures?.featureList?.is_solution_enabled &&
    configFeatures?.featureList?.is_solution_analytics_enabled &&
    reportsForUser?.solutionAnalytics
  ) {
    tabs.push(CONFIG_REPORTS['is_solution_analytics_enabled']);
  }
  if (
    configFeatures?.featureList?.is_calendar_analytics_enabled &&
    reportsForUser?.calendarAnalytics
  ) {
    tabs.push(CONFIG_REPORTS['is_calendar_analytics_enabled']);
  }
  return tabs;
};

export const getFilteredCustomFieldData = (sections) => {
  let fieldsData = [];
  //filtering out fields from response
  sections?.map((data) => {
    // checking if custom fields are available before assigning data
    // to prevent app from breaking.
    if (data?.fields) {
      fieldsData = [...fieldsData, ...data?.fields];
    }
  });

  const filteredFields = fieldsData?.map((data) => {
    return data?.label;
  });
  const mandatoryFields = {};
  fieldsData?.forEach((data) => {
    mandatoryFields[data.label] = data?.mandatory;
  });
  const crmField = {};
  fieldsData?.forEach((data) => {
    crmField[data.label] = data?.crmField;
  });

  //mapping type (select,radio,text) and fieldNames
  const typeMapData = {};
  fieldsData?.forEach((data) => {
    typeMapData[data?.label] = data.type;
  });

  //mapping customSection's fieldName and it's dropdown options
  const customSectionlabelValue = {};
  fieldsData?.forEach((data) => {
    customSectionlabelValue[data.label] = data.values;
  });

  return {
    filteredFields,
    customSectionlabelValue,
    typeMapData,
    mandatoryFields,
    crmField,
  };
};

//generates random unique string
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (res) => {
    const random = (Math.random() * 16) | 0;
    const key = res === 'x' ? random : (random & 0x3) | 0x8;
    return key.toString(16);
  });
};

export const getLaneId = (stages, preskalStageName) => {
  const laneId = stages?.ids?.filter(
    (stage) =>
      stage &&
      stages?.entities[stage]?.name?.toLowerCase() ===
        preskalStageName?.toLowerCase()
  );
  return laneId;
};

export const customizeRequiredMark = (label, { required }) => (
  <>
    {label}
    {required ? (
      <Text type="danger" className="ml-5">
        *
      </Text>
    ) : null}
  </>
);
