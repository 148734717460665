import React, { useState } from 'react';
import { Flex, Select, Typography } from 'antd';
import Button from '../../common/Button';
import { useGetUsersQuery } from '../../app/api/usersSlice';
import emptyHyphen from '../../_helpers/empty-hyphen';
import { ReactComponent as IconSVG } from '../../Icons/InfoIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../Icons/ArrowThinRight.svg';

const { Text } = Typography;
const DealComparisonView = ({
  activeCard,
  existingDeal,
  toggleSearchableView,
  handleSyncCRM,
}) => {
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const [selectedPresalesOwner, setSelectedPresalesOwner] = useState(
    loggedInUser?.userId
  );
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => {
      let userDropDownList = [];
      if (data && data.entities) {
        const userIds = data.ids;
        userIds &&
          userIds.forEach((userId) => {
            const dropDownElement = {
              label: data.entities[userId]?.name,
              value: userId,
            };
            userDropDownList.push(dropDownElement);
          });
      }
      const result = { userIdMap: data?.entities, userDropDownList };
      return {
        data: result,
      };
    },
  });
  return (
    <div className="sync_opportunity__details">
      <Flex align="center" className="width-full">
        <div className="heading"> {activeCard.dealName}</div>
        {!activeCard?.presalesUser && (
          <Flex className="mr-10 ml-auto" align="center" gap={6}>
            <Text>Presales Owner : </Text>
            <Select
              className="width-200"
              options={userMap?.userDropDownList}
              value={selectedPresalesOwner}
              onChange={(e) => setSelectedPresalesOwner(e)}
            />
          </Flex>
        )}
      </Flex>
      <div className="alert_box heading_level5">
        <div className="pr">
          <IconSVG />
        </div>
        <div>
          <span className="bold-weight">Note: </span> When you{' '}
          <span className="text-primary">Sync an Opportunity,</span> the
          following fields will be updated with the CRM records. All other data
          from the Preskale deal will be merged into the CRM deal.
        </div>
      </div>
      <div className="deal_comparison__view">
        <div className="view_container width-30">
          <div className="view_header">Fields</div>
          <div className="view_label">Account Name</div>
          <div className="view_label">Opportunity Name</div>
          <div className="view_label">Opportunity Value</div>
          <div className="view_label">Contact Email</div>
          <div className="view_label">Industry</div>
          <div className="view_label">Region</div>
        </div>
        <div className="view_container width-35">
          <div className="view_header">Preskale</div>
          <div className="view_content">
            {emptyHyphen(existingDeal.accountName)}
          </div>
          <div className="view_content">{emptyHyphen(existingDeal.name)}</div>
          <div className="view_content">
            ${emptyHyphen(existingDeal.dealValue)}
          </div>
          <div className="view_content">
            {existingDeal.contacts?.length
              ? existingDeal.contacts[0].email
              : '--'}
          </div>
          <div className="view_content">
            {emptyHyphen(existingDeal?.additionalDetails?.industry)}
          </div>
          <div className="view_content">
            {emptyHyphen(existingDeal?.additionalDetails?.country)}
          </div>
        </div>
        <div className="view_container width-10">
          <div className="view_header visibility-hidden">Arrows</div>
          {[...Array(6)].map(() => (
            <div className="view_label">
              <ArrowRightIcon />
            </div>
          ))}
        </div>
        <div className="view_container width-35">
          <div className="view_header">CRM</div>
          <div className="view_content">
            {emptyHyphen(activeCard.accountName)}
          </div>
          <div className="view_content">{emptyHyphen(activeCard.dealName)}</div>
          <div className="view_content">
            ${emptyHyphen(activeCard.dealValue)}
          </div>
          <div className="view_content">
            {emptyHyphen(activeCard.contact?.email)}
          </div>
          <div className="view_content">{emptyHyphen(activeCard.industry)}</div>
          <div className="view_content">{emptyHyphen(activeCard.region)}</div>
        </div>
      </div>
      <div className="flex-justify-end mt-20">
        <Button
          className="mr-10"
          type="secondary"
          onClick={toggleSearchableView}
        >
          Cancel
        </Button>
        <Button
          className="mr-20"
          type="primary"
          onClick={() => handleSyncCRM(selectedPresalesOwner)}
        >
          Sync
        </Button>
      </div>
    </div>
  );
};

export default DealComparisonView;
