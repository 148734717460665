import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import { businessService } from '../../app/api/businessService';

export const extendedIssuesSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    getFilterOptions: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.issues.getFilterOptions;
      },
      providesTags: () => [{ type: 'Issues', id: 'FilterOptions' }],
      transformResponse: (res) => {
        return res?.data;
      },
    }),
    getAllIssues: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.issues.getAllIssues,
        method: 'POST',
        body,
      }),
      providesTags: () => [{ type: 'Issues', id: 'getAllIssues' }],
    }),
    bulkUpdateIssues: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.issues.bulkUpdateIssues,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Issues', id: 'getAllIssues' }],
    }),
    getJiraConfig: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.issues.getJiraConfig;
      },
      providesTags: () => [{ type: 'Issues', id: 'Jira-Config' }],
    }),
  }),
});
export const {
  useGetAllIssuesMutation,
  useBulkUpdateIssuesMutation,
  useGetFilterOptionsQuery,
  useLazyGetJiraConfigQuery,
} = extendedIssuesSlice;
