import { useState } from 'react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopySVG } from '../Icons/NewImage/copy.svg';
import { ReactComponent as CopiedSVG } from '../Icons/NewImage/tick.svg';

function CopyText(props) {
  const [copied, SetCopied] = useState(false);

  const OnTextCopy = () => {
    SetCopied(true);
    const timer = setTimeout(() => {
      SetCopied(false);
    }, 5000);
    return () => clearTimeout(timer);
  };

  return (
    <div className="copy-content flex-align-center">
      <CopyToClipboard text={props.content} onCopy={() => OnTextCopy()}>
        <div className="flex pointer">
          {props.content}
          {copied ? (
            <CopiedSVG className="ml-5" />
          ) : (
            <CopySVG className="ml-5" />
          )}
        </div>
      </CopyToClipboard>
    </div>
  );
}
export default CopyText;
