import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/pages/signup.module.scss';

const FormFooter = () => {
  return (
    <>
      <p className={styles.secondary_text}>
        By signing up, you agree to our{' '}
        <a
          target="_blank"
          href="https://www.preskale.com/terms"
          className={styles.underline}
        >
          Terms and Conditions
        </a>
        ,{' '}
        <a
          target="_blank"
          href="https://www.preskale.com/privacy-policy"
          className={styles.underline}
        >
          Privacy Policy
        </a>{' '}
        and PreSales Times. You can opt out anytime.
      </p>
      <p className={styles.text}>
        Already signed up?{' '}
        <Link to="/login" className={styles.highlight}>
          Log in
        </Link>
      </p>
    </>
  );
};

export default FormFooter;
