import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Route,
} from 'react-router-dom';
import CreateOpportunity from './CreateOpportunity';
import { ReactComponent as AddSVG } from '../Icons/NewImage/add.svg';
import { isAdminOrManager as checkIsAdminOrManager } from '../Utils/common';
import PageLayout from '../Layout/PageLayout';
import {
  useFetchGetTeamsByManagerQuery,
  useFetchPipelineAccessibilityQuery,
} from '../app/api/teamsSlice';
import DashboardMain from './DashboardMain';
import DropdownMenu from '../common/DropdownMenu';
import Button from '../common/Button';
import { useFetchAllDealsQuery } from '../features/workbook/workbookSlice';
import useDashboardSearchProps from './DashboardSearchField';
import { useFetchAccountsBySearchQuery } from '../Accounts/accountsSlice';
import useDebounce from '../hooks/useDebounce';
import AddTaskNoteDescriptionDrawer from '../common/AddDrawerContent/AddTaskNoteDescriptionDrawer';
import AssignPresalesUser from './AssignPresalesUser';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';

const Index = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const isAdminOrManager = checkIsAdminOrManager();
  const tabContainerRef = useRef();
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showPresalesUserDrawer, setShowPresalesUserDrawer] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 1500);
  const [openCreateOpportunity, setOpenCreateOpportunity] = useState(false);
  const dropdownMenuRef = useRef(null);

  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();

  const { data: newTeams } = useFetchGetTeamsByManagerQuery(undefined, {
    skip: !isAdminOrManager && pipelineAccessibility?.data !== 'user',
  });

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  useEffect(() => {
    if (newTeams?.data?.length > 0) {
      let allTeamUsers = [];
      newTeams?.data?.map((team) => {
        team?.teamMembers?.map((teamMember) => {
          if (
            teamMember?.active === true &&
            !allTeamUsers?.some((user) => user?.value === teamMember?.id)
          ) {
            allTeamUsers.push({
              value: teamMember?.id,
              label: teamMember?.name,
            });
          }
        });
      });
      setTeamMembers(allTeamUsers);
    }
  }, [newTeams]);

  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const handleAddTaskOpen = () => {
    if (dropdownMenuRef?.current.parentNode.dataset.show) {
      delete dropdownMenuRef?.current.parentNode.dataset.show;
    }
    setShowAddTaskModal(true);
  };
  const handleAddTaskCancel = () => setShowAddTaskModal(false);

  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const handleAddNoteOpen = () => {
    if (dropdownMenuRef?.current.parentNode.dataset.show) {
      delete dropdownMenuRef?.current.parentNode.dataset.show;
    }
    setShowAddNoteModal(true);
  };
  const handleAddNoteCancel = () => setShowAddNoteModal(false);

  const {
    data: { activeAccounts = [], accounts = [] } = {
      activeAccounts: [],
      accounts: [],
    },
  } = useFetchAllDealsQuery();

  const { data: searchedAccounts } = useFetchAccountsBySearchQuery(
    {
      searchTerm: debouncedSearchTerm,
      teamMembers:
        isAdminOrManager || pipelineAccessibility?.data === 'user'
          ? teamMembers
          : false,
    },
    {
      skip: !debouncedSearchTerm || debouncedSearchTerm.length < 3,
    }
  );

  useEffect(() => {
    // Replacing default pathname
    if (location.pathname === '/dashboard') {
      history.replace('/dashboard/calendar');
    }
  }, [location]);

  const getTeamData = (data) => {
    // ! We don't seem to be using this. Confirm with Hitesh or Sridhar
    let teamData = [];
    if (data && data.status && data.data && data.data.length > 0) {
      data.data[0].teamMembers.forEach((user) => {
        let userStructure = {};
        userStructure.label = user.name;
        userStructure.value = user.id;
        teamData.push(userStructure);
      });
    }
    setTeamMembers(teamData);
  };

  const handleCreateOpportunityModalToggle = useCallback(() => {
    if (dropdownMenuRef?.current.parentNode.dataset.show) {
      delete dropdownMenuRef?.current.parentNode.dataset.show;
    }
    setOpenCreateOpportunity((prev) => !prev);
  });

  const Menu = (
    <div
      ref={tabContainerRef}
      className="flex-align-center"
      style={{ width: '75%' }}
    >
      <button className="accounts-button active">
        <div className="accounts-tab">
          <h2 className="heading_level4 text-dark">Activity Manager</h2>
        </div>
      </button>
    </div>
  );

  const handleSearch = (event) => {
    if (!event.target.value || event.target.value.length < 3) {
      setSearchTerm(null);
      return;
    }
    setSearchTerm(event.target.value);
  };

  const searchProps = useDashboardSearchProps(handleSearch, searchedAccounts);

  const handleAddTaskDrawerClose = () => {
    setShowAddTaskModal(false);
  };

  const handleAddNotesDrawerClose = () => {
    setShowAddNoteModal(false);
  };

  const handleAssignPresalesUserToggle = () => {
    setShowPresalesUserDrawer(!showPresalesUserDrawer);
  };

  return (
    <PageLayout
      headerMainExtra={Menu}
      searchProps={searchProps}
      extra={[
        <DropdownMenu
          key="dropdown-menu"
          ref={dropdownMenuRef}
          placement="bottom-end"
          content={
            <>
              <Button type="text" onClick={handleCreateOpportunityModalToggle}>
                Create Opportunity
              </Button>
              {configFeatures?.data.crm === 'salesforce' && (
                <Button type="text" onClick={handleAssignPresalesUserToggle}>
                  Assign Presales User
                </Button>
              )}
              <Button type="text" onClick={handleAddNoteOpen}>
                Create Notes
              </Button>
              <Button type="text" onClick={handleAddTaskOpen}>
                Create Tasks
              </Button>
            </>
          }
        >
          <button className="create-opportunity-icon">
            <AddSVG />
          </button>
        </DropdownMenu>,
      ]}
    >
      {openCreateOpportunity && (
        <CreateOpportunity
          handleClose={handleCreateOpportunityModalToggle}
          openDrawer={openCreateOpportunity}
          key="dashboard-create-opportunity"
        />
      )}
      <Route
        path={
          path === '/dashboard' ? `${path}/:tab/:recent?` : `${path}/:dealID`
        }
      >
        <DashboardMain path={path} />
      </Route>
      {showAddTaskModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showAddTaskModal}
          closeDrawer={handleAddTaskDrawerClose}
          tab="Task"
          action="add"
          key="popup-add-task"
        />
      )}
      {showAddNoteModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showAddNoteModal}
          closeDrawer={handleAddNotesDrawerClose}
          tab="Notes"
          action="add"
          key="popup-add-notes"
        />
      )}
      {showPresalesUserDrawer && (
        <AssignPresalesUser
          openDrawer={showPresalesUserDrawer}
          handleCloseDrawer={handleAssignPresalesUserToggle}
        />
      )}
    </PageLayout>
  );
};

export default Index;
