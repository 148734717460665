import React from 'react';
import { ReactComponent as ProfileSVG } from '../Icons/user.svg';
import { ReactComponent as PeopleSVG } from '../Icons/people.svg';
import { ReactComponent as WorkFlowSVG } from '../Icons/workflow.svg';
import { ReactComponent as PipelineSVG } from '../Icons/profile.svg';

const SettingsCard = [
  {
    title: 'Teams',
    key: 'teams_card',
    cards: [
      {
        title: 'Users',
        description: 'Provide personal details and how we can reach you',
        key: 'users',
        icon: <ProfileSVG />,
      },
      {
        title: 'Teams',
        description: 'Provide personal details and how we can reach you',
        key: 'teams',
        icon: <PeopleSVG />,
      },
    ],
  },
  {
    title: 'Configurations',
    key: 'configurations_card',
    cards: [
      {
        title: 'Pipeline and Preferences',
        description: 'Provide personal details and how we can reach you',
        key: 'pipeline_preferences',
        icon: <PipelineSVG />,
      },
      {
        title: 'Outcomes and Automation',
        description: 'Provide personal details and how we can reach you',
        key: 'outcome_automation',
        icon: <WorkFlowSVG />,
      },
    ],
  },
];

export const USER_ROLES = [
  { label: 'Manager', value: 'Manager' },
  { label: 'User', value: 'User' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Product', value: 'Product' },
];

export const TEAM_ROLES = [
  { label: 'Manager', value: 'Manager' },
  { label: 'User', value: 'User' },
  { label: 'Admin', value: 'Admin' },
];

export const USER_STATUS = [
  { label: 'All', value: 'ALL' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'In-Active', value: 'IN_ACTIVE' },
];

export default SettingsCard;
