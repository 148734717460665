import React from 'react';
import { ReactComponent as ThreeDotsSVG } from '../../Icons/NewImage/threedots.svg';
import Button from '../../common/Button';
import styles from '../../styles/components/preskaleDropdownMenu.module.scss';

const ExtraActionsMenu = ({ children, showMenu, toggleMenu }) => {
  return (
    <div className={styles.extras_menu}>
      <Button
        icon
        className={`btn-icon-only extra-actions-menu ml-6 ${
          showMenu ? 'active-icon' : ''
        }`}
        onClick={toggleMenu}
      >
        <ThreeDotsSVG />
      </Button>
      {children}
    </div>
  );
};

export default ExtraActionsMenu;
