import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { businessService } from './businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

const contributionAdapter = createEntityAdapter();

const initialState = contributionAdapter.getInitialState();

export const extendedContributionSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    getParentContribution: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.contribution.getParentContribution;
      },
      transformResponse: (responseData) => {
        return contributionAdapter.setAll(initialState, responseData);
      },
      providesTags: (result = [], error, arg) => {
        return ['Contributions', 'All'];
      },
    }),
    outcomeTags: builder.query({
      query: (members) => {
        return `${
          URLS_BUSINESS_SERVICE.contribution.getTeamMembersOutcomeTags
        }${members ? `?teamMembers=${members}` : ''}`;
      },
    }),
    fetchAllTeamOutcomeTags: builder.query({
      query: (members) => {
        return `${
          URLS_BUSINESS_SERVICE.contribution.getTeamMembersOutcomeTags
        }${members ? `?teamMembers=${members}` : ''}`;
      },
    }),
    deleteOutcome: builder.query({
      query: (outcomeId) => {
        return `${URLS_BUSINESS_SERVICE.contribution.deleteOutcome}?id=${outcomeId}`;
      },
    }),
    addContributions: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.contribution.addParentContribution,
        method: 'POST',
        body,
      }),
    }),
    mergeOutcome: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.contribution.mergeOutcome,
        method: 'POST',
        body,
      }),
    }),
    updateOutcome: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.contribution.updateOutcome,
        method: 'POST',
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        console.log(body);
        const patchResult = dispatch(
          extendedContributionSlice.util.updateQueryData(
            'getParentContribution',
            undefined,
            (draft) => {
              const newName = body.changedName;
              const entityIdToUpdate = body.id;
              draft.entities[entityIdToUpdate].title = newName;
              return draft;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetParentContributionQuery,
  useOutcomeTagsQuery,
  useLazyFetchAllTeamOutcomeTagsQuery,
  useAddContributionsMutation,
  useLazyDeleteOutcomeQuery,
  useMergeOutcomeMutation,
  useUpdateOutcomeMutation,
} = extendedContributionSlice;
