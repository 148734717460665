import React, { useRef, useState } from 'react';
import Drawer from '../common/Drawer';
import DailyTaskNotification from './UserPreference/DailyTaskNotification';
import WeeklyMeetingNotification from './UserPreference/WeeklyMeetingNotification';
import MonthlyMeetingNotification from './UserPreference/MonthlyMeetingNotification';
import SideBarLayout from '../common/SideBarLayout';
import {
  useGetAllUserPreferencesQuery,
  useGetContributionsAdminOpsQuery,
} from './adminOpsSlice';
import { colourStyles } from '../Constant/MultiSelectStylesAndComponents';
import DealBreakerNotification from './UserPreference/DealBreakerNotification';
import ReportsPermissions from './UserPermission/ReportsPermissions';
import CommonPreference from './UserPreference/CommonPreference';
import { NAV_ITEMS } from './utils/AdminOpsConstants';

const UserPreferences = ({
  companyId,
  openUserPreference,
  setOpenUserPreference,
}) => {
  const [activeSection, setActiveSection] = useState('common_preference');
  const sectionRefs = useRef({});
  const { data: userPreferences } = useGetAllUserPreferencesQuery(
    { companyId },
    { skip: !companyId }
  );

  const { data: outcomes } = useGetContributionsAdminOpsQuery(
    { companyId },
    { skip: !companyId }
  );

  const customStyles = {
    ...colourStyles,
    control: (base, { isFocused }) => {
      return {
        ...base,
        border: `1px solid ${isFocused ? '#447bf2' : '#e2ebf3'}`,
        '&:hover': {
          outline: '1px solid #e2ebf3',
        },
      };
    },
  };

  const handleClick = (section) => {
    const reference = section.reference;
    setActiveSection(section?.id);
    sectionRefs.current[reference].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Drawer
      showDrawer={openUserPreference}
      closeDrawer={() => setOpenUserPreference(false)}
      title="User Preference and Permissions"
      className="presentationViewDrawer"
      headerClassName="presentationViewHeader"
      closeOnOverlayClick={false}
    >
      <SideBarLayout
        items={NAV_ITEMS}
        activeSection={activeSection}
        handleClick={handleClick}
        withHeader={true}
      >
        <CommonPreference
          ref={(scroll) => (sectionRefs.current['commonPreference'] = scroll)}
          userPreferences={userPreferences?.data}
          customStyles={customStyles}
          companyId={companyId}
          outcomes={outcomes}
        />
        <DailyTaskNotification
          ref={(scroll) => (sectionRefs.current['taskNotification'] = scroll)}
          userPreferences={userPreferences?.data}
          customStyles={customStyles}
          companyId={companyId}
        />
        <WeeklyMeetingNotification
          ref={(scroll) => (sectionRefs.current['weeklyMeeting'] = scroll)}
          userPreferences={userPreferences?.data}
          customStyles={customStyles}
          companyId={companyId}
        />
        <MonthlyMeetingNotification
          ref={(scroll) => (sectionRefs.current['monthlyMeeting'] = scroll)}
          userPreferences={userPreferences?.data}
          customStyles={customStyles}
          companyId={companyId}
        />
        <DealBreakerNotification
          ref={(scroll) => (sectionRefs.current['dealBreaker'] = scroll)}
          userPreferences={userPreferences?.data}
          customStyles={customStyles}
          companyId={companyId}
        />
        <ReportsPermissions
          ref={(scroll) => (sectionRefs.current['reports'] = scroll)}
          userPreferences={userPreferences?.data}
          customStyles={customStyles}
          companyId={companyId}
        />
      </SideBarLayout>
    </Drawer>
  );
};

export default UserPreferences;
