import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Reports from './Reports';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import { useFetchReportsQuery } from './reportsSlice';
import { getDefaultTab } from '../Utils/common';
import { useGetUserPreferenceByIdQuery } from '../app/api/usersSlice';
import useDataPersistance from '../hooks/useDataPersistance';

const ReportingManager = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { getStorageData } = useDataPersistance();
  const userObject = getStorageData('user');
  const userId = userObject?.userId;
  const companyId = userObject?.companyId;
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const { data: userPreferenceData } = useGetUserPreferenceByIdQuery({
    companyId: companyId,
    userId: userId,
  });
  const { data: reports } = useFetchReportsQuery();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (configFeatures && reports) {
      const tabs = getDefaultTab(
        configFeatures?.data,
        userPreferenceData?.data?.reports,
        reports
      );
      setTabs(tabs);
    }
  }, [configFeatures, reports, userPreferenceData?.data?.reports]);

  useEffect(() => {
    // Replacing default pathname
    if (location.pathname === '/reports') {
      if (tabs.length) {
        const reportUrl = `/reports/${tabs[0].key}`;
        if (reportUrl) {
          history.replace(reportUrl);
        }
      }
    }
  }, [location, tabs]);

  return (
    <Route path={`${path}/:tab`}>
      <Reports
        tabs={tabs}
        reportsForUser={userPreferenceData?.data?.reports}
        configFeatures={configFeatures?.data}
        reports={reports}
      />
    </Route>
  );
};

export default ReportingManager;
