import React from 'react';
import Select from 'react-select';
import {
  SELECT_STYLES,
  ROLES,
  TEAM_SIZE,
  SOURCE,
  CURRENT_PG_SETUP,
} from '../Constant/SignupConstants';
import styles from '../styles/pages/signupForm.module.scss';

const SecondaryForm = ({ values, handleChange, errors }) => {
  return (
    <>
      <label className={styles.form_field__container} htmlFor="role">
        Role
        <div className={styles.form_field}>
          <Select
            name="role"
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            placeholder="Select your role"
            className={`${styles.form_input} ${styles.form_input_select}`}
            styles={SELECT_STYLES}
            value={values.role}
            options={ROLES}
            onChange={(option) => {
              handleChange('role', !option ? '' : option);
            }}
            menuPlacement="auto"
          />
        </div>
        {errors.touched.role && Boolean(errors.errors.role) && (
          <span className={styles.form_error}>{errors.errors.role}</span>
        )}
      </label>
      <label className={styles.form_field__container} htmlFor="teamSize">
        Team Size
        <div className={styles.form_field}>
          <Select
            name="teamSize"
            placeholder="Select your team size"
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            className={`${styles.form_input} ${styles.form_input_select}`}
            styles={SELECT_STYLES}
            value={values.teamSize}
            options={TEAM_SIZE}
            onChange={(option) => {
              handleChange('teamSize', !option ? '' : option);
            }}
            menuPlacement="auto"
          />
        </div>
        {errors.touched.teamSize && Boolean(errors.errors.teamSize) && (
          <span className={styles.form_error}>{errors.errors.teamSize}</span>
        )}
      </label>
      <label
        className={styles.form_field__container}
        htmlFor="productGapTracker"
      >
        How are you tracking product gaps?
        <div className={styles.form_field}>
          <Select
            name="productGapTracker"
            placeholder="Select the current setup"
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            className={`${styles.form_input} ${styles.form_input_select}`}
            styles={SELECT_STYLES}
            value={values.productGapTracker}
            options={CURRENT_PG_SETUP}
            onChange={(option) => {
              handleChange('productGapTracker', !option ? '' : option);
            }}
            menuPlacement="auto"
          />
        </div>
        {errors.touched.productGapTracker &&
          Boolean(errors.errors.productGapTracker) && (
            <span className={styles.form_error}>
              {errors.errors.productGapTracker}
            </span>
          )}
      </label>
      <label className={styles.form_field__container} htmlFor="referralSource">
        Where did you find us?
        <div className={styles.form_field}>
          <Select
            name="referralSource"
            placeholder="We’re excited to know!"
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            className={`${styles.form_input} ${styles.form_input_select}`}
            styles={SELECT_STYLES}
            value={values.referralSource}
            options={SOURCE}
            onChange={(option) => {
              handleChange('referralSource', !option ? '' : option);
            }}
            menuPlacement="auto"
          />
        </div>
        {errors.touched.referralSource &&
          Boolean(errors.errors.referralSource) && (
            <span className={styles.form_error}>
              {errors.errors.referralSource}
            </span>
          )}
      </label>
    </>
  );
};

export default SecondaryForm;
