import { URLS_BUSINESS_SERVICE } from '../Constant/urls';
import { constructQueryString } from '../Utils/common';

export const setDealToClosedAccount = (
  service,
  dealId,
  isClosed,
  handleSuccess
) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.accounts.dealClosed}?&deal_id=${dealId}&is_closed_account=${isClosed}`,
    onSuccess: handleSuccess,
  });

export const getAccountDetails = (service, accountId) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.accounts.accountDetails}?account_id=${accountId}`,
  });

export const getAccountSummary = (service, dealId, handleSuccess) => {
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.accounts.accountSummary}?deal_id=${dealId}`,
    onSuccess: handleSuccess,
  });
};

export const getAccountOwners = (service, handleSuccess) => {
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.accounts.salesOwners,
    onSuccess: handleSuccess,
  });
};

export const getAccountsByStatus = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.accounts.getByStatus,
    onSuccess: handleSuccess,
  });

export const getIndividualReportingTrends = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.accounts.individualTrends,
    data,
    onSuccess: handleSuccess,
  });
};

export const getIndividualReporting = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.accounts.individualReporting,
    data,
    onSuccess: handleSuccess,
  });
};

export const customSectionEnabled = (service) => {
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.customSection.isCustomSectionEnabled,
  });
};

export const fetchAccountsBySearch = (service, teamMembers, searchTerm) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.accounts.search}?term=${encodeURIComponent(
      searchTerm
    )}${
      teamMembers
        ? constructQueryString('userIds', teamMembers, 'teamMembers')
        : ''
    }`,
  }); // TODO: Needs to be replaced with rtk query in pipeline
