import {
  useFetchDealDetailsQuery,
  useLazyFetchDealDetailsQuery,
} from '../Accounts/accountsSlice';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const useDealDetails = (dealId, salesForceView = false) => {
  const history = useHistory();

  const {
    data: dealDetails,
    refetch: refetchDealDetails,
    isError,
    isFetching,
    isLoading,
  } = useFetchDealDetailsQuery(
    salesForceView
      ? {
          dealId,
          salesForceView,
        }
      : { dealId },
    {
      skip: !dealId,
    }
  );

  const [
    fetchDealDetails,
    { isFetching: isManuallyFetching, isLoading: isManuallyLoading },
  ] = useLazyFetchDealDetailsQuery();

  const redirectToDealDetails = (deal = { dealId: '' }, successCb, errorCb) => {
    if (!deal?.dealId) {
      console.error('Cannot redirect: Missing deal ID!');
      return;
    }

    fetchDealDetails(
      deal.salesForceView
        ? { dealId: deal.dealId, salesForceView: deal.salesForceView }
        : { dealId: deal.dealId }
    )
      .unwrap()
      .then(() => {
        if (typeof successCb === 'function') {
          successCb();
        }
        history.push(`/dealDetails/${deal.dealId}`);
      })
      .catch((error) => {
        toast.error('Error: Deal Details Loading Error');
        if (typeof errorCb === 'function') {
          errorCb();
        }
        console.error(error);
      });
  };

  return {
    loading: isLoading || isManuallyLoading,
    fetching: isFetching || isManuallyFetching,
    error: isError,
    refetch: refetchDealDetails,
    fetch: fetchDealDetails,
    dealDetails: dealDetails?.data,
    goToDealDetails: redirectToDealDetails,
  };
};

export default useDealDetails;
