import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useDataPersistance from './useDataPersistance';

//  ! Styles are in layout.scss

const useTabMenu = (
  tabList = [],
  handleClick,
  options = { storageLabel: false, addSeparator: false }
) => {
  const location = useLocation();
  const history = useHistory();
  const tab = useParams();
  const [active, setActive] = useState(0);
  const { setStorageData } = useDataPersistance();
  useEffect(() => {
    if (!tab?.tab) {
      return;
    }
    if (!Array.isArray(tabList)) {
      console.warn('tabList should be an array of items');
      return;
    }
    tabList.every((tabItem) => {
      if (tab?.tab === tabItem.key) {
        setActive(tabItem.key);
        return false;
      }
      return true;
    });
  }, [tab?.tab, tabList]);

  const handleTabClick = (tab) => () => {
    const path = location.pathname.split('/');
    if (!path.length || path.length === 1) {
      return;
    }
    if (handleClick) {
      handleClick(tab);
    }
    options.storageLabel && setStorageData(options.storageLabel, tab.key, true);
    history.push(`/${path[1]}/${tab.key}`);
  };

  const tabMenu = tabList.map((tabItem) => {
    return (
      <button
        key={tabItem.key}
        onClick={handleTabClick(tabItem)}
        className={`tab-button ${options.addSeparator ? '' : 'no-separator'} ${
          active === tabItem.key ? 'tab-active' : ''
        }`}
      >
        {tabItem.label}
      </button>
    );
  });

  return { tabMenu, active };
};

export default useTabMenu;
