import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  InputNumber,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import dayjs, { localeData } from 'dayjs';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as ReopenSVG } from '../Icons/Refresh.svg';
import PreskaleMultiSelect from '../AdminSettings/PreskaleMultiSelect';
import {
  DATE_OPERATOR_OPTIONS,
  REVENUE_OPERATOR_OPTIONS,
} from '../Constant/FilterOperators';
import {
  useFetchAllDealTypesQuery,
  useFetchCRMStagesQuery,
  useFetchSalesOwnersQuery,
} from '../Accounts/accountsSlice';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { Text, Title } = Typography;

const PRESKALE_STAGES = ['Closed Won', 'Closed Lost', 'Active'];

const Filter = ({ openDrawer, handleClose, onSubmit }) => {
  const [owners, setOwners] = useState([]);
  const [type, setType] = useState([]);
  const [revenue, setRevenue] = useState('');
  const [amount, setAmount] = useState('');
  const [salesStage, setSalesStage] = useState([]);
  const [expectedCloseDate, setExpectedCloseDate] = useState('');
  const [expectedCloseDateRange, setExpectedCloseDateRange] = useState([]);
  const { data: salesOwners, isLoading: salesOwnerLoading } =
    useFetchSalesOwnersQuery(undefined, {
      selectFromResult: ({ data }) => {
        const salesOwners = useMemo(() => {
          return data?.filter((owner) => {
            if (owner?.value) {
              return owner;
            }
          });
        }, [data]);
        return { data: salesOwners };
      },
    });
  const { data: salesStages, isLoading: loadingSalesStages } =
    useFetchCRMStagesQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => {
        const salesStage = useMemo(() => {
          return data?.stages?.filter((stage) => {
            if (stage?.value && !PRESKALE_STAGES.includes(stage?.value)) {
              return stage;
            }
          });
        }, [data?.stages]);
        return { data: salesStage };
      },
    });

  const { data: dealTypes, isLoading: loadingDealTypes } =
    useFetchAllDealTypesQuery();

  useEffect(() => {
    // Retrieve values from localStorage when component mounts
    const storedFilters = JSON.parse(localStorage.getItem('filters')) || {};

    // If no data is stored, set default values based on options
    if (Object.keys(storedFilters).length === 0) {
      setOwners(salesOwners);
      setType(dealTypes);
      setRevenue(REVENUE_OPERATOR_OPTIONS[0]?.value || '');
      setExpectedCloseDate(DATE_OPERATOR_OPTIONS[3]?.value);
      setAmount('');
      setSalesStage(salesStages || []);
    } else {
      // If data is stored, use the stored values
      setOwners(storedFilters.owners || []);
      setType(storedFilters.type || []);
      setRevenue(storedFilters.revenue || '');
      setExpectedCloseDate(storedFilters?.expectedCloseDate);
      setExpectedCloseDateRange([
        storedFilters.expectedCloseDateRange?.[0] ?? null,
        storedFilters.expectedCloseDateRange?.[1] ?? null,
      ]);
      setAmount(storedFilters.amount || '');
      setSalesStage(storedFilters.salesStage || []);
    }
  }, [dealTypes, salesOwners, salesStages, dealTypes]);

  const handleReset = () => {
    // Reset all state variables to their initial values
    setOwners(salesOwners);
    setType(dealTypes);
    setRevenue(REVENUE_OPERATOR_OPTIONS[0]?.value || '');
    setExpectedCloseDate(DATE_OPERATOR_OPTIONS[3]?.value);
    setAmount('');
    setSalesStage(salesStages || []);

    const values = {
      owners: salesOwners,
      revenue: REVENUE_OPERATOR_OPTIONS[0]?.value,
      amount: '',
      salesStage: salesStages,
      type: dealTypes,
      expectedCloseDate: DATE_OPERATOR_OPTIONS[3]?.value,
    };
    localStorage.setItem('filters', JSON.stringify(values));
    onSubmit(values);
  };

  const handleOwnersChange = (value) => {
    setOwners(value);
  };

  const handleTypeChange = (value) => {
    setType(value);
  };

  const handleRevenueChange = (value) => {
    if (value === 'ALL') {
      setAmount(null);
    }
    setRevenue(value);
  };

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  const handleSalesStageChange = (value) => {
    setSalesStage(value);
  };

  const onFinish = () => {
    console.log(expectedCloseDateRange);
    const values = {
      owners,
      revenue,
      amount,
      salesStage,
      type,
      expectedCloseDate,
      expectedCloseDateRange,
    };
    localStorage.setItem('filters', JSON.stringify(values));
    onSubmit(values);
    handleClose();
  };

  const handleExpectedCloseDateChange = (value) => {
    setExpectedCloseDate(value);
  };

  const onCustomFilterChange = (date, dateString) => {
    setExpectedCloseDateRange(dateString);
  };

  const dateStringToDates = (dateStrings) => {
    if (!dateStrings || dateStrings.length !== 2) {
      return [null, null];
    }
    return [
      dateStrings[0] ? dayjs(new Date(dateStrings[0])) : null,
      dateStrings[1] ? dayjs(new Date(dateStrings[1])) : null,
    ];
  };
  return (
    <Drawer
      destroyOnClose
      open={openDrawer}
      onClose={handleClose}
      title={<Title level={4}>Filter</Title>}
      closable={false}
      footer={
        <Flex gap={3} justify="end">
          <Tooltip title="Reset to Default">
            <Button
              icon={<ReopenSVG height={16} width={16} />}
              onClick={handleReset}
            />
          </Tooltip>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" onClick={onFinish}>
            Apply
          </Button>
        </Flex>
      }
      extra={<Button icon={<CloseOutlined />} onClick={handleClose} />}
      styles={{
        wrapper: {
          width: '30%',
        },
      }}
    >
      <Flex vertical gap={10}>
        <Text strong>Sales Owner</Text>
        <PreskaleMultiSelect
          value={owners}
          placeholder="Select options"
          maxTagCount={2}
          options={salesOwners || []}
          onChange={handleOwnersChange}
          showSearch={true}
        />

        <Text strong>Type</Text>
        <PreskaleMultiSelect
          value={type}
          placeholder="Select options"
          maxTagCount={2}
          options={dealTypes || []}
          onChange={handleTypeChange}
        />
        <Flex gap={10} vertical>
          <Text strong>Revenue</Text>

          <Flex gap={10}>
            <Select
              options={REVENUE_OPERATOR_OPTIONS}
              className="width-200"
              value={revenue}
              onChange={handleRevenueChange}
            />
            <InputNumber
              placeholder="Enter Opportunity Value"
              min={0}
              disabled={revenue === 'ALL'}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              className="width-200"
              onChange={handleAmountChange}
              value={amount}
            />
          </Flex>
        </Flex>
        <Flex vertical gap={10}>
          <Text strong>Sales Stage</Text>
          <PreskaleMultiSelect
            value={salesStage}
            placeholder="Select options"
            maxTagCount={2}
            options={salesStages || []}
            onChange={handleSalesStageChange}
          />
        </Flex>
        <Flex vertical gap={10}>
          <Text strong>Expected Close Date</Text>
          <Select
            options={DATE_OPERATOR_OPTIONS}
            value={expectedCloseDate}
            onChange={handleExpectedCloseDateChange}
          />
          {expectedCloseDate === 'CUSTOM' && (
            <Flex align="center" gap={10}>
              <DatePicker.RangePicker
                onChange={onCustomFilterChange}
                value={dateStringToDates(expectedCloseDateRange)}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default Filter;
