import { Flex, Form, Select } from 'antd';
import Typography from 'antd/es/typography/Typography';
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { renderDetails } from '../tasks/utils';

const { Text } = Typography;

const AddNotesSidecontent = ({
  notesAccount,
  allAccounts,
  accountSummary,
  loggedInUser,
  form,
  initialValues,
  action,
  dealDetails,
  userMap,
}) => {
  useEffect(() => {
    if (initialValues && action === 'update') {
      form.setFieldsValue({
        'notes-opportunity': initialValues?.dealId,
        'notes-account': initialValues?.accountId,
      });
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (dealDetails) {
      form.setFieldsValue({
        'notes-account': dealDetails?.accountId,
        'notes-opportunity': dealDetails?.dealId,
      });
    }
  }, [dealDetails]);

  const renderFormItems = () => (
    <>
      <Form.Item
        label={<Text strong>Account</Text>}
        name="notes-account"
        rules={[
          {
            required: true,
            message: 'Account field is Required',
          },
        ]}
      >
        <Select
          options={allAccounts}
          placeholder="Select an account"
          size="large"
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
      <Form.Item
        label={<Text strong>Opportunity</Text>}
        name="notes-opportunity"
        rules={[
          {
            required: true,
            message: 'Opportunity field is Required',
          },
        ]}
      >
        <Select
          options={
            notesAccount
              ? accountSummary?.filter(
                  (data) => data.accountId === notesAccount
                )
              : accountSummary
          }
          placeholder="Select an opportunity"
          size="large"
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
    </>
  );

  return (
    <>
      {action === 'update' || dealDetails
        ? renderDetails(
            allAccounts,
            accountSummary,
            dealDetails ? dealDetails?.accountId : initialValues?.accountId,
            dealDetails ? dealDetails?.dealId : initialValues?.dealId
          )
        : renderFormItems()}
      <Flex vertical>
        <Text strong type="secondary">
          Note Creator
        </Text>
        <Text strong>
          {initialValues
            ? userMap?.filter(
                (data) => data.value === initialValues?.userId
              )?.[0]?.label
            : loggedInUser?.name}
        </Text>
      </Flex>
      <Flex vertical>
        <Text strong type="secondary">
          Note Created On
        </Text>
        <Text strong>
          {initialValues
            ? format(new Date(initialValues?.createdDate), 'dd/MM/yyyy')
            : format(new Date(), 'dd/MM/yyyy')}
        </Text>
      </Flex>
    </>
  );
};

export default AddNotesSidecontent;
