import React, { useState, useEffect, useMemo } from 'react';
import {
  useFetchJiraIssuesOnSearchMutation,
  useFetchJiraProjectsQuery,
  useFetchJiraStatusQuery,
} from '../../features/ProductGap/productGapSlice';
import useDebounce from '../../hooks/useDebounce';

import { noData } from '../../Utils/common';
import Select from 'react-dropdown-select';
import { MultiSelect } from '../../common/MultiSelect';
import {
  ValueContainer,
  MultiValueRemove,
  customStylesForJiraFilters,
  RemoveIndicatorSeparator,
  Option,
} from '../../Constant/MultiSelectStylesAndComponents';
import { ReactComponent as LeftArrowSVG } from '../../Icons/ArrowLeft.svg';
import { ReactComponent as AddGreenSVG } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';

import JiraForm from './JiraForm';
import JiraList from './jiraList';
import JiraShow from './jiraShow';
import Button from '../../common/Button';

const DEFAULT_FILTER = [{ label: 'All', value: 'All' }];
const JiraPane = ({
  bubbleUpSelectedIssue,
  // issues,
  isCreateForm,
  isJiraReporterDisable,
  setParentView,
  searchKey,
  closeButton,
  standalone = false,
  onJiraSelect = false,
}) => {
  const [jiraSearch, { isLoading }] = useFetchJiraIssuesOnSearchMutation();
  const [selectedProject, setSelectedProject] = useState(DEFAULT_FILTER);
  const [selectedStatus, setSelectedStatus] = useState(DEFAULT_FILTER);
  const { data: jiraProjects } = useFetchJiraProjectsQuery(undefined, {
    selectFromResult: ({ data }) => {
      const options = useMemo(() => {
        let optionValues = [];
        optionValues = data?.map((project) => ({
          label: project.name,
          value: project.projectId,
        }));
        return optionValues;
      }, [data]);
      return {
        data: options ? [...DEFAULT_FILTER, ...options] : DEFAULT_FILTER,
      };
    },
  });
  const { data: jiraStatus } = useFetchJiraStatusQuery(undefined, {
    selectFromResult: ({ data }) => {
      const options = useMemo(() => {
        let optionValues = [];
        optionValues = data?.data?.map((status) => ({
          label: status,
          value: status,
        }));
        return optionValues;
      }, [data]);
      return {
        data: options ? options : DEFAULT_FILTER,
      };
    },
  });

  const [jiraIssues, setJiraIssues] = useState([]);
  const [view, setView] = useState('listView'); //listView, detailView, createView
  const [selectedIssueId, setSelectedIssueId] = useState(false);
  const [jiraSearchKeyword, setJiraSearchKeyword] = useState(searchKey);

  const debouncedKeyword = useDebounce(jiraSearchKeyword, 1000);

  useEffect(() => {
    if (
      Array.isArray(selectedStatus) &&
      selectedStatus.length > 0 &&
      Array.isArray(selectedProject) &&
      selectedProject.length > 0
    ) {
      let statusIds = [];
      if (selectedStatus.length === jiraStatus.length) {
        statusIds = ['All'];
      } else {
        statusIds = selectedStatus.map((status) => status.value);
      }

      const data = {
        searchTerm: debouncedKeyword,
        status: statusIds,
        projectId: selectedProject[0].value,
        offset: 0,
      };

      jiraSearch(data)
        .unwrap()
        .then((response) => {
          setJiraIssues(response?.data?.result);
        });
    }
  }, [debouncedKeyword, selectedProject, selectedStatus]);

  // TODO: Remove in next iteration
  // useEffect(() => {
  //   issues && setSelectedIssueId(issues.issue_id);
  // }, [issues]);

  const handleBacktoPglist = () => {
    setParentView('list');
  };

  const handleCreateJira = () => {
    setView('createView');
  };

  const handleBacktoJiralist = () => {
    setView('listView');
  };

  const jiraIssuesIndetails = (issue) => {
    setView('detailView');
    setSelectedIssueId(issue.issue_id);
  };

  const handleJiraSubmit = (data) => {
    // bubbleUpSelectedIssue(data);

    setJiraIssues([data]);
    if (setParentView) {
      handleBacktoPglist();
    }
  };

  const handleSearchKey = (value) => {
    setJiraSearchKeyword(value);
  };

  const handleStatusChange = (option) => {
    if (option != selectedStatus) {
      setSelectedStatus(option);
    }
  };

  return (
    <div
      className={`jira-right-view ${standalone ? 'standalone_jira__list' : ''}`}
    >
      {view === 'createView' && (
        <JiraForm
          handleJiraSubmit={handleJiraSubmit}
          handleBacktoJiralist={handleBacktoJiralist}
          onSelect={onJiraSelect}
          onSuccess={bubbleUpSelectedIssue}
          isJiraReporterDisable={isJiraReporterDisable}
        />
      )}

      {view === 'listView' && (
        <>
          <div className="flex gap-1 pb-0">
            {!standalone && (
              <button
                className="btn-secondary p-2-4 b-4"
                onClick={handleBacktoPglist}
              >
                <LeftArrowSVG />
              </button>
            )}
            <h2 className="heading_level5 text-dark flex-align-center">
              Associate Jira Ticket
            </h2>

            <div className="jira-create flex-align-center flex ml-auto">
              <button
                className="btn btn-icon btn-link"
                onClick={handleCreateJira}
              >
                <span className="add-icon">
                  <AddGreenSVG />
                </span>
                <span className="btn-txt"> Create JIRA ticket </span>
              </button>
              {closeButton && (
                <Button onClick={closeButton} icon className="btn-icon-only">
                  <CrossSVG />
                </Button>
              )}
            </div>
          </div>
          Associate Product Gap Item with Jira issue
          <h2 className="heading_level3 text-dark pb-15 pt-10">
            {' '}
            Select Jira Tickets
          </h2>
          <div className="filtering-options">
            <div className="font-bold text-underline">Project:</div>
            <Select
              noDataRenderer={() => noData()}
              options={jiraProjects}
              values={selectedProject}
              onChange={(option) => setSelectedProject(option)}
            />
            <div className="font-bold text-underline ml-10">Status:</div>
            <MultiSelect
              noDataRenderer={() => noData()}
              options={jiraStatus}
              value={selectedStatus}
              onChange={(option) => handleStatusChange(option)}
              isSearchable={false}
              components={{
                ValueContainer,
                MultiValueRemove,
                RemoveIndicatorSeparator,
                Option,
              }}
              styles={customStylesForJiraFilters}
            />
          </div>
          <input
            className="jira-search-bar"
            type="text"
            value={jiraSearchKeyword}
            placeholder={'Search Jira Issues'}
            onChange={(event) => {
              handleSearchKey(event.target.value);
            }}
          />
          <JiraList
            jiraIssues={jiraIssues}
            jiraIssuesIndetails={jiraIssuesIndetails}
            onSuccess={bubbleUpSelectedIssue}
            onSelect={onJiraSelect}
            isLoading={isLoading}
          />
        </>
      )}

      {view === 'detailView' && (
        <JiraShow
          jiraIssueId={selectedIssueId}
          handleBacktoJiralist={handleBacktoJiralist}
          onSuccess={bubbleUpSelectedIssue}
          onSelect={onJiraSelect}
          isCreateForm={isCreateForm}
        />
      )}
    </div>
  );
};

export default JiraPane;
