import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Button, Drawer, Flex, Radio, Space, Typography } from 'antd';
import { useConfigureDefaultPipelineMutation } from '../../Company/companySlice';
import { businessService } from '../../app/api/businessService';

const ConfigureDefaultPipeline = ({
  showDrawer,
  setShowConfigurePipelineModal,
  stageView,
}) => {
  const dispatch = useDispatch();
  const [configureDefaultPipeline] = useConfigureDefaultPipelineMutation();
  const [selectedView, setSelectedView] = useState(stageView ?? null);

  const handleCancel = () => {
    setShowConfigurePipelineModal(false);
    setSelectedView(stageView);
    dispatch(
      businessService.util.invalidateTags([{ type: 'Company', id: 'Config' }])
    );
  };

  useEffect(() => {
    setSelectedView(stageView);
  }, [stageView]);

  const onViewChange = (e) => {
    setSelectedView(e.target.value);
  };

  const onSave = () => {
    configureDefaultPipeline(selectedView)
      .then((data) => {
        toast.success('Updated Successfully!');
      })
      .catch((e) => {
        toast.success('Update Failed!');
      })
      .finally(() => {
        dispatch(
          businessService.util.invalidateTags([
            { type: 'Company', id: 'Config' },
          ])
        );
        setShowConfigurePipelineModal(false);
      });
  };

  return (
    <Drawer
      getContainer={false}
      title={<div className="text-primary">Configure Pipeline</div>}
      extra={
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </Space>
      }
      onClose={handleCancel}
      open={showDrawer}
      styles={{
        wrapper: {
          width: '30%',
        },
        mask: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Typography.Text className="text-primary" strong>
        Set Default Pipeline
      </Typography.Text>
      <Flex align="center" gap={20} className="mt-16">
        <Radio.Group
          onChange={onViewChange}
          name="radiogroup"
          value={selectedView}
        >
          <Radio value="preskale">Presales Stage</Radio>
          <Radio value="crm">CRM Stage</Radio>
        </Radio.Group>
      </Flex>
    </Drawer>
  );
};

export default ConfigureDefaultPipeline;
