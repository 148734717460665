import React from 'react';
import styles from '../styles/pages/signupForm.module.scss';

const LandingForm = ({ values, handleChange, errors }) => {
  return (
    <>
      <label className={styles.form_field__container} htmlFor="fullName">
        Full Name
        <div className={styles.form_field}>
          <input
            className={styles.form_input}
            name="fullName"
            placeholder="Enter your First and last name"
            autoComplete="fullName"
            value={values?.fullName}
            onChange={handleChange}
          />
        </div>
        {errors.touched.fullName && Boolean(errors.errors.fullName) && (
          <span className={styles.form_error}>{errors.errors.fullName}</span>
        )}
      </label>
      <label className={styles.form_field__container} htmlFor="email">
        Work Email
        <div className={styles.form_field}>
          <input
            className={styles.form_input}
            name="email"
            placeholder="Enter your Work email address"
            type="email"
            autoComplete="email"
            value={values?.email}
            onChange={handleChange}
          />
        </div>
        {errors.touched.email && Boolean(errors.errors.email) && (
          <span className={styles.form_error}>{errors.errors.email}</span>
        )}
      </label>
    </>
  );
};

export default LandingForm;
