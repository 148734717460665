import React, { useState } from 'react';
import { Flex, Typography } from 'antd';
import DualRingLoader from '../common/DualRingLoader';
import { ReactComponent as QuestionSVG } from '../Icons/NewImage/question.svg';

const { Text, Paragraph, Title } = Typography;

const HelpCenter = () => {
  const [loadingIFrame, setLoadingIFrame] = useState(true);

  const hideIFrameLoader = () => {
    setLoadingIFrame(false);
  };

  return (
    <>
      <Flex align="center" gap={8}>
        <Flex align="center">
          <QuestionSVG className="overflow-visible" />
        </Flex>
        <Title level={5} className="mb-0">
          Need help configuring your Outcome?
        </Title>
      </Flex>

      <div className="p-1em">
        <Paragraph className="mb-8">
          <Text strong>Meeting Title Tags</Text> help{' '}
          <Text strong>PreSkale AI </Text>to{' '}
          <Text strong>auto-tag outcome </Text>according to your terminology.
          All your tags act as training data that help the machine learning
          algorithms to improve and personalize the way the outcome auto-tag
          functions within PreSkale for your account. This also{' '}
          <Text strong> improves </Text> the meeting{' '}
          <Text strong>auto-tagging efficiency</Text> for the entire team.
        </Paragraph>

        <Title level={5}>How to add a new Meeting Title Tag?</Title>
        <Paragraph>
          <ol>
            <li>
              <Text>
                Within the table, You can view the list of outcomes on the left
                panel and Meeting Title Tags on the Right panel.
              </Text>
            </li>
            <li>
              <Text>
                Next to the desired outcome, Type the Meeting Title Tag and
                press the 'Enter' key. Note: The same title tag cannot be used
                for two different outcomes.
              </Text>
            </li>
          </ol>
        </Paragraph>

        <Flex align="center" justify="center" className="mt-20">
          {loadingIFrame && <DualRingLoader dark />}
          <iframe
            width="400"
            height="210"
            src="https://www.loom.com/embed/06d16c43ed6a4c62b1c809ea408dcf4a?sid=5a2c467a-ce0e-4db4-bd68-2381f3e11093"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            onLoad={hideIFrameLoader}
            allowFullScreen
            className={loadingIFrame ? 'display-none' : 'display-block'}
          />
        </Flex>
      </div>
    </>
  );
};

export default HelpCenter;
