import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Avatar from '../common/Avatar';
import useAxios from '../hooks/useAxios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import Logo from '../Icons/NewImage/logo.png';
import { ReactComponent as LightningDashboardSVG } from '../Icons/NewImage/lightningDashBoard.svg';
import { ReactComponent as PipelineViewSVG } from '../Icons/PipelineIcon.svg';
import { ReactComponent as DashboardViewSVG } from '../Icons/homeIcon.svg';
import { ReactComponent as ReportsViewSVG } from '../Icons/reportsIcon.svg';
import { ReactComponent as ProductGapUseCaseSVG } from '../Icons/productgapUsecaseIcon.svg';
import { ReactComponent as RequestFormSVG } from '../Icons/requestFormIcon.svg';
import { ReactComponent as SettingsSVG } from '../Icons/Settings.svg';
import { getCompanyDetails } from '../_services/common.service';
import FeatureAccessControl from '../common/FeatureAccessControl';
import NavProfileMenu from './NavProfileMenu';
import useClickAwayListener from '../hooks/useClickAwayListener';
import ToolTip from '../common/ToolTip';
import { isAdminUser } from '../Utils/common';

const Nav = ({ appLogout }) => {
  const popoverMenuRef = useRef(null);
  const [showNav, setShowNav] = useState(false);
  const popoverBlur = useClickAwayListener(
    popoverMenuRef,
    null,
    () => {},
    true,
    showNav
  );
  const axios = useAxiosPrivate();
  const companyInfo = useAxios(axios);
  const userDetails = localStorage.getItem('user');
  const profileData = userDetails && JSON.parse(userDetails);
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !profileData,
  });

  const [isSalesUser, setSalesUser] = useState(false);
  const [isProductUser, setProductUser] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const isAdmin = isAdminUser();

  useEffect(() => {
    // Hide popup on blur
    if (popoverBlur) {
      setShowNav(false);
    }
  }, [popoverBlur]);

  useEffect(() => {
    if (!profileData?.roles) {
      console.warn('No roles');
      return;
    }
    setSalesUser(profileData.roles.includes('sales'));
    setProductUser(profileData.roles.includes('product'));
    setSuperAdmin(profileData.roles.includes('super_admin'));
  }, [profileData?.roles]);

  const handleProfileImageClick = () => {
    if (!showNav && Object.keys(companyInfo.response).length < 1) {
      getCompanyDetails(companyInfo);
    }
    !popoverBlur && setShowNav((prev) => !prev);
  };

  return (
    <nav className="app-nav">
      <ul>
        <li>
          <NavLink to="/" className="nav-logo">
            <img src={Logo} alt="Preskale" className="logo" />
          </NavLink>
        </li>
        <FeatureAccessControl
          enabled={
            configFeatures?.data?.navPages?.request_form &&
            isSalesUser &&
            !isProductUser
          }
        >
          <li>
            <ToolTip text="Resource Request Form">
              <NavLink
                to="/requestForm"
                data-tip="Request Form"
                className="nav-link"
              >
                <RequestFormSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>

        <FeatureAccessControl
          enabled={
            configFeatures?.data?.navPages?.manager_dashboard &&
            (profileData.roles.includes('admin') ||
              profileData.roles.includes('manager'))
          }
        >
          <li>
            <ToolTip text="Resource Assignment Manager">
              <NavLink
                to="/manager"
                data-tip="Lightning Dashboard"
                className="nav-link"
              >
                <LightningDashboardSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
        <FeatureAccessControl
          enabled={
            configFeatures?.data?.navPages?.dashboard &&
            !isSalesUser &&
            !isProductUser
          }
        >
          <li>
            <ToolTip text="Workspace">
              <NavLink
                to="/dashboard"
                className="nav-link no-fill"
                data-tip="Dashboard"
              >
                <DashboardViewSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
        <FeatureAccessControl
          enabled={configFeatures?.data?.navPages?.pipeline}
        >
          <li>
            <ToolTip text="Pipeline">
              <NavLink
                to="/pipeline"
                data-tip="Pipeline"
                className="nav-link no-fill"
              >
                <PipelineViewSVG />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
        <FeatureAccessControl
          enabled={
            configFeatures?.data?.navPages?.product_gap_use_case && !isSalesUser
          }
        >
          <li>
            <ToolTip text="Libraries">
              <NavLink
                to="/libraries"
                data-tip="Libraries"
                className="nav-link no-fill"
              >
                <ProductGapUseCaseSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
        <FeatureAccessControl
          enabled={configFeatures?.data?.navPages?.statistics && !isSalesUser}
        >
          <li>
            <ToolTip text="Reports">
              <NavLink
                to="/reports"
                data-tip="Reports"
                className="nav-link no-fill"
              >
                <ReportsViewSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
        <FeatureAccessControl enabled={superAdmin}>
          <li>
            <ToolTip text="AdminOps">
              <NavLink to="/adminOps" data-tip="AdminOps" className="nav-link">
                <SettingsSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
        <FeatureAccessControl enabled={isAdmin}>
          <li>
            <ToolTip text="Settings">
              <NavLink to="/settings" data-tip="Settings" className="nav-link">
                <SettingsSVG className="nav-icons" />
              </NavLink>
            </ToolTip>
          </li>
        </FeatureAccessControl>
      </ul>

      <div className="app-profile-content">
        <div className="profile-img" onClick={handleProfileImageClick}>
          <Avatar user={profileData} />
        </div>
      </div>
      {showNav && (
        <NavProfileMenu
          ref={popoverMenuRef}
          profileData={profileData}
          companyInfo={companyInfo}
          logout={appLogout}
        />
      )}
    </nav>
  );
};

export default Nav;
