import React from 'react';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import Button from '../Button';
import { useLazyDeleteNoteQuery } from './notesSlice';
import { fetchTableApi } from '../../Pipeline/pipelineSlice';

const DeleteNote = ({ note, showModal, handleClose = () => {} }) => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    handleClose();
  };

  const [deleteNote, { isLoading }] = useLazyDeleteNoteQuery();

  const handleDeleteNote = () => {
    if (!note?.id || !note?.dealId) {
      console.error('Missing note ID or dealID');
      return;
    }
    deleteNote({ noteId: note?.id, dealId: note?.dealId })
      .unwrap()
      .then((res) => {
        dispatch(fetchTableApi(true));
        console.log('res', res);
        toast.success('Note deleted successfully!');
      })
      .catch((error) => toast.error('Failed to delete note!'));
    handleCancel();
  };
  return (
    <ReactModal
      isOpen={showModal}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Delete Confirmation</h4>
      </div>

      <div className="modal-body">Are you sure you want to delete?</div>

      <div className="modal-footer flex-justify-end">
        <Button type="secondary" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="danger"
          className="ml-5"
          onClick={handleDeleteNote}
          disabled={isLoading}
          loading={isLoading}
        >
          Delete
        </Button>
      </div>
    </ReactModal>
  );
};

export default DeleteNote;
