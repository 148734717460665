import React, { useState } from 'react';
import SearchField from '../../Accounts/commons/SearchField';
import { formatCount } from '../../Utils/Revenue';
import ToolTip from '../../common/ToolTip';
import { Typography } from 'antd';

const { Title } = Typography;

const OpportunityList = ({
  data,
  selectedOpportunity,
  handleSearch,
  filterByDeal,
  tab,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const onSearch = (event) => {
    if (typeof handleSearch === 'function') {
      handleSearch(event);
    }
    if (!event.target.value) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(event.target.value);
  };
  return (
    <div
      className={`pg-usecases-left-panel ${
        tab === 'ProductGapDrawer' ? 'border-right-light-blue-2' : ''
      }`}
    >
      {tab === 'ProductGapDrawer' ? (
        <Title level={5} className="flex-align-center p-5 pl-18">
          Associated Opportunities
        </Title>
      ) : (
        <h2 className="header heading_level4 text-dark p-18">
          {' '}
          List of Opportunities{' '}
        </h2>
      )}
      <SearchField
        placeholder="Search Opportunities"
        onChange={onSearch}
        className={`${
          tab === 'ProductGapDrawer' ? 'm-0 b-0-imp pl-12-imp' : 'mt-20-imp'
        }`}
      />
      <div
        className={`opportunity-section-contents ${
          tab === 'ProductGapDrawer'
            ? 'opportunity-section-pg-drawer'
            : 'opportunity-section-pg'
        }`}
      >
        {Array.isArray(data) &&
          data.map(
            (opportunity) =>
              opportunity.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) && (
                <div
                  key={opportunity.dealId}
                  className={`opportunity-select ${
                    tab === 'ProductGapDrawer'
                      ? 'm-0 b-0-imp border-bottom-light-blue-2'
                      : ''
                  } ${
                    opportunity.dealId === selectedOpportunity ||
                    (opportunity.dealId === 'All' && !selectedOpportunity)
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => filterByDeal(opportunity.dealId)}
                >
                  <ToolTip
                    placement="top"
                    text={opportunity.name}
                    contentClassname="tooltip_content_container"
                  >
                    <span className="opportunity-select-name">
                      {' '}
                      {opportunity.name}{' '}
                    </span>
                  </ToolTip>
                  {opportunity.dealId !== 'All' && (
                    <span className="deal-details">
                      <div className="deal-value">
                        <div className="font-12">Deal Value: </div>
                        <div className="font-12 font-bold">
                          {`$${formatCount(opportunity.dealValue)}`}{' '}
                        </div>
                      </div>
                      {tab && tab === 'ProductGap' ? (
                        <div className="product-gap">
                          <div className="font-12">Product Gap: </div>
                          <div className="font-12 font-bold">
                            {opportunity.productGap}
                          </div>
                        </div>
                      ) : tab === 'Solution' ? (
                        <div className="product-gap flex">
                          <div className="font-12">Solution: </div>
                          <div className="font-12 font-bold">
                            {opportunity.solution}
                          </div>
                        </div>
                      ) : null}
                    </span>
                  )}
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default OpportunityList;
