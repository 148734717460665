export const useCaseDropdownStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: '4px',
      minWidth: '124px',
      width: '160px',
      maxWidth: '200px',
      minHeight: '30px',
      height: '30px',
    };
  },
  dropdownIndicator: (base) => {
    return { ...base, padding: '2px' };
  },
  clearIndicator: (base) => {
    return { ...base, padding: '2px' };
  },
  valueContainer: (base) => {
    return {
      ...base,
      fontWeight: '400',
      padding: '0px',
      fontSize: '12px',
      flexWrap: 'nowrap',
    };
  },
  menu: (base) => {
    return { ...base, width: '160px' };
  },
  multiValue: (base) => {
    return { ...base, fontWeight: '400', backgroundColor: 'none' };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};
