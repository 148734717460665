import React, { useState } from 'react';
import { DateRelativeTime } from '../../Utils/DayJs';
import { ReactComponent as RightArrowSVG } from '../../Icons/ArrowRight.svg';
import { ReactComponent as RefreshJira } from '../../Icons/Refresh.svg';
import Loader from '../../common/Loader';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { refreshJiraMetaData } from '../../_services/common.service';
import { axiosDE } from '../../_helpers/axios';
import { saveRetainValueBoolean } from '../../features/ProductGap/productGapSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  productGapState,
  useUpdateProductGapItemJiraMutation,
} from '../../features/ProductGap/productGapSlice';
import DualRingLoader from '../../common/DualRingLoader';

const JiraList = ({
  jiraIssues,
  jiraIssuesIndetails,
  onSuccess,
  onSelect,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { loading: refreshing, ...refreshJira } = useAxios(axiosDE);
  const [selectedIssue, setSelectedIssue] = useState([]);
  const { selectedProductGap } = useSelector(productGapState);
  const [updatePgItemJira, { isLoading: submittingJiraSelection }] =
    useUpdateProductGapItemJiraMutation();

  const selectJira = (selectedJiraIssue) => {
    if (onSelect) {
      onSelect({
        jiraTitle: selectedJiraIssue?.summary,
        jiraType: selectedJiraIssue?.issuetype?.name,
        jiraIssueId: selectedJiraIssue?.issue_id,
        jiraIssueKey: selectedJiraIssue?.key,
      });
      onSuccess();
      return;
    }
    if (!selectedProductGap?.id) {
      toast.error('Error: Product gap item not selected!');
      return;
    }
    updatePgItemJira({
      id: selectedProductGap?.id,
      jiraTitle: selectedJiraIssue?.summary,
      jiraType: selectedJiraIssue?.issuetype?.name,
      jiraIssueId: selectedJiraIssue?.issue_id,
      jiraIssueKey: selectedJiraIssue?.key,
    })
      .unwrap()
      .then(() => {
        toast.success('Jira associated successfully!');
        onSuccess();
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Jira not associated!');
      });
  };

  const handleJiraIssuesIndetails = (ev, issue) => {
    ev.preventDefault();

    jiraIssuesIndetails(issue);
  };

  const handleRefreshJira = () => {
    const profileData = JSON.parse(localStorage.getItem('user'));
    const userId = profileData?.userId;
    const companyId = profileData?.companyId;
    refreshJiraMetaData(
      refreshJira,
      { user_id: userId, company_id: companyId },
      handleRefreshJiraSuccess,
      handleRefreshJiraError
    );
  };

  const handleRefreshJiraSuccess = () =>
    toast.success('Jira Projects Refreshed Successfully');

  const handleRefreshJiraError = (error) => {
    if (error?.response && !error.response?.data?.status) {
      toast.error(error.response);
      return;
    }
    toast.error('Unable to refresh Jira');
  };

  const handleRadioButton = (ev, issue) => {
    ev.stopPropagation();
    console.log(' --- handleRadioButton --- ', issue);
    setSelectedIssue(issue);
  };

  return (
    <>
      <div className="modal-header">
        <div className="refresh-icon width-5">
          <button
            data-tip="Refresh Jira Projects"
            className="jira-refresh-container"
            onClick={handleRefreshJira}
            disabled={refreshing}
          >
            <span>
              <RefreshJira
                className={refreshing ? 'refresh-loading--animation' : ''}
              />
            </span>
          </button>
          <ReactTooltip place="top" backgroundColor="#172B4D" effect="solid" />
        </div>
      </div>
      <div className="modal-body jira-issues-modal-body">
        {isLoading ? (
          <Loader customClass="pg-jira-list-loader" />
        ) : (
          <>
            <div className="pg-jira-issue-list">
              {Array.isArray(jiraIssues) &&
                jiraIssues.map((issue, index) => (
                  <div
                    key={`jira-${index}`}
                    className="jira-issue-item"
                    onClick={(ev) => handleJiraIssuesIndetails(ev, issue)}
                  >
                    <input
                      type="radio"
                      name="jira-issue"
                      checked={issue.radioSelected}
                      className="jira-item-radio"
                      value={issue.key}
                      onClick={(ev) => handleRadioButton(ev, issue)}
                    />
                    <div>
                      <div>
                        <span className="jira-item-key"> {issue.key} </span>
                        <span className="jira-item-name">
                          {' '}
                          {issue.summary}{' '}
                        </span>
                      </div>
                      <div className="jira-issue-project">
                        <span className="jira-issue-type">
                          {' '}
                          {issue.issuetype.name}{' '}
                        </span>
                        <span className="dot-mediator"> . </span>
                        <span> {issue.project.name} </span>
                        <span className="dot-mediator"> . </span>
                        <span>
                          {' '}
                          Updated {DateRelativeTime(issue.updated_at)}
                        </span>
                      </div>
                    </div>
                    <div className="issue-item-arrow">
                      <RightArrowSVG />
                    </div>
                  </div>
                ))}
            </div>
            {jiraIssues?.length > 0 && (
              <div className="pg-jira-btn-wrapper pt-10">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    dispatch(saveRetainValueBoolean(true));
                    selectJira(selectedIssue);
                  }}
                  disabled={submittingJiraSelection}
                >
                  {submittingJiraSelection ? <DualRingLoader /> : 'Select'}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default JiraList;
