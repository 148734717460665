import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import { MultiSelect } from '../../common/MultiSelect';

import {
  REVENUE_OPERATOR_OPTIONS,
  DATE_OPERATOR_OPTIONS,
  PRESALES_USER_OPTIONS,
} from '../../Constant/FilterOperators';
import 'react-datepicker/dist/react-datepicker.css';
import {
  _setDateFilter,
  getPreskaleStages,
  getSalesOwner,
  getSalesStages,
  getType,
  getUsers,
} from '../utils/pipelineUtils';
import mixpanel from 'mixpanel-browser';
import {
  MultiValueRemove,
  Option,
  colourStyles,
} from '../../Constant/MultiSelectStylesAndComponents';
import { getDateByOperator, toISODateForFilter } from '../../Utils/DateUtils';
import { isProductUser } from '../../Utils/common';

const PipelineFilter = (
  {
    pipelineFilter,
    setPipelineFilter,
    salesOwners,
    salesOwnersLoading,
    stages,
    preskaleStages,
    preskaleStagesLoading,
    isCRMStageView,
    pipelineView,
    stagesLoading,
    resetPage,
    handleReset,
    dealTypes,
    isSalesUser,
    userMapOptions,
    handleApply,
    ...props
  },
  ref
) => {
  const [tempFilter, setTempFilter] = useState(
    JSON.parse(JSON.stringify(pipelineFilter))
  );
  const [type, setType] = useState([]);
  const [users, setUsers] = useState([]);
  const [salesOwner, setSalesOwner] = useState([]);
  const [salesStages, setSalesStages] = useState([]);
  const [selectedPreskaleStages, setSelectedPreskaleStages] = useState([]);
  const salesOwnerDDList = [...salesOwners];

  useEffect(() => {
    getSalesOwner(salesOwners, setSalesOwner);
  }, [salesOwners]);

  useEffect(() => {
    onDropdownSelect('users', users);
  }, [users]);

  useEffect(() => {
    getType(dealTypes, setType);
  }, [dealTypes]);

  useEffect(() => {
    getSalesStages(stages, setSalesStages);
  }, [stages]);

  useEffect(() => {
    console.log('preskaleStages', preskaleStages);
    getPreskaleStages(preskaleStages, setSelectedPreskaleStages);
  }, [preskaleStages]);

  useEffect(() => {
    getUsers(userMapOptions, setUsers);
  }, []);

  //When the salesPerson dropdown value changes, update the tempfilter accordingly.
  useEffect(() => {
    onDropdownSelect('salesOwner', salesOwner);
  }, [salesOwner]);

  //When the type dropdown value changes, update the tempfilter accordingly.
  useEffect(() => {
    onDropdownSelect('type', type);
  }, [type]);

  useEffect(() => {
    onDropdownSelect('stage', salesStages);
  }, [salesStages]);

  useEffect(() => {
    onDropdownSelect('preskale-stage', selectedPreskaleStages);
  }, [selectedPreskaleStages]);

  const onDropdownSelect = (objectType, option) => {
    let filter = { ...tempFilter };
    switch (objectType) {
      case 'stage':
        if (typeof option[0] !== 'undefined') {
          filter.stage = option.map((option) => option.value);
        }
        break;
      case 'preskale-stage':
        if (typeof option[0] !== 'undefined') {
          filter.preskaleStage = option.map((option) => option?.label);
        }
        break;
      case 'revenue':
        filter.revenue.operator = option.value;
        if (filter.revenue.operator === 'ALL') {
          filter.revenue.value = '';
        }
        break;
      case 'date':
        filter.expectedCloseDate.operator = option.value;
        break;
      case 'salesOwner':
        if (typeof option[0] !== 'undefined') {
          filter.salesPerson = option.map((option) => option.value);
        }
        break;
      case 'users':
        if (typeof option[0] !== 'undefined') {
          filter.users = option.map((option) => option.value);
        }
        break;
      case 'presalesUser':
        filter.presalesUser = option.value;
        break;
      case 'type':
        if (typeof option[0] !== 'undefined') {
          filter.type = option.map((option) => option.value);
        }
        break;
    }
    setTempFilter(filter);
  };

  const handleRevenueChange = (value) => {
    const filter = { ...tempFilter };
    filter.revenue.value = value;
    setTempFilter(filter);
  };

  const handleFilterApply = () => {
    try {
      mixpanel.track('pipeline_filter_accessed', {
        event: 'Pipeline filtering',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }

    resetPage();
    let filter = { ...tempFilter };
    //when no value is selected , All is considered as default value
    if (salesOwner.length === 0) {
      filter.salesPerson = salesOwnerDDList?.map((person) => person.value);
      setSalesOwner([{ label: 'All', value: 'ALL' }]);
    }
    if (type.length === 0) {
      filter.type = dealTypes?.map((type) => type.value);
      setType([{ label: 'All', value: 'ALL' }]);
    }
    if (salesStages.length === 0 || salesStages.length === stages.length) {
      filter.stage = stages?.map((stage) => stage.value);
      setSalesStages([{ label: 'All', value: 'ALL' }]);
    }
    if (selectedPreskaleStages.length === 0) {
      filter.preskaleStage = preskaleStages?.map((stage) => stage.label);
      setSelectedPreskaleStages([{ label: 'All', value: 'ALL' }]);
    }
    if (users.length === 0) {
      filter.users = userMapOptions?.map((user) => user.value);
      setUsers([{ label: 'All', value: 'ALL' }]);
    }
    const operator = filter.expectedCloseDate.operator;

    //if expected close date is other than "CUSTOM"
    //generate "from" and "to" date based on the selected operator(prev quarter,this quarter etc)
    if (operator && operator !== 'CUSTOM') {
      filter = _setDateFilter(filter);
    }

    //if expected close date is "CUSTOM" and user did not select anything from datepicker,
    //populate filter with current quarter date
    if (operator === 'CUSTOM' && !filter.expectedCloseDate.fromDate) {
      filter.expectedCloseDate.fromDate =
        getDateByOperator('THIS_QUARTER').fromDate;
    }
    if (operator === 'CUSTOM' && !filter.expectedCloseDate.toDate) {
      filter.expectedCloseDate.toDate =
        getDateByOperator('THIS_QUARTER').toDate;
    }

    filter.page = 1;
    localStorage.setItem('pipeline_filter', JSON.stringify(filter));
    setPipelineFilter(filter);
    props.handlePipelineFilterModalToggle();
    handleApply(filter);
  };

  const handleCalenderOnChange = (objectType, date) => {
    const filter = { ...tempFilter };
    if (objectType === 'from') {
      filter.expectedCloseDate.fromDate = toISODateForFilter(date);
    } else {
      filter.expectedCloseDate.toDate = toISODateForFilter(date);
    }
    setTempFilter(filter);
  };

  const handleFilterReset = () => {
    const filter = handleReset();
    setType([{ label: 'All', value: 'ALL' }]);
    setTempFilter(filter);
    props.handlePipelineFilterModalToggle();
    const user = JSON.parse(localStorage.getItem('user'));
    const defaultUser = isProductUser()
      ? { value: 'all', label: 'All' }
      : { value: user?.userId, label: user?.name };
    handleApply(filter, defaultUser);
  };

  return (
    <div ref={ref} className="filter-popup-container pipeline-filter">
      {!isSalesUser && (
        <>
          <div className="filter-content-container">
            <div className="filter-header">Accounts</div>
            <Select
              name="presalesUser"
              type="dropdown"
              options={PRESALES_USER_OPTIONS}
              value={
                PRESALES_USER_OPTIONS.filter(
                  (option) => option.value === tempFilter?.presalesUser
                )[0]
              }
              dropdownPosition="auto"
              onChange={(option) => onDropdownSelect('presalesUser', option)}
            />
          </div>
          <div className="filter-content-container">
            <div className="filter-header">Sales Owner</div>
            <MultiSelect
              options={salesOwnerDDList}
              value={salesOwner}
              onChange={setSalesOwner}
              styles={colourStyles}
              components={{ Option, MultiValueRemove }}
            />
          </div>
        </>
      )}
      {isSalesUser && (
        <div className="filter-content-container">
          <div className="filter-header">Presales User</div>
          <MultiSelect
            options={userMapOptions}
            onChange={setUsers}
            value={users}
            components={{ Option, MultiValueRemove }}
            styles={colourStyles}
          />
        </div>
      )}
      {dealTypes && dealTypes.length > 1 && (
        <div className="filter-content-container">
          <div className="filter-header">Type</div>
          <MultiSelect
            options={dealTypes}
            value={type}
            onChange={setType}
            styles={colourStyles}
            components={{ Option, MultiValueRemove }}
          />
        </div>
      )}
      <div className="filter-content-container">
        <div className="filter-header">Revenue</div>
        <div className="flex-align-center">
          <div className="width-50">
            <Select
              name="revenue"
              type="dropdown"
              options={REVENUE_OPERATOR_OPTIONS}
              value={
                REVENUE_OPERATOR_OPTIONS.filter(
                  (option) => option.value === tempFilter?.revenue.operator
                )[0]
              }
              onChange={(option) => onDropdownSelect('revenue', option)}
            />
          </div>
          {
            <div className="ml-10 width-50">
              <CurrencyInput
                className="currency-input "
                decimalsLimit={2}
                prefix="$"
                placeholder="Enter a number"
                value={tempFilter?.revenue.value}
                disabled={tempFilter?.revenue.operator === 'ALL'}
                onValueChange={handleRevenueChange}
              />
            </div>
          }
        </div>
      </div>
      {(pipelineView[0]?.value === 'table' || isCRMStageView === 'crm') && (
        <div className="filter-content-container">
          <div className="filter-header">Preskale Stage</div>
          <MultiSelect
            options={preskaleStages}
            value={selectedPreskaleStages}
            onChange={setSelectedPreskaleStages}
            styles={colourStyles}
            components={{ Option, MultiValueRemove }}
          />
        </div>
      )}
      {(pipelineView[0]?.value === 'table' ||
        isCRMStageView === 'preskale') && (
        <div className="filter-content-container">
          <div className="filter-header">Sales Stage</div>
          <MultiSelect
            options={stages}
            value={salesStages}
            onChange={setSalesStages}
            styles={colourStyles}
            components={{ Option, MultiValueRemove }}
          />
        </div>
      )}
      <div className="filter-content-container">
        <div className="filter-header">Expected Close Date</div>
        <Select
          name="date"
          type="dropdown"
          options={DATE_OPERATOR_OPTIONS}
          value={
            DATE_OPERATOR_OPTIONS.filter(
              (option) =>
                option.value === tempFilter?.expectedCloseDate.operator
            )[0]
          }
          onChange={(option) => onDropdownSelect('date', option)}
        />
        {tempFilter?.expectedCloseDate.operator === 'CUSTOM' && (
          <div className="flex-align-center  mt-10">
            <div className="filter-date-container">
              <label>From</label>
              <DatePicker
                selected={
                  tempFilter?.expectedCloseDate?.fromDate
                    ? new Date(tempFilter?.expectedCloseDate?.fromDate)
                    : new Date(getDateByOperator('THIS_QUARTER')?.fromDate)
                }
                onChange={(date) => handleCalenderOnChange('from', date)}
                disabled={tempFilter?.expectedCloseDate.operator !== 'CUSTOM'}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="filter-date-container">
              <label>To</label>
              <DatePicker
                selected={
                  tempFilter?.expectedCloseDate?.toDate
                    ? new Date(tempFilter?.expectedCloseDate?.toDate)
                    : new Date(getDateByOperator('THIS_QUARTER')?.toDate)
                }
                onChange={(date) => handleCalenderOnChange('to', date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
        )}
      </div>
      <div className="filter-button-container">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.handlePipelineFilterModalToggle}
        >
          {' '}
          Cancel{' '}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleFilterApply}
        >
          {' '}
          Apply{' '}
        </button>
      </div>
      <div className="filter-button-container mt-10">
        <button type="button" className="reset-btn" onClick={handleFilterReset}>
          Reset to Default
        </button>
      </div>
    </div>
  );
};

export default React.forwardRef(PipelineFilter);
