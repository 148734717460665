import React, { useState, useEffect, useRef } from 'react';
import Chart from './Chart';
import Dropdown from 'react-dropdown';
import Button from '../common/Button';
import { ReactComponent as RefreshIcon } from '../Icons/Sync.svg';
import useAxios from '../hooks/useAxios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { fetchCrmStages } from '../_services/pipeline.service';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import { getQuarterlyDateRange } from '../Utils/DateUtils';
import { DATERANGE_OPTIONS } from '../Utils/Reports';
import addDays from 'date-fns/addDays';
import { getParentStages } from '../_services/stages.service';
import { toast } from 'react-toastify';
import { useLazyFetchCustomReportQuery } from './reportsSlice';

const CustomSections = ({ report, presalesOwners }) => {
  const axios = useAxiosPrivate();
  const { loading: stagesLoading, ...crmStages } = useAxios(axios);
  const { loading: parentStagesLoading, ...parentStages } = useAxios(axios);
  const [presalesOwnersDDList, setPresalesOwnerDDList] = useState([]);
  const [presalesStagesDDList, setPresalesStagesDDList] = useState([]);
  const [chart, setChart] = useState(null);
  const [chartError, setChartError] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [chartHeaders, setChartHeaders] = useState([]);
  const [chartHasData, setChartHasData] = useState(false);
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [filter, setFilter] = useState({});
  const downloadRef = useRef(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [showCustomRangeSelector, setShowCustomRangeSelector] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [generateReport] = useLazyFetchCustomReportQuery();

  const getDateRange = (quarter) => {
    setSelectedDateRange(`Q${quarter}`);
    const { from, to } = getQuarterlyDateRange(null, quarter);
    updateFilter({
      'Expected Close': { $gte: new Date(from), $lt: new Date(to) },
    });
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    start && start.setHours(0, 0, 0, 0);
    const to = end && addDays(new Date(end.setHours(0, 0, 0, 0)), 1);
    updateFilter({
      'Expected Close': {
        $gte: start && new Date(start),
        $lte: end && new Date(to),
      },
    });
  };

  const onSelectDateRange = (option) => {
    if (
      option.value === 'custom' ||
      (option.value !== 'custom' && showCustomRangeSelector)
    ) {
      setStartDate(null);
      setEndDate(null);
    }
    if (option.value !== 'custom' && showCustomRangeSelector) {
      setShowCustomRangeSelector(false);
    }
    if (option.label === 'Show all') {
      setSelectedDateRange(null);
      updateFilter({ 'Expected Close': null });
      return;
    }
    if (option.value === 'Q1') {
      getDateRange(1);
      return;
    }
    if (option.value === 'Q2') {
      getDateRange(2);
      return;
    }
    if (option.value === 'Q3') {
      getDateRange(3);
      return;
    }
    if (option.value === 'Q4') {
      getDateRange(4);
      return;
    }
    if (option.value === 'custom') {
      setSelectedDateRange(option.value);
      setShowCustomRangeSelector(true);
    }
  };

  const refreshChart = () => chart?.refresh();

  const getChartData = () => {
    if (!chart?.iframe) {
      return;
    }
    setLoadingChartData(true);
    chart
      ?.getData()
      .then((res) => {
        let headers = [];
        for (let key in res?.fields) {
          headers = [...headers, { label: res?.fields[key], key: key }];
        }
        setChartData(res?.documents || []);
        setChartHeaders(headers);
        setChartHasData(true);
      })
      .catch((err) => {
        console.log('Unable to get chart data', err);
      })
      .finally(() => setLoadingChartData(false));
  };

  const updateFilter = (values = {}) => {
    const filterCopy = { ...filter };
    for (let key in values) {
      if (!values[key]) {
        filterCopy[key] && delete filterCopy[key];
      } else {
        filterCopy[key] = values[key];
      }
    }
    setFilter(filterCopy);
  };

  useEffect(() => {
    if (!Array.isArray(presalesOwners)) {
      return;
    }
    const owners = presalesOwners.map((owner) => ({
      label: owner.name,
      value: owner.name,
    }));
    setPresalesOwnerDDList(owners);
  }, [presalesOwners]);

  const handlePresalesStageSuccess = (data) => {
    const stages =
      Array.isArray(data) &&
      data.map((stage) => ({
        id: stage.id,
        value: stage.name,
        label: stage.name,
      }));
    setPresalesStagesDDList(stages);
  };

  useEffect(() => {
    fetchCrmStages(crmStages);
    getParentStages(parentStages, handlePresalesStageSuccess);
  }, []);

  useEffect(() => {
    if (chartHasData) {
      downloadRef.current?.link?.click();
    }
    return () => {
      setChartHasData(false);
    };
  }, [chartHasData]);

  const generateCustomExtract = () => {
    generateReport()
      .unwrap()
      .then((res) => {
        toast.success(
          "Your report is being prepared, you'll receive the report over email shortly."
        );
      })
      .catch((error) => {
        toast.error('Error:  Please re-request report generation.');
      });
  };

  return (
    <div className="reporting-custom-sections">
      <div className="flex-justify-end gap-default">
        <Dropdown
          placeholder="Presales owners"
          className="dropdown"
          options={[
            {
              value: '',
              label: 'Show all',
              className: !filter['Presales owner'] ? 'is-selected' : '',
            },
            ...presalesOwnersDDList,
          ]}
          value={filter['Presales owner']}
          onChange={(option) => {
            if (option.label === 'Show all') {
              updateFilter({ 'Presales owner': null });
              return;
            }
            updateFilter({ 'Presales owner': option.label });
          }}
        />
        <Dropdown
          placeholder="Sales stages"
          className="dropdown"
          options={[
            {
              value: '',
              label: 'Show all',
              className: !filter['Sales Stage'] ? 'is-selected' : '',
            },
            ...crmStages.response,
          ]}
          value={filter['Sales Stage']}
          onChange={(option) => {
            if (option.label === 'Show all') {
              updateFilter({ 'Sales Stage': null });
              return;
            }
            updateFilter({ 'Sales Stage': option.label });
          }}
        />
        <Dropdown
          placeholder="Presales stage"
          className="dropdown"
          options={[
            {
              value: '',
              label: 'Show all',
              className: !filter['Presales Stage'] ? 'is-selected' : '',
            },
            ...presalesStagesDDList,
          ]}
          value={filter['Presales Stage']}
          onChange={(option) => {
            if (option.label === 'Show all') {
              updateFilter({ 'Presales Stage': null });
              return;
            }
            updateFilter({ 'Presales Stage': option.label });
          }}
        />
        <Dropdown
          placeholder="Date range"
          className="dropdown"
          options={DATERANGE_OPTIONS(selectedDateRange)}
          value={selectedDateRange}
          onChange={onSelectDateRange}
        />
        {showCustomRangeSelector && (
          <div className="datepicker">
            <DatePicker
              selected={startDate || new Date()}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              isClearable={true}
            />
          </div>
        )}
        {/* <Button
          type="ghost"
          className="btn-refresh"
          disabled={loadingChartData}
          loading={loadingChartData}
          onClick={getChartData}
        >
          Download CSV
        </Button> */}
        <CSVLink
          className="off-screen"
          filename={'custom_sections.csv'}
          ref={downloadRef}
          data={chartData}
          headers={chartHeaders}
        ></CSVLink>
        <Button
          type="ghost"
          className="btn-refresh"
          onClick={generateCustomExtract}
        >
          Download Report
        </Button>
        <Button
          icon={true}
          type="ghost"
          className="btn-refresh"
          onClick={refreshChart}
        >
          <RefreshIcon />
        </Button>
      </div>
      {report?.chart_url && !chartError ? (
        <Chart
          height="calc(100vh - 145px)" // Should be 125px. 145px is taking banner into consideration
          width="100%"
          filter={filter}
          chartID={report?.chart_id}
          chartURL={report?.chart_url}
          setChart={setChart}
          chartError={chartError}
          setChartError={setChartError}
        />
      ) : (
        <div className="alert">Failed to load chart: Invalid chart url</div>
      )}
    </div>
  );
};

export default CustomSections;
