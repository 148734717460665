import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  Row,
  Space,
  Tabs,
  Typography,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import BreadCrumb from '../BreadCrumb';
import GeneralSettings from './GeneralSettings';
import TeamMembers from './TeamMembers';
import PageLayout from '../../Layout/PageLayout';
import Loader from '../../common/Loader';
import {
  extendedTeamSlice,
  useFetchTeamByIdQuery,
  useUpdateGeneralSettingsMutation,
} from '../../Settings/Teams/teamSlice';
import TeamsContent from '../PipelinePreference/TeamsContent';
import { TeamInfoCard } from './TeamInfoCard';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { ReactComponent as BackSVG } from '../../Icons/ArrowLeft.svg';

const { Title, Text } = Typography;

const TeamDetailView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('selectedTeamId');
  const { data: teamInfo, isLoading: loadingTeamInfo } =
    useFetchTeamByIdQuery(teamId);
  const { teamName, teamDescription, teamMembers, teamManagers, id } =
    teamInfo?.data ?? [];
  const [isEditing, setIsEditing] = useState(false);
  const [teamNameHeading, setTeamNameHeading] = useState(null);

  const [updateGeneralSettings] = useUpdateGeneralSettingsMutation();

  const items = [
    {
      key: 'team_members',
      label: 'Team Members',
      children: <TeamMembers teamData={teamInfo?.data} />,
    },
    {
      key: 'general',
      label: 'General Settings',
      children: <GeneralSettings teamData={teamInfo?.data} />,
    },
  ];

  const totalMembers = teamMembers?.length || 0;
  const totalAdmins = teamManagers?.length || 0;
  const totalUsers =
    teamMembers?.filter((data) => data.roles?.[0]?.toLowerCase() === 'user')
      ?.length || 0;

  useEffect(() => {
    setTeamNameHeading(teamName);
  }, [teamName]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveTeamName = () => {
    let body = {
      teamId: id,
      updatedField: 'teamName',
      teamName: teamNameHeading,
    };
    updateGeneralSettings(body)
      .unwrap()
      .then((data) => {
        toast.success('Updated Successfully!');
      })
      .then(() => {
        dispatch(
          extendedTeamSlice.util.invalidateTags([
            { type: 'Team', id: 'fetchTeamById' },
            { type: 'Team', id: 'fetchActiveInactiveTeams' },
          ])
        );
      });
    setIsEditing(false);
  };
  const handleCancelTeamNameEdit = () => {
    setTeamNameHeading(teamName);
    setIsEditing(false);
  };

  const handleTeamNameChange = (e) => {
    setTeamNameHeading(e?.target?.value);
  };

  return (
    <PageLayout
      headerMainContent={
        <Flex
          align="center"
          className="fill-colors-primary admin-settings-subpage"
        >
          <Button
            className="flex-align-center"
            type="link"
            onClick={() => history.push('/settings?activePage=teams')}
            icon={<BackSVG title="" />}
          />
          <Title level={5} className="flex-align-center mb-0">
            {teamName}
          </Title>
        </Flex>
      }
    >
      {loadingTeamInfo && <Loader />}
      <div className="manage-teams">
        <div className="team-detail">
          <div className="team-main-content">
            <BreadCrumb
              className="breadcrumb"
              items={[
                {
                  title: (
                    <a
                      href="javascript:void(0)"
                      onClick={() => history.push('/settings')}
                    >
                      Settings
                    </a>
                  ),
                },
                {
                  title: (
                    <a
                      onClick={() => history.push('/settings?activePage=teams')}
                      href="javascript:void(0)"
                    >
                      Team Management
                    </a>
                  ),
                },
                { title: teamName },
              ]}
            />
            <Card className="team-detail-card">
              <Row>
                <Col span={12} className="p-1em">
                  <Flex gap={10} align="center">
                    {!isEditing ? (
                      <>
                        <Text className="text-lg text-weight-semi-bold text-primary">
                          {teamNameHeading}{' '}
                        </Text>
                        <Button onClick={handleEditClick} icon={<EditSVG />} />
                      </>
                    ) : (
                      <>
                        <Input
                          value={teamNameHeading}
                          onChange={handleTeamNameChange}
                          className="width-35"
                        />
                        <Button
                          icon={<CheckOutlined />}
                          onClick={handleSaveTeamName}
                        />
                        <Button
                          icon={<CloseOutlined />}
                          onClick={handleCancelTeamNameEdit}
                        />
                      </>
                    )}
                  </Flex>
                  <Flex align="center" className="mt-8">
                    {teamDescription ? (
                      <Text className="team-description">
                        {teamDescription}
                      </Text>
                    ) : (
                      <Text italic>No description available</Text>
                    )}
                  </Flex>
                </Col>
                <Col span={12} className="flex-justify-end">
                  <Space>
                    <TeamInfoCard title="Total Members" value={totalMembers} />
                    <TeamInfoCard title="Team Managers" value={totalAdmins} />
                    <TeamInfoCard title="Active Users" value={totalUsers} />
                  </Space>
                </Col>
              </Row>
            </Card>
            <Tabs tabBarGutter={60} className="settings-tab" items={items} />
          </div>
          <div className="help-content">
            <TeamsContent />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default TeamDetailView;
