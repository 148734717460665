import React, { useRef, useState, useMemo } from 'react';
import { Button, Drawer, Flex, Modal, Select, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useGetUsersQuery } from '../app/api/usersSlice';
import { AgGridReact } from 'ag-grid-react';
import { ReactComponent as CrossSVG } from '../Icons/remove.svg';
import { useBulkAssignPresalesOwnerMutation } from '../Pipeline/pipelineSlice';
import AgGridTooltip from '../common/AgGridTooltip';

const { Text, Paragraph } = Typography;

const AssignOpportunityModal = ({ openModal, handleCancel, rows }) => {
  const [savePresalesOwner, { isLoading }] =
    useBulkAssignPresalesOwnerMutation();
  const unassignedDealsRef = useRef(null);
  const [deals, setDeals] = useState(rows ?? []);
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const [selectedPresalesOwner, setSelectedPresalesOwner] = useState(
    loggedInUser?.userId
  );

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => {
      let userDropDownList = [];
      if (data && data.entities) {
        const userIds = data.ids;
        userIds &&
          userIds.forEach((userId) => {
            const dropDownElement = {
              label: data.entities[userId]?.name,
              value: userId,
            };
            userDropDownList.push(dropDownElement);
          });
      }
      const result = { userIdMap: data?.entities, userDropDownList };
      return {
        data: result,
      };
    },
  });

  const assignPresalesOwner = () => {
    const presalesOwnerBody = {
      dealIds: deals ? deals?.map((data) => data?.dealId) : [],
      userId: selectedPresalesOwner,
    };
    savePresalesOwner({
      body: presalesOwnerBody,
    })
      .unwrap()
      .then(() => {
        handleCancel();
        toast.success('Presales User Assigned Successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Presales user not assigned!');
      });
  };

  const handleRemoveDeal = (params) => {
    const idx = deals?.findIndex((deal) => deal?.id === params?.id);
    if (idx > -1) {
      const filteredDeals = [...deals];
      filteredDeals.splice(idx, 1);
      setDeals(filteredDeals);
    }
  };
  const columnDefs = [
    {
      field: 'name',
      minWidth: 400,
      headerName: 'Selected Deals',
      tooltipField: 'name',
    },
    {
      field: '',
      headerName: 'Remove Deal',
      tooltipField: '',
      cellRenderer: (params) => (
        <Flex align="center">
          <CrossSVG
            width={16}
            height={16}
            className="pointer fill-neutral-800 overflow-visible"
            onClick={() => handleRemoveDeal(params?.data)}
          />
        </Flex>
      ),
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 75,
      suppressMovable: true,
    }),
    []
  );

  return (
    <Drawer
      open={openModal}
      styles={{
        header: {
          paddingLeft: '20px',
        },
        wrapper: {
          width: '50%',
        },
      }}
      closable={false}
      title="Assignment Confirmation"
      className="preskale-modal--header"
      onClose={handleCancel}
      extra={
        <Flex gap={5} justify="flex-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={assignPresalesOwner}
            disabled={!deals?.length}
            loading={isLoading}
          >
            Confirm Assignment
          </Button>
        </Flex>
      }
    >
      <Flex className="width-full">
        <Flex vertical>
          <Text strong>Please confirm the Presales Owner </Text>
          <Paragraph type="secondary">
            Once Confirmed, the Presales owner field in the CRM will be updated.
          </Paragraph>
        </Flex>
        <Flex vertical justify="end" className="mr-10 ml-auto">
          <Text strong>Presales Owner</Text>
          <Select
            className="width-200"
            options={userMap?.userDropDownList}
            value={selectedPresalesOwner}
            onChange={(e) => setSelectedPresalesOwner(e)}
          />
        </Flex>
      </Flex>

      <div className="ag-theme-alpine add-users__table">
        <AgGridReact
          ref={unassignedDealsRef}
          domLayout={'autoHeight'}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableCellTextSelection={true}
          rowData={deals}
          singleClickEdit="true"
        />
      </div>
    </Drawer>
  );
};

export default AssignOpportunityModal;
