import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { ReactComponent as AddSVG } from '../Icons/Plus.svg';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxios from '../hooks/useAxios';
import { addParentStage, getParentStages } from '../_services/stages.service';

const AddStages = () => {
  const axios = useAxiosPrivate();
  const parentStages = useAxios(axios);
  const addStage = useAxios(axios);
  const [initialStageList, setIntialStageList] = useState([]);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [input, setInput] = useState('');

  useEffect(() => {
    getParentStages(parentStages, getAllStages);
  }, []);

  const getAllStages = (data) => {
    let initialList = [];
    Array.isArray(data) &&
      data.forEach((item) => {
        initialList.push({ name: item.name, id: item.id });
      });
    setIntialStageList(initialList);
  };

  const handleOnChange = (inputValue) => {
    setInput(inputValue);
  };

  const handleAddItem = () => {
    let stageList = [];
    stageList.push(input);
    addParentStage(addStage, stageList, () => {
      toast.success('Stages Added');
      getParentStages(parentStages, getAllStages);
      setShowAddPanel(false);
    });
  };

  const handleCancel = () => {
    setShowAddPanel(false);
  };

  const handleAddPopUp = () => {
    setShowAddPanel(true);
  };

  return (
    <div className="add-stages">
      <div className="popover-inline-wrapper">
        <div className="flex">
          <h2 className="width-95">Pipeline Stages</h2>
          <div className="config-company-add-btn">
            <a
              href="#"
              data-tip="Add"
              data-place="bottom"
              onClick={() => handleAddPopUp()}
              className="add-button"
            >
              <AddSVG />
            </a>
          </div>
        </div>
        <div className="display-list-wrapper">
          <ol>
            {initialStageList.length > 0 &&
              initialStageList.map((stage) => (
                <div className="criteria-list" key={stage?.id}>
                  <li className="display-list">
                    <div className="flex">
                      <div className="width-95">{stage?.name} </div>
                      {/* <a className="edit-button">
                                                <EditSVG />
                                            </a>
                                            <a className="edit-button">
                                                <DeleteSVG />
                                            </a> */}
                    </div>
                  </li>
                </div>
              ))}
          </ol>
        </div>

        <ReactModal
          isOpen={showAddPanel}
          className="Modal confirm-box"
          overlayClassName="Overlay confirm-box-overlay"
          ariaHideApp={false}
        >
          <div className="modal-header">
            <h4 className="modal-title">Add Pipeline Stage</h4>
          </div>
          <form onSubmit={handleAddItem}>
            <div className="modal-body form">
              <div className="form-field">
                <label> Stage Name </label>
                <div className="required-field">
                  <input
                    type="text"
                    onChange={(event) => handleOnChange(event.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn" onClick={handleCancel}>
                {' '}
                Cancel{' '}
              </button>
              <button type="submit" className="btn btn-primary">
                {' '}
                Add Item{' '}
              </button>
            </div>
          </form>
        </ReactModal>
      </div>
    </div>
  );
};

export default AddStages;
