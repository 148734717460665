import { URLS_BUSINESS_SERVICE, URLS_DE_SERVICE } from '../Constant/urls';
import { constructQueryString } from '../Utils/common';

export const getStarterProfile = (service) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.accounts.starterProfile}?is_starter_profile=true`,
  });

/* ==== LIGHTNING DASHBOARD ==== */

export const getTeamWorkload = (service, fromDate, toDate, users) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${
      URLS_BUSINESS_SERVICE.lightningDashboard.teamWorkload
    }?from_date=${fromDate}&to_date=${toDate}${constructQueryString(
      'userIds',
      users
    )}`,
  });

export const fetchActivityTrend = (service, fromDate, toDate, users) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${
      URLS_BUSINESS_SERVICE.lightningDashboard.activityTrend
    }?from_date=${fromDate}&to_date=${toDate}${constructQueryString(
      'userIds',
      users
    )}`,
  });

export const fetchOutcomeTrend = (service, fromDate, toDate, users) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${
      URLS_BUSINESS_SERVICE.lightningDashboard.outcomeTrends
    }?from_date=${fromDate}&to_date=${toDate}${constructQueryString(
      'userIds',
      users
    )}`,
  });

export const getStageTrends = (service, users) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${
      URLS_BUSINESS_SERVICE.lightningDashboard.stageTrends
    }?${constructQueryString('userIds', users)}`,
  });

export const fetchUpcomingMeetings = (service, fromDate, toDate, users) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${
      URLS_BUSINESS_SERVICE.lightningDashboard.upcomingMeetings
    }?from_date=${fromDate}&to_date=${toDate}${constructQueryString(
      'userIds',
      users
    )}`,
  });

/* ==== COLLABORATORS ==== */
export const getAllCollaborators = (service, dealId, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.collaborators.getAll}?deal_id=${dealId}`,
    onSuccess: handleSuccess,
  });

export const getRelatedContacts = (service, accountId, dealId) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.collaborators.getRelatedContacts}?account_id=${accountId}&deal_id=${dealId}`,
  });

export const getExternalContacts = (service, dealId) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.collaborators.getExternalContacts}?deal_id=${dealId}`,
  });

export const deleteExternalContact = (
  service,
  dealId,
  contactId,
  handleSuccess
) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.collaborators.deleteExternalContact}?deal_id=${dealId}&contact_id=${contactId}`,
    onSuccess: handleSuccess,
  });

export const deleteCollaborator = (
  service,
  dealId,
  collaboratorId,
  handleSuccess
) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.collaborators.delete}?deal_id=${dealId}&collabrator_id=${collaboratorId}`,
    onSuccess: handleSuccess,
  });

// export const addCollaborator = (service, data, handleSuccess) =>
//   service.makeAxiosCall({
//     method: 'post',
//     url: URLS_BUSINESS_SERVICE.collaborators.add,
//     data,
//     onSuccess: handleSuccess,
//   });

export const addExternalContact = (service, data, handleSuccess) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.collaborators.addExternalContact,
    data,
    onSuccess: handleSuccess,
  });

/* ==== CONTRIBUTIONS ==== */
export const addParentContribution = (
  service,
  data,
  handleSuccess,
  handleError
) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.contribution.addParentContribution,
    data,
    onSuccess: handleSuccess,
    onError: handleError,
  });

export const getParentContribution = (
  service,
  { params, handleSuccess, handleError }
) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.contribution.getParentContribution,
    requestConfig: { params },
    onSuccess: handleSuccess,
    onError: handleError,
  });

export const getOutcomeTags = (service, subordinateId) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.contribution.getOutcomeTags}${
      subordinateId ? `?subordinate_id=${subordinateId}` : ''
    }`,
  });

export const saveOutcomeTags = (service, data) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.contribution.saveOutcomeTags,
    data,
  });

/*=== TEAMS ===*/
export const getAllTeams = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.teams.getAll,
    onSuccess: handleSuccess,
  });

export const getAllUsers = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.teams.getUsersForAdminManager,
    onSuccess: handleSuccess,
  });

export const getTeam = (service, teamId, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.teams.getById}/${teamId}`,
    onSuccess: handleSuccess,
  });

export const createTeam = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.teams.create,
    data,
    onSuccess: handleSuccess,
  });
};

export const addTeamMember = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.teams.addUser,
    data,
    onSuccess: handleSuccess,
  });
};

/*=== EVALUATION PLAN ===*/
export const getClosedAccount = (service, dealId) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.evaluationPlan.getClosedAccount}?deal_id=${dealId}`,
  });

export const getTaskForStage = (service, dealId, stageId, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.evaluationPlan.getTaskForStage}?deal_id=${dealId}&stage_id=${stageId}`,
    onSuccess: handleSuccess,
  });

export const saveCompletedTasks = (service, data, handleSuccess) => {
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.evaluationPlan.saveCompletedTasks,
    data,
    onSuccess: handleSuccess,
  });
};

/* ==== COMPANY ==== */
export const getAllCompanies = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.company.getAll,
    onSuccess: handleSuccess,
  });

export const getCompanyDetails = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.company.getDetails,
    onSuccess: handleSuccess,
  });

export const getCRMDetails = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.company.crmDetails,
    onSuccess: handleSuccess,
  });

export const updateProductBoardIntegration = (service, data, handleSuccess) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.company.updateProductBoardIntegration,
    data,
    onSuccess: handleSuccess,
  });

export const createCompany = (service, data, handleSuccess) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.company.createCompany,
    data,
    onSuccess: handleSuccess,
  });

/* ==== CUSTOM SECTION ==== */
export const getAllSections = (service, handleSuccess) => {
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.customSection.gellAllSections,
    onSuccess: handleSuccess,
  });
};

export const getAllCustomFieldValues = (
  service,
  sectionId,
  dealId,
  handleSuccess
) => {
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.customSection.getCustomFieldValues}?sectionId=${sectionId}&dealId=${dealId}`,
    onSuccess: handleSuccess,
  });
};

export const saveCustomFieldsAPI = (service, data, handleSuccess) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.customSection.saveCustomFieldValues,
    data,
    onSuccess: handleSuccess,
  });

export const getSectionByType = (service, handleSuccess) => {
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.customSection.getSectionByType}?type=SALESFORCE`,
    onSuccess: handleSuccess,
  });
};

export const isRequestFormSubmitted = (
  service,
  accountId,
  dealId,
  handleSuccess
) => {
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.customSection.isRequestFormSubmitted}?accountId=${accountId}&dealId=${dealId}`,
    onSuccess: handleSuccess,
  });
};

/* ==== CALLBACK ==== */
export const createJiraIssue = (service, data, handleSuccess) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_DE_SERVICE.callback.createJiraIssue,
    data,
    onSuccess: handleSuccess,
  });

export const refreshJiraMetaData = (
  service,
  params,
  handleSuccess,
  handleError
) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_DE_SERVICE.callback.refreshJira,
    requestConfig: {
      params,
    },
    onSuccess: handleSuccess,
    onError: handleError,
  });
