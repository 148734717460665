const useDataPersistance = () => {
  const getStorageData = (key) => {
    if (!key) {
      console.warn('No key is passed to the hook');
      return;
    }
    const storedData = localStorage.getItem(key);
    const storedDataParsed = storedData ? JSON.parse(storedData) : undefined;
    return storedDataParsed;
  };
  const setStorageData = (key, data, replace) => {
    if (!key) {
      console.warn('No key is passed to the hook');
      return;
    }
    if (!data) {
      console.warn('No data to set in Storage');
      return;
    }
    if (replace) {
      localStorage.setItem(key, JSON.stringify(data));
      return;
    }
    const storedDataParsed = getStorageData(key);
    localStorage.setItem(key, JSON.stringify({ ...storedDataParsed, ...data }));
  };
  const removeStorageData = (key) => {
    if (!key) {
      console.warn('No key is passed to the hook');
      return;
    }
    localStorage.removeItem(key);
  };
  return { getStorageData, setStorageData, removeStorageData };
};

export default useDataPersistance;
