import { Tag } from 'antd';
import React from 'react';

const BG_COLOR = {
  Manager: 'manager-tag b-10',
  Admin: 'admin-tag b-10',
  User: 'user-tag b-10',
};

const RoleTag = ({ role }) => {
  let userRole = role ? role[0] : null;
  userRole = userRole
    ? userRole?.charAt(0).toUpperCase() + userRole.slice(1)
    : null;
  return <Tag rootClassName={BG_COLOR[userRole]}> {userRole}</Tag>;
};

export default RoleTag;
