import React, { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Button from '../../common/Button';
import {
  getOptedOutcomesLabelValuePair,
  getUsersLabelValuePair,
} from '../utils/adminopsUtils';
import { MultiSelect } from '../../common/MultiSelect';
import { Option } from '../../Constant/MultiSelectStylesAndComponents';
import {
  extendedAdminOpsSlice,
  useGetContributionForNotificationQuery,
  useUpdateNotificationCenterMutation,
} from '../adminOpsSlice';

const CommonPreference = forwardRef(
  ({ userPreferences, customStyles, outcomes, companyId }, ref) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState(null);
    const [selectedOutcomes, setSelectedOutcomes] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [updateNotificationCenter] = useUpdateNotificationCenterMutation();
    const { data: outcomesForNotification } =
      useGetContributionForNotificationQuery(
        { companyId },
        { skip: !companyId }
      );

    useEffect(() => {
      if (userPreferences) {
        const userLabelValuePair = getUsersLabelValuePair(userPreferences);
        setUsers(userLabelValuePair);
        const filteredUsersForWeeklyNotification = [];
        userLabelValuePair?.map((data) => {
          if (data?.includeInMeetingTable) {
            filteredUsersForWeeklyNotification.push(data);
          }
        });
        setSelectedUsers(filteredUsersForWeeklyNotification);
      }
    }, [userPreferences]);

    useEffect(() => {
      if (outcomesForNotification) {
        const optedOutcomesLabelValuePair = getOptedOutcomesLabelValuePair(
          outcomesForNotification
        );
        setSelectedOutcomes(optedOutcomesLabelValuePair);
      }
    }, [outcomesForNotification]);

    const onSave = () => {
      toast.info('Updating notification center');
      const includeUsersForMeetingTable = selectedUsers.map(
        (data) => data?.value
      );
      const outcomes = selectedOutcomes.map((data) => data.value);
      const body = {
        companyId: companyId,
        includeUsersForMeetingTable: includeUsersForMeetingTable,
        outcomes: outcomes,
        notification: 'common_preference',
      };
      updateNotificationCenter(body)
        .then((data) => {
          toast.success('Updated successfully!');
          dispatch(
            extendedAdminOpsSlice.util.invalidateTags([
              { type: 'AdminOps', id: 'getAllUserPreference' },
              { type: 'AdminOps', id: 'getContributionsAdminOps' },
            ])
          );
        })
        .catch((error) => {
          toast.error('Update failed!');
          console.log('error:', error);
        });
    };

    return (
      <div ref={ref} className="mb-20">
        <h1 className="text-weight-semi-bold text-lg font-primary">
          Common Preference
        </h1>
        <div className="mb-10">
          <div className="mt-10">Outcomes in Table</div>
          <div className="mt-5">
            <MultiSelect
              className="Dropdown-root"
              options={outcomes}
              value={selectedOutcomes || []}
              onChange={setSelectedOutcomes}
              components={{ Option }}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="mt-10 mb-10">Users included in meeting Table</div>
          <MultiSelect
            className="Dropdown-root"
            options={users}
            value={selectedUsers || []}
            onChange={setSelectedUsers}
            components={{ Option }}
            styles={customStyles}
          />
          <div className="mt-10">
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
export default CommonPreference;
