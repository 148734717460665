import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { businessService } from '../app/api/businessService';
import { logout } from '../Authorize/authSlice';
import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

const initialState = {
  updateStage: null,
  selectedCard: null,
  selectedDealDetail: null,

  // tracks selected preskale column in  Column Filter.
  selectedColumn: { value: 'NOTES', label: 'Notes' },

  // This flag is updated to true when any notes action is performed
  // and indicates that the pipeline table api should be refetched.
  // after refetch flag will be updated to false.
  refetchTableApi: false,
};
const pipelineAdapter = createEntityAdapter();
const initial = pipelineAdapter.getInitialState();

export const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState,
  reducers: {
    updateDealStage: (state, action) => {
      state.updateStage = action.payload;
    },
    saveSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    saveSelectedDealDetail: (state, action) => {
      state.selectedDealDetail = action.payload;
    },
    saveSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    fetchTableApi: (state, action) => {
      state.refetchTableApi = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state = { updateStage: null, selectedCard: null };
      return state;
    });
  },
});

export const extendedPipelineSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchTotalRevenue: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.getTotalRevenue,
        method: 'POST',
        body,
      }),
      transformResponse: (responseData) => {
        return pipelineAdapter.setAll(initial, responseData);
      },
    }),

    fetchParentStages: builder.query({
      query: () => URLS_BUSINESS_SERVICE.stage.getParentStages,
      providesTags: () => [{ type: 'Pipeline', id: 'presalesParentStages' }],
      transformResponse: (responseData) => {
        return pipelineAdapter.setAll(initial, responseData);
      },
    }),
    fetchParentStagesForCheckList: builder.query({
      query: () => URLS_BUSINESS_SERVICE.stage.getParentStagesForCheckList,
      transformResponse: (responseData) => {
        return pipelineAdapter.setAll(initial, responseData?.data);
      },
    }),
    fetchCRMParentStages: builder.query({
      query: () => URLS_BUSINESS_SERVICE.stage.crmStage,
      transformResponse: (responseData) => {
        return responseData?.data ? responseData?.data : [];
      },
    }),
    changeSortOrder: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.changeSortOrder,
        method: 'POST',
        body,
      }),
    }),
    addParentChange: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.addParentStage,
        method: 'POST',
        body,
      }),
    }),
    fetchPipelineWithStages: builder.query({
      query: () => URLS_BUSINESS_SERVICE.pipeline.pipelineWithStages,
      providesTags: () => [
        { type: 'Pipeline', id: 'presalesPipelineWithStages' },
      ],
    }),
    enableDisablePipeline: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.pipeline.enableDisablePipeline,
        method: 'POST',
        body,
      }),
    }),
    updateStageName: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.updateStageName,
        method: 'POST',
        body,
      }),
    }),
    getUnassignedDeals: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.unassignedDeals,
        method: 'POST',
        body,
      }),
    }),
    bulkAssignPresalesOwner: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.accounts.bulkAssignPresalesOwner,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const selectStageDetails = (state) => state.pipeline.updateStage;
export const selectSelectedCard = (state) => state.pipeline.selectedCard;
export const selectedDealDetail = (state) => state.pipeline.selectedDealDetail;
export const selectedColumn = (state) => state.pipeline.selectedColumn;
export const selectRefetchTableApi = (state) => state.pipeline.refetchTableApi;
export const {
  updateDealStage,
  saveSelectedCard,
  saveSelectedDealDetail,
  saveSelectedColumn,
  fetchTableApi,
} = pipelineSlice.actions;
export default pipelineSlice.reducer;

export const {
  useFetchTotalRevenueMutation,
  useFetchParentStagesQuery,
  useFetchCRMParentStagesQuery,
  useFetchParentStagesForCheckListQuery,
  useChangeSortOrderMutation,
  useAddParentChangeMutation,
  useFetchPipelineWithStagesQuery,
  useEnableDisablePipelineMutation,
  useUpdateStageNameMutation,
  useGetUnassignedDealsMutation,
  useBulkAssignPresalesOwnerMutation,
} = extendedPipelineSlice;
