import React, { useRef, useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select, Space } from 'antd';

export const CustomOptionSelect = ({
  options,
  placeholder,
  value,
  onChange,
  onAddItem,
  field,
  disabled,
  isLoading = false,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onNameChange = (event) => {
    setInputValue(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (inputValue.trim() !== '') {
      onAddItem(field, inputValue);
      setInputValue('');
      setIsDropdownOpen(false);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <Select
      placeholder={placeholder}
      value={selectedValue ?? null}
      disabled={disabled}
      open={isDropdownOpen}
      onChange={handleSelectChange}
      loading={isLoading}
      onDropdownVisibleChange={(visible) => setIsDropdownOpen(visible)}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider className="mt-8 mb-8" />
          <Space className="pb-4 pl-8 pr-8">
            <Input
              placeholder="Enter custom option."
              ref={inputRef}
              value={inputValue}
              onChange={onNameChange}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={addItem}
              disabled={!inputValue.trim()}
            >
              Add
            </Button>
          </Space>
        </>
      )}
      options={options}
    />
  );
};
