import React, { useEffect, useState } from 'react';
import DualRingLoader from '../common/DualRingLoader';
import { EMBEDS_BY_ROLE } from '../Constant/SignupConstants';
import styles from '../styles/pages/signup.module.scss';

const StorylaneContainer = ({ role, handleNext }) => {
  const [embedLink, setEmbedLink] = useState('');
  const [loading, setLoading] = useState(true);
  const hideLoader = () => setLoading(false);

  useEffect(() => {
    setEmbedLink(EMBEDS_BY_ROLE[role][0]);
  }, [role]);

  return (
    <>
      <section className={styles.storylane_container}>
        {role === 'Presales' && (
          <ul className={styles.storylane_list}>
            <li>
              <button
                onClick={() => setEmbedLink(EMBEDS_BY_ROLE[role][0])}
                className={`${
                  embedLink === EMBEDS_BY_ROLE[role][0]
                    ? `${styles.active} `
                    : ''
                }${styles.storylane_list_item}`}
              >
                Presales Management
              </button>
            </li>
            <li>
              <button
                onClick={() => setEmbedLink(EMBEDS_BY_ROLE[role][1])}
                className={`${
                  embedLink === EMBEDS_BY_ROLE[role][1]
                    ? `${styles.active} `
                    : ''
                }${styles.storylane_list_item}`}
              >
                Product gap management
              </button>
            </li>
          </ul>
        )}
        <section
          className={`${styles.storylane_embed} ${
            role === 'Presales' ? styles.multi_embed : ''
          }`}
        >
          {loading && (
            <div className={styles.loader_container}>
              <DualRingLoader />
            </div>
          )}
          <iframe
            className={`sl-demo ${styles.storylane_content}`}
            src={embedLink}
            name="sl-embed"
            allow="fullscreen;"
            onLoad={hideLoader}
          />
        </section>
      </section>
      <section className={styles.help_text}>
        <p className={styles.text__step3}>
          Next steps?
          <br />
          Start your trial with our onboarding expert.
        </p>
        <button
          onClick={handleNext}
          className={`${styles.next_button} ${styles.know_more_button}`}
          type="button"
        >
          Know more!
        </button>
      </section>
    </>
  );
};

export default StorylaneContainer;
