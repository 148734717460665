import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { saveOutcomeTags } from '../../_services/common.service';
import { ReactComponent as WarningSVG } from '../../Icons/NewImage/warning.svg';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAxios from '../../hooks/useAxios';
import SearchField from '../../Accounts/commons/SearchField';
import Dropdown from 'react-dropdown-select';
import OutcomeTagsPercentage from './OutcomeTagsPercentage';
import mixpanel from 'mixpanel-browser';
import SimpleTable from '../../common/Table/SimpleTable';
import Button from '../../common/Button';
import { ReactComponent as ToggleActiveSVG } from '../../Icons/NewImage/toggle-active-icon.svg';
import { ReactComponent as ToggleInActiveSVG } from '../../Icons/NewImage/toggle-inactive-icon.svg';
import PreskaleToolTip from '../../common/PreskaleToolTip';

const OutcomeTagsTable = ({
  outcomeData,
  userDropdown,
  userFilter,
  setUserFilter,
  teamMembers,
  isAdminOrManager,
  pipelineAccessibility,
  className = '',
  adminSettings,
  userDropdownClass,
  getAllTeamMemberOutcomeTags,
  teamMemberIds,
}) => {
  const axios = useAxiosPrivate();
  const saveTags = useAxios(axios);
  const [outcomes, setOutcomes] = useState(outcomeData);
  const [percentageAutomated, setPercentageAutomated] = useState();
  const [calculatePercentage, setCalculatePercentage] = useState(false);
  const [toggleTags, setToggleTags] = useState(false);

  useEffect(() => {
    setOutcomes(outcomeData);
    if (!userDropdown) {
      calculatePercentageAutomated(outcomeData);
    }
  }, [outcomeData]);

  useEffect(() => {
    if (calculatePercentage) {
      // * Calculating percentage as soon as outcomes is updated
      calculatePercentageAutomated(outcomes);
      setCalculatePercentage(false);
      return;
    }
  }, [calculatePercentage, outcomes]);

  const calculatePercentageAutomated = (outcomeData) => {
    let count = 0;
    if (Array.isArray(outcomeData)) {
      outcomeData.map((outcome) => {
        if (outcome.tags?.length > 0) {
          count = count + 1;
        }
      });
      setPercentageAutomated([
        { name: 'Tagged', value: count },
        { name: 'Untagged', value: outcomeData.length - count },
      ]);
    }
  };

  const Keys = {
    COMMA: 188,
    ENTER: 13,
  };

  //data passed here is list of all outcomes with its tags
  const isTagPresent = (tag, data) => {
    let isDuplicate = false;
    data?.forEach((contribution) => {
      if (
        contribution?.tags?.some(
          (eachTag) => eachTag.toLowerCase() === tag.text.toLowerCase()
        )
      ) {
        isDuplicate = true;
        return;
      }
    });
    return isDuplicate;
  };

  const updateOutcomes = (row, rowData) =>
    setOutcomes((prev) => {
      const copy = [...prev];
      if (!row || !copy[row.index]) {
        return prev;
      }
      copy[row.index] = rowData;
      return copy;
    });

  const handleAddition = (tag, allTags, row = {}, data) => {
    let toCalculatePercentage = false;
    let rowData = { ...row.original }; // From react-table

    if (data && isTagPresent(tag, data)) {
      return;
    }
    if (allTags === null || allTags?.length === 0) {
      toCalculatePercentage = true;
    }
    if (allTags) {
      rowData.tags = [...allTags, tag.text];
    } else {
      rowData.tags = [tag.text];
    }
    updateOutcomes(row, rowData);

    try {
      mixpanel.track('tag_added', {
        event: 'Calendar tag added',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }

    saveOutcomeTags(saveTags, rowData);
    if (toCalculatePercentage) {
      setCalculatePercentage(true);
    }
  };

  const handleDeletion = (deletedIndex, row) => {
    const allTags = row.original.tags;
    const outcomeTags = allTags.filter((tag, index) => index !== deletedIndex);
    const rowData = { ...row.original };
    rowData.tags = outcomeTags;
    updateOutcomes(row, rowData);

    saveOutcomeTags(saveTags, rowData);
    if (outcomeTags?.length === 0) {
      setCalculatePercentage(true);
    }
  };

  const handleSearch = (event) => {
    if (!event.target.value) {
      setOutcomes(outcomeData);
      return;
    }

    const searchResponse =
      outcomeData &&
      outcomeData.filter((outcome) =>
        outcome.title.toLowerCase().includes(event.target.value.toLowerCase())
      );

    setOutcomes(searchResponse);
  };

  const generateTags = (tags) => {
    let tagsPair = [];
    Array.isArray(tags) &&
      tags.forEach((item) => {
        tagsPair.push({ id: item, text: item });
      });
    return tagsPair;
  };

  const onUserSelect = (option) => {
    setUserFilter(option);
  };

  const onUserFilterSelect = (option) => {
    setUserFilter(option);
    if (option[0].value === 'ALL') {
      getAllTeamMemberOutcomeTags(teamMemberIds);
    } else {
      getAllTeamMemberOutcomeTags(option[0].value);
    }
  };

  const handleViewAllTags = () => {
    setToggleTags(!toggleTags);
    getAllTeamMemberOutcomeTags(!toggleTags ? teamMemberIds : '');
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Outcomes',
        accessor: 'title',
      },
      {
        Header: 'Meeting Title Tags',
        accessor: 'tags',
        Cell: (props) => (
          <div className="gap-1 flex-align-center">
            <div className="width-full">
              <ReactTags
                tags={props.value ? generateTags(props.value) : []}
                delimiters={[Keys.ENTER, Keys.COMMA]}
                handleAddition={(tag) =>
                  handleAddition(tag, props.value, props.row, props.data)
                }
                handleDelete={(deletedIndex) =>
                  handleDeletion(deletedIndex, props.row)
                }
                placeholder="Enter New tag"
                allowUnique={false}
                autofocus={false}
                readOnly={userDropdown || toggleTags}
              />
            </div>
            <div className="warning-icon">
              {!props.value?.length && !userDropdown && !toggleTags && (
                <WarningSVG />
              )}
            </div>
          </div>
        ),
      },
    ],
    [toggleTags]
  );

  return (
    <>
      {!userDropdown && (
        <OutcomeTagsPercentage
          isAdminOrManager={isAdminOrManager}
          percentageAutomated={percentageAutomated}
          totalCount={outcomeData?.length}
          adminSettings={adminSettings}
          pipelineAccessibility={pipelineAccessibility}
        />
      )}

      {userDropdown && (
        <div className="mt-15">
          Managers will have access to the complete list of tags used by
          everyone in the team.
        </div>
      )}
      <div
        className={`${
          userDropdown
            ? 'flex-center'
            : adminSettings
            ? 'flex-center justify-space-between-imp'
            : ''
        }`}
      >
        <div className={`${userDropdown || adminSettings ? 'width-50' : ''}`}>
          <SearchField
            placeholder="Search Outcomes"
            onChange={(event) => handleSearch(event)}
          />
        </div>

        {userDropdown && teamMembers && teamMembers.length > 0 && (
          <div className="user-filter-dropdown">
            {' '}
            <div className="mr-10">User </div>
            <Dropdown
              options={teamMembers}
              onChange={onUserSelect}
              values={userFilter}
              disabled={teamMembers.length === 1}
              className="user-dropdown"
            />
          </div>
        )}
        {toggleTags && teamMembers && teamMembers.length > 0 && (
          <div className={`${userDropdownClass}`}>
            <Dropdown
              options={teamMembers}
              values={userFilter}
              className="user-dropdown"
              onChange={onUserFilterSelect}
            />
          </div>
        )}
        {adminSettings && (
          <div className="flex-align-center">
            <Button
              onClick={handleViewAllTags}
              icon={true}
              type="ghost"
              className="btn-icon-only br-none bg-unset"
            >
              Manage My Title Tags{' '}
              {toggleTags ? <ToggleActiveSVG /> : <ToggleInActiveSVG />}
            </Button>
            <PreskaleToolTip
              toolTipMessage="Toggle between managing your title tags and viewing your team's tags."
              customClassName="max-width-50ch"
            >
              <div className="mt-4">
                {' '}
                <span className="text-weight-semi-bold">Note:</span> Team view
                allows you to view-only all your team members meeting title tag
                set-up.
              </div>
            </PreskaleToolTip>
          </div>
        )}
      </div>

      <div className={`opportunity-tags--table ${className}`}>
        <SimpleTable
          columns={columns}
          data={outcomes ?? [].filter((outcome) => !outcome.hideFromUI)}
        />
      </div>
    </>
  );
};

export default OutcomeTagsTable;
