import React, { useEffect, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { toISODateForFilter } from '../../Utils/DateUtils';
import { MultiSelect } from '../../common/MultiSelect';
import CreatableSelect from 'react-select/creatable';
import InputField from '../../common/Form/InputField';
import {
  MultiValueRemove,
  Option,
  colourStyles,
} from '../../Constant/MultiSelectStylesAndComponents';
import { noData } from '../../Utils/common';
import { Select } from 'antd';

const FieldRenderer = ({ field, userList, ...props }) => {
  const { values, setFieldValue } = useFormikContext();
  const [dateField] = useField(props);
  const [numField] = useField(props);
  const [multiSelectField, meta, helpers] = useField(props.name);
  const [userDefinedDropdownField, userMeta, userDefinedHelpers] = useField(
    props.name
  );
  const [userLookupField] = useField(props);
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  const [userDefinedDropdownValues, setUserDefinedDropdownValues] = useState(
    []
  );

  useEffect(() => {
    if (field.type === 'multiselect' && field?.values?.length) {
      const DDList = field.values.map((value) => {
        const valueObject = { label: value, key: value, value };
        return valueObject;
      });
      setMultiSelectValues([...DDList]);
    } else if (field.type === 'userdefineddropdown' && field?.values?.length) {
      const DDList = field.values.map((value) => {
        const valueObject = { label: value, key: value, value };
        return valueObject;
      });
      setUserDefinedDropdownValues([...DDList]);
    }
  }, []);

  switch (field.type) {
    case 'text':
      return (
        <Field
          type={field.type}
          maxLength={field.length === 0 ? -1 : field.length}
          {...props}
        />
      );

    case 'textarea':
      return <Field component={field.type} {...props} />;

    case 'select':
      let values = [...field.values];
      const defaultOption = ['Select an option'];
      values = defaultOption.concat(values);
      return field.values.length && Array.isArray(values) ? (
        <Field as={field.type} {...props} className="field-select">
          {values.map((fieldValue) => {
            return (
              <option
                key={fieldValue}
                value={fieldValue === 'Select an option' ? '' : fieldValue}
              >
                {fieldValue}
              </option>
            );
          })}
        </Field>
      ) : (
        <Field as={field.type} {...props}>
          <option value={'No data to display'}>{'No data to display'}</option>
        </Field>
      );

    case 'radio':
      return (
        <div className="radio-container">
          {field.values.map((value) => {
            return (
              <span className="flex mr-10" key={value}>
                <Field
                  type={field.type}
                  value={value}
                  {...props}
                  className="mr-5"
                />
                <span className="capitalize">{value}</span>
              </span>
            );
          })}
        </div>
      );

    case 'number':
      return (
        <InputField
          type={field.type}
          onChange={(val) =>
            setFieldValue(numField.name, val === '' ? null : val)
          }
          value={numField.value ?? ''}
          {...props}
        />
      );

    case 'date':
      return (
        <ReactDatePicker
          {...props}
          selected={(dateField.value && new Date(dateField.value)) || null}
          onChange={(val) => {
            const parsedDate = toISODateForFilter(val);
            setFieldValue(dateField.name, parsedDate);
          }}
        />
      );

    case 'multiselect':
      return (
        <MultiSelect
          isDisabled={props?.disabled}
          className="custom-form_input__field"
          options={multiSelectValues}
          value={multiSelectField.value || []}
          onChange={(selection) => {
            helpers.setValue([...selection]);
          }}
          styles={colourStyles}
          components={{ Option, MultiValueRemove }}
        />
      );

    case 'userdefineddropdown':
      return (
        <CreatableSelect
          components={{
            IndicatorSeparator: () => null,
          }}
          isClearable
          className="form_select"
          noOptionsMessage={() => noData()}
          value={userDefinedDropdownField.value || []}
          options={userDefinedDropdownValues ?? []}
          onChange={(selection) => {
            userDefinedHelpers.setValue([...selection]);
          }}
          isMulti
          menuPlacement="auto"
          closeMenuOnSelect={false}
        />
      );
    case 'userlookup':
      return (
        <Select
          options={userList?.userDropDownList}
          value={userLookupField.value || null}
          onChange={(selected) => setFieldValue(dateField.name, selected)}
          className="text-sm width-full"
        />
      );
  }
};
export default FieldRenderer;
