import React, { useState } from 'react';
import TaskItem from './TaskItem';
import ReopenTaskConfirm from '../../../common/tasks/ReopenTaskConfrim';
import DeleteTask from '../../../common/tasks/DeleteTask';
import DualRingLoader from '../../../common/DualRingLoader';
import styles from '../../../styles/features/presentationList.module.scss';
import AddTaskNoteDescriptionDrawer from '../../../common/AddDrawerContent/AddTaskNoteDescriptionDrawer';
import { useCompleteTaskMutation } from '../../../common/tasks/tasksSlice';

const TaskList = ({ data, isReadOnly, isLoading = false, dealDetails }) => {
  const [completeTask] = useCompleteTaskMutation();
  const [currentSelectedTask, setCurrentSelectedTask] = useState(null);
  const [reopenTask, setReopenTask] = useState(null);
  const [showUpdateTaskModal, setShowUpdateTaskModal] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showReopenTaskModal, setShowReopenTaskModal] = useState(false);
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState(false);

  const handleUpdateTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    setShowUpdateTaskModal(true);
  };
  const handleUpdateTaskCancel = () => {
    setCurrentSelectedTask(null);
    if (reopenTask) {
      setReopenTask(false);
    }
    setShowUpdateTaskModal(false);
  };

  const handleDeleteTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    setShowDeleteTaskModal(true);
  };
  const handleDeleteTaskCancel = () => {
    setCurrentSelectedTask(null);
    setShowDeleteTaskModal(false);
  };
  const handleReopenTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    setShowReopenTaskModal(true);
  };
  const handleReopenTaskCancel = () => {
    setReopenTask(null);
    setCurrentSelectedTask(null);
    setShowReopenTaskModal(false);
  };
  const handleReopenConfirm = () => {
    setReopenTask(true);
    setShowReopenTaskModal(false);
    setShowUpdateTaskModal(true);
  };
  const handleCompleteTaskOpen = (task) => {
    let requestData = {
      id: task.id,
      dealId: task.dealId,
    };
    completeTask({ body: requestData }).catch((error) => {
      console.warn('Error:', error);
      toast.error('Error: Unable to mark task as completed');
    });
  };

  const closeDawer = () => {
    if (reopenTask) {
      setReopenTask(false);
    }
    setShowUpdateTaskModal(false);
  };

  return (
    <>
      <ul className={styles.presentation_list}>
        {isLoading && (
          <div className="flex-center py-1">
            <DualRingLoader dark />
          </div>
        )}
        {Array.isArray(data) &&
          data.map((item) => (
            <li key={item?.id} className={styles.presentation_item__container}>
              <TaskItem
                item={item}
                handleComplete={handleCompleteTaskOpen}
                handleUpdate={handleUpdateTaskOpen}
                handleDelete={handleDeleteTaskOpen}
                handleReopen={handleReopenTaskOpen}
                isReadOnly={isReadOnly}
              />
            </li>
          ))}
        {!isLoading && (!data || data.length === 0) ? (
          <li className={`${styles.presentation_item__container}`}>
            <span className={styles.no_data}>No tasks added</span>
          </li>
        ) : null}
      </ul>
      {showUpdateTaskModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showUpdateTaskModal}
          closeDrawer={closeDawer}
          tab="Task"
          action="update"
          key="presentation-update-task"
          initialValues={currentSelectedTask}
          dealDetails={dealDetails}
          reopenTask={reopenTask}
        />
      )}
      {showDeleteTaskModal && (
        <DeleteTask
          task={currentSelectedTask}
          showModal={showDeleteTaskModal}
          handleClose={handleDeleteTaskCancel}
        />
      )}
      {showReopenTaskModal && (
        <ReopenTaskConfirm
          showModal={showReopenTaskModal}
          handleClose={handleReopenTaskCancel}
          handleConfirm={handleReopenConfirm}
        />
      )}
    </>
  );
};

export default TaskList;
