export const getFilteredCount = (
  productGapUseCases,
  searchQuery,
  selectedOpportunity
) => {
  if (!Array.isArray(productGapUseCases?.data)) return 0;

  const filteredData = selectedOpportunity
    ? productGapUseCases?.data.filter(
        (productGap) => productGap.dealId === selectedOpportunity
      )
    : productGapUseCases?.data;

  return searchQuery
    ? filteredData?.filter((productGap) =>
        productGap.useCase.toLowerCase().includes(searchQuery.toLowerCase())
      ).length
    : filteredData?.length;
};
