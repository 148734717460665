import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Button from '../../common/Button';
import { MultiSelect } from '../../common/MultiSelect';
import { CRM_FIELDS, PRESKALE_COLUMNS } from '../../Constant/FilterOperators';
import {
  colourStyles,
  MultiValueRemove,
  Option,
} from '../../Constant/MultiSelectStylesAndComponents';
import { useFetchAllSectionsDataQuery } from '../../features/CustomSections/CustomSectionSlice';
import useDataPersistance from '../../hooks/useDataPersistance';
import { getFilteredCustomFieldData } from '../../Utils/common';
import { saveSelectedColumn, selectedColumn } from '../pipelineSlice';

export const ColumnFilter = React.forwardRef(
  (
    {
      selectedCrmFields,
      setSelectedCrmFields,
      filteredCrmFields,
      setFilteredCrmFields,
      selectedCustomFields,
      setSelectedCustomFields,
      filteredCustomFields,
      setFilteredCustomFields,
      ...props
    },
    ref
  ) => {
    const [selectedColumnView, setSelectedColumnView] = useState(
      PRESKALE_COLUMNS[0]
    );
    const dispatch = useDispatch();
    const selectedView = useSelector(selectedColumn);
    const { data: allSections } = useFetchAllSectionsDataQuery();
    const [fields, setFields] = useState([]);
    const { setStorageData } = useDataPersistance();

    useEffect(() => {
      const { filteredFields } = getFilteredCustomFieldData(allSections);
      const objArr = filteredFields.map((item) => {
        return {
          label: item,
          value: item.toUpperCase(),
        };
      });
      setFields(objArr);
    }, [allSections]);

    const handleFilterApply = () => {
      const crmColumns = [...selectedCrmFields];
      const customFields = [...selectedCustomFields];
      setFilteredCrmFields(crmColumns);
      setFilteredCustomFields(customFields);
      dispatch(saveSelectedColumn(selectedColumnView));
      setStorageData('pipeline_crm_columns', crmColumns, true);
      setStorageData('pipeline_custom_fields', customFields, true);
      setStorageData('current_active_column', selectedColumnView, true);
      props.handleColumnFilterModalToggle();
    };

    useEffect(() => {
      setSelectedCrmFields([...filteredCrmFields]);
    }, [filteredCrmFields]);

    useEffect(() => {
      setSelectedCustomFields([...filteredCustomFields]);
    }, [filteredCustomFields]);

    const onChange = (e) => {
      setSelectedColumnView(e);
    };

    useEffect(() => {
      setSelectedColumnView(selectedView);
    }, [selectedView]);

    return (
      <div ref={ref} className="filter-popup-container">
        <header className="table-settings-header">Table Settings</header>
        <div className="filter-content-container">
          <label className="filter-header">CRM Columns</label>
          <div className="mt-5">
            <MultiSelect
              options={CRM_FIELDS}
              value={selectedCrmFields}
              onChange={setSelectedCrmFields}
              styles={colourStyles}
              components={{ Option, MultiValueRemove }}
            />
          </div>
        </div>
        <div className="filter-content-container">
          <label className="filter-header">Preskale Columns</label>
          <div className="mt-5">
            <Select
              value={selectedColumnView}
              onChange={onChange}
              options={PRESKALE_COLUMNS}
            />
          </div>
        </div>
        {selectedColumnView.value !== 'NOTES' && (
          <div className="filter-content-container">
            <label className="filter-header">Custom Fields</label>
            <div className="mt-5">
              <MultiSelect
                options={fields}
                value={selectedCustomFields}
                onChange={setSelectedCustomFields}
                styles={colourStyles}
                components={{ Option, MultiValueRemove }}
              />
            </div>
          </div>
        )}
        <div className="filter-button-container mt-20">
          <Button
            type="secondary"
            onClick={props.handleColumnFilterModalToggle}
          >
            Cancel
          </Button>
          <Button className="ml-5" type="primary" onClick={handleFilterApply}>
            Update
          </Button>
        </div>
      </div>
    );
  }
);
