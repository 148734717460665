import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getConnectUrl, getSVG, showButton } from './utils';
import {
  useCheckIsJiraIntegratedQuery,
  useCheckIsZohoBugsIntegratedQuery,
} from '../../app/api/usersSlice';
import { useUpdateProductBoardIntegrationMutation } from './integrationsSlice';
import {
  useLazyPullZohoBugsQuery,
  useLazyRefreshJiraQuery,
} from '../../app/api/dataEngineeringService';
import { ReactComponent as WarningSVG } from '../../Icons/InfoIcon.svg';

const IntegrationCard = ({
  integration,
  companyInfo,
  adminConnections,
  handleDisconnect,
  isPicklist,
  enableJiraNote,
}) => {
  const profileData = JSON.parse(localStorage.getItem('user'));
  const userId = profileData?.userId;
  const companyId = profileData?.companyId;
  const { data: isJiraConnected } = useCheckIsJiraIntegratedQuery();
  const { data: isZohoBugsConnected } = useCheckIsZohoBugsIntegratedQuery();
  const [updateProductBoardIntegration, data: updatePBEnable] =
    useUpdateProductBoardIntegrationMutation();
  const [refreshJira, { isLoading: jiraRefreshLoading }] =
    useLazyRefreshJiraQuery();

  const [syncZohoBugs, { isLoading: syncingZohoBugs }] =
    useLazyPullZohoBugsQuery();

  const [activeIntegration, setActiveIntegration] = useState({});

  useEffect(() => {
    const active_integrations = { ...activeIntegration };
    if (companyInfo) {
      if (integration.type === 'productboard') {
        active_integrations[integration.type] = companyInfo.isproductboard;
      }
    }
  }, [companyInfo]);

  useEffect(() => {
    const active_integrations = { ...activeIntegration };
    if (integration.type === 'jira') {
      active_integrations[integration.type] = isJiraConnected;
    }
    setActiveIntegration(active_integrations);
  }, [isJiraConnected]);

  useEffect(() => {
    const active_integrations = { ...activeIntegration };
    if (integration.type === 'zohobugs') {
      active_integrations[integration.type] = isZohoBugsConnected;
    }
    setActiveIntegration(active_integrations);
  }, [isZohoBugsConnected]);

  const handleJiraSync = () => {
    refreshJira({ userId, companyId })
      .unwrap()
      .then((result) => {
        toast.success('Jira Sync Successful');
      })
      .catch(() => toast.error('Error syncing Jira'));
  };

  const handleZohoBugsSync = () => {
    syncZohoBugs({ state: `${userId}:${companyId}` })
      .unwrap()
      .then((result) => {
        toast.success('Zoho Sync Successful');
      })
      .catch(() => toast.error('Error syncing Zoho Bugs'));
  };

  const handlePBEnable = (enabled) => {
    const body = {
      company_id: companyId,
      enabled: enabled,
    };
    updateProductBoardIntegration(body)
      .unwrap()
      .then((res) => {
        const integrations = { ...activeIntegration };
        //if response is one it means that its enabled or if 0 its disabled
        integrations[integration.type] = res === 1;
        setActiveIntegration(integrations);
        enabled
          ? toast.success('Product Board Integration Enabled Successfully')
          : toast.success('Product Board Integration Disabled Successfully');
      })
      .catch(() => toast.error('Error updating Product Board'));
  };

  const onDisconnect = (connectionType) => () => {
    if (connectionType === 'crm') {
      handleDisconnect();
      return;
    }
    handleDisconnect(connectionType)
      .unwrap()
      .then((res) => {
        if (
          res?.msg ===
          'Exception occured while Disconnecting..but token removed from DB'
        ) {
          toast.warn(res?.msg);
          return;
        }
        toast.success(res?.msg);
      })
      .catch((err) => {
        console.warn('error', err);
        toast.error('Something went wrong!');
      });
  };

  const getButton = (integration) => {
    const name = integration.name;
    if (name === 'Jira') {
      return (
        <>
          {showButton(
            isJiraConnected && adminConnections?.token?.acessToken,
            {
              url: getConnectUrl('Jira'),
              content: 'Connect',
              disable: !profileData.roles.includes('admin'),
            },
            {
              handleClick: () =>
                handleDisconnect({ type: adminConnections?.type }),
              content: 'Disconnect',
              disable: !profileData.roles.includes('admin'),
            }
          )}
          {isJiraConnected && adminConnections?.token?.acessToken && (
            <button
              className={`btn btn-secondary sync ${
                jiraRefreshLoading ? 'sync-loading' : ''
              }`}
              onClick={handleJiraSync}
              disabled={jiraRefreshLoading}
            >
              Sync
            </button>
          )}
        </>
      );
    }
    if (name === 'Productboard') {
      return showButton(
        activeIntegration[integration.type],
        { handleClick: () => handlePBEnable(true), content: 'Enable' },
        { handleClick: () => handlePBEnable(false), content: 'Disable' }
      );
    }

    if (name === 'Zoho Bugs') {
      return (
        <>
          {showButton(
            isZohoBugsConnected && activeIntegration[integration.type],
            {
              url: getConnectUrl('ZohoBugs'),
              content: 'Connect',
              disable:
                profileData.roles.includes('sales') ||
                profileData.roles.includes('product'),
            },
            {
              handleClick: () =>
                handleDisconnect({ type: adminConnections?.type }),
              content: 'Disconnect',
              disable:
                profileData.roles.includes('sales') ||
                profileData.roles.includes('product'),
            }
          )}
          {isZohoBugsConnected && (
            <button
              className={`btn btn-secondary sync ${
                syncingZohoBugs ? 'sync-loading' : ''
              }`}
              onClick={handleZohoBugsSync}
              disabled={syncingZohoBugs}
            >
              Sync
            </button>
          )}
        </>
      );
    }
    if (
      isPicklist &&
      !profileData.roles.includes('admin') &&
      adminConnections?.token?.acessToken
    ) {
      return <button className="btn btn-primary disable"> Connected </button>;
    }
    return showButton(
      adminConnections?.token?.acessToken,
      {
        url: getConnectUrl(name),
        content: 'Connect',
        disable: !profileData.roles.includes('admin'),
      },
      {
        handleClick: onDisconnect('crm'),
        content: 'Disconnect',
        disable: !profileData.roles.includes('admin'),
      }
    );
  };

  return (
    <>
      {!integration ? (
        <div className="flex-align-center"> No Integrations Available</div>
      ) : (
        <>
          <div className="integrations-card__content">
            <div className="card_heading">
              <h4> Integrations Status : </h4>
              <div className="card-container">
                {activeIntegration[integration.type] ||
                adminConnections?.token?.acessToken ? (
                  <span className="active">Active</span>
                ) : (
                  <span className="in-active">Inactive</span>
                )}
              </div>
            </div>
            <div className="integrations-card">
              <div className="int-card-img">{getSVG(integration.name)}</div>
              <div className="int-card__content">
                <div className="card-title"> {integration.name} </div>
                <div className="card-sub__title">{integration.description}</div>
              </div>
              <div className="button-content">{getButton(integration)}</div>
            </div>
          </div>
          {enableJiraNote ? (
            <div className="icon-container">
              <div className="icon">
                <WarningSVG /> <span className="icon-note">Note</span>
              </div>
              <div className="warning-content">
                <li>
                  Please enter the API Key and JIRA Admin Email information.
                </li>
                <li>
                  Once the Fields are verified, Select the{' '}
                  <span className="text-highlight">Confirm Details.</span>
                </li>
              </div>
              <div className="warning-text">
                Need assistance setting this up? Please reach out to us at{' '}
                <span className="email-highlight">support@preskale.com.</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default IntegrationCard;
