import React from 'react';

const DualRingLoader = ({ dark }) => {
  return (
    <div
      className={`lds-dual-ring lds-dual-ring--animation ${
        dark ? 'dark' : 'light'
      }`}
    ></div>
  );
};

export default DualRingLoader;
