import React from 'react';

export const ActivateButton = ({ url, handleClick, content, disabled }) => {
  const onClick = (e) => handleClick(e);
  return url && !disabled ? (
    <a
      role={'button'}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={typeof content !== 'string' ? '' : 'btn btn-primary pointer'}
    >
      {content}
    </a>
  ) : (
    <button
      className={`btn btn-primary ${disabled ? 'disable' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};
