export const pgSelectStyles = {
  option: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
    backgroundColor: '#447bf2',
    color: '#fff',
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.74rem',
    backgroundColor: '#447bf2',
    color: '#fff',
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: 0,
    padding: 0,
  }),
};

export const filterSelectStyles = {
  option: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: '0.75rem',
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
  }),
};
