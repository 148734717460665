import React from 'react';
import { colourStyles } from '../../Constant/MultiSelectStylesAndComponents';
import {
  Option,
  MultiValueRemove,
} from '../../Constant/MultiSelectStylesAndComponents';
import { MultiSelect } from '../MultiSelect';

const MultiSelectField = ({ value, values, onChange, disabled = false }) => {
  const handleChange = (options) => {
    if (typeof onChange === 'function') {
      onChange(options);
    }
  };
  return (
    <MultiSelect
      isDisabled={disabled}
      className="custom-form_input__field"
      options={values}
      value={
        Array.isArray(value) && value.length
          ? value[0].label
            ? value
            : value.map((val) => ({ value: val, label: val }))
          : []
      }
      onChange={handleChange}
      styles={colourStyles}
      components={{ Option }}
    />
  );
};

export default MultiSelectField;
