import React from 'react';
import { useHistory } from 'react-router-dom';
import { isAdminUser } from '../Utils/common';
import useDataPersistance from '../hooks/useDataPersistance';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';

const SideNavigation = ({
  navigationContent,
  onSidebarItemClick,
  activeSection,
  setSelectedSection,
  setActivePage,
  activePage,
}) => {
  const history = useHistory();
  const isUserAdmin = isAdminUser();
  const { getStorageData } = useDataPersistance();
  const currentUser = getStorageData('user');
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !currentUser,
  });

  const handleClick = (section) => {
    history.push('/user/profile', {
      prevActvePage: activePage,
      section: section?.id,
    });
    setSelectedSection(section.id);
    setActivePage('');
    onSidebarItemClick(section?.id);
    const sectionElement = document.getElementById(section.id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav-container">
      {navigationContent.map((item) =>
        !item.restrict || isUserAdmin ? (
          <div key={item.title} className="nav-item">
            <div className="nav-title">{item.title}</div>
            {item.subTitles &&
              item.subTitles.map((subTitle) =>
                subTitle?.restrict_crm !== configFeatures?.data?.crm ? (
                  <div
                    key={subTitle.label}
                    onClick={() => handleClick(subTitle)}
                    className={`nav-subtitle ${
                      activeSection === subTitle.id ? 'active' : ''
                    }`}
                  >
                    {subTitle.label}
                  </div>
                ) : null
              )}
          </div>
        ) : null
      )}
    </div>
  );
};

export default SideNavigation;
