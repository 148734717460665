import React, { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Button from '../../common/Button';
import { getUsersLabelValuePair } from '../utils/adminopsUtils';
import { MultiSelect } from '../../common/MultiSelect';
import { Option } from '../../Constant/MultiSelectStylesAndComponents';
import {
  extendedAdminOpsSlice,
  useUpdateNotificationCenterMutation,
} from '../adminOpsSlice';

const WeeklyMeetingNotification = forwardRef(
  ({ userPreferences, customStyles, companyId }, ref) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [updateNotificationCenter] = useUpdateNotificationCenterMutation();

    useEffect(() => {
      if (userPreferences) {
        const userLabelValuePair = getUsersLabelValuePair(userPreferences);
        setUsers(userLabelValuePair);
        const filteredUsersForWeeklyNotification = [];
        userLabelValuePair?.map((data) => {
          if (data?.sendMeetingNotification) {
            filteredUsersForWeeklyNotification.push(data);
          }
        });
        setSelectedUsers(filteredUsersForWeeklyNotification);
      }
    }, [userPreferences]);

    const onSave = () => {
      toast.info('Updating notification center');
      const usersForManagerDigest = selectedUsers.map((data) => data?.value);
      const body = {
        companyId: companyId,
        usersForManagerDigest: usersForManagerDigest,
        notification: 'weekly_meeting',
      };
      updateNotificationCenter(body)
        .then((data) => {
          toast.success('Updated successfully!');
          dispatch(
            extendedAdminOpsSlice.util.invalidateTags([
              { type: 'AdminOps', id: 'getAllUserPreference' },
            ])
          );
        })
        .catch((error) => {
          toast.error('Update failed!');
          console.log('error:', error);
        });
    };

    return (
      <div ref={ref} className="mb-20">
        <h1 className="text-weight-semi-bold text-lg font-primary">
          Weekly Meeting Notification
        </h1>
        <div className="mt-10">
          <div className="mt-10 mb-10">
            User list who will get the email (Admin, Manager)
          </div>
          <MultiSelect
            className="Dropdown-root"
            options={users}
            value={selectedUsers || []}
            onChange={setSelectedUsers}
            components={{ Option }}
            styles={customStyles}
          />
          <div className="mt-10">
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default WeeklyMeetingNotification;
