import React, { useEffect } from 'react';
import {
  useRouteMatch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import ManagerDashboardManager from './DashboardManager';
import useDataPersistance from '../hooks/useDataPersistance';

const Index = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { getStorageData } = useDataPersistance();
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  useEffect(() => {
    const defaultTab = getStorageData('dashboardManager');
    // Replacing default pathname
    if (
      configFeatures?.data?.navPages?.manager_dashboard &&
      location.pathname === '/manager'
    ) {
      if (
        !configFeatures?.data?.navPages?.request_assistance_manager &&
        !configFeatures?.data?.featureList?.is_lightning_dashboard_enabled
      ) {
        // * The navigation to the entire feature will be disabled and this condition will never run as ram and allrequests are coupled
        history.replace('/manager/allrequests');
      } else if (
        !configFeatures?.data?.navPages?.request_assistance_manager &&
        defaultTab === 'ram'
      ) {
        // * Edge case 1: This can happen if the config was updated while the user is logged in(access to old defaultTab data)
        // * The default is hardcoded, this can be changed as per requirement
        history.replace('/manager/dashboard');
      } else if (
        !configFeatures?.data?.featureList?.is_lightning_dashboard_enabled &&
        defaultTab === 'dashboard'
      ) {
        // * Edge case 2: This can happen if the config was updated while the user is logged in(access to old defaultTab data)
        // * The default is hardcoded, this can be changed as per requirement
        history.replace('/manager/allrequests');
      } else {
        history.replace(defaultTab ? `/manager/${defaultTab}` : '/manager/ram');
      }
    }
  }, [location, configFeatures]);

  return (
    <Route path={`${path}/:tab`}>
      <ManagerDashboardManager
        isRAM={configFeatures?.data?.navPages?.request_assistance_manager}
        isLightningDashboard={
          configFeatures?.data?.featureList?.is_lightning_dashboard_enabled
        }
      />
    </Route>
  );
};

export default Index;
