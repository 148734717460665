import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import {
  extendedAccountsSlice,
  useUpdateAccountStageMutation,
} from '../../Accounts/accountsSlice';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import {
  selectSelectedCard,
  useFetchParentStagesQuery,
  updateDealStage,
  saveSelectedCard,
} from '../../Pipeline/pipelineSlice';
import CustomForm from '../CustomSections/CustomForm';
import {
  useCheckCustomSectionEnabledQuery,
  useFetchAllSectionsDataQuery,
} from '../CustomSections/CustomSectionSlice';
import { toast } from 'react-toastify';
import DualRingLoader from '../../common/DualRingLoader';
import { isAdminOrManager } from '../../Utils/common';
import { EditDropdownIndicator } from '../../Constant/MultiSelectStylesAndComponents';
import { useGetUsersQuery } from '../../app/api/usersSlice';
import PresalesChangeConfirmation from '../../Accounts/PresalesChangeConfirmation';
import Button from '../../common/Button';
import Alert from '../../common/Alert';
import useDataPersistance from '../../hooks/useDataPersistance';
import CRMStageDropdown from '../../Accounts/commons/CRMStageDropdown';
import { useFetchPipelineAccessibilityQuery } from '../../app/api/teamsSlice';

const customStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: '4px',
      minWidth: '124px',
      width: '200px',
      maxWidth: '250px',
      minHeight: '30px',
      height: '30px',
    };
  },
  dropdownIndicator: (base) => {
    return { ...base, padding: '2px' };
  },
  clearIndicator: (base) => {
    return { ...base, padding: '2px' };
  },
  valueContainer: (base) => {
    return {
      ...base,
      fontWeight: '400',
      padding: '0px',
      fontSize: '12px',
      flexWrap: 'nowrap',
    };
  },
  menu: (base) => {
    return { ...base, width: '200px' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};

const editSEUserStyles = {
  ...customStyles,
  control: (base) => {
    return {
      ...base,
      border: 'none',
      padding: '0 0.3rem',
      width: 'auto',
      maxWidth: '200px',
      minHeight: 'auto',
      height: 'auto',
    };
  },
  menu: (base) => {
    return { ...base, width: '160px' };
  },
  valueContainer: (base) => {
    return {
      ...base,
      fontWeight: '600',
      padding: '0 10px 0 0',
      fontSize: '14px',
      flexWrap: 'nowrap',
    };
  },
};

const OverviewSection = ({
  dealDetails,
  fetchDealDetails,
  presalesUserId,
  loading,
  dealDetailsError,
  readOnly = false,
  userNameMapError,
  selectedUser,
  // reloadLaneOnSEUserChange,
}) => {
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const industry = dealDetails?.additionalDetails?.industry?.replaceAll(
    '_',
    ' '
  );
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState('crm');
  const [activeListItem, setActiveListItem] = useState('deal-details');
  const [presalesUser, setPresalesUser] = useState([
    {
      label: dealDetails?.presalesUser?.displayName,
      value: dealDetails?.presalesUser?.id,
    },
  ]);
  const { getStorageData } = useDataPersistance();
  // const crmStageView = getStorageData('crm_stage_view');

  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();

  useEffect(() => {
    setPresalesUser([
      {
        label: dealDetails?.presalesUser?.displayName,
        value: dealDetails?.presalesUser?.id,
      },
    ]);
  }, [dealDetails?.presalesUser?.displayName]);

  const [showSEUserConfirmation, setShowSEUserConfirmation] = useState(false);
  const { data: sections, isLoading: sectionsLoading } =
    useFetchAllSectionsDataQuery();
  const { data: sectionEnabled } = useCheckCustomSectionEnabledQuery();
  const { data: stages, isLoading: stagesLoading } = useFetchParentStagesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const options = useMemo(() => {
          const options = data?.ids?.map((stage) => ({
            label: data?.entities[stage]?.name,
            value: stage,
          }));
          return { ...data, options };
        }, [data]);

        return { data: options };
      },
    }
  );
  const { data: userMapOptions = [] } = useGetUsersQuery(undefined, {
    skip: userNameMapError,
    selectFromResult: ({ data }) => {
      const usersData = useMemo(() => {
        const users = data?.entities && Object.values(data?.entities);
        let optionValues = [];
        optionValues = users?.map((user) => {
          return {
            value: user?.userName,
            label: user?.name,
            id: user?.id,
          };
        });
        return optionValues;
      }, [data?.entities]);
      return {
        data: usersData ? usersData : [],
      };
    },
  });
  const [updateAccountStage, { isLoading: updatingStage }] =
    useUpdateAccountStageMutation();
  const [selectedStage, setSelectedStage] = useState([]);
  const selectedCard = useSelector(selectSelectedCard);

  const updateSortingInPipeline = () => {
    if (selectedCard?.cardId) {
      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
        })
      );
    }
  };

  useEffect(() => {
    const presalesStage = dealDetails?.additionalDetails?.presalesStage;
    if (!presalesStage) {
      console.warn('Missing presales stage!');
      return;
    }
    if (!stages) {
      return;
    }
    const stage = stages?.ids?.filter(
      (stage) =>
        stage &&
        stages?.entities[stage]?.name?.toLowerCase() ===
          presalesStage?.toLowerCase()
    );
    if (!stage?.length) {
      console.warn(
        'Current presales stage does not match with existing stages!'
      );
      return;
    }
    if (stage.length > 1) {
      console.warn(
        'Duplicate stages: Current presales stage matches more than one stage!'
      );
    }
    setSelectedStage({
      label: stages?.entities[stage[0]]?.name,
      value: stage[0],
    });
  }, [dealDetails?.additionalDetails?.presalesStage, stages]);

  const handlePresalesUserChange = (option) => {
    setPresalesUser(option);
    setShowSEUserConfirmation(true);
  };

  const handleConfirmationCancel = () => {
    setShowSEUserConfirmation(false);
    setPresalesUser([
      {
        label: dealDetails?.presalesUser?.displayName,
        value: dealDetails?.presalesUser?.id,
      },
    ]);
  };

  const handleConfirmation = () => {
    try {
      fetchDealDetails();
    } catch (error) {
      console.warn(error);
    }
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const userName = selectedUser?.label || userDetails?.userName;
    const isCollabrator = selectedCard?.collaborators?.some(
      (data) => data.name === userName
    );

    if (selectedCard?.cardId) {
      if (
        !isCollabrator &&
        userName !== 'All' &&
        presalesUser?.label !== userName
      ) {
        // * If updated se user is not the same as selected Kanban view user

        dispatch(
          updateDealStage({
            cardId: selectedCard?.cardId,
            sourceLaneId: selectedCard?.laneId,
            deleteCard: true,
          })
        );
        const updates = {
          presalesUser: presalesUser?.label,
          presalesUserDetails: {
            displayName: presalesUser?.label,
          },
        };
        const updatedCardDetails = {
          ...selectedCard,
          ...updates,
        };
        dispatch(saveSelectedCard(updatedCardDetails));
        return;
      }

      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
          updatedCardDetails: {
            presalesUser: presalesUser?.label,
            presalesUserDetails: {
              displayName: presalesUser?.label,
            },
          },
        })
      );
    }

    // reloadLaneOnSEUserChange(presalesUser);
  };

  const onDropdownSelect = (selectedItem) => {
    if (updatingStage) {
      return;
    }
    if (
      !selectedItem ||
      selectedItem.length === 0 ||
      selectedItem.label?.toLowerCase() === selectedStage?.label?.toLowerCase()
    ) {
      return;
    }
    const profileData = JSON.parse(localStorage.getItem('user'));
    const userId = profileData?.userId;
    const data = {
      id: selectedItem.value,
      userId,
      accountId: dealDetails?.accountId,
      dealId: dealDetails?.dealId,
      dealValue: dealDetails?.dealValue,
      presalesUser: dealDetails?.presalesUser?.displayName,
    };
    const presalesStageBeforeUpdate = selectedStage;
    setSelectedStage(selectedItem);
    updateAccountStage(data)
      .unwrap()
      .then(() => {
        dispatch(
          updateDealStage({
            data,
            updatedPreskaleStage: selectedItem,
            cardId: selectedCard?.cardId,
            sourceLaneId: selectedCard?.laneId,
            targetLaneId: data?.id,
          })
        );
        dispatch(
          extendedAccountsSlice.util.invalidateTags([
            { type: 'Deals', id: dealDetails.dealId },
          ])
        );
      })
      .catch((err) => {
        console.error('err', err);
        setSelectedStage(presalesStageBeforeUpdate);
        toast.error('Something went wrong: Unable to update presales stage!');
      });
  };

  const handleSectionSelect = (section, item) => {
    setActiveSection(section);
    setActiveListItem(item);
  };

  const updateCrmStageInCard = (data, selectedItem) => {
    dispatch(
      updateDealStage({
        data,
        updatedCrmStage: selectedItem,
        cardId: selectedCard?.cardId,
        sourceLaneId: selectedCard?.laneId,
      })
    );
  };

  return (
    <>
      <div className="deal-overview--container">
        <aside className="deal-overview--sidebar">
          <ul>
            <ul>
              <h4 className="deal-overview--section-title">CRM Section</h4>
              <li
                className={`deal-overview--section-item ${
                  activeListItem === 'deal-details' ? 'active' : ''
                } `}
                onClick={() => handleSectionSelect('crm', 'deal-details')}
              >
                Deal details
              </li>
            </ul>
            {sectionEnabled?.data &&
              configFeatures?.data?.featureList?.is_custom_section_enabled && (
                <ul>
                  <h4 className="deal-overview--section-title">
                    Custom Section
                  </h4>
                  {sections &&
                    sections.map((section) => (
                      <li
                        key={section.id}
                        className={`deal-overview--section-item ${
                          activeListItem?.id === section.id ? 'active' : ''
                        } `}
                        onClick={() =>
                          handleSectionSelect('custom-form', section)
                        }
                      >
                        {' '}
                        {section.name}{' '}
                      </li>
                    ))}
                </ul>
              )}
          </ul>
        </aside>
        <section className="deal-overview--content">
          {activeSection === 'crm' && (
            <>
              {dealDetailsError && (
                <Alert type="danger" className="m-20">
                  <div className="config-error">
                    Something went wrong!
                    <Button onClick={fetchDealDetails}>
                      Reload deal details
                    </Button>
                  </div>
                </Alert>
              )}
              <div className="deal-overview--title-container">
                <h3 className="deal-overview--title">Deal Information</h3>
                <hr className="horizontal" />
              </div>
              <ul className="descriptions">
                <li className="description--item">
                  <div className="description--title">Deal Owner</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : ''
                    } description--value`}
                  >
                    {dealDetails?.dealOwner}
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">Deal Age</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : ''
                    } description--value`}
                  >
                    {dealDetails?.dealAge} days
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">Deal Value</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : ''
                    } description--value`}
                  >
                    ${dealDetails?.dealValue}
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">Expected Close Date</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : ''
                    } description--value`}
                  >
                    {dealDetails?.additionalDetails?.estimatedCloseDate}
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">SE User</div>
                  {isAdminOrManager() ||
                  pipelineAccessibility?.data === 'user' ? (
                    <Select
                      className="description--value"
                      styles={editSEUserStyles}
                      options={userMapOptions || []}
                      components={{
                        DropdownIndicator: EditDropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={handlePresalesUserChange}
                      value={presalesUser}
                      menuPlacement="auto"
                      isDisabled={
                        dealDetailsError ||
                        readOnly ||
                        loading ||
                        userMapOptions?.length === 1
                      }
                    />
                  ) : (
                    <div
                      className={`${
                        loading ? 'font-grey' : ''
                      } description--value`}
                    >
                      {dealDetails?.presalesUser?.displayName}
                    </div>
                  )}
                </li>
              </ul>
              <div className="deal-overview--title-container">
                <h3 className="deal-overview--title">Deal Stage</h3>
                <hr className="horizontal" />
              </div>
              <ul className="descriptions">
                <li className="description--item">
                  <div className="description--title">SE Stage</div>
                  <Select
                    className="description--value"
                    styles={customStyles}
                    options={stages?.options || []}
                    onChange={onDropdownSelect}
                    value={selectedStage}
                    menuPlacement="auto"
                    components={
                      dealDetailsError ||
                      readOnly ||
                      loading ||
                      updatingStage ||
                      stagesLoading ||
                      configFeatures?.data?.featureList?.is_crm_stage_enabled
                        ? { DropdownIndicator: () => null }
                        : {}
                    }
                    isDisabled={
                      dealDetailsError ||
                      readOnly ||
                      loading ||
                      updatingStage ||
                      stagesLoading ||
                      configFeatures?.data?.featureList?.is_crm_stage_enabled
                    }
                    isLoading={updatingStage || stagesLoading}
                  />
                  <div
                    className={`${
                      loading ? 'font-grey' : 'description--valueExtra'
                    }`}
                  >
                    {dealDetails?.additionalDetails?.daysInPresalesStage} days
                    into this stage
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">CRM Stage</div>
                  <CRMStageDropdown
                    isPresentationView={true}
                    dealDetail={dealDetails}
                    fetchDealDetails={fetchDealDetails}
                    customStyles={customStyles}
                    handleSuccess={updateCrmStageInCard}
                  />
                  {configFeatures?.data?.crm !== 'freshworks' && (
                    <div
                      className={`${
                        loading ? 'font-grey' : 'description--valueExtra'
                      }`}
                    >
                      {dealDetails?.additionalDetails?.daysInCRMStage} days into
                      this stage
                    </div>
                  )}
                </li>
              </ul>
              <div className="deal-overview--title-container">
                <h3 className="deal-overview--title">Playbook</h3>
                <hr className="horizontal" />
              </div>
              <ul className="descriptions">
                <li className="description--item">
                  <div className="description--title">Industry</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : ''
                    } description--value`}
                  >
                    {industry}
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">Region</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : ''
                    } description--value`}
                  >
                    {dealDetails?.additionalDetails?.country}
                  </div>
                </li>
                <li className="description--item">
                  <div className="description--title">Product</div>
                  <div
                    className={`${
                      loading ? 'font-grey' : 'description--value'
                    }}`}
                  >
                    -
                  </div>
                </li>
              </ul>
            </>
          )}
          {activeSection === 'custom-form' &&
            sectionEnabled?.data &&
            configFeatures?.data?.featureList?.is_custom_section_enabled &&
            (sectionsLoading ? (
              <div className="loader-container">
                <DualRingLoader dark />
              </div>
            ) : !dealDetails?.dealId ? null : (
              <CustomForm
                section={activeListItem}
                deal={dealDetails}
                readOnly={readOnly}
                handleSaveCustomFieldSuccess={updateSortingInPipeline}
              />
            ))}
        </section>
      </div>
      {showSEUserConfirmation && (
        <PresalesChangeConfirmation
          showPopUp={showSEUserConfirmation}
          setShowPopUp={() => setShowSEUserConfirmation(false)}
          previousPresales={dealDetails?.presalesUser}
          currentPresales={presalesUser}
          dealId={dealDetails?.dealId}
          presalesUserId={presalesUserId}
          handleCancel={handleConfirmationCancel}
          handleConfirmation={handleConfirmation}
        />
      )}
    </>
  );
};

export default OverviewSection;
