import React, { useState, useRef, useEffect } from 'react';
import SearchField from '../Accounts/commons/SearchField';
import Select from 'react-select';
import Button from './Button';
import { MultiSelect } from './MultiSelect';
import { PULSE_SCORE } from '../Constant/FilterOperators';
import {
  ValueContainer,
  Option,
  MultiValueRemove,
} from '../Constant/MultiSelectStylesAndComponents';
import { ReactComponent as AddSVG } from '../Icons/NewImage/add-green.svg';
import { SOLUTION_SCORE } from './Rating';
import { useCaseDropdownStyles } from '../Constant/UseCaseHeader';
import { useFetchModulesFeaturesQuery } from '../features/ModulesManager/moduleFeaturesSlice';
import { getSelectedTeam } from '../Pipeline/utils/pipelineUtils';

const UseCaseHeader = ({
  showTitle = false,
  setFilters = () => {},
  account,
  search = true,
  handleAdd,
  addComponent,
  type = '', // Solution / Product Gap / Issues
  addButtonlabel,
}) => {
  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'All',
    isSelected: true,
  };
  const searchRef = useRef(null);
  const selectedTeam = getSelectedTeam();
  const { data: parentProductGap } = useFetchModulesFeaturesQuery({
    selectedTeam,
  });
  const [featureOptions, setFeatureOptions] = useState([]);
  const [defaultFeatureOptions, setDefaultFeatureOptions] = useState({}); // Cache for feature options list to display
  const [moduleFilter, setModuleFilter] = useState(parentProductGap);
  const [featureFilter, setFeatureFilter] = useState([]);
  const [featureFilterMod, setFeatureFilterMod] = useState({ all: true }); // To track which modules have active filters other than all
  const [pulseScoreFilter, setPulseScoreFilter] = useState(PULSE_SCORE);

  const storeDefaultFeatureOptions = (moduleValue, feature) => {
    // * Store in map to use later
    if (!defaultFeatureOptions[moduleValue]) {
      setDefaultFeatureOptions((prev) => ({
        ...prev,
        [moduleValue]: feature,
      }));
    }
  };

  const createFeatureList = (modules, all) => {
    let selectedModules = {};
    let filteredFeatures = [...featureFilter];
    const features = [selectAllOption];
    let optionsCount = 0; // Track current feature options count
    modules.forEach((module) => {
      if (!all) {
        selectedModules[module.value] = true;
      }
      const feature = defaultFeatureOptions[module.value] || {
        label: module.label,
        id: module.value,
        options:
          type === 'Solution'
            ? module.features
                .map((feature) => {
                  if (feature.label !== 'New Feature') {
                    optionsCount += 1;
                  }
                  return {
                    ...feature,
                    moduleId: module.value,
                    moduleLabel: module.label,
                    featureValue: feature.value,
                    value: `${module.value}-${feature.value}`,
                    isSelected: true,
                  };
                })
                .filter((feature) => feature.label !== 'New Feature')
            : module.features.map((feature) => {
                optionsCount += 1;
                return {
                  ...feature,
                  moduleId: module.value,
                  moduleLabel: module.label,
                  featureValue: feature.value,
                  value: `${module.value}-${feature.value}`,
                  isSelected: true,
                };
              }),
      };
      features.push(feature);
      if (!all) {
        storeDefaultFeatureOptions(module.value, feature);
        //  Track selected modules with active filters
        if (!featureFilterMod[module.value]) {
          filteredFeatures = [...filteredFeatures, ...feature.options];
        }
      }
    });
    return { features, filteredFeatures, optionsCount, selectedModules };
  };

  useEffect(() => {
    setModuleFilter(parentProductGap);
    if (!parentProductGap) {
      return;
    }
    const { features } = createFeatureList(parentProductGap, true);
    setFeatureOptions(features);
    setDefaultFeatureOptions({ all: features });
    setFeatureFilter([selectAllOption]);
  }, [parentProductGap]);

  const handleFilterChange = (change) => {
    console.log('change', change);
    setFilters((prev) => {
      let modulesCopy = { ...prev.modules };
      let featuresCopy = { ...prev.features };
      let scoreCopy = { ...prev.score };
      let searchTerm = prev.searchTerm;
      if (change?.modules) {
        modulesCopy = change.modules;
        modulesCopy.all = false;
      }
      if (change?.modules === null) {
        modulesCopy = { all: true };
      }
      if (change?.score) {
        scoreCopy = change.score;
        scoreCopy.all = false;
      }
      if (change?.score === null) {
        scoreCopy = { all: true };
      }
      if (change?.features) {
        console.log('change?.features', change?.features);
        featuresCopy = change.features;
        featuresCopy.all = false;
      }
      if (change?.features === null) {
        featuresCopy = { all: true };
      }
      if (change?.searchTerm) {
        searchTerm = change?.searchTerm;
      }
      if (change?.searchTerm === null) {
        searchTerm = '';
      }
      return {
        modules: modulesCopy,
        features: featuresCopy,
        score: scoreCopy,
        searchTerm,
      };
    });
  };

  useEffect(() => {
    //  * Handle change for features filter
    if (!featureOptions || !featureOptions.length) {
      return;
    }
    if (featureOptions[0].label === 'All' && featureOptions[0].isSelected) {
      handleFilterChange({ features: null });
      return;
    }
    let selectedFeatures = {};
    featureFilter.forEach((feature) => {
      if (feature.isSelected && feature.label !== 'All') {
        selectedFeatures[feature.value] = true;
      }
    });
    if (!Object.keys(selectedFeatures).length) {
      handleFilterChange({ features: null });
      return;
    }
    handleFilterChange({ features: selectedFeatures });
  }, [featureFilter, featureOptions]);

  const onModuleSelect = (option) => {
    setModuleFilter(option);
    if (!option.length) {
      handleFilterChange({ modules: null });
      setFeatureOptions([]);
      return;
    }
    if (option?.length === parentProductGap?.length) {
      setFeatureOptions(defaultFeatureOptions.all);
      selectAllOption.isSelected = true;
      setFeatureFilter([selectAllOption]);
      setFeatureFilterMod({ all: true });
      handleFilterChange({ modules: null });
      return;
    }
    if (option?.length) {
      if (featureFilter[0].label !== 'All') {
        selectAllOption.isSelected = false;
      }
      const { features, filteredFeatures, selectedModules } =
        createFeatureList(option);
      setFeatureOptions(features);
      if (featureFilterMod.all) {
        setFeatureFilter([selectAllOption]);
      } else {
        setFeatureFilter(filteredFeatures);
      }
      handleFilterChange({ modules: selectedModules });
    }
  };
  const onFeatureSelect = (option, triggeredAction) => {
    console.log('feature: option', option, featureOptions);
    console.log('feature: triggeredAction', triggeredAction);
    let data = option;
    let selectedValues = [];
    let options = [];
    // * -- if 'All' is selected/deselected -- * //
    if (
      (triggeredAction?.option?.label === 'All' &&
        (triggeredAction.action === 'select-option' ||
          triggeredAction.action === 'deselect-option')) ||
      triggeredAction.action === 'clear'
    ) {
      let allOption =
        triggeredAction.action === 'clear'
          ? { ...selectAllOption, isSelected: true }
          : triggeredAction.option;
      allOption.isSelected = !allOption.isSelected;
      options = featureOptions.map((section) => {
        if (section.label === 'All') {
          return allOption;
        }
        section.options = section.options.map((option) => {
          const optionCopy = { ...option };
          optionCopy.isSelected = allOption.isSelected;
          return optionCopy;
        });
        return section;
      });
      setFeatureOptions(options);
      setFeatureFilter([selectAllOption]);
      setFeatureFilterMod({ all: true });
      return;
    }
    // * -- End of: if 'All' is selected/deselected * -- //
    const filterMod = {};
    data = data.filter((option) => {
      return option.label !== 'All';
    });
    options = featureOptions.map((section) => {
      if (section.label === 'All') {
        const sectionCopy = { ...section };
        sectionCopy.isSelected = false;
        return sectionCopy;
      }

      section.options = section.options.map((option) => {
        const optionCopy = { ...option };
        if (
          option.value === triggeredAction.option.value &&
          (triggeredAction.action === 'select-option' ||
            triggeredAction.action === 'deselect-option')
        ) {
          optionCopy.isSelected = !triggeredAction.option.isSelected;
          if (optionCopy.isSelected) {
            selectedValues.push(optionCopy);
            if (!filterMod[optionCopy.moduleId]) {
              filterMod[optionCopy.moduleId] = true;
            }
          }
        } else {
          if (optionCopy.isSelected) {
            selectedValues.push(optionCopy);
            if (!filterMod[optionCopy.moduleId]) {
              filterMod[optionCopy.moduleId] = true;
            }
          }
        }
        return optionCopy;
      });
      return section;
    });
    setFeatureFilterMod(filterMod);
    setFeatureOptions(options);
    setFeatureFilter(selectedValues);
  };
  const onPulseScoreSelect = (option) => {
    setPulseScoreFilter(option);
    if (!option.length) {
      handleFilterChange({ score: null });
      return;
    }
    setPulseScoreFilter(option);
    if (PULSE_SCORE.length === option.length) {
      handleFilterChange({ score: null });
      return;
    }
    let scores = {};
    option.forEach((score) => {
      scores[score.value] = true;
    });
    handleFilterChange({ score: scores });
  };

  const onSearch = (e) => {
    const searchValue = e?.target?.value;
    if (!searchValue) {
      handleFilterChange({ searchTerm: null });
      return searchValue;
    }
    handleFilterChange({ searchTerm: searchValue });
    return searchValue;
  };
  return (
    <>
      <div className="module-usecase-header--container">
        <div className="module-title">
          {showTitle && `${type} Manager`}
          <div className="header--filters">
            <div className="header--filter">
              <span>Modules</span>
              <MultiSelect
                options={parentProductGap}
                value={moduleFilter}
                onChange={onModuleSelect}
                components={{ ValueContainer, Option }}
                styles={useCaseDropdownStyles}
                isSearchable={false}
              />
            </div>
            <div className="header--filter">
              <span>Features</span>
              <Select
                options={featureOptions}
                value={featureFilter}
                onChange={onFeatureSelect}
                isMulti
                components={{
                  ValueContainer,
                  Option,
                  MultiValueRemove,
                }}
                styles={useCaseDropdownStyles}
                isSearchable={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isClearable={true}
              />
            </div>
            <div className="header--filter">
              <span>{type === 'Product Gap' ? 'Pulse' : 'Score'}</span>
              <MultiSelect
                options={type === 'Product Gap' ? PULSE_SCORE : SOLUTION_SCORE}
                value={pulseScoreFilter}
                onChange={onPulseScoreSelect}
                components={{ ValueContainer, Option }}
                styles={useCaseDropdownStyles}
                isSearchable={false}
              />
            </div>
          </div>
        </div>

        <section className="actions--container">
          {search && (
            <SearchField
              ref={searchRef}
              placeholder="Search Use Cases"
              onChange={onSearch}
            />
          )}
          {!account.isClosedAccount && handleAdd && (
            <Button icon onClick={handleAdd} type="text">
              <AddSVG />{' '}
              {addButtonlabel ? (
                <span>
                  <span className="usecase-type">{addButtonlabel}</span>
                </span>
              ) : (
                <span>
                  Add {type && <span className="usecase-type">{type}</span>}
                </span>
              )}
            </Button>
          )}
        </section>
      </div>
      {addComponent}
    </>
  );
};

export default UseCaseHeader;
