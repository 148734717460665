import React, { useState, useMemo } from 'react';
import AddPg from './AddPg';
import { useFetchAllProductGapsQuery } from './productGapSlice';
import ProductGapList from './ProductGapList';
import { useCheckIsJiraIntegratedQuery } from '../../app/api/usersSlice';
import UseCaseHeader from '../../common/UseCaseHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedCard,
  updateDealStage,
} from '../../Pipeline/pipelineSlice';

const ProductGap = ({
  account,
  isPresentationView,
  handleUpdateSuccessCriteria = () => {},
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const selectedCard = useSelector(selectSelectedCard);
  const [filters, setFilters] = useState({
    modules: { all: true },
    features: { all: true },
    score: { all: true },
    searchTerm: '',
  });
  const jiraIntegrationFromLS = localStorage.getItem('jiraIntegration');
  const checkIsJiraIntegratedFromLS =
    jiraIntegrationFromLS &&
    JSON.parse(localStorage.getItem('jiraIntegration'));

  const { data: isJiraIntegrated = checkIsJiraIntegratedFromLS } =
    useCheckIsJiraIntegratedQuery();

  const groupPGListByModule = (productGaps) =>
    useMemo(() => {
      const pgByModule = {};
      Array.isArray(productGaps) &&
        productGaps.forEach((productGap) => {
          if (
            Array.isArray(productGap?.pgItem) &&
            productGap?.pgItem.length > 0 &&
            productGap?.pgItem[0]
          ) {
            pgByModule[productGap.pgItem[0].module?.value] = {
              id: productGap.pgItem[0].module?.value,
              module: productGap.pgItem[0].module?.label,
              usecases: pgByModule[productGap.pgItem[0].module?.value]
                ? [
                    ...pgByModule[productGap.pgItem[0].module.value].usecases,
                    productGap,
                  ]
                : [productGap],
            };
          } else {
            // * This is legacy code when product gap was a 4 step process
            pgByModule['noModule'] = {
              id: 'noModule',
              module: 'No associated module',
              usecases: pgByModule['noModule']
                ? [...pgByModule['noModule'].usecases, productGap]
                : [productGap],
            };
          }
        });
      return Object.values(pgByModule);
    }, [productGaps]);

  const { data: productGaps } = useFetchAllProductGapsQuery(
    {
      dealId: account?.dealId,
    },
    {
      skip: !account?.dealId,
      selectFromResult: ({ data }) => {
        const pgs = groupPGListByModule(data?.data || []);
        return { data: pgs };
      },
    }
  );

  const [showAddPgModal, setShowAddPgModal] = useState(false);

  const addProductGap = (ev) => {
    ev.preventDefault();
    setShowAddPgModal(true);
  };

  const handleProductGapsAfterAdding = (data, status) => {
    setShowAddPgModal(false);
    if (selectedCard?.cardId) {
      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
        })
      );
    }
    if (status) {
      handleUpdateSuccessCriteria('pg-add', data);
    }
  };

  const handleCloseModal = () => {
    setShowAddPgModal(false);
  };

  return (
    <div className="productgap--container">
      <UseCaseHeader
        account={account}
        handleAdd={readOnly ? false : addProductGap}
        setFilters={setFilters}
        type="Product Gap"
        addComponent={
          <AddPg
            open={showAddPgModal}
            account={account}
            onSuccess={handleProductGapsAfterAdding}
            onClose={handleCloseModal}
          />
        }
      />
      <ProductGapList
        list={productGaps}
        account={account}
        isPresentationView={isPresentationView}
        isJiraIntegrated={isJiraIntegrated}
        setProductGaps={() => {}}
        filters={filters}
        handleUpdateSuccessCriteria={handleUpdateSuccessCriteria}
        readOnly={readOnly}
      />
    </div>
  );
};

export default ProductGap;
