import React from 'react';

const CrmBanner = ({ message }) => (
  <div className="pipeline-sync-banner">
    {message ||
      'Data-sync in progress. All assigned opportunities will be available in sometime.'}
  </div>
);

export default CrmBanner;
