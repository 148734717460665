import React, { useEffect, useState } from 'react';
import { Button as AntButton, Drawer, Flex, Tooltip, Typography } from 'antd';
import { PG_USECASES_DATE_RANGE } from '../../Constant/FilterOperators';
import Select from 'react-select';
import { CATEGORY_LIST, RATING_LIST } from './constants';
import Button from '../../common/Button';

const Filter = ({
  openDrawer,
  handleClose,
  onSubmit,
  filterOptions,
  moduleList,
  featureList,
  selectedFilter,
  handleChange,
  handleReset,
}) => {
  const { Text, Title } = Typography;
  const [reporterList, setReporterList] = useState([]);
  const [issueTypeList, setIssueTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const defaultValue = { label: 'All', value: 'ALL' };
  const [ratingOptions, setRatingOptions] = useState([defaultValue]);
  const [categoryOptions, setCategoryOptions] = useState([defaultValue]);

  useEffect(() => {
    const filter = { ...selectedFilter };
    if (filterOptions?.reporterList?.length) {
      const options = filterOptions.reporterList.map((reporter) => {
        return { label: reporter, value: reporter };
      });
      setReporterList(options);
    }
    if (filterOptions?.issueTypeList?.length) {
      const options = filterOptions.issueTypeList.map((item) => {
        return { label: item, value: item };
      });
      setIssueTypeList(options);
    }
    if (filterOptions?.statusList?.length) {
      const options = filterOptions.statusList.map((item) => {
        return { label: item, value: item };
      });
      setStatusList(options);
    }
  }, [filterOptions]);

  useEffect(() => {
    setCategoryOptions(categoryOptions.concat(CATEGORY_LIST));
    setRatingOptions(ratingOptions.concat(RATING_LIST));
  }, []);

  return (
    <Drawer
      destroyOnClose
      open={openDrawer}
      onClose={handleClose}
      title={<Title level={4}>Issues Filter</Title>}
      closable={false}
      footer={
        <Flex gap={2} justify="end">
          <Tooltip title="Reset to Default">
            <Button type="link" onClick={handleReset}>
              Reset to Default
            </Button>
          </Tooltip>
          <AntButton onClick={handleClose}>Cancel</AntButton>
          <AntButton type="primary" onClick={onSubmit}>
            Apply
          </AntButton>
        </Flex>
      }
      styles={{
        wrapper: {
          width: '30%',
        },
        content: {
          backgroundColor: '#ecf2fe',
        },
      }}
      className="custom-drawer"
    >
      <Flex gap={4} vertical>
        <Text className="mb-5" strong>
          Issue Filters
        </Text>
        <Flex gap={6} vertical>
          <div className="filter-label">Reporter</div>
          <Select
            options={[defaultValue, ...reporterList]}
            onChange={(option) => handleChange('reporter', option)}
            value={selectedFilter?.reporter}
            menuPlacement="auto"
          />
        </Flex>
        <Flex gap={6} vertical>
          <div className="filter-label">Type</div>
          <Select
            options={[defaultValue, ...issueTypeList]}
            onChange={(option) => handleChange('type', option)}
            value={selectedFilter?.type}
            menuPlacement="auto"
          />
        </Flex>
        <Flex gap={6} vertical>
          <div className="filter-label">Status</div>
          <Select
            options={[defaultValue, ...statusList]}
            onChange={(option) => handleChange('status', option)}
            value={selectedFilter?.status}
            menuPlacement="auto"
          />
        </Flex>
        <Flex gap={6} vertical>
          <div className="filter-label">Created Date</div>
          <Select
            options={PG_USECASES_DATE_RANGE}
            onChange={(option) => handleChange('createdDate', option)}
            value={selectedFilter?.createdDate}
            menuPlacement="auto"
          />
        </Flex>
      </Flex>
      <Flex gap={4} vertical>
        <Text className="mt-10 mb-5" strong>
          Issue Details Filters
        </Text>
        <Flex gap={6} vertical>
          <div className="filter-label">Category</div>
          <Select
            options={categoryOptions}
            onChange={(option) => handleChange('category', option)}
            value={selectedFilter?.category}
            menuPlacement="auto"
          />
        </Flex>
        <Flex gap={6} vertical>
          <div className="filter-label">Module</div>
          <Select
            options={[defaultValue, ...moduleList]}
            onChange={(option) => handleChange('module', option)}
            value={selectedFilter?.module}
            menuPlacement="auto"
          />
        </Flex>
        <Flex gap={6} vertical>
          <div className="filter-label">Feature</div>
          <Select
            options={[defaultValue, ...featureList]}
            onChange={(option) => handleChange('feature', option)}
            value={selectedFilter?.feature}
            menuPlacement="auto"
          />
        </Flex>
        <Flex gap={6} vertical>
          <div className="filter-label">Pulse Score</div>
          <Select
            options={ratingOptions}
            onChange={(option) => handleChange('rating', option)}
            value={selectedFilter?.rating}
            menuPlacement="auto"
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default Filter;
