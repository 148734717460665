import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { ReactComponent as AddSVG } from '../Icons/Plus.svg';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxios from '../hooks/useAxios';
import {
  addParentContribution,
  getParentContribution,
} from '../_services/common.service';

export default function AddContributions() {
  const axios = useAxiosPrivate();
  const addParentContributionService = useAxios(axios);
  const getContributions = useAxios(axios);
  const [initialContributionList, setIntialContributionList] = useState([]);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [input, setInput] = useState('');
  const history = useHistory();

  useEffect(() => {
    getAllContributions();
  }, []);

  const getAllContributions = async () => {
    const profileData = JSON.parse(localStorage.getItem('user'));
    const companyId = profileData?.companyId;
    getParentContribution(getContributions, {
      params: { company_id: companyId },
      handleSuccess: handleGetAllContributions,
    });
  };

  const handleGetAllContributions = (data) => {
    let initialList = [];
    data.forEach((item) => {
      if (!item.hideFromUI) {
        initialList.push({ title: item.title, id: item.id });
      }
    });
    setIntialContributionList(initialList);
  };

  const handleAddPopUp = () => {
    setShowAddPanel(true);
  };

  const handleOnChange = (inputValue) => {
    setInput(inputValue);
  };

  const handleCancel = () => {
    setShowAddPanel(false);
  };

  const handleAddItem = () => {
    let contributionList = [];
    contributionList.push(input);
    const isDuplicate = initialContributionList?.some((contribution) => {
      if (contribution.title?.toLowerCase() === input?.toLowerCase()) {
        toast.warning('Contribution already present!');
        return true;
      }
      return false;
    });
    if (isDuplicate) {
      handleCancel();
      return;
    }
    addParentContribution(
      addParentContributionService,
      contributionList,
      () => {
        toast.success('Contribution Added');
        setShowAddPanel(false);
        getAllContributions();
      },
      () => toast.error('Error')
    );
  };

  return (
    <div className="add-contribution">
      <div className="popover-inline-wrapper">
        <div className="flex">
          <h2 className="width-95 add-contribution-title">Outcomes</h2>
          <div className="config-company-add-btn">
            <a
              href="#"
              data-tip="Add"
              data-place="bottom"
              onClick={() => handleAddPopUp()}
              className="add-button"
            >
              <AddSVG />
            </a>
          </div>
        </div>

        <div className="display-list-wrapper">
          <ol>
            {initialContributionList.length > 0 &&
              initialContributionList.map((contribution) => (
                <div className="criteria-list" key={contribution?.id}>
                  <li className="display-list">
                    <div className="flex">
                      <div className="width-95">{contribution?.title} </div>
                      {/* <a className="edit-button">
                                                <EditSVG />
                                            </a>
                                            <a className="edit-button">
                                                <DeleteSVG />
                                            </a> */}
                    </div>
                  </li>
                </div>
              ))}
          </ol>
        </div>

        <ReactModal
          isOpen={showAddPanel}
          className="Modal confirm-box"
          overlayClassName="Overlay confirm-box-overlay"
          ariaHideApp={false}
        >
          <div className="modal-header">
            <h4 className="modal-title">Add Contribution</h4>
          </div>
          <form onSubmit={handleAddItem}>
            <div className="modal-body form">
              <div className="form-field">
                <label> Contribution Name </label>
                <div className="required-field">
                  <input
                    type="text"
                    onChange={(event) => handleOnChange(event.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                {' '}
                Cancel{' '}
              </button>
              <button type="submit" className="btn btn-primary">
                {' '}
                Add Item{' '}
              </button>
            </div>
          </form>
        </ReactModal>
        <div className="login-fields">
          <button
            className="skip-button"
            onClick={() => history.push('/dashboard')}
          >
            Skip for later
          </button>
        </div>
      </div>
    </div>
  );
}
