import { businessService } from './businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedActivitiesSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchActivitySummary: builder.query({
      query: ({ dealId, from, to }) =>
        `${URLS_BUSINESS_SERVICE.activities.activitySummary}?deal_id=${dealId}&from_date=${from}&to_date=${to}`,
      providesTags: (result, error, arg) => {
        return [{ type: 'ActivitySummary', id: arg?.dealId }];
      },
    }),
  }),
});

export const { useFetchActivitySummaryQuery } = extendedActivitiesSlice;
