import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  memo,
} from 'react';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import LaneHeader from './LaneHeader';
import KanbanCard from './KanbanCard';
import {
  isSalesUser as findIsSalesUser,
  isProductUser as findIsProductUser,
} from '../../Utils/common';
import Board from 'react-trello';
import Loader from '../../common/Loader';
import {
  saveSelectedCard,
  selectSelectedCard,
  selectStageDetails,
  updateDealStage,
  useFetchTotalRevenueMutation,
} from '../pipelineSlice';
import { useFetchAccountsByStageMutation } from '../../app/api/stageSlice';
import {
  getAccountByStageSuccess,
  updateLoadingState,
} from '../utils/pipelineUtils';
import { useGetAllCompanyUsersQuery } from '../../app/api/usersSlice';
import {
  extendedAccountsSlice,
  useUpdateAccountStageMutation,
} from '../../Accounts/accountsSlice';
import useDebounce from '../../hooks/useDebounce';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const KanbanView = ({
  parentStages,
  stages, // sales stages
  parentStagesLoading,
  pipelineFilter,
  handleCardClick,
  userFilter,
  teamFilter,
  refetchCardDetails,
  setRefetchCardDetails,
  isCRMStageView,
  refreshForCreateOpportunity,
  setRefreshForCreateOpportunity,
  currentPage,
  setCurrentPage,
  collapseEmptyLanes,
}) => {
  const dispatch = useDispatch();
  const initialBoardData = useRef({ lanes: [] });
  const parentStageMap = useRef({});
  const prevPage = useRef({}); // To track current page
  const lastRequestedPage = useRef({}); // To track last requested page
  const [boardData, setBoardData] = useState(initialBoardData?.current);
  const [eventBus, setEventBus] = useState(undefined); // Set up board events
  const [lanesToUpdate, setLanesToUpdate] = useState([]); // Lanes that need a update after data change
  const debouncedUpdateLanes = useDebounce(lanesToUpdate, 1500);
  const dealsCountByLane = useRef({});
  const updatedCard = useSelector(selectStageDetails);
  const selectedCard = useSelector(selectSelectedCard);
  const isSalesUser = findIsSalesUser();
  const isProductUser = findIsProductUser();
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userId = userDetails?.userId;
  const LANE_WIDTH = '240px';
  const COLLAPSED_LANE_WIDTH = '42px';

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const [
    totalRevenue,
    { isSuccess: totalRevenueSuccess, isLoading: totalRevenueLoading },
  ] = useFetchTotalRevenueMutation();

  const [fetchAccountsByStage, { isLoading: fetchingAccountsByStage }] =
    useFetchAccountsByStageMutation();

  const { data: existingUserList } = useGetAllCompanyUsersQuery();

  const [updateAccountStage, { isLoading: updatingAccountStage }] =
    useUpdateAccountStageMutation();

  const initializeBoardData = (boardStages) => {
    // console.log('boardStages', boardStages);
    if (!boardStages || !Array.isArray(boardStages)) {
      return;
    }
    // * Prep board data
    let defaultPage = {};
    let parentStages = {};
    const lanes = boardStages?.map((lane) => {
      parentStages[lane.id] = lane.name;
      defaultPage[lane.id] = 1;
      return {
        id: lane.id,
        header: lane.name,
        name: lane.name,
        loading: 'false',
        currentPage: prevPage.current[lane.id] || 1,
        cards: [],
      };
    });
    prevPage.current = defaultPage;
    parentStageMap.current = parentStages;
    initialBoardData.current = { lanes };
    return lanes;
  };

  useEffect(() => {
    if (Object.keys(currentPage).length === 0) {
      return;
    }
    prevPage.current = { ...currentPage };
  }, [currentPage]);

  const getAccountByStage = (
    filter,
    laneId,
    lanes,
    updateLanes,
    size = 10,
    userId
  ) => {
    // Prevent sending request for lane data before applying filters
    if (!filter) {
      console.warn('No filters applied');
      return;
    }

    const expectedCloseDate = { ...filter.expectedCloseDate };
    // Removing operator key from the request as it is not needed for the backend
    if (expectedCloseDate?.operator) {
      expectedCloseDate.operator = undefined;
    }

    // Shows loading indicator for the lane that matches the laneId
    updateLoadingState(laneId, true, {
      lanes,
      setBoardData,
      parentStageMap: parentStageMap.current,
    });

    // Removes loading indicator in case of an error
    const handleError = (error) => {
      updateLoadingState(laneId, false, {
        lanes,
        setBoardData,
        parentStageMap: parentStageMap.current,
        handleRetry: () =>
          getAccountByStage(filter, laneId, lanes, updateLanes, 10, userId),
        error,
      });
    };

    // Populates cards and tracks current page
    // Also removes loading indicator
    const handleSuccess = (data) => {
      getAccountByStageSuccess(
        data,
        lanes,
        laneId,
        prevPage,
        currentPage,
        setCurrentPage,
        filter,
        setBoardData,
        parentStageMap.current,
        userFilter,
        updateLanes,
        existingUserList
      );

      // * Populate lane with cards when there is more space
      const headerEl = document.getElementById(laneId);
      const laneBody = headerEl.nextSibling;
      const lastPage = lastRequestedPage.current[laneId] ?? 1;
      const approxDealsInLane = 10 * lastPage;

      if (
        laneBody.clientHeight >= laneBody.scrollHeight &&
        lastPage &&
        dealsCountByLane[laneId] > approxDealsInLane
      ) {
        onLaneScroll(lastPage + 1, laneId);
      }
    };

    // Fetches list of cards for the given lane id
    return fetchAccountsByStage({
      parentId: laneId,
      filter: { ...filter, size, expectedCloseDate },
      userId,
      crmStageView: isCRMStageView === 'crm',
      teamFilter,
      pipelineId: JSON.parse(localStorage.getItem('pipeline'))?.value ?? null,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  };

  const getAllAccountsAndRevenue = async (filter, userId, selectedLanes) => {
    totalRevenue({
      filter,
      userId,
      crmStageView: isCRMStageView === 'crm',
      teamFilter,
      pipelineId: JSON.parse(localStorage.getItem('pipeline'))?.value ?? null,
    })
      .unwrap()
      .then((data) => {
        const lanes = initialBoardData.current?.lanes?.map((lane) => {
          lane.count =
            data?.entities[
              isCRMStageView === 'crm' ? lane.id : lane.name
            ]?.count;
          lane.revenue =
            data?.entities[
              isCRMStageView === 'crm' ? lane.id : lane.name
            ]?.revenue;
          if (
            collapseEmptyLanes &&
            (lane.count === 0 || lane.count === undefined)
          ) {
            lane.style = { width: COLLAPSED_LANE_WIDTH };
          } else {
            lane.style = { width: LANE_WIDTH };
          }
          dealsCountByLane[lane.id] = lane.count;
          return lane;
        });
        setBoardData({ lanes });
      })
      .catch((error) => {
        console.error(error);
        toast.error('Something went wrong');
      });

    if (selectedLanes) {
      selectedLanes?.forEach((laneId) => {
        getAccountByStage(
          {
            ...filter,
            page: 1,
          },
          laneId,
          initialBoardData.current?.lanes,
          null,
          10,
          userId
        );
      });
      return;
    }
    console.log('continue');
    // Fetches cards for each stage from parent stages
    initialBoardData.current?.lanes?.forEach((lane) => {
      getAccountByStage(
        {
          ...filter,
          page: 1,
        },
        lane.id,
        initialBoardData.current?.lanes,
        null,
        10,
        userId
      );
    });
  };

  useEffect(() => {
    // * Reset board data whenever parentStages changes
    const lanes = initializeBoardData(parentStages);
    if (!lanes || !lanes.length) {
      return;
    }
    setBoardData({ lanes });
    // * Get revenue and deals information for each lane for crm stage view
    if (isCRMStageView === 'crm') {
      console.log('crm stage view, call accounts');
      getAllAccountsAndRevenue(pipelineFilter, userFilter?.value || undefined);
    }
  }, [parentStages]);

  useEffect(() => {
    if (!initialBoardData.current?.lanes.length) {
      return;
    }
    const lanes = initialBoardData.current?.lanes.map((lane) => {
      if (
        collapseEmptyLanes &&
        (lane.count === 0 || lane.count === undefined)
      ) {
        lane.style = { width: COLLAPSED_LANE_WIDTH };
      } else {
        lane.style = { width: LANE_WIDTH };
      }
      return lane;
    });
    setBoardData({ lanes });
  }, [collapseEmptyLanes]);

  useEffect(() => {
    // * Get revenue and deals information for each lane for preskale stage view
    // * This has been seperated from the above useEffect prevent reinitializing board data everytime a change is detected in sales stages
    // * Also, we are using set interval to check if the board has been initialized with the lane data instead of a state
    // * Because, setting boardData as dependency will trigger an infinite loop
    // * We could have added another state to track it but it becomes an added dependency which may be misused down the lane
    // console.log('stages', stages);
    const checkBoardInterval = setInterval(() => {
      if (initialBoardData.current?.lanes.length) {
        if (isCRMStageView === 'preskale' && stages) {
          let filter = { ...pipelineFilter };
          if (!filter.stage) {
            filter.stage = stages.map((stage) => stage?.value);
          }
          console.log('preskale stage view, call accounts');
          getAllAccountsAndRevenue(filter, userFilter?.value || undefined);
        }
        clearInterval(checkBoardInterval);
        return;
      }
    }, 10);
    return () => {
      if (checkBoardInterval) {
        clearInterval(checkBoardInterval);
      }
    };
  }, [stages, isCRMStageView]);

  useEffect(() => {
    // * Update board data when cards are moved from one lane to another
    // * The asynchronous calls are made after a delay to group all actions
    // * This is also because the UI is updated by react-trello
    // * Hence there is no rush to update the details
    if (!debouncedUpdateLanes || !debouncedUpdateLanes.length) {
      return;
    }
    const lanesToUpdate = [...new Set(debouncedUpdateLanes)];
    // console.log('lanesToUpdate', lanesToUpdate);
    getAllAccountsAndRevenue(
      pipelineFilter,
      userFilter?.value || undefined,
      lanesToUpdate
    );
    eventBus?.publish({
      type: 'UPDATE_LANES',
      lanes: initialBoardData.current?.lanes,
    });
    setLanesToUpdate([]);
  }, [debouncedUpdateLanes]);

  useEffect(() => {
    // console.log('refetchCardDetails', refetchCardDetails);
    if (!refetchCardDetails || !boardData || !boardData?.lanes?.length) {
      // * Prevent calling revenue and account requests if board has not initialized yet
      // * Currently userFilter tries to trigger the board render
      // * Since we are not persisting userFilter this doesn't seem to have any issues
      return;
    }
    if (refetchCardDetails?.userFilter && !refetchCardDetails?.filter) {
      getAllAccountsAndRevenue(
        pipelineFilter,
        refetchCardDetails?.userFilter.value
      );
    }
    if (refetchCardDetails?.filter && !refetchCardDetails?.userFilter) {
      getAllAccountsAndRevenue(
        refetchCardDetails?.filter,
        userFilter?.value || undefined
      );
    }
    if (refetchCardDetails?.filter && refetchCardDetails?.userFilter) {
      getAllAccountsAndRevenue(
        refetchCardDetails?.filter,
        refetchCardDetails?.userFilter?.value
      );
    }

    if (refetchCardDetails?.laneId) {
      // * Refresh the cards in the lane in which presales user have been edited
      getAllAccountsAndRevenue(pipelineFilter, userFilter?.value || undefined, [
        refetchCardDetails?.laneId,
      ]);
    }

    if (
      refetchCardDetails &&
      !refetchCardDetails.laneId &&
      !refetchCardDetails.userFilter &&
      !refetchCardDetails.filter
    ) {
      getAllAccountsAndRevenue(pipelineFilter, userFilter?.value || undefined);
    }
    setRefetchCardDetails(null);
  }, [refetchCardDetails]);

  useEffect(() => {
    // * Update board data on preskale created opportunity's actions
    if (!refreshForCreateOpportunity) {
      return;
    }
    let laneId;
    if (refreshForCreateOpportunity?.deleteDeal) {
      laneId = refreshForCreateOpportunity?.laneId || selectedCard?.laneId;
    }
    if (
      refreshForCreateOpportunity !== null &&
      !refreshForCreateOpportunity.deleteDeal
    ) {
      if (!refreshForCreateOpportunity?.update && parentStages) {
        laneId = parentStages[0]?.id;
      } else if (selectedCard?.laneId) {
        laneId = selectedCard?.laneId;
      }
      // TODO: Check what the below code does and add comments
      // * Updates information on the selected card
      // * May be needed for data consistency
      dispatch(
        saveSelectedCard({
          ...selectedCard,
          dealValue: refreshForCreateOpportunity?.dealValue,
        })
      );
    }
    getAllAccountsAndRevenue(pipelineFilter, userFilter?.value || undefined, [
      laneId,
    ]);
    setRefreshForCreateOpportunity(null);
  }, [refreshForCreateOpportunity]);

  useEffect(() => {
    if (!updatedCard && eventBus) {
      eventBus.publish({
        type: 'UPDATE_LANES',
        lanes: initialBoardData.current?.lanes,
      });
      return;
    }
    console.log('updatedCard', updatedCard);
    const updateLanes = [];
    if (updatedCard?.sourceLaneId) {
      updateLanes.push(updatedCard?.sourceLaneId);
    }
    if (updatedCard?.targetLaneId) {
      updateLanes.push(updatedCard?.targetLaneId);
    }
    if (updatedCard?.smartSort) {
      if (updatedCard?.updatedCardDetails) {
        const updatedCardDetails = {
          ...selectedCard,
          ...updatedCard.updatedCardDetails,
        };
        dispatch(saveSelectedCard(updatedCardDetails));
      }
      dispatch(updateDealStage(null));
      setLanesToUpdate(updateLanes);
      return;
    }
    if (updatedCard?.deleteCard) {
      dispatch(updateDealStage(null));
      setLanesToUpdate(updateLanes);
      return;
    }
    if (
      !updatedCard ||
      !updatedCard?.cardId ||
      !updatedCard?.sourceLaneId ||
      !updatedCard?.data?.id
    ) {
      return;
    }

    if (isCRMStageView === 'crm' && updatedCard?.updatedPreskaleStage) {
      dispatch(
        saveSelectedCard({
          ...selectedCard,
          currentPreskaleStageName: updatedCard.updatedPreskaleStage?.label,
        })
      );
      dispatch(updateDealStage(null));
      setLanesToUpdate(updateLanes);
      return;
    }
    if (updatedCard?.updatedCrmStage) {
      dispatch(
        saveSelectedCard({
          ...selectedCard,
          crmStage: updatedCard.updatedCrmStage?.label,
        })
      );
      dispatch(updateDealStage(null));
      setLanesToUpdate(updateLanes);
      return;
    }

    //Do not move cards when crm stage is updated for preskale created deals in crm connected account
    if (configFeatures?.data?.crm && isCRMStageView === 'crm') {
      dispatch(updateDealStage(null));
      return;
    }

    dispatch(
      saveSelectedCard({ ...selectedCard, laneId: updatedCard?.data?.id })
    );
    dispatch(updateDealStage(null));
    setLanesToUpdate(updateLanes);
  }, [updatedCard]);

  const onLaneScroll = useCallback(
    (requestedPage, laneId) => {
      // * Exit if requested page is the same as last requested page
      if (lastRequestedPage.current[laneId] === requestedPage) {
        return Promise.resolve();
      }
      lastRequestedPage.current[laneId] = requestedPage;
      return getAccountByStage(
        { ...pipelineFilter, page: requestedPage },
        laneId,
        initialBoardData.current?.lanes,
        (lanes) => eventBus?.publish({ type: 'UPDATE_LANES', lanes }),
        10,
        userFilter?.value
      );
    },
    [pipelineFilter, eventBus, boardData, userFilter?.value]
  );

  const moveAccount = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    const currentUser = userFilter ? userFilter?.label : userDetails?.userName;
    //TODO: fix inconsistency in presales user data
    const presalesUser =
      cardDetails?.presalesUserDetails?.displayName ??
      cardDetails?.presalesUser;
    console.log('current user', currentUser);
    let updatedCard;
    // * Update the source lane
    initialBoardData.current?.lanes.map((lane) => {
      if (lane.id === sourceLaneId) {
        let cards = lane.cards.filter((card) => {
          if (card.id === cardId) {
            updatedCard = card;
            updatedCard.laneId = targetLaneId;
          }
          return card.id !== cardId;
        });
        lane.cards = cards;
        let isCollabrator = false;
        if (updatedCard?.collabrator) {
          const usersData = updatedCard?.collabrator?.map((data) => {
            return data.name;
          });
          isCollabrator = usersData.includes(currentUser);
        }
        if (
          userFilter?.value === 'all' ||
          presalesUser === currentUser ||
          isCollabrator
        ) {
          lane.revenue = lane.revenue - cardDetails?.dealValue;
          lane.count = lane.count - 1;
          if (
            collapseEmptyLanes &&
            (lane.count === 0 || lane.count === undefined)
          ) {
            lane.style = { width: COLLAPSED_LANE_WIDTH };
          } else {
            lane.style = { width: LANE_WIDTH };
          }
        }
      }
    });
    if (!updatedCard) {
      console.error('Updated card not found!');
      return;
    }
    // * Update the target lane
    initialBoardData.current?.lanes.map((lane) => {
      if (lane.id === targetLaneId) {
        let cards = [...lane.cards];
        // To maintain the position when lanes are updated using the eventBus
        cards.splice(position, 0, updatedCard);
        lane.cards = cards;
        let isCollabrator = false;
        if (updatedCard?.collabrator) {
          const usersData = updatedCard?.collabrator?.map((data) => {
            return data.name;
          });
          isCollabrator = usersData.includes(currentUser);
        }
        console.log(
          'presalesUserName',
          cardDetails?.presalesUserDetails?.displayName
        );
        console.log('presalesUser', cardDetails?.presalesUser);
        // TODO: Check this code
        if (
          totalRevenueSuccess &&
          (userFilter?.value === 'all' ||
            presalesUser === currentUser ||
            isCollabrator)
        ) {
          if (!lane.revenue) {
            lane.revenue = 0 + cardDetails.dealValue;
            lane.count = 1;
          } else {
            lane.revenue = lane.revenue + cardDetails.dealValue;
            lane.count = lane.count + 1;
          }
          lane.style = { width: LANE_WIDTH };
        }
      }
    });
  };

  const optimisticallyCollapseLane = (sourceLaneId, targetLaneId) => {
    // * Collapse lanes if count is 0 optimistically
    initialBoardData.current?.lanes.map((lane) => {
      if (lane.id === sourceLaneId) {
        let count = lane.count - 1;
        if (collapseEmptyLanes && count === 0) {
          lane.style = { width: COLLAPSED_LANE_WIDTH };
        } else {
          lane.style = { width: LANE_WIDTH };
        }
      }
      if (lane.id === targetLaneId) {
        let count = lane.count + 1;
        if (collapseEmptyLanes && count === 0) {
          lane.style = { width: COLLAPSED_LANE_WIDTH };
        } else {
          lane.style = { width: LANE_WIDTH };
        }
      }
    });
    setBoardData(initialBoardData.current);
  };

  const rollbackLaneCollapse = (sourceLaneId, targetLaneId) => {
    // * Rollback lane collapse
    initialBoardData.current?.lanes.map((lane) => {
      if (lane.id === sourceLaneId) {
        if (collapseEmptyLanes && !lane.count) {
          lane.style = { width: COLLAPSED_LANE_WIDTH };
        } else {
          lane.style = { width: LANE_WIDTH };
        }
      }
      if (lane.id === targetLaneId) {
        if (collapseEmptyLanes && lane.count === 0) {
          lane.style = { width: COLLAPSED_LANE_WIDTH };
        } else {
          lane.style = { width: LANE_WIDTH };
        }
      }
    });
    setBoardData(initialBoardData.current);
  };

  const handleDragEnd = useCallback(
    (
      // Method called when a card is dropped into a lane
      cardId,
      sourceLaneId,
      targetLaneId,
      position,
      cardDetails
    ) => {
      if (
        sourceLaneId === targetLaneId ||
        configFeatures?.data?.featureList?.is_crm_stage_enabled
      ) {
        return;
      }
      const data = {
        id: targetLaneId,
        accountId: cardDetails.accountId,
        dealId: cardDetails.dealId,
        userId,
      };
      optimisticallyCollapseLane(sourceLaneId, targetLaneId);
      updateAccountStage(data)
        .unwrap()
        .then(() => {
          // * This function prevents UI glitch where the card momentarily goes back to the old lane before the lane data is refreshed
          moveAccount(
            cardId,
            sourceLaneId,
            targetLaneId,
            position,
            cardDetails
          );
          dispatch(
            extendedAccountsSlice.util.invalidateTags([
              { type: 'Deals', id: data.dealId },
            ])
          );
        })
        .catch((error) => {
          toast.error('Unable to move card');
          rollbackLaneCollapse(sourceLaneId, targetLaneId);
          console.warn('error', error);
        })
        .finally(() => {
          eventBus?.publish({
            type: 'UPDATE_LANES',
            lanes: initialBoardData.current?.lanes,
          });
          setLanesToUpdate((prev) => {
            return [...prev, sourceLaneId, targetLaneId];
          });
        });
    },
    [configFeatures?.data?.featureList?.is_crm_stage_enabled]
  );

  const style = useMemo(() => ({ padding: '16px 16px 10px' }), []);
  const laneStyle = useMemo(
    () => ({
      cursor: 'grab',
      width: '20.2%',
    }),
    []
  );
  const _renderCard = memo((props) => {
    return (
      <KanbanCard
        {...props}
        tab="kanban"
        handleCardClick={handleCardClick}
        isCRMStageView={isCRMStageView}
        isProductUser={isProductUser}
      />
    );
  });
  const components = useMemo(
    () => ({
      Card: _renderCard,
      LaneHeader: (props) => (
        <LaneHeader
          totalRevenueSuccess={totalRevenueSuccess}
          totalRevenueLoading={totalRevenueLoading || fetchingAccountsByStage}
          updatingAccountStage={updatingAccountStage}
          {...props}
        />
      ),
      Loader: () => null,
    }),
    [
      parentStages,
      totalRevenueLoading,
      totalRevenueSuccess,
      updatingAccountStage,
      fetchingAccountsByStage,
    ]
  );

  return (
    <>
      {parentStagesLoading ? (
        <Loader />
      ) : (
        <Board
          data={boardData}
          style={style}
          laneStyle={laneStyle}
          components={components}
          handleDragEnd={handleDragEnd}
          onLaneScroll={onLaneScroll}
          eventBusHandle={setEventBus}
          cardDraggable={
            isCRMStageView === 'crm'
              ? false
              : configFeatures?.data?.featureList?.is_crm_stage_enabled
              ? false
              : isSalesUser
              ? false
              : isProductUser
              ? false
              : true
          }
        />
      )}
    </>
  );
};

export default KanbanView;
