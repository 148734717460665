import { businessService } from './businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedTeamsSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllTeams: builder.query({
      query: () => URLS_BUSINESS_SERVICE.teams.getAll,
      providesTags: () => [{ type: 'Teams', id: 'ALL' }],
    }),
    fetchPipelineAccessibility: builder.query({
      query: () => URLS_BUSINESS_SERVICE.teams.getPipelineAccessibility,
      providesTags: () => [{ type: 'Teams', id: 'getPipelineAccessibility' }],
    }),
    updatePipelineAccessibility: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.updatePipelineAccessibility,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        { type: 'Teams', id: 'getPipelineAccessibility' },
      ],
    }),
    fetchGetTeamsByManager: builder.query({
      query: () => URLS_BUSINESS_SERVICE.teams.getTeamsByManager,
    }),
    fetchGetAllTeamsPlaybook: builder.query({
      query: () => URLS_BUSINESS_SERVICE.teams.getAllTeamsPlaybook,
    }),
    fetchAllUserTeams: builder.query({
      query: () => URLS_BUSINESS_SERVICE.teams.getAllUserTeams,
      providesTags: () => [{ type: 'Teams', id: 'ALL_USERS' }],
    }),
  }),
});

export const {
  useFetchAllTeamsQuery,
  useFetchPipelineAccessibilityQuery,
  useUpdatePipelineAccessibilityMutation,
  useFetchGetTeamsByManagerQuery,
  useFetchGetAllTeamsPlaybookQuery,
  useFetchAllUserTeamsQuery,
} = extendedTeamsSlice;
