import { useState } from 'react';
import {
  useLazyActivateUserQuery,
  useLazyDeactivateUserQuery,
} from '../app/api/userService';
import { toast } from 'react-toastify';

const CheckboxRenderer = ({ data, status }) => {
  const [isChecked, setIsChecked] = useState(data.active);
  const [activate, { isFetching: activatingUser }] = useLazyActivateUserQuery();
  const [deactivate, { isFetching: deactivatingUser }] =
    useLazyDeactivateUserQuery();

  const handleCheckboxChange = async () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (newValue) {
      activate({
        userId: data?.id,
        companyId: data?.companyId,
        userName: data?.email,
      })
        .unwrap()
        .then(() => {
          toast.success('Updated Successfully');
        });
    } else {
      deactivate({
        userId: data?.id,
        companyId: data.companyId,
        userName: data?.email,
      })
        .unwrap()
        .then(() => {
          toast.success('Updated Successfully');
        });
    }
  };

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={handleCheckboxChange}
      disabled={activatingUser || deactivatingUser || status !== 'ACTIVE'}
    />
  );
};

export default CheckboxRenderer;
