import { createEntityAdapter } from '@reduxjs/toolkit';
import { businessService } from '../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

const adminopsAdapter = createEntityAdapter();

const initialState = adminopsAdapter.getInitialState();

export const tranformOutcomeResponse = (response) => {
  const transformedData = response?.map((item) => ({
    label: item.title,
    value: item.id,
  }));
  return transformedData;
};

export const extendedAdminOpsSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanyConfigDatabyId: builder.query({
      query: ({ companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.features}?companyId=${companyId}`;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'AdminOps', id: 'ConfigFeatures' },
      ],
    }),
    updateUserCount: builder.query({
      query: ({ companyId, count }) => {
        return `${URLS_BUSINESS_SERVICE.organization.updateUserCount}?id=${companyId}&count=${count}`;
      },
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          businessService.util.invalidateTags([
            { type: 'Company', id: 'allCompanies' },
          ])
        );
      },
    }),
    getAllUsersByCompanyId: builder.query({
      query: ({ companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getAllCompanyUsers}${
          companyId ? `?companyId=${companyId}` : ''
        }`;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'AdminOps', id: 'getAllUsers' },
      ],
      transformResponse: (responseData) => {
        return adminopsAdapter.setAll(initialState, responseData?.data);
      },
    }),
    //return both active and inactive users
    getAllUserDetailsByRoleByCompanyId: builder.query({
      query: ({ role, companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getAllUsersByRole}?role=${role}&companyId=${companyId}`;
      },
    }),
    updateNotificationCenter: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.organization.updateNotificationCenter,
        method: 'POST',
        body,
      }),
    }),
    getAllTeamsByCompanyId: builder.query({
      query: ({ companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getAllTeamsByCompanyId}?companyId=${companyId}`;
      },
    }),
    updateTimeZone: builder.query({
      query: ({ companyId, teamId, timezone }) => {
        return `${URLS_BUSINESS_SERVICE.organization.updateTimeZone}?companyId=${companyId}&teamId=${teamId}&timezone=${timezone}`;
      },
    }),
    getContributionsAdminOps: builder.query({
      query: ({ companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getContributions}?companyId=${companyId}`;
      },
      transformResponse: tranformOutcomeResponse,
      providesTags: (result = [], error, arg) => [
        { type: 'AdminOps', id: 'getContributionsAdminOps' },
      ],
    }),
    getContributionForNotification: builder.query({
      query: ({ companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getContributionForNotification}?companyId=${companyId}`;
      },
    }),
    updateReports: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.organization.updateReports,
        method: 'POST',
        body,
      }),
    }),
    getAllUserPreferences: builder.query({
      query: ({ companyId }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getAllUserPreferences}?companyId=${companyId}`;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'AdminOps', id: 'getAllUserPreference' },
      ],
    }),
    updateTaskNotificationPreference: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.organization
          .updateTaskNotificationPreference,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchCompanyConfigDatabyIdQuery,
  useLazyFetchCompanyConfigDatabyIdQuery,
  useLazyUpdateUserCountQuery,
  useLazyGetAllUsersByCompanyIdQuery,
  useLazyGetAllUserDetailsByRoleByCompanyIdQuery,
  useLazyGetAllTeamsByCompanyIdQuery,
  useLazyUpdateTimeZoneQuery,
  useGetContributionsAdminOpsQuery,
  useUpdateNotificationCenterMutation,
  useGetContributionForNotificationQuery,
  useUpdateReportsMutation,
  useGetAllUserPreferencesQuery,
  useUpdateTaskNotificationPreferenceMutation,
} = extendedAdminOpsSlice;
