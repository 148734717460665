import ReactModal from 'react-modal';
import React from 'react';
import AddUsecase from './AddUsecase';
import SimilarUsecases from './SimilarUsecases';

const ManageAndEditUsecase = ({
  account,
  selectedSolution,
  moduleList,
  editUsecases,
  showEditUsecaseModal,
  handleSuccessUseCase,
  handleCancelAddUsecase,
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={showEditUsecaseModal}
      className="Modal confirm-box solution-model"
      overlayClassName="Overlay confirm-box-overlay"
    >
      <>
        <AddUsecase
          account={account}
          isEdit={true}
          modules={moduleList}
          usecase={editUsecases}
          moduleId={selectedSolution?.id}
          handleSuccess={handleSuccessUseCase}
          handleCancel={handleCancelAddUsecase}
        />

        <SimilarUsecases
          account={account}
          moduleId={selectedSolution?.id}
          handleAddModuleCancel={handleCancelAddUsecase}
        />
      </>
    </ReactModal>
  );
};

export default ManageAndEditUsecase;
