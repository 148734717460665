import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedModuleFeaturesSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchModulesFeatures: builder.query({
      query: ({ selectedTeam }) => {
        return `${
          URLS_BUSINESS_SERVICE.modulesFeatures.getAllModulesFeatures
        }?team_id=${selectedTeam ? selectedTeam?.join(',') : ''}`;
      },
      transformResponse: (responseData) => {
        const modules =
          Array.isArray(responseData?.data) &&
          responseData.data.map((item) => {
            const modulesAndFeatures = Array.isArray(item.moduleFeatures)
              ? item.moduleFeatures?.filter((feature) => {
                  return feature.label !== 'New Feature';
                })
              : item.moduleFeatures;
            return {
              ...item,
              value: item.id,
              label: item.moduleTitle,
              disabled: item.disabled,
              moduleFeatures: modulesAndFeatures,
              features: modulesAndFeatures,
            };
          });
        return modules || [];
      },
      providesTags: () => [{ type: 'Modules', id: 'AllModulesAndFeatures' }],
    }),
    addFeature: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.addFeature,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'AllModulesAndFeatures' },
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),
    updateModule: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.updateModule,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'AllModulesAndFeatures' },
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),
    updateFeature: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.updateFeature,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'AllModulesAndFeatures' },
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),
    addModulesFeatures: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.addModulesFeatures,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'AllModulesAndFeatures' },
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),
    enableDisableFeature: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.enableDisableFeature,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'AllModulesAndFeatures' },
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),
    enableDisableModule: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.enableDisableModule,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Modules', id: 'AllModulesAndFeatures' },
        { type: 'ProductGap', id: 'getProductGapModulesAndItems' },
        { type: 'ProductGap', id: 'recentlyAddedPg' },
        { type: 'ProductGap', id: 'allPg' },
      ],
    }),
    fetchModuleByModuleId: builder.query({
      query: ({ moduleId }) => {
        return `${URLS_BUSINESS_SERVICE.modulesFeatures.getModuleByModuleId}?module_id=${moduleId}`;
      },
    }),
    fetchProductGapByFeatureId: builder.query({
      query: ({ featureId }) => {
        return `${URLS_BUSINESS_SERVICE.modulesFeatures.getProductGapByFeatureId}?feature_id=${featureId}`;
      },
      providesTags: () => [{ type: 'Modules', id: 'pgByFeatureId' }],
      transformResponse: (responseData) => {
        let data = [];
        Array.isArray(responseData?.data) &&
          responseData.data.map((item) => {
            let json = {
              title: item?.title,
              // pgItems: pgItems,
              pgId: item?.id,
              disabled: item?.disabled ? item?.disabled : false,
              active_usecase: item?.data ? item?.data?.activeUsecase : null,
              total_usecase: item?.data ? item?.data?.totalUsecase : null,
              total_rating: item?.data
                ? Math.round(item?.data?.totalRating / item?.data?.totalUsecase)
                : null,
              status: item?.data
                ? item?.data?.activeUsecase === 0
                  ? 'Resolved'
                  : 'Active'
                : 'Active',
              added_by: item?.createdBy,
              jira_title: item?.jiraIssueKey,
            };
            data.push(json);
          });
        return data || [];
      },
    }),
    updateProductGapIntegration: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.modulesFeatures.updateProductGapIntegration,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Company', id: 'PgIntegration' }],
    }),
  }),
});

export const {
  useFetchModulesFeaturesQuery,
  useLazyFetchModulesFeaturesQuery,
  useAddFeatureMutation,
  useUpdateModuleMutation,
  useUpdateFeatureMutation,
  useAddModulesFeaturesMutation,
  useEnableDisableFeatureMutation,
  useEnableDisableModuleMutation,
  useLazyFetchModuleByModuleIdQuery,
  useFetchProductGapByFeatureIdQuery,
  useUpdateProductGapIntegrationMutation,
} = extendedModuleFeaturesSlice;

export const selectModulesAndItems = (responseData) => {
  const modules =
    Array.isArray(responseData?.data) &&
    responseData.data.map((item) => {
      return {
        ...item,
        pg: item.pg?.filter((feature) => {
          return feature.title !== 'New Feature';
        }),
      };
    });
  return {
    data: modules ? modules : [],
  };
};

export default extendedModuleFeaturesSlice.reducer;
