import React, { useState } from 'react';
import { DEFAULT_CRM_STAGES } from '../../Constant/FilterOperators';
import { useUpdateAccountCrmStageMutation } from '../accountsSlice';
import Dropdown from 'react-dropdown-select';
import Select from 'react-select';
import { toast } from 'react-toastify';

const CRMStageDropdown = ({
  isPresentationView,
  dealDetail,
  fetchDealDetails,
  customStyles,
  handleSuccess = () => {},
}) => {
  const [updateAccountCrmStage, { isLoading: updatingCrmStage }] =
    useUpdateAccountCrmStageMutation();

  const [selectedCrmStage, setSelectedCrmStage] = useState([
    {
      label: dealDetail?.additionalDetails?.stage,
      value: dealDetail?.additionalDetails?.stage,
    },
  ]);

  const onCrmStageChange = (option, isArray) => {
    const selectedItem = isArray ? option[0] : option;
    if (updatingCrmStage) {
      return;
    }
    if (
      !selectedItem ||
      selectedItem?.label?.toLowerCase() ===
        selectedCrmStage[0]?.label?.toLowerCase()
    ) {
      return;
    }
    const profileData = JSON.parse(localStorage.getItem('user'));
    const userId = profileData?.userId;
    const data = {
      id: selectedItem.value,
      name: selectedItem.value,
      userId,
      accountId: dealDetail?.accountId,
      dealId: dealDetail?.dealId,
      dealValue: dealDetail?.dealValue,
      presalesUser: dealDetail?.presalesUser?.displayName,
    };
    const presalesStageBeforeUpdate = selectedCrmStage;
    setSelectedCrmStage([selectedItem]);
    updateAccountCrmStage(data)
      .unwrap()
      .then((response) => {
        if (response.status) {
          handleSuccess(data, selectedItem);
          try {
            fetchDealDetails();
          } catch (error) {
            console.warn(error);
          }
        }
      })
      .catch((err) => {
        console.error('err', err);
        setSelectedCrmStage(presalesStageBeforeUpdate);
        toast.error('Something went wrong: Unable to update crm stage!');
      });
  };

  return (
    <>
      {dealDetail?.preskaleCreated ? (
        isPresentationView ? (
          <Select
            className="description--value"
            styles={customStyles}
            options={DEFAULT_CRM_STAGES}
            onChange={(data) => onCrmStageChange(data, false)}
            value={selectedCrmStage}
            menuPlacement="auto"
            isLoading={updatingCrmStage}
          />
        ) : (
          <div className="content-header">
            <Dropdown
              options={DEFAULT_CRM_STAGES}
              onChange={(data) => onCrmStageChange(data, true)}
              values={selectedCrmStage}
            />
          </div>
        )
      ) : (
        <div
          className={`${
            isPresentationView ? 'description--value' : 'content-header'
          } `}
        >
          {dealDetail?.additionalDetails?.stage}
        </div>
      )}
    </>
  );
};

export default CRMStageDropdown;
