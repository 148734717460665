export const commonStyles = {
  border: '1px solid #E2EBF3',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '38px',
  boxShadow: 'none',
  cursor: 'pointer',
  '&:hover': {
    borderColor: '#E2EBF3',
  },
};

export const customPresalesDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    ...commonStyles,
    outline: state.isFocused ? 0 : null,
    '&:focus': {
      borderColor: '#E2EBF3',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minWidth: '140px',
    fontWeight: '600',
  }),
  indicatorSeparator: () => null,
};
export const customBorderlessDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    ...commonStyles,
    border: 'none',
    outline: state.isFocused ? 0 : null,
    '&:focus': {
      borderColor: '#E2EBF3',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minWidth: '120px',
    fontWeight: '600',
  }),
  indicatorSeparator: () => null,
};

export const customPipelineViewDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    ...commonStyles,
    outline: state.isFocused ? 0 : null,
    marginRight: '10px',
    '&:focus': {
      borderColor: '#E2EBF3',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
  }),
  indicatorSeparator: () => null,
};
