import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import { BG_COLOUR } from '../../Constant/BackgroundColourForMeetings';
import { logout } from '../../Authorize/authSlice';

const calendarSliceInitialState = {
  params: {},
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: calendarSliceInitialState,
  reducers: {
    calendarEvents(state, action) {
      if (action.type === 'calendar/calendarEvents') {
        state.params['dashboard'] = action.payload;
      }
    },
    calendarEventsForAccount(state, action) {
      if (action.type === 'calendar/calendarEventsForAccount') {
        state.params[`${action.payload.accountId}-${action.payload.dealId}`] =
          action.payload;
      }
    },
    saveSelectedCalendarEvent(state, action) {
      state.selectedCalendarEvent = action.payload;
    },
    updateSelectedCalendarEvent(state, action) {
      if (
        state.selectedCalendarEvent &&
        state.selectedCalendarEvent?.id === action.payload?.id
      ) {
        state.selectedCalendarEvent = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = calendarSliceInitialState;
      return state;
    });
  },
});

export const selectAllParams = (state) => state.calendar.params;
export const selectDashboardParams = (state) =>
  state.calendar.params['dashboard'];
export const selectAccountParams = (state, key) => state.calendar.params[key];
export const selectSelectedCalendarEvent = (state) =>
  state.calendar.selectedCalendarEvent;

const calendarAdapter = createEntityAdapter();

const initialState = calendarAdapter.getInitialState();

const tranformCalendarResponse = (response, meta, arg) => {
  const responseWithBGColour = Array.isArray(response?.data)
    ? response.data.map((meeting) => {
        meeting.args = arg;
        meeting.backgroundColor = getBackgroundColor(meeting);
        return meeting;
      })
    : [];
  return calendarAdapter.setAll(initialState, responseWithBGColour);
};

const getBackgroundColor = (meeting) => {
  if (meeting.eventType === 'PERSONAL') return BG_COLOUR[meeting.eventType];
  if (meeting.eventType === 'EXTERNAL') return BG_COLOUR[meeting.eventType];
  if (meeting.eventType === 'INTERNAL' && meeting.opportunity != null)
    return BG_COLOUR[meeting.eventType];
  return BG_COLOUR['GENERAL'];
};

const updateCalendarEvents = (draft, data, dispatch, replace = false) => {
  Array.isArray(data) &&
    data.forEach((event) => {
      if (replace && draft.entities[event?.id]) {
        Object.assign(draft.entities[event?.id], event);
        dispatch(updateSelectedCalendarEvent(event));
      } else {
        const eventCopy = draft.entities[event?.id];
        if (eventCopy) {
          eventCopy.backgroundColor = getBackgroundColor(event);
          eventCopy.eventType = event.eventType;
          eventCopy.source = event.source;
          eventCopy.opportunity = event.opportunity;
          eventCopy.opportunityName = event.opportunityName;
          eventCopy.accountId = event.accountId;
          eventCopy.accountName = event.accountName;
          eventCopy.contribution = event.contribution;
        }
      }
    });
};

const handleCalEventUpdateSuccess = (params, dispatch, data) => {
  dispatch(
    extendedCalendarSlice.util.updateQueryData(
      'fetchCalendarForDashboard',
      { from: params?.from, to: params?.to },
      (draft) => {
        Array.isArray(data) &&
          updateCalendarEvents(draft, data, dispatch, true);
      }
    )
  );
  if (params?.accountId) {
    dispatch(
      extendedCalendarSlice.util.updateQueryData(
        'fetchCalendarForAccount',
        params,
        (draft) => {
          Array.isArray(data) &&
            updateCalendarEvents(draft, data, dispatch, true);
        }
      )
    );
    dispatch(
      extendedCalendarSlice.util.updateQueryData(
        'fetchWorkbookMeetings',
        params,
        (draft) => {
          Array.isArray(data) &&
            updateCalendarEvents(draft, data, dispatch, true);
        }
      )
    );
  }
};

export const extendedCalendarSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchCalendarForDashboard: builder.query({
      query: ({ from, to, created_date_from, created_date_to }) => {
        return `${URLS_BUSINESS_SERVICE.meetings.getAll}?from_date=${
          from ?? ''
        }&to_date=${to ?? ''}&created_date_from=${
          created_date_from ?? ''
        }&created_date_to=${created_date_to ?? ''}`;
      },
      transformResponse: tranformCalendarResponse,
      providesTags: (result = [], error, arg) => {
        return [{ type: 'Calendar', id: 'Calendar_Dashbaord' }];
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          cacheDataLoaded.then(() => dispatch(calendarEvents(arg)));
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
      },
    }),
    fetchCalendarForAccount: builder.query({
      query: ({
        accountId = '',
        dealId = '',
        from,
        to,
        created_date_from,
        created_date_to,
      }) =>
        `${
          URLS_BUSINESS_SERVICE.meetings.getAllByAccount
        }?account_id=${accountId}&deal_id=${dealId}&from_date=${
          from ?? ''
        }&to_date=${to ?? ''}&created_date_from=${
          created_date_from ?? ''
        }&created_date_to=${created_date_to ?? ''}`,
      transformResponse: tranformCalendarResponse,
      providesTags: (result = [], error, arg) => {
        return [
          { type: 'Calendar', id: arg?.dealId },
          { type: 'Calendar', id: 'Account-events' },
        ];
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          cacheDataLoaded.then(() => dispatch(calendarEventsForAccount(arg)));
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
      },
    }),
    saveEventListView: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.meetings.saveEventListView,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ params, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedCalendarSlice.util.updateQueryData(
            'fetchCalendarForDashboard',
            { from: params?.from, to: params?.to },
            (draft) => {
              updateCalendarEvents(draft, body?.events);
            }
          )
        );
        let patchResultAccount;
        if (params?.accountId) {
          patchResultAccount = dispatch(
            extendedCalendarSlice.util.updateQueryData(
              'fetchCalendarForAccount',
              params,
              (draft) => {
                updateCalendarEvents(draft, body?.events);
              }
            )
          );
        }
        try {
          await queryFulfilled;
          if (!params?.accountId) {
            dispatch(
              extendedCalendarSlice.util.invalidateTags([
                { type: 'Calendar', id: 'Workbook_meetings' },
                { type: 'Calendar', id: 'Account-events' },
              ])
            );
            return;
          }
          dispatch(
            extendedCalendarSlice.util.invalidateTags([
              { type: 'Calendar', id: 'Workbook_meetings' },
            ])
          );
        } catch {
          patchResult.undo();
          if (patchResultAccount?.patches?.length) {
            patchResultAccount.undo();
          }
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
          dispatch(
            extendedCalendarSlice.util.invalidateTags([
              { type: 'Calendar', id: 'Workbook_meetings' },
            ])
          );
        }
      },
    }),
    saveAdditionalEventInfo: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.meetings.saveAdditionalEvInfo,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ params, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedCalendarSlice.util.updateQueryData(
            'fetchCalendarForDashboard',
            { from: params?.from, to: params?.to },
            (draft) => {
              updateCalendarEvents(draft, [body?.events]);
            }
          )
        );
        let patchResultAccount;
        if (params?.accountId) {
          patchResultAccount = dispatch(
            extendedCalendarSlice.util.updateQueryData(
              'fetchCalendarForAccount',
              params,
              (draft) => {
                updateCalendarEvents(draft, [body?.events]);
              }
            )
          );
        }
        try {
          const { data } = await queryFulfilled;
          if (body?.checked) {
            /**
             * Invalidating the corresponding cache tags
             * to trigger a re-fetch when checked is true
             * to update the changes made to all events regardless of its id
             */
            dispatch(extendedCalendarSlice.util.invalidateTags(['Calendar']));
          } else {
            if (data?.length) {
              const dataCopy = [{ ...data[0] }];
              dataCopy[0] = {
                ...dataCopy[0],
                timeText: body?.events?.timeText,
                args: params,
              };
              handleCalEventUpdateSuccess(params, dispatch, dataCopy);
            }
          }
        } catch {
          patchResult.undo();
          if (patchResultAccount?.patches?.length) {
            patchResultAccount.undo();
          }
        }
      },
    }),
    fetchWorkbookMeetings: builder.query({
      query: ({ accountId, dealId, from, to, page, size }) => {
        return `${URLS_BUSINESS_SERVICE.meetings.getWorkbookEvents}?account_id=${accountId}&deal_id=${dealId}&from_date=${from}&to_date=${to}&page=${page}&size=${size}`;
      },
      transformResponse: tranformCalendarResponse,
      providesTags: () => {
        return [{ type: 'Calendar', id: 'Workbook_meetings' }];
      },
    }),
    fetchUserSpecificCalendar: builder.query({
      query: ({ from, to, userId, created_date_from, created_date_to }) => {
        return `${
          URLS_BUSINESS_SERVICE.meetings.getAll
        }?from_date=${from}&to_date=${to}&created_date_from=${
          created_date_from ?? ''
        }&created_date_to=${created_date_to ?? ''}&user_id=${userId}`;
      },
      transformResponse: tranformCalendarResponse,
    }),

    reSyncCalendarAutoTag: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.meetings.reSyncCalendarAutoTag;
      },
    }),

    reSyncCalendarOpportunityEvents: builder.query({
      query: ({ accountId, dealId }) => {
        return `${URLS_BUSINESS_SERVICE.meetings.reSyncOpportunityEventsAutoTag}?account_id=${accountId}&deal_id=${dealId}`;
      },
    }),
  }),
});

export const {
  useFetchCalendarForDashboardQuery,
  useFetchCalendarForAccountQuery,
  useLazyFetchCalendarForDashboardQuery,
  useLazyFetchCalendarForAccountQuery,
  useSaveEventListViewMutation,
  useSaveAdditionalEventInfoMutation,
  useLazyFetchWorkbookMeetingsQuery,
  useFetchWorkbookMeetingsQuery,
  useLazyFetchUserSpecificCalendarQuery,
  useLazyReSyncCalendarAutoTagQuery,
  useLazyReSyncCalendarOpportunityEventsQuery,
} = extendedCalendarSlice;

export const {
  calendarEvents,
  calendarEventsForAccount,
  saveSelectedCalendarEvent,
  updateSelectedCalendarEvent,
} = calendarSlice.actions;
export default calendarSlice.reducer;
