import React, { useState, useEffect, useRef } from 'react';
import { DateFormat } from '../../Utils/DayJs';
import { createMarkup } from '../../Utils/common';
import Loader from '../../common/Loader';
import { formatCount } from '../../Utils/Revenue';
import { useGetUsersQuery, selectUserMap } from '../../app/api/usersSlice';
import OpportunityList from '../ProductGapUseCases/opportunityList';
import useDataPersistance from '../../hooks/useDataPersistance';
import PageLayout from '../../Layout/PageLayout';
import { SuccessItemsRatingReadOnly } from '../../common/Rating';
import { processFilter } from '../../Utils/libraryUtils';

const SolutionUseCases = ({
  solutionUseCases,
  solutionUseCasesLoading,
  getSolutionUsecase,
  opportunities,
}) => {
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  const clearAll = useRef(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);

  const { setStorageData, removeStorageData } = useDataPersistance();

  useEffect(() => {
    if (clearAll.current) {
      // * Prevents calling the api if this is triggered soon after clicking on clear all
      return;
    }
    getSolutionUsecase(processFilter('solutions', {}));
  }, []);

  const clearAllFilters = () => {
    setSelectedOpportunity(null);
    removeStorageData('solutionUseCases');
    clearAll.current = true;
    getSolutionUsecase({}, () => (clearAll.current = false));
  };

  const filterByDeal = (dealId) => {
    if (dealId === 'All') {
      setSelectedOpportunity(null);
      setStorageData('solutionUseCases', { selectedOpportunity: undefined });
    } else {
      setSelectedOpportunity(dealId);
      setStorageData('solutionUseCases', { selectedOpportunity: dealId });
    }
  };

  return (
    <PageLayout
      contentClassname="playbook--solution-usecases"
      sidebarSize="M"
      sidebar={
        <OpportunityList
          data={opportunities}
          selectedOpportunity={selectedOpportunity}
          filterByDeal={filterByDeal}
          tab={'Solution'}
        />
      }
    >
      <div className="pg-usecases-right-panel">
        <h2 className="solution-heading flex">
          <div className="heading_level4 text-dark ">
            {' '}
            Use-cases (
            {Array.isArray(solutionUseCases?.data)
              ? selectedOpportunity
                ? solutionUseCases?.data.filter(
                    (solution) => solution.deal_id === selectedOpportunity
                  ).length
                : solutionUseCases?.data.length
              : 0}
            )
          </div>
          <div className="clear-filter" onClick={clearAllFilters}>
            {' '}
            Clear all filters{' '}
          </div>
        </h2>
        {solutionUseCasesLoading ? (
          <Loader />
        ) : (
          <div className="success-criteria-tab--solutionUseCases solution-tab">
            {Array.isArray(solutionUseCases?.data) &&
              solutionUseCases?.data.map((solution, index) => {
                const solutionItem = selectedOpportunity
                  ? solution.deal_id === selectedOpportunity
                  : true;
                return (
                  solutionItem && (
                    <div
                      key={`sol ${solution?._id}${index}`}
                      className="criteria-list"
                    >
                      <div className="flex">
                        <div className="deal-name"> {solution?.deal_name} </div>
                        <div className="pg-stats ">
                          <div className="pg-stats-item revenue pg-details">
                            {`$${formatCount(solution?.revenue)}`}
                          </div>
                          <div className="pg-details">
                            {' '}
                            {userMap[solution?.created_by] &&
                              userMap[solution?.created_by].name}{' '}
                          </div>
                          <div className="pg-details">
                            {' '}
                            {DateFormat(
                              solution?.created_date,
                              'DD MMM, YYYY'
                            )}{' '}
                          </div>
                          <div className="pg-details star-rating">
                            <span className="flex-align-center usecase-rating b-4 mr-10 pr-5">
                              <SuccessItemsRatingReadOnly
                                rating={solution?.rating}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rtf-text use-cases-comments mt-15 mb-16 "
                        dangerouslySetInnerHTML={createMarkup(
                          solution?.usecase
                        )}
                      ></div>
                      <div className="product-gap-stats flex-align-center">
                        <div className="pg-details mr-5">
                          {solution?.module_title}{' '}
                        </div>
                        <div className="usecase-features">
                          {solution?.module_features.map((feature, i) => (
                            <span
                              className="usecase-feature m-5 b-4 p-5"
                              key={`usecase-feature${i}`}
                            >
                              {feature?.feature_title}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default SolutionUseCases;
