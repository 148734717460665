import { configureStore } from '@reduxjs/toolkit';
import calendarReducer from '../features/calendar/calendarSlice';
import accountsReducer from '../Accounts/accountsSlice';
import workbookReducer from '../features/workbook/workbookSlice';
import tasksReducer from '../common/tasks/tasksSlice';
import notesReducer from '../common/notes/notesSlice';
import pipelineReducer from '../Pipeline/pipelineSlice';
import authReducer from '../Authorize/authSlice';
import productGapReducer from '../features/ProductGap/productGapSlice';
import solutionReducer from '../features/Solution/solutionSlice';
import activitiyReducer from '../features/DealPresentationView/Activity/activitySlice';
import { businessService } from './api/businessService';
import { userService } from './api/userService';
import { dataEngineeringService } from './api/dataEngineeringService';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    accounts: accountsReducer,
    activity: activitiyReducer,
    calendar: calendarReducer,
    workbook: workbookReducer,
    tasks: tasksReducer,
    notes: notesReducer,
    productGap: productGapReducer,
    solutions: solutionReducer,
    pipeline: pipelineReducer,
    [businessService.reducerPath]: businessService.reducer,
    [userService.reducerPath]: userService.reducer,
    [dataEngineeringService.reducerPath]: dataEngineeringService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      businessService.middleware,
      userService.middleware,
      dataEngineeringService.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
});
