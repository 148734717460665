import React from 'react';
import Button from '../common/Button';
import { ReactComponent as TaskSVG } from '../Icons/NewImage/task.svg';
import { ReactComponent as CalendarViewSVG } from '../Icons/NewImage/calender.svg';
// import { ReactComponent as MeetingIcon } from '../Icons/briefcase.svg';

const SideNav = ({ handleWorkbookChange, activeWorkbook }) => {
  return (
    <aside className="side_nav">
      <Button
        icon
        type="text"
        className={`btn-icon-only ${
          activeWorkbook === 'tasks' ? 'sidenav-icons active' : 'sidenav-icons'
        }`}
        onClick={() => handleWorkbookChange('tasks')}
      >
        <TaskSVG />
      </Button>
      <Button
        icon
        type="text"
        className={`btn-icon-only ${
          activeWorkbook === 'calendar_info' || activeWorkbook === 'meetings'
            ? 'sidenav-icons active'
            : 'sidenav-icons'
        }`}
        onClick={() => handleWorkbookChange('meetings')}
      >
        <CalendarViewSVG />
      </Button>
      {/* TODO: Below button to be uncommented when activities feature is brought in */}
      {/* <Button
        icon
        type="text"
        className={`btn-icon-only ${
          activeWorkbook === 'meetings'
            ? 'sidenav-icons active'
            : 'sidenav-icons'
        }`}
        onClick={() => handleWorkbookChange('meetings')}
      >
        <MeetingIcon />
      </Button> */}
    </aside>
  );
};

export default SideNav;
