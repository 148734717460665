import React from 'react';
import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedRAMSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchUtilizationReport: builder.query({
      query: ({ ownerId, fromDate, toDate, dateFilter }) => {
        return `${URLS_BUSINESS_SERVICE.reports.utilizationReport}?ownerId=${ownerId}&fromDate=${fromDate}&toDate=${toDate}&dateFilter=${dateFilter}`;
      },
    }),
    fetchResourceRequests: builder.query({
      query: ({ crmType }) => {
        return `${URLS_BUSINESS_SERVICE.customSection.getCustomFieldValuesBySection}?type=${crmType}`;
      },
    }),
    fetchDealInfoForRAM: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.accounts.dealInfoForRAM}?deal_id=${dealId}`;
      },
    }),
    fetchCustomSectionById: builder.query({
      query: ({ sectionId }) => {
        return `${URLS_BUSINESS_SERVICE.customSection.getCustomSectionById}?customSectionId=${sectionId}`;
      },
    }),
    fetchBestResource: builder.query({
      query: ({ dealId, accountId, ownerId }) => {
        return `${URLS_BUSINESS_SERVICE.reports.bestResource}?dealId=${dealId}&accountId=${accountId}&ownerId=${ownerId}`;
      },
    }),
    assignPresalesOwner: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.accounts.assignPresalesOwner,
        method: 'POST',
        body,
      }),
    }),
    fetchDealsBySalesPerson: builder.query({
      query: ({ ownerId }) => {
        return `${URLS_BUSINESS_SERVICE.accounts.getDealsBySalesPerson}?ownerId=${ownerId}`;
      },
    }),
    fetchCustomSectionByType: builder.query({
      query: ({ crmType }) => {
        return `${URLS_BUSINESS_SERVICE.customSection.getSectionByType}?type=${crmType}`;
      },
    }),
    fetchAllRequests: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.ram.getAllRequests,
        method: 'POST',
        body,
      }),
    }),
    rejectRequest: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.ram.rejectRequest,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchUtilizationReportQuery,
  useFetchResourceRequestsQuery,
  useFetchDealInfoForRAMQuery,
  useFetchCustomSectionByIdQuery,
  useFetchBestResourceQuery,
  useAssignPresalesOwnerMutation,
  useFetchDealsBySalesPersonQuery,
  useFetchCustomSectionByTypeQuery,
  useFetchAllRequestsMutation,
  useRejectRequestMutation,
} = extendedRAMSlice;

export default extendedRAMSlice.reducer;
