import React from 'react';
import ReactModal from 'react-modal';
import Button from '../common/Button';
import { useEditPresalesUserMutation } from './accountsSlice';
import { ReactComponent as ArrowRightIcon } from '../Icons/ArrowThinRight.svg';

const PresalesChangeConfirmation = ({
  showPopUp,
  setShowPopUp = () => {},
  previousPresales,
  currentPresales,
  dealId,
  presalesUserId,
  handleCancel,
  handleConfirmation,
}) => {
  const [editPresalesUser] = useEditPresalesUserMutation();

  const handlePresalesUserChange = () => {
    if (currentPresales && currentPresales.value) {
      const presalesOwnerBody = {
        dealId: dealId,
        userId: currentPresales.id,
      };
      editPresalesUser({
        body: presalesOwnerBody,
        updateTo: currentPresales,
        presalesUserId,
      })
        .unwrap()
        .then((response) => {
          response.status && handleConfirmation(currentPresales.id);
        });

      //Update with the nely changed presales in the local storage
      const tabs = JSON.parse(localStorage.getItem('dashboard_tabs'));
      let tabsData = tabs?.map((tab) => {
        if (tab.dealId === dealId) {
          tab.presalesUser = currentPresales?.label;
          tab.presalesUserId = currentPresales?.value;
        }
        return tab;
      });
      if (tabsData?.length) {
        localStorage.setItem('dashboard_tabs', JSON.stringify(tabsData));
      }

      setShowPopUp();
    }
  };

  return (
    <ReactModal
      isOpen={showPopUp}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="heading_level3">
          Confirmation for Changing Presales Owner
        </h4>
      </div>

      <div className="modal-body font-14">
        Are you sure you want to change Presales Owner?
        <div className="font-bold pt-10">
          {' '}
          {previousPresales?.displayName}
          <span className="pr-5 pl-5">
            <ArrowRightIcon />{' '}
          </span>
          {currentPresales?.label}
        </div>
      </div>

      <div className="modal-footer flex-justify-end">
        <Button type="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="ml-5"
          type="primary"
          onClick={handlePresalesUserChange}
        >
          Confirm
        </Button>
      </div>
    </ReactModal>
  );
};

export default PresalesChangeConfirmation;
