import React, { useState, useEffect } from 'react';
import Button from '../../common/Button';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import { useUpdateProductGapIntegrationMutation } from '../ModulesManager/moduleFeaturesSlice';
import { toast } from 'react-toastify';
import styles from '../../styles/features/_productIntegrations.module.scss';

export default function ManageProductIntegrations() {
  const INTEGRATIONS = [
    {
      label: 'Preskale',
      value: 'preskale',
    },
    {
      label: 'JIRA',
      value: 'jira',
    },
    {
      label: 'Product board',
      value: 'productboard',
    },
  ];
  const ISSUE_MANAGER = [
    {
      label: 'JIRA',
      value: 'jira',
    },
    {
      label: 'Zoho Bugs',
      value: 'zohobugs',
    },
  ];

  const [newRequest, setNewRequest] = useState();
  const [enhancementBugs, setEnhancementBugs] = useState();
  const [issueManager, setIssueManager] = useState('');

  const [updateIntegrations] = useUpdateProductGapIntegrationMutation();

  const { data: configCompanyResponse } = useFetchCompanyConfigDataQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return { data: data?.data };
      },
    }
  );

  useEffect(() => {
    if (configCompanyResponse) {
      setNewRequest(configCompanyResponse?.newRequestIntegration);
      setEnhancementBugs(configCompanyResponse?.enhancementBugIntegration);
      //  Companies with issue manager can only use one product gap integration.
      const integration = configCompanyResponse.productGapIntegration.filter(
        (data) => data.is_enabled
      );
      if (integration.length) {
        setIssueManager(integration[0]?.type ?? '');
      }
    }
  }, [configCompanyResponse]);

  const handleIntegrations = (e) => {
    e.preventDefault();
    updateIntegrations({
      bugIntegration: enhancementBugs,
      requestIntegration: newRequest,
      issueManagerIntegration: issueManager,
    })
      .unwrap()
      .then(() => {
        toast.success('Product Integrations Updated!');
      });
  };

  return (
    <div className={styles.product_integrations}>
      <div className={styles.title}>Manage Product Integrations</div>
      <form>
        {configCompanyResponse?.featureList?.is_product_gap_enabled && (
          <div className={`${styles.integrations} flex`}>
            <fieldset className={styles.integration}>
              <legend className="ml-25">
                <span className="text-primary text-lg text-weight-bold ml-5 mr-5">
                  New Request
                </span>
              </legend>

              {INTEGRATIONS?.map((integration, index) => (
                <div
                  key={`request-${integration?.value}`}
                  className={`ml-25 ${index === 0 ? 'mt-25' : 'mt-10'}`}
                >
                  <input
                    type="radio"
                    name="new-request"
                    checked={newRequest?.toLowerCase() === integration?.value}
                    className="mr-5"
                    value={integration?.value}
                    onChange={() => setNewRequest(integration?.value)}
                  />{' '}
                  <span className="text-weight-semi-bold text-md">
                    {integration?.label}
                  </span>
                </div>
              ))}
            </fieldset>
            <fieldset className={styles.integration}>
              <legend className="ml-25">
                <span className="text-primary text-lg text-weight-bold ml-5 mr-5">
                  Enhancement/Bugs
                </span>
              </legend>

              {INTEGRATIONS?.map((integration, index) => (
                <div
                  key={`bugs-${integration?.value}`}
                  className={`ml-25 ${index === 0 ? 'mt-25' : 'mt-10'}`}
                >
                  <input
                    type="radio"
                    name="bugs"
                    checked={
                      enhancementBugs?.toLowerCase() === integration?.value
                    }
                    className="mr-5"
                    value={integration?.value}
                    onChange={() => setEnhancementBugs(integration?.value)}
                  />{' '}
                  <span className="text-weight-semi-bold text-md">
                    {integration?.label}
                  </span>
                </div>
              ))}
            </fieldset>
          </div>
        )}
        {configCompanyResponse?.featureList?.is_issue_manager_enabled && (
          <div className={`${styles.integrations} mt-25`}>
            <fieldset className={styles.integration}>
              <legend className="ml-25">
                <span className="text-primary text-lg text-weight-bold ml-5 mr-5">
                  Issue Manager
                </span>
              </legend>

              {ISSUE_MANAGER?.map((integration, index) => (
                <div
                  key={`issue-manager-${integration?.value}`}
                  className={`ml-25 ${index === 0 ? 'mt-25' : 'mt-10'}`}
                >
                  <input
                    type="radio"
                    name="issue_manager"
                    checked={issueManager?.toLowerCase() === integration?.value}
                    className="mr-5"
                    value={integration?.value}
                    onChange={() => setIssueManager(integration?.value)}
                  />{' '}
                  <span className="text-weight-semi-bold text-md">
                    {integration?.label}
                  </span>
                </div>
              ))}
            </fieldset>
          </div>
        )}
        <div className={`mt-20 ${styles.integration_btn} float-right`}>
          <Button
            className="ml-5"
            type="primary"
            onClick={(e) => handleIntegrations(e)}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
