import React, { Suspense, useEffect, useState } from 'react';
import { Button, Drawer, Flex, Input, Space, Select, Typography } from 'antd';
import {
  getFilterKey,
  handleUseCasesSuccess,
  processFilter,
} from '../../Utils/libraryUtils';
import PageLayout from '../../Layout/PageLayout';
import OpportunityList from '../ProductGapUseCases/opportunityList';
import LibraryFilterDrawer from '../../common/filters/library/LibraryFilterDrawer';
import useDataPersistance from '../../hooks/useDataPersistance';
import DealPresentationViewDrawer from '../DealPresentationView';
import DualRingLoader from '../../common/DualRingLoader';
import PgDrawerRightPane from './PgDrawerRightPane';
import { getFilteredCount } from '../ProductGapUseCases/utils';
import { useGetProductGapUseCasesMutation } from '../ProductGap/productGapSlice';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as ProductGapSVG } from '../../Icons/product-gap.svg';
import { ReactComponent as ModulesSVG } from '../../Icons/Modules.svg';
import { ReactComponent as FeaturesSVG } from '../../Icons/features.svg';
import { ReactComponent as FilterSVG } from '../../Icons/NewImage/filter.svg';

const { Title, Text } = Typography;

const USECASE_SORT_OPTIONS = [
  {
    label: 'Date added (New to Old)',
    value: 'date-added-new-old',
  },
  {
    label: 'Date added (Old to New)',
    value: 'date-added-old-new',
  },
  {
    label: 'Revenue (High to Low)',
    value: 'revenue-high-low',
  },
  {
    label: 'Revenue (Low to High)',
    value: 'revenue-low-high',
  },
];

const ProductGapUsecaseDrawer = ({
  openDrawer,
  setOpenPgDrawer,
  selectedPg,
  setSelectedPg,
  selectedModuleFeature,
  companyConfig,
}) => {
  const [opportunitiesForPG, setOpportunitiesForPG] = useState([]);
  const [sortUseCase, setSortUseCase] = useState(USECASE_SORT_OPTIONS[0]);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [activeDeal, setActiveDeal] = useState(null);
  const { setStorageData, removeStorageData } = useDataPersistance();
  const handleClose = () => {
    setShowFilter(false);
  };
  const handleFilterToggle = () => {
    setShowFilter((prev) => !prev);
  };
  const [
    getProductGapUseCases,
    { data: productGapUseCases, isLoading: productGapUseCasesLoading },
  ] = useGetProductGapUseCasesMutation();

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  useEffect(() => {
    if (selectedPg?.pgId) {
      removeStorageData('pgUsecaseDrawer');
      getProductGapUseCases(
        processFilter('pgUsecaseDrawer', {
          productGapItems: [selectedPg?.pgId],
          moduleIdWithItems: [selectedModuleFeature?.featureId],
        })
      )
        .unwrap()
        .then(handleUseCasesSuccess('pgUsecaseDrawer', setOpportunitiesForPG));
    }
  }, [selectedPg?.pgId, selectedModuleFeature?.featureId]);

  const onSort = (sortOrder) => {
    setSortUseCase(sortOrder);
    const storedData = localStorage.getItem(getFilterKey('pgUseCasesDrawer'));
    let filtersFromLS = storedData ? JSON.parse(storedData) : {};
    filtersFromLS.sort = sortOrder;
    setStorageData('pgUsecaseDrawer', filtersFromLS);
    getProductGapUseCases({
      productGapItems: [selectedPg?.pgId],
      moduleIdWithItems: [selectedModuleFeature?.featureId],
      ...processFilter('pgUsecaseDrawer', {}),
    })
      .unwrap()
      .then(handleUseCasesSuccess('pgUsecaseDrawer', setOpportunitiesForPG));
  };

  const filterByDeal = (dealId) => {
    if (dealId === 'All') {
      setSelectedOpportunity(null);
      setStorageData('pgUsecaseDrawer', { selectedOpportunity: undefined });
    } else {
      setSelectedOpportunity(dealId);
      setStorageData('pgUsecaseDrawer', { selectedOpportunity: dealId });
    }
  };

  const onSubmit = (filters) => {
    if (selectedPg?.pgId) {
      getProductGapUseCases({
        productGapItems: [selectedPg?.pgId],
        moduleIdWithItems: [selectedModuleFeature?.featureId],
        ...processFilter('pgUsecaseDrawer', filters),
      })
        .unwrap()
        .then(handleUseCasesSuccess('pgUsecasesDrawer', setOpportunitiesForPG));
    }
  };

  const onReset = () => {
    setShowFilter(false);
    getProductGapUseCases({
      productGapItems: [selectedPg?.pgId],
      moduleIdWithItems: [selectedModuleFeature?.featureId],
    })
      .unwrap()
      .then(handleUseCasesSuccess('pgUsecasesDrawer', setOpportunitiesForPG));
  };

  const handleClosePgDrawer = () => {
    removeStorageData('pgUsecaseDrawer');
    setSelectedPg(null);
    setSearchQuery('');
    setSelectedOpportunity(null);
    setOpenPgDrawer(false);
  };

  return (
    <>
      <Drawer
        open={openDrawer && !activeDeal}
        onClose={handleClosePgDrawer}
        closable={false}
        destroyOnClose
        title={
          <Flex gap={20}>
            <Flex align="center" gap={8}>
              <ProductGapSVG />
              <Title level={4} className="mb-6-imp">
                {selectedPg?.title}
              </Title>
            </Flex>
            <Space>
              <ModulesSVG />
              <Title level={5} type="secondary" className="mb-6-imp">
                {selectedModuleFeature?.moduleTitle}
              </Title>
            </Space>
            <Space>
              {' '}
              <FeaturesSVG />{' '}
              <Title level={5} type="secondary" className="mb-6-imp">
                {selectedModuleFeature?.featureTitle}
              </Title>
            </Space>
          </Flex>
        }
        styles={{
          header: {
            borderBottom: '2px solid #d0e2f5',
            paddingLeft: '20px',
          },
          wrapper: {
            width: '75%',
          },
          body: {
            padding: 0,
            overflow: 'hidden',
          },
        }}
        extra={
          <Flex gap={20}>
            <Space>
              <Title type="secondary" level={5}>
                {' '}
                {opportunitiesForPG && opportunitiesForPG?.length
                  ? opportunitiesForPG?.length - 1
                  : 0}
              </Title>
              <Title type="secondary" level={5}>
                Opportunities
              </Title>
            </Space>
            <Space>
              <Title type="secondary" level={5}>
                {getFilteredCount(
                  productGapUseCases,
                  searchQuery,
                  selectedOpportunity
                )}
              </Title>
              <Title type="secondary" level={5}>
                Usecases
              </Title>
            </Space>
            <Button
              icon={<CloseOutlined />}
              onClick={handleClosePgDrawer}
            ></Button>
          </Flex>
        }
      >
        <Flex className="border-bottom-light-blue p-12">
          <Input
            prefix={<SearchSVG />}
            size="small"
            className="mr-30 width-20"
            value={searchQuery}
            onChange={handleSearchInputChange}
            placeholder="Search Usecase"
          />
          <Space className="ml-10">
            <Text strong className="text-dark">
              {' '}
              Sort :{' '}
            </Text>
            <Select
              options={USECASE_SORT_OPTIONS}
              value={sortUseCase}
              onChange={onSort}
            />
          </Space>
          <div className="mr-20 ml-auto">
            <Button onClick={handleFilterToggle} className="height-full b-4">
              <Flex align="center" gap={4}>
                <FilterSVG height={16} width={16} />
                <Text strong> Filters</Text>
              </Flex>
            </Button>
          </div>
        </Flex>
        <PageLayout
          contentClassname="playbook--pg-usecases"
          sidebarSize="M"
          sidebar={
            <OpportunityList
              data={opportunitiesForPG}
              selectedOpportunity={selectedOpportunity}
              filterByDeal={filterByDeal}
              tab={'ProductGapDrawer'}
            />
          }
        >
          <PgDrawerRightPane
            productGapUseCases={productGapUseCases}
            productGapUseCasesLoading={productGapUseCasesLoading}
            searchQuery={searchQuery}
            selectedOpportunity={selectedOpportunity}
            setActiveDeal={setActiveDeal}
          />
          {showFilter && (
            <LibraryFilterDrawer
              filterFor="pgUsecaseDrawer"
              showFilters={showFilter}
              onCancel={handleClose}
              onSubmit={onSubmit}
              onReset={onReset}
              view="antdDrawer"
            />
          )}
        </PageLayout>
      </Drawer>
      {activeDeal && (
        <Suspense
          fallback={
            <Drawer showDrawer={true} className="presentationViewDrawer">
              <div className="loader-container--suspense">
                <DualRingLoader dark />
              </div>
            </Drawer>
          }
        >
          <DealPresentationViewDrawer
            showPresentationView={true}
            closePresentationView={() => {
              setOpenPgDrawer(false);
              setActiveDeal('');
            }}
            dealId={activeDeal}
            refreshView={(deal) => setActiveDeal(deal.dealId)}
            iscrmAccount={companyConfig?.data?.crm}
            productGapDetailView={true}
            backToPreviousState={() => {
              setActiveDeal('');
            }}
          />
        </Suspense>
      )}
    </>
  );
};

export default ProductGapUsecaseDrawer;
