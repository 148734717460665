import { PG_USECASES_DATE_RANGE } from '../../Constant/FilterOperators';

const defaultValue = { label: 'All', value: 'ALL' };

const CATEGORY_LIST = [
  {
    label: 'Unassociated',
    value: 'UNASSOCIATED',
  },
  {
    label: 'Skipped',
    value: 'SKIPPED',
  },
  {
    label: 'Opportunity',
    value: 'OPPORTUNITY',
  },
];

const RATING_LIST = [
  {
    label: 'Requested',
    value: 1,
  },
  {
    label: 'Good to have',
    value: 2,
  },
  {
    label: 'Must have',
    value: 3,
  },
  {
    label: 'Critical',
    value: 4,
  },
  {
    label: 'Deal Breaker',
    value: 5,
  },
];

const DEFAULT_FILTER = {
  reporter: defaultValue,
  status: defaultValue,
  type: defaultValue,
  category: defaultValue,
  rating: defaultValue,
  feature: defaultValue,
  module: defaultValue,
  creator: defaultValue,
  project: defaultValue,
  createdDate: PG_USECASES_DATE_RANGE[4],
  page: 1,
  size: 300,
};

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    minWidth: '120px',
  }),
};

export { CATEGORY_LIST, RATING_LIST, DEFAULT_FILTER, SELECT_STYLES };
