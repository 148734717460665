import React, { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MultiSelect } from '../../common/MultiSelect';
import Button from '../../common/Button';
import { Option } from '../../Constant/MultiSelectStylesAndComponents';
import { getUsersLabelValuePair } from '../utils/adminopsUtils';
import {
  extendedAdminOpsSlice,
  useFetchCompanyConfigDatabyIdQuery,
  useUpdateReportsMutation,
} from '../adminOpsSlice';

const ReportsPermissions = forwardRef(
  ({ userPreferences, customStyles, companyId }, ref) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState(null);
    const [dealVelocity, setDealVelocity] = useState(null);
    const [solutionAnalytics, setSolutionAnalytics] = useState(null);
    const [productGapAnalytics, setProductGapAnalytics] = useState(null);
    const [calendarAnalytics, setCalendarAnalytics] = useState(null);
    const [managerAnalytics, setManagerAnalytics] = useState(null);

    const [updateReports] = useUpdateReportsMutation();
    const { data: configData } = useFetchCompanyConfigDatabyIdQuery(
      { companyId },
      { skip: !companyId, refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
      if (userPreferences) {
        const userLabelValuePair = getUsersLabelValuePair(userPreferences);
        setUsers(userLabelValuePair);
        const usersWithDealVelocityAccess = [];
        const usersWithPgAnalyticsAccess = [];
        const usersWithSolutionAnalyticsAccess = [];
        const usersWithCalendarAnalyticsAccess = [];
        const usersWithManagerAnalyticsAccess = [];
        userLabelValuePair?.map((data) => {
          if (data?.reports?.dealVelocityReports) {
            usersWithDealVelocityAccess.push(data);
          }
          if (data?.reports?.productGapAnalytics) {
            usersWithPgAnalyticsAccess.push(data);
          }
          if (data?.reports?.solutionAnalytics) {
            usersWithSolutionAnalyticsAccess.push(data);
          }
          if (data?.reports?.calendarAnalytics) {
            usersWithCalendarAnalyticsAccess.push(data);
          }
          if (data?.reports?.managerAnalytics) {
            usersWithManagerAnalyticsAccess.push(data);
          }
        });
        setDealVelocity(usersWithDealVelocityAccess);
        setProductGapAnalytics(usersWithPgAnalyticsAccess);
        setSolutionAnalytics(usersWithSolutionAnalyticsAccess);
        setCalendarAnalytics(usersWithCalendarAnalyticsAccess);
        setManagerAnalytics(usersWithManagerAnalyticsAccess);
      }
    }, [userPreferences]);

    const onSave = () => {
      const usersWithDealVelocityAccess = dealVelocity.map(
        (data) => data.value
      );
      const usersWithPgAnalyticsAccess = productGapAnalytics.map(
        (data) => data.value
      );
      const usersWithSolutionAnalyticsAccess = solutionAnalytics.map(
        (data) => data.value
      );
      const usersWithCalendarAnalyticsAccess = calendarAnalytics.map(
        (data) => data.value
      );
      const usersWithManagerAnalyticsAccess = managerAnalytics.map(
        (data) => data.value
      );
      const body = {
        companyId: companyId,
        usersWithDealVelocityAccess: usersWithDealVelocityAccess,
        usersWithSolutionAnalyticsAccess: usersWithSolutionAnalyticsAccess,
        usersWithPgAnalyticsAccess: usersWithPgAnalyticsAccess,
        usersWithCalendarAnalyticsAccess: usersWithCalendarAnalyticsAccess,
        usersWithManagerAnalyticsAccess: usersWithManagerAnalyticsAccess,
      };
      updateReports(body)
        .unwrap()
        .then((response) => {
          dispatch(
            extendedAdminOpsSlice.util.invalidateTags([
              { type: 'AdminOps', id: 'getAllUserPreference' },
            ])
          );
          toast.success('updated reports successfully');
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Something went wrong!');
        });
    };
    return (
      <div ref={ref}>
        <h1 className="text-weight-semi-bold text-lg font-primary">Reports</h1>
        <div className="mt-10">
          <div className="text-md-13">
            Deal Velocity
            {!configData?.data?.featureList
              ?.is_deal_velocity_reports_enabled && (
              <span className="font-red">(Disabled for this company)</span>
            )}
          </div>
          <div className="mt-10">
            <MultiSelect
              className="Dropdown-root"
              value={dealVelocity || []}
              onChange={setDealVelocity}
              options={users}
              components={{ Option }}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="text-md-13">
            Solution Analytics
            {!configData?.data?.featureList?.is_solution_analytics_enabled && (
              <span className="font-red">(Disabled for this company)</span>
            )}
          </div>
          <div className="mt-10">
            <MultiSelect
              className="Dropdown-root"
              value={solutionAnalytics || []}
              onChange={setSolutionAnalytics}
              options={users}
              components={{ Option }}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="text-md-13">
            Productgap Analytics
            {!configData?.data?.featureList
              ?.is_productgap_analytics_enabled && (
              <span className="font-red">(Disabled for this company)</span>
            )}
          </div>
          <div className="mt-10">
            <MultiSelect
              className="Dropdown-root"
              value={productGapAnalytics || []}
              onChange={setProductGapAnalytics}
              options={users}
              components={{ Option }}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="text-md-13">
            Calendar Analytics
            {!configData?.data?.featureList?.is_calendar_analytics_enabled && (
              <span className="font-red">(Disabled for this company)</span>
            )}
          </div>
          <div className="mt-10">
            <MultiSelect
              className="Dropdown-root"
              value={calendarAnalytics || []}
              onChange={setCalendarAnalytics}
              options={users}
              components={{ Option }}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="text-md-13">
            Manager Analytics
            {!configData?.data?.featureList?.is_manager_analytics_enabled && (
              <span className="font-red">(Disabled for this company)</span>
            )}
          </div>
          <div className="mt-10">
            <MultiSelect
              className="Dropdown-root"
              value={managerAnalytics || []}
              onChange={setManagerAnalytics}
              options={users}
              components={{ Option }}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="mt-10">
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
    );
  }
);

export default ReportsPermissions;
