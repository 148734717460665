import React, { useState, useMemo } from 'react';
import UseCaseHeader from '../../common/UseCaseHeader';
import AddSolution from './AddSolution';
import { saveSelectedModule, useFetchSolutionQuery } from './solutionSlice';
import SolutionUsecaseList from './SolutionUsecaseList';
import {
  selectSelectedCard,
  updateDealStage,
} from '../../Pipeline/pipelineSlice';
import { useDispatch, useSelector } from 'react-redux';

const Solution = ({ account, readOnly = false }) => {
  const dispatch = useDispatch();
  const selectedCard = useSelector(selectSelectedCard);
  const [filters, setFilters] = useState({
    modules: { all: true },
    features: { all: true },
    score: { all: true },
    searchTerm: '',
  });
  const [showAddSolutionModal, setShowAddSolutionModal] = useState(false);
  const handleAddSolution = () => {
    setShowAddSolutionModal(true);
  };
  const {
    data: { solutionList, moduleList } = { solutionList: [], moduleList: [] },
    refetch: getSolution,
  } = useFetchSolutionQuery(
    {
      dealId: account?.dealId,
    },
    {
      skip: !account?.dealId,
      selectFromResult: (response) => {
        const moduleList = useMemo(() => {
          return response?.data?.map((module) => {
            return {
              value: module.id,
              label: module.moduleTitle,
              disabled: module.disabled,
            };
          });
        }, [response?.data]);
        return {
          data: {
            solutionList: response?.data,
            moduleList: moduleList,
          },
        };
      },
    }
  );

  const onAddUseCaseSuccess = () => {
    if (selectedCard?.cardId) {
      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
        })
      );
      dispatch(saveSelectedModule([]));
    }
  };

  const onAddUseCaseCancel = (type) => {
    setShowAddSolutionModal(false);
    if (type === 'similar_usercases') {
      return;
    }
    dispatch(saveSelectedModule([]));
  };

  return (
    <div className="solution--container">
      <UseCaseHeader
        account={account}
        handleAdd={readOnly ? false : handleAddSolution}
        setFilters={setFilters}
        type="Solution"
        addComponent={
          showAddSolutionModal && (
            <AddSolution
              account={account}
              showAddSolutionModal={showAddSolutionModal}
              handleSuccess={onAddUseCaseSuccess}
              handleCancel={onAddUseCaseCancel}
            />
          )
        }
      />
      <SolutionUsecaseList
        account={account}
        list={solutionList}
        filters={filters}
        moduleList={moduleList}
        getSolution={getSolution}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Solution;
