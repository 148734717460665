import { logout, setCredentials } from '../../Authorize/authSlice';
import { userServiceBaseQuery } from '../api/userService';

export const saveLastArgsToState =
  (saveLastArgs, type) =>
  async (arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) => {
    try {
      // wait for the initial query to resolve before proceeding
      await cacheDataLoaded;
      cacheDataLoaded.then(() => dispatch(saveLastArgs({ type, value: arg })));
    } catch {
      // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
      // in which case `cacheDataLoaded` will throw
    }
    // cacheEntryRemoved will resolve when the cache subscription is no longer active
    await cacheEntryRemoved;
  };

export const generateTags =
  (type, id) =>
  (result = []) => {
    return result
      ? [
          ...Object.values(result?.entities).map(({ id }) => ({
            type,
            id,
          })),
          { type, id },
        ]
      : [{ type, id }];
  };

export const handleTokenExpired = async (
  result,
  api,
  args,
  baseQuery,
  extraOptions
) => {
  if (
    result &&
    result.msg === 'REFRESH_TOKEN_EXPIRED' &&
    result.status === 100
  ) {
    api.dispatch(logout());
    console.error('refresh-token-expired: base', result);
  }

  if (result.error && result.error.status === 401) {
    // try to get a new token
    const userDetails =
      api.getState().auth.user ?? localStorage.getItem('user');
    const refreshToken =
      api.getState().auth.refreshToken ?? localStorage.getItem('refreshToken');

    const refreshResult = await userServiceBaseQuery(
      {
        url: 'refresh',
        method: 'POST',
        body: {
          userName: userDetails?.email,
          refreshToken,
        },
      },
      api,
      extraOptions
    );

    if (
      (refreshResult.error &&
        refreshResult.error?.data?.errorMessage === 'REFRESH_TOKEN_EXPIRED') ||
      (refreshResult.data &&
        refreshResult.data?.errorMessage === 'REFRESH_TOKEN_EXPIRED')
    ) {
      api.dispatch(logout());
      console.error('refresh-token-expired', refreshResult);
    }

    if (refreshResult.data?.data) {
      // store the new token
      api.dispatch(setCredentials(refreshResult.data?.data));
      localStorage.setItem('idToken', refreshResult?.data?.data?.idToken);

      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    }
  }

  if (result.error && result.error.status === 500) {
    console.error('Something went wrong on our end!');
  }

  return result;
};
