import React from 'react';
import Button from '../../common/Button';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { ReactComponent as DeleteSVG } from '../../Icons/NewImage/delete.svg';
import styles from '../../styles/features/item.module.scss';

const Item = ({
  description = '',
  footer,
  extraActions = [],
  editable,
  handleEdit,
  handleDelete,
  className = '',
}) => {
  return (
    <div className={`${styles.presentation_item} ${className}`}>
      <section className={styles.presentation_item__content}>
        <section className={styles.presentation_item__description}>
          {description}
        </section>
        {editable && (
          <section className={styles.presentation_item__actions}>
            {extraActions &&
              extraActions?.map((action) => (
                <Button
                  key={action.key}
                  icon
                  className={`${styles.btn_icon} btn-icon-only ${
                    styles[action.className] ?? ''
                  }`}
                  type="ghost"
                  onClick={action.onClick}
                >
                  {action.icon}
                </Button>
              ))}
            {handleEdit && (
              <Button
                icon
                className={`${styles.btn_icon} btn-icon-only`}
                type="ghost"
                onClick={handleEdit}
              >
                <EditSVG />
              </Button>
            )}
            {handleDelete && (
              <Button
                icon
                className={`${styles.btn_icon} btn-icon-only`}
                type="ghost"
                onClick={handleDelete}
              >
                <DeleteSVG />
              </Button>
            )}
          </section>
        )}
      </section>
      {footer && (
        <section className={styles.presentation_item__footer}>
          {footer?.start && (
            <section className={styles.presentation_item__info}>
              {footer?.start}
            </section>
          )}
          {footer?.end && (
            <section className={styles.presentation_item__createdInfo}>
              {footer?.end}
            </section>
          )}
        </section>
      )}
    </div>
  );
};

export default Item;
