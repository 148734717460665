import React, { useEffect, useMemo, useState } from 'react';
import CrmIntegrationCardContainer from './CrmIntegrationCardContainer';
import {
  useFetchCompanyDetailsQuery,
  useLazyFetchCRMDetailsQuery,
} from '../../Company/companySlice';
import {
  useLazyDisconnectCRMQuery,
  useUpdateCrmMapperMutation,
  useFetchCrmMapperQuery,
} from './integrationsSlice';
import AgGridTooltip from '../../common/AgGridTooltip';
import { DEFAULT_FIELDS } from './constants';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridCustomSelect } from '../../features/calendar/AgGridCustomSelect';
import { ReactComponent as WarningSVG } from '../../Icons/InfoIcon.svg';
import {
  useAssociatePresalesFieldMutation,
  useFetchCrmMetadataQuery,
} from '../../app/api/dataEngineeringService';
import { CustomAgGridLoader } from '../../features/calendar/CustomAgGridLoader';
import { toast } from 'react-toastify';

export const CrmIntegrations = ({ integration }) => {
  const { data: companyInformation } = useFetchCompanyDetailsQuery();
  const [handleCrmDisconnect] = useLazyDisconnectCRMQuery();
  const [getCrmDetails] = useLazyFetchCRMDetailsQuery();
  const [crmDetails, setCrmDetails] = useState({});
  const [crmFields, setCrmFields] = useState(DEFAULT_FIELDS);
  const [primaryUserField, setPrimaryUserField] = useState({});
  const gridRef = useRef();
  const profileData = JSON.parse(localStorage.getItem('user'));
  const userId = profileData?.userId;
  const companyId = profileData?.companyId;
  const [associatePresalesField] = useAssociatePresalesFieldMutation();
  const [updateCrmMapper] = useUpdateCrmMapperMutation();
  const [errors, setErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [crmFieldsExisting, setCrmFieldsExisting] = useState(false);
  const {
    data: { crmMetadata, metaDataMap } = {
      crmMetadata: {},
      metaDataMap: {},
    },
    isLoading: metaDataLoading,
  } = useFetchCrmMetadataQuery({ companyId });
  const crmType = 'SALESFORCE';
  const {
    data: { existingCrmFields } = {
      existingCrmFields: [],
    },
  } = useFetchCrmMapperQuery({ crmType });

  const [primaryFieldValues, setPrimaryFieldValues] = useState([
    {
      label: 'Primary User Field',
      value: '',
      type: 'OPPORTUNITY',
    },
  ]);

  const opportunityFieldColumnDefs = [
    {
      field: 'label',
      minWidth: 250,
      headerName: 'Preskale Field',
      tooltipField: 'Preskale Field',
    },
    {
      field: 'value',
      headerName: 'CRM Field',
      tooltipField: 'CRM Field',
      minWidth: 80,
      editable: !existingCrmFields || existingCrmFields.length === 0,
      cellEditor: AgGridCustomSelect,
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          values: crmMetadata[params.data.type],
        };
      },
      valueSetter: (params) => {
        if (
          params.newValue.label &&
          params.oldValue !== params.newValue?.label
        ) {
          const newData = { ...params.data };
          newData.value = params.newValue.label;
          params.node.setData(newData);
          return true;
        }
      },
    },
  ];

  const primaryUserFieldColumnDefs = [
    {
      field: 'label',
      minWidth: 250,
      headerName: 'Preskale Field',
      tooltipField: 'Preskale Field',
    },
    {
      field: 'value',
      headerName: 'CRM Field',
      tooltipField: 'CRM Field',
      editable: !(primaryUserField && Object.keys(primaryUserField).length),
      cellEditor: AgGridCustomSelect,
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          values: crmMetadata[params.data.type],
        };
      },
      valueSetter: (params) => {
        if (
          params.newValue.label &&
          params.oldValue !== params.newValue?.label
        ) {
          const newData = { ...params.data };
          newData.value = params?.newValue?.label;
          params.node.setData(newData);
          return true;
        }
      },
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 75,
      suppressMovable: true,
    }),
    []
  );

  useEffect(() => {
    if (existingCrmFields?.length && Object.keys(metaDataMap).length) {
      const fieldList = [];
      existingCrmFields.forEach((field) => {
        const transformedField = { ...field };
        transformedField.value = metaDataMap[field.type][field.value];
        fieldList.push(transformedField);
      });
      setCrmFields(fieldList);
      setCrmFieldsExisting(true);
    }
  }, [existingCrmFields, metaDataMap]);

  useEffect(() => {
    const errorList = [];
    if (Object.keys(primaryUserField).length === 0) {
      const message = 'Primary User field is required';
      const includes = errorList.includes(message);
      if (!includes) {
        errorList.push(message);
      }
    }
    if (crmFields && crmFields.length) {
      crmFields.forEach((field) => {
        if (!field.value) {
          const message = `${field.label} is required`;
          const includes = errorList.includes(message);
          if (!includes) {
            errorList.push(message);
          }
        }
      });
    }
    setErrors(errorList);
  }, [primaryUserField, crmFields]);

  useEffect(() => {
    if (
      companyInformation?.data?.presalesUserField &&
      Object.keys(crmMetadata).length
    ) {
      const oppFields = crmMetadata[primaryFieldValues[0].type];
      const field = oppFields.filter((field) => {
        return field.value === companyInformation.data.presalesUserField;
      });
      if (field && field.length) {
        setPrimaryUserField({ ...field[0] });
        const primaryFieldRowData = [...primaryFieldValues];
        primaryFieldRowData[0].value = field[0].label;
        setPrimaryFieldValues(primaryFieldRowData);
      }
    }
  }, [companyInformation, crmMetadata]);

  const onCellEditingStopped = (params) => {
    if (params.newValue && params.oldValue !== params?.newValue?.label) {
      const index = crmFields.findIndex(
        (option) => option.label === params?.data?.label
      );
      if (index > -1) {
        const fields = [...crmFields];
        fields[index] = {
          label: params?.data?.label,
          value: params?.newValue?.value,
          type: params?.data?.type,
        };
        setCrmFields(fields);
      }
    }
  };

  const onPrimaryUserFieldCellEditingStopped = (params) => {
    if (params.newValue && params.oldValue !== params?.newValue?.label) {
      const field = {
        label: params?.data?.label,
        value: params?.newValue?.value,
        type: params?.data?.type,
      };
      setPrimaryUserField(field);
    }
  };

  const onCrmDisconnect = () => {
    handleCrmDisconnect()
      .unwrap()
      .then((res) => {
        handleCrmDetails();
        toast.success('Disconnected Successfully');
      })
      .catch((err) => {
        console.warn('error', err);
        toast.error('Something went wrong!');
      });
  };

  const handleCrmDetails = () => {
    getCrmDetails()
      .unwrap()
      .then((result) => {
        setCrmDetails(result);
      });
  };

  const onClick = () => {
    const transformedFields = {};
    crmFields.forEach((field) => {
      transformedFields[field.label] = field.value;
    });
    transformedFields['crmType'] = 'SALESFORCE';
    updateCrmMapper(transformedFields)
      .unwrap()
      .then(() => {
        setCrmFieldsExisting(true);
        handleAssociateField();
      });
  };

  const handleAssociateField = () => {
    const field = primaryUserField?.value;
    const body = { companyId, userId, field };
    associatePresalesField(body)
      .unwrap()
      .then((response) => {
        setSubmitted(true);
        toast.success(response.message);
      });
  };

  useEffect(() => {
    getCrmDetails()
      .unwrap()
      .then((response) => {
        setCrmDetails(response);
      });
  }, []);

  return (
    <div className="integration__wrapper">
      <CrmIntegrationCardContainer
        companyInformation={companyInformation}
        integration={integration}
        isPicklist={crmDetails.picklist}
        adminConnections={crmDetails.crmType}
        handleDisconnect={onCrmDisconnect}
        primaryFieldExisting={Object.keys(primaryUserField).length > 0}
      />
      {integration?.name === 'Salesforce' &&
      profileData.roles.includes('admin') &&
      (integration?.connection?.token?.acessToken ||
        crmDetails.crmType?.token?.acessToken) ? (
        <>
          <div className="primary-field__table">
            <div className="flex justify-space-between">
              <div className="heading">
                <h4>Preskale User Field Mapping</h4>
              </div>
              <div>
                {errors?.length
                  ? errors.map((error, index) => {
                      return (
                        <span className="font-red pr-5" key={error}>
                          {error} {index !== errors.length - 1 ? ',' : ''}
                        </span>
                      );
                    })
                  : null}
                <button
                  className={`btn btn-primary ${
                    errors.length || existingCrmFields?.length > 0 || submitted
                      ? 'disable'
                      : ''
                  }`}
                  onClick={onClick}
                  disabled={
                    errors.length || existingCrmFields?.length > 0 || submitted
                  }
                >
                  Confirm Mapping Detail
                </button>
              </div>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: '100%', width: '100%' }}
            >
              <AgGridReact
                ref={gridRef}
                domLayout={'autoHeight'}
                columnDefs={primaryUserFieldColumnDefs}
                defaultColDef={defaultColDef}
                enableCellTextSelection={true}
                rowData={primaryFieldValues}
                singleClickEdit="true"
                onCellEditingStopped={onPrimaryUserFieldCellEditingStopped}
              />
            </div>
            {!crmFieldsExisting &&
              (integration?.connection?.token?.acessToken ||
                crmDetails?.crmType?.token?.acessToken) && (
                <div className="icon-container">
                  <div className="icon">
                    <WarningSVG /> <span className="icon-note">Note</span>
                  </div>
                  <div className="warning-content">
                    <span className="font-bold">Primary User Field (PUF)</span>{' '}
                    refers to the Presales/Technical Sales Owner Field within
                    your CRM. Once a selection is made, deals associated to you
                    based on the selected field will be populated in the
                    Pipeline View. Only a Picklist or a Lookup field can be
                    associated to the PUF and the associated field can’t be
                    changed after selection.
                  </div>
                </div>
              )}
          </div>
          <div className="primary-field__table">
            <div className="heading">
              <h4>Opportunity Field Mapping</h4>
            </div>
            {metaDataLoading ? (
              <CustomAgGridLoader />
            ) : (
              <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  ref={gridRef}
                  domLayout={'autoHeight'}
                  columnDefs={opportunityFieldColumnDefs}
                  defaultColDef={defaultColDef}
                  enableCellTextSelection={true}
                  rowData={crmFields}
                  singleClickEdit="true"
                  onCellEditingStopped={onCellEditingStopped}
                />
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};
