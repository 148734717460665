import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import {
  extendedWorkbookSlice,
  saveDealList,
} from '../../features/workbook/workbookSlice';
import {
  addDays,
  getCurrentWeek,
  getDateByOperator,
  subtractDays,
} from '../../Utils/DateUtils';
import { updateTasks } from './utils';
import { saveLastArgsToState } from '../../app/utils/apiUtils';
import { logout } from '../../Authorize/authSlice';
import { extendedAccountsSlice } from '../../Accounts/accountsSlice';

const tasksSliceInitialState = {
  recentArgs: undefined,
  accountArgs: {},
  taskManagerArgs: {},
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: tasksSliceInitialState,
  reducers: {
    saveLastArgs(state, action) {
      state[action.payload.type] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = tasksSliceInitialState;
      return state;
    });
  },
});

export const { saveLastArgs } = tasksSlice.actions;

const taskAdapter = createEntityAdapter();
const initialState = taskAdapter.getInitialState();

export const extendedTaskSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchRecentTasks: builder.query({
      query: (deals) => {
        let queryString = '';
        deals &&
          deals.forEach((dealId) => {
            if (dealId) {
              queryString = queryString + dealId + ',';
            }
          });
        // Show all open tasks for today by default
        const today = getDateByOperator('TODAY');
        return `${URLS_BUSINESS_SERVICE.tasks.getRecent}?from_date=${today.fromDate}&to_date=${today.toDate}&deal_id=${queryString}`;
      },
      transformResponse: (responseData) => {
        return taskAdapter.setAll(initialState, responseData);
      },
      onCacheEntryAdded: saveLastArgsToState(saveLastArgs, 'recentArgs'),
      providesTags: (result) => {
        return result?.entities
          ? [
              ...Object.values(result?.entities).map(({ id }) => ({
                type: 'Tasks',
                id,
              })),
              { type: 'Tasks', id: 'Tasks_Recent' },
            ]
          : [{ type: 'Tasks', id: 'Tasks_Recent' }];
      },
    }),
    fetchTasksForAccount: builder.query({
      query: ({ status, dealId }) => {
        return `${URLS_BUSINESS_SERVICE.tasks.getByAccount}?deal_id=${dealId}&status=${status}`;
      },
      transformResponse: (responseData) => {
        return taskAdapter.setAll(initialState, responseData);
      },
      onCacheEntryAdded: saveLastArgsToState(saveLastArgs, 'accountArgs'),
      providesTags: (_, __, arg) => [
        { type: 'Tasks', id: 'Tasks_Account' },
        { type: 'Tasks', id: arg?.dealId },
      ],
    }),
    fetchTasksForTaskManager: builder.query({
      query: ({
        deals = [],
        status = '',
        dateRange,
        currentWeek,
        subordinate_id,
      }) => {
        let queryString = '';
        deals?.forEach((dealId) => {
          if (dealId) {
            queryString = queryString + dealId + ',';
          }
        });

        let range = dateRange;
        if (currentWeek) {
          // Ask Keerthana why we are doing this.
          let currentWeek = getCurrentWeek(new Date());
          currentWeek.from = subtractDays(7, new Date(currentWeek.from));
          currentWeek.to = addDays(7, new Date(currentWeek.to)); //to get this week, next week and last week data
          range = { from: currentWeek.from, to: currentWeek.to };
        } else {
          const getRange = getDateByOperator(dateRange);
          range = { from: getRange.fromDate, to: getRange.toDate };
        }

        return `${
          URLS_BUSINESS_SERVICE.tasks.get
        }?deal_id=${queryString}&status=${status}&from_date=${
          range?.from ?? ''
        }&to_date=${range?.to ?? ''}&subordinate_id=${subordinate_id ?? ''}`;
      },
      transformResponse: (responseData, meta, arg) => {
        if (!arg.currentWeek) {
          return taskAdapter.setAll(initialState, responseData);
        }
        const currentWeek = getCurrentWeek(new Date());
        const currentWeekTasks =
          Array.isArray(responseData) &&
          responseData.filter(
            (task) =>
              task?.dueDate > currentWeek.from && task?.dueDate < currentWeek.to
          );
        return taskAdapter.setAll(initialState, currentWeekTasks || []);
      },
      onCacheEntryAdded: saveLastArgsToState(saveLastArgs, 'taskManagerArgs'),
      providesTags: (result) => {
        return result
          ? [
              ...Object.values(result?.entities).map(({ id }) => ({
                type: 'Tasks',
                id,
              })),
              { type: 'Tasks', id: 'Tasks_TaskManager' },
            ]
          : [{ type: 'Tasks', id: 'Tasks_TaskManager' }];
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // Form dropdown containing deal names
        try {
          const { data } = await queryFulfilled;
          let responseData = data?.entities
            ? Object.values(data?.entities)
            : [];
          if (arg?.currentWeek && !arg?.deals.length) {
            let accounts = {};
            for (let task of responseData) {
              if (task?.dealId && !accounts[task.dealId]) {
                //Checking out for duplicates to form dropdown containing deal names
                const option = {};
                option.label = task.dealName;
                option.value = task.dealId;
                accounts[task.dealId] = option;
              }
            }
            dispatch(saveDealList(Object.values(accounts)));
          }
        } catch {
          // Optionally invalidate tags here on error
        }
      },
    }),

    addTask: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.tasks.add,
        method: 'POST',
        body,
      }),
      onQueryStarted: async ({ body }, { dispatch, queryFulfilled }) => {
        // * Optimistic updates
        const patchResult = dispatch(
          extendedAccountsSlice.util.updateQueryData(
            'fetchAccountSummary',
            {
              accountId: body?.accountId,
              dealId: body?.dealId,
            },
            (draft) => {
              draft.totalTasks = draft.totalTasks + 1;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Tasks', { type: 'Deals', id: 'Overview' }],
    }),
    updateTask: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.tasks.update,
        method: 'POST',
        body,
      }),
      onQueryStarted: updateTasks(),
      invalidatesTags: [{ type: 'Deals', id: 'Overview' }],
    }),
    completeTask: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.tasks.complete,
        method: 'POST',
        body,
      }),
      onQueryStarted: updateTasks('COMPLETED'),
    }),
    deleteTask: builder.query({
      query: ({ taskId, dealId }) =>
        `${URLS_BUSINESS_SERVICE.tasks.delete}?id=${taskId}&deal_id=${dealId}`,
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedAccountsSlice.util.updateQueryData(
            'fetchAccountSummary',
            {
              accountId: body?.accountId,
              dealId: body?.dealId,
            },
            (draft) => {
              draft.totalTasks = draft.totalTasks - 1;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
        dispatch(extendedTaskSlice.util.invalidateTags(['Tasks']));
        dispatch(
          extendedWorkbookSlice.util.invalidateTags([
            { type: 'Deals', id: 'Overview' },
          ])
        );
      },
    }),
  }),
});

export const {
  useFetchRecentTasksQuery,
  useFetchTasksForAccountQuery,
  useFetchTasksForTaskManagerQuery,
  useAddTaskMutation,
  useUpdateTaskMutation,
  useCompleteTaskMutation,
  useLazyDeleteTaskQuery,
  util: extendedTaskSliceUtil,
} = extendedTaskSlice;

export const selectRecentArgs = (state) => state.tasks.recentArgs;

export default tasksSlice.reducer;
