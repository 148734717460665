import React, { useState } from 'react';
import ReactModal from 'react-modal';
import Button from '../../common/Button';
import Alert from '../../common/Alert';
import { handleFormValidation } from '../../Utils/common';
import { ReactComponent as IconSVG } from '../../Icons/InfoIcon.svg';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';

const RequestRejectPopup = ({ showModal, setShowModal, ...props }) => {
  const [rejectReason, setRejectReason] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const confirmRejectRequest = () => {
    let requiredFields = {
      rejectReason,
    };
    const formValidation = handleFormValidation(requiredFields, false, null);
    if (formValidation?.isFormValid) {
      props.requestRejection(rejectReason);
    } else {
      formValidation?.formErrors && setFormErrors(formValidation?.formErrors);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFormErrors({});
  };

  return (
    <ReactModal
      isOpen={showModal}
      className="Modal confirm-box request-reject-confirmation"
      overlayClassName="Overlay confirm-box-overlay"
    >
      <div className="modal-header flex">
        <h4 className="heading_level3">Confirm Request Rejection</h4>
        <div className="ml-auto">
          <Button type="ghost" onClick={closeModal}>
            <CrossSVG />
          </Button>
        </div>
      </div>

      <div className="modal-body form">
        <div className="form-field required-field">
          <label className="label heading_level4 required-field">
            {' '}
            Rejection Notes{' '}
          </label>
          <textarea
            className="mention-wrapper"
            placeholder="Reason for Rejection"
            value={rejectReason}
            rows={4}
            onChange={(event) => {
              setRejectReason(event.target.value);
            }}
          />
          <span className="error">{formErrors['rejectReason']}</span>
        </div>

        <Alert type="info">
          <div>
            {' '}
            <IconSVG />{' '}
          </div>
          Note: The Sales Owner will be notified about the rejection along with
          the rejection notes in an email and also accessible in All Request
          Panel.
        </Alert>
      </div>

      <div className="modal-footer">
        <Button type="danger" onClick={confirmRejectRequest}>
          Proceed to Reject Request
        </Button>
      </div>
    </ReactModal>
  );
};
export default RequestRejectPopup;
