import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';
import { ReactComponent as CalendarSVG } from '../../Icons/Calendar.svg';
import { ReactComponent as SideViewSVG } from '../../Icons/OpenInRight.svg';
import { ReactComponent as DownSVG } from '../../Icons/NewImage/down.svg';
import { ReactComponent as UpSVG } from '../../Icons/NewImage/up.svg';
import Button from '../../common/Button';
import { MultiSelect } from '../../common/MultiSelect';
import Select from 'react-select';
import {
  ValueContainer,
  Option,
  customStylesForSingleValue,
  customStyles,
} from '../../Constant/MultiSelectStylesAndComponents';
import { isAdminOrManager as findIsAdminOrManager } from '../../Utils/common';
import { DateFormat } from '../../Utils/DayJs';
import { getDateByOperator } from '../../Utils/DateUtils';
import { formatCount } from '../../Utils/Revenue';
import emptyHyphen, {
  emptyHyphenForRevenue,
} from '../../_helpers/empty-hyphen';
import Loader from '../../common/Loader';
import {
  DATE_OPERATOR_OPTIONS,
  RRF_STATUS,
} from '../../Constant/FilterOperators';
import useDataPersistance from '../../hooks/useDataPersistance';
import {
  useGetUsersQuery,
  useGetAllCompanyUsersQuery,
  selectUserIdMap,
} from '../../app/api/usersSlice';
import { useFetchAllRequestsMutation } from '../../LightningDashboard/RequestAssistanceManager/RAMSlice';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import AgGridTooltip from '../../common/AgGridTooltip';
import { useFetchPipelineAccessibilityQuery } from '../../app/api/teamsSlice';

const AllRequests = () => {
  const isAdminOrManager = findIsAdminOrManager();
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const ownerId = userDetails?.userId;
  const { getStorageData, setStorageData } = useDataPersistance();

  const [showDetailedView, setShowDetailedView] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState({ label: 'All', value: 'ALL' });
  const [requestedOn, setRequestedOn] = useState({
    label: 'All',
    value: 'ALL',
  });
  const [resourceAssigned, setResourceAssigned] = useState([]);
  const [requestedBy, setRequestedBy] = useState([]);

  const [allRequests, setAllRequests] = useState(null);
  const [crmType, setCrmType] = useState('');
  const [salesOwners, setSalesOwners] = useState([]);
  const [showReason, setShowReason] = useState(false);

  const [getAllRequests, { isLoading }] = useFetchAllRequestsMutation();

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  const { data: userMapOptions } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserIdMap,
  });

  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();

  const { data: allUsers } = useGetAllCompanyUsersQuery(undefined, {
    selectFromResult: ({ data }) => {
      const options = useMemo(() => {
        let salesOwnersMap = [];
        let usersMap = {};
        if (data?.ids) {
          const userIds = data?.ids;
          userIds &&
            userIds.forEach((id) => {
              usersMap[data.entities[id]?.userName] = data.entities[id]?.name;
              if (
                (isAdminOrManager || pipelineAccessibility?.data === 'user') &&
                (data.entities[id]?.roles.includes('Sales') ||
                  data.entities[id]?.roles.includes('sales'))
              ) {
                let userStructure = {};
                userStructure.label = data.entities[id]?.name;
                userStructure.value = id;
                salesOwnersMap.push(userStructure);
              }
            });
        }
        setSalesOwners(salesOwnersMap);
        return usersMap;
      }, [data]);
      return {
        data: options ? options : [],
      };
    },
  });

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const columnDefs = [
    {
      headerName: 'S.No',
      maxWidth: 70,
      valueGetter: (params) => params?.node?.id,
      cellRenderer: (props) => {
        if (props.value !== undefined) {
          return parseInt(props?.value) + 1;
        }
      },
    },
    {
      headerName: 'Deal Name',
      field: 'dealName',
      maxWidth: 700,
      minWidth: 150,
      tooltipField: 'dealName',
      tooltipComponentParams: (props) => ({ value: props.value }),
      cellRenderer: (props) => {
        if (props) {
          return (
            <div className="table-deal-name-cell">
              <div
                className="table-deal-name"
                onClick={() => openDeatiledView(props)}
              >
                {props.value}{' '}
              </div>
              <Button
                icon
                className="table-sideViewBtn"
                type="text"
                onClick={() => openDeatiledView(props)}
              >
                <SideViewSVG />
              </Button>
            </div>
          );
        }
      },
    },
    {
      headerName: 'Account Name',
      field: 'accountName',
      maxWidth: 700,
      minWidth: 150,
    },
    {
      headerName: 'Requested by',
      field: 'requestedBy',
      cellRenderer: (props) => {
        if (props.value) {
          return allUsers[props.value] ? (
            allUsers[props.value]
          ) : (
            <div className="font-grey-500 font-bold"> Deactivated User</div>
          );
        }
      },
    },
    {
      headerName: 'Requested on',
      field: 'requestedOn',
      minWidth: 200,
      maxWidth: 200,
      cellRenderer: (props) => {
        if (props.value) {
          return DateFormat(props.value, 'DD MMM, YYYY');
        }
      },
    },
    {
      headerName: 'Assigned on',
      field: 'presalesAssignedDate',
      hide: showDetailedView,
      minWidth: 200,
      maxWidth: 200,
      cellRenderer: (props) => {
        if (props.data.presalesUser && props.value) {
          return DateFormat(props.value, 'DD MMM, YYYY');
        } else {
          return '--';
        }
      },
    },
    {
      headerName: 'Assigned to',
      field: 'presalesUser.displayName',
      cellRenderer: (props) => {
        switch (props.data.status) {
          case 'ASSIGNED':
            return <div className="font-bold"> {props.value} </div>;
          case 'REQUESTED':
            return <div className="font-grey-500 font-bold">Not Yet</div>;
          case 'REJECTED':
            return <div className="font-red font-bold">Rejected</div>;
          default:
            <div className="font-grey-500 font-bold"> Deactivated User</div>;
        }
      },
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      tooltipComponent: AgGridTooltip,
      comparator: () => 0,
      cellStyle: () => ({
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        borderRight: '1px solid #d8e4fd',
      }),
    };
  }, []);

  useEffect(() => {
    if (configFeatures) {
      setCrmType(configFeatures.data?.crm?.toUpperCase());
    }
  }, [configFeatures]);

  useEffect(() => {
    if (userMapOptions.length > 0 && resourceAssigned.length === 0) {
      setResourceAssigned(userMapOptions);
    }
  }, [userMapOptions.length]);

  useEffect(() => {
    if (salesOwners.length > 0 && requestedBy.length === 0) {
      setRequestedBy(salesOwners);
    }
  }, [salesOwners.length]);

  useEffect(() => {
    // Populate filters from storage
    const currentFiltersParsed = getStorageData('allRequests');
    if (currentFiltersParsed?.status) {
      setStatus(currentFiltersParsed?.status);
    }
    if (currentFiltersParsed?.requestedOn) {
      setRequestedOn(currentFiltersParsed?.requestedOn);
    }
    if (currentFiltersParsed?.resourceAssigned) {
      setResourceAssigned(currentFiltersParsed?.resourceAssigned);
    }
    if (currentFiltersParsed?.requestedBy) {
      setRequestedBy(currentFiltersParsed?.requestedBy);
    }
  }, []);

  useEffect(() => {
    if (
      crmType &&
      status &&
      requestedOn &&
      resourceAssigned?.length &&
      (requestedBy?.length || isAdminOrManager === false)
    ) {
      const filter = processFilter();
      getAllRequests(filter)
        .unwrap()
        .then((response) => {
          if (response?.data && Array.isArray(response.data)) {
            setAllRequests(response.data);
          }
        });
    }
  }, [status, requestedOn, resourceAssigned, crmType, requestedBy]);

  const processFilter = () => {
    const dateRange = getDateByOperator(requestedOn?.value);
    const salesOwnerIds =
      (isAdminOrManager || pipelineAccessibility?.data === 'user') &&
      Array.isArray(requestedBy) &&
      requestedBy?.length !== salesOwners?.length
        ? requestedBy.map((owner) => owner.value)
        : [];
    const filter = {
      crmType: crmType,
      status: status?.value,
      resource:
        Array.isArray(resourceAssigned) &&
        resourceAssigned?.length !== userMapOptions?.length
          ? resourceAssigned.map((resource) => resource.value)
          : [],
      createdDate: {
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
      },
      salesOwnerId:
        isAdminOrManager || pipelineAccessibility?.data === 'user'
          ? salesOwnerIds
          : [ownerId],
    };
    return filter;
  };

  const openDeatiledView = (rowData) => {
    setShowDetailedView(true);
    setSelectedRow(rowData?.data);
  };

  const handleResourceChange = (option) => {
    setResourceAssigned(option);
    if (option?.length === userMapOptions.length) {
      setStorageData('allRequests', { resourceAssigned: undefined });
    } else {
      setStorageData('allRequests', { resourceAssigned: option });
    }
    //When a resource is selected, automatically change the status to "Resource assigned"
    if (option && option[0]?.value && option.length !== userMapOptions.length) {
      setStatus(RRF_STATUS[2]);
      setStorageData('allRequests', { status: RRF_STATUS[2] });
    }
  };

  const handleStatusChange = (option) => {
    setStatus(option);
    setStorageData('allRequests', { status: option });
  };

  const handleDateFilterChange = (option) => {
    setRequestedOn(option);
    setStorageData('allRequests', { requestedOn: option });
  };

  const handleRequestedByChange = (option) => {
    setRequestedBy(option);
    if (option?.length === salesOwners.length) {
      setStorageData('allRequests', { requestedBy: undefined });
    } else {
      setStorageData('allRequests', { requestedBy: option });
    }
  };

  return (
    <div className="all-requests">
      <div className="filters flex">
        <div className="filter">
          <span> Status: </span>
          <Select
            options={RRF_STATUS}
            onChange={(option) => handleStatusChange(option)}
            value={status}
            styles={customStylesForSingleValue}
          />
        </div>

        <div className="filter">
          <span> Requested on: </span>
          <Select
            options={DATE_OPERATOR_OPTIONS}
            onChange={(option) => handleDateFilterChange(option)}
            value={requestedOn}
            styles={customStylesForSingleValue}
          />
        </div>

        <div className="filter">
          <span> Resource Assigned: </span>
          <MultiSelect
            options={userMapOptions}
            onChange={(option) => handleResourceChange(option)}
            value={resourceAssigned}
            components={{ ValueContainer, Option }}
            styles={customStyles}
            isSearchable={false}
          />
        </div>

        {(isAdminOrManager || pipelineAccessibility?.data === 'user') && (
          <div className="filter">
            <span> Requested By: </span>
            <MultiSelect
              options={salesOwners}
              onChange={(option) => handleRequestedByChange(option)}
              value={requestedBy}
              components={{ ValueContainer, Option }}
              styles={customStyles}
              isSearchable={false}
            />
          </div>
        )}
      </div>

      <div className="all-requests-table flex">
        {isLoading && <Loader />}
        <div className="ag-theme-alpine" style={gridStyle}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={allRequests}
            defaultColDef={defaultColDef}
            tooltipShowDelay={0}
            tooltipHideDelay={5000}
          />
        </div>

        {showDetailedView && (
          <div className="detailed-view">
            <div className="flex">
              <div className="deal-name text-primary heading_level3 pl-12">
                {selectedRow?.dealName}
              </div>
              <div className="ml-auto">
                <Button type="ghost" onClick={() => setShowDetailedView(false)}>
                  <CrossSVG />
                </Button>
              </div>
            </div>

            <div className="heading_level5 deal-name-header">
              <div> {selectedRow.accountName} </div>
              {selectedRow.presalesUser ? (
                <div className="flex gap-1">
                  <div className="tag tag-green"> Assigned </div>
                  <div> {selectedRow.owner.displayName}</div>
                  <div>
                    {' '}
                    <CalendarSVG />{' '}
                    {DateFormat(
                      selectedRow.presalesAssignedDate,
                      'DD-MM-YYYY'
                    )}{' '}
                  </div>
                </div>
              ) : selectedRow.status === 'REQUESTED' ? (
                <div className="tag tag-orange"> Request Pending </div>
              ) : (
                <div className="tag tag-dark-red"> Rejected </div>
              )}
            </div>

            {selectedRow.status === 'REJECTED' && (
              <div className="reject-reason">
                <div
                  className="heading_level4 font-red header flex"
                  onClick={() => setShowReason(!showReason)}
                >
                  Reason for Rejection
                  <div className="ml-auto">
                    {showReason ? <UpSVG /> : <DownSVG />}
                  </div>
                </div>
                {showReason && (
                  <div className="content">
                    {selectedRow.reasonForRejection}
                  </div>
                )}
              </div>
            )}

            <div className="flex-align-center pt-18">
              <div className="deal-information">
                <div className="header">Presales Owner</div>
                <div className="content">
                  {emptyHyphen(selectedRow.presalesUser?.displayName)}
                </div>
              </div>
              <div className="deal-information">
                <div className="header">Deal Value</div>
                <div className="content">
                  {selectedRow.dealValue === null
                    ? emptyHyphenForRevenue('')
                    : emptyHyphenForRevenue(formatCount(selectedRow.dealValue))}
                </div>
              </div>
              <div className="deal-information">
                <div className="header">Industry</div>
                <div className="content">
                  {emptyHyphen(selectedRow.industry)}
                </div>
              </div>
              <div className="deal-information">
                <div className="header">Region</div>
                <div className="content">{emptyHyphen(selectedRow.region)}</div>
              </div>
            </div>

            <div className="request-form-values">
              {selectedRow.formValues?.map((field, index) => (
                <div className="field" key={index}>
                  <div className="label">{field.label}:</div>
                  <div className="value">
                    {Array.isArray(field.value) ? (
                      <span>
                        {field.value.map((item) => item.value).join(', ')}
                      </span>
                    ) : (
                      <span className="value">{field.value}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllRequests;
