import React from 'react';
import { useHistory } from 'react-router-dom';
import errorIcon from './../Icons/errorIcon.svg';
import { mapRoute } from './common';

const Fallback = (landingPage) => {
  let history = useHistory();

  const navigateTo = () => {
    history.push(mapRoute(landingPage));
    history.go(0);
  };

  return (
    <>
      <div className="error-page">
        <div className="errorIcon">
          <img src={errorIcon} />
        </div>
        <div className="fallBack-heading"> Unauthorized!</div>
        <div className="fallBack-content">
          You do not have access to this page.
          {/* We are sorry for the inconvenience.  */}
        </div>
      </div>

      <div className="error-page-btn">
        <button className="btn btn-primary" onClick={navigateTo}>
          {' '}
          Go to Workspace
        </button>
        <a
          href="http://preskale.freshdesk.com"
          target="_blank"
          className="btn btn-secondary"
        >
          {' '}
          Contact Support
        </a>
      </div>
    </>
  );
};

export default Fallback;
