import React, { useState } from 'react';
import { formatCount } from '../../Utils/Revenue';
import ToolTip from '../../common/ToolTip';

const LaneHeader = ({
  totalRevenueSuccess,
  totalRevenueLoading,
  updatingAccountStage,
  ...props
}) => {
  const [showTitleTooltip, setShowTitleTooltip] = useState(false);

  const showTooltip = (e) => {
    var element = e.target;
    if (element.offsetWidth < element.scrollWidth) {
      setShowTitleTooltip(true);
      return element.innerText;
    } else {
      return '';
    }
  };
  const hideTooltip = () => {
    if (!showTitleTooltip) {
      return;
    }
    setShowTitleTooltip(false);
  };

  return (
    <header id={props?.id}>
      <div
        className={`header-text${
          (totalRevenueSuccess &&
            !props.count &&
            props.style.width !== '240px') ||
          props.style.width === '42px'
            ? ' collapsed'
            : ''
        }`}
      >
        <ToolTip text={props.header} showTooltip={showTitleTooltip}>
          <div
            className="title"
            draggable="true"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
          >
            {' '}
            {props.header}{' '}
          </div>
        </ToolTip>

        {props.loading === 'false' &&
          !totalRevenueLoading &&
          totalRevenueSuccess && (
            <div draggable="true" className="deal-count">
              ({props.count ? props.count : '0'} deals)
            </div>
          )}
      </div>
      {/* {totalRevenueSuccess && ( */}
      <div
        className={`flex header-revenue-text ${
          totalRevenueLoading || updatingAccountStage
            ? 'header-revenue-text-font-grey'
            : 'header-revenue-text-font-color-default'
        }${props.style.width === '42px' ? ' visibility-hidden' : ''}`}
      >
        {props && props.revenue && props.style.width !== '42px'
          ? formatCount(props.revenue, true)
          : '$0'}{' '}
      </div>
      {/* )} */}
    </header>
  );
};

export default LaneHeader;
