import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import dayjs from 'dayjs';

import LegendIcon from './LegendIcon';

// refer https://codesandbox.io/s/simple-line-chart-forked-1czgoz?file=/src/App.tsx for example of LineChart
//https://recharts.org/en-US/examples
const renderLegend = (props) => {
  const { payload } = props;

  return (
    <div className="legend">
      {payload.map((entry) => (
        <span key={entry.dataKey} className="flex-center">
          <LegendIcon fill={entry.color} />
          <span className="legend-content">{entry.value}</span>
        </span>
      ))}
    </div>
  );
};
const formatXAxis = (props) => {
  return dayjs(props).format('DD-MMM');
};

const LightningDashboardLineChart = (props) => {
  return (
    <ResponsiveContainer width={'100%'} height={300} debounce={50}>
      <LineChart
        data={props.data}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis dataKey="date" interval={0} tickFormatter={formatXAxis} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          contentStyle={{ background: '#172B4D', textTransform: 'capitalize' }}
          itemStyle={{ color: '#FFFFFF' }}
          labelStyle={{ color: '#FFFFFF' }}
        />
        <Legend content={renderLegend} />
        {props.dataKey &&
          Object.entries(props.dataKey).length &&
          Object.entries(props.dataKey).map((element) => {
            return (
              <Line key={element[0]} dataKey={element[0]} stroke={element[1]} />
            );
          })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LightningDashboardLineChart;
