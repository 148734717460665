import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import {
  opportunitiesColumn,
  salesTrendColumn,
  managerSalesTrendColumn,
  managerOpportunitiesColumn,
} from '../Constant/PerformanceUtilisation';
import { DATE_OPERATOR_OPTIONS } from '../Constant/FilterOperators';
import { getDateByOperator } from '../Utils/DateUtils';
import Loader from '../common/Loader';
import PerformanceUtilisationTable from './PerformanceUtilisationTable';
import { ReactComponent as ArrowLeft } from '../Icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../Icons/ArrowRight.svg';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
  getManagerOpportunities,
  getManagerSalesTrends,
} from '../_services/reports.service';
import useAxios from '../hooks/useAxios';
import {
  getIndividualReporting,
  getIndividualReportingTrends,
} from '../_services/accounts.service';

const PerformanceUtilisation = (props) => {
  const axios = useAxiosPrivate();
  const {
    response: managerOpportunities,
    loading: opportunitiesLoading,
    ...opportunitiesService
  } = useAxios(axios);
  const {
    response: managerSalesTrends,
    loading: salesTrendsLoading,
    ...salesTrendsService
  } = useAxios(axios);
  const { loading: individualTrendsLoading, ...individualTrendsService } =
    useAxios(axios);
  const { loading: individualReportingLoading, ...individualReportingService } =
    useAxios(axios);
  const [opportunities, setOpportunities] = useState([]);
  const [salesTrends, setSalesTrends] = useState([]);
  const [reportingFilter, setReportingFilter] = useState(props.reportingFilter);
  const [tabIndex, setTabIndex] = useState(0);
  const [filterUserList, setFilterUserList] = useState(props.filterUserList);
  const [selectedUserFilter, setSelectedUserFilter] = useState(
    props.selectedUserFilter
  );
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPage, setLastPage] = useState(false);

  useEffect(() => {
    let filter = _setDateFilter(reportingFilter);
    if (tabIndex === 1) {
      if (props.isAdminOrManagerOrProduct) {
        getManagerOpportunities(
          opportunitiesService,
          constructFilter(),
          (data) => isLastPage(data)
        );
      } else {
        getIndividualReporting(
          individualReportingService,
          constructDate(),
          (data) => {
            isLastPage(data.utilization);
            setOpportunities(data.utilization);
          }
        );
      }
    } else {
      if (props.isAdminOrManagerOrProduct) {
        if (
          selectedUserFilter !== 'OVERALL_TREND' &&
          selectedUserFilter !== 'ALL_USERS'
        ) {
          getSalesTrends();
        } else {
          getManagerSalesTrends(
            salesTrendsService,
            constructFilterWithoutPagniation(),
            (data) => isLastPage(data)
          );
        }
      } else {
        getSalesTrends();
      }
    }
    props.updateReportingFilter(filter);
  }, [
    reportingFilter,
    pageNumber,
    tabIndex,
    selectedUserFilter,
    props.isAdminOrManagerOrProduct,
  ]);

  const onDropdownSelect = (objectType, option) => {
    let filter = { ...reportingFilter };
    switch (objectType) {
      case 'date-range':
        filter.dateRange = option.value;
        setReportingFilter(_setDateFilter(filter));
        break;
      case 'user-filter':
        setSelectedUserFilter(option.value);
        props.updateSelectedUserFilter(option.value);
        updateUserListByUserFilter(option);
        break;
    }
    setPageNumber(1);
  };

  const updateUserListByUserFilter = (option) => {
    switch (option.value) {
      case 'ALL_USERS':
        setFilterUserList(props.userList);
        break;
      case 'OVERALL_TREND':
        setFilterUserList(props.userList);
        break;
      default:
        props.userList.map((user) => {
          if (user.id === option.value) {
            setFilterUserList([user]);
          }
        });
        break;
    }
    props.changeFilterUserList(option.value);
  };

  const _setDateFilter = (filter) => {
    const dateRange = getDateByOperator(filter.dateRange);
    filter.fromDate = dateRange.fromDate;
    filter.toDate = dateRange.toDate;
    setReportingFilter(filter);
    return filter;
  };

  const constructDate = () => {
    let filter = {
      fromDate: reportingFilter.fromDate,
      toDate: reportingFilter.toDate,
      page: pageNumber,
      size: pageSize,
    };
    return filter;
  };

  const constructFilter = () => {
    let filter = {
      fromDate: reportingFilter.fromDate,
      toDate: reportingFilter.toDate,
      userList: setupFilterUserList(),
      page: pageNumber,
      size: pageSize,
    };
    return filter;
  };

  const constructFilterWithoutPagniation = () => {
    let filter = {
      fromDate: reportingFilter.fromDate,
      toDate: reportingFilter.toDate,
      userList: setupFilterUserList(),
    };
    return filter;
  };

  const setupFilterUserList = () => {
    let userIdList = [];
    filterUserList.map((user) => {
      userIdList.push(user.id);
    });
    return userIdList;
  };

  const getSalesTrends = async () => {
    let filter;
    if (props.isAdminOrManagerOrProduct) {
      filter = constructFilter();
    } else {
      filter = constructDate();
    }
    getIndividualReportingTrends(individualTrendsService, filter, (data) => {
      isLastPage(data.report);
      setSalesTrends(data.report);
    });
  };

  const isLastPage = (data) => {
    if (data === null || data.length !== pageSize) {
      setLastPage(true);
    } else {
      setLastPage(false);
    }
  };

  const gotoPreviousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const gotoNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const tabSwitch = (index) => {
    setPageNumber(1);
    setTabIndex(index);
  };

  return (
    <div className="performance-style">
      {individualReportingLoading || individualTrendsLoading ? (
        <Loader></Loader>
      ) : (
        <div>
          <div className="header">
            <div className="heading">Performance And Utilisation</div>

            {props.isAdminOrManagerOrProduct && (
              <div className="dropdown team-header zipy-block">
                <Dropdown
                  options={props.userFilter}
                  value={selectedUserFilter}
                  onChange={onDropdownSelect.bind(this, 'user-filter')}
                />
              </div>
            )}

            <div className="date-range">Date Range</div>
            <div className="dropdown">
              <Dropdown
                options={DATE_OPERATOR_OPTIONS}
                value={reportingFilter.dateRange}
                onChange={onDropdownSelect.bind(this, 'date-range')}
              />
            </div>
          </div>

          <div className="pagination zipy-block">
            {props.isAdminOrManagerOrProduct &&
            (selectedUserFilter === 'ALL_USERS' ||
              selectedUserFilter === 'OVERALL_TREND') ? (
              <PerformanceUtilisationTable
                salesTrends={managerSalesTrends}
                opportunities={managerOpportunities}
                salesTrendsColumns={managerSalesTrendColumn}
                opportunitiesColumns={managerOpportunitiesColumn}
                tabIndex={tabIndex}
                setTabIndex={tabSwitch}
              />
            ) : props.isAdminOrManagerOrProduct &&
              selectedUserFilter != 'ALL_USERS' &&
              selectedUserFilter != 'OVERALL_TREND' ? (
              <PerformanceUtilisationTable
                salesTrends={salesTrends}
                opportunities={managerOpportunities}
                salesTrendsColumns={salesTrendColumn}
                opportunitiesColumns={managerOpportunitiesColumn}
                tabIndex={tabIndex}
                setTabIndex={tabSwitch}
              />
            ) : (
              <PerformanceUtilisationTable
                salesTrends={salesTrends}
                opportunities={opportunities}
                salesTrendsColumns={salesTrendColumn}
                opportunitiesColumns={opportunitiesColumn}
                tabIndex={tabIndex}
                setTabIndex={tabSwitch}
              />
            )}
          </div>

          <div className="pagination-parent">
            <button
              className="pagination-btn"
              onClick={gotoPreviousPage}
              disabled={pageNumber === 1}
            >
              <ArrowLeft className={pageNumber === 1 ? 'btn-disable' : ''} />
            </button>
            <div className="page-text">Page Number {pageNumber}</div>
            <button
              className="pagination-btn"
              onClick={gotoNextPage}
              disabled={lastPage}
            >
              <ArrowRight className={lastPage ? 'btn-disable' : ''} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default PerformanceUtilisation;
