import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import Button from '../../common/Button';
import { SuccessItemsRatingReadOnly } from '../../common/Rating';
import {
  saveSelectedModule,
  useLazyFetchSolutionByModuleQuery,
} from './solutionSlice';
import { ReactComponent as AddSVG } from '../../Icons/NewImage/add-green.svg';
import AddUsecase from './AddUsecase';
import { createMarkup } from '../../Utils/common';
import SimilarUsecases from './SimilarUsecases';
import { solutionState } from './solutionSlice';

const ManageUsecase = ({
  account,
  moduleId,
  showManageUsecaseModal,
  modules,
  handleAddModuleCancel,
  handleSuccessManageUseCase = () => {},
}) => {
  const { selectedModule } = useSelector(solutionState);
  const dispatch = useDispatch();
  const [showAddUsecaseModal, setShowAddUsecaseModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [editUsecases, setEditUsecases] = useState({});
  const [fromManageUsecase, setFromManageUsecase] = useState(false);

  const [fetchSolutionByModule, { data: solutionUsecases }] =
    useLazyFetchSolutionByModuleQuery({
      selectFromResult: ({ data }) => ({ data: data ? data.data : [] }),
    });

  useEffect(() => {
    selectModule();
    getSolutionByModule();
  }, []);

  const onEditSuccess = () => {
    handleSuccessManageUseCase();
    getSolutionByModule();
  };

  const selectModule = () => {
    let index = modules.findIndex((module) => module.value === moduleId);
    dispatch(saveSelectedModule([modules[index]]));
  };

  const onModuleChange = (option) => {
    dispatch(saveSelectedModule(option));
    getSolutionByModule(option);
  };

  const getSolutionByModule = (option) => {
    if (option) {
      dispatch(saveSelectedModule(option));
    }
    fetchSolutionByModule({
      dealId: account?.dealId,
      moduleId: option
        ? option[0]?.value
        : selectedModule[0]?.value
        ? selectedModule[0]?.value
        : moduleId,
    });
  };

  const editUsecase = (usecase) => {
    setIsEdit(true);
    setEditUsecases(usecase);
    setShowAddUsecaseModal(true);
  };

  const cancelPopup = () => {
    setEditUsecases({});
    setShowAddUsecaseModal(false);
    setIsEdit(false);
    setFromManageUsecase(false);
  };

  const handleAddUsecase = () => {
    setShowAddUsecaseModal(true);
    setFromManageUsecase(true);
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={showManageUsecaseModal}
      className="Modal confirm-box solution-model"
      overlayClassName="Overlay confirm-box-overlay"
    >
      {!showAddUsecaseModal ? (
        <div className="modal-left-pane">
          <div className="modal-header pg-header">
            <h4 className="modal-title-pg">Manage Solutions</h4>
          </div>
          <div className="edit-module-title mb-10">Module</div>
          <div className="module-select">
            <div className="module-list-parent vertical-scroll">
              {modules &&
                modules?.map((module, i) => (
                  <span
                    key={`module${module?.value}`}
                    className={`module m-5 b-4 p-4 pointer
                            ${
                              selectedModule.find(
                                (sModule) => sModule?.value === module?.value
                              )
                                ? 'selected'
                                : ''
                            }`}
                    onClick={() =>
                      onModuleChange([
                        { value: module?.value, label: module?.label },
                      ])
                    }
                  >
                    {module?.label}
                  </span>
                ))}
            </div>
          </div>
          <div className="module-usecases">
            {solutionUsecases &&
              solutionUsecases?.map((usecase, i) => (
                <div
                  className="module-usecase mt-15 b-4 p-10 pointer"
                  key={`edit-usecase${i}`}
                  onClick={() => editUsecase(usecase)}
                >
                  <div className="module-usecase-index">#{i + 1}</div>
                  <div
                    className="module-usecase-content mt-10"
                    dangerouslySetInnerHTML={createMarkup(usecase?.usecase)}
                  ></div>
                  <div className="flex">
                    <div className="module-usecase-features mt-5">
                      {usecase?.moduleFeatures?.map((feature, i) => (
                        <span
                          className="module-usecase-feature m-5 b-4 p-5"
                          key={`fea-usecase${i}`}
                        >
                          {feature?.label}
                        </span>
                      ))}
                    </div>
                    <span className="flex-align-center usecase-rating">
                      <SuccessItemsRatingReadOnly rating={usecase?.rating} />
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-20 flex-center add-usecases b-4">
            <Button onClick={handleAddUsecase} type="text">
              <span className="flex-align-center">
                <span className="add-icon flex">
                  <AddSVG />
                </span>
                <span className="btn-txt ml-5"> Add Use-cases </span>
              </span>
            </Button>
          </div>
        </div>
      ) : (
        <AddUsecase
          account={account}
          modules={modules}
          handleCancel={cancelPopup}
          isEdit={isEdit}
          moduleId={selectedModule.length && selectedModule[0]?.value}
          usecase={editUsecases}
          handleSuccess={isEdit ? onEditSuccess : getSolutionByModule}
          fromManageUsecase={fromManageUsecase}
        />
      )}

      <SimilarUsecases
        account={account}
        handleAddModuleCancel={handleAddModuleCancel}
      />
    </ReactModal>
  );
};

export default ManageUsecase;
