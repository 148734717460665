import React, { useEffect, useState, useRef } from 'react';
import { ProfileInfoCard } from './ProfileInfoCard';
import ProfileCard from './ProfileCard';
import { ReactComponent as LockSVG } from '../Icons/profileLock.svg';
import { ReactComponent as CalendarSVG } from '../Icons/profileCalendar.svg';
import { ReactComponent as BellSVG } from '../Icons/bell.svg';
import { ReactComponent as WorkFlowSVG } from '../Icons/workflow.svg';
import { ReactComponent as SettingsSVG } from '../Icons/NewImage/settings.svg';
import { ReactComponent as IntegrationsSVG } from '../Icons/integration.svg';
import { ReactComponent as BackSVG } from '../Icons/ArrowLeft.svg';
import Button from '../common/Button';
import ChangePassword from './ChangePassword';
import {
  useGetDetailsQuery,
  useGetUserPreferenceByIdQuery,
  useGetUserProfileDetailsQuery,
} from '../app/api/usersSlice';
import { useUpdateTaskNotificationPreferenceMutation } from '../AdminOps/adminOpsSlice';
import useDataPersistance from '../hooks/useDataPersistance';
import { toast } from 'react-toastify';
import { getConnectUrl } from '../Settings/integrations/utils';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import { useLazyDisconnectQuery } from '../app/api/dataEngineeringService';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { CrmIntegrations } from '../Settings/integrations/CrmIntegrations';
import { CRM_INTEGRATIONS } from '../Settings/integrations/constants';
import ProductIntegrations from '../Settings/integrations/ProductIntegrations';
import DualRingLoader from '../common/DualRingLoader';
import { isAdminUser } from '../Utils/common';
import PreskaleToolTip from '../common/PreskaleToolTip';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import Switch from '../common/Switch';

const ProfileRightPanel = ({
  onSidebarItemClick,
  activePage,
  setActivePage,
  selectedSection,
}) => {
  const sectionRefs = {
    my_profile: useRef(null),
    my_notification: useRef(null),
    reset_password: useRef(null),
    connect_calendar: useRef(null),
    outcome_automation: useRef(null),
    crm_integration: useRef(null),
    product_integration: useRef(null),
  };
  const location = useLocation();

  const activeComponent = useIntersectionObserver(sectionRefs, selectedSection);

  const { data: profileDetails, isLoading: profileLoading } =
    useGetUserProfileDetailsQuery();
  const [updateTaskNotification] =
    useUpdateTaskNotificationPreferenceMutation();
  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const { getStorageData } = useDataPersistance();
  const currentUser = getStorageData('user');
  const { data: userPreferenceData, isLoading: userPreferenceLoading } =
    useGetUserPreferenceByIdQuery({
      companyId: currentUser?.companyId,
      userId: currentUser?.userId,
    });

  const { data: configFeatures, isLoading: configLoading } =
    useFetchCompanyConfigDataQuery(undefined, {
      skip: !currentUser,
    });
  const [handleDisconnect] = useLazyDisconnectQuery();
  const { data: userDetails, isLoading: userLoading } = useGetDetailsQuery();
  const [calendarConnection, setCalendarConnection] = useState({});
  const [cardContent, setCardContent] = useState([]);
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const isUserAdmin = isAdminUser();

  useEffect(() => {
    if (activeComponent && !location.state?.prevActvePage) {
      onSidebarItemClick(activeComponent);
    }
    if (location.state?.prevActvePage && location.state?.section) {
      onSidebarItemClick(location.state?.section);
      sectionRefs[location.state?.section]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      history.push('/user/profile', { prevActvePage: null, section: null });
    }
  }, [activeComponent]);

  const NotificationToggleSwitch = ({ enabled }) => {
    return (
      <p className="flex-align-center mr-30">
        <span className="mr-5 checkbox-content">
          {enabled ? 'Enabled' : 'Disabled'}
        </span>
        <Switch enabled={enabled} onChange={handleCheckboxChange} round />
      </p>
    );
  };

  const RightPanelHeaderWithClose = ({ heading, handleClose }) => {
    return (
      <div className="profile-right_panel__header">
        <div className="flex-align-center">
          <Button
            onClick={handleClose}
            icon
            type="text"
            className={` btn-icon-only`}
          >
            <BackSVG />
          </Button>
          {heading}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (userPreferenceData?.data) {
      setNotificationEnabled(userPreferenceData?.data?.sendTaskNotification);
    }
  }, [userPreferenceData]);

  useEffect(() => {
    if (Object.keys(cardContent).length) {
      const contentList = [...cardContent];
      const notificationContent = contentList[0];
      notificationContent.rightSideComponent = (
        <NotificationToggleSwitch enabled={notificationEnabled} />
      );
      contentList[0] = notificationContent;
      setCardContent(contentList);
    }
  }, [notificationEnabled]);

  useEffect(() => {
    const calendarType = configFeatures?.data?.calendar;
    if (calendarType) {
      if (userDetails?.connections && Array.isArray(userDetails?.connections)) {
        userDetails.connections.forEach((connection) => {
          if (
            connection?.type === calendarType &&
            connection?.token &&
            connection?.token?.acessToken
          ) {
            setCalendarConnection(connection);
          }
        });
      }
    }
  }, [userDetails, configFeatures]);

  const handleCheckboxChange = () => {
    const body = { enableNotification: !notificationEnabled };
    updateTaskNotification(body)
      .then((response) => {
        if (response?.data?.data === false || response?.data?.data === true) {
          setNotificationEnabled(response?.data?.data);
          toast.success('Updated successfully!');
        }
      })
      .catch((error) => {
        toast.error('Update failed!');
        console.error('error:', error);
      });
  };

  const onDisconnect = (integration) => {
    handleDisconnect({ integration })
      .unwrap()
      .then((res) => {
        if (
          res?.msg ===
          'Exception occured while Disconnecting..but token removed from DB'
        ) {
          toast.warn(res?.msg);
          return;
        }
        setCalendarConnection({});
        toast.success(res?.msg);
      })
      .catch((err) => {
        console.warn('error', err);
        toast.error('Something went wrong!');
      });
  };

  const content = [
    {
      title: 'My Notification',
      id: 'my_notification',
      type: 'task_notification',
      label: 'Task Notification',
      subText: 'Get an Daily Reminder on the Open and Overdue Tasks',
      icon: <BellSVG />,
      rightSideComponent: (
        <NotificationToggleSwitch enabled={notificationEnabled} />
      ),
    },
    {
      title: 'Password',
      type: 'password',
      id: 'reset_password',
      label: 'Reset Password (Manual)',
      labelIcon: (
        <PreskaleToolTip toolTipMessage="New password, new you. Reset in seconds by clicking on change password." />
      ),
      labelClassName: 'password-icon',
      icon: <LockSVG />,
      rightSideComponent: (
        <button className="btn-outline btn" onClick={() => onClick('password')}>
          Change Password
        </button>
      ),
    },
    {
      title: 'Calendar',
      id: 'connect_calendar',
      type: 'calendar_connection',
      label: 'Connect My Calendar',
      subText:
        'Bring your meetings into PreSkale and automate Activity Tracking.',
      icon: <CalendarSVG />,
      rightSideComponent: (
        <Button type={'primary'} disabled>
          {' '}
          Disconnect
        </Button>
      ),
    },
    {
      title: 'Workflow Outcome Automation',
      type: 'outcome_automation',
      id: 'outcome_automation',
      label: 'Outcome Automation',
      icon: <WorkFlowSVG />,
      subText:
        'Set-up Meeting Title Tags to Personalise PreSkale Dart AI to auto-tag outcome according to your terminology.',
      rightSideComponent: (
        <button
          className="btn-outline btn flex-align-center"
          onClick={() => onClick('outcome')}
        >
          <SettingsSVG className="pr-5" />
          <span>Configure</span>
        </button>
      ),
    },
    {
      title: 'Integrations',
      type: 'integration',
      id: 'crm_integration',
      label: 'CRM Integration',
      icon: <IntegrationsSVG />,
      restrict: true,
      rightSideComponent: (
        <button
          className="btn-outline btn flex-align-center"
          onClick={() => onClick('crm_integration')}
        >
          <SettingsSVG className="pr-5" />
          <span>Configure</span>
        </button>
      ),
    },
    {
      type: 'integration',
      id: 'product_integration',
      label: 'Product Integration',
      className: 'mt-15',
      restrict: true,
      rightSideComponent: (
        <button
          className="btn-outline btn flex-align-center"
          onClick={() => onClick('product_integration')}
        >
          <SettingsSVG className="pr-5" />
          <span>Configure</span>
        </button>
      ),
    },
  ];

  useEffect(() => {
    setCardContent(content);
  }, []);

  useEffect(() => {
    if (searchParams.get('activePage')) {
      setActivePage(searchParams.get('activePage'));
    }
  }, []);

  const getButton = (connection, config) => {
    return connection?.token?.acessToken ? (
      <Button type="primary" onClick={() => onDisconnect(connection?.type)}>
        {'Disconnect'}
      </Button>
    ) : (
      <a
        href={getConnectUrl(config?.data?.calendar, userDetails)}
        role={'button'}
        target="_blank"
        rel="noopener noreferrer"
        className={'btn btn-primary pointer'}
      >
        {'Connect'}
      </a>
    );
  };

  useEffect(() => {
    if (Object.keys(cardContent).length) {
      const contentList = [...cardContent];
      const calendarContent = contentList[2];
      calendarContent.rightSideComponent = getButton(
        calendarConnection,
        configFeatures
      );
      contentList[2] = calendarContent;
      // Removing CRM Integrations card for freshworks
      // As freshworks doesnt have connect button
      if (configFeatures?.data?.crm === 'freshworks') {
        const idx = contentList.findIndex(
          (card) => card?.id === 'crm_integration'
        );
        if (idx > -1) {
          contentList.splice(idx, 1);
          //Setting icon and other metadata for productboard
          //As we remove crm integration card, we also remove its metadata
          //As product integration metadata is tied up to crm integration we are adding it
          const productCard = contentList[idx];
          productCard.icon = <IntegrationsSVG />;
          productCard.className = '';
          productCard.title = 'Integrations';
        }
      }
      setCardContent(contentList);
    }
  }, [
    calendarConnection,
    configFeatures,
    userDetails,
    Object.keys(cardContent).length,
  ]);

  const onClick = (page) => {
    setActivePage(activePage === page ? '' : page);
    history.push(`?activePage=${page}`);
  };

  const handleClose = () => {
    history.push('/user/profile');
    setActivePage('profile');
  };

  return (
    <div className="width-80">
      {configLoading ||
      userLoading ||
      userPreferenceLoading ||
      profileLoading ? (
        <div className="flex-center py-1 width-full">
          <DualRingLoader dark />
        </div>
      ) : activePage === 'crm_integration' && configFeatures?.data?.crm ? (
        <>
          <RightPanelHeaderWithClose
            heading={'CRM Integrations'}
            handleClose={handleClose}
          />
          <CrmIntegrations
            integration={CRM_INTEGRATIONS[configFeatures?.data?.crm]}
          />
        </>
      ) : activePage === 'product_integration' ? (
        <>
          <RightPanelHeaderWithClose
            heading={'Product Integrations'}
            handleClose={handleClose}
          />
          <ProductIntegrations configFeatures={configFeatures?.data} />
        </>
      ) : (
        <div className="right-panel">
          <ProfileInfoCard
            profile={profileDetails?.data}
            ref={sectionRefs.my_profile}
          />
          {cardContent?.length > 0 &&
            cardContent.map((card) =>
              card.type === 'password' && activePage === 'password' ? (
                <div
                  className="main-content"
                  key={card?.id}
                  ref={sectionRefs.reset_password}
                  id={card.id}
                >
                  <div className="content-title">
                    <LockSVG />
                    <span className="content-label">Password</span>
                  </div>
                  <div className="password-container">
                    <div className="password-header">
                      Reset Password (Manual)
                    </div>
                    <ChangePassword setActivePage={setActivePage} />
                  </div>
                </div>
              ) : (
                (!card?.restrict || isUserAdmin) && (
                  <ProfileCard
                    cardContent={card}
                    key={card?.id}
                    ref={sectionRefs[card.id]}
                  >
                    {card.rightSideComponent}
                  </ProfileCard>
                )
              )
            )}
        </div>
      )}
    </div>
  );
};
export default ProfileRightPanel;
