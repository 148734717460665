import axios from '../_helpers/axios';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import mixpanel from 'mixpanel-browser';
import { axiosBusiness } from '../_helpers/axios';
import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

// const isProduction = process.env.REACT_APP_IS_DEVELOPMENT ? false : true;

const DOMAIN_NAME =
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? 'https://appdemo.preskale.com'
    : process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'https://trial.preskale.com'
    : process.env.REACT_APP_ENV === 'APPUAT'
    ? 'https://appuat.preskale.com'
    : process.env.REACT_APP_ENV === 'GITHUB'
    ? 'https://github.preskale.com'
    : 'https://app.preskale.com';
// const DOMAIN_NAME = "https://appuat.preskale.com" ;

// const LOCAL_API_URL = 'http://localhost:9004/';

const PROD_BUSINESS_URL = DOMAIN_NAME + '/api/business/';
const PROD_USER_URL = DOMAIN_NAME + '/api/user/v1/';
// const API_TOKEN_URL = DOMAIN_NAME+"/auth/realms/preskale/protocol/openid-connect/token"
// const CREATE_USER =  DOMAIN_NAME+"/api/user/create";
// const GET_USER =  DOMAIN_NAME+"/api/user/details";
const SALESFORCE_URL =
  process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'https://test.salesforce.com'
    : 'https://login.salesforce.com';

// const DOMAIN_URL = (!isProduction) ? LOCAL_API_URL : PROD_BUSINESS_URL;
class AuthenticationService {
  userDetails = {};

  userId = '';

  DOMAIN = '';

  idToken = '';

  refreshToken = '';

  // userDetails = {
  //   "userId": "7",
  //   "userName": "vishnu",
  //   "realm": "preskale",
  //   "roles": [
  //       "ROLE_user"
  //   ],
  //   "email": "vishnu@preskale.com",
  //   "authenticated": true
  // };

  constructor() {
    this.userDetails = this.getSessionData();

    if (this.userDetails) {
      this.userDetails.isAdminOrManager =
        this.userDetails.roles &&
        (this.userDetails.roles.includes('admin') ||
          this.userDetails.roles.includes('manager'));
    }

    this.userId = this.userDetails ? this.userDetails.userId : '';

    this.idToken = !this.getTokenData() ? '' : this.getTokenData();

    this.refreshToken = !this.getRefreshToken() ? '' : this.getRefreshToken();

    this.DOMAIN = DOMAIN_NAME;
    this.PROD_API_URL = DOMAIN_NAME + '/api/de/';
    //this.PROD_API_URL =  PROD_BUSINESS_URL;
    this.PROD_BUSINESS_API_URL = PROD_BUSINESS_URL;
    this.PROD_USER_URL = PROD_USER_URL;
    this.PROD_URL = DOMAIN_NAME;
    this.SALESFORCE_URL = SALESFORCE_URL;
  }

  async changePassword(username, password, recipientName) {
    let requestBody = {
      userName: username,
      secret: password,
      companyId: this.userDetails.companyId,
      userId: this.userDetails.userId,
      recipientName: recipientName,
      type: 'password',
    };

    const changePasswordOptions = {
      url: 'changepassword',
      method: 'POST',
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        realm: 'preskale',
      },
    };
    return axios(changePasswordOptions);
  }

  async login(username, password, newPassword) {
    const secretKey = process.env.REACT_APP_AUTH_SECRET;

    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const initializationVector = CryptoJS.enc.Utf8.parse(
      secretKey.substring(0, 16)
    );

    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: initializationVector,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    password = encrypted;

    if (newPassword) {
      const newPasswordEncrypted = CryptoJS.AES.encrypt(newPassword, key, {
        iv: initializationVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();
      newPassword = newPasswordEncrypted;
    }

    let requestBody = {
      username,
      password,
    };

    const options = {
      method: 'POST',
      // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      headers: { 'Content-Type': 'application/json' },
      // data: qs.stringify(requestBody),
      data: requestBody,
      // url: API_TOKEN_URL
      url: 'login',
    };

    return await axios(options)
      .then(async (response) => {
        if (newPassword) {
          return await this.changePassword(username, newPassword, null);
        } else if (response.data.data.idToken) {
          localStorage.setItem('idToken', response.data.data.idToken);
          localStorage.setItem('refreshToken', response.data.data.refreshToken);
          return await this.getCurrentUser(response.data.data.idToken);
        } else {
          return {};
        }
      })
      .catch((error) => {
        if (error?.response && !error?.response?.data?.status) {
          toast.error('Username or password is invalid');
        }
      });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  register(userCredentials) {
    let data = {
      userName: userCredentials.userName,
      password: userCredentials.password,
      email: userCredentials.email,
      roles: [userCredentials.role],
      fullName: userCredentials.userName,
      companyId: userCredentials.company,
      communityEdition: userCredentials.communityEdition ?? false,
      userpoolId: userCredentials?.userpoolId,
      userpoolClientId: userCredentials?.userpoolClientId,
      userpoolClientSecret: userCredentials?.userpoolClientSecret,
    };
    const options = {
      withCredentials: true,
      url: 'create-user',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        realm: 'preskale',
      },
      data,
    };

    return axios(options)
      .then((response) => {
        let isCompanyOnMixPanel;
        try {
          isCompanyOnMixPanel = mixpanel.get_group(
            'company',
            userCredentials?.company
          );
        } catch {
          isCompanyOnMixPanel = false;
        }
        if (!isCompanyOnMixPanel && userCredentials?.company) {
          try {
            mixpanel.add_group('company', userCredentials?.company);
          } catch {
            console.error('Cannot add group to mixpanel');
          }
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error?.response?.data
            ? error?.response?.data
            : 'Unable to create user'
        );
      });
  }

  async getCurrentUser(accessToken) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        realm: 'preskale',
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': DOMAIN_NAME,
      },
      // url: GET_USER
      url: 'details',
    };

    return await axios(options)
      .then((response) => {
        this.userDetails = response.data;
        this.userId = response.data.userId;
        // Identify user
        if (response?.data?.userId) {
          try {
            mixpanel.identify(response?.data?.userId);
            mixpanel.people.set({
              $name: response?.data?.name,
              $email: response?.data?.email,
              role:
                response?.data?.roles.length > 0
                  ? response?.data?.roles[0]
                  : 'User',
              platform:
                !process.env.REACT_APP_IS_DEVELOPMENT &&
                process.env.REACT_APP_ENV === 'DEVELOPMENT'
                  ? 'AppDemo'
                  : !process.env.REACT_APP_IS_DEVELOPMENT &&
                    process.env.REACT_APP_ENV === 'SANDBOX'
                  ? 'Trial'
                  : !process.env.REACT_APP_IS_DEVELOPMENT &&
                    process.env.REACT_APP_ENV === 'PRODUCTION'
                  ? 'App'
                  : 'Other',
            });
          } catch {
            console.error('Cannot identify user.');
          }
        }
        // Getting company details to track company
        axiosBusiness
          .get(URLS_BUSINESS_SERVICE.company.getDetails, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Access-Control-Allow-Origin': DOMAIN_NAME,
            },
          })
          .then((data) => {
            let isCompanyOnMixPanel;
            try {
              isCompanyOnMixPanel = mixpanel
                .get_group('company', response?.data?.companyId)
                .set_once('Company_name', data?.data?.name);
            } catch {
              isCompanyOnMixPanel = false;
            }
            if (!isCompanyOnMixPanel && response?.data?.companyId) {
              try {
                mixpanel
                  .add_group('company', response?.data?.companyId)
                  .set_once('Company_name', data?.data?.name);
              } catch {
                console.error('Cannot add group to mixpanel.');
              }
            }
            let isCompanyAUserProp;
            try {
              isCompanyAUserProp = mixpanel.get_property('company');
            } catch {
              isCompanyAUserProp = false;
            }
            if (!isCompanyAUserProp) {
              try {
                mixpanel
                  .set_group('company', response?.data?.companyId)
                  .set_once('Company_name', data?.data?.name);
              } catch {
                console.error('Cannot set group.');
              }
            }
          })
          .catch((err) => console.log(err));
        try {
          mixpanel.track('user_login', { event: 'User logged in' });
        } catch {
          console.error('Mixpanel error.');
        }
        localStorage.setItem('user', JSON.stringify(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setUserDetails() {
    const user = this.getSessionData();
    this.userDetails = user;
    if (this.userDetails) {
      this.userDetails.isAdminOrManager =
        this.userDetails.roles &&
        (this.userDetails.roles.includes('admin') ||
          this.userDetails.roles.includes('manager'));
    }

    this.userId = user ? user.userId : '';

    this.idToken = !this.getTokenData() ? '' : this.getTokenData();

    this.refreshToken = !this.getRefreshToken() ? '' : this.getRefreshToken();
  }

  resetUserDetails() {
    this.userDetails = '';

    this.userId = '';

    this.idToken = '';

    this.refreshToken = '';
  }

  setRefreshedIdToken(token) {
    this.idToken = token;
  }

  getSessionData() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getTokenData() {
    return localStorage.getItem('idToken');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  setIdToken(idToken) {
    localStorage.setItem('idToken', idToken);
  }
}

export default new AuthenticationService();
