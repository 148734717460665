import React from 'react';
import DualRingLoader from './DualRingLoader';

const Button = ({
  children,
  type,
  icon = false,
  disabled = false,
  className = '',
  loading,
  ...props
}) => {
  return (
    <div
      className={disabled ? 'cursor-not-allowed' : ''}
      style={{ height: 'fit-content' }}
    >
      <button
        className={`
        btn ${
          type === 'primary'
            ? 'btn-primary'
            : type === 'secondary'
            ? 'btn-secondary'
            : type === 'danger'
            ? 'btn-danger'
            : type === 'ghost'
            ? 'btn-ghost'
            : type === 'text'
            ? 'btn-text'
            : type === 'link'
            ? 'btn-links'
            : type === 'link-underline'
            ? // TODO : Fix during UI revamp
              'btn-link-underline'
            : type === 'outline'
            ? 'btn-outline'
            : 'btn-default'
        } ${icon ? 'btn-icon' : ''} ${className} relative`}
        disabled={disabled}
        {...props}
      >
        <span
          className={`${loading ? 'opacity-half' : ''} ${
            icon ? ' flex-align-center' : ''
          }`}
        >
          {children}
        </span>
        {loading && (
          <div
            className="flex-center absolute width-full"
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <div className="absolute width-full">
              <DualRingLoader />
            </div>
          </div>
        )}
      </button>
    </div>
  );
};

export default Button;
