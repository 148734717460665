import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from '../../common/Button';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  getAllCustomFieldValues,
  saveCustomFieldsAPI,
} from '../../_services/common.service';
import FieldRenderer from './FieldRenderer';
import mixpanel from 'mixpanel-browser';
import { ReactComponent as CloudSVG } from '../../Icons/NewImage/cloud.svg';
import { toast } from 'react-toastify';

import { ReactComponent as TickSVG } from '../../Icons/Tick-circle.svg';
import NoContent from '../../Accounts/commons/NoContent';
import { useDispatch } from 'react-redux';
import { extendedCustomSectionSlice } from './CustomSectionSlice';
import { selectUserList, useGetUsersQuery } from '../../app/api/usersSlice';

const CustomForm = ({
  section,
  deal,
  customClassName = '',
  sort = true,
  hiddenView = false,
  crmType,
  handleSaveCustomFieldSuccess,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({});
  const axios = useAxiosPrivate();
  const getCustomFieldValuesAxios = useAxios(axios);
  const saveCustomFieldsValuesAxios = useAxios(axios);
  const [submit, setSubmit] = useState(false);
  const userDetails = localStorage.getItem('user');
  const profileData = userDetails && JSON.parse(userDetails);
  const salesOwner =
    profileData.roles.includes('sales') &&
    !profileData.roles.includes('product');

  const { data: userList } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => {
      let userDropDownList = [];
      if (data && data.entities) {
        const userIds = data.ids;
        userIds &&
          userIds.forEach((userId) => {
            const dropDownElement = {
              label: data.entities[userId]?.name,
              value: data.entities[userId]?.userName,
            };
            userDropDownList.push(dropDownElement);
          });
      }
      const result = { userIdMap: data?.entities, userDropDownList };
      return {
        data: result,
      };
    },
  });

  useEffect(() => {
    if (section) {
      if (!section.fields || !section?.fields?.length) {
        setFields([]);
        return;
      }
      getAllCustomFieldValues(
        getCustomFieldValuesAxios,
        section?.id,
        deal.dealId,
        handleCustomFieldValueSuccess
      );
      let fields = [...section.fields];
      if (sort) {
        fields = fields.sort((field1, field2) =>
          field1.sortOrder > field2.sortOrder
            ? 1
            : field2.sortOrder > field1.sortOrder
            ? -1
            : 0
        );
      }
      setFields(fields);
    }
  }, [section, deal]);

  const saveCustomFields = (form) => {
    //Handle clearing out values
    Object.keys(form).forEach(function (key) {
      //for 'number' type
      if (formInitialValues[key] === 0 && form[key] === null) {
        form[key] = 0;
      }
      //for 'text', 'textarea', 'dropdown' types
      else if (formInitialValues[key] !== '' && form[key] === '') {
        form[key] = null;
      }
      //for 'multiselect' and 'userdefineddropdown' types
      else if (
        formInitialValues[key] === '' &&
        Array.isArray(form[key]) &&
        form[key]?.length === 0
      ) {
        form[key] = '';
      }
    });

    let requestBody = {
      dealId: deal.dealId,
      accountId: deal.accountId,
      fieldValues: form,
      name: section?.name,
      sectionId: section?.id,
    };
    if (crmType) {
      requestBody.sectionType = crmType;
    }
    saveCustomFieldsAPI(
      saveCustomFieldsValuesAxios,
      requestBody,
      handleSaveCustomFieldsSuccess
    );
  };

  const handleSaveCustomFieldsSuccess = (result) => {
    if (result.message) {
      toast.error(result.message);
    } else if (result.data && result.data.length) {
      const fieldValues = { ...formInitialValues };
      result.data.forEach((element) => {
        if (
          (element.type === 'multiselect' ||
            element.type === 'userdefineddropdown') &&
          Array.isArray(element.input) &&
          element.input.length
        ) {
          const formattedData = element.input.map((value) => {
            const valueObject = { label: value, key: value, value };
            return valueObject;
          });
          fieldValues[element.label] = formattedData;
        } else {
          fieldValues[element.label] = element.input;
        }
      });
      setFormInitialValues(fieldValues);
      handleSaveCustomFieldSuccess &&
        handleSaveCustomFieldSuccess(deal?.dealId);
      toast.success('Saved Successfully');
    }
    result && setSubmit(false);
    dispatch(
      extendedCustomSectionSlice.util.invalidateTags([
        { type: 'CustomSection', id: 'allSections' },
      ])
    );
  };

  const getInitialValuesBasedOnFieldType = (type, values) => {
    switch (type) {
      case 'number':
        return 0;
      default:
        return '';
    }
  };

  const formatValuesByType = (value, type) => {
    if (type === 'multiselect' || type === 'userdefineddropdown') {
      const formattedData = value.map((value) => {
        const valueObject = { label: value, key: value, value };
        return valueObject;
      });
      return formattedData;
    }
    return value;
  };

  const handleCustomFieldValueSuccess = (result) => {
    const data = result.data;
    const initialValues = {};
    if (Array.isArray(data) && data.length) {
      const fields = section.fields;
      fields.forEach((field) => {
        const idx = data.findIndex((value) => value.fieldId === field.fieldId);
        initialValues[field.label] =
          idx > -1
            ? formatValuesByType(data[idx].input, field.type)
            : getInitialValuesBasedOnFieldType(field.type, field.values);
      });
    } else {
      const sectionFields = section.fields;
      sectionFields.forEach((field) => {
        initialValues[field.label] = getInitialValuesBasedOnFieldType(
          field.type,
          field.values
        );
      });
    }
    setFormInitialValues(initialValues);
  };

  return (
    <div className="right_pane_container">
      <div className={`content_container relative ${customClassName}`}>
        {!hiddenView && (
          <div className="content_title z-1">
            <h4> {section.name}</h4>
            {!readOnly && (
              <div className="button-container">
                <Button
                  type="primary"
                  form="customForm"
                  disabled={submit || readOnly}
                  className="custom-form-button"
                  loading={submit}
                >
                  {crmType ? 'Request Resource' : 'Save'}
                </Button>
              </div>
            )}
          </div>
        )}
        {fields && fields.length && Object.keys(formInitialValues).length ? (
          <div className="form">
            <Formik
              initialValues={formInitialValues}
              enableReinitialize={true}
              validate={(values) => {
                const errors = {};
                fields.map((field) => {
                  if (
                    field.mandatory &&
                    (!values[field.label] ||
                      (Array.isArray(values[field.label]) &&
                        values[field.label].length === 0))
                  ) {
                    errors[field.label] = 'Complete this field';
                  }
                });
                return errors;
              }}
              onSubmit={(values) => {
                setSubmit(true);
                saveCustomFields(values);
                if (!crmType) {
                  try {
                    mixpanel.track('customsection_added', {
                      event: 'Custom Section Values Saved',
                    });
                  } catch {
                    console.error('Cannot add event to mixpanel');
                  }
                }
                console.log('Save section information', values);
                if (salesOwner && crmType) {
                  // To be triggered in rrf form
                  try {
                    mixpanel.track('rrf_request_resource', {
                      event: 'RRF form resource requested',
                    });
                  } catch {
                    console.error('Cannot add event to mixpanel');
                  }
                }
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id="customForm">
                  {fields.map((field) => {
                    return (
                      <div
                        key={field.fieldId}
                        className={`form-field ${
                          field.mandatory ? 'required-field' : ''
                        }`}
                      >
                        <span className="flex">
                          <label htmlFor={field.label}> {field.label} </label>
                          {field.crmField ? (
                            <CloudSVG className="ml-10 mt-2" />
                          ) : (
                            <></>
                          )}
                        </span>
                        <div className="field-container">
                          <div className="form-control">
                            <FieldRenderer
                              disabled={readOnly}
                              field={field}
                              name={field.label}
                              userList={userList}
                              className={
                                // 'form-control' +
                                errors[field.label] && touched[field.label]
                                  ? ' is-invalid'
                                  : ''
                              }
                            />
                          </div>
                          {!hiddenView && formInitialValues[field.label] ? (
                            <TickSVG
                            // className="m-5"
                            />
                          ) : null}
                        </div>
                        <ErrorMessage
                          name={field.label}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    );
                  })}
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <NoContent message="No fields Found" className="no-content" />
        )}
      </div>
    </div>
  );
};

export default CustomForm;
