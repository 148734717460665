import React from 'react';
import {
  BrowserRouter as BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import RequireAuth from './common/RequireAuth';

import mixpanel from './_helpers/mixpanel'; // Initializing mixpanel. DO NOT REMOVE.

import PublicLayout from './Layout/PublicLayout';
import PublicRoute from './common/PublicRoute';
import AppLayout from './Layout/AppLayout';
import CallbackLayout from './Layout/CallbackLayout';

import SignUp from './SignUp/Index';
import Login from './Authorize/login';
import SalesforceLayout from './Layout/SalesforceLayout';
import './styles/main.scss';
import authenticationService from './_services/authentication.service';
import ErrorBoundary from './Pipeline/components/ErrorBoundary';

function App() {
  console.log('DOMAIN', authenticationService.DOMAIN);
  return (
    <>
      <BrowserRouter>
        <ErrorBoundary>
          <Switch>
            {/* PUBLIC ROUTES */}
            <PublicRoute path="/login" component={Login} exact />
            <PublicRoute path="/signup" component={SignUp} exact />

            <Route
              path="/createcompany"
              render={(props) => <PublicLayout {...props} />}
            />

            <Route
              path="/callback"
              render={(props) => <CallbackLayout {...props} />}
            />

            {/* PRIVATE ROUTES */}
            <RequireAuth path="/salesforce" component={<SalesforceLayout />} />
            <RequireAuth path="/" component={<AppLayout />} />
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
}

export default App;
