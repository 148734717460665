import React, { useState, useEffect, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  useAddUsersMutation,
  useChangeUserRoleMutation,
} from '../app/api/userService';
import {
  useLazyGetAllCompanyUsersQuery,
  useLazyGetUserDetailsByRoleQuery,
} from '../app/api/usersSlice';
import { AgGridReact } from 'ag-grid-react';
import Button from '../common/Button';
import ResetConfirmationModal from './ResetConfirmationModal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { ReactComponent as AddSVG } from '../Icons/NewImage/add-green.svg';
import { ReactComponent as UsersSVG } from '../Icons/users.svg';
import AddUserModal from './AddUserModal';
import CheckboxRenderer from './CheckboxRenderer';
import {
  useLazyGetAllUsersByCompanyIdQuery,
  useLazyGetAllUserDetailsByRoleByCompanyIdQuery,
} from '../AdminOps/adminOpsSlice';
import customNoRowsOverlay from '../Pipeline/utils/customNoRowsOverlay';
import agGridSelect from '../Pipeline/utils/agGridSelect';

const roles = [
  { label: 'Manager', value: 'Manager' },
  { label: 'User', value: 'User' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Product', value: 'Product' },
];

const userRoles = [{ label: 'All Users', value: 'All' }, ...roles];

export default function AddUser({ adminOps = false, companyId, status = '' }) {
  const gridRef = useRef();
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [input, setInput] = useState({
    username: '',
    email: '',
    role: '',
  });
  const [userCount, setUserCount] = useState({
    sales: 0,
    admin: 0,
    manager: 0,
    all: 0,
    user: 0,
    product: 0,
  });
  const [selectedTab, setSelectedTab] = useState('All');
  const [showReactModal, setShowReactModal] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [addUsers, { isLoading: isAdding }] = useAddUsersMutation();
  const [userList, { data: usersDataByRole }] =
    useLazyGetUserDetailsByRoleQuery();
  const [getAllCompanyUsersByRole, { data: usersDataByRoleAndId }] =
    useLazyGetAllUserDetailsByRoleByCompanyIdQuery();
  const [AllusersList, { data: allusersData }] =
    useLazyGetAllCompanyUsersQuery();
  const [getAllUsers, { data: allusersDataById }] =
    useLazyGetAllUsersByCompanyIdQuery();
  const [changeRole] = useChangeUserRoleMutation();

  const columnDefs = [
    {
      headerName: '#',
      maxWidth: 100,
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: (params) => params?.node?.id,
      cellRenderer: (props) => {
        if (props.value !== undefined) {
          return parseInt(props?.value) + 1;
        } else if (props?.value === undefined) {
          return <DualRingLoader dark />;
        }
      },
    },
    { headerName: 'User Name', field: 'name' },
    { headerName: 'Email', field: 'email' },
    {
      headerName: 'Role',
      field: 'roles',
      editable: adminOps,
      valueGetter: (params) => {
        const role = params?.data?.roles ? params?.data?.roles[0] : null;
        return role ? role?.charAt(0).toUpperCase() + role.slice(1) : null;
      },
      valueSetter: (params) => {
        if (params.oldValue !== params.newValue) {
          const newData = { ...params.data };
          newData.roles = [params?.newValue];
          params.node.setData(newData);
          return true;
        }
      },
      cellEditor: 'agSelectCellEditor',
      cellRenderer: (props) => agGridSelect(props),
      cellEditorParams: () => {
        return {
          values: roles?.map((val) => val.label),
        };
      },
      singleClickEdit: 'true',
    },
    {
      field: 'disabled',
      headerName: ' Active/InActive',
      maxWidth: 200,
      cellRenderer: CheckboxRenderer,
      cellRendererParams: {
        status: status,
      },
      hide: !adminOps,
    },
    {
      maxWidth: 200,
      cellRenderer: (params) => {
        return (
          <div
            className="config-company-resetpassword"
            onClick={() => {
              setCurrentSelectedUser(params?.data);
              setShowReactModal(true);
            }}
          >
            Reset Password
          </div>
        );
      },
    },
  ];

  const onCellEditingStopped = (params) => {
    if (params.newValue) {
      const data = {
        userId: params?.data?.id,
        email: params?.data?.email,
        companyId: params?.data?.companyId,
        roles: [params?.newValue],
      };
      changeRole(data)
        .unwrap()
        .then((response) => {
          toast.success('Changed role Successfully');
          getAllUsersData();
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Something went wrong!');
        });
    }
  };

  const getAllUsersData = () => {
    if (!adminOps) {
      AllusersList();
    } else {
      getAllUsers({
        companyId: companyId,
      });
    }
  };

  const getRowData = () => {
    if (!adminOps) {
      userList({
        role: selectedTab,
      });
    } else {
      getAllCompanyUsersByRole({
        role: selectedTab,
        companyId: companyId,
      });
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, [companyId, adminOps]);

  //should re-render , when tab is switched or a new value is being added
  useEffect(() => {
    getRowData();
  }, [selectedTab, allusersDataById?.entities, allusersData?.entities, status]);

  useEffect(() => {
    const existingUserList = adminOps
      ? allusersDataById?.entities
      : allusersData?.entities;
    if (existingUserList) {
      let data = [...Object.values(existingUserList)];
      //group roles with it's count
      const counts = data.reduce((callbackData, value) => {
        const lowerCaseValue = value?.roles && value?.roles[0]?.toLowerCase();
        callbackData[lowerCaseValue] = (callbackData[lowerCaseValue] || 0) + 1;
        return callbackData;
      }, {});
      const allUsers = data.length;
      setUserCount({
        ...counts,
        all: allUsers,
      });
    }
  }, [allusersDataById?.entities, allusersData?.entities]);

  const handleTabClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };
  const noRowsOverlayComponent = useMemo(() => {
    return customNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'No Data',
    };
  }, []);

  const handleAddPopUp = () => {
    const user = {
      username: '',
      email: '',
      role: '',
    };
    setInput(user);
    setShowAddPanel(true);
  };

  const handleOnChange = (evt) => {
    const value = evt.target.value;
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowAddPanel(false);
  };
  const handleCloseModal = () => {
    setCurrentSelectedUser(null);
    setShowReactModal(false);
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    let userList = [];
    userList.push(input);
    const data = [];
    const profileData = JSON.parse(localStorage.getItem('user'));
    const loggedInUserCompanyId = profileData?.companyId;

    // If the user account is created via adminops acc,
    // retrieve the companyId from the companyId props.
    // Otherwise, obtain the companyId from local storage.
    //(companyId from props will be undefined for admin/manager)
    userList &&
      userList.forEach((userCredentials) => {
        const userObject = {
          userName: userCredentials.username,
          password: userCredentials.password,
          email: userCredentials.email,
          fullName: userCredentials.username,
          companyId: companyId ?? loggedInUserCompanyId,
          roles: [userCredentials.role],
        };
        data.push(userObject);
      });
    const dataObject = {
      companyId: companyId ?? loggedInUserCompanyId,
      userList: data,
    };
    try {
      const response = await addUsers(dataObject).unwrap();
      if (response?.status) {
        toast.success('User Added');
        setShowAddPanel(false);
        getAllUsersData(); //refetch users data
      } else if (!response?.status) {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error('User not added!');
      setShowAddPanel(false);
    }
  };

  const handleDropDownOnChange = (option) => {
    const selectedRole = option[0]?.label;
    setInput({
      ...input,
      role: selectedRole,
    });
  };
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      suppressMovable: true,
    };
  }, []);

  return (
    <div className="user-management">
      <div className="add-users">
        <div className="popover-inline-wrapper">
          <div className="user-management-header">
            {!adminOps && (
              <div className="flex-align-center width-full col-gap ">
                <span className="users-svg">
                  <UsersSVG />
                </span>
                <span className="company-config-heading">User Management</span>
              </div>
            )}
            <div className="add-new-user flex-align-center">
              <Button
                icon
                className="flex-center"
                onClick={handleAddPopUp}
                type="text"
              >
                <span className="add-icon">
                  <AddSVG />
                </span>
              </Button>
              <span className="text-primary flex-center">Add new user</span>
            </div>
          </div>
          <div>
            <div className="config-company-user--tab">
              {userRoles.map((button) => (
                <button
                  key={button.value}
                  onClick={() => handleTabClick(button.value)}
                  className={`tab-button ${
                    selectedTab === button.value ? 'tab-active' : ''
                  }`}
                >
                  {button.label} ({userCount[button.value.toLowerCase()] || 0})
                </button>
              ))}
            </div>
            <div className="config-company-table">
              <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  ref={gridRef}
                  domLayout={'autoHeight'}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  noRowsOverlayComponent={noRowsOverlayComponent}
                  noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                  enableCellTextSelection={true}
                  rowData={
                    adminOps
                      ? usersDataByRoleAndId?.data
                      : usersDataByRole?.data
                  }
                  onCellEditingStopped={onCellEditingStopped}
                />
              </div>
            </div>
            {showReactModal && (
              <ResetConfirmationModal
                showModal={showReactModal}
                handleClose={handleCloseModal}
                user={currentSelectedUser}
              />
            )}
            {showAddPanel && (
              <AddUserModal
                isOpen={showAddPanel}
                onSubmit={handleAddItem}
                onChange={handleOnChange}
                onSelect={handleDropDownOnChange}
                onCancel={handleCancel}
                isLoading={isAdding}
                input={input}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
