import React from 'react';

const Sidebar = ({
  items,
  activeSection,
  handleClick = () => {},
  withHeader,
}) => {
  return (
    <aside className="sidebar">
      {items.map((section, sectionIndex) => (
        <div key={section.title} className="section-container">
          {withHeader ? (
            <ul className="nav-item">
              <h2 className="nav-title">{section.title}</h2>
              {section.subSections.map((item, itemIndex) => (
                <li
                  key={item.label}
                  onClick={() => handleClick(item)}
                  className={`nav-subtitle ${
                    activeSection === item.id ? 'active' : ''
                  }`}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          ) : (
            <ul className="nav-item">
              <li
                key={section.label}
                onClick={() => handleClick(section)}
                className={`nav-subtitle ${
                  activeSection === section.id ? 'active' : ''
                }`}
              >
                {section.label}
              </li>
            </ul>
          )}
        </div>
      ))}
    </aside>
  );
};

export default Sidebar;
