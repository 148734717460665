import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Typography } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLazyFetchAllTeamOutcomeTagsQuery } from '../app/api/contributionSlice';
import { isAdminOrManager as findIsAdminOrManager } from '../Utils/common';
import {
  useFetchAllUserTeamsQuery,
  useFetchPipelineAccessibilityQuery,
} from '../app/api/teamsSlice';
import OutcomeTagsTable from '../features/calendar/OutcomeTagsTable';
import PageLayout from '../Layout/PageLayout';
import Button from '../common/Button';
import { ReactComponent as SyncSVG } from '../Icons/Sync.svg';
import { useLazyReSyncCalendarAutoTagQuery } from '../features/calendar/calendarSlice';
import { toast } from 'react-toastify';
import HelpCenter from './HelpCenter';
import { ReactComponent as BackSVG } from '../Icons/ArrowLeft.svg';

const { Title } = Typography;

const OutcomeAutomation = ({ setActivePage }) => {
  const [teamMemberIds, setTeamMemberIds] = useState([]);
  const [getAllTeamMemberOutcomeTags, { data: outcomeTags }] =
    useLazyFetchAllTeamOutcomeTagsQuery(teamMemberIds);
  const isAdminOrManager = findIsAdminOrManager();
  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();
  const [userFilter, setUserFilter] = useState([]);
  const history = useHistory();

  const [reSync] = useLazyReSyncCalendarAutoTagQuery();

  const { data: teamMembers } = useFetchAllUserTeamsQuery(undefined, {
    skip: !isAdminOrManager,
    selectFromResult: ({ data }) => {
      const users = useMemo(() => {
        let teamData = [{ label: 'All Users', value: 'ALL' }];
        let userIds = [];
        setUserFilter([teamData[0]]);
        if (
          data &&
          data.status &&
          Array.isArray(data?.data) &&
          data.data.length
        ) {
          data.data.forEach((team) => {
            team.teamMembers.forEach((user) => {
              if (!userIds.includes(user.id)) {
                let userStructure = {};
                userStructure.label = user.name;
                userStructure.value = user.id;
                teamData.push(userStructure);
                userIds.push(user.id);
              }
            });
          });
          setTeamMemberIds(userIds);
        }
        return teamData;
      }, [data?.data]);
      return {
        data: users,
      };
    },
  });

  useEffect(() => {
    getAllTeamMemberOutcomeTags();
  }, []);

  const handleClose = () => {
    history.push('/user/profile');
    setActivePage('profile');
  };

  const handleCalendarAutomationSync = () => {
    reSync()
      .then((data) => {
        toast.success('Calendar automation sync started');
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Calendar automation sync failed');
      });
  };

  return (
    <PageLayout
      headerMainContent={
        <Flex
          align="center"
          className="fill-colors-primary admin-settings-subpage"
        >
          <Button
            onClick={handleClose}
            icon
            type="text"
            className=" btn-icon-only"
          >
            <BackSVG />
          </Button>
          <Title level={5} className="flex-align-center mb-0">
            Outcome Automation
          </Title>
        </Flex>
      }
      extra={[
        <div className="outcome-header">
          <Button
            onClick={handleCalendarAutomationSync}
            icon
            className="btn-sync bg-none"
          >
            <SyncSVG /> Run calendar Auto-tag
          </Button>
        </div>,
      ]}
    >
      <div className="admin-settings">
        <div className="calendar-preferences width-80">
          <OutcomeTagsTable
            outcomeData={outcomeTags?.data}
            userDropdown={false}
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            isAdminOrManager={isAdminOrManager}
            pipelineAccessibility={pipelineAccessibility}
            adminSettings={true}
            teamMembers={teamMembers}
            userDropdownClass="outcome-user-dropdown"
            getAllTeamMemberOutcomeTags={getAllTeamMemberOutcomeTags}
            teamMemberIds={teamMemberIds}
          />
        </div>
        <div className="help-content">
          <HelpCenter />
        </div>
      </div>
    </PageLayout>
  );
};

export default OutcomeAutomation;
