export const BG_COLOUR = {
  PERSONAL: '#C7D6E6',
  GENERAL: '#7F75B1',
  INTERNAL: '#36B37E',
  EXTERNAL: '#447BF2',
};
export const EVENT_TYPE_PROPS = {
  PERSONAL: {
    color: '#C7D6E6',
    tagClass: 'tag-blue-300',
  },
  GENERAL: {
    color: '#7F75B1',
    tagClass: 'tag-purple',
  },
  INTERNAL: {
    color: '#36B37E',
    tagClass: 'tag-green',
  },
  EXTERNAL: {
    color: '#447BF2',
    tagClass: 'tag-violet',
  },
};
