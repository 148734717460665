import { createSlice } from '@reduxjs/toolkit';
import { businessService } from '../app/api/businessService';
import { logout } from '../Authorize/authSlice';
import { URLS_BUSINESS_SERVICE } from '../Constant/urls';
import { constructQueryString } from '../Utils/common';

const accountsSliceInitialState = {
  currentDeal: null,
  currentDealInfo: null,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: accountsSliceInitialState,
  reducers: {
    saveCurrentDeal(state, action) {
      state.currentDeal = action.payload?.dealId
        ? action.payload?.dealId
        : action.payload;
      state.currentDealInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = { accountsSliceInitialState: [] };
      return state;
    });
  },
});

const tranformDealTypeResponse = (response) => {
  const transformedData = response?.data?.map((item) => ({
    label: item.dealType,
    value: item.dealType,
  }));
  const noType = [{ label: 'No Type', value: 'NO_TYPE' }];
  const AllOptions = noType.concat(transformedData);
  return AllOptions;
};

export const extendedAccountsSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchDealDetails: builder.query({
      query: ({ dealId, salesforceView }) =>
        `${URLS_BUSINESS_SERVICE.accounts.dealDetails}?deal_id=${dealId}${
          salesforceView ? '&salesforceView=true' : ''
        }`,
      providesTags: (result = {}) => {
        return [{ type: 'Deals', id: result?.data?.dealId ?? 'Details' }];
      },
    }),
    fetchAccountSummary: builder.query({
      query: ({ dealId }) =>
        `${URLS_BUSINESS_SERVICE.accounts.accountSummary}?deal_id=${dealId}`,
      providesTags: (result, error, arg) => {
        return [{ type: 'AccountSummary', id: arg?.dealId }];
      },
    }),
    updateAccountStage: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.updateAccountStage,
        method: 'POST',
        body,
      }),
    }),
    updateAccountCrmStage: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.updateAccountCrmStage,
        method: 'POST',
        body,
      }),
    }),
    updatePipelineTable: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.accounts.updatePipelineTable,
        method: 'POST',
        body,
      }),
    }),
    updateAllRecordsInPipeline: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.accounts.updateAllRecordsInPipeline,
        method: 'POST',
        body,
      }),
    }),
    checkPipelineBatch: builder.query({
      query: ({ processId }) =>
        `${URLS_BUSINESS_SERVICE.processing.pipelineTableBatchProcess}?process_id=${processId}`,
    }),
    fetchCRMStages: builder.query({
      query: (pipelineId) => {
        const profileData = JSON.parse(localStorage.getItem('user'));
        const companyId = profileData?.companyId;
        if (!companyId) {
          return;
        }
        return `${
          URLS_BUSINESS_SERVICE.accounts.crmStages
        }?company_id=${companyId}${
          pipelineId ? `&pipeline_id=${pipelineId}` : ''
        }`;
      },
      transformResponse: (result) => {
        let stages;
        if (Array.isArray(result)) {
          stages = result.map((stage) => {
            if (stage.label === stage.value) {
              return {
                label: `${stage.label}(Preskale)`,
                value: stage.value,
              };
            }
            return stage;
          });
        }
        return { stages };
      },
    }),
    fetchSalesOwners: builder.query({
      query: ({ needEmail } = { needEmail: '' }) => {
        return `${URLS_BUSINESS_SERVICE.accounts.salesOwners}${
          needEmail ? `?needEmail=${needEmail}` : ''
        }`;
      },
      providesTags: () => {
        return [{ type: 'Deals', id: 'salesOwners' }];
      },
    }),
    fetchBasicAccountDetails: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.accounts.basicAccountDetails;
      },
      providesTags: () => {
        return [{ type: 'Deals', id: 'basicAccountDetails' }];
      },
    }),
    fetchAccountsBySearch: builder.query({
      query: ({ searchTerm, teamMembers }) => {
        return `${
          URLS_BUSINESS_SERVICE.accounts.search
        }?term=${encodeURIComponent(searchTerm)}${
          teamMembers
            ? constructQueryString('userIds', teamMembers, 'teamMembers')
            : ''
        }`;
      },
    }),
    createOpportunity: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.accounts.createOpportunity,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'Deals', id: 'All' }],
    }),
    deleteDeal: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.accounts.deleteDeal}?deal_id=${dealId}`;
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          businessService.util.invalidateTags([
            { type: 'Tasks', id: 'Tasks_TaskManager' },
            { type: 'Deals', id: 'All' },
          ])
        );
      },
    }),
    getDealSummary: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.accounts.dealSummary,
        method: 'POST',
        body,
      }),
    }),
    updateDeal: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.accounts.updatePreskaleDeal,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'Tasks', id: arg?.dealId },
        { type: 'Notes', id: arg?.dealId },
        { type: 'Tasks', id: 'Tasks_TaskManager' },
        { type: 'Deals', id: 'All' },
      ],
    }),
    editPresalesUser: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.accounts.editPresalesUser,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        if (!body?.updateTo?.value) {
          console.warn('No value to update to');
          return;
        }
        const patchResult = dispatch(
          extendedAccountsSlice.util.updateQueryData(
            'fetchDealDetails',
            {
              dealId: body?.body?.dealId,
              presalesUser: body?.presalesUserId,
            },
            (draft) => {
              const presalesUserCopy = {
                id: body?.updateTo?.value, // This is presales userName which is sent as value here
                displayName: body?.updateTo?.label,
              };
              draft.data.presalesUser = presalesUserCopy;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    syncOpportunity: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.accounts.syncOpportunity,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Tasks', id: 'Tasks_Account' },
        { type: 'Notes', id: 'Notes_Account' },
        { type: 'Tasks', id: 'Tasks_TaskManager' },
        { type: 'Deals', id: 'All' },
      ],
    }),
    fetchAllDealTypes: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.accounts.getDealTypes;
      },
      transformResponse: tranformDealTypeResponse,
    }),
    //not used anywhere
    fetchUsersForProdutRole: builder.query({
      query: () => URLS_BUSINESS_SERVICE.accounts.getAllUsersForProductRole,
    }),

    fetchPipelines: builder.query({
      query: (teams) =>
        `${URLS_BUSINESS_SERVICE.pipeline.getPipelines}${
          teams ? `?teams=${teams}` : ''
        }`,
      transformResponse: (responseData) => {
        return responseData?.data ? responseData?.data : null;
      },
    }),
  }),
});

export const {
  useFetchDealDetailsQuery,
  useLazyFetchDealDetailsQuery,
  useUpdateAccountStageMutation,
  useUpdateAccountCrmStageMutation,
  useUpdatePipelineTableMutation,
  useUpdateAllRecordsInPipelineMutation,
  useLazyFetchCRMStagesQuery,
  useFetchCRMStagesQuery,
  useFetchSalesOwnersQuery,
  useFetchBasicAccountDetailsQuery,
  useCreateOpportunityMutation,
  useGetDealSummaryMutation,
  useEditPresalesUserMutation,
  useSyncOpportunityMutation,
  useFetchAccountSummaryQuery,
  useFetchAllDealTypesQuery,
  useLazyDeleteDealQuery,
  useUpdateDealMutation,
  useLazyFetchUsersForProdutRoleQuery,
  useFetchAccountsBySearchQuery,
  useLazyCheckPipelineBatchQuery,
  useLazyFetchPipelinesQuery,
} = extendedAccountsSlice;

export const selectCurrentDeal = (state) => state.accounts.currentDeal;
export const selectCurrentDealInfo = (state) => state.accounts.currentDealInfo;
export const { saveCurrentDeal } = accountsSlice.actions;
export default accountsSlice.reducer;
