import React from 'react';
import ReactModal from 'react-modal';
import Button from '../../common/Button';

const ReopenTaskConfirm = ({
  showModal,
  handleClose = () => {},
  handleConfirm = () => {},
}) => {
  const handleCancel = () => {
    handleClose();
  };

  return (
    <ReactModal
      isOpen={showModal}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Re-Open Task Confirmation</h4>
      </div>
      <div className="modal-body">
        <ul>
          <li>Are you sure you want to reopen this task?</li>
          <li />
          <li className="popup-note">
            Note: The previously tracked time will be reset
          </li>
        </ul>
      </div>
      <div className="modal-footer flex-justify-end">
        <Button type="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button className="ml-5" type="primary" onClick={handleConfirm}>
          Reopen
        </Button>
      </div>
    </ReactModal>
  );
};

export default ReopenTaskConfirm;
