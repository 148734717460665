import { DATE_RANGE } from './FilterOperators';

export const MEETING_STATUS = [
  {
    label: 'All Meetings',
    value: 'allmeetings',
  },
  {
    label: 'Tagged Meetings',
    value: 'taggedmeetings',
  },
  {
    label: 'Untagged Meetings',
    value: 'untaggedmeetings',
  },
];

export const MEETING_EVENT = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'External',
    value: 'external',
  },
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'Personal',
    value: 'personal',
  },
];

export const DATE_TYPE_OPTIONS = [
  {
    label: 'Meeting Date',
    value: 'meeting_date',
  },
  {
    label: 'Created Date',
    value: 'created_date',
  },
];

export const DATE_RANGE_WITH_CUSTOM = [
  ...DATE_RANGE,
  {
    value: 'CUSTOM',
    label: 'Custom',
  },
];
