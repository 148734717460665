import React, { useState, useEffect } from 'react';
import ManagerDashboard from './ManagerDashboard/ManagerDashboard';
import RequestAssistanceManager from './RequestAssistanceManager/RequestAssistance';
import AllRequests from '../features/RequestForm/AllRequests';
import PageLayout from '../Layout/PageLayout';
import { useHistory, useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useDataPersistance from '../hooks/useDataPersistance';
import { isSalesOwnerOrManagerOrAdmin } from '../Utils/common';

const ManagerDashboardManager = ({ isRAM, isLightningDashboard }) => {
  const history = useHistory();
  let { tab } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const { setStorageData } = useDataPersistance();
  const handleTabClick = (index) => () => {
    if (index === 0) {
      try {
        mixpanel.track('ram_accessed', {
          event: 'RAM accessed',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      setStorageData('dashboardManager', 'ram', true);
      history.push(`/manager/ram`);
      return;
    }
    if (index === 1) {
      if (isSalesOwnerOrManagerOrAdmin) {
        try {
          mixpanel.track('manager_dashboard_allrequests_accessed', {
            event: 'All requests accessed from manager dashboard',
          });
        } catch {
          console.error('Cannot add event to mixpanel');
        }
      }
      setStorageData('dashboardManager', 'allrequests', true);
      history.push(`/manager/allrequests`);
      return;
    }
    if (index === 2) {
      try {
        mixpanel.track('lightning_accessed', {
          event: 'Lightning Dashboard accessed',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      setStorageData('dashboardManager', 'dashboard', true);
      history.push(`/manager/dashboard`);
      return;
    }
  };

  useEffect(() => {
    if (tab === 'ram') {
      setActiveTab(0);
    }
    if (tab === 'allrequests') {
      setActiveTab(1);
    }
    if (tab === 'dashboard') {
      setActiveTab(2);
    }
  }, [tab]);

  return (
    <PageLayout
      headerMainContent={
        <>
          {isRAM && (
            <>
              <button
                onClick={handleTabClick(0)}
                className={`tab-button ${activeTab === 0 ? 'tab-active' : ''}`}
              >
                Resource Assignment Manager
              </button>
              <button
                onClick={handleTabClick(1)}
                className={`tab-button ${activeTab === 1 ? 'tab-active' : ''}`}
              >
                All Requests
              </button>
            </>
          )}
          {isLightningDashboard && (
            <button
              onClick={handleTabClick(2)}
              className={`tab-button ${activeTab === 2 ? 'tab-active' : ''}`}
            >
              Lightning Dashboard
            </button>
          )}
        </>
      }
    >
      {activeTab === 2 ? (
        <ManagerDashboard />
      ) : activeTab === 0 ? (
        <RequestAssistanceManager />
      ) : (
        <AllRequests />
      )}
    </PageLayout>
  );
};
export default ManagerDashboardManager;
