import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-dropdown-select';
import { ReactComponent as AddGreenSVG } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { ReactComponent as DeleteSVG } from '../../Icons/NewImage/delete.svg';
import { useHistory } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  createTeam,
  getAllTeams,
  getAllUsers,
} from '../../_services/common.service';
import { isAdminOrManager, logoutAndResetState } from '../../Utils/common';
import { useDispatch } from 'react-redux';

export default function Teams() {
  const axios = useAxiosPrivate();
  const fetchAllTeams = useAxios(axios);
  const fetchAllUsers = useAxios(axios);
  const addTeam = useAxios(axios);
  let history = useHistory();
  const dispatch = useDispatch();
  const [showCreateTeamModal, setCreateTeamModal] = useState(false);
  const [teamName, setTeamName] = useState();
  const [teamData, setTeamData] = useState([]);
  const [filteredTeamData, setFilteredTeamData] = useState([]);
  const [teamDescription, setTeamDescription] = useState();
  const [usersList, setUsersList] = useState([]);
  const [selectedTeamManager, setSelectedTeamManager] = useState([]);
  // const [teamInDetail, setTeamInDetail] = useState(false);
  const [teamInDetailId, setTeamInDetailId] = useState();

  useEffect(() => {
    if (isAdminOrManager()) getAllTeams(fetchAllTeams, getAllTeamsData);
    getAllUsers(fetchAllUsers, getAllUsersData);
  }, []);

  const getAllUsersData = (data) => {
    const usersData =
      data &&
      data.data.length > 0 &&
      data.data.map((item) => {
        return {
          value: item.id,
          label: item.email,
          userName: item.userName,
          name: item.name,
          roles: item.roles,
        };
      });
    setUsersList(usersData);
  };

  const getAllTeamsData = (data) => {
    // TODO: Need to check if this logic still remains true
    if (data && data.msg === 'REFRESH_TOKEN_EXPIRED' && data.status === 100) {
      // push/send them to login page
      logoutAndResetState(dispatch, history);
      history.push('/login');
    }
    if (data && data.status) {
      setTeamData(data.data);
      setFilteredTeamData(data.data);
    }
  };

  const handleCreateTeam = () => {
    setCreateTeamModal(true);
    getAllUsers(fetchAllUsers, getAllUsersData);
  };

  const handleCancel = () => {
    setCreateTeamModal(false);
  };

  const handleSearch = async (event) => {
    if (!event.target.value) {
      setFilteredTeamData(teamData);
      return;
    }
    let text = event.target.value.trim().toLowerCase();
    let users = teamData;
    if (text.length > 0) {
      users = users.filter((user) => {
        return (
          user.teamName.toLowerCase().match(text) ||
          user.teamDescription.toLowerCase().match(text)
        );
      });
    }
    setFilteredTeamData(users);
  };

  const handleAddTeam = async () => {
    let data = {
      teamName: teamName,
      teamDescription: teamDescription,
      // "teamManagerId": selectedTeamManager.value,
      teamManagers: [
        {
          id: selectedTeamManager.value,
          email: selectedTeamManager.label,
          userName: selectedTeamManager.userName,
          name: selectedTeamManager.name,
          roles: selectedTeamManager.roles,
        },
      ],
    };

    const handleSuccess = () => {
      setCreateTeamModal(false);
      if (isAdminOrManager()) getAllTeams(fetchAllTeams, getAllTeamsData);
    };

    createTeam(addTeam, data, handleSuccess);
  };

  const onDropDownSelect = async (option) => {
    setSelectedTeamManager(option[0]);
  };

  const handleTeamInDetail = (teamId) => {
    setTeamInDetailId(teamId);
    history.push(`/teamdescription/${teamId}`, { teamId, usersList });
  };

  return (
    <div className="team-parent">
      <div className="teams-header">
        <div className="teams-title">Teams</div>

        <div className="create-team">
          <button
            className="btn btn-icon btn-link"
            onClick={() => handleCreateTeam()}
          >
            <span className="add-team-btn">
              <AddGreenSVG />
            </span>
            <span className="btn-txt"> Add New Team</span>
          </button>
        </div>
      </div>

      <div className="team-title-desc"> Manage all your teams </div>

      <div className="team-handler">
        <div className="search-container mb-10">
          <SearchSVG />{' '}
          <input
            placeholder="Search teams"
            onChange={(event) => handleSearch(event)}
          />
        </div>
      </div>

      <ReactModal
        isOpen={showCreateTeamModal}
        className="Modal confirm-box"
        overlayClassName="Overlay confirm-box-overlay"
      >
        <div className="modal-header create-team-modal">
          <h4 className="modal-title">Add new team</h4>
        </div>
        <div className="form">
          <div className="form-field">
            <label> Team Name </label>
            <input
              type="text"
              value={teamName}
              placeholder="Enter name"
              onChange={(event) => {
                setTeamName(event.target.value);
              }}
            />
          </div>

          <div className="form-field">
            <label> Description </label>
            <textarea
              value={teamDescription}
              className="mention-wrapper"
              onChange={(event) => {
                setTeamDescription(event.target.value);
              }}
            />
          </div>

          <div className="form-field">
            <label> Team Manager </label>
            <Select
              options={usersList}
              onChange={onDropDownSelect}
              value={selectedTeamManager}
            >
              <optgroup></optgroup>
            </Select>
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={() => handleCancel()}>
            {' '}
            Cancel{' '}
          </button>
          <button className="btn btn-primary" onClick={() => handleAddTeam()}>
            {' '}
            Add{' '}
          </button>
        </div>
      </ReactModal>

      <div className="team-content">
        <table className="list-view">
          <tbody>
            <tr className="list-view-head">
              <th className="width-20 team-td"> Team Name </th>
              <th className="width-20 team-td"> No. of Team Members </th>
              <th className="width-20 team-td"></th>
            </tr>
            {filteredTeamData &&
              filteredTeamData.map((team) => (
                <tr
                  key={team.id}
                  className="list-view-row team-handle"
                  onClick={() => handleTeamInDetail(team.id)}
                >
                  <td>
                    <div className="font-bold">{team.teamName}</div>
                    <div className="date-content-time teams-description">
                      {team.teamDescription}
                    </div>
                  </td>
                  <td>
                    <div className="font-bold">{team.teamMembers.length}</div>
                  </td>
                  <td>
                    <div key={team.id} className="nvhbf criteria-action">
                      <div className="task-actions">
                        <a className="mini-btn" role="button">
                          <EditSVG />
                        </a>
                        <a className="mini-btn" role="button">
                          <DeleteSVG />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
