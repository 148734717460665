import React from 'react';
import DualRingLoader from './DualRingLoader';

export default (props) => {
  return (
    <div className="ag-overlay-loading-center">
      {props.loadingMessage ? <DualRingLoader dark /> : null}
      <div> {props.loadingMessage || props.message} </div>
    </div>
  );
};
