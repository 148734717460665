// import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedEvaluationPlanSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchTasksForStage: builder.query({
      query: ({ dealId, stageId }) =>
        `${URLS_BUSINESS_SERVICE.evaluationPlan.getTaskForStage}?deal_id=${dealId}&stage_id=${stageId}`,
      transformResponse: (responseData) => {
        let tasks = [];
        let completedCount = 0;
        if (responseData) {
          tasks =
            Array.isArray(responseData) &&
            responseData.map((item) => {
              if (item.completed) {
                completedCount++;
              }
              return {
                value: item.task,
                label: item.completed,
              };
            });
        }
        return { tasks, completedCount };
      },
    }),
    saveCompletedTasks: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.evaluationPlan.saveCompletedTasks,
        method: 'POST',
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedEvaluationPlanSlice.util.updateQueryData(
            'fetchTasksForStage',
            { dealId: body?.dealId, stageId: body?.stageId },
            (draft) => {
              const tasks = draft?.tasks.map((task) => {
                if (task.value === body?.task) {
                  task.label = body.completed;
                }
                return task;
              });
              draft.tasks = tasks;
              draft.completedCount = body?.completed
                ? draft?.completedCount + 1
                : draft?.completedCount - 1;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useLazyFetchTasksForStageQuery, useSaveCompletedTasksMutation } =
  extendedEvaluationPlanSlice;
