import axios from 'axios';

const isProduction = process.env.REACT_APP_IS_DEVELOPMENT ? false : true;

export const AXIOS_DOMAIN_NAME =
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? 'https://appdemo.preskale.com'
    : process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'https://trial.preskale.com'
    : process.env.REACT_APP_ENV === 'GITHUB'
    ? 'https://github.preskale.com'
    : 'https://app.preskale.com';

export const AUTH_DOMAIN_NAME =
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? 'https://appdemo.preskale.com'
    : process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'https://trial.preskale.com'
    : process.env.REACT_APP_ENV === 'APPUAT'
    ? 'https://appuat.preskale.com'
    : process.env.REACT_APP_ENV === 'GITHUB'
    ? 'https://github.preskale.com'
    : 'https://app.preskale.com';

export const SALESFORCE_URL =
  process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'https://test.salesforce.com'
    : 'https://login.salesforce.com';

const LOCAL_API_URL = 'http://localhost:9002/api/business/';
const LOCAL_USER_URL = 'http://localhost:9002/api/user/v1/';
const LOCAL_DE_URL = 'http://localhost:9002/api/de/';

const PROD_BUSINESS_API_URL = `${AUTH_DOMAIN_NAME}/api/business/`;
const PROD_USER_URL = `${AUTH_DOMAIN_NAME}/api/user/v1/`;
const API_TOKEN_URL = `${AUTH_DOMAIN_NAME}/auth/realms/preskale/protocol/openid-connect/token`;
const CREATE_USER = `${AUTH_DOMAIN_NAME}/api/user/create`;
const CREATE_USER_COGNITO = `${AUTH_DOMAIN_NAME}/api/user/v1/create-user`;
const COGNITO_CREATE_USERS_LIST = `${AUTH_DOMAIN_NAME}/api/user/v1/create-users`;
const GET_USER = `${AUTH_DOMAIN_NAME}/api/user/details`;

const PROD_DATA_ENGINEERING_URL = `${AUTH_DOMAIN_NAME}/api/de/`;

export const DOMAIN_URL = !isProduction ? LOCAL_API_URL : PROD_BUSINESS_API_URL;
export const DE_DOMAIN_URL = !isProduction
  ? LOCAL_DE_URL
  : PROD_DATA_ENGINEERING_URL;
export const USER_DOMAIN_URL = !isProduction ? LOCAL_USER_URL : PROD_USER_URL;

export default axios.create({
  baseURL: USER_DOMAIN_URL,
});

export const axiosBusiness = axios.create({
  baseURL: DOMAIN_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const axiosDE = axios.create({
  // data engineering
  baseURL: DE_DOMAIN_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const axiosUser = axios.create({
  baseURL: USER_DOMAIN_URL,
  headers: { 'Content-Type': 'application/json' },
});
