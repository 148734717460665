import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from '../common/images/logoFullWhite.svg';
import { ReactComponent as Badge1 } from '../common/images/bestroi.svg';
import { ReactComponent as Badge2 } from '../common/images/bestsupport.svg';
import { ReactComponent as Badge3 } from '../common/images/bestsupport.svg';
import { ReactComponent as Badge4 } from '../common/images/useradoption.svg';
import { ReactComponent as Badge5 } from '../common/images/highperformer.svg';
import MainContent from './MainContent';
import Landing from './Landing';
import FormFooter from './FormFooter';
import styles from '../styles/pages/signup.module.scss';
import Perks from './Perks';

const Index = () => {
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (step === 5) {
      setTimeout(() => {
        window.location.replace('https://www.preskale.com/sign-up/thank-you');
      }, 2000);
    }
  }, [step]);

  const handleNext = () => setStep((prev) => prev + 1);
  const getHeading = (step) => {
    switch (step) {
      case 1:
        return 'Get ready to experience PreSkale!';
      case 2:
        return 'We would love to know more about you!';
      case 3:
        return 'Explore PreSkale in action';
      case 4:
        return 'Book time with our onboarding expert';
      default:
        return 'We are excited to onboard you!';
    }
  };

  const renderHero = (step) =>
    step === 1 ? (
      <Landing />
    ) : (
      <section className={styles.badges_container}>
        <Badge1 />
        <Badge2 />
        <Badge3 />
        <Badge4 />
        <Badge5 />
      </section>
    );

  const renderFooter = (step) => {
    switch (step) {
      case 1:
        return <FormFooter />;
      case 4:
        return <Perks />;
      case 5:
        return <p>Redirecting to our home page...</p>;
      default:
        return null;
    }
  };

  return (
    <section className={styles.container}>
      <section
        className={`${styles.hero__container} ${
          step > 1 ? styles.hero__small : ''
        }`}
      >
        <section className={styles.header}>
          <Logo />
          {step > 1 && <h6 className={styles.heading}>G2 Recognitions</h6>}
        </section>
        {renderHero(step)}
      </section>
      <section
        className={`${styles.main_container} ${
          step === 4 ? styles.padding_step_calendar : ''
        } ${step !== 4 ? styles.center_content : ''} ${
          step === 1 ? styles.main_positioning : ''
        }`}
      >
        <h3
          className={`${styles.heading} ${
            step === 4
              ? styles.heading_margin_calendar
              : step > 2
              ? styles.heading_margin
              : ''
          }`}
        >
          {getHeading(step)}
        </h3>
        <section
          className={`${styles.content_container} ${
            step === 4 ? styles.no_margin : ''
          }`}
        >
          <MainContent step={step} handleNext={handleNext} />
        </section>
        {step !== 3 && (
          <section className={styles.help_text}>{renderFooter(step)}</section>
        )}
      </section>
    </section>
  );
};

export default Index;
