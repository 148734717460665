import { URLS_BUSINESS_SERVICE } from '../Constant/urls';
import { toQueryString } from '../Utils/common';

export const getParentStages = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.stage.getParentStages,
    onSuccess: handleSuccess,
  });

export const getActivitiesByStage = (service, params = {}, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.stage.getActivitiesByStage}?${toQueryString(
      params
    )}`,
    onSuccess: handleSuccess,
  });

export const getStageActivityDays = (service) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.stage.activeDays}?from_date=2021-02-01&to_date=2021-12-01`,
  });

export const addParentStage = (service, data, handleSuccess, handleError) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.stage.addParentStage,
    data,
    onSuccess: handleSuccess,
    onError: handleError,
  });

export const updateAccountStage = (service, data, handleSuccess, handleError) =>
  service.makeAxiosCall({
    method: 'post',
    url: URLS_BUSINESS_SERVICE.stage.updateAccountStage,
    data,
    onSuccess: handleSuccess,
    onError: handleError,
  });
