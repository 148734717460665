import React, { useState } from 'react';
import NoteItem from './NoteItem';
import DualRingLoader from '../../../common/DualRingLoader';
import DeleteNote from '../../../common/notes/DeleteNote';
import styles from '../../../styles/features/presentationList.module.scss';
import AddTaskNoteDescriptionDrawer from '../../../common/AddDrawerContent/AddTaskNoteDescriptionDrawer';

const NoteList = ({ data, isReadOnly, isLoading = false, dealDetails }) => {
  const [currentSelectedNote, setCurrentSelectedNote] = useState(null);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);

  const handleEditNoteOpen = (note) => {
    setCurrentSelectedNote(note);
    setShowEditNoteModal(true);
  };
  const handleEditNoteCancel = () => {
    setShowEditNoteModal(false);
  };

  const handleDeleteNoteOpen = (note) => {
    setCurrentSelectedNote(note);
    setShowDeleteNoteModal(true);
  };
  const handleDeleteNoteCancel = () => {
    setCurrentSelectedNote(null);
    setShowDeleteNoteModal(false);
  };

  return (
    <>
      <ul className={`${styles.presentation_list}`}>
        {isLoading && (
          <div className="flex-center py-1">
            <DualRingLoader dark />
          </div>
        )}
        {Array.isArray(data) &&
          data.map((note) => (
            <li key={note.id} className={styles.presentation_item__container}>
              <NoteItem
                handleUpdate={handleEditNoteOpen}
                handleDelete={handleDeleteNoteOpen}
                item={note}
                eventInfo={note?.eventInfo}
                isReadOnly={isReadOnly}
              />
            </li>
          ))}
        {!isLoading && (!data || data.length === 0) ? (
          <li className={styles.no_data}>No notes added</li>
        ) : null}
      </ul>
      {showEditNoteModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showEditNoteModal}
          closeDrawer={handleEditNoteCancel}
          tab="Notes"
          action="update"
          dealDetails={dealDetails}
          initialValues={currentSelectedNote}
          key="presentation-update-notes"
        />
      )}
      {showDeleteNoteModal && (
        <DeleteNote
          note={currentSelectedNote}
          showModal={showDeleteNoteModal}
          handleClose={handleDeleteNoteCancel}
        />
      )}
    </>
  );
};

export default NoteList;
