import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import { DEFAULT_FIELDS } from './constants';

export const extendedIntegrationSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    disconnectCRM: builder.query({
      query: () => URLS_BUSINESS_SERVICE.company.crmDisconnect,
      providesTags: () => [{ type: 'Crm', id: 'Disconnect' }],
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          businessService.util.invalidateTags([
            { type: 'Users', id: 'UserDetails' },
          ])
        );
      },
    }),
    productIntegrationDisconnect: builder.query({
      query: ({ type }) =>
        `${URLS_BUSINESS_SERVICE.company.productIntegrationDisconnect}?type=${type}`,
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          businessService.util.invalidateTags([
            { type: 'Users', id: 'UserDetails' },
            {
              type: 'Jira',
              id: 'Check_Integration',
            },
            {
              type: 'Zoho',
              id: 'Check_Integration',
            },
          ])
        );
      },
    }),
    fetchCrmMapper: builder.query({
      query: ({ crmType }) => {
        return `${URLS_BUSINESS_SERVICE.organization.getCrmMapper}?crmType=${crmType}`;
      },
      providesTags: () => [{ type: 'Crm', id: 'CrmMapper' }],
      transformResponse: (responseData) => {
        const response = [];
        if (!responseData || responseData.data?.length === 0) {
          return response;
        }
        const dealFields = responseData.data[0];
        const accountFields = responseData.data[1];
        DEFAULT_FIELDS.forEach((field) => {
          const transformedField = field;
          transformedField.value =
            field.type === 'OPPORTUNITY'
              ? dealFields.fields[field.preskaleField]
              : accountFields.fields[field.preskaleField];
          response.push(transformedField);
        });
        return { existingCrmFields: response };
      },
    }),
    updateCrmMapper: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.organization.updateCrmMapper,
        method: 'POST',
        body,
      }),
    }),
    updateProductBoardIntegration: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.company.updateProductBoardIntegration,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyDisconnectCRMQuery,
  useLazyProductIntegrationDisconnectQuery,
  useUpdateCrmMapperMutation,
  useFetchCrmMapperQuery,
  useUpdateProductBoardIntegrationMutation,
} = extendedIntegrationSlice;
