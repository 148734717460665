import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Flex, Segmented, Select, Typography } from 'antd';
import CalendarList from '../calendar/CalendarList';
import DualRingLoader from '../../common/DualRingLoader';
import useCloseComponentOnOutsideClick from '../../hooks/useCloseComponentOnOutsideClick';
import PreskaleSimpleMultiSelect from './PreskaleSimpleMultiSelect';
import AddTaskNoteDescriptionDrawer from '../../common/AddDrawerContent/AddTaskNoteDescriptionDrawer';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import { isProductUser, isSalesUser } from '../../Utils/common';
import { saveSelectedCalendarEvent } from '../calendar/calendarSlice';
import { useGetParentContributionQuery } from '../../app/api/contributionSlice';
import { ReactComponent as SyncSVG } from '../../Icons/Sync.svg';
import { ReactComponent as AddSVG } from '../../Icons/NewImage/add-green.svg';

const ListView = lazy(() => import('../calendar/ListView'));
const SalesActivityListView = lazy(() =>
  import('./Activity/SalesActivityListView')
);
const { Text } = Typography;

const MEETING_TYPE_OPTIONS = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Completed Meetings',
    value: 'COMPLETED_MEETINGS',
  },
  {
    label: 'Upcomming  Meetings',
    value: 'UPCOMMING_MEETINGS',
  },
];

const CalendarTabContent = ({
  handleCalendarAutomationSync,
  dealDetails,
  isReadOnly,
}) => {
  const [openAddActivityDrawer, setOpenAddActivityDrawer] = useState(false);
  const [meetingRange, setMeetingRange] = useState(MEETING_TYPE_OPTIONS[1]);
  const [selectedMeetingOutcomes, setSelectedMeetingOutcomes] = useState([]);
  const [meetingFilter, setMeetingFilter] = useState({
    startDate: '',
    endDate: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
  });
  const {
    containerRef: filtersContainerRef,
    openComponent: showEvent,
    setOpenComponent: setShowEvent,
  } = useCloseComponentOnOutsideClick('custom-event-title');
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('Calendar');

  const { data: contributionMap } = useGetParentContributionQuery(undefined, {
    selectFromResult: ({ data }) => {
      const options = useMemo(() => {
        let optionValues = [];
        optionValues =
          data?.entities &&
          Object.values(data?.entities).map((outcome) => ({
            label: outcome?.title,
            value: outcome?.id,
          }));
        return optionValues;
      }, [data]);
      return {
        data: options ?? [],
      };
    },
  });

  useEffect(() => {
    const defaultContributions =
      contributionMap?.map((data) => data?.value) ?? [];
    setSelectedMeetingOutcomes(defaultContributions);
    const filter = {
      ...meetingFilter,
      outcomes: defaultContributions,
    };
    setMeetingFilter(filter);
  }, [contributionMap]);

  const openCalendarInSidebar = (calendarEventInfo) => {
    if (calendarEventInfo) {
      setShowEvent(true);
    }
    dispatch(saveSelectedCalendarEvent(calendarEventInfo));
  };

  const handleCloseAddActivityDrawer = () => {
    setOpenAddActivityDrawer(false);
  };

  const handleMeetingRangeChange = (e) => {
    const { startDate, endDate } = deriveDateRange(e);
    setMeetingRange(e);
    const defaultContributions = contributionMap.map((data) => data?.value);
    const outcomes = selectedMeetingOutcomes.includes('ALL')
      ? defaultContributions
      : selectedMeetingOutcomes;
    setMeetingFilter({
      startDate: startDate,
      endDate: endDate,
      outcomes: outcomes ?? [],
    });
  };

  const deriveDateRange = (e) => {
    let from = '';
    let to = '';

    switch (e) {
      case MEETING_TYPE_OPTIONS[0].value:
        break;
      case MEETING_TYPE_OPTIONS[1].value:
        to = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
        break;

      case MEETING_TYPE_OPTIONS[2].value:
        from = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd');
        break;
    }
    return {
      startDate: from,
      endDate: to,
    };
  };

  const handleOutcomeChange = (value) => {
    setSelectedMeetingOutcomes(value);
    if (!value.includes('All')) {
      setMeetingFilter({ ...meetingFilter, outcomes: value ?? [] });
    } else {
      setMeetingFilter({
        ...meetingFilter,
        outcomes: contributionMap?.map((data) => data?.value) ?? [],
      });
    }
  };

  return (
    <>
      <Flex className="calendar-actions" gap={6}>
        <Segmented
          options={['Calendar', 'Activity']}
          value={selectedTab}
          onChange={(value) => {
            setSelectedTab(value);
          }}
        />
        {!isSalesUser() && !isProductUser() && (
          <Flex className="calendar-table-actions" align="center" gap={10}>
            {selectedTab === 'Calendar' && (
              <>
                <Button
                  className="flex-align-center"
                  onClick={handleCalendarAutomationSync}
                  icon={<SyncSVG width={16} height={16} />}
                >
                  Run calendar Auto-tag
                </Button>

                <Text strong>Meeting Type : </Text>
                <Select
                  options={MEETING_TYPE_OPTIONS}
                  value={meetingRange}
                  onChange={handleMeetingRangeChange}
                  className="min-width-150"
                />
                <Text strong>Outcome : </Text>
                <PreskaleSimpleMultiSelect
                  data={contributionMap}
                  value={selectedMeetingOutcomes}
                  onChange={handleOutcomeChange}
                  maxTagCount={1}
                  style={{ width: 'inherit', minWidth: '200px' }}
                />
              </>
            )}
            {selectedTab === 'Activity' && (
              <Button
                className="flex-align-center text-dark"
                type="link"
                onClick={() => setOpenAddActivityDrawer(true)}
                icon={<AddSVG className="overflow-visible" />}
              >
                Add Activity
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      {showEvent && selectedTab === 'Calendar' && (
        <div className="table-calendar-popup">
          <Suspense
            fallback={
              <div className="loader-container--suspense">
                <DualRingLoader dark />
              </div>
            }
          >
            <CalendarList
              showEventDetails={openCalendarInSidebar}
              account={dealDetails}
              handleClose={() => setShowEvent(false)}
              isAccountView
              ref={filtersContainerRef}
            />
          </Suspense>
        </div>
      )}
      <div className="list-view--container pl-3em pr-20 pb-3em">
        {selectedTab === 'Calendar' && (
          <Suspense
            fallback={
              <div className="loader-container--suspense">
                <DualRingLoader dark />
              </div>
            }
          >
            <ListView
              account={dealDetails}
              showAll
              readOnly={isReadOnly}
              outcomes={contributionMap}
              showEventDetails={openCalendarInSidebar}
              meetingFilter={meetingFilter}
            />
          </Suspense>
        )}
        {selectedTab === 'Activity' && (
          <Suspense
            fallback={
              <div className="loader-container--suspense">
                <DualRingLoader dark />
              </div>
            }
          >
            <SalesActivityListView
              dealDetails={dealDetails}
              readOnly={isReadOnly}
            />
          </Suspense>
        )}
      </div>
      {openAddActivityDrawer && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={openAddActivityDrawer}
          closeDrawer={handleCloseAddActivityDrawer}
          tab="Activity"
          action="add"
          key="presentation-add-activity"
          dealDetails={dealDetails}
        />
      )}
    </>
  );
};

export default CalendarTabContent;
