import { useState, useEffect, useMemo } from 'react';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import {
  useFetchCRMStagesQuery,
  useLazyFetchPipelinesQuery,
} from '../Accounts/accountsSlice';
import {
  useFetchCRMParentStagesQuery,
  useFetchParentStagesQuery,
} from './pipelineSlice';
import { isSalesUser } from '../Utils/common';

const useSetUpPipelines = () => {
  const [selectedTeam, setSelectedTeam] = useState();
  // * Get company config
  const { data: configFeatures, isFetching: loadingConfig } =
    useFetchCompanyConfigDataQuery(undefined, {
      skip: !JSON.parse(localStorage.getItem('user')),
    });

  // * Setting view type - CRM | Preskale
  const CRM_STAGE_VIEW = localStorage.getItem('crm_stage_view');
  const [isCRMStageView, setIsCRMStageView] = useState(null);
  useEffect(() => {
    if (!CRM_STAGE_VIEW) {
      const defaultValue = configFeatures?.data?.stageView;
      setIsCRMStageView(defaultValue);
    } else {
      setIsCRMStageView(CRM_STAGE_VIEW);
    }
  }, [configFeatures?.data?.stageView]);

  // * Get pipelines
  const [getPipelines, { data: pipelines }] = useLazyFetchPipelinesQuery();

  useEffect(() => {
    if (isSalesUser()) {
      getPipelines();
      return;
    }
    getPipelines(selectedTeam?.map((data) => data?.value));
  }, [selectedTeam]);

  // * Get crm stages based on company config, view type and pipelines
  const { data: crmParentStages, isFetching: crmParentStagesLoading } =
    useFetchCRMParentStagesQuery(undefined, {
      skip:
        !configFeatures?.data ||
        !pipelines ||
        !isCRMStageView ||
        isCRMStageView === 'preskale',
    });

  // * Get preskale stages based on company config, view type and pipelines
  const { data: psParentStages, isFetching: psParentStagesLoading } =
    useFetchParentStagesQuery(undefined, {
      skip: !configFeatures?.data || !pipelines,
      selectFromResult: ({ data, isFetching }) => {
        const options = useMemo(() => {
          const stages = [];
          const options = data?.ids?.map((stage) => {
            stages.push(data?.entities[stage]);
            return {
              label: data?.entities[stage]?.name,
              value: stage,
            };
          });
          return {
            ...data,
            preskaleParentStages: stages,
            preskaleStages: options,
          };
        }, [data]);
        return {
          data: options,
          isFetching,
        };
      },
    });

  // * Selected pipelines
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const onPipelineSelect = (option) => {
    if (option) {
      setSelectedPipeline(option);
      localStorage.setItem('pipeline', JSON.stringify(option));
    }
  };

  useEffect(() => {
    if (pipelines && pipelines?.length) {
      const pipeline = pipelines[0];
      onPipelineSelect(pipeline);
    }
  }, [pipelines]);

  // * SALES STAGES * //
  const { data: salesStages, isLoading: loadingSalesStages } =
    useFetchCRMStagesQuery(
      selectedPipeline ? selectedPipeline.value : undefined,
      {
        refetchOnMountOrArgChange: true,
        skip:
          !pipelines ||
          (pipelines.length && !selectedPipeline.hasOwnProperty('value')),
      }
    );

  // * Set lane stages for view
  const [parentStages, setParentStages] = useState();

  const getPipelineStagesForView = (isCRMStageView) => {
    if (
      !configFeatures ||
      !pipelines ||
      !isCRMStageView ||
      (isCRMStageView === 'preskale' && !psParentStages.preskaleParentStages) ||
      (isCRMStageView === 'crm' && !crmParentStages)
    ) {
      // console.log('returned');
      return;
    }
    // console.log('started');
    // * If there are multiple pipelines in a crm stage view
    if (
      pipelines &&
      pipelines.length &&
      isCRMStageView === 'crm' &&
      selectedPipeline.hasOwnProperty('value') &&
      crmParentStages
    ) {
      const filteredList = crmParentStages?.filter(
        (stage) => stage.pipelineParentId === selectedPipeline.value
      );
      if (
        configFeatures?.data?.crm &&
        filteredList[0]?.id !== 'PreSkale Deals'
      ) {
        setParentStages([crmParentStages[0], ...filteredList]);
      } else {
        setParentStages(filteredList);
      }
    }
    // * If there are no multiple pipelines in a crm stage view
    if (pipelines && !pipelines.length && isCRMStageView === 'crm') {
      setParentStages(crmParentStages);
    }
    // * Preskale stage view
    // * First condition checks for multiple pipelines
    // * Second condition checks for normal or no multiple pipelines
    if (
      (pipelines &&
        pipelines.length &&
        isCRMStageView === 'preskale' &&
        selectedPipeline.hasOwnProperty('value') &&
        psParentStages?.preskaleParentStages) ||
      (pipelines && !pipelines.length && isCRMStageView === 'preskale')
    ) {
      setParentStages(psParentStages.preskaleParentStages);
    }
  };

  useEffect(() => {
    getPipelineStagesForView(isCRMStageView);
  }, [
    configFeatures?.data?.crm,
    isCRMStageView,
    psParentStages.preskaleParentStages,
    crmParentStages,
    selectedPipeline,
  ]);

  return {
    configFeatures,
    loadingConfig,
    isCRMStageView,
    setIsCRMStageView,
    pipelines,
    parentStages,
    crmParentStagesLoading,
    presalesStages: psParentStages.preskaleStages,
    pipelinePresalesStages: psParentStages.preskaleParentStages,
    psParentStagesLoading,
    selectedPipeline,
    onPipelineSelect,
    salesStages: salesStages?.stages,
    loadingSalesStages,
    selectedTeam,
    setSelectedTeam,
  };
};

export default useSetUpPipelines;
