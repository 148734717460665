import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import {
  selectSelectedCard,
  updateDealStage,
  useFetchParentStagesQuery,
} from '../../Pipeline/pipelineSlice';
import {
  useLazyFetchTasksForStageQuery,
  useSaveCompletedTasksMutation,
} from './evaluationPlanSlice';
import { useDispatch, useSelector } from 'react-redux';

const EvaluationPlan = ({
  deal,
  isClosedAccount,
  customClassName = '',
  readOnly = false,
}) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userId = userDetails?.userId;
  const dispatch = useDispatch();
  const selectedCard = useSelector(selectSelectedCard);
  const [activeStage, setActiveStage] = useState({});
  const { data: stages } = useFetchParentStagesQuery();

  const [
    getTaskForStage,
    { data: { tasks, completedCount } = { tasks: [], completedCount: 0 } },
  ] = useLazyFetchTasksForStageQuery();

  const [saveCompletedTasks] = useSaveCompletedTasksMutation();

  useEffect(() => {
    const presalesStage = deal?.additionalDetails?.presalesStage;
    if (!presalesStage) {
      console.warn('Missing presales stage!');
      return;
    }
    if (!stages) {
      return;
    }
    const stage = stages?.ids?.filter(
      (stage) =>
        stage &&
        stages?.entities[stage]?.name?.toLowerCase() ===
          presalesStage?.toLowerCase()
    );
    if (!stage?.length) {
      console.warn(
        'Current presales stage does not match with existing stages!'
      );
      return;
    }
    if (stage.length > 1) {
      console.warn(
        'Duplicate stages: Current presales stage matches more than one stage!'
      );
    }
    getTaskForStage({ dealId: deal?.dealId, stageId: stage[0] });
    setActiveStage({
      label: stages?.entities[stage[0]]?.name,
      value: stage[0],
    });
  }, [deal?.additionalDetails?.presalesStage, stages]);

  const handleSaveCompletedTask = (task, completed) => {
    const data = {
      userId,
      dealId: deal.dealId,
      stageId: activeStage.value,
      stageName: activeStage.label,
      task,
      completed,
      accountId: deal.accountId,
      accountName: deal.accountName,
    };

    saveCompletedTasks(data)
      .unwrap()
      .then(() => {
        if (selectedCard?.cardId) {
          dispatch(
            updateDealStage({
              smartSort: true,
              cardId: selectedCard?.cardId,
              sourceLaneId: selectedCard?.laneId,
            })
          );
        }
      });
  };

  const handleStageSelect = (stage) => {
    setActiveStage(stage);
    getTaskForStage({ dealId: deal?.dealId, stageId: stage.value });
  };

  const handleCheckboxClick = (task, ev) => {
    ev.stopPropagation();

    handleSaveCompletedTask(task.value, ev.target.checked);
    try {
      mixpanel.track('checklist_accessed', { event: 'Checklist Accessed' });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  return (
    <div className="evaluationplan-wrapper">
      <div
        className={
          customClassName
            ? 'stages_list min-height-70'
            : 'stages_list secondary-content--padding'
        }
      >
        <div className="manager-title font-bold">Stages</div>
        {stages?.ids &&
          stages?.ids.map((stage) => (
            <div
              className={`capitalize manager-type ${
                activeStage.value === stage ? 'active' : ''
              } `}
              key={stage}
              onClick={() =>
                handleStageSelect({
                  label: stages?.entities[stage]?.name,
                  value: stage,
                })
              }
            >
              {stages?.entities[stage]?.name}
            </div>
          ))}
      </div>

      <div
        className={`contents-container secondary-content--padding ${customClassName}`}
      >
        <div className="success-criteria-tab">
          <div className="success-criteria">
            <div className="criteria-title capitalize">
              <h4>
                {' '}
                {activeStage.label}{' '}
                {tasks.length > 0
                  ? `Checklist (${completedCount} / ${tasks.length})`
                  : null}{' '}
              </h4>
            </div>
            {tasks && tasks.length > 0 ? (
              tasks.map((task, index) => (
                <div key={`${task?.value}-${index}`} className="criteria-list">
                  <div className="cscsdcsdcd">
                    <input
                      name="completed"
                      type="checkbox"
                      disabled={isClosedAccount || readOnly}
                      checked={task?.label}
                      onChange={(ev) => handleCheckboxClick(task, ev)}
                    />
                    <span className="criteria-text"> {task.value} </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex-center">No action item in this stage.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationPlan;
