const REVENUE_OPERATOR_OPTIONS = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Equals',
    value: 'EQUALS',
  },
  {
    label: 'Less Than',
    value: 'LESS_THAN',
  },
  {
    label: 'Greater Than',
    value: 'GREATER_THAN',
  },
];

const DATE_OPERATOR_OPTIONS = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Custom Date Range',
    value: 'CUSTOM',
  },
  {
    label: 'Previous Quarter',
    value: 'PREVIOUS_QUARTER',
  },
  {
    label: 'This Quarter',
    value: 'THIS_QUARTER',
  },
  {
    label: 'Next Quarter',
    value: 'NEXT_QUARTER',
  },
  {
    label: 'Previous Month',
    value: 'PREVIOUS_MONTH',
  },
  {
    label: 'This Month',
    value: 'THIS_MONTH',
  },
  {
    label: 'Next Month',
    value: 'NEXT_MONTH',
  },
  {
    label: 'Previous Week',
    value: 'PREVIOUS_WEEK',
  },
  {
    label: 'This Week',
    value: 'THIS_WEEK',
  },
  {
    label: 'Next Week',
    value: 'NEXT_WEEK',
  },
];

const REPORTING_DATE_OPERATOR_OPTIONS = [
  {
    label: 'Previous Quarter',
    value: 'PREVIOUS_QUARTER',
  },
  {
    label: 'This Quarter',
    value: 'THIS_QUARTER',
  },
  {
    label: 'Next Quarter',
    value: 'NEXT_QUARTER',
  },
  {
    label: 'Previous Month',
    value: 'PREVIOUS_MONTH',
  },
  {
    label: 'This Month',
    value: 'THIS_MONTH',
  },
  {
    label: 'Next Month',
    value: 'NEXT_MONTH',
  },
  {
    label: 'Previous Week',
    value: 'PREVIOUS_WEEK',
  },
  {
    label: 'This Week',
    value: 'THIS_WEEK',
  },
  {
    label: 'Next Week',
    value: 'NEXT_WEEK',
  },
  {
    label: 'Custom',
    value: 'CUSTOM',
  },
];

const PRESALES_USER_OPTIONS = [
  {
    label: 'All Accounts',
    value: 'ALL',
  },
  {
    label: 'My Accounts',
    value: 'MY',
  },
  {
    label: 'Collaborated Accounts',
    value: 'COLLABRATED',
  },
];

const DATE_OPERATOR_LABELS = {
  ALL: 'All',
  PREVIOUS_QUARTER: 'Previous Quarter',
  THIS_QUARTER: 'This Quarter',
  NEXT_QUARTER: 'Next Quarter',
  PREVIOUS_MONTH: 'Previous Month',
  THIS_MONTH: 'This Month',
  NEXT_MONTH: 'Next Month',
  PREVIOUS_WEEK: 'Previous Week',
  THIS_WEEK: 'This Week',
  NEXT_WEEK: 'Next Week',
  // ,
  // 'IS_BETWEEN' : 'Is Between'
};

const DEFAULT_STAGES = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
];

const DEFAULT_CRM_STAGES = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Closed Won',
    value: 'Closed Won',
  },
  {
    label: 'Closed Lost',
    value: 'Closed Lost',
  },
];

const DEFAULT_ALL = [
  {
    label: 'All',
    value: 'ALL',
    index: 0,
  },
];

const USER_FILTER_OPTIONS = [
  {
    label: 'Overall Trend',
    value: 'OVERALL_TREND',
  },
  {
    label: 'All Users',
    value: 'ALL_USERS',
  },
];

const TASK_OPTIONS = [
  {
    value: 'OPEN',
    label: 'Open',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
  },
  {
    value: 'OVERDUE',
    label: 'Overdue',
  },
];

const TASK_MANAGER_OPTIONS = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'OPEN',
    label: 'Open',
  },
  {
    value: 'OVERDUE',
    label: 'Overdue',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
  },
];

const DATE_RANGE = [
  {
    value: 'YESTERDAY',
    label: 'Yesterday',
  },
  {
    value: 'TODAY',
    label: 'Today',
  },
  {
    value: 'TOMORROW',
    label: 'Tomorrow',
  },
  {
    value: 'PREVIOUS_WEEK',
    label: 'Last Week',
  },
  {
    value: 'THIS_WEEK',
    label: 'This Week',
  },
  {
    value: 'NEXT_WEEK',
    label: 'Next Week',
  },
];

const DATERANGE_IN_QUARTERS = [
  {
    label: 'Q1',
    value: 'Q1',
  },
  {
    label: 'Q2',
    value: 'Q2',
  },
  {
    label: 'Q3',
    value: 'Q3',
  },
  {
    label: 'Q4',
    value: 'Q4',
  },
];

const PULSE_SCORE = [
  {
    value: 1,
    label: 'Requested',
  },
  {
    value: 2,
    label: 'Good to have',
  },
  {
    value: 3,
    label: 'Must have',
  },
  {
    value: 4,
    label: 'Critical',
  },
  {
    value: 5,
    label: 'Deal breaker',
  },
];

const PG_USECASES_DATE_RANGE = [
  {
    value: 'THIS_MONTH',
    label: 'This Month',
  },
  {
    value: 'THIS_QUARTER',
    label: 'This Quarter',
  },
  {
    value: 'PREVIOUS_QUARTER',
    label: 'Last Quarter',
  },
  {
    value: 'LAST_6_MONTHS',
    label: 'Last 6 Months',
  },
  {
    value: 'All Time Range',
    label: 'All Time Range',
  },
];

const UTILIZATION_TABLE_DATE_OPERATORS = [
  {
    label: 'This Month',
    value: 'THIS_MONTH',
  },
  {
    label: 'This Quarter',
    value: 'THIS_QUARTER',
  },
];

const PRODUCT_GAP_TYPE = [
  { label: 'Enhancements/Bugs', value: 'Enhancements/Bugs' },
  { label: 'New Request', value: 'New Request' },
];

const CRM_FIELDS = [
  { label: 'Name', value: 'name' },
  { label: 'Crm Stage', value: 'crmStage' },
  { label: 'Deal Value', value: 'dealValue' },
  { label: 'Deal Owner', value: 'dealOwner.displayName' },
  { label: 'Presales Owner', value: 'presalesUserDetails.displayName' },
  { label: 'Expected Close Date', value: 'expectedCloseDate' },
  { label: 'Presales Stage', value: 'presalesStage.name' },
  { label: 'Presales Stage Updated At', value: 'presalesUpdatedDate' },
];

const RRF_STATUS = [
  { label: 'All', value: 'ALL' },
  { label: 'Open Requests', value: 'REQUESTED' },
  { label: 'Resource Assigned', value: 'ASSIGNED' },
  { label: 'Request Rejected', value: 'REJECTED' },
];

const PIPELINE_VIEWS = [
  {
    label: 'Kanban',
    value: 'kanban',
  },
  {
    label: 'Table',
    value: 'table',
  },
];

const PRESKALE_COLUMNS = [
  {
    label: 'Notes',
    value: 'NOTES',
  },
  {
    label: 'Custom Section',
    value: 'CUSTOM_SECTION',
  },
];

export {
  REVENUE_OPERATOR_OPTIONS,
  DATE_OPERATOR_OPTIONS,
  PRESALES_USER_OPTIONS,
  DATE_OPERATOR_LABELS,
  DEFAULT_STAGES,
  DEFAULT_CRM_STAGES,
  DEFAULT_ALL,
  USER_FILTER_OPTIONS,
  TASK_OPTIONS,
  TASK_MANAGER_OPTIONS,
  DATE_RANGE,
  DATERANGE_IN_QUARTERS,
  PULSE_SCORE,
  PG_USECASES_DATE_RANGE,
  UTILIZATION_TABLE_DATE_OPERATORS,
  PRODUCT_GAP_TYPE,
  REPORTING_DATE_OPERATOR_OPTIONS,
  CRM_FIELDS,
  RRF_STATUS,
  PIPELINE_VIEWS,
  PRESKALE_COLUMNS,
};
