import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import { ReactComponent as AddIcon } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as SearchIcon } from '../../Icons/NewImage/search.svg';
import { ReactComponent as ArrowIcon } from '../../Icons/ArrowLeft.svg';
import { ReactComponent as ArrowDownIcon } from '../../Icons/ArrowDownSmall.svg';
import ProductGapItemsList from './ProductGapItemsList';
import UseCasesList from './UseCasesList';
import { PRODUCT_GAP_TYPE } from '../../Constant/FilterOperators';
import { useFetchModulesFeaturesQuery } from '../ModulesManager/moduleFeaturesSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  productGapState,
  saveNewPGTitle,
  saveProductGap,
} from './productGapSlice';
import JiraInfo from './JiraInfo';
import useDebounce from '../../hooks/useDebounce';
import { noData } from '../../Utils/common';
import { filterSelectStyles } from '../../Constant/ProuductGapDropdowns';
import { getSelectedTeam } from '../../Pipeline/utils/pipelineUtils';

const ALL_PRODUCT_GAP_TYPE = [{ label: 'All', value: '' }, ...PRODUCT_GAP_TYPE];

const ProductGapSearch = ({
  handleAdd,
  onListClick,
  filters,
  integrationType,
  dealId,
  formik,
}) => {
  const dispatch = useDispatch();
  const [showJiraInfo, setShowJiraInfo] = React.useState(false);
  const [features, setFeatures] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState({ label: '' });
  const [selectedModule, setSelectedModule] = useState({ label: '' });
  const [selectedFeature, setSelectedFeature] = useState({ label: '' });
  const { selectedProductGap, newPGTitle } = useSelector(productGapState);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const selectedTeam = getSelectedTeam();

  const { data: modulesAndFeatures } = useFetchModulesFeaturesQuery(
    { selectedTeam },
    {
      selectFromResult: ({ data }) => {
        const modifiedData = useMemo(() => {
          const disabledModules = {};
          const disabledFeatures = {};
          const options = data?.filter((module) => {
            if (module.disabled) {
              disabledModules[module.id] = true;
            }
            Array.isArray(module.moduleFeatures) &&
              module.moduleFeatures.every((feature) => {
                if (feature.disabled) {
                  disabledFeatures[feature.value] = true;
                  return false;
                }
              });
            return !module.disabled;
          }); // * Filter out disabled modules
          return { options, disabledModules, disabledFeatures };
        }, [data]);
        return { data: modifiedData };
      },
    }
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateNewPG = () => {
    handleAdd();
    setSearchTerm('');
    setSelectedType({ label: '' });
    setSelectedModule({ label: '' });
    setSelectedFeature({ label: '' });
    dispatch(saveProductGap({}));
  };

  return (
    <>
      <div className="header-container">
        {!selectedProductGap?.id && !newPGTitle ? (
          <>
            <div className="title_container">
              <h3 className="title">Product Gap</h3>
            </div>
            <button
              onClick={handleCreateNewPG}
              className="btn-pg-add"
              type="button"
            >
              <span className="startIcon">
                <AddIcon />
              </span>
              New Product Gap
              <span className="ripple"></span>
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                if (showJiraInfo) {
                  setShowJiraInfo(false);
                }
                if (newPGTitle) {
                  dispatch(saveNewPGTitle(''));
                  handleAdd();
                  // return;
                }
                dispatch(saveProductGap({}));
              }}
              className="back_btn"
              type="button"
            >
              <span className="icon_container">
                <ArrowIcon />
              </span>
              {selectedProductGap?.title || newPGTitle}
              <span className="ripple"></span>
            </button>
            {integrationType === 'jira' &&
              (selectedProductGap?.id || formik?.values?.jiraIssueId) && (
                <button
                  onClick={() => setShowJiraInfo((prev) => !prev)}
                  className="jira_info_btn"
                  type="button"
                >
                  JIRA Info
                  <span className="icon_container">
                    <ArrowDownIcon />
                  </span>
                  <span className="ripple"></span>
                </button>
              )}
          </>
        )}
      </div>
      {showJiraInfo && <JiraInfo data={selectedProductGap} formik={formik} />}
      {!selectedProductGap?.id && !newPGTitle ? (
        <>
          {/* SEARCH */}
          <div className="search_container">
            <button className="search_btn" type="button" aria-label="search">
              <SearchIcon />
              <span className="ripple"></span>
            </button>
            <div className="search_input_container">
              <input
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search for an existing product gap"
                type="text"
                aria-label="search pg items"
                className="search_input"
              />
            </div>
          </div>
          {/* FILTERS */}
          <div className="filters_parentContainer">
            <div className="filters_container">
              <label
                className="filter_label"
                data-shrink="true"
                htmlFor="pg-type"
                id="pg-type-label"
              >
                <span>Type:</span>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                  }}
                  isClearable
                  backspaceRemovesValue
                  className="pg_select"
                  styles={filterSelectStyles}
                  noOptionsMessage={() => noData()}
                  value={
                    !selectedType.label
                      ? { label: 'All', value: '' }
                      : selectedType
                  }
                  options={ALL_PRODUCT_GAP_TYPE}
                  onChange={(option) => {
                    if (!option || !option.value) {
                      setSelectedType({ label: '' });
                      return;
                    }
                    setSelectedType(option);
                  }}
                  menuPlacement="auto"
                />
              </label>
            </div>
            <div className="filters_container">
              <label
                className="filter_label"
                data-shrink="true"
                htmlFor="pg-module"
                id="pg-module-label"
              >
                <span>Module:</span>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                  }}
                  isClearable
                  backspaceRemovesValue
                  className="pg_select"
                  styles={filterSelectStyles}
                  noOptionsMessage={() => noData()}
                  options={
                    modulesAndFeatures?.options
                      ? [
                          { label: 'All', value: '' },
                          ...modulesAndFeatures.options,
                        ]
                      : []
                  }
                  value={
                    !selectedModule.label
                      ? { label: 'All', value: '' }
                      : selectedModule
                  }
                  onChange={(option) => {
                    if (!option || !option.value) {
                      setSelectedModule({ label: '' });
                      setFeatures([]);
                      return;
                    }
                    setSelectedModule(option);
                    setFeatures([
                      { label: 'All', value: '' },
                      ...option?.moduleFeatures,
                    ]);
                  }}
                  menuPlacement="auto"
                />
              </label>
            </div>
            <div className="filters_container">
              <label
                className="filter_label"
                data-shrink="true"
                htmlFor="pg-feature"
                id="pg-feature-label"
              >
                <span>Feature:</span>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                  }}
                  isClearable
                  backspaceRemovesValue
                  className="pg_select"
                  styles={filterSelectStyles}
                  noOptionsMessage={() =>
                    noData(
                      !selectedModule.label
                        ? 'Please select module'
                        : 'No options'
                    )
                  }
                  options={features.filter((feature) => {
                    // * Filter out New Feature and disabled features
                    return !feature.disabled && feature.label !== 'New Feature';
                  })}
                  value={
                    !selectedFeature.label
                      ? { label: 'All', value: '' }
                      : selectedFeature
                  }
                  onChange={(option) => {
                    if (!option || !option.value) {
                      setSelectedFeature({ label: '' });
                      return;
                    }
                    setSelectedFeature(option);
                  }}
                  menuPlacement="auto"
                />
              </label>
            </div>
          </div>
        </>
      ) : null}

      {/* Product gap list */}
      {/* Show usecase list when an item is selected */}
      {!selectedProductGap?.id && !newPGTitle ? (
        <ProductGapItemsList
          filters={{
            searchTerm:
              debouncedSearchTerm?.toLowerCase() || filters?.productGapItem,
            type: selectedType?.label || filters?.type?.label,
            module: selectedModule?.label || filters?.module?.label,
            feature: selectedFeature?.label || filters?.feature?.label,
          }}
          onListItemClick={onListClick}
          dealId={dealId}
          disabledItems={{
            modules: modulesAndFeatures?.disabledModules,
            features: modulesAndFeatures?.disabledFeatures,
          }}
        />
      ) : (
        <UseCasesList usecases={selectedProductGap?.pgItemDealMapping ?? []} />
      )}
    </>
  );
};

export default ProductGapSearch;
