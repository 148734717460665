import React from 'react';
import { components } from 'react-select';
import { ReactComponent as EditSVG } from '../Icons/NewImage/edit.svg';

//For checkbox presense inside dropdown
const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="value flex">
        <div>
          <input
            type="checkbox"
            checked={props.data.isSelected || props.isSelected}
            onChange={() => null}
          />{' '}
        </div>
        <div className="ml-5 flex-center">{props.label}</div>
      </div>
    </components.Option>
  );
};

//Component to remove close icon
const MultiValueRemove = (props) => {
  if (props) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

//Component to remove close icon
const RemoveIndicatorSeparator = (props) => {
  if (props) {
    return null;
  }
  return <components.IndicatorSeparator {...props} />;
};

//Component to add edit icon inside dropdown placeholder
const EditDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <EditSVG />
    </components.DropdownIndicator>
  );
};

// For "+n more" selected options in placeholder
const ValueContainer = ({ children, getValue, ...props }) => {
  const { inputValue } = props.selectProps;
  let maxToShow = 1;
  var length = getValue().length;
  let childrenArr = React.Children.toArray(children);
  // Displays the last selected item
  let displayChips = childrenArr.slice(
    childrenArr.length - 2,
    childrenArr.length
  );
  let shouldBadgeShow = length > maxToShow;
  let displayLength = length - maxToShow;

  return (
    <components.ValueContainer {...props}>
      {!inputValue && displayChips}
      <div className="dropdown-badge">
        {shouldBadgeShow && `+ ${displayLength} more`}
      </div>
    </components.ValueContainer>
  );
};

//Pipeline SalesOwner dropdown custom Styles
const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};

//Product Gap UseCases Multi-select dropdown custom styles
const customStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: '6px',
      maxWidth: '220px',
      minWidth: '120px',
    };
  },
  valueContainer: (base) => {
    return { ...base, fontWeight: '400', padding: '0px', fontSize: '13px' };
  },
  menu: (base) => {
    return { ...base, width: '180px' };
  },
  multiValue: (base) => {
    return { ...base, fontWeight: '400', backgroundColor: 'none' };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};

//Product Gap UseCases Date Range dropdown custom styles
const customStylesForSingleValue = {
  control: (base) => {
    return { ...base, borderRadius: '6px' };
  },
  singleValue: (base) => {
    return { ...base, fontWeight: '400', minHeight: '20px' };
  },
};

//Product Gap (with jira integration) Multi-select dropdown custom styles
const customStylesForJiraFilters = {
  control: (base) => {
    return {
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderColor: 'none',
      boxShadow: 'none',
    };
  },
  valueContainer: (base) => {
    return { ...base, fontSize: '14px' };
  },
  multiValue: (base) => {
    return { ...base, backgroundColor: 'transparent' };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
  indicatorSeparator: (base) => {
    return { ...base, display: 'none', visibility: 'hidden' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};

const useCaseStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: '6px',
      maxWidth: '220px',
      minWidth: '120px',
      minHeight: '30px',
      height: '30px',
    };
  },
  indicatorsContainer: (base) => {
    return {
      ...base,
      fontWeight: '400',
      padding: '0px',
      fontSize: '12px',
      height: '30px',
    };
  },
  dropdownIndicator: (base) => {
    return { ...base, padding: '0px 4px' };
  },
  clearIndicator: (base) => {
    return { ...base, padding: '0px 4px' };
  },
  valueContainer: (base) => {
    return { ...base, fontWeight: '400', padding: '0px', fontSize: '12px' };
  },
  singleValue: (base) => {
    return { ...base, fontWeight: '400', minHeight: '15px', fontSize: '85%' };
  },
  menu: (base) => {
    return { ...base, width: '180px' };
  },
  multiValue: (base) => {
    return { ...base, fontWeight: '400', backgroundColor: 'none' };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};

export {
  ValueContainer,
  MultiValueRemove,
  Option,
  RemoveIndicatorSeparator,
  EditDropdownIndicator,
  colourStyles,
  customStyles,
  customStylesForSingleValue,
  customStylesForJiraFilters,
  useCaseStyles,
};
