import { createSlice } from '@reduxjs/toolkit';
import { URLS_BUSINESS_SERVICE } from '../../../Constant/urls';
import { businessService } from '../../../app/api/businessService';

const initialState = {
  refetchActivityTableApi: false,
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    fetchActivityTableApi: (state, action) => {
      state.refetchActivityTableApi = action.payload;
    },
  },
});

export const extendedActivitySlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    addActivity: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.activity.addActivity,
        method: 'POST',
        body,
      }),
    }),
    updateActivity: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.activity.updateActivity,
        method: 'POST',
        body,
      }),
    }),
    getPaginatedSalesActivities: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.activity.getPaginatedActivities,
        method: 'POST',
        body,
      }),
    }),
    deleteActivity: builder.query({
      query: ({ activityId }) =>
        `${URLS_BUSINESS_SERVICE.activity.deleteActivity}?id=${activityId}`,
    }),
  }),
});

export const selectRefetchActivityTableApi = (state) =>
  state.activity.refetchActivityTableApi;

export const { fetchActivityTableApi } = activitySlice.actions;

export const {
  useAddActivityMutation,
  useUpdateActivityMutation,
  useGetPaginatedSalesActivitiesMutation,
  useLazyDeleteActivityQuery,
} = extendedActivitySlice;

export default activitySlice.reducer;
