import addMonths from 'date-fns/addMonths';
import {
  startOfQuarter,
  subQuarters,
  lastDayOfQuarter,
  endOfQuarter,
  subWeeks,
  endOfWeek,
  startOfWeek,
  startOfToday,
  endOfToday,
  addWeeks,
  format,
  add,
  sub,
} from 'date-fns';

const getQuarterlyDateRange = (date, quarter) => {
  date = date ?? new Date();
  let year = date.getFullYear();
  if (quarter === 5) {
    year += 1;
    quarter = 1;
  }
  if (quarter === 0) {
    year -= 1;
    quarter = 4;
  }
  const quarterDateRanges = {
    1: {
      from: toISODateForFilter(new Date(year, 0, 1)),
      to: toISODateForFilter(new Date(year, 3, 1)),
    },
    2: {
      from: toISODateForFilter(new Date(year, 3, 1)),
      to: toISODateForFilter(new Date(year, 6, 1)),
    },
    3: {
      from: toISODateForFilter(new Date(year, 6, 1)),
      to: toISODateForFilter(new Date(year, 9, 1)),
    },
    4: {
      from: toISODateForFilter(new Date(year, 9, 1)),
      to: toISODateForFilter(new Date(year + 1, 0, 1)),
    },
  };
  return quarterDateRanges[quarter];
};

const toISODate = (date) => {
  return new Date(date).toISOString().split('T')[0];
};

const convertDateToString = (date) => {
  let formatedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toJSON();
  //FORMAT 'YYYY-MM-DD'
  return formatedDate.slice(0, 10);
};

const toISODateForFilter = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  // toISOString() converts current timezone to UTC timezone.
  // The getTimezoneOffset() method returns the difference, in minutes,
  // between a date as evaluated in the UTC time zone, and the same date as evaluated in the local time zone
};

const addDays = (days, currentDate) => {
  const date = currentDate ?? new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
};

const subtractDays = (days, currentDate) => {
  const date = currentDate ?? new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString().split('T')[0];
};

const getTodayDiff = (add = 2, subtract = 1) => {
  return {
    from: subtractDays(subtract),
    to: addDays(add),
  };
};

const getCurrentWeek = (date) => {
  // currentDay = 3
  date = date ? date : new Date();
  let current = new Date(date); // get current date
  let currentDayOfWeek = -current.getDay();
  let weekend = currentDayOfWeek + 7; // weekend = -currentDay + 6 = 3 add 3 to todays date gives saturday
  let sunday = addDays(currentDayOfWeek - 1, new Date(date));
  let saturday = addDays(weekend, new Date(date));

  return {
    from: sunday,
    to: saturday,
  };
};

const getCurrentWeekForFilter = (date) => {
  // Calendar filter
  date = date ? date : new Date();
  let current = new Date(date); // get current date
  let currentDayOfWeek = -current.getDay();
  let weekend = currentDayOfWeek + 6; // weekend = -currentDay + 6 = 3 add 3 to todays date gives saturday
  let sunday = addDays(currentDayOfWeek, new Date(date));
  let endRange = addDays(weekend, new Date(date));

  return {
    from: sunday,
    to: endRange,
  };
};

const getCurrentMonth = (date) => {
  date = date ? date : new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  //month + 1 is because javascript month starts from 0
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  let startDate = toISODateForFilter(new Date(year, month, 1));
  let endDate = toISODateForFilter(new Date(year, month, daysInMonth));
  return {
    from: startDate,
    to: endDate,
  };
};

const getCurrentQuarter = (date, addQuarter) => {
  date = date ? date : new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  //find which quarter the date belongs to
  const quarter = addQuarter
    ? Math.floor(month / 3 + addQuarter + 1)
    : Math.floor(month / 3 + 1);
  return getQuarterlyDateRange(date, quarter);
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

const convertMsToHM = (milliseconds) => {
  let hours = milliseconds / (1000 * 60 * 60);
  let absoluteHours = Math.floor(hours);
  let totalHours = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  let minutes = (hours - absoluteHours) * 60;
  let absoluteMinutes = Math.floor(minutes);
  let totalMinutes =
    absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

  return `${totalHours}h ${totalMinutes}m`;
};

const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate);

  const dates = [];

  while (date <= endDate) {
    dates.push(toISODateForFilter(new Date(date)));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

const getFormattedDate = (date) => {
  if (!date) {
    return;
  }
  const dateObject = new Date(date);
  return (
    dateObject.getDate() +
    ' ' +
    dateObject.toLocaleString('default', { month: 'short' }) +
    ',' +
    dateObject.getFullYear()
  );
};

const getFormattedTimeFromDate = (date) => {
  if (!date) {
    return;
  }
  const dateObject = new Date(date);
  return dateObject.getHours() + '.' + dateObject.getMinutes();
};

const getFormattedTime = (metric) => {
  return metric ? `${Math.floor(metric / 60)}h ${metric % 60}m` : '0h 0m';
};

//to format date using pattern
const formatDate = (date, pattern) => {
  return format(new Date(date), `${pattern}`, { hour12: true });
};

const getDateByOperator = (OPERATOR) => {
  const today = new Date();
  let fromDate = '';
  let toDate = '';
  let date = '';
  switch (OPERATOR) {
    case 'TODAY':
      fromDate = addDays(-1);
      toDate = addDays(1);
      break;
    case 'TOMORROW':
      fromDate = toISODateForFilter(today);
      toDate = addDays(2);
      break;
    case 'YESTERDAY':
      fromDate = addDays(-2);
      toDate = toISODateForFilter(today);
      break;
    case 'THIS_WEEK':
      date = getCurrentWeek();
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'NEXT_WEEK':
      date = addDays(7);
      date = getCurrentWeek(date);
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'PREVIOUS_WEEK':
      date = addDays(-7);
      date = getCurrentWeek(date);
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'THIS_QUARTER':
      date = getCurrentQuarter();
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'NEXT_QUARTER':
      date = getCurrentQuarter(null, 1);
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'PREVIOUS_QUARTER':
      date = getCurrentQuarter(null, -1);
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'THIS_MONTH':
      date = getCurrentMonth();
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'NEXT_MONTH':
      date = addMonths(today, 1);
      date = getCurrentMonth(date);
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'PREVIOUS_MONTH':
      date = addMonths(today, -1);
      date = getCurrentMonth(date);
      fromDate = date.from;
      toDate = date.to;
      break;
    case 'LAST_6_MONTHS':
      date = addMonths(today, -6);
      fromDate = toISODate(date);
      toDate = toISODate(today);
      break;
    default:
      fromDate = '';
      toDate = '';
      break;
  }
  return { fromDate, toDate };
};

const getDateObjectByOperator = (OPERATOR) => {
  // For calendar filter
  // * This may need to be refactored when timezone is included in the request
  const today = startOfToday();
  let fromDate = sub(today, {
    days: 1,
  });
  let toDate = add(today, {
    days: 1,
  });
  let date = '';
  let startDate = '';
  let endDate = '';
  switch (OPERATOR) {
    case 'TODAY':
      fromDate = fromDate;
      toDate = toDate;
      break;
    case 'TOMORROW':
      fromDate = today;
      toDate = add(today, {
        days: 2,
      });
      break;
    case 'YESTERDAY':
      fromDate = sub(today, {
        days: 2,
      });
      toDate = today;
      break;
    case 'THIS_WEEK':
      startDate = startOfWeek(today);
      endDate = endOfWeek(today);
      fromDate = sub(startDate, {
        days: 1,
      });
      toDate = add(endDate, {
        days: 1,
      });
      break;
    case 'NEXT_WEEK':
      date = addWeeks(today, 1);
      startDate = startOfWeek(date);
      endDate = endOfWeek(date);
      fromDate = sub(startDate, {
        days: 1,
      });
      toDate = add(endDate, {
        days: 1,
      });
      break;
    case 'PREVIOUS_WEEK':
      date = subWeeks(today, 1);
      startDate = startOfWeek(date);
      endDate = endOfWeek(date);
      fromDate = sub(startDate, {
        days: 1,
      });
      toDate = add(endDate, {
        days: 1,
      });
      break;

    default:
      fromDate = '';
      toDate = '';
      break;
  }
  return { fromDate, toDate };
};

const getDateByOperatorForFilter = (OPERATOR) => {
  // For calendar filter
  const today = startOfToday();
  let fromDate = '';
  let toDate = '';
  let date = '';
  switch (OPERATOR) {
    case 'TODAY':
      fromDate = startOfToday();
      toDate = endOfToday();
      break;
    case 'TOMORROW':
      fromDate = add(today, {
        days: 1,
      });
      toDate = add(today, {
        days: 1,
        hours: 23,
        minutes: 59,
        seconds: 0,
      });
      break;
    case 'YESTERDAY':
      fromDate = sub(today, {
        days: 1,
      });
      toDate = sub(today, {
        minutes: 1,
      });
      break;
    case 'THIS_WEEK':
      fromDate = startOfWeek(today);
      toDate = endOfWeek(today);
      break;
    case 'NEXT_WEEK':
      date = addWeeks(today, 1);
      fromDate = startOfWeek(date);
      toDate = endOfWeek(date);
      break;
    case 'PREVIOUS_WEEK':
      date = subWeeks(today, 1);
      fromDate = startOfWeek(date);
      toDate = endOfWeek(date);
      break;

    default:
      fromDate = '';
      toDate = '';
      break;
  }
  return { fromDate, toDate };
};

//Format => Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)

const getCurrentQuarterStartEnd = () => {
  const currentDate = new Date();
  const startOfCurrentQuarter = startOfQuarter(currentDate);
  const endOfCurrentQuarter = new Date(endOfQuarter(currentDate, 1));

  return { startOfCurrentQuarter, endOfCurrentQuarter };
};

const getPreviousQuarterStartEnd = () => {
  const previousQuarterStart = startOfQuarter(subQuarters(new Date(), 1));
  const previousQuarterEnd = lastDayOfQuarter(subQuarters(new Date(), 1));
  return { previousQuarterStart, previousQuarterEnd };
};

const getNextQuarterStartEnd = () => {
  const now = new Date();
  let nextQuarterStart;
  let nextQuarterEnd;

  if (now.getMonth() < 3) {
    // next quarter starts in April
    nextQuarterStart = new Date(now.getFullYear(), 3, 1);
    nextQuarterEnd = new Date(now.getFullYear(), 5, 30);
  } else if (now.getMonth() < 6) {
    // next quarter starts in July
    nextQuarterStart = new Date(now.getFullYear(), 6, 1);
    nextQuarterEnd = new Date(now.getFullYear(), 8, 30);
  } else if (now.getMonth() < 9) {
    // next quarter starts in October
    nextQuarterStart = new Date(now.getFullYear(), 9, 1);
    nextQuarterEnd = new Date(now.getFullYear(), 11, 31);
  } else {
    // next quarter starts in January of the next year
    nextQuarterStart = new Date(now.getFullYear() + 1, 0, 1);
    nextQuarterEnd = new Date(now.getFullYear() + 1, 2, 31);
  }
  return { nextQuarterStart, nextQuarterEnd };
};

const PreviousMonthDates = () => {
  const today = new Date();

  // Get the current month and year
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  // Calculate the previous month and year
  let previousMonth;
  let previousYear;
  if (currentMonth === 0) {
    // Handle January (month 0)
    previousMonth = 11;
    previousYear = currentYear - 1;
  } else {
    previousMonth = currentMonth - 1;
    previousYear = currentYear;
  }

  // Get the start and end dates of the previous month
  const previousMonthStart = new Date(previousYear, previousMonth, 1);
  const previousMonthEnd = new Date(previousYear, previousMonth + 1, 0);

  return { previousMonthStart, previousMonthEnd };
};

const getCurrentMonthDates = () => {
  const date = new Date();

  // Get the first day of the month
  const currentMonthstart = new Date(date.getFullYear(), date.getMonth(), 1);

  // Get the last day of the month
  const currentMonthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { currentMonthstart, currentMonthEnd };
};

const getNextMonthDates = () => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const nextMonth = (currentMonth + 1) % 12;
  const nextMonthYear =
    currentMonth === 11 ? today.getFullYear() + 1 : today.getFullYear();

  const nextMonthStartDate = new Date(nextMonthYear, nextMonth, 1);
  const nextMonthEndDate = new Date(nextMonthYear, nextMonth + 1, 0);

  return {
    nextMonthStartDate,
    nextMonthEndDate,
  };
};

const getPreviousWeekStartEnd = () => {
  const currentDate = new Date();
  const startOfCurrentWeek = startOfWeek(currentDate);
  const endOfCurrentWeek = endOfWeek(currentDate);
  const startOfPreviousWeek = subWeeks(startOfCurrentWeek, 1);
  const endOfPreviousWeek = subWeeks(endOfCurrentWeek, 1);
  return { startOfPreviousWeek, endOfPreviousWeek };
};

const getCurentWeekStartEnd = () => {
  const currentDate = new Date();
  const currentWeekStartDate = startOfWeek(currentDate);
  const currentWeekEndDate = endOfWeek(currentDate);
  return { currentWeekStartDate, currentWeekEndDate };
};

const getNextWeekStartEnd = () => {
  const currentDate = new Date();
  const startOfCurrentWeek = startOfWeek(currentDate);
  const endOfCurrentWeek = endOfWeek(currentDate);
  const startOfNextWeek = addWeeks(startOfCurrentWeek, 1);
  const endOfNextWeek = addWeeks(endOfCurrentWeek, 1);
  return { startOfNextWeek, endOfNextWeek };
};

export {
  toISODate,
  toISODateForFilter,
  addDays,
  subtractDays,
  getTodayDiff,
  getCurrentWeek,
  getCurrentWeekForFilter,
  getCurrentMonth,
  getCurrentQuarter,
  convertMsToHM,
  getDateByOperator,
  getDateByOperatorForFilter,
  getDateObjectByOperator,
  getDatesInRange,
  getFormattedDate,
  getFormattedTime,
  getFormattedTimeFromDate,
  getQuarterlyDateRange,
  formatDate,
  getCurrentMonthDates,
  getPreviousQuarterStartEnd,
  PreviousMonthDates,
  getNextQuarterStartEnd,
  getCurrentQuarterStartEnd,
  getNextMonthDates,
  getPreviousWeekStartEnd,
  getCurentWeekStartEnd,
  getNextWeekStartEnd,
  convertDateToString,
};
