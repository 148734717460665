import React, { useState } from 'react';
import { toast } from 'react-toastify';
import TaskManager from './TaskManager';
import DeleteTask from './DeleteTask';
import ReopenTaskConfirm from './ReopenTaskConfrim';
import AddTaskNoteDescriptionDrawer from '../AddDrawerContent/AddTaskNoteDescriptionDrawer';
import { useCompleteTaskMutation } from './tasksSlice';

const Tasks = ({ isReadOnly }) => {
  const [completeTask, { isLoading }] = useCompleteTaskMutation();
  const [currentSelectedTask, setCurrentSelectedTask] = useState(null);
  const [reopenTask, setReopenTask] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showUpdateTaskModal, setShowUpdateTaskModal] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showReopenTaskModal, setShowReopenTaskModal] = useState(false);

  const handleAddTaskOpen = () => setShowAddTaskModal(true);

  const handleUpdateTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    setShowUpdateTaskModal(true);
  };

  const handleDeleteTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    setShowDeleteTaskModal(true);
  };
  const handleDeleteTaskCancel = () => {
    setCurrentSelectedTask(null);
    setShowDeleteTaskModal(false);
  };
  const handleReopenTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    setShowReopenTaskModal(true);
  };
  const handleReopenTaskCancel = () => {
    setReopenTask(false);
    setCurrentSelectedTask(null);
    setShowReopenTaskModal(false);
  };
  const handleReopenConfirm = () => {
    setReopenTask(true);
    setShowReopenTaskModal(false);
    setShowUpdateTaskModal(true);
  };
  const handleCompleteTaskOpen = (task) => {
    setCurrentSelectedTask(task);
    let requestData = {
      id: task.id,
      dealId: task.dealId,
    };
    completeTask({ body: requestData }).catch((error) => {
      console.warn('Error:', error);
      toast.error('Error: Unable to mark task as completed');
    });
  };

  const handleCloseAddTaskDrawer = () => {
    setShowAddTaskModal(false);
  };

  const handleCloseUpdateTaskDrawer = () => {
    if (reopenTask) {
      setReopenTask(false);
    }
    setShowUpdateTaskModal(false);
  };

  return (
    <>
      {showDeleteTaskModal && (
        <DeleteTask
          task={currentSelectedTask}
          showModal={showDeleteTaskModal}
          handleClose={handleDeleteTaskCancel}
        />
      )}
      {showReopenTaskModal && (
        <ReopenTaskConfirm
          showModal={showReopenTaskModal}
          handleClose={handleReopenTaskCancel}
          handleConfirm={handleReopenConfirm}
        />
      )}

      {showAddTaskModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showAddTaskModal}
          closeDrawer={handleCloseAddTaskDrawer}
          tab="Task"
          action="add"
          key="dashboard-add-task"
        />
      )}
      {showUpdateTaskModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showUpdateTaskModal}
          closeDrawer={handleCloseUpdateTaskDrawer}
          tab="Task"
          action="update"
          reopenTask={reopenTask}
          initialValues={currentSelectedTask}
          key="dashboard-update-task"
        />
      )}
      <TaskManager
        handleAdd={handleAddTaskOpen}
        handleUpdate={handleUpdateTaskOpen}
        handleDelete={handleDeleteTaskOpen}
        handleRedirect={true}
        handleComplete={handleCompleteTaskOpen}
        handleReopen={handleReopenTaskOpen}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

export default Tasks;
