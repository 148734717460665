import React from 'react';
import { ReactComponent as Contact } from '../Icons/contact.svg';
import { ReactComponent as Support } from '../Icons/support.svg';
import { ReactComponent as Onboarding } from '../Icons/training.svg';
import { ReactComponent as Training } from '../Icons/shakehands.svg';
import styles from '../styles/pages/perks.module.scss';

const Perks = () => {
  return (
    <>
      <h5 className={styles.perks_heading}>Perks of PreSkale</h5>
      <ul className={styles.perks_list}>
        <li className={styles.perks}>
          <Contact /> Dedicated account manager
        </li>
        <li className={styles.perks}>
          <Support /> Priority Support Channel{' '}
        </li>
        <li className={styles.perks}>
          <Onboarding /> Personalised Onboarding
        </li>
        <li className={styles.perks}>
          <Training /> Hands-on training
        </li>
      </ul>
    </>
  );
};

export default Perks;
