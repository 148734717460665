import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_URL, AXIOS_DOMAIN_NAME } from '../../_helpers/axios';
import { handleTokenExpired } from '../utils/apiUtils';

const businessBaseQuery = fetchBaseQuery({
  baseUrl: DOMAIN_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.idToken;
    const companyId = localStorage.getItem('companyId');
    if (
      token &&
      endpoint !== 'checkExistingUsers' &&
      endpoint !== 'signupUser'
    ) {
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('Access-Control-Allow-Origin', AXIOS_DOMAIN_NAME);
      headers.set('X-TENANT-ID', companyId);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

export const businessServiceWithReauth = async (args, api, extraOptions) => {
  let result = await businessBaseQuery(args, api, extraOptions);
  return handleTokenExpired(result, api, args, businessBaseQuery, extraOptions);
};

// Define a service using a base URL and expected endpoints
export const businessService = createApi({
  reducerPath: 'business',
  baseQuery: businessServiceWithReauth,
  tagTypes: [
    'Calendar',
    'Company',
    'Users',
    'Deals',
    'Tasks',
    'Notes',
    'ProductGap',
    'Crm',
    'Solutions',
    'Contributions',
    'ActivitySummary',
    'AccountSummary',
    'Modules',
    'AdminOps',
    'Accounts',
    'Collaborators',
    'CustomSection',
    'Pipeline',
    'Team',
  ],
  endpoints: (builder) => ({}),
});
