import React from 'react';
import ExternalContacts from './ExternalContacts';
import InternalCollabrators from './InternalCollabrators';

const Collabrators = ({ readOnly = false, ...props }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div className="collaborator-container">
      <InternalCollabrators
        user={user}
        dealId={props.dealId}
        accountId={props.accountId}
        presalesUser={props.presalesUser}
        readOnly={readOnly}
      />
      <ExternalContacts
        user={user}
        dealId={props.dealId}
        accountId={props.accountId}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Collabrators;
