const CALENDAR_TYPE_OPTIONS = [
  {
    label: 'Google',
    value: 'google',
  },
  {
    label: 'Outlook',
    value: 'microsoft',
  },
];
const CRM_TYPE_OPTIONS = [
  {
    label: 'Salesforce',
    value: 'salesforce',
  },
  {
    label: 'Zoho',
    value: 'zoho',
  },
  {
    label: 'Hubspot',
    value: 'hubspot',
  },
  {
    label: 'Freshworks',
    value: 'freshworks',
  },
];
const PRODUCTGAP_INTEGRATION_OPTIONS = [
  {
    label: 'Jira',
    value: 'jira',
  },
  {
    label: 'Product Board',
    value: 'productboard',
  },
  {
    label: 'Zoho Bugs',
    value: 'zohobugs',
  },
];
const FEATURE_LIST_OPTIONS = [
  {
    label: 'Custom Section',
    value: 'is_custom_section_enabled',
  },
  {
    label: 'Product Gap',
    value: 'is_product_gap_enabled',
  },
  {
    label: 'Check List',
    value: 'is_evaluation_plan_enabled',
  },
  {
    label: 'Solutions',
    value: 'is_solution_enabled',
  },
  {
    label: 'Solution Library',
    value: 'is_solution_usecase_manager_enabled',
  },
  {
    label: 'Calendar',
    value: 'is_calendar_enabled',
  },
  {
    label: 'Overview',
    value: 'is_overview_enabled',
  },
  {
    label: 'Deal Velocity Reports',
    value: 'is_deal_velocity_reports_enabled',
  },
  {
    label: 'Solution Analytics',
    value: 'is_solution_analytics_enabled',
  },
  {
    label: 'Productgap Analytics',
    value: 'is_productgap_analytics_enabled',
  },
  {
    label: 'Calendar Analytics',
    value: 'is_calendar_analytics_enabled',
  },
  {
    label: 'Set owner as presales user',
    value: 'set_owner_as_presales',
  },
  {
    label: 'Manager Analytics',
    value: 'is_manager_analytics_enabled',
  },
  {
    label: 'Issue Manager',
    value: 'is_issue_manager_enabled',
  },
];

const NAV_PAGE_OPTIONS = [
  {
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    label: 'Pipeline',
    value: 'pipeline',
  },
  {
    label: 'Statistics',
    value: 'statistics',
  },
  {
    label: 'Productgap Usecase',
    value: 'product_gap_use_case',
  },
  {
    label: 'Manager Dashboard',
    value: 'manager_dashboard',
  },
  {
    label: 'Request Assistance Manager',
    value: 'request_assistance_manager',
  },
  {
    label: 'Request Form',
    value: 'request_form',
  },
];
const DEFAULT_STAGE_VIEW_OPTIONS = [
  {
    label: 'Preskale Stage',
    value: 'preskale',
  },
  {
    label: 'CRM Stage',
    value: 'crm',
  },
];
const LANDING_PAGE_OPTIONS = [
  {
    label: 'Dashboard',
    value: 'Dashboard',
  },
  {
    label: 'Manager Dashboard',
    value: 'ManagerDashboard',
  },
  {
    label: 'Pipeline',
    value: 'Accounts',
  },
  {
    label: 'Statistics',
    value: 'Statistics',
  },
  {
    label: 'Libraries',
    value: 'ProductGapUseCases', //TODO: change this to Playbook
  },
  {
    label: 'Request Form',
    value: 'RequestForm',
  },
  {
    label: 'AdminOps',
    value: 'AdminOps',
  },
];

const PRODUCT_INTEGRATIONS = [
  {
    label: 'Preskale',
    value: 'preskale',
  },
  {
    label: 'JIRA',
    value: 'jira',
  },
  {
    label: 'Product board',
    value: 'productboard',
  },
];
export {
  LANDING_PAGE_OPTIONS,
  DEFAULT_STAGE_VIEW_OPTIONS,
  NAV_PAGE_OPTIONS,
  FEATURE_LIST_OPTIONS,
  PRODUCTGAP_INTEGRATION_OPTIONS,
  CRM_TYPE_OPTIONS,
  CALENDAR_TYPE_OPTIONS,
  PRODUCT_INTEGRATIONS,
};
