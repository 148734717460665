export const URLS_BUSINESS_SERVICE = {
  accounts: {
    accountDetails: 'accounts/account-details',
    accountSummary: 'accounts/account-summary',
    allDeals: 'accounts/all-deals',
    crmStages: 'accounts/crm-stages',
    dealDetails: 'accounts/deal-details',
    dealOverview: 'accounts/dealoverview',
    dealClosed: 'accounts/deal-closed',
    getByStatus: 'accounts/get-accounts-by-status',
    search: 'accounts/search',
    starterProfile: 'accounts/starter-profile',
    individualTrends: 'accounts/individual-reporting-trends',
    individualReporting: 'accounts/individual-reporting',
    dealInfoForRAM: 'accounts/dealInfoForRAM',
    assignPresalesOwner: 'accounts/assign-presales-owner',
    bulkAssignPresalesOwner: 'accounts/bulk-assign-presales-owner',
    getDealsBySalesPerson: 'accounts/get-deals-by-sales-owner',
    salesOwners: 'accounts/owners',
    basicAccountDetails: 'accounts/basic-account-details',
    createOpportunity: 'accounts/create-opportunity',
    dealSummary: 'accounts/all-deals-summary',
    editPresalesUser: 'accounts/edit-presales-user',
    syncOpportunity: 'accounts/sync-opportunity',
    getDealTypes: 'stage/getAllDealTypes',
    getAllRequests: 'accounts/get-all-requests',
    updatePreskaleDeal: 'accounts/update-preskale-deal',
    deleteDeal: 'accounts/delete-deal',
    getAllUsersForProductRole: 'accounts/all-team-users',
    updatePipelineTable: 'accounts/pipeline-bulk-update',
    updateAllRecordsInPipeline: 'accounts/pipeline-all-records-update',
    getAllAccounts: 'accounts/all-accounts',
  },
  activity: {
    addActivity: 'activity/add-activity',
    updateActivity: 'activity/update-activity',
    getPaginatedActivities: 'activity/get-paginated-activities',
    deleteActivity: 'activity/delete-sales-activity',
  },
  activities: {
    getAll: 'activities/get', // Not used anywhere
    getAllByAccount: 'activities/account', // Not used anywhere
    activitySummary: 'activities/activity-summary',
  },
  organization: {
    features: 'organization/admin-company-config',
    updateUserCount: 'organization/update-license-count',
    getAllUsersByRole: 'organization/get-users-by-role',
    getAllCompanyUsers: 'organization/get-all-company-users',
    getAllTeamsByCompanyId: 'organization/get-all-teams-by-company',
    updateTimeZone: 'organization/update-timezone',
    updateCrmMapper: 'organization/update-crm-mapper',
    getCrmMapper: 'organization/get-crm-mapper',
    getContributions: 'organization/get-contributions-by-companyId',
    updateNotificationCenter: 'organization/update-notification-center',
    getContributionForNotification:
      'organization/get-contribution-for-notification',
    updateReports: 'organization/update-reports',
    getAllUserPreferences: 'organization/get-all-user-preferences',
    updateTaskNotificationPreference:
      'organization/update-task-notification-preference',
  },
  collaborators: {
    getAll: 'collabrators/get',
    getRelatedContacts: 'collabrators/get-related-contacts',
    getExternalContacts: 'collabrators/get-external-contacts',
    deleteExternalContact: 'collabrators/delete-external-contact',
    delete: 'collabrators/delete',
    add: 'collabrators/add',
    addExternalContact: 'collabrators/add-external-contact',
    getExternalContactsEmail: 'collabrators/get-external-contacts-email',
  },
  contribution: {
    getParentContribution: 'contribution/get-parent-contribution',
    addParentContribution: 'contribution/add-parent-contribution',
    getOutcomeTags: 'contribution/getOutcomeTags',
    saveOutcomeTags: 'contribution/saveOutcomeTags',
    getTeamMembersOutcomeTags: 'contribution/get-team-members-outcome-tags',
    updateOutcome: 'contribution/update-outcome',
    deleteOutcome: 'contribution/delete-outcome',
    mergeOutcome: 'contribution/merge-outcome',
  },
  company: {
    getDetails: 'company/get',
    getAll: 'company/get-all-company',
    crmDetails: 'company/crm-details',
    crmDisconnect: 'company/crm-disconnect',
    productIntegrationDisconnect: 'company/product-integration-disconnect',
    deactivate: 'company/deactivate', // Not yet implemented
    updateProductBoardIntegration: 'company/update-productboard',
    reports: 'company/dashboard_reports',
    createCompany: 'company/add',
    features: 'company/company-config',
    updateFeatures: 'company/update-company-config',
    configureDefaultPipeline: 'company/configure-default-pipeline',
  },
  dashboard: {
    activeSyncIntegrations: 'dashboard/active-sync-integrations',
    avgTimeSpent: 'dashboard/avg-time-spent',
    revenue: 'dashboard/revenue',
  },
  evaluationPlan: {
    getClosedAccount: 'evaluationPlan/get-closed-account-ep',
    getTaskForStage: 'evaluationPlan/getTaskForStage',
    addPlanForAllDeals: 'evaluationPlan/addEvPlanForAllDeals', // Not used anywhere - Post with getmap
    saveCompletedTasks: 'evaluationPlan/saveCompletedTasks',
  },
  lightningDashboard: {
    activityTrend: 'lightningDashboard/activityTrend',
    outcomeTrends: 'lightningDashboard/outcomeTrends',
    stageTrends: 'lightningDashboard/stageTrends',
    teamWorkload: 'lightningDashboard/teamWorkload',
    upcomingMeetings: 'lightningDashboard/upcomingMeetings',
  },
  meetings: {
    getAll: 'meetings/get',
    getAllByAccount: 'meetings/account',
    getListView: 'meetings/get-list-view-response',
    getOutcomeOverview: 'meetings/outcome-overview',
    saveAdditionalEvInfo: 'meetings/save-additional-event-information',
    saveEventListView: 'meetings/save-event-list-view',
    getWorkbookEvents: 'meetings/get-workbook-events',
    reSyncCalendarAutoTag: 'meetings/re-sync-opportunity-and-outcome-auto-tag',
    reSyncOpportunityEventsAutoTag: 'meetings/re-sync-opportunity-events',
  },
  notes: {
    add: 'notes/add',
    update: 'notes/update',
    getAll: 'notes/get',
    getByAccount: 'notes/get-notes-by-account',
    getByCriteria: 'notes/get-notes-by-criteria',
    getNotes: 'notes/get-notes',
    delete: 'notes/delete',
  },
  productGap: {
    add: 'productgap/add',
    delete: 'productgap/delete',
    update: 'productgap/update',
    getByAccount: 'productgap/get-account-productgap',
    getClosedAccount: 'productgap/get-closed-account-pg',
    getJiraProjects: 'productgap/jira/projects',
    getJiraStatus: 'productgap/jira/status',
    getJiraIssueTypes: 'productgap/jira/issue_types',
    getJiraPriorities: 'productgap/jira/priorities', // Not used
    getJiraIssuesFromSearch: 'productgap/jira/issue/search',
    getJiraIssue: 'productgap/jira/issue/detail',
    getJiraCommentsForIssue: 'productgap/jira/issue/comments',
    addJira: 'productgap/jira/add',
    updateJira: 'productgap/jira/update',
    getJiraUsers: 'productgap/jira/users',
    modulesAndItems: 'productgap/modulesAndItems',
    useCases: 'productgap/get-pg-usecase',
    getPgAnalytics: 'productgap/get-pg-analytics',
    getPgUnderFeatures: 'productgap/get-pg-under-feature',
    getPgItemsUnderModule: 'productgap/get-pgi-under-module',
    getRecentlyAddedPgItems: 'productgap/get-recently-added-pgi',
    getPgItemsByPgId: 'productgap/get-pgi-by-pg',
    enableDisablePg: 'productgap/enable-disable-pg',
    enableDisablePgi: 'productgap/enable-disable-pgi',
    updateStatus: 'productgap/update-status',
    updateProductGapTitle: 'productgap/update-pg-title',
    updateProductGapItemTitle: 'productgap/update-pgi-title',
    addProductGapItem: 'productgap/add-product-gap-item',
    addProductGap: 'productgap/add-product-gap',
    // getJiraIssues: 'productgap/get-jira-issues',
    updatePgItemJira: 'productgap/update-pgi-jira',
    getRevenueBlocked: 'productgap/get-average-revenue-blocked',
    getPgItemsCount: 'productgap/get-pgitems-count',
    getPGItemByFeatureId: 'productgap/get-pg-by-feature-list',
  },
  reports: {
    events: 'reports/ind-trends-events',
    outcomes: 'reports/ind-trends-outcomes',
    meetings: 'reports/ind-all-meetings', // Not used - post
    successItems: 'reports/ind-trends-si',
    productGaps: 'reports/ind-trends-product-gap',
    opportunityManager: 'reports/man-all-opp',
    salesManager: 'reports/man-sales-trends',
    successItemsManager: 'reports/man-trends-si',
    eventsManager: 'reports/man-trends-events',
    outcomesManager: 'reports/man-trends-outcomes',
    utilizationReport: 'reports/user-utilization-report',
    bestResource: 'reports/best-resource',
    getEmbedUrl: 'reports/aws-quick-sight/dashboards',
    generateCustomExtract: 'reports/reportsNotification',
  },
  stage: {
    accounts: 'stage/accounts',
    activeDays: 'stage/stage-active-days',
    getParentStages: 'stage/parent-stages',
    crmStage: 'stage/crm-parent-stages',
    getActivitiesByStage: 'stage/stage-activities',
    updateAccountStage: 'stage/update-account-stage',
    updateAccountCrmStage: 'stage/update-account-crm-stage',
    addParentStage: 'stage/add-parent-stage',
    getDeals: 'stage/getRecentDeals',
    getTotalRevenue: 'stage/totalRevenue',
    getParentStagesForCheckList: 'stage/parent-stages-for-check-list',
    changeSortOrder: 'stage/change-sort-order',
    updateStageName: 'stage/update-stage-name',
    unassignedDeals: 'stage/getUnassignedDeals',
  },
  successItem: {
    getAll: 'criteria/get', // Not used anywhere
    addBulk: 'criteria/add-bulk',
    addParentCriteria: 'criteria/add-parent-criteria',
    complete: 'criteria/complete',
    delete: 'criteria/delete',
    update: 'criteria/update',
    getAllPlaybooks: 'criteria/get-all-playbooks',
    getByAccount: 'criteria/get-account-criteria',
    getParent: 'criteria/get-parent-criteria',
    getReport: 'criteria/get-criteria-report',
    getUnused: 'criteria/get-unused-criteria',
  },
  teams: {
    addUser: 'teams/add-user',
    create: 'teams/create',
    getAll: 'teams/get-all-teams',
    getById: 'teams/get-team',
    getUsersForAdminManager: 'teams/get-admin-manager-users',
    createTeamByCompanyId: 'teams/create-team-by-company',
    getPipelineAccessibility: 'teams/get-pipeline-accessibility',
    updatePipelineAccessibility: 'teams/update-pipeline-accessibility',
    getTeamsByManager: 'teams/get-teams-by-manager',
    getAllTeamsPlaybook: 'teams/get-all-teams-playbook',
    getAllUserTeams: 'teams/get-all-user-teams',
    getAllTeams: 'teams/get-all-active-inactive-teams',
    deleteTeam: 'teams/delete',
    editTeam: 'teams/edit',
    manageTeamMembers: 'teams/manage-team-members',
    updateGeneralSettings: 'teams/update-settings',
  },
  tasks: {
    add: 'task/add-task',
    complete: 'task/complete-task',
    update: 'task/update-task',
    delete: 'task/delete-task',
    get: 'task/getTasksForTaskManager',
    getByAccount: 'task/get-all-account-tasks',
    getRecent: 'task/get-recent-tasks',
    getBySuccessItem: 'task/get-all-criteria-tasks',
  },
  users: {
    checkJiraIntegration: 'users/jira/check',
    zohoBugsIntegration: 'users/zohobugs/check',
    getAll: 'users/get-all-users',
    getDetails: 'users/details',
    getInactiveConnections: 'users/inactive-connections',
    updateProfileInformation: 'users/update-profile-information',
    getAllCompanyUsers: 'users/get-all-company-users',
    getAllUsersByRole: 'users/getUserByRole',
    activateUser: 'users/activate-user',
    deactivateUser: 'users/deactivate-user',
    checkExistingUser: 'users/user-exist',
    signupUser: 'users/signup-user',
    profileDetails: 'users/user-profile-details',
    getCompanyUsers: 'users/get-company-users',
  },
  userPreferences: {
    getUserPreferences: 'user-preference/get-user-preferences',
  },
  customSection: {
    gellAllSections: 'custom-section/get-all-sections',
    getCustomFieldValues: 'custom-field/get-field-values',
    isCustomSectionEnabled: 'custom-section/enabled',
    saveCustomFieldValues: 'custom-field/save-fields',
    getCustomFieldValuesBySection: 'custom-field/get-values-by-section',
    getCustomSectionById: 'custom-section/get-section-by-id',
    getSectionByType: 'custom-section/get-section-by-type',
    isRequestFormSubmitted: 'custom-section/is-request-form-submitted',
  },
  modulesFeatures: {
    getAllModulesFeatures: 'modules/get-modules-and-features',
    addModulesFeatures: 'modules/add-module',
    addFeature: 'modules/add-feature',
    updateModule: 'modules/update-module',
    updateFeature: 'modules/update-feature',
    enableDisableFeature: 'modules/enable-disable-feature',
    enableDisableModule: 'modules/enable-disable-module',
    getModuleByModuleId: 'modules/get-module-by-module-id',
    updateProductGapIntegration: 'modules/update-product-gap-integration',
    getProductGapByFeatureId: 'modules/get-product-gap-by-feature-id',
  },
  solution: {
    getSolution: 'solution/get-solution',
    completeSolution: 'solution/complete-solution',
    reopenSolution: 'solution/reopen-solution',
    getSolutionByModule: 'solution/get-solution-by-module',
    addSolution: 'solution/add-solution',
    getSimilarUsecases: 'solution/get-similar-usecases',
    getAnalyticsData: 'solution/get-analytics-data',
    updateSolution: 'solution/update-solution',
    timeSpentSolution: 'solution/add-time-spent-solution',
    deleteUsecase: 'solution/delete-usecase',
    useCases: 'solution/get-solution-usecases',
    getClosedAccount: 'solution/get-closed-account-solution',
  },
  ram: {
    rejectRequest: 'ram/reject-request',
    getAllRequests: 'ram/get-all-requests',
  },
  processing: {
    pipelineTableBatchProcess: 'batchprocess/check-batch-process',
  },
  pipeline: {
    getPipelines: 'pipeline/getAllPipelines',
    pipelineWithStages: 'pipeline/getAllPipelinesWithStages',
    enableDisablePipeline: 'pipeline/enable-disable-pipeline',
  },
  issues: {
    getAllIssues: 'issues/get-all-issues',
    bulkUpdateIssues: 'issues/bulk-update-issues',
    getFilterOptions: 'issues/get-filter-options',
    searchIssues: 'issues/search',
    getJiraConfig: 'issues/get-jira-config',
  },
};

export const URLS_USER_SERVICE = {
  v1: {
    createUsers: 'create-users',
    login: 'login',
    changePassword: 'changepassword',
    getUserDetails: 'details',
    activateUser: 'enable-user',
    deactivateUser: 'disable-user',
    changeRole: 'change-role',
    activateDeactivateCompany: 'activate-deactivate-company',
    addBulkUsers: 'add-bulk-users',
    updateUser: 'update-user',
    deactivateUserAndTransferData: 'transfer-user-data',
    deleteUser: 'delete-user',
    registerUser: 'get-auth-code',
  },
};

export const URLS_DE_SERVICE = {
  callback: {
    createJiraIssue: 'callback/jira/issue/create',
    refreshJira: 'callback/jira/refresh_meta_data',
    pullZohoBugs: 'callback/zohobugs/pull',
    createFreshsalesLead: 'callback/create-lead',
    getCrmMetadata: 'callback/get-crm-metadata',
    associatePresalesField: 'callback/associate-presales-field',
    saveJiraAuthentication: 'callback/save-jira-authentication',
    pullGoogleEvents: 'callback/google/pull',
    pullMicrosoftEvents: 'callback/microsoft/pull',
  },
};
