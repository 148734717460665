import {
  Checkbox,
  DatePicker,
  Form,
  Space,
  TimePicker,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import RichTextEditor from '../RichTextEditor';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { convertHTMLtoPlainText } from '../../Utils/common';

dayjs.extend(localeData);
const { Text } = Typography;
const dateFormat = 'YYYY-MM-DD';
const formatTime = 'HH:mm';

const AddTaskMainContent = ({
  initialValues,
  form,
  allAccounts,
  action,
  reopenTask,
}) => {
  useEffect(() => {
    if (initialValues && action === 'update') {
      const minutes = Number(initialValues?.timeSpent) % 60 ?? 0;
      const hours = Math.floor(Number(initialValues?.timeSpent / 60)) ?? 0;
      form.setFieldsValue({
        'task-description': initialValues?.description,
        'task-dueDate': dayjs(initialValues?.dueDate, dateFormat),
        // clear time spent, when a completed task is reopened.
        'task-timespent': reopenTask
          ? null
          : dayjs(`${hours}:${minutes}`, formatTime),
      });
    }
    if (action === 'add') {
      form.setFieldsValue({
        'task-dueDate': dayjs(new Date().toISOString(), dateFormat),
      });
    }
  }, [initialValues, form, reopenTask]);

  return (
    <>
      <Form.Item
        className="mt-30"
        label={<Text strong>Task description</Text>}
        name="task-description"
        rules={[
          {
            required: true,
            message: 'Description field is Required',
            validator: (_, value) => {
              if (!value) {
                return Promise.reject('Description field is Required');
              }
              if (value && !convertHTMLtoPlainText(value)) {
                return Promise.reject('Description field is Required');
              }
              if (value && convertHTMLtoPlainText(value)) {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <RichTextEditor className="mention-wrapper description" />
      </Form.Item>

      <Space>
        <Form.Item
          name="task-dueDate"
          label={<Text strong>Due Date</Text>}
          rules={[
            {
              required: true,
              message: 'Due date field is Required',
            },
          ]}
        >
          <DatePicker format={'YYYY-MM-DD'} />
        </Form.Item>

        <Form.Item name="task-timespent" label={<Text strong>Time Spent</Text>}>
          <TimePicker format={formatTime} />
        </Form.Item>
        {action === 'update' && !reopenTask && (
          <Form.Item name="task-completed" label=" " valuePropName="checked">
            <Checkbox>Mark as completed</Checkbox>
          </Form.Item>
        )}
      </Space>
    </>
  );
};

export default AddTaskMainContent;
