import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Drawer, Flex, Select, Space, Typography } from 'antd';
import {
  extendedTeamSlice,
  useManageTeamMembersMutation,
} from '../../Settings/Teams/teamSlice';
import { useLazyGetAllUsersByCompanyIdQuery } from '../../AdminOps/adminOpsSlice';

const { Text } = Typography;

const ManageTeamMembers = ({
  showModal,
  setShowAddTeamMembersDrawer,
  teamInfo,
}) => {
  const dispatch = useDispatch();
  const [userOptions, setUserOptions] = useState(null);
  const [userObj, setUserObj] = useState(null);
  const [manageTeamMembers] = useManageTeamMembersMutation();
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [getAllUsers] = useLazyGetAllUsersByCompanyIdQuery();

  useEffect(() => {
    const userObj = [];

    // Fetch both active & inactive users(roles => Admin, Manager, User).
    getAllUsers({ companyId: null })
      .unwrap()
      .then((data) => {
        const options = data?.ids?.map((user) => {
          userObj?.push(data?.entities[user]);
          return {
            label: data?.entities[user]?.name,
            value: data?.entities[user]?.name,
          };
        });
        setUserOptions(options);
      });
    setUserObj(userObj);

    if (teamInfo?.teamMembers?.length) {
      const members = teamInfo?.teamMembers?.map((data) => data?.name);
      setSelectedMembers(members);
    }
  }, [teamInfo]);

  const onSave = () => {
    // Construct user object of selected users for payload.
    const members = userObj?.filter((data) => {
      if (selectedMembers?.includes(data?.name)) {
        return data;
      }
    });

    if (!members.length) {
      toast.info('Atleast one user must be a part of the Team!');
      return;
    }

    const body = {
      id: teamInfo?.id,
      teamMembers: members,
    };
    manageTeamMembers(body)
      .unwrap()
      .then(() => {
        toast.success('Added Members Successfully!');
        dispatch(
          extendedTeamSlice.util.invalidateTags([
            { type: 'Team', id: 'fetchActiveInactiveTeams' },
            { type: 'Team', id: 'fetchAdminManagers' },
            { type: 'Team', id: 'fetchTeamById' },
          ])
        );
      })
      .catch((e) => {
        console.log(e);
        toast.error('Update Failed!');
      })
      .finally(() => onClose());
  };

  const onClose = () => {
    setShowAddTeamMembersDrawer(false);
    setSelectedMembers(null);
  };

  const onChange = (e) => {
    setSelectedMembers(e);
  };

  return (
    <Drawer
      title={
        <Flex align="center" className="text-primary">
          Manage Team Members
        </Flex>
      }
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave} type="primary">
            Save
          </Button>
        </Space>
      }
      width="40%"
      open={showModal}
      onClose={onClose}
    >
      <Text htmlFor="teamMembers" className="mb-10" strong>
        Team Members
      </Text>

      <Select
        mode="multiple"
        onChange={onChange}
        id="teamMembers"
        className="manage-team-select"
        options={userOptions}
        value={selectedMembers}
      />
    </Drawer>
  );
};

export default ManageTeamMembers;
