import React from 'react';
import ReactModal from 'react-modal';
import Avatar from '../Avatar';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';

const UserPanel = (props) => {
  return (
    <ReactModal
      isOpen={props.showAddUserPanel}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Add new member</h4>
        {/* <div className="team-create-user-heading">Create new user</div> */}
      </div>
      <form>
        <div className="form">
          <div className="search-container">
            <SearchSVG />{' '}
            <input
              placeholder="Search by email or name"
              onChange={(event) => props.handleAddUserSearch(event)}
            />
          </div>
          <div className="form-field add-user-modal mt-10">
            {Array.isArray(props.usersList) &&
              props.usersList.map((user) => (
                <div key={user?.value} className="fields">
                  <input
                    name="completed"
                    type="checkbox"
                    className="checkbox-position"
                    onClick={() => props.handleCheckBoxClick(user)}
                  />
                  <Avatar userName={user.name} />
                  <div className="add-user-list">
                    <span className="add-user-name-heading">{user.name}</span> (
                    {user.label})
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => props.handleCancel()}
          >
            {' '}
            Cancel{' '}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.addUser()}
          >
            {' '}
            Add{' '}
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default UserPanel;
