import React, { useState } from 'react';
import { Button, Drawer, Flex, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useSyncOpportunityMutation } from '../accountsSlice';
import DealComparisonView from './DealComparisonView.js';
import SyncDrawerDealList from './SyncDrawerDealList.js';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ReactComponent as BackSVG } from '../../Icons/ArrowLeft.svg';

const { Title } = Typography;

const SyncCRMDrawer = ({
  title,
  showDrawer,
  closeDrawer,
  existingDeal,
  handleSyncSuccess = () => {},
}) => {
  const [activeCard, setActiveCard] = useState({});
  const [showSearchableView, setShowSearchableView] = useState(true);

  const [syncOpportunity] = useSyncOpportunityMutation();

  const onCardClick = (deal) => {
    setShowSearchableView(!showSearchableView);
    setActiveCard(deal);
  };

  const toggleSearchableView = () => {
    setShowSearchableView(!showSearchableView);
  };

  const handleSyncCRM = (presalesOwner) => {
    const requestBody = {
      preskaleDealId: existingDeal.dealId,
      crmDealId: activeCard.dealId,
      presalesOwnerId: presalesOwner,
    };
    syncOpportunity(requestBody)
      .unwrap()
      .then(() => {
        handleSyncSuccess(activeCard);
        closeDrawer();
        toast.success('Sync initiated Successfully');
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('Something went wrong');
      });
  };

  const handleCloseDrawer = () => {
    setShowSearchableView(true);
    closeDrawer();
  };

  return (
    <Drawer
      open={showDrawer}
      onClose={closeDrawer}
      title={
        <Flex align="center" className="fill-colors-primary">
          {!showSearchableView && (
            <Button
              type="link"
              icon={<BackSVG title="" />}
              onClick={() => setShowSearchableView(true)}
            />
          )}
          <Title level={5} className="flex-align-center mb-0">
            {title}
          </Title>
        </Flex>
      }
      styles={{
        header: {
          paddingLeft: '20px',
        },
        wrapper: {
          width: '50%',
        },
        body: {
          padding: 0,
          overflow: 'hidden',
        },
      }}
      closable={false}
      extra={
        <Button icon={<CloseCircleOutlined />} onClick={handleCloseDrawer}>
          Close
        </Button>
      }
    >
      {showSearchableView ? (
        <SyncDrawerDealList onCardClick={onCardClick} />
      ) : (
        <DealComparisonView
          activeCard={activeCard}
          existingDeal={existingDeal}
          toggleSearchableView={toggleSearchableView}
          handleSyncCRM={handleSyncCRM}
        />
      )}
    </Drawer>
  );
};

export default SyncCRMDrawer;
