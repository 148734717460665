import { useEffect, useState, useRef } from 'react';

const useClickAwayListener = (
  ref,
  excludeClassName,
  excludeCallback = () => {},
  closeOnDelay = false,
  contentVisibility
) => {
  const [clickedAway, setClickedAway] = useState(false);
  let delayClose = useRef(null);
  const clearDelay = () => {
    if (typeof delayClose?.current === 'number') {
      clearTimeout(delayClose?.current);
    }
  };
  const isClearBtn = (event) => {
    if (
      event?.target?.className &&
      typeof event?.target?.className === 'string' &&
      event?.target?.className?.trim().includes('indicatorContainer')
    ) {
      return true;
    }
    if (
      excludeClassName &&
      event?.target?.className &&
      typeof event?.target?.className === 'string' &&
      event?.target?.className?.trim()?.includes(excludeClassName)
    ) {
      return true;
    }
    if (
      event?.target?.className &&
      typeof event?.target?.parentNode?.className === 'string' &&
      event?.target?.parentNode?.className
        ?.trim()
        .includes('indicatorContainer')
    ) {
      return true;
    }
    if (
      typeof event?.target?.parentNode?.className !== 'string' ||
      typeof event?.target?.className !== 'string'
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const handleClickAway = (event) => {
      const clearBtn = isClearBtn(event);
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !excludeCallback(event?.target) &&
        !clearBtn
      ) {
        setClickedAway(true);
      } else {
        if (closeOnDelay) {
          clearDelay();
          delayClose.current = setTimeout(() => {
            setClickedAway(true);
          }, 5000);
        }
        setClickedAway(false);
      }
    };

    const handleMouseOver = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        clearDelay();
      }
    };
    const handleMouseOut = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        if (closeOnDelay) {
          delayClose.current = setTimeout(() => {
            setClickedAway(true);
          }, 5000);
        }
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickAway);
    if (closeOnDelay) {
      document.addEventListener('mouseover', handleMouseOver);
      document.addEventListener('mouseout', handleMouseOut);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickAway);
      if (closeOnDelay) {
        document.removeEventListener('mouseover', handleMouseOver);
        document.removeEventListener('mouseout', handleMouseOut);
      }
      clearDelay();
    };
  }, [ref]);

  useEffect(() => {
    if (!contentVisibility) {
      clearDelay();
    }
  }, [contentVisibility]);

  return clickedAway;
};

export default useClickAwayListener;
