import React, { useRef, useEffect, useState, forwardRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import CalendarEvent from './CalenderEvent';
import { toISODate } from '../../Utils/DateUtils';
import {
  useLazyFetchCalendarForAccountQuery,
  useLazyFetchCalendarForDashboardQuery,
  useLazyFetchUserSpecificCalendarQuery,
} from './calendarSlice';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import format from 'date-fns/format';
import useDebounce from '../../hooks/useDebounce';

const Calendar = forwardRef(
  (
    {
      dashboard,
      accountId,
      dealId,
      showEventDetails,
      showUserCalendar = false,
      userId,
      height = 'auto',
      headerToolbar,
      updateCalendar,
      resetUpdateCalendar,
    },
    ref
  ) => {
    const calendarRef = ref ?? useRef();
    const [calendarChanged, setCalendarChanged] = useState();
    const debouncedCalendar = useDebounce(
      calendarChanged || updateCalendar,
      1000
    );

    useEffect(() => {
      if (!calendarChanged && !updateCalendar) {
        return;
      }
      getWeekEvents(calendarRef.current.getApi(), dealId);
      setCalendarChanged(null);
      if (resetUpdateCalendar) {
        resetUpdateCalendar(null);
      }
    }, [debouncedCalendar]);

    const [getCalendarDataForDashboard, calendarEvents] =
      useLazyFetchCalendarForDashboardQuery({
        selectFromResult: (result) => {
          return result?.data ? result?.data?.entities : {};
        },
      });
    const [getCalendarDataForAccount, calendarEventsForAccount] =
      useLazyFetchCalendarForAccountQuery({
        selectFromResult: (result) => {
          return result?.data ? result?.data?.entities : {};
        },
      });
    const [getUserSpecificCalendar, calendarEventsForUser] =
      useLazyFetchUserSpecificCalendarQuery({
        selectFromResult: (result) => {
          return result?.data ? result?.data?.entities : {};
        },
      });

    useEffect(() => {
      // * Persisting last viewed week information
      // * For data and for UI
      if (!showUserCalendar) {
        const currentWeekFromStorage = sessionStorage.getItem('selectedWeek');
        const currentWeekFromStorageParsed = JSON.parse(currentWeekFromStorage);
        if (currentWeekFromStorage) {
          calendarRef.current?.getApi().changeView(
            'timeGridWeek',
            toISODate(
              add(new Date(currentWeekFromStorageParsed.from), {
                days: 1,
              })
            )
          );
        }
      }
      getWeekEvents(calendarRef.current?.getApi(), dealId);
    }, [accountId, dealId, calendarRef.current, userId, showUserCalendar]);

    const saveSelectedWeektoSession = (currentWeek) => {
      sessionStorage.setItem('selectedWeek', JSON.stringify(currentWeek));
    };

    // Fetch all events for the week
    const getCalendar = (weekData, accountId, dealId) => {
      if (showUserCalendar) {
        getUserSpecificCalendar({ userId, ...weekData });
        return;
      }
      if (dashboard) {
        // To avoid displaying dashboard calendar events on mount in account view
        getCalendarDataForDashboard(weekData);
        return;
      }
      if (accountId && dealId) {
        getCalendarDataForAccount({ accountId, dealId, ...weekData });
      }
    };

    // Getting current week from the calendar API
    const getCurrentWeekFromApi = (start, end) => {
      const currentWeek = {
        from: format(new Date(start), 'yyyy-MM-dd'),
        to: format(new Date(end), 'yyyy-MM-dd'),
      };
      return currentWeek;
    };

    const getWeekEvents = (calendarApi, dealId) => {
      const from = calendarApi.view.activeStart;
      const to = calendarApi.view.activeEnd;
      const currentWeek = getCurrentWeekFromApi(from, to);
      !showUserCalendar &&
        saveSelectedWeektoSession(
          { from: currentWeek.from, to: currentWeek.to },
          dealId
        );
      getCalendar(
        {
          from: format(
            sub(new Date(currentWeek.from), {
              days: 1,
            }),
            'yyyy-MM-dd'
          ),
          to: currentWeek.to,
        },
        accountId,
        dealId
      );
    };

    // Render events on the calendar
    const eventContent = (eventInfo) => {
      let eventProps = Object.assign({}, eventInfo.event._def.extendedProps);
      eventProps.title = eventInfo.event._def.title;
      eventProps.timeText = eventInfo.timeText;
      eventProps.id = eventInfo.event._def.publicId;
      eventProps.backgroundColor = eventInfo.backgroundColor;
      eventProps.start = showUserCalendar
        ? calendarEventsForUser[eventProps.id].start
        : dashboard
        ? calendarEvents[eventProps.id].start
        : calendarEventsForAccount[eventProps.id].start;
      eventProps.end = showUserCalendar
        ? calendarEventsForUser[eventProps.id].end
        : dashboard
        ? calendarEvents[eventProps.id].end
        : calendarEventsForAccount[eventProps.id].end;

      return (
        <CalendarEvent
          eventInfo={eventProps}
          openCalendarInSidebar={showEventDetails}
        />
      );
    };

    return (
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin]}
        scrollTime={'08:00:00'}
        headerToolbar={headerToolbar} // Example:  { left: '', center: 'title', right: 'previousBtn,todayBtn,nextBtn',}
        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
        customButtons={{
          todayBtn: {
            text: 'Today',
            click: (e) => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.today();
              setCalendarChanged(e.target);
            },
          },
          previousBtn: {
            text: 'Previous',
            click: (e) => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.prev();
              setCalendarChanged(e.target);
            },
          },
          nextBtn: {
            text: 'Next',
            click: (e) => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.next();
              setCalendarChanged(e.target);
            },
          },
        }}
        buttonIcons={{
          previousBtn: 'left-single-arrow',
          nextBtn: 'right-single-arrow',
        }}
        initialView="timeGridWeek"
        nowIndicator={true}
        editable={false}
        selectable={false}
        selectMirror={false}
        dayMaxEvents={true}
        events={
          showUserCalendar
            ? Object.values(calendarEventsForUser)
            : dashboard
            ? Object.values(calendarEvents)
            : Object.values(calendarEventsForAccount)
        } // alternatively, use the `events` setting to fetch from a feed
        weekends={true}
        eventContent={eventContent} // custom render function
        views={{
          timeGridWeek: {
            dayHeaderFormat: {
              weekday: 'short',
              day: '2-digit',
              omitCommas: true,
            },
          },
        }}
        height={height}
      />
    );
  }
);

export default Calendar;
