import React, { useEffect, useState } from 'react';
import { ReactComponent as AddSVG } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as DeleteSVG } from '../../Icons/NewImage/delete.svg';
import mixpanel from 'mixpanel-browser';
import UserPanel from '../../common/Modals/UserPanel';
import DeletePopup from '../../common/Modals/DeletePopup';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  deleteCollaborator,
  getAllCollaborators,
} from '../../_services/common.service';
import { useAddCollaboratorMutation } from './collaboratorsSlice';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import { Button } from 'antd';

const InternalCollabrators = ({ readOnly = false, ...props }) => {
  const axios = useAxiosPrivate();
  const fetchAllCollaborators = useAxios(axios);
  const deleteContact = useAxios(axios);
  const [addCollaborator] = useAddCollaboratorMutation();

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });

  useEffect(() => {
    getAllCollaborators(
      fetchAllCollaborators,
      props.dealId,
      setCollaboratorList
    );
  }, [props.dealId]);

  const [collaboratorList, setCollaboratorList] = useState([]);
  const [showAddUserPanel, setShowAddUserPanel] = useState(false);
  const [userListForPane, setUserListForPane] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [addUserList, setAddUserList] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('user'));

  const getAllUsers = () => {
    const data = Object.values(userMap);
    let usersData = data.map((item) => {
      return {
        value: item?.id,
        label: item?.email,
        name: item?.name,
        roles: item?.roles,
      };
    });

    let loggedUserIndex = null;
    let currentFilterUser = 0;
    // Except for the deal owner and already present collabrator, all the other users can be added as collabrators
    const filterUsers = usersData?.filter((filteredUser) => {
      const filterUser =
        !props.presalesUser?.email.includes(filteredUser?.label) &&
        (collaboratorList?.length < 1
          ? true
          : !collaboratorList
              .map((collabrator) => collabrator?.email)
              .includes(filteredUser?.label));
      if (filterUser && filteredUser.label === loggedUser?.email) {
        loggedUserIndex = currentFilterUser;
      }
      if (filterUser) {
        currentFilterUser++; // * Track filteredUsers to get loggedUserIndex
      }
      return filterUser;
    });
    // * Swap places so that the logged users email shows up first in the list
    if (loggedUserIndex !== null) {
      const firstUser = filterUsers[0];
      filterUsers[0] = filterUsers[loggedUserIndex];
      filterUsers[loggedUserIndex] = firstUser;
    }
    setUserListForPane(filterUsers);
    setFilteredUsersList(filterUsers);
    setShowAddUserPanel(true);
  };

  const handleCancel = () => {
    setShowAddUserPanel(false);
    setAddUserList([]);
  };

  const handleCheckBoxClick = (userItem) => {
    const userInfo = addUserList;
    const index = addUserList.findIndex(
      (existingUser) => existingUser.value === userItem.value
    );
    if (index < 0) {
      userInfo.push(userItem);
    } else {
      userInfo.splice(index, 1);
    }
    setAddUserList(userInfo);
  };

  const handleAddUserSearch = async (event) => {
    if (!event.target.value) {
      setFilteredUsersList(userListForPane);
      return;
    }
    let text = event.target.value.trim().toLowerCase();
    let usersCopy = [...userListForPane];
    if (text.length > 0) {
      usersCopy = usersCopy.filter((userItem) => {
        return (
          userItem.name.toLowerCase().match(text) ||
          userItem.label.toLowerCase().match(text)
        );
      });
    }
    setFilteredUsersList(usersCopy);
  };

  const addCollaborators = async () => {
    const collabratorIdList = [];
    addUserList.map((userItem) => {
      collabratorIdList.push(userItem.value);
    });
    const collabratorPayLoad = {
      accountId: props.accountId,
      dealId: props.dealId,
      userId: props.user.userId,
      collabrators: collabratorIdList,
    };

    addCollaborator({ body: collabratorPayLoad })
      .unwrap()
      .then((data) => {
        if (data && data.length > 0) {
          let originalList = [...collaboratorList];
          originalList = originalList.concat(data);
          setCollaboratorList(originalList);
        }
        setShowAddUserPanel(false);
        setAddUserList([]);
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Collaborator not added!');
      });

    try {
      mixpanel.track('internalcollab_added', {
        event: 'Collaborator Internal',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  const handleCollabratorPaneOpen = async (event) => {
    if (event && typeof event.stopPropagation() === 'function')
      event.stopPropagation();
    getAllUsers();
  };

  const handleCollabratorDelete = (collaboratorId) => {
    const collaboratorCopy = [...collaboratorList];
    const filteredList = collaboratorCopy.filter(
      (collaborator) => collaborator.id !== collaboratorId
    );
    setCollaboratorList(filteredList);
    setShowDeleteModal(false);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="parent-container">
        <div className="heading-container">
          <div className="header">Internal - Preskale Users</div>
          {!readOnly && (
            <button
              onClick={handleCollabratorPaneOpen}
              className="btn btn-icon btn-transparent"
            >
              <span className="add-icon">
                <AddSVG />
              </span>
              <span className="btn-txt"> Add new </span>
            </button>
          )}
        </div>
        <div className="content-container">
          <table>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Team</th>
              <th></th>
            </tr>
            {collaboratorList.length > 0 &&
              collaboratorList.map((collabrator) => (
                <tr key={collabrator?.id} className="content-row">
                  <td>{collabrator.name}</td>
                  <td>
                    {collabrator.roles && collabrator.roles.length > 0
                      ? collabrator.roles[1] ?? collabrator.roles[0]
                      : ''}
                  </td>
                  <td>--</td>
                  {!readOnly && (
                    <td className="content-actions">
                      <Button
                        onClick={() => handleDelete(collabrator.id)}
                        icon={<DeleteSVG />}
                      />
                    </td>
                  )}
                </tr>
              ))}
            {collaboratorList.length == 0 && (
              <div className="no-content-message">
                {' '}
                No Internal Preskale Users
              </div>
            )}
          </table>
        </div>
      </div>
      <UserPanel
        showAddUserPanel={showAddUserPanel}
        handleAddUserSearch={handleAddUserSearch}
        handleCheckBoxClick={handleCheckBoxClick}
        handleCancel={handleCancel}
        usersList={filteredUsersList}
        addUser={addCollaborators}
      />
      {showDeleteModal && (
        <DeletePopup
          showModal={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          actionComponents={[
            <Button key="back" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                deleteCollaborator(
                  deleteContact,
                  props?.dealId,
                  selectedItem,
                  () => handleCollabratorDelete(selectedItem)
                )
              }
              danger
            >
              Delete
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default InternalCollabrators;
