import React, { forwardRef } from 'react';
import emptyHyphen from '../_helpers/empty-hyphen';

const ProfileCard = forwardRef(({ cardContent, children }, ref) => {
  return (
    <div className="main-content" ref={ref} id={cardContent?.id}>
      {cardContent?.icon && cardContent?.title ? (
        <div className="content-title">
          {cardContent?.icon}
          <span className="content-label">
            {emptyHyphen(cardContent?.title)}
          </span>
        </div>
      ) : null}
      <div className={`card-content ${cardContent?.className}`}>
        <div className={`${cardContent?.labelClassName}`}>
          <h2 className={cardContent?.subText ? 'mb-10' : ''}>
            {emptyHyphen(cardContent?.label)}
          </h2>
          {cardContent?.labelIcon}
          {cardContent?.subText && (
            <div className="text-md-13 text-weight-regular">
              {cardContent?.subText}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
});

export default ProfileCard;
