import React from 'react';
import emptyHyphen from '../../_helpers/empty-hyphen';
import ToolTip from '../../common/ToolTip';

const DealSummaryCard = ({ dealData, activeCard, onCardClick }) => {
  return (
    <div
      className={`content ${
        activeCard.dealId === dealData.dealId ? 'active' : ''
      }`}
      onClick={() => onCardClick(dealData)}
    >
      <div className="content-top flex">
        <ToolTip
          placement="top"
          text={dealData.dealName}
          contentClassname="tooltip_content_container"
        >
          <div className="content-highlight">{dealData.dealName}</div>
        </ToolTip>
        <div className="flex-grow"></div>
        <div className="font-bold">{dealData.dealValue}</div>
      </div>
      <div className="content-bottom flex">
        <div
          className={
            'stage-content ' +
            (activeCard.dealId === dealData.dealId ? 'active' : '')
          }
        >
          {dealData.status}
        </div>
        <div className="flex-grow"></div>
        <div>{emptyHyphen(dealData.owner?.displayName)}</div>
      </div>
    </div>
  );
};
export default DealSummaryCard;
