export const solutionErrors = {
  selectedModule: 'Select Module',
  associatedFeatures: 'Select relevant features',
  rating: 'Select pulse score',
  usecaseComments: 'Enter use-case',
};

export const productGapErrors = {
  selectedModule: 'Select Module',
  selectedFeature: 'Select Feature',
  selectedProductGap: 'Select Product Gap',
  selectedProductGapType: 'Select Product Gap Type',
  title: 'Select Product Gap Item',
  comments: 'Enter Use-case',
  rating: 'Select Gap Pulse',
};
