import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

export const fetchActiveIntegrations = (service, handleSuccess, handleError) =>
  service.makeAxiosCall({
    method: 'get',
    url: URLS_BUSINESS_SERVICE.dashboard.activeSyncIntegrations,
    onSuccess: handleSuccess,
    onError: handleError,
  });

export const getAverageTimeSpent = (service) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.dashboard.avgTimeSpent}?from_date=2019-01-01&to_date=2022-12-01`,
  });

export const getRevenue = (service, handleSuccess) =>
  service.makeAxiosCall({
    method: 'get',
    url: `${URLS_BUSINESS_SERVICE.dashboard.revenue}?from_date=2016-02-01&to_date=2021-12-01`,
    onSuccess: handleSuccess,
  });
