import { TreeSelect } from 'antd';
import React from 'react';
const PreskaleSimpleMultiSelect = ({
  data,
  value = ['All'],
  onChange,
  className,
  maxTagCount,
  style = {},
}) => {
  return (
    <TreeSelect
      className={`preskale-multiselect-simple${
        className ? ` ${className}` : ''
      }`}
      popupClassName="preskale-multiselect-simple--dropdown"
      treeData={[
        {
          title: 'All',
          value: 'All',
          key: 'All',
          children: data,
        },
      ]}
      value={value}
      onChange={onChange}
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      placeholder="Please select"
      switcherIcon={null}
      treeDefaultExpandAll={true}
      maxTagCount={maxTagCount || 2}
      maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length}`}
      style={style}
      showSearch={false}
    />
  );
};
export default PreskaleSimpleMultiSelect;
