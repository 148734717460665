import React from 'react';

const PreskaleCard = ({
  header,
  headerClassName = '',
  children,
  contentClassName = '',
  footer,
  footerClassName = '',
  className = '',
}) => {
  return (
    <div className={`preskale-card--container ${className}`}>
      {header && (
        <div className={`preskale-card--header ${headerClassName}`}>
          {header}
        </div>
      )}
      <div className={`preskale-card--body ${contentClassName}`}>
        {children}
      </div>
      {footer && (
        <div className={`preskale-card--footer  ${footerClassName}`}>
          {footer}
        </div>
      )}
    </div>
  );
};

export default PreskaleCard;
