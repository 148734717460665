import React, { useState, useEffect } from 'react';
import {
  selectUserMap,
  useGetAllCompanyUsersQuery,
} from '../../app/api/usersSlice';
import DualRingLoader from '../../common/DualRingLoader';
import Loader from '../../common/Loader';
import PageLayout from '../../Layout/PageLayout';
import DealInformationContainer from '../../LightningDashboard/RequestAssistanceManager/DealInformationContainer';
import {
  useFetchDealsBySalesPersonQuery,
  useFetchCustomSectionByTypeQuery,
} from '../../LightningDashboard/RequestAssistanceManager/RAMSlice';
import CustomForm from '../CustomSections/CustomForm';
import mixpanel from 'mixpanel-browser';
import { SearchableSidebar } from '../../Accounts/commons/SearchableSidebar';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';

const UnassignedOpportunities = () => {
  const [activeCard, setActiveCard] = useState({});
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const { data: userMap } = useGetAllCompanyUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  const ownerId = userMap[userDetails?.userId]?.userName;
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const [crmType, setCrmType] = useState('');
  useEffect(() => {
    if (configFeatures) {
      setCrmType(configFeatures.data?.crm?.toUpperCase());
    }
  }, [configFeatures]);

  const {
    data: unAssignedDeals,
    refetch: refetchUnassignedDeals,
    isLoading: requestLoading,
  } = useFetchDealsBySalesPersonQuery(
    { ownerId },
    {
      skip: !ownerId,
      selectFromResult: ({ data, isLoading }) => ({
        data: data?.data && Array.isArray(data.data) ? data.data : [],
        isLoading,
      }),
    }
  );

  const { data: customSection } = useFetchCustomSectionByTypeQuery(
    { crmType },
    {
      skip: !crmType,
      selectFromResult: ({ data }) => ({
        data: data && data.data ? data.data : [],
      }),
    }
  );

  const onDealSearch = () => {
    try {
      mixpanel.track('rrf_search', {
        event: 'RRF Deals Searched',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  const onCardClick = (deal) => {
    setActiveCard(deal);
    try {
      mixpanel.track('rrf_deal_card_click', {
        event: 'RRF Deal Clicked',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  const handleSaveCustomFieldSuccess = (dealId) => {
    refetchUnassignedDeals();
    const deals = unAssignedDeals.filter((deal) => deal.dealId !== dealId);
    setActiveCard(deals && deals.length ? deals[0] : {});
  };

  useEffect(() => {
    if (unAssignedDeals && unAssignedDeals.length) {
      let deal = { ...unAssignedDeals[0] };
      setActiveCard(deal);
    }
  }, [unAssignedDeals]);

  useEffect(() => {
    if (Object.keys(activeCard).length == 0) {
      return;
    }
    const activeDeal = { ...activeCard };
    if (!activeDeal.accountId) {
      activeDeal.accountId = activeDeal.salesAccountId;
      setActiveCard(activeDeal);
    }
  }, [activeCard]);

  return (
    <PageLayout
      contentClassname="request-form-container"
      sidebarSize="M"
      sidebar={
        <div className="left-pane">
          <div className="heading-container">
            <h2 className="heading_level4 text-dark header">
              Un-assigned Opportunities
            </h2>
          </div>
          {requestLoading ? (
            <div className="loader-container">
              <DualRingLoader dark />
            </div>
          ) : (
            <SearchableSidebar
              deals={unAssignedDeals}
              activeCard={activeCard}
              onCardClick={onCardClick}
              handleSearch={onDealSearch}
            />
          )}
        </div>
      }
    >
      <div className="assistance-manager">
        {requestLoading ? (
          <Loader />
        ) : (
          <div className="main-container">
            <div className="request-form">
              <DealInformationContainer activeCard={activeCard} />
            </div>
            {activeCard &&
            Object.keys(activeCard).length > 0 &&
            customSection &&
            Object.keys(customSection).length ? (
              <CustomForm
                section={customSection}
                deal={activeCard}
                sort={false}
                crmType={crmType}
                handleSaveCustomFieldSuccess={handleSaveCustomFieldSuccess}
              />
            ) : null}
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default UnassignedOpportunities;
