import React, { useState } from 'react';
import { useFetchEmbedUrlQuery } from './reportsSlice';
import Loader from '../common/Loader';

const QuickSightDashboard = ({ dashboardType }) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyId = userDetails?.companyId;
  const [loading, setLoading] = useState(true);
  const hideLoader = () => setLoading(false);
  const { data: embedUrl } = useFetchEmbedUrlQuery(dashboardType, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {loading && <Loader />}
      {embedUrl?.data && (
        <div className="width-full height-full no-scroll">
          <iframe
            width="100%"
            height="100%"
            src={`${embedUrl?.data}#p.CompanyID=${companyId}`}
            onLoad={hideLoader}
          />
        </div>
      )}
    </>
  );
};

export default QuickSightDashboard;
