import React, { useState } from 'react';
import Button from '../../../common/Button';
import { useFetchNotesByAccountQuery } from '../../../common/notes/notesSlice';
import NoteList from './NoteList';
import { ReactComponent as AddSVG } from '../../../Icons/NewImage/add-green.svg';
import styles from '../../../styles/features/presentationListContainer.module.scss';
import AddTaskNoteDescriptionDrawer from '../../../common/AddDrawerContent/AddTaskNoteDescriptionDrawer';

const DealNotes = ({ dealId, accountId, readOnly, dealDetails }) => {
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const { data: notes, isLoading } = useFetchNotesByAccountQuery(
    {
      dealId,
    },
    {
      skip: !dealId,
      selectFromResult: ({ data, isLoading }) => ({
        data: data ? Object.values(data?.entities) : [],
        isLoading,
      }),
    }
  );
  const handleAddNoteOpen = () => setShowAddNoteModal(true);
  const handleAddNoteCancel = () => setShowAddNoteModal(false);

  return (
    <section className={styles.presentation_list__container}>
      <section className={styles.notes_header}>
        <h3 className={styles.notes_title}>Notes</h3>
        {!readOnly && (
          <Button icon onClick={handleAddNoteOpen} type="text">
            <AddSVG />
            Add Note
          </Button>
        )}
      </section>
      <NoteList
        data={notes}
        isReadOnly={readOnly}
        isLoading={isLoading}
        dealDetails={dealDetails}
      />
      {showAddNoteModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showAddNoteModal}
          closeDrawer={handleAddNoteCancel}
          dealDetails={dealDetails}
          tab="Notes"
          action="add"
          key="presentation-add-notes"
        />
      )}
    </section>
  );
};

export default DealNotes;
