import { businessService } from './businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedStageSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccountsByStage: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.stage.accounts,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Deals'],
    }),
  }),
});

export const { useFetchAccountsByStageMutation } = extendedStageSlice;
