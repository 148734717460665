export const EVENT_TYPE = [
  {
    value: 'EXTERNAL',
    label: 'External',
  },
  {
    value: 'INTERNAL',
    label: 'Internal',
  },
  {
    value: 'PERSONAL',
    label: 'Personal',
  },
];
