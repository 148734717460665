import React, { useState } from 'react';
import { DateFormat } from '../../Utils/DayJs';
import Avatar from '../../common/Avatar';
import { ReactComponent as LeftArrowSVG } from '../../Icons/ArrowLeft.svg';
import {
  useFetchJiraIssueQuery,
  useFetchJiraIssueCommentsQuery,
  saveRetainValueBoolean,
  useUpdateProductGapItemJiraMutation,
  productGapState,
} from '../../features/ProductGap/productGapSlice';
import { useDispatch, useSelector } from 'react-redux';
import DualRingLoader from '../../common/DualRingLoader';
import { toast } from 'react-toastify';

const JiraShow = ({
  jiraIssueId,
  handleBacktoJiralist,
  onSuccess,
  onSelect,
  isCreateForm,
}) => {
  const dispatch = useDispatch();
  const { data: selectedJiraItem } = useFetchJiraIssueQuery(
    { issueId: jiraIssueId },
    {
      skip: !jiraIssueId,
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: comments } = useFetchJiraIssueCommentsQuery(
    { issueId: jiraIssueId },
    { skip: !jiraIssueId, refetchOnMountOrArgChange: true }
  );
  const [isSelected, setIsSelected] = useState(false);

  const { selectedProductGap } = useSelector(productGapState);
  const [updatePgItemJira, { isLoading: submittingJiraSelection }] =
    useUpdateProductGapItemJiraMutation();

  const selectJira = (selectedJiraIssue) => {
    if (onSelect) {
      onSelect({
        jiraTitle: selectedJiraIssue?.summary,
        jiraType: selectedJiraIssue?.issuetype?.name,
        jiraIssueId: jiraIssueId,
        jiraIssueKey: selectedJiraIssue?.key,
      });
      onSuccess();
      return;
    }
    if (!selectedProductGap?.id) {
      toast.error('Error: Product gap item not selected!');
      return;
    }
    updatePgItemJira({
      id: selectedProductGap?.id,
      jiraTitle: selectedJiraIssue?.summary,
      jiraType: selectedJiraIssue?.issuetype?.name,
      jiraIssueId: selectedJiraIssue?.issue_id,
      jiraIssueKey: selectedJiraIssue?.key,
    })
      .unwrap()
      .then(() => {
        toast.success('Jira associated successfully!');
        setIsSelected(true);
        onSuccess();
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Jira not associated!');
      });
  };

  const handleBack = () => {
    // setSelectedJiraItem(null);
    handleBacktoJiralist();
  };

  return (
    <>
      {selectedJiraItem && selectedJiraItem.data && (
        <>
          <div className="modal-header">
            {isCreateForm && (
              <button
                className="btn btn-secondary pg-jira-back"
                onClick={handleBack}
              >
                <LeftArrowSVG />
              </button>
            )}
            <h4>
              {' '}
              {selectedJiraItem.data.project.name} / {selectedJiraItem.data.key}{' '}
            </h4>
          </div>

          <div className="modal-body jira-issues-modal-body jira-show-height">
            <div className="pg-jira-issue-details jira-modal-height">
              <div className="pg-jira-field">
                <span className="jira-issue-type">
                  {' '}
                  {selectedJiraItem.data.issuetype.name}
                </span>
                <span className="float-right">
                  <span className="jira-field-label"> Status : </span>
                  <span> {selectedJiraItem.data.status.name} </span>
                </span>
              </div>
              <h5 className="pg-jira-field text-lg">
                {' '}
                {selectedJiraItem.data.summary}{' '}
              </h5>

              <div className="pg-jira-field">
                <div className="jira-field-label jira-description-label">
                  {' '}
                  Description{' '}
                </div>
                <p> {selectedJiraItem.data.description} </p>
              </div>

              {comments?.length > 0 && (
                <div className="pg-jira-field">
                  <div className="jira-field-label jira-description-label">
                    {' '}
                    Comments{' '}
                  </div>
                  {comments.map((comment, index) => (
                    <div
                      key={comment?.id || index}
                      className="jira-comments-wrapper"
                    >
                      <div className="jira-comment-author flex-align-center">
                        <Avatar
                          size="small"
                          userName={comment.author.displayName}
                        />
                        <span className="comment-author">
                          {comment.author.displayName}
                        </span>
                        <span className="comment-created">
                          {DateFormat(comment.created, 'DD MMM, YYYY, hh:mm a')}
                        </span>
                      </div>
                      <p className="commet-body">{comment.body}</p>
                    </div>
                  ))}
                </div>
              )}

              <div className="pg-jira-field">
                <span className="jira-field-label"> Assignee : </span>
                <span> {selectedJiraItem.data.creator.displayName} </span>
              </div>

              <div className="pg-jira-field">
                <span className="jira-field-label"> Reporter : </span>
                <span> {selectedJiraItem.data.reporter.displayName} </span>
              </div>

              <div className="pg-jira-field">
                <span className="jira-field-label"> Priority : </span>
                <span> {selectedJiraItem.data.priority.name} </span>
              </div>
            </div>

            {isCreateForm && (
              <div className="pg-jira-btn-wrapper pt-10">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    dispatch(saveRetainValueBoolean(true));
                    selectJira(selectedJiraItem.data);
                  }}
                  disabled={submittingJiraSelection}
                >
                  {submittingJiraSelection ? (
                    <DualRingLoader />
                  ) : isSelected ? (
                    'Associated'
                  ) : (
                    'Select'
                  )}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default JiraShow;
