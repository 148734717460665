import React from 'react';
import { ReactComponent as CloudSVG } from '../../Icons/NewImage/cloud.svg';

export const CustomHeaderComponent = ({
  mandatoryField = null,
  writeBackField = null,
  masterCheckboxValue = null,
  handleMasterCheckbox = null,
  ...props
}) => {
  return (
    <>
      {writeBackField && (
        <div>
          <CloudSVG className="flex-align-center" />
        </div>
      )}
      {mandatoryField && <div style={{ color: '#de350b' }}>*</div>}
      {handleMasterCheckbox && (
        <div
          className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper${
            masterCheckboxValue ? ' ag-checked' : ''
          }`}
          role="presentation"
        >
          <input
            className="ag-input-field-input ag-checkbox-input"
            checked={masterCheckboxValue}
            type="checkbox"
            onChange={handleMasterCheckbox}
          />
        </div>
      )}
      <div title={props.displayName} className="agGridCustomHeader">
        {props.displayName}
      </div>
    </>
  );
};
