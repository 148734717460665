import React, { useState, useEffect } from 'react';
import styles from '../styles/components/ButtonGroup.module.scss';

const ButtonGroup = ({
  options = [],
  defaultValue = null,
  containerClassname = '',
  showActive = true,
}) => {
  const [checked, setChecked] = useState(defaultValue);
  const handleChange = (e) => {
    if (e.target.checked) {
      setChecked(e.target.value);
    }
  };

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  return (
    <div className={`${styles.button_group} ${containerClassname}`}>
      {options.map((option) => (
        <label
          key={option.value}
          className={`${styles.radio_button_wrapper}${
            checked === option.value && showActive
              ? ` ${styles.radio_button_wrapper_checked}`
              : ''
          }`}
        >
          <span
            className={`${styles.radio_button}${
              checked === option.value && showActive
                ? ` ${styles.radio_button_checked}`
                : ''
            }`}
          >
            <input
              className={styles.radio_button_input}
              type="radio"
              value={option.value}
              checked={checked === option.value}
              onChange={handleChange}
              onClick={option.onClick}
            />
            <span className={styles.radio_button_inner}></span>
          </span>

          <span className={styles.label_container}>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default ButtonGroup;
