import { businessService } from '../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../Constant/urls';

export const extendedCompanySlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanyConfigData: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.company.features;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Company', id: 'Config' },
        { type: 'Company', id: 'PgIntegration' },
      ],
      transformResponse: (res) => {
        const response = res;
        const productGapIntegration = {};
        const integrations = res?.data?.productGapIntegration;
        Array.isArray(integrations) &&
          integrations.forEach(
            (integration) =>
              (productGapIntegration[integration.type] = integration.is_enabled)
          );
        if (response?.data) {
          response.data.pgIntegrations = productGapIntegration;
        }
        return response;
      },
    }),
    getAllCompanies: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.company.getAll;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Company', id: 'allCompanies' },
      ],
    }),
    updateCompanyConfigData: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.company.updateFeatures,
        method: 'POST',
        body,
      }),
    }),
    fetchCRMDetails: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.company.crmDetails;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Company', id: 'crmDetails' },
      ],
    }),
    fetchCompanyDetails: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.company.getDetails;
      },
      providesTags: () => [{ type: 'Company', id: 'companyDetails' }],
    }),
    configureDefaultPipeline: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.company.configureDefaultPipeline,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchCompanyConfigDataQuery,
  useFetchCRMDetailsQuery,
  useLazyFetchCRMDetailsQuery,
  useUpdateCompanyConfigDataMutation,
  useGetAllCompaniesQuery,
  useFetchCompanyDetailsQuery,
  useLazyFetchCompanyDetailsQuery,
  useConfigureDefaultPipelineMutation,
} = extendedCompanySlice;
