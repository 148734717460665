import React from "react";
import { ReactComponent as LoaderSVG } from "../Icons/Loader.svg";

const Loader = ({ customClass }) => {
  return (
    <div className={customClass ? customClass : "loader-icon"}>
      <LoaderSVG />
    </div>
  );
};

export default Loader;
