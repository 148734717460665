import React, { useMemo, useState } from 'react';
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Form,
  Segmented,
  Typography,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import {
  useFetchAllAccountsQuery,
  useFetchAllDealsQuery,
} from '../../features/workbook/workbookSlice';
import { selectUserIdMap, useGetUsersQuery } from '../../app/api/usersSlice';
import { useGetParentContributionQuery } from '../../app/api/contributionSlice';
import {
  fetchActivityTableApi,
  useUpdateActivityMutation,
} from '../../features/DealPresentationView/Activity/activitySlice';
import {
  convertHTMLtoPlainText,
  customizeRequiredMark,
} from '../../Utils/common';
import {
  extendedTaskSliceUtil,
  useAddTaskMutation,
  useUpdateTaskMutation,
} from '../tasks/tasksSlice';
import { useAddNoteMutation, useUpdateNoteMutation } from '../notes/notesSlice';
import { fetchTableApi } from '../../Pipeline/pipelineSlice';
import { useAddActivityMutation } from '../../features/DealPresentationView/Activity/activitySlice';
import { businessService } from '../../app/api/businessService';
import AddNotesMainContent from './AddNotesMainContent';
import AddTaskMainContent from './AddTaskMainContent';
import AddActivityMainContent from './AddActivityMainContent';
import AddTaskSideContent from './AddTaskSideContent';
import AddActivitySideContent from './AddActivitySideContent';
import AddNotesSidecontent from './AddNotesSidecontent';
dayjs.extend(localeData);

const dateFormat = 'YYYY-MM-DD';

const addDrawerHeader = {
  Task: 'Create Tasks',
  Notes: 'Create Notes',
  Activity: 'Create Activity',
};

const updateDrawerHeader = {
  Task: 'Update Tasks',
  Notes: 'Update Notes',
  Activity: 'Update Activity',
};

const { Text } = Typography;

const AddTaskNoteDescriptionDrawer = ({
  showDrawer,
  closeDrawer,
  initialValues = null,
  tab = 'Task',
  action = '',
  dealDetails = null,
  reopenTask,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const taskAccount = Form.useWatch('task-account', form);
  const activityAccount = Form.useWatch('activity-account', form);
  const notesAccount = Form.useWatch('notes-account', form);
  const [selectedTab, setSelectedTab] = useState(tab);

  const [addTask, { isLoading: addingTask }] = useAddTaskMutation();
  const [updateTask, { isLoading: updatingTask }] = useUpdateTaskMutation();
  const [addNote, { isLoading: addingNote }] = useAddNoteMutation();
  const [updateNote, { isLoading: updatingNote }] = useUpdateNoteMutation();
  const [addActivity, { isLoading: addingActivity }] = useAddActivityMutation();
  const [updateActivity, { isLoading: updatingActivity }] =
    useUpdateActivityMutation();

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserIdMap,
  });

  const { data: outcomes } = useGetParentContributionQuery(undefined, {
    selectFromResult: ({ data }) => {
      const options = useMemo(() => {
        let optionValues = [];
        optionValues =
          data?.entities &&
          Object.values(data?.entities).map((outcome) => ({
            label: outcome?.title,
            value: outcome?.id,
          }));
        return optionValues;
      }, [data?.entities]);
      return {
        data: options ?? [],
      };
    },
  });

  const {
    data: { accounts = [], accountSummary = [] } = {
      activeAccounts: [],
      accounts: [],
      accountSummary: [],
    },
  } = useFetchAllDealsQuery();

  const {
    data: { allAccounts = [] } = {
      allAccounts: null,
      accountLabelMap: null,
    },
  } = useFetchAllAccountsQuery();

  const onFinishFailed = (e) => {
    console.error(e);
  };

  const saveTask = (fieldsValue) => {
    try {
      let values = {
        ...fieldsValue,
        'task-dueDate': fieldsValue['task-dueDate'].format(dateFormat),
      };
      if (fieldsValue['task-timespent']) {
        const hours = fieldsValue['task-timespent'].$d.getHours();
        const minutes = fieldsValue['task-timespent'].$d.getMinutes();
        const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
        values = {
          ...values,
          'task-timespent': totalMinutes,
        };
      }
      const sanitizedValue = DOMPurify.sanitize(values['task-description']);

      const data = {
        dealId: dealDetails ? dealDetails?.dealId : values['task-opportunity'],
        accountId: dealDetails
          ? dealDetails?.accountId
          : values['task-account'],
        userId: values['task-owner'],
        dueDate: values['task-dueDate'],
        dealName: dealDetails
          ? dealDetails?.name
          : accountSummary.filter(
              (data) => data.value === values['task-opportunity']
            )?.[0]?.label,
        description: sanitizedValue,
        plainText: convertHTMLtoPlainText(sanitizedValue),
        timeSpent: values['task-timespent'],
        module: 'ACCOUNT',
      };

      if (action === 'add') {
        addTask({
          body: { ...data, status: 'OPEN' },
        })
          .unwrap()
          .then(() => {
            toast.success('Task added successfully!');
          })
          .catch((error) => {
            console.warn('error', error);
            toast.error('Error: Task not added!');
          })
          .finally(() => {
            closeDrawer();
          });
      }

      if (action === 'update') {
        const body = {
          ...data,
          id: initialValues?.id,
          userId: initialValues?.userId,
        };
        if (fieldsValue['task-completed']) {
          body.status = 'COMPLETED';
        } else if (reopenTask && !fieldsValue['task-completed']) {
          body.status = 'OPEN';
        } else {
          body.status = initialValues.status;
        }

        updateTask({
          body: body,
        })
          .unwrap()
          .then(() => {
            if (dealDetails) {
              dispatch(
                extendedTaskSliceUtil.invalidateTags([
                  { type: 'Tasks', id: 'Tasks_Account' },
                ])
              );
            }
            toast.success('Task updated successfully!');
          })
          .catch((error) => {
            console.warn('Error:', error);
            toast.error('Error: Task not updated!');
          })
          .finally(() => {
            closeDrawer();
          });
      }
    } catch (e) {
      console.error('error while saving data!', e);
    }
  };

  const saveActivity = (fieldsValue) => {
    let values = {
      ...fieldsValue,
      'activity-date': fieldsValue['activity-date'].format(dateFormat),
    };
    if (fieldsValue['activity-timespent']) {
      const hours = fieldsValue['activity-timespent'].$d.getHours();
      const minutes = fieldsValue['activity-timespent'].$d.getMinutes();
      const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
      values = {
        ...values,
        'activity-timespent': totalMinutes,
      };
    }

    const body = {
      userId: values['activity-owner'],
      createdBy: loggedInUser?.userId,
      accountId: dealDetails
        ? dealDetails?.accountId
        : values['activity-account'],
      accountName: dealDetails
        ? dealDetails?.accountName
        : allAccounts?.find((data) => data.value === values['activity-account'])
            ?.label,
      dealId: dealDetails
        ? dealDetails?.dealId
        : values['activity-opportunity'],
      dealName: dealDetails
        ? dealDetails?.name
        : accountSummary?.filter(
            (data) => data.value === fieldsValue['activity-opportunity']
          )?.[0]?.label,
      outcomeId: values['activity-outcome'],
      outcomeName: outcomes?.find(
        (data) => data?.value === values['activity-outcome']
      )?.label,
      description: values['activity-description'],
      plainText: convertHTMLtoPlainText(values['activity-description']),
      timeSpent: parseInt(values['activity-timespent']) ?? 0,
      activityDate: values['activity-date'],
    };
    if (action === 'add') {
      addActivity(body)
        .unwrap()
        .then(() => {
          dispatch(fetchActivityTableApi(true));
        })
        .catch((e) => console.error(e))
        .finally(() => closeDrawer());
    }
    if (action === 'update') {
      const data = { ...body, id: initialValues?.id };
      updateActivity(data)
        .unwrap()
        .then((data) => {
          dispatch(fetchActivityTableApi(true));
        })
        .catch((e) => console.error(e))
        .finally(() => closeDrawer());
    }
  };

  const saveNotes = (fieldsValue) => {
    const sanitizedValue = DOMPurify.sanitize(fieldsValue['notes-description']);
    const data = {
      userId: loggedInUser.userId,
      accountId: dealDetails
        ? dealDetails?.accountId
        : fieldsValue['notes-account'],
      dealName: dealDetails
        ? dealDetails?.name
        : accountSummary?.filter(
            (data) => data.value === fieldsValue['notes-opportunity']
          )?.[0]?.label,
      dealId: dealDetails
        ? dealDetails?.dealId
        : fieldsValue['notes-opportunity'],
      description: sanitizedValue,
      plainText: convertHTMLtoPlainText(fieldsValue['notes-description']),
    };
    if (action === 'add') {
      addNote({
        body: data,
      })
        .unwrap()
        .then((res) => {
          if (!res?.data) {
            toast.error(res?.message);
          } else {
            dispatch(fetchTableApi(true));
            dispatch(
              businessService.util.invalidateTags([
                { type: 'Notes', id: 'Notes_Account' },
              ])
            );
            toast.success('Note added successfully!');
          }
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Note not added!');
        })
        .finally(() => closeDrawer());
    }
    if (action === 'update' && initialValues) {
      const body = { ...data, id: initialValues?.id };
      updateNote({
        body: body,
      })
        .unwrap()
        .then(() => {
          dispatch(fetchTableApi(true));
          if (dealDetails) {
            dispatch(
              businessService.util.invalidateTags([
                { type: 'Notes', id: 'Notes_Account' },
              ])
            );
          }
          toast.success('Note updated successfully!');
        })
        .catch((error) => {
          console.warn('Error:', error);
          toast.error('Error: Note not updated!');
        })
        .finally(() => closeDrawer());
    }
  };

  const onFinish = (fieldsValue) => {
    if (selectedTab === 'Task') {
      saveTask(fieldsValue);
    }
    if (selectedTab === 'Activity') {
      saveActivity(fieldsValue);
    }
    if (selectedTab === 'Notes') {
      saveNotes(fieldsValue);
    }
  };

  const renderMainContent = (selectedTab) => {
    switch (selectedTab) {
      case 'Task':
        return (
          <AddTaskMainContent
            initialValues={initialValues}
            form={form}
            action={action}
            reopenTask={reopenTask}
          />
        );
      case 'Activity':
        return (
          <AddActivityMainContent
            outcomes={outcomes}
            action={action}
            initialValues={initialValues}
            form={form}
          />
        );
      case 'Notes':
        return (
          <AddNotesMainContent
            initialValues={initialValues}
            form={form}
            action={action}
          />
        );
      default:
        return null;
    }
  };

  const renderSideContent = (selectedTab) => {
    switch (selectedTab) {
      case 'Task':
        return (
          <AddTaskSideContent
            userMap={userMap}
            taskAccount={taskAccount}
            allAccounts={allAccounts}
            accountSummary={accountSummary}
            loggedInUser={loggedInUser}
            initialValues={initialValues}
            form={form}
            action={action}
            dealDetails={dealDetails}
          />
        );
      case 'Activity':
        return (
          <AddActivitySideContent
            userMap={userMap}
            activityAccount={activityAccount}
            allAccounts={allAccounts}
            accountSummary={accountSummary}
            loggedInUser={loggedInUser}
            action={action}
            initialValues={initialValues}
            form={form}
            dealDetails={dealDetails}
          />
        );
      case 'Notes':
        return (
          <AddNotesSidecontent
            userMap={userMap}
            notesAccount={notesAccount}
            allAccounts={allAccounts}
            accountSummary={accountSummary}
            loggedInUser={loggedInUser}
            initialValues={initialValues}
            form={form}
            action={action}
            dealDetails={dealDetails}
          />
        );
      default:
        return null;
    }
  };
  const onValuesChange = (changedValues, allValues) => {
    if ('task-opportunity' in changedValues) {
      const dealInfo =
        accounts.length === 1
          ? accounts[0]
          : accounts.find(
              (deal) => deal.id == changedValues['task-opportunity']
            );
      form.setFieldsValue({
        ...allValues,
        'task-account': dealInfo?.accountId,
      });
    }
    if ('task-account' in changedValues) {
      form.setFieldsValue({
        ...allValues,
        'task-opportunity': null,
      });
    }
    if ('activity-opportunity' in changedValues) {
      const dealInfo =
        accounts.length === 1
          ? accounts[0]
          : accounts.find(
              (deal) => deal.id == changedValues['activity-opportunity']
            );
      form.setFieldsValue({
        'activity-account': dealInfo?.accountId,
      });
    }
    if ('activity-account' in changedValues) {
      form.setFieldsValue({
        'activity-opportunity': null,
      });
    }
    if ('notes-opportunity' in changedValues) {
      const dealInfo =
        accounts.length === 1
          ? accounts[0]
          : accounts.find(
              (deal) => deal.id == changedValues['notes-opportunity']
            );
      form.setFieldsValue({
        'notes-account': dealInfo?.accountId,
      });
    }
    if ('notes-account' in changedValues) {
      form.setFieldsValue({
        'notes-opportunity': null,
      });
    }
  };
  const handleClose = () => {
    closeDrawer();
  };

  return (
    <Drawer
      title={
        action === 'add'
          ? addDrawerHeader[selectedTab]
          : updateDrawerHeader[selectedTab]
      }
      onClose={handleClose}
      open={showDrawer}
      closable={false}
      extra={
        <Button icon={<CloseCircleOutlined />} onClick={handleClose}>
          Close
        </Button>
      }
      styles={{
        wrapper: {
          width: '50%',
        },
        body: {
          paddingTop: 0,
        },
      }}
    >
      <Form
        className="addTaskNotesActivityForm"
        form={form}
        id="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off"
        requiredMark={customizeRequiredMark}
      >
        <Flex className="width-full height-full">
          <Flex vertical className="width-70">
            {action === 'add' ? (
              <Segmented
                options={['Task', 'Activity', 'Notes']}
                value={selectedTab}
                onChange={(value) => {
                  setSelectedTab(value);
                }}
                className="mt-30"
              />
            ) : null}
            {renderMainContent(selectedTab)}
            <Flex align="center" justify="end">
              <Button onClick={handleClose} className="mr-10">
                Cancel
              </Button>
              <Button
                form="form"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={
                  updatingActivity ||
                  updatingTask ||
                  updatingNote ||
                  addingTask ||
                  addingNote ||
                  addingActivity
                }
              >
                Save
              </Button>
            </Flex>
          </Flex>
          <Divider type="vertical" className="height-full" />
          <Flex vertical className="width-30 p-1em" gap={26}>
            {renderSideContent(selectedTab)}
          </Flex>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddTaskNoteDescriptionDrawer;
