import React from 'react';
import ReactModal from 'react-modal';
import AddUsecase from './AddUsecase';
import SimilarUsecases from './SimilarUsecases';

const AddSolution = ({
  account,
  showAddSolutionModal,
  handleSuccess = () => {},
  handleCancel = () => {},
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={showAddSolutionModal}
      className="Modal confirm-box solution-model"
      overlayClassName="Overlay confirm-box-overlay"
    >
      {showAddSolutionModal && (
        <>
          <AddUsecase
            account={account}
            isAdd={true}
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
          />

          <SimilarUsecases
            account={account}
            handleAddModuleCancel={() => handleCancel('similar_usercases')}
          />
        </>
      )}
    </ReactModal>
  );
};

export default AddSolution;
