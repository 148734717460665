import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Select from 'react-select';
import Button from '../../common/Button';
import TextField from '../../common/Form/TextField';
import InputField from '../../common/Form/InputField';
import DateField from '../../common/Form/DateField';
import MultiSelectField from '../../common/Form/MultiSelectField';
import UserDefinedDropdownField from '../../common/Form/UserDefinedDropdownField';

export const BulkUpdatePopup = forwardRef((props, ref) => {
  let selected = props?.bulkSelectValue;
  const [selectedValue, setSelectedValue] = useState(null);
  const [bulkUpdate, setBulkUpdate] = useState(false);

  useEffect(() => {
    setSelectedValue(props.bulkSelectValue);
    setBulkUpdate(props.selectAllRecords);
    if (props.onCellClicked) {
      props.onCellClicked();
    }
    console.log('props.selectAllRecords', props.selectAllRecords, props.type);
  }, []);

  const onChange = (option) => {
    if (props.type === 'multiselect' || props.type === 'userdefineddropdown') {
      setSelectedValue(option.map((val) => val.value));
      return;
    }
    setSelectedValue(option);
  };
  const customStyles = {
    control: (base) => {
      return {
        ...base,
        borderRadius: '6px',
        maxWidth: '220px',
        minWidth: '120px',
      };
    },
    indicatorSeparator: () => null,
    valueContainer: (base) => {
      return { ...base, fontWeight: '400', padding: '0px', fontSize: '13px' };
    },
  };
  const onApply = () => {
    selected = selectedValue;
    if (props.selectAllRecords && bulkUpdate) {
      console.log('updateTotalRecords', true);
      props.callback({
        option: selectedValue,
        params: props,
        updateTotalRecords: true,
      });
    }
    if (!props.selectAllRecords && bulkUpdate) {
      props.callback({ option: selectedValue, params: props });
    }
    if (!bulkUpdate) {
      props.callback({
        option: selectedValue,
        params: props,
        singleEdit: true,
      });
    }
    props.stopEditing();
  };
  const handleCancel = () => {
    props.stopEditing();
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return selected;
    },
  }));
  const handleCheckboxChange = () => {
    setBulkUpdate(!bulkUpdate);
  };

  const options = props.values?.length
    ? props.values[0].label
      ? props.values
      : props.values.map((value) => ({ value, label: value }))
    : [];

  const getField = (type) => {
    switch (type) {
      case 'text':
      case 'number':
        return (
          <InputField
            type={type}
            onChange={onChange}
            value={selectedValue ?? (type === 'number' ? 0 : '')}
          />
        );
      case 'textarea':
        return <TextField onChange={onChange} value={selectedValue} />;
      case 'date':
        return <DateField onChange={onChange} value={selectedValue} />;
      case 'multiselect':
        return (
          <MultiSelectField
            onChange={onChange}
            value={selectedValue}
            values={options}
          />
        );
      case 'userdefineddropdown':
        return (
          <UserDefinedDropdownField
            onChange={onChange}
            value={selectedValue}
            values={options}
          />
        );
      default:
        return (
          <Select
            options={props.values}
            onChange={onChange}
            value={selectedValue}
            customStyles={customStyles}
            menuPosition="fixed"
          />
        );
    }
  };

  return (
    <div className="ag-grid-popup">
      <div className="popup">
        <div className="popup-body">
          <div className="mt-20 ml-20 mr-20">{getField(props.type)}</div>

          <div className="ml-20 flex-align-center mt-10">
            <input
              type="checkbox"
              name="checkbox"
              checked={bulkUpdate ?? false}
              className="flex-align-center"
              onChange={handleCheckboxChange}
              disabled={props.selectAllRecords}
            />
            <label className="ml-5 flex-align-center">
              Update {props.numberOfSelectedCheckbox} selected item{' '}
            </label>
          </div>
        </div>
        <div className="popup-footer flex-align-center flex-end-direction mt-4  pr-5">
          <Button type="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" className="ml-5" onClick={onApply}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
});
