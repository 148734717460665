import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { URLS_USER_SERVICE } from '../../Constant/urls';
import { AXIOS_DOMAIN_NAME, USER_DOMAIN_URL } from '../../_helpers/axios';
import { businessService } from './businessService';
import CryptoJS from 'crypto-js';
import mixpanel from 'mixpanel-browser';
import { handleTokenExpired } from '../utils/apiUtils';

const AUTHORIZED_ENDPOINTS = {
  getUserDetails: true,
  activateUser: true,
  deactivateUser: true,
  changeUserRole: true,
  activateOrDeactivateCompany: true,
  deactivateUserAndTranferRecords: true,
  updateUser: true,
  addBulkUsers: true,
  deleteUser: true,
};

export const refetchUsers = async (
  { body },
  { dispatch, queryFulfilled, getState }
) => {
  const cache = getState();
  try {
    await queryFulfilled;
    dispatch(
      businessService.util.invalidateTags([
        { type: 'Users', id: 'LIST' },
        { type: 'Users', id: 'CompanyUsers' },
      ])
    );
  } catch {
    console.error('Error while adding a user');
  }
};
const userBaseQuery = fetchBaseQuery({
  baseUrl: USER_DOMAIN_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const companyId = localStorage.getItem('companyId');
    headers.set('realm', 'preskale');
    if (AUTHORIZED_ENDPOINTS[endpoint]) {
      const accessToken = localStorage.getItem('idToken');
      if (accessToken) {
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', `Bearer ${accessToken}`);
        headers.set('Access-Control-Allow-Origin', AXIOS_DOMAIN_NAME);
        headers.set('X-TENANT-ID', companyId);
      }
    } else {
      headers.set('Content-Type', 'application/json');
    }
    return headers;
  },
});

export const userServiceWithReauth = async (args, api, extraOptions) => {
  let result = await userBaseQuery(args, api, extraOptions);
  return handleTokenExpired(result, api, args, userBaseQuery, extraOptions);
};

export const userService = createApi({
  reducerPath: 'userService',
  baseQuery: userServiceWithReauth,
  endpoints: (builder) => ({
    addUsers: builder.mutation({
      query: (body) => ({
        url: URLS_USER_SERVICE.v1.createUsers,
        method: 'POST',
        body,
      }),
      // Invalidates all User-type queries providing the `LIST` id - after all,
      // depending of the sort order,
      // that newly created post could show up in any lists.
      onQueryStarted: refetchUsers,
    }),
    changeUserRole: builder.mutation({
      query: (body) => {
        return {
          url: URLS_USER_SERVICE.v1.changeRole,
          method: 'POST',
          body,
        };
      },
    }),
    getUserDetails: builder.query({
      query: () => URLS_USER_SERVICE.v1.getUserDetails,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          if (response?.data?.userId) {
            try {
              mixpanel.identify(response?.data?.userId);
              mixpanel.people.set({
                $name: response?.data?.name,
                $email: response?.data?.email,
                role:
                  response?.data?.roles.length > 0
                    ? response?.data?.roles[0]
                    : 'User',
                platform:
                  !process.env.REACT_APP_IS_DEVELOPMENT &&
                  process.env.REACT_APP_ENV === 'DEVELOPMENT'
                    ? 'AppDemo'
                    : !process.env.REACT_APP_IS_DEVELOPMENT &&
                      process.env.REACT_APP_ENV === 'SANDBOX'
                    ? 'Trial'
                    : !process.env.REACT_APP_IS_DEVELOPMENT &&
                      process.env.REACT_APP_ENV === 'PRODUCTION'
                    ? 'App'
                    : 'Other',
              });
            } catch {
              console.error('Cannot identify user.');
            }
            try {
              if (window.zipy) {
                window.zipy.identify(response?.data?.userId, {
                  email: response?.data?.email,
                });
              }
            } catch (error) {
              console.error('Cannot identify user with Zipy.');
            }
          }
        } catch {
          console.warn('Something went wrong while fetching user details');
        }
      },
    }),
    login: builder.mutation({
      query: ({ username, password }) => {
        const secretKey = process.env.REACT_APP_AUTH_SECRET;

        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const initializationVector = CryptoJS.enc.Utf8.parse(
          secretKey.substring(0, 16)
        );

        const encrypted = CryptoJS.AES.encrypt(password, key, {
          iv: initializationVector,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }).toString();

        password = encrypted;

        const body = {
          username,
          password,
        };
        return {
          url: URLS_USER_SERVICE.v1.login,
          method: 'POST',
          body,
        };
      },
    }),
    changePassword: builder.mutation({
      query: ({ username, password, recipientName = null }) => {
        const userDetails = JSON.parse(localStorage.getItem('user'));
        let newPassword;
        const secretKey = process.env.REACT_APP_AUTH_SECRET;
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const initializationVector = CryptoJS.enc.Utf8.parse(
          secretKey.substring(0, 16)
        );
        const newPasswordEncrypted = CryptoJS.AES.encrypt(password, key, {
          iv: initializationVector,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }).toString();
        newPassword = newPasswordEncrypted;

        const body = {
          userName: username,
          secret: newPassword,
          companyId: userDetails.companyId,
          userId: userDetails.userId,
          recipientName,
          type: 'password',
        };
        return {
          url: URLS_USER_SERVICE.v1.changePassword,
          method: 'POST',
          body,
        };
      },
    }),
    activateUser: builder.query({
      query: ({ userId, companyId, userName }) => {
        return {
          url: `${URLS_USER_SERVICE.v1.activateUser}?companyId=${companyId}&userId=${userId}&userName=${userName}`,
        };
      },
    }),
    deactivateUser: builder.query({
      query: ({ userId, companyId, userName }) => {
        return {
          url: `${URLS_USER_SERVICE.v1.deactivateUser}?companyId=${companyId}&userId=${userId}&userName=${userName}`,
        };
      },
    }),
    activateOrDeactivateCompany: builder.query({
      query: ({ companyId, status }) => {
        const url = `${URLS_USER_SERVICE.v1.activateDeactivateCompany}?id=${companyId}&status=${status}`;
        return url;
      },
    }),
    addBulkUsers: builder.mutation({
      query: (body) => ({
        url: URLS_USER_SERVICE.v1.addBulkUsers,
        method: 'POST',
        body,
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: URLS_USER_SERVICE.v1.updateUser,
        method: 'POST',
        body,
      }),
    }),
    deactivateUserAndTranferRecords: builder.query({
      query: ({ oldUserId, newUserId }) => {
        return `${URLS_USER_SERVICE.v1.deactivateUserAndTransferData}?oldUserId=${oldUserId}&newUserId=${newUserId}`;
      },
    }),
    deleteUser: builder.query({
      query: (userId) => {
        return `${URLS_USER_SERVICE.v1.deleteUser}?userId=${userId}`;
      },
    }),
    registerUser: builder.query({
      query: ({ code }) => {
        return `${URLS_USER_SERVICE.v1.registerUser}${code}`;
      },
    }),
  }),
});

export const userServiceBaseQuery = fetchBaseQuery({
  baseUrl: USER_DOMAIN_URL,
});

export const {
  useAddUsersMutation,
  useLoginMutation,
  useChangePasswordMutation,
  useLazyGetUserDetailsQuery,
  useLazyActivateUserQuery,
  useLazyDeactivateUserQuery,
  useChangeUserRoleMutation,
  useLazyActivateOrDeactivateCompanyQuery,
  useAddBulkUsersMutation,
  useUpdateUserMutation,
  useLazyDeactivateUserAndTranferRecordsQuery,
  useLazyDeleteUserQuery,
  useRegisterUserQuery,
} = userService; // check this
