import React from 'react';

const LegendIcon = (props) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="10" y="10" width="15" height="15" fill={props.fill} />
    </svg>
  );
};
export default LegendIcon;
