import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Flex, Input, Space, Typography } from 'antd';
import { SELECT_STYLES } from '../../common/tasks/Constants';
import { getFilterKey, processFilter } from '../../Utils/libraryUtils';
import { DateFormat } from '../../Utils/DayJs';
import { createMarkup, noData } from '../../Utils/common';
import Loader from '../../common/Loader';
import { PULSE_SCORE_DROPDOWN } from '../../Constant/ProductGapUseCases';
import { formatCount } from '../../Utils/Revenue';
import { useGetUsersQuery, selectUserMap } from '../../app/api/usersSlice';
import OpportunityList from './opportunityList';
import useDataPersistance from '../../hooks/useDataPersistance';
import PageLayout from '../../Layout/PageLayout';
import DualRingLoader from '../../common/DualRingLoader';
import Drawer from '../../common/Drawer';
import { getFilteredCount } from './utils';
import {
  productGapState,
  saveRefetchPgUsecase,
} from '../ProductGap/productGapSlice';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as RedirectSVG } from '../../Icons/NewImage/redirect.svg';
const DealPresentationView = lazy(() => import('../DealPresentationView'));

const { Text } = Typography;

const USECASE_SORT_OPTIONS = [
  {
    label: 'Date added (New to Old)',
    value: 'date-added-new-old',
  },
  {
    label: 'Date added (Old to New)',
    value: 'date-added-old-new',
  },
  {
    label: 'Revenue (High to Low)',
    value: 'revenue-high-low',
  },
  {
    label: 'Revenue (Low to High)',
    value: 'revenue-low-high',
  },
];

const ProductGapUseCases = ({
  productGapUseCases,
  productGapUseCasesLoading,
  getProductGapUseCases,
  opportunities,
  companyConfig,
}) => {
  const filterTextBoxRef = useRef();
  const dispatch = useDispatch();
  const storedData = localStorage.getItem(getFilterKey('pgUseCases'));
  let filtersFromLS = storedData ? JSON.parse(storedData) : {};
  const sortField =
    Object.keys(filtersFromLS) && filtersFromLS?.sort
      ? USECASE_SORT_OPTIONS.filter(
          (data) => data.value === filtersFromLS?.sort
        )
      : USECASE_SORT_OPTIONS[0];

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  const clearAll = useRef(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [sortUseCase, setSortUseCase] = useState(sortField);
  const [searchQuery, setSearchQuery] = useState('');
  const { refetchPgUsecases } = useSelector(productGapState);

  const { setStorageData, removeStorageData } = useDataPersistance();

  const [activeDeal, setActiveDeal] = useState('');
  useEffect(() => {
    if (clearAll.current) {
      // * Prevents calling the api if this is triggered soon after clicking on clear all
      return;
    }
    getProductGapUseCases(processFilter('productgaps', {}));
  }, []);

  useEffect(() => {
    if (refetchPgUsecases === true) {
      getProductGapUseCases(processFilter('productgaps', {}));
      dispatch(saveRefetchPgUsecase(false));
    }
  }, [refetchPgUsecases]);

  const clearAllFilters = () => {
    setSearchQuery('');
    setSelectedOpportunity(null);
    setSortUseCase(USECASE_SORT_OPTIONS[0]);
    removeStorageData('pgUseCases');
    clearAll.current = true;
    getProductGapUseCases({}, () => (clearAll.current = false));
  };

  const filterByDeal = (dealId) => {
    if (dealId === 'All') {
      setSelectedOpportunity(null);
      setStorageData('pgUseCases', { selectedOpportunity: undefined });
    } else {
      setSelectedOpportunity(dealId);
      setStorageData('pgUseCases', { selectedOpportunity: dealId });
    }
  };

  const onSort = (value) => {
    const storedData = localStorage.getItem(getFilterKey('pgUseCases'));
    let filtersFromLS = storedData ? JSON.parse(storedData) : {};
    filtersFromLS.sort = value?.value;
    setStorageData('pgUseCases', filtersFromLS);
    getProductGapUseCases(processFilter('productgaps', {}));
    setSortUseCase(value);
  };

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleAccountRedirection = (productGap) => {
    if (!productGap) {
      return;
    }
    setActiveDeal(productGap?.dealId);
  };

  return (
    <PageLayout
      contentClassname="playbook--pg-usecases"
      sidebarSize="M"
      sidebar={
        <OpportunityList
          data={opportunities}
          selectedOpportunity={selectedOpportunity}
          filterByDeal={filterByDeal}
          tab={'ProductGap'}
        />
      }
    >
      <div className="pg-usecases-right-panel">
        <Flex align="center" className="header">
          <h2 className="pg-heading flex">
            <div className="heading_level4 text-dark ">
              {' '}
              Use-cases (
              {getFilteredCount(
                productGapUseCases,
                searchQuery,
                selectedOpportunity
              )}
              )
            </div>
            <div className="clear-filter" onClick={clearAllFilters}>
              {' '}
              Clear all filters{' '}
            </div>
          </h2>
          <Space className="mr-20 ml-auto">
            <Input
              prefix={<SearchSVG />}
              ref={filterTextBoxRef}
              size="small"
              className="search-container min-width-90 mr-30"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Usecase"
            />
            <Text strong className="text-dark">
              {' '}
              Sort :{' '}
            </Text>
            <Select
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isClearable
              backspaceRemovesValue
              className="select flex-align-center"
              styles={SELECT_STYLES}
              noOptionsMessage={() => noData()}
              options={USECASE_SORT_OPTIONS}
              value={sortUseCase}
              onChange={onSort}
              menuPlacement="auto"
            />
          </Space>
        </Flex>
        {productGapUseCasesLoading ? (
          <Loader />
        ) : (
          <div className="pg-item-tab--pgUseCases pg-item-height">
            <div className="pgItems">
              {Array.isArray(productGapUseCases?.data) &&
                productGapUseCases?.data
                  .filter((productGap) => {
                    const productGapItem = selectedOpportunity
                      ? productGap.dealId === selectedOpportunity
                      : true;

                    const useCaseContainsQuery =
                      !searchQuery ||
                      productGap?.useCase
                        .toLowerCase()
                        .includes(searchQuery?.toLowerCase());

                    return productGapItem && useCaseContainsQuery;
                  })
                  ?.map((productGap) => {
                    return (
                      <div key={productGap.id} className="criteria-list">
                        <div className="flex ml-5">
                          <Flex
                            onClick={() => handleAccountRedirection(productGap)}
                            gap={8}
                            align="center"
                          >
                            <Text type="primary" className="pointer" strong>
                              {' '}
                              {productGap?.dealName}{' '}
                            </Text>
                            <Button
                              icon={<RedirectSVG />}
                              className="br-none fill-colors-primary"
                            />
                          </Flex>
                          <div className="pg-stats">
                            <div className="pg-details">
                              {productGap?.pgItem[0]?.module?.label}
                            </div>
                            <div className="pg-details">
                              {productGap?.pgItem[0]?.feature?.label}
                            </div>
                            {/* TODO: Remove this in next iteration */}
                            {/* <div className="pg-details">
                            {productGap?.pgItem[0]?.productGap?.label}
                            </div> */}

                            <div className="pg-details">
                              {' '}
                              {productGap?.pgItem[0]?.title}{' '}
                            </div>
                            <div className="pg-details star-rating">
                              <div
                                className={`tag score-${productGap?.rating}`}
                              >
                                {' '}
                                {PULSE_SCORE_DROPDOWN[productGap?.rating]}{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product-gap-stats flex-align-center">
                          <div className="pg-stats-item revenue">
                            {`$${formatCount(productGap?.dealValue)}`}
                          </div>
                          <div className="pg-stats-item">
                            {' '}
                            {userMap[productGap?.createdBy] &&
                              userMap[productGap?.createdBy].name}{' '}
                          </div>
                          <div className="pg-stats-item">
                            {' '}
                            {DateFormat(
                              productGap?.createdDate,
                              'DD MMM, YYYY'
                            )}{' '}
                          </div>
                        </div>
                        <div
                          className="rtf-text use-cases-comments ml-5"
                          dangerouslySetInnerHTML={createMarkup(
                            productGap?.useCase
                          )}
                        ></div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}
      </div>
      {activeDeal && (
        <Suspense
          fallback={
            <Drawer showDrawer={true} className="presentationViewDrawer">
              <div className="loader-container--suspense">
                <DualRingLoader dark />
              </div>
            </Drawer>
          }
        >
          <DealPresentationView
            showPresentationView={true}
            closePresentationView={() => {
              setActiveDeal('');
            }}
            dealId={activeDeal}
            refreshView={(deal) => setActiveDeal(deal.dealId)}
            iscrmAccount={companyConfig?.data?.crm}
          />
        </Suspense>
      )}
    </PageLayout>
  );
};

export default ProductGapUseCases;
