import React, { useState, useEffect } from 'react';
import PageLayout from '../../Layout/PageLayout';
import { useHistory, useParams } from 'react-router-dom';
import UnassignedOpportunities from './UnassignedOpportunities';
import AllRequests from './AllRequests';
import useDataPersistance from '../../hooks/useDataPersistance';
import mixpanel from 'mixpanel-browser';
import { isSalesOwnerOrManagerOrAdmin } from '../../Utils/common';

const RequestForm = () => {
  const history = useHistory();
  let { tab } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const { setStorageData } = useDataPersistance();

  const handleTabClick = (index) => () => {
    if (index === 1) {
      setStorageData('requestForm', 'rrf', true);
      history.push(`/requestForm/rrf`);
      return;
    }
    if (index === 0) {
      if (isSalesOwnerOrManagerOrAdmin) {
        try {
          mixpanel.track('request_form_allrequests_accessed', {
            event: 'All requests accessed from request form',
          });
        } catch {
          console.error('Cannot add event to mixpanel');
        }
      }
      setStorageData('requestForm', 'allrequests', true);
      history.push(`/requestForm/allrequests`);
      return;
    }
  };

  useEffect(() => {
    if (tab === 'allrequests') {
      setActiveTab(0);
    }
    if (tab === 'rrf') {
      setActiveTab(1);
    }
  }, [tab]);

  return (
    <PageLayout
      headerMainContent={
        <>
          <button
            onClick={handleTabClick(1)}
            className={`tab-button ${activeTab === 1 ? 'tab-active' : ''}`}
          >
            Resource Request Form
          </button>

          <button
            onClick={handleTabClick(0)}
            className={`tab-button ${activeTab === 0 ? 'tab-active' : ''}`}
          >
            All Requests
          </button>
        </>
      }
    >
      {activeTab === 1 ? <UnassignedOpportunities /> : <AllRequests />}
    </PageLayout>
  );
};

export default RequestForm;
