import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import DualRingLoader from '../common/DualRingLoader';
import AgGridTooltip from '../common/AgGridTooltip';
import customNoRowsOverlay from '../Pipeline/utils/customNoRowsOverlay';
import { useGetUnassignedDealsMutation } from '../Pipeline/pipelineSlice';
import { formatCount } from '../Utils/Revenue';
import { CustomHeaderComponent } from '../Pipeline/components/CustomHeaderComponent';
import { Button, Spin } from 'antd';
import { getDateByOperator, getFormattedDate } from '../Utils/DateUtils';
import CustomPinnedRowRenderer from '../features/calendar/PinnedRowRenderer';
import AssignOpportunityModal from './AssignOpportunityModal';
import { _setDateFilter } from '../Pipeline/utils/pipelineUtils';
import UnAssignedDealInfo from './UnAssignedDealInfo';
import { DATE_OPERATOR_OPTIONS } from '../Constant/FilterOperators';

const SORT_DIRECTIONS = {
  asc: 'ASC',
  desc: 'DESC',
};
const COLUMN_HEADERS = {
  name: 'deal_name',
  dealValue: 'deal_value',
  'dealOwner.displayName': 'owner.display_name',
  crmStage: 'status',
  expectedCloseDate: 'expected_close',
  accountName: 'account_name',
};

const AssignPresalesUserTable = ({ filter = {}, searchTerm = '' }) => {
  const gridRef = useRef(null);
  const updatedRows = useRef({});
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortField, setSortField] = useState('start_time');
  const [getUnassignedDeals] = useGetUnassignedDealsMutation();
  const totalRows = useRef(0);
  const [checkingMasterBox, setCheckingMasterBox] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [openAssignDealOpportunityModal, setOpenAssignOpportunityModal] =
    useState(false);
  const [totalSelected, setTotalSelected] = useState(false);

  const [rowsToUpdate, setRowsToUpdate] = useState([]);

  const currentTableState = useRef({
    selectAll: false,
    totalSelected: false,
  });

  const processFilter = (filterJson) => {
    let tempFilter = {};
    let currentFilter = {};
    const filterLs = JSON.parse(localStorage.getItem('filters')) ?? {};

    if (filterJson && Object.keys(filterJson)?.length) {
      currentFilter = filterJson;
    } else if (filterLs && Object.keys(filterLs)?.length) {
      currentFilter = filterLs;
    }

    if (
      filterJson &&
      filterLs &&
      !Object.keys(filterLs)?.length &&
      !Object.keys(filterJson)?.length
    ) {
      currentFilter = { expectedCloseDate: DATE_OPERATOR_OPTIONS[3]?.value };
    }

    if (Array.isArray(currentFilter?.salesStage)) {
      tempFilter.stage = currentFilter?.salesStage?.map((data) => data?.value);
    }
    if (Array.isArray(currentFilter?.owners)) {
      tempFilter.salesPerson = currentFilter?.owners?.map(
        (data) => data?.value
      );
    }
    if (currentFilter?.revenue) {
      tempFilter.revenue = { operator: currentFilter?.revenue };
    }
    if (currentFilter?.amount) {
      tempFilter.revenue = {
        ...tempFilter.revenue,
        value: currentFilter?.amount?.toString() ?? '',
      };
    }
    if (Array.isArray(currentFilter?.type)) {
      tempFilter.type = currentFilter?.type?.map((data) => data?.value);
    }

    if (
      currentFilter?.expectedCloseDateRange &&
      currentFilter?.expectedCloseDateRange.length &&
      currentFilter?.expectedCloseDate !== 'ALL'
    ) {
      tempFilter.expectedCloseDate = {};
      tempFilter.expectedCloseDate.fromDate =
        currentFilter?.expectedCloseDateRange[0] ?? '';
      tempFilter.expectedCloseDate.toDate =
        currentFilter?.expectedCloseDateRange[1] ?? '';
    }
    if (
      currentFilter?.expectedCloseDate &&
      currentFilter?.expectedCloseDate !== 'CUSTOM'
    ) {
      tempFilter.expectedCloseDate = {};
      const dateRange = getDateByOperator(currentFilter?.expectedCloseDate);
      tempFilter.expectedCloseDate.fromDate = dateRange?.fromDate;
      tempFilter.expectedCloseDate.toDate = dateRange?.toDate;
    }
    return tempFilter;
  };

  useEffect(() => {
    hideButtons();
    gridRef?.current?.api?.setDatasource(datasource);
    gridRef?.current?.api?.hideOverlay();
  }, [filter, sortField, sortOrder, searchTerm]);

  const IndexCellRenderer = useCallback((props) => {
    if (props.value !== undefined) {
      return parseInt(props?.value) + 1;
    } else if (props?.value === undefined) {
      return <DualRingLoader dark />;
    }
  }, []);

  const datasource = {
    rowCount: null,
    getRows(params) {
      let { startRow } = params;
      const body = {
        page: startRow / 20,
        searchTerm: searchTerm,
        size: 20,
        direction: sortOrder,
        field: sortField,
        filter: processFilter(filter),
      };
      getUnassignedDeals(body)
        .unwrap()
        .then((res) => {
          const data = res?.face1;
          if (body.page === 0) {
            // * Total records will be available only on the first call
            totalRows.current = res.totalRecords;
          }
          if (data) {
            if (!totalRows.current) {
              gridRef?.current?.api?.showNoRowsOverlay();
            }
            if (totalRows.current) {
              gridRef.current?.api?.hideOverlay();
            }
            params.successCallback(
              data,
              totalRows.current > 500 ? 500 : totalRows.current
            );
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          params.failCallback();
        });
    },
  };
  const onGridReady = (params) => {
    params?.api?.setDatasource(datasource);
  };

  const onPaginationChanged = () => {
    if (selectAll && totalSelected) {
      gridRef?.current?.api?.forEachNode((node) => {
        if (!node.isSelected()) {
          node.setSelected(true);
        }
      });
    }
  };

  const onSortChange = (params) => {
    const sortModel = params?.api?.sortController?.getSortModel();
    let field;
    let direction;
    if (sortModel[0]) {
      field = COLUMN_HEADERS[sortModel[0].colId];
      direction = SORT_DIRECTIONS[sortModel[0].sort];
    }
    if (sortModel.length > 0) {
      setSortField(field);
      setSortOrder(direction);
    } else {
      setSortField('start_time');
      setSortOrder('DESC');
    }
  };

  const noRowsOverlayComponent = useMemo(() => {
    return customNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'No Data',
    };
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      suppressMovable: true,
      sortable: true,
      comparator: () => 0,
      cellStyle: () => ({
        borderRight: '1px solid #d8e4fd',
      }),
      tooltipComponent: AgGridTooltip,
    }),
    []
  );

  function showButtons() {
    const selected = currentTableState?.current?.totalSelected
      ? totalRows?.current
      : gridRef.current?.api?.getSelectedRows().length;
    return [
      {
        pinned: (
          <section className="sync-button-container">
            <p className="alert-text">
              {selected ? (
                <strong>
                  {selected} out of {totalRows.current} deals selected.
                </strong>
              ) : null}{' '}
              Click on proceed to Assign Deals {selected ? '.' : ''}
            </p>
            <div className="save-btns">
              <Button onClick={hideButtons} ghost>
                Cancel
              </Button>
              <Button onClick={() => setOpenAssignOpportunityModal(true)}>
                Proceed to Assign Deals
              </Button>
            </div>
          </section>
        ),
      },
    ];
  }

  function hideButtons() {
    const rows = Object.values(updatedRows.current);
    updatedRows.current = {};
    setRowsToUpdate([]);
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.setPinnedTopRowData([]);
    if (currentTableState?.current?.selectAll) {
      setSelectAll(false);
      currentTableState.current.selectAll = false;
    }
    if (currentTableState?.current?.totalSelected) {
      setTotalSelected(false);
      currentTableState.current.totalSelected = false;
    }
    return [];
  }

  const onRowSelected = (row) => {
    setRowsToUpdate(gridRef.current?.api?.getSelectedRows() ?? []);
    if (
      gridRef?.current?.api?.getSelectedRows()?.length &&
      !gridRef?.current?.api?.getPinnedTopRow(0)
    ) {
      gridRef.current.api.setPinnedTopRowData(showButtons());
    }

    if (!gridRef?.current?.api?.getSelectedRows()?.length) {
      gridRef.current?.api?.setPinnedTopRowData([]);
    }

    // * Update selected count if the save/cancel pinned row is visible
    if (gridRef?.current?.api?.getPinnedTopRow(0)) {
      gridRef.current.api.setPinnedTopRowData(showButtons());
    }
  };

  const getRowHeight = () => 45;

  const formatCountMemoized = useCallback((value) => {
    if (value) {
      return formatCount(value);
    }
  }, []);

  const gridStyle = useMemo(
    () => ({ height: !rowsToUpdate?.length ? '85%' : '100%', width: '100%' }),
    [rowsToUpdate]
  );

  return (
    <>
      {checkingMasterBox && <Spin fullscreen />}
      {rowsToUpdate && rowsToUpdate?.length === 0 && (
        <div className="mt-18 ml-5 mr-5 mb-10">
          <UnAssignedDealInfo />
        </div>
      )}
      <div className="pipeline-table p-8">
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            cacheBlockSize={20}
            rowModelType={'infinite'}
            cacheOverflowSize={20}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={500}
            infiniteInitialRowCount={1}
            onGridReady={onGridReady}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            onSortChanged={onSortChange}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={onRowSelected}
            getRowHeight={getRowHeight}
            pinnedTopRowData={[]}
            onPaginationChanged={onPaginationChanged}
            stopEditingWhenCellsLoseFocus={true}
          >
            <AgGridColumn
              headerName=""
              field="checkbox"
              maxWidth={52}
              pinned="left"
              sortable={false}
              resizable={false}
              checkboxSelection={true}
              showDisabledCheckboxes={true}
              headerComponent={CustomHeaderComponent}
              headerComponentParams={{
                masterCheckboxValue: selectAll,
                handleMasterCheckbox: (e) => {
                  setCheckingMasterBox(true);
                  setSelectAll(e.target.checked);
                  currentTableState.current.selectAll = e.target.checked;

                  //check all the boxes
                  if (e.target.checked) {
                    gridRef.current?.api?.setPinnedTopRowData(showButtons());
                    gridRef.current.api.forEachNode((node) => {
                      node.setSelected(true);
                    });
                  }

                  // uncheck all the boxes
                  if (!e.target.checked) {
                    gridRef.current.api.deselectAll();
                    if (totalSelected) {
                      setTotalSelected(false);
                      currentTableState.current.totalSelected = false;
                    }
                  }
                  setCheckingMasterBox(false);
                },
              }}
              colSpan={(params) => {
                if (params.node.rowPinned) {
                  // have all pinned columns span 6 columns
                  return 5;
                } else {
                  // all other rows should be just normal
                  return 1;
                }
              }}
              cellRendererSelector={(params) => {
                if (params.node.rowPinned) {
                  return {
                    component: CustomPinnedRowRenderer,
                    params: {
                      style: {
                        backgroundColor: '#447BF2',
                        lineHeight: 'initial',
                        height: 'inherit',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      },
                    },
                  };
                } else {
                  // rows that are not pinned don't use any cell renderer
                  return undefined;
                }
              }}
              cellStyle={(params) => {
                if (params.node.rowPinned) {
                  return {
                    padding: 0,
                    border: 0,
                    width: 'calc(100vw - 52px) !important',
                  };
                } else {
                  return {
                    borderRight: '1px solid #d8e4fd',
                  };
                }
              }}
              // it is important to have node.id here, so that when the id changes (which happens
              // when the row is loaded) then the cell is refreshed.
              valueGetter={(params) => params?.node?.id}
            />
            <AgGridColumn
              headerName="S.No."
              maxWidth={100}
              pinned="left"
              sortable={false}
              resizable={false}
              // it is important to have node.id here, so that when the id changes (which happens
              // when the row is loaded) then the cell is refreshed.
              valueGetter={(params) => params?.node?.id}
              cellRenderer={IndexCellRenderer}
            />
            <AgGridColumn
              field="name"
              headerName="Deal Name"
              minWidth={200}
              pinned="left"
            />
            <AgGridColumn
              field="accountName"
              headerName="Account"
              minWidth={150}
            />

            <AgGridColumn
              field="dealOwner.displayName"
              headerName="Sales Owner"
              minWidth={150}
            />

            <AgGridColumn
              field="crmStage"
              headerName="Sales Stage"
              minWidth={150}
              sortable={false}
              singleClickEdit="true"
            />

            <AgGridColumn
              field="dealValue"
              headerName="Revenue"
              cellRenderer={(props) => {
                return formatCountMemoized(props.value);
              }}
              minWidth={150}
            />

            <AgGridColumn
              field="expectedCloseDate"
              headerName="Expected Close Date"
              minWidth={150}
            />
          </AgGridReact>
        </div>
      </div>
      {openAssignDealOpportunityModal && (
        <AssignOpportunityModal
          openModal={openAssignDealOpportunityModal}
          handleCancel={() => {
            setOpenAssignOpportunityModal(false);
            hideButtons();
            gridRef?.current?.api?.setDatasource(datasource);
          }}
          rows={rowsToUpdate}
        />
      )}
    </>
  );
};

export default AssignPresalesUserTable;
