import React, { useEffect } from 'react';
import { DatePicker, Form, Select, Space, TimePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import RichTextEditor from '../RichTextEditor';
import localeData from 'dayjs/plugin/localeData';
import { convertHTMLtoPlainText } from '../../Utils/common';

dayjs.extend(localeData);
const { Text } = Typography;
const formatTime = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';

const AddActivityMainContent = ({ outcomes, action, initialValues, form }) => {
  useEffect(() => {
    if (initialValues && action === 'update') {
      const minutes = Number(initialValues?.timeSpent) % 60 ?? 0;
      const hours = Math.floor(Number(initialValues?.timeSpent / 60)) ?? 0;
      form.setFieldsValue({
        'activity-outcome': initialValues.outcomeId,
        'activity-description': initialValues?.description,
        'activity-date': dayjs(initialValues?.activityDate, dateFormat),
        'activity-timespent': dayjs(`${hours}:${minutes}`, formatTime),
      });
    }
    if (action === 'add') {
      form.setFieldsValue({
        'activity-date': dayjs(new Date().toISOString(), dateFormat),
      });
    }
  }, [initialValues, form]);
  return (
    <>
      <Form.Item
        label={<Text strong>Activity Outcome</Text>}
        className="mt-30"
        name="activity-outcome"
        rules={[
          {
            required: true,
            message: 'Activity outcome field is Required',
          },
        ]}
      >
        <Select
          options={outcomes}
          placeholder="Select an outcome"
          size="large"
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
      <Form.Item
        className="mt-10"
        label={<Text strong>Activity description</Text>}
        name="activity-description"
        rules={[
          {
            required: true,
            message: 'Description field is Required',
            validator: (_, value) => {
              if (!value) {
                return Promise.reject('Description field is Required');
              }
              if (value && !convertHTMLtoPlainText(value)) {
                return Promise.reject('Description field is Required');
              }
              if (value && convertHTMLtoPlainText(value)) {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <RichTextEditor className="mention-wrapper description" />
      </Form.Item>

      <Space>
        <Form.Item
          name="activity-date"
          label={<Text strong>Activity Date</Text>}
          rules={[
            {
              required: true,
              message: 'Activity field is Required',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="activity-timespent"
          label={<Text strong>Time Spent</Text>}
        >
          <TimePicker format={formatTime} />
        </Form.Item>
      </Space>
    </>
  );
};

export default AddActivityMainContent;
