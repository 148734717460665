import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { businessService } from './businessService';
import { URLS_BUSINESS_SERVICE, URLS_USER_SERVICE } from '../../Constant/urls';

const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState();

export const extendedUsersSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.users.getAll;
      },
      transformResponse: (responseData) => {
        // TODO : Refactor data.entities
        const result = usersAdapter.setAll(initialState, responseData);
        const response = { ...result };
        response.original = responseData;
        return response;
      },
      // Provides a list of `Users` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Users` element was added.
      providesTags: (result) =>
        result
          ? [
              ...Object.values(result?.entities).map(({ id }) => ({
                type: 'Users',
                id,
              })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    getDetails: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.users.getDetails;
      },
      providesTags: (result) => [{ type: 'Users', id: 'UserDetails' }],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.users.updateProfileInformation,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Users', id: 'UserDetails' }],
    }),
    getAllCompanyUsers: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.users.getAllCompanyUsers;
      },
      transformResponse: (responseData) => {
        return usersAdapter.setAll(initialState, responseData?.data);
      },
      providesTags: (result) => [
        { type: 'Users', id: 'UserDetails' },
        { type: 'Users', id: 'CompanyUsers' },
      ],
    }),
    getUserDetailsByRole: builder.query({
      query: ({ role }) => {
        return `${URLS_BUSINESS_SERVICE.users.getAllUsersByRole}?role=${role}`;
      },
    }),
    checkIsJiraIntegrated: builder.query({
      query: () => URLS_BUSINESS_SERVICE.users.checkJiraIntegration,
      transformResponse: (responseData) => {
        localStorage.setItem(
          'jiraIntegration',
          responseData?.is_jira_connection
        );
        return responseData?.is_jira_connection;
      },
      providesTags: () => [{ type: 'Jira', id: 'Check_Integration' }],
    }),
    checkIsZohoBugsIntegrated: builder.query({
      query: () => URLS_BUSINESS_SERVICE.users.zohoBugsIntegration,
      transformResponse: (responseData) => {
        localStorage.setItem(
          'zohobugsIntegration',
          responseData?.is_zoho_connection
        );
        return responseData?.is_zoho_bug_connection;
      },
      providesTags: () => [{ type: 'Zoho', id: 'Check_Integration' }],
    }),
    activateUser: builder.query({
      query: ({ userId, companyId }) => {
        return `${URLS_BUSINESS_SERVICE.users.activateUser}?user_id=${userId}&company_id=${companyId}`;
      },
    }),
    deactivateUser: builder.query({
      query: ({ userId, companyId }) => {
        return `${URLS_BUSINESS_SERVICE.users.deactivateUser}?user_id=${userId}&company_id=${companyId}`;
      },
    }),
    checkExistingUsers: builder.query({
      query: (email) => {
        return `${URLS_BUSINESS_SERVICE.users.checkExistingUser}?email=${email}`;
      },
    }),
    signupUser: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.users.signupUser,
        method: 'POST',
        body,
      }),
    }),
    getUserPreferenceById: builder.query({
      query: ({ userId, companyId }) => {
        return `${URLS_BUSINESS_SERVICE.userPreferences.getUserPreferences}?userId=${userId}&companyId=${companyId}`;
      },
    }),
    getUserProfileDetails: builder.query({
      query: () => {
        return `${URLS_BUSINESS_SERVICE.users.profileDetails}`;
      },
    }),
    getCompanyUsers: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.users.getCompanyUsers,
        method: 'POST',
        body,
      }),
      // invalidatesTags: (result) => [{ type: 'Users', id: 'UserDetails' }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetDetailsQuery,
  useUpdateProfileMutation,
  useGetAllCompanyUsersQuery,
  useLazyGetAllCompanyUsersQuery,
  useLazyGetUserDetailsByRoleQuery,
  useCheckIsJiraIntegratedQuery,
  useCheckIsZohoBugsIntegratedQuery,
  useLazyActivateUserQuery,
  useLazyDeactivateUserQuery,
  useLazyCheckExistingUsersQuery,
  useSignupUserMutation,
  useGetUserPreferenceByIdQuery,
  useGetUserProfileDetailsQuery,
  useGetCompanyUsersMutation,
} = extendedUsersSlice;

export const selectUserNameMap = (data) => {
  let usersMap = {};
  const users = data?.entities && Object.values(data?.entities);
  users &&
    users.forEach((user) => {
      usersMap[user.userName] = user.id;
    });
  return {
    data: usersMap,
  };
};

export const selectUserIdMap = (data) => {
  const users = data?.data?.entities && Object.values(data?.data?.entities);
  let usersData = users?.map((user) => {
    return {
      value: user?.id,
      label: user?.name,
    };
  });
  return {
    data: usersData ? usersData : [],
  };
};

export const selectUserEmailMap = (data) => {
  if (data?.data?.entities && data?.data?.ids) {
    const usersData = data?.data?.ids?.map((id) => {
      return {
        value: data?.data?.entities[id]?.email,
        label: data?.data?.entities[id]?.name,
      };
    });
    return {
      data: usersData ? usersData : [],
    };
  }
  return {
    data: [],
  };
};

export const selectUserMap = ({ data }) => ({ data: data?.entities ?? {} });

export const selectUsersResult = extendedUsersSlice.endpoints.getUsers.select();

export const selectUserList = ({ data }) => ({
  data: data?.original && data?.original.length > 0 ? data?.original : [],
});

const selectUsersData = createSelector(
  selectUsersResult,
  (usersResult) => usersResult.data
);

export const selectUserEmail = (responseData) => {
  const users =
    responseData?.data?.entities && Object.values(responseData?.data?.entities);
  const usersData = Array.isArray(users) ? users.map((item) => item.email) : [];
  return {
    data: usersData ? usersData : [],
  };
};

export const { selectAll: selectAllUsers, selectById: selectUserById } =
  usersAdapter.getSelectors((state) => selectUsersData(state) ?? initialState);
