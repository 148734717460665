import React, { useState } from 'react';
import DealSummaryCard from './DealSummaryCard';
import NoContent from './NoContent';
import SearchField from './SearchField';

export const SearchableSidebar = ({
  deals,
  activeCard,
  onCardClick,
  handleSearch = () => {},
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    if (!event.target.value) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(event.target.value?.toLowerCase());
    handleSearch();
  };

  return (
    <div className="searchable-sidebar--container">
      <SearchField
        className="searchable-sidebar-search--container"
        placeholder="Search Deals"
        onChange={handleChange}
      />
      <div className="content-container">
        {deals && deals.length ? (
          deals.map(
            (deal) =>
              deal?.dealName?.toLowerCase().includes(searchTerm) && (
                <DealSummaryCard
                  key={deal.id}
                  dealData={deal}
                  activeCard={activeCard}
                  onCardClick={onCardClick}
                />
              )
          )
        ) : (
          <NoContent message="No Opportunities Found" className="no-content" />
        )}
      </div>
    </div>
  );
};
