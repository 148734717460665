import { usePopper } from 'react-popper';
import 'react-popper-tooltip/dist/styles.css';
import React, { useState, useEffect } from 'react';

const DropdownMenu = ({ content, children, placement = 'auto' }, ref) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update, state } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );

  const show = () => {
    state?.elements?.popper?.setAttribute('data-show', 'show');

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    if (!update) {
      return;
    }
    update();
  };

  const hide = (event) => {
    const popper = document.getElementsByClassName(
      'preskale-dropdown-container'
    );
    if (popper.length) {
      if (popper[0]?.contains(event.target)) {
        return;
      }
      popper[0].removeAttribute('data-show');
    }
  };

  const showEvents = ['click', 'focus'];

  useEffect(() => {
    showEvents.forEach((event) => {
      state?.elements?.reference?.addEventListener(event, show);
    });

    return () => {
      showEvents.forEach((event) => {
        state?.elements?.reference?.removeEventListener(event, show);
      });
    };
  }, [state?.elements?.reference]);

  useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener('mousedown', hide);
    return () => {
      document.removeEventListener('mousedown', hide);
    };
  }, []);

  return (
    <>
      <span ref={setReferenceElement}>{children}</span>

      <div
        ref={setPopperElement}
        className="preskale-dropdown-container"
        style={styles.popper}
        {...attributes.popper}
      >
        <section
          style={styles.offset}
          className="preskale-dropdown-menu"
          ref={ref}
        >
          {content}
        </section>
        <div
          ref={setArrowElement}
          style={styles.arrow}
          className="preskale-dropdown-arrow"
          data-popper-arrow
        />
      </div>
    </>
  );
};

export default React.forwardRef(DropdownMenu);
