import React, { useEffect, useRef, useState } from 'react';
import useDealDetails from '../hooks/useDealDetails';
import { toast } from 'react-toastify';

const useDashboardSearchProps = (handleSearch, searchResponse) => {
  const inputref = useRef(null);
  const toastID = useRef(null);
  const [clickedDeal, setClickedDeal] = useState(null);
  const { goToDealDetails, loading, fetching } = useDealDetails();
  const navigateToDeal = (dealItem) => {
    const account = {
      dealId: dealItem.dealId,
      name: dealItem.dealName,
      presalesUser: dealItem.presalesUser,
      presalesUserId: dealItem.presalesUserId,
    };
    setClickedDeal(account);
    goToDealDetails({ dealId: dealItem.dealId });
    inputref.current.value = '';
  };

  useEffect(() => {
    if (!loading && !fetching && !toastID.current) {
      return;
    }
    if (loading || fetching) {
      toastID.current = toast(`Loading ${clickedDeal?.name}`, {
        progressClassName: 'loading-progress-bar',
      });
    }
    if (!loading && !fetching && toastID.current) {
      setClickedDeal(null);
      toast.dismiss(toastID.current);
    }
  }, [loading, fetching]);

  const searchProps = {
    ref: inputref,
    placeholder: 'Search Deals(Atleast three characters required)',
    className: 'width-300px',
    onChange: handleSearch,
    options: searchResponse,
    renderOption: (item) => (
      <>
        <div className="heading">{item['dealName']}</div>
        <div className="flex col-gap-20">
          <div className="sub-text" title={item['presalesUser']}>
            {' '}
            {item['presalesUser']}
          </div>
          <div className="sub-text" title={item?.accountInfo[0]?.name}>
            {item?.accountInfo[0]?.name}
          </div>
        </div>
      </>
    ),
    onSelect: (item) => navigateToDeal(item),
  };

  return searchProps;
};

export default useDashboardSearchProps;
