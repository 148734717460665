import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';

export const MultiSelect = (props) => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'All',
  };

  useEffect(() => {
    if (props?.value && props?.value[0]?.label === 'All') {
      props.onChange(props.options);
    }
  }, [props]);

  const isAllSelected = () => {
    if (!valueRef?.current || !props.options) {
      return false;
    }
    return valueRef.current.length === props.options.length;
  };

  const isOptionSelected = (option) =>
    valueRef?.current?.some(({ value }) => value === option.value) ||
    isAllSelected();

  const getOptions = () =>
    Array.isArray(props.options)
      ? [selectAllOption, ...props.options]
      : [selectAllOption];

  const getValue = () => (isAllSelected() ? [selectAllOption] : props.value);

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isAllSelected()) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      isDisabled={props.isDisabled}
      value={getValue()}
      onChange={onChange}
      components={props.components}
      styles={props.styles}
      className={props.className}
      hideSelectedOptions={props.hideSelectedOptions ?? false}
      closeMenuOnSelect={props.closeMenuOnSelect ?? false}
      isSearchable={props.isSearchable ?? true}
      isLoading={props.isLoading ?? false}
      isMulti
      menuPlacement={props.menuPlacement ?? 'auto'}
    />
  );
};
