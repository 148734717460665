import React, { useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import emailIcon from '../Icons/email.svg';
import passwordIcon from '../Icons/lock.svg';
import viewIcon from '../Icons/view.svg';
import hideIcon from '../Icons/hide.svg';
import { ReactComponent as GsignIcon } from '../Icons/GSignIn.svg';
import { ReactComponent as OktaIcon } from '../Icons/okta.svg';
import {
  useLazyGetUserDetailsQuery,
  useLoginMutation,
} from '../app/api/userService';
import { useLazyFetchCompanyDetailsQuery } from '../Company/companySlice';
import { toast } from 'react-toastify';
import DualRingLoader from '../common/DualRingLoader';
import { setCredentials, setUser } from './authSlice';
import { useDispatch } from 'react-redux';
import styles from '../styles/components/loginForm.module.scss';
import authenticationService from '../_services/authentication.service';
import { AUTH_DOMAIN_NAME } from '../_helpers/axios';

const loginForm = () => {
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const [getCurrentUser] = useLazyGetUserDetailsQuery();
  const [getCompanyDetails] = useLazyFetchCompanyDetailsQuery();

  const passwordRef = useRef(null);
  const passwordVisibilityRef = useRef(null);

  const togglePasswordVisibilityIcon = () => {
    if (!passwordRef?.current || passwordRef?.current.type === 'password') {
      passwordVisibilityRef.current.src = viewIcon;
      return;
    }
    if (passwordRef?.current.type === 'text') {
      passwordVisibilityRef.current.src = hideIcon;
      return;
    }
  };
  const togglePasswordVisibility = () => {
    if (passwordRef?.current?.type === 'password') {
      passwordRef.current.type = 'text';
      togglePasswordVisibilityIcon();
      return;
    }
    if (passwordRef?.current?.type === 'text') {
      passwordRef.current.type = 'password';
      togglePasswordVisibilityIcon();
      return;
    }
  };

  const handleSSOButton = (type) => {
    const googleURL = `https://preskalegoogle.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${AUTH_DOMAIN_NAME}/callback/sso&response_type=CODE&client_id=7hhl697if2prltnf1q3bcr7tvp&scope=email%20openid%20phone%20profile`;
    const oktaURL = `https://preskalegoogle.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=GitHubOkta&redirect_uri=${AUTH_DOMAIN_NAME}/callback/sso&response_type=CODE&client_id=7hhl697if2prltnf1q3bcr7tvp&scope=email%20openid%20phone%20profile`;
    // 'https://github.okta.com/app/github_preskale_1/exk1pvtw5le2S8kGQ1d8/sso/saml?SAMLRequest=fZLNTuswEIX3PEXkvfPjppdgNUEVCITEXVwKCLGp3GSSWk1s45mUXp6eNG0RbFh6dM58o3M8u9x1bbAFj9qanCVhzAIwpa20aXL29HjDM3ZZnM1Qda1wct7T2jzAWw9IwRwRPA2%2BK2uw78AvwG91CU8P9zlbEzmUUeQ84Ea10FjbtBCqYUHYIweFxJNQderDDrjGaLJhabtoBEW62vvcsBdYcD3AtFE0Xnja22ha96vQbkiNPuXccbY8IZdJBLtN4rb0Pm1BLLLN7b%2BkyiJEO2JYcHeds2WaZRdJOqm5qKHmKYiSrwTEPL2AqlJZpaZ1OkgRe7gzSMpQzkQsUh5PeXz%2BmCRSTORkEqZT8cqC51OUYh%2FlEK5BeQgvZ7030irUKI3qACWVcjH%2Fey8HqXTeki1ty4pD1nIE%2BuDG%2Bk7R7979RFe8HqUSDGn6%2F4P9u12demTFXnboRB5LkejkV1%2FL3eo1flnp7Z9Z9P3K4vj8%2BUGKTw%3D%3D&RelayState=H4sIAAAAAAAAAD2Q3XLaMBBG30XXCCxbxpg74jIYSoAkFAOdjkeW5D9ky7FlIHT67t1cJHff7p7d2Tl_EUNT1HdYss5gEt-Ts3VMiusYDVACk0Vhwj7ZXgyDBoeGl-dq7HtFajetMnVK3p2Et565NgAIAHJjmm46Gpm2YGrYtLK7MCWHXFcjzpRKGL-Muk4DLYHmWkiIKcS32fMaYoamv5GsWKGg0I2sCwGhyXX9CTatTgsl0Z8BusBONT7g1ibRMtyPr1n5mEeHQyra4nZ8DcKf0f4pX7JV0IXnBjvrY_xu3Mtyzkts0f32oXp5nc39XSSi5EN43qJ0dnG5o9aP6Jfwt4fodbaJtrfAsmfn8OXEKtsunZdg1awWshctrZ62jw9uNffbLjivNx7d-2-rnDw2vr-ugjgro2p3ej4t7nFbixs8r-DjLyGZ1hloYb3Jh9_6h6xiD11zndWF0Z_OYKtCU-IR15oQizhgAE1Tpjo5QC2cIz6hHks49t2Jiym1OE7Gbopt7hDGGeXC9eGGATSmkwnQDgxTmWIqbWBtaWHqSyHYRDA3pejffz336B4SAgAA.H4sIAAAAAAAAAAEgAN___QkeFS61_9mVhVG7qYf3MEVX3-B4XVX8kZchoOnD9cfRyPWWIAAAAA.4';
    // `https://preskalegoogle.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=GitOktaIDP&redirect_uri=${AUTH_DOMAIN_NAME}/callback/sso&response_type=CODE&client_id=7hhl697if2prltnf1q3bcr7tvp&scope=email%20openid%20phone%20profile`;

    window.open(type === 'google' ? googleURL : oktaURL, '_blank');
  };

  const handleSubmit = (values, { setSubmitting }) => {
    login({ username: values.email, password: values.password })
      .unwrap()
      .then(async (response) => {
        if (response.data.idToken) {
          localStorage.setItem('idToken', response.data.idToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('companyId', response.data.companyId);
          dispatch(
            setCredentials({
              idToken: response.data.idToken,
              refreshToken: response.data.refreshToken,
              companyId: response.data.companyId,
            })
          );
          await getCurrentUser()
            .unwrap()
            .then((response) => {
              localStorage.setItem('user', JSON.stringify(response));
              localStorage.setItem('authenticated', response.authenticated);
              dispatch(setUser(response));
              authenticationService.setUserDetails();
              let companyId = response?.data?.companyId;
              getCompanyDetails()
                .unwrap()
                .then((data) => {
                  let isCompanyOnMixPanel;
                  try {
                    isCompanyOnMixPanel = mixpanel
                      .get_group('company', companyId)
                      .set_once('Company_name', data?.data?.name);
                  } catch {
                    isCompanyOnMixPanel = false;
                  }
                  if (!isCompanyOnMixPanel && companyId) {
                    try {
                      mixpanel
                        .add_group('company', companyId)
                        .set_once('Company_name', data?.data?.name);
                    } catch {
                      console.error('Cannot add group to mixpanel.');
                    }
                  }
                  let isCompanyAUserProp;
                  try {
                    isCompanyAUserProp = mixpanel.get_property('company');
                  } catch {
                    isCompanyAUserProp = false;
                  }
                  if (!isCompanyAUserProp) {
                    try {
                      mixpanel
                        .set_group('company', companyId)
                        .set_once('Company_name', data?.data?.name);
                    } catch {
                      console.error('Cannot set group.');
                    }
                  }
                });
              try {
                mixpanel.track('user_login', { event: 'User logged in' });
              } catch {
                console.error('Mixpanel error.');
              }
            });
          // TODO: To be removed after the next couple of iterations
          // if (response.status) {
          //   if (location && location.state && location.state.accountId) {
          //     setRedirectTo(
          //       `/salesforce/${location.state.accountId}/${location.state.dealId}`
          //     );
          //   } else if (location && location.state && location.state.from) {
          //     setRedirectTo(location.state.from);
          //   } else {
          //     console.log('redirect');
          //     setRedirectTo('/');
          //   }
          // }
        }
      })
      .catch((err) => {
        console.warn(err);
        if (
          err.data?.errorMessage?.includes('Incorrect username or password')
        ) {
          toast.error('Error: Incorrect username or password!');
          return;
        }
        toast.error('Error: Something went wrong!');
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Email field is Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) // * Pattern to test a valid email
        ) {
          errors.email = 'Entered email is not valid';
        }
        if (!values.password) {
          errors.password = 'Password field is Required';
        }
        return errors;
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form_container}>
          <label className={styles.form_field__container} htmlFor="email">
            <div className={styles.form_field}>
              <img alt="Email field" fetchpriority="high" src={emailIcon} />
              <Field
                className={styles.form_input}
                name="email"
                placeholder="Enter your e-mail"
                type="email"
                autoComplete="username"
              />
            </div>
            <ErrorMessage
              name="email"
              component="div"
              className={styles.form_error}
            />
          </label>

          <label className={styles.form_field__container} htmlFor="password">
            <div className={styles.form_field}>
              <img
                alt="Password field"
                fetchpriority="high"
                src={passwordIcon}
              />
              <Field
                innerRef={passwordRef}
                className={styles.form_input}
                name="password"
                placeholder="Enter password"
                type={passwordRef?.current?.type || 'password'}
                autoComplete="current-password"
              />
              <button
                type="button"
                className={styles.password_visibility}
                onClick={togglePasswordVisibility}
              >
                <img
                  ref={passwordVisibilityRef}
                  alt="View password"
                  fetchpriority="high"
                  src={viewIcon}
                />
              </button>
            </div>
            <ErrorMessage
              name="password"
              component="div"
              className={styles.form_error}
            />
          </label>

          <button
            className={styles.login_button}
            type="submit"
            disabled={isSubmitting}
          >
            <span className={isSubmitting ? styles.faded_text : ''}>Login</span>{' '}
            {isSubmitting && (
              <span className={styles.loader}>
                <DualRingLoader />
              </span>
            )}
          </button>
          {/* <button
            className={styles.login_button}
            type="submit"
            disabled={isSubmitting}
            onClick={() => handleSSOButton('google')}
          >
            <span className={isSubmitting ? styles.faded_text : ''}>
              Login with Google
            </span>{' '}
          </button> */}
          {process.env.REACT_APP_ENV === 'GITHUB' && (
            <button
              className={styles.login_button}
              type="submit"
              disabled={isSubmitting}
              onClick={() => handleSSOButton('okta')}
            >
              <span className={isSubmitting ? styles.faded_text : ''}>
                Login with Okta
              </span>{' '}
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default loginForm;
