import React from 'react';
import Sidebar from './Sidebar';

const SideBarLayout = ({
  items,
  activeSection,
  handleClick = () => {},
  withHeader,
  children,
  backgroundColor,
}) => {
  return (
    <div
      className="sidebar-layout"
      style={{ backgroundColor: backgroundColor ? backgroundColor : null }}
    >
      <Sidebar
        items={items}
        handleClick={handleClick}
        activeSection={activeSection}
        withHeader={withHeader}
      />
      <div className="main-content">{children}</div>
    </div>
  );
};

export default SideBarLayout;
