import React from 'react';

const agGridSelect = (props) => {
  return (
    <div className="agGridSelect">
      <div className="agGridContainer">
        <div className="ag-grid-value">{props.value}</div>
        {props.value && (
          <div className="ag-icon ag-icon-small-down ag-grid-arrow-icon"></div>
        )}
      </div>
    </div>
  );
};
export default agGridSelect;
