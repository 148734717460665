import React, { useState, useEffect } from 'react';

import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import useClickAwayListener from '../../hooks/useClickAwayListener';
import { useRef } from 'react';

const SearchField = (
  {
    options = [],
    renderOption,
    onSelect = () => {},
    onChange = () => {},
    placeholder,
    className = '',
  },
  ref
) => {
  const suggestionsContainerRef = useRef(null);
  const suggestionsBlur = useClickAwayListener(suggestionsContainerRef);
  const [showSuggestions, setShowSuggestions] = useState(false);
  useEffect(() => {
    // Hide dropdownTabMenu on blur
    if (suggestionsBlur) {
      setShowSuggestions(false);
    }
  }, [suggestionsBlur]);

  const handleChange = (e) => {
    setShowSuggestions(true);
    onChange(e);
  };

  return (
    <div className={`search-container ${className}`}>
      <span>
        <SearchSVG className="searchsvg" />
      </span>

      <input
        id="textfield"
        ref={ref}
        placeholder={placeholder}
        onChange={handleChange}
      />

      {showSuggestions &&
        options &&
        options.length > 0 &&
        ref.current.value !== '' && (
          <div ref={suggestionsContainerRef} className="top-container">
            <ul className="parent-container">
              {options.map((item, index) => {
                return (
                  <li
                    key={item?.id || index} // Passing an id is ideal
                    className="content-container"
                    onClick={() => onSelect(item)}
                  >
                    {renderOption ? (
                      renderOption(item)
                    ) : (
                      <div className="heading">{item}</div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
    </div>
  );
};

export default React.forwardRef(SearchField);
