import React, { useState, useEffect } from 'react';
import { ReactComponent as StarRatingSVG } from '../Icons/NewImage/star-rating.svg';
import { ReactComponent as StarRatingFilledSVG } from '../Icons/NewImage/star-rating-filled.svg';
import ReactTooltip from 'react-tooltip';
import { PULSE_SCORE_DROPDOWN } from '../Constant/ProductGapUseCases';

export const SOLUTION_SCORE = [
  {
    value: 5,
    label: <SuccessItemsRatingReadOnly rating={5} />,
  },
  {
    value: 4,
    label: <SuccessItemsRatingReadOnly rating={4} />,
  },
  {
    value: 3,
    label: <SuccessItemsRatingReadOnly rating={3} />,
  },
  {
    value: 2,
    label: <SuccessItemsRatingReadOnly rating={2} />,
  },
  {
    value: 1,
    label: (
      <div>
        <SuccessItemsRatingReadOnly rating={1} />
      </div>
    ),
  },
];

export function SuccessItemsRating(props) {
  const [rating, setRating] = useState(
    props.updateRating ? props.updateRating : 0
  );
  const [hover, setHover] = useState(
    props.updateRating ? props.updateRating : 0
  );

  const RATING_TEXT = {
    1: '0%',
    2: '25%',
    3: '50%',
    4: '75%',
    5: '100%',
  };

  const handleRating = (rating) => {
    setRating(rating);
    props.test(rating);
  };

  const getTooltipText = (index) => {
    return RATING_TEXT[index];
  };

  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <div key={`si-rating-${index}`}>
            <div>
              <div
                data-tip={getTooltipText(index)}
                onClick={() => handleRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
              >
                <div className="star-rating-popup">
                  {index <= (hover || rating) ? (
                    <StarRatingFilledSVG />
                  ) : (
                    <StarRatingSVG />
                  )}
                </div>
              </div>
              <ReactTooltip
                place="top"
                backgroundColor="#172B4D"
                effect="solid"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function SuccessItemsRatingReadOnly(props) {
  return (
    <div className="star-rating" style={{ display: 'flex' }}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <div key={`si-rating-${index}`} className="readonly-btn-parent">
            <div className="readonly-btn-size">
              {index <= props.rating && props.rating ? (
                <StarRatingFilledSVG width="12px" height="11px" />
              ) : (
                <StarRatingSVG width="12px" height="11px" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function ProductGapRating(props) {
  const [rating, setRating] = useState(props.rating ? props.rating : 0);
  const [hover, setHover] = useState(props.rating ? props.rating : 0);

  useEffect(() => {
    if (props.rating > 0) {
      setRating(props.rating);
    }
  }, [props.rating]);

  const RATING_TEXT = {
    1: 'Requested',
    2: 'Good to have',
    3: 'Must have',
    4: 'Critical',
    5: 'Deal Breaker',
  };

  const handleRating = (rating) => {
    setRating(rating);
    props.updateRating(rating);
  };

  const getTooltipText = (index) => {
    return RATING_TEXT[index];
  };

  const getRatingClassName = (index) => {
    let value = hover || rating;

    return `btn-size
            ${
              index <= value && value === 1
                ? 'box-one'
                : index <= value && value === 2
                ? 'box-two'
                : index <= value && value === 3
                ? 'box-three'
                : index <= value && value === 4
                ? 'box-four'
                : index <= value && value === 5
                ? 'box-five'
                : 'off'
            }`;
  };

  return (
    <div>
      <div
        className="star-rating flex-align-center"
        style={{ marginLeft: '15px' }}
      >
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <div key={`pg-rating-${index}`}>
              <div
                data-tip={getTooltipText(index)}
                onClick={() => handleRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
                className={getRatingClassName(index)}
              ></div>

              <ReactTooltip
                place="top"
                backgroundColor="#172B4D"
                effect="solid"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function ProductGapRatingReadOnly(props) {
  const getRatingClassName = (index) => {
    let value = props.rating;
    return `readonly-btn-size-pg
            ${
              index <= value && value === 1
                ? 'box-one'
                : index <= value && value === 2
                ? 'box-two'
                : index <= value && value === 3
                ? 'box-three'
                : index <= value && value === 4
                ? 'box-four'
                : index <= value && value === 5
                ? 'box-five'
                : 'off'
            }`;
  };

  return (
    <div className="star-rating box-border flex-align-center">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <div key={`pg-r-rating-${index}`} className="readonly-btn-parent-pg">
            <div className={getRatingClassName(index)} />
          </div>
        );
      })}
      <span className="pulse-score">{PULSE_SCORE_DROPDOWN[props.rating]}</span>
    </div>
  );
}
