import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import Item from '../../features/DealPresentationView/Item';
import DualRingLoader from '../DualRingLoader';
import { TASK_MANAGER_OPTIONS } from '../../Constant/FilterOperators';
import { useFetchTasksForTaskManagerQuery } from './tasksSlice';
import { createMarkup, isAdminOrManager, noData } from '../../Utils/common';
import emptyHyphen from '../../_helpers/empty-hyphen';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import { ReactComponent as DeleteSVG } from '../../Icons/NewImage/delete.svg';
import { ReactComponent as CheckSVG } from '../../Icons/NewImage/checkIcon.svg';
import { ReactComponent as ReopenSVG } from '../../Icons/Refresh.svg';
import { ReactComponent as RedirectSVG } from '../../Icons/NewImage/redirect.svg';
import { ReactComponent as AddSVG } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as CalendarViewSVG } from '../../Icons/NewImage/calender.svg';
import { ReactComponent as UserIcon } from '../../Icons/user.svg';
import { useHistory } from 'react-router-dom';
import { useFetchGetTeamsByManagerQuery } from '../../app/api/teamsSlice';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import { STATUS_COLOUR, DATE_RANGE_ALL, SELECT_STYLES } from './Constants';
import Button from '../Button';
import useDataPersistance from '../../hooks/useDataPersistance';

const TaskManager = ({
  handleAdd,
  handleDelete,
  handleUpdate,
  handleComplete,
  handleReopen,
  isReadOnly,
  isReopen = true,
}) => {
  const history = useHistory();
  const [taskStatus, setTaskStatus] = useState(TASK_MANAGER_OPTIONS[0]);
  const [dateRange, setDateRange] = useState(DATE_RANGE_ALL[5]);
  const [selectedUser, setSelectedUser] = useState();

  const { setStorageData, getStorageData } = useDataPersistance();

  const { data: adminManagerTeams } = useFetchGetTeamsByManagerQuery(
    undefined,
    {
      skip: !isAdminOrManager && pipelineAccessibility?.data !== 'user',

      selectFromResult: (response) => {
        const adminManagerTeams = useMemo(() => {
          return response?.data?.data?.map((team) => {
            return {
              value: team?.id,
              label: team?.teamName,
            };
          });
        }, [response?.data]);
        sessionStorage.setItem(
          'selectedTeams',
          JSON.stringify(adminManagerTeams)
        );
        return {
          data: adminManagerTeams,
        };
      },
    }
  );

  useEffect(() => {
    // * Set task manager filters from localStorage
    const filter = getStorageData('task_manager');
    if (filter?.status) {
      setTaskStatus(filter.status);
    }
    if (filter?.date_range) {
      setDateRange(filter.date_range);
    }
    if (filter?.user) {
      setSelectedUser(filter.user);
      return;
    }

    //Default user filter should be the logged in user
    if (!selectedUser) {
      const user = getStorageData('user');
      setSelectedUser({ label: user.name, value: user.userId });
    }
  }, []);

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  const { data: tasks, isFetching } = useFetchTasksForTaskManagerQuery(
    {
      status: taskStatus?.value,
      currentWeek: dateRange?.value === 'THIS_WEEK',
      deals: [],
      dateRange: dateRange?.value,
      subordinate_id: selectedUser?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        data: data ? Object.values(data?.entities) : [],
        isFetching,
      }),
    }
  );

  const { data: users, isFetching: fetchingUsers } =
    useFetchGetTeamsByManagerQuery(undefined, {
      skip: !isAdminOrManager(),
      selectFromResult: ({ data, isFetching }) => {
        const options = useMemo(() => {
          const allTeamUsers = [];
          if (data && data.status && data.data && data.data.length > 0) {
            data.data.forEach((team) => {
              team?.teamMembers?.map((teamMember) => {
                if (
                  teamMember?.active === true &&
                  !allTeamUsers?.some((user) => user?.value === teamMember?.id)
                ) {
                  allTeamUsers.push({
                    value: teamMember?.id,
                    label: teamMember?.name,
                  });
                }
              });
            });
          }
          return allTeamUsers;
        }, [data?.data]);

        return {
          data: options,
          isFetching,
        };
      },
    });

  const onFilterSelect = (option) => {
    if (!option) {
      setTaskStatus({ label: '' });
      return;
    }
    setTaskStatus(option);
    setStorageData('task_manager', { status: option });
  };

  const onDateRangeSelect = (option) => {
    if (!option) {
      setDateRange({ label: '' });
      return;
    }
    setDateRange(option);
    setStorageData('task_manager', { date_range: option });
  };

  const redirection = (account) =>
    history.push(`/dealDetails/${account.dealId}`);

  const onUserFilterSelect = (option) => {
    if (!option) {
      const user = getStorageData('user');
      setSelectedUser({ label: user.name, value: user.userId });
      return;
    }
    setSelectedUser(option);
    setStorageData('task_manager', { user: option });
  };

  return (
    <div className="task-manager-body">
      <section className="task-manager--header">
        <section className="task-manager--headingContainer">
          <h2 className="heading">Task Manager</h2>
          {!isReadOnly && (
            <Button icon onClick={handleAdd} type="text">
              <AddSVG />
              <span className="inner-text">Add new</span>
            </Button>
          )}
        </section>
        <div className="task-filter-group">
          <label
            className="task-filter flex-align-center"
            data-shrink="true"
            htmlFor="status"
            id="status-label"
          >
            <span>Status:</span>
            <Select
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isClearable
              backspaceRemovesValue
              className="select"
              styles={SELECT_STYLES}
              noOptionsMessage={() => noData()}
              value={taskStatus || TASK_MANAGER_OPTIONS[0]}
              options={TASK_MANAGER_OPTIONS}
              onChange={onFilterSelect}
              menuPlacement="auto"
            />
          </label>
          <label
            className="task-filter flex-align-center"
            data-shrink="true"
            htmlFor="date-range"
            id="date-range-label"
          >
            {/* Based on due date */}
            {/* <span> Date Range: </span> */}
            <CalendarViewSVG />
            <Select
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isClearable
              backspaceRemovesValue
              className="select"
              styles={SELECT_STYLES}
              noOptionsMessage={() => noData()}
              value={dateRange || DATE_RANGE_ALL[4]}
              options={DATE_RANGE_ALL}
              onChange={onDateRangeSelect}
              menuPlacement="auto"
            />
          </label>
          {isAdminOrManager() && (
            <label
              className="task-filter flex-align-center"
              data-shrink="true"
              htmlFor="status"
              id="status-label"
            >
              {/* <span>Users:</span> */}
              <UserIcon />
              <Select
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                isClearable
                isLoading={fetchingUsers}
                backspaceRemovesValue
                className="select"
                styles={SELECT_STYLES}
                noOptionsMessage={() => noData()}
                value={selectedUser}
                options={users ?? []}
                onChange={onUserFilterSelect}
                menuPlacement="auto"
              />
            </label>
          )}
        </div>
      </section>

      <div className="task-section">
        {isFetching && (
          <div className="flex-center py-1">
            <DualRingLoader dark />
          </div>
        )}
        {tasks &&
          tasks.length > 0 &&
          tasks.map((task) => {
            const markComplete = () => handleComplete(task);
            const updateTask = () => handleUpdate(task);
            const deleteTask = () => handleDelete(task);
            const reopenTask = () => handleReopen(task);
            const redirect = () => redirection(task);
            return (
              <Item
                key={task?.id}
                className="task_manager__item"
                description={
                  <span
                    dangerouslySetInnerHTML={createMarkup(task.description)}
                  />
                }
                footer={{
                  start: (
                    <div>
                      <div
                        className={`chip_filled_container chip_color__${
                          STATUS_COLOUR[task.status].color
                        }`}
                      >
                        <span className="chip_label">
                          {STATUS_COLOUR[task.status].label}
                        </span>
                      </div>
                      <div className="mt-8">{emptyHyphen(task.dealName)}</div>
                    </div>
                  ),
                  end: (
                    <div className="footer__end">
                      <div>{task.dueDate}</div>
                      <div className="mt-8 text-right">
                        {userMap[task.userId] && userMap[task.userId].name}
                      </div>
                    </div>
                  ),
                }}
                extraActions={
                  task.status !== 'COMPLETED'
                    ? [
                        {
                          key: 'redirect',
                          onClick: redirect,
                          icon: <RedirectSVG />,
                        },
                        {
                          key: 'mark-complete',
                          onClick: markComplete,
                          icon: <CheckSVG />,
                        },
                        {
                          key: 'update',
                          onClick: updateTask,
                          icon: <EditSVG />,
                        },
                        {
                          key: 'delete',
                          onClick: deleteTask,
                          icon: <DeleteSVG />,
                        },
                      ]
                    : isReopen && task.status === 'COMPLETED'
                    ? [
                        {
                          key: 'redirect',
                          onClick: redirect,
                          icon: <RedirectSVG />,
                        },
                        {
                          key: 'reopen',
                          onClick: reopenTask,
                          icon: <ReopenSVG />,
                          className: 'reopen-btn',
                        },
                      ]
                    : []
                }
                editable={!isReadOnly}
              />
            );
          })}
      </div>
    </div>
  );
};

export default TaskManager;
