import React, { useState, useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchActiveIntegrations } from '../_services/app.service';
import {
  fetchInactiveIntegrations,
  checkIsJiraIntegrated,
} from '../_services/users.service';
import Nav from './Nav';
import RouteSwitch from './RouteSwitch';
import Banner from '../common/Banner';
import useAxios from '../hooks/useAxios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import { useDispatch } from 'react-redux';
import { logoutAndResetState, mapRoute } from '../Utils/common';
import ErrorBoundary from '../Pipeline/components/ErrorBoundary';

const AppLayout_ = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const { response: activeIntegrations, ...activeIntegrationsService } =
    useAxios(axios);
  const { response: inactiveIntegrations, ...inactiveIntegrationsService } =
    useAxios(axios);
  const jiraIntegration = useAxios(axios);
  const [showBanner, setShowBanner] = useState(false);
  const [message, setMessage] = useState('');
  const [intervalId, setIntervalId] = useState('');
  const userDetails = localStorage.getItem('user');
  const profileData = userDetails && JSON.parse(userDetails);
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !profileData,
  });

  const getLocalStorageData = () => {
    const integrations = localStorage.getItem('active_integration_sync');
    return integrations ? JSON.parse(integrations) : [];
  };

  const logoutApp = (e) => {
    e.preventDefault();
    logoutAndResetState(dispatch, history);
  };

  const getActiveIntegrations = (data) => {
    if (data && data.length > 0) {
      localStorage.setItem('active_integration_sync', JSON.stringify(data));
      data.join(', ');
      const message = `Data Sync (${data}) in progress... Please wait...`;
      setShowBanner(true);
      setMessage(message);
      return true;
    } else {
      setShowBanner(false);
      clearInterval(intervalId);
      localStorage.setItem('active_integration_sync', JSON.stringify([]));
    }
  };

  const onGetActiveIntegrationsError = (error) => {
    clearInterval(intervalId);
    console.log(' ----- Error occured while checking active integrations ---');
    console.log(error);
  };

  const onGetInActiveIntegrationsError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    checkIsJiraIntegrated(jiraIntegration, (data) =>
      localStorage.setItem('jiraIntegration', data?.is_jira_connection)
    );
    const activeIntegrationsFromLS = getLocalStorageData();
    if (activeIntegrationsFromLS.length > 0) {
      fetchActiveIntegrations(
        activeIntegrationsService,
        getActiveIntegrations,
        onGetActiveIntegrationsError
      );

      const intervalId = setInterval(() => {
        fetchActiveIntegrations(
          activeIntegrationsService,
          getActiveIntegrations,
          onGetActiveIntegrationsError
        );
      }, 60000);
      setIntervalId(intervalId);
    }
    fetchInactiveIntegrations(
      inactiveIntegrationsService,
      null,
      onGetInActiveIntegrationsError
    );
  }, []);

  useEffect(() => {
    let response = [...inactiveIntegrations];
    if (
      !configFeatures?.data?.navPages?.dashboard ||
      !configFeatures?.data?.calendar
    ) {
      response = response?.filter((res) => {
        return res !== 'CALENDAR';
      });
    }
    if (!configFeatures?.data?.crm) {
      response = response.filter((res) => {
        return res !== 'CRM';
      });
    }
    if (
      profileData?.roles?.includes('sales') ||
      profileData?.roles?.includes('product')
    ) {
      response = inactiveIntegrations.filter((res) => {
        return res !== 'CRM' && res !== 'CALENDAR';
      });
    }
    if (response && response.length) {
      response.join(', ');
      const message = `Please connect your ${response}`;
      setShowBanner(true);
      setMessage(message);
    }
  }, [configFeatures?.data, inactiveIntegrations, profileData?.roles]);

  useEffect(() => {
    // Replacing default pathname
    if (location.pathname === '/' && configFeatures?.data?.landingPage) {
      let landingPage = configFeatures.data.landingPage;
      landingPage = profileData.roles.includes('sales')
        ? 'RequestForm'
        : landingPage === 'RequestForm' && !profileData.roles.includes('sales')
        ? 'Dashboard'
        : landingPage;
      landingPage = profileData.roles.includes('product')
        ? 'ProductGapUseCases'
        : landingPage;
      history.replace(mapRoute(landingPage));
    }
  }, [location.pathname, configFeatures?.data?.landingPage]);

  return (
    <Route>
      <Helmet titleTemplate="%s | Preskale" defaultTitle="Preskale">
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://preskale.com" />
      </Helmet>

      <Nav appLogout={logoutApp} />

      <Banner showBanner={showBanner} message={message} />
      <main
        className={`layout-container ${showBanner ? 'layout--has-banner' : ''}`}
      >
        <ErrorBoundary>
          <RouteSwitch />
        </ErrorBoundary>
      </main>
    </Route>
  );
};

export default AppLayout_;
