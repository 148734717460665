import React from 'react';
import badges from '../Icons/badges.svg';
import g2Reviews from '../common/images/g2_reviews.png';
import LoginForm from './loginForm';
import PreskaleLogo from '../Icons/logo_full.svg';
import styles from '../styles/pages/login.module.scss';

export default function Login() {
  return (
    <section
      className={`${styles.container} ${
        window.location !== window.parent.location
          ? styles.iframe_container
          : ''
      }`}
    >
      <section className={styles.hero__container}>
        <section className={styles.heading_container}>
          <h1 className={styles.heading}>
            Hi There!
            <br /> Good day to you{' '}
          </h1>
          <h6 className={styles.sub_heading}>G2 Spring Report 2023</h6>
        </section>
        <section className={styles.badges_container}>
          <img alt="G2 recognitions" fetchpriority="high" src={badges} />
        </section>
        <section className={styles.read_reviews}>
          <a
            href="https://www.g2.com/products/preskale-inc-preskale/reviews?utm_source=review-widget"
            title="Read reviews of PreSkale on G2"
          >
            <img
              alt="Read PreSkale reviews on G2"
              fetchpriority="high"
              src={g2Reviews}
            />
          </a>
        </section>
      </section>
      <section className={styles.form_container}>
        <section className={styles.main_container}>
          <section className={styles.logo_container}>
            <img alt="Preskale Logo" fetchpriority="high" src={PreskaleLogo} />
          </section>
          <section className={styles.content_container}>
            <LoginForm />
            <hr className={styles.divider} />
            <section className={styles.help_text}>
              <p className={styles.text}>Do you need help with anything?</p>
              <a
                href="http://preskale.freshdesk.com"
                target="_blank"
                className={styles.btn_default}
              >
                Contact Us
              </a>
            </section>
          </section>
        </section>
        <p className={styles.secondary_text}>© 2023 All Rights Reserved</p>
      </section>
    </section>
  );
}
