import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Flex, Input } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { TEAM_ROLES } from '../constants';
import AgGridTooltip from '../../common/AgGridTooltip';
import PreskaleMultiSelect from '../PreskaleMultiSelect';
import RoleTag from './RoleTag';
import ManageTeamMembers from './ManageTeamMembers';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as CollabratorSVG } from '../../Icons/NewImage/collabrator.svg';

const TeamMembers = ({ teamData }) => {
  const filterTextBoxRef = useRef(null);
  const gridRef = useRef(null);
  const [selectedRole, setSelectedRole] = useState(TEAM_ROLES);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showAddTeamMembersDrawer, setShowAddTeamMembersDrawer] =
    useState(false);
  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Role',
      field: 'roles',
      cellRenderer: (props) => <RoleTag role={props?.value} />,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 75,
      suppressMovable: true,
    }),
    []
  );

  useEffect(() => {
    let rowData = teamData?.teamMembers;
    const roles =
      selectedRole && selectedRole.map((data) => data?.value?.toLowerCase());

    if (rowData) {
      rowData = rowData.filter((data) => {
        if (data?.roles && roles.includes(data?.roles[0]?.toLowerCase())) {
          return data;
        }
      });
    }
    setTeamMembers(rowData);
  }, [teamData, selectedRole]);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(filterTextBoxRef?.current?.input?.value);
  }, []);

  const onFilterChange = (e) => {
    setSelectedRole(e);
  };

  return (
    <>
      <Flex align="center" className="width-full">
        <Input
          prefix={<SearchSVG />}
          ref={filterTextBoxRef}
          size="small"
          className="search-container width-35"
          placeholder="Search"
          onChange={onFilterTextBoxChanged}
        />
        <Flex align="center" className="mr-10 ml-auto">
          <Flex gap={5} className="mr-10">
            <Flex align="center" component="label">
              Role :{' '}
            </Flex>
            <PreskaleMultiSelect
              options={TEAM_ROLES}
              placeholder="Select options"
              maxTagCount={1}
              value={selectedRole}
              onChange={(option) => onFilterChange(option, 'role')}
            />
          </Flex>
          <Button
            className="flex-align-center"
            type="link"
            onClick={() => setShowAddTeamMembersDrawer(true)}
            icon={<CollabratorSVG width={16} height={16} />}
          >
            Manage Team Members
          </Button>
        </Flex>
      </Flex>
      <div className="height-full">
        <div className="ag-theme-alpine team-members-table">
          <AgGridReact
            ref={gridRef}
            rowData={teamMembers}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            enableCellTextSelection={true}
          />
        </div>
      </div>
      {showAddTeamMembersDrawer && (
        <ManageTeamMembers
          showModal={showAddTeamMembersDrawer}
          setShowAddTeamMembersDrawer={setShowAddTeamMembersDrawer}
          teamInfo={teamData}
        />
      )}
    </>
  );
};

export default TeamMembers;
