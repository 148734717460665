export const NavigatorTitles = [
  {
    title: 'Personal Information',
    subTitles: [
      {
        label: 'My Profile',
        id: 'my_profile',
      },
      {
        label: 'My Notification',
        id: 'my_notification',
      },
      {
        label: 'Reset Password',
        id: 'reset_password',
      },
    ],
  },
  {
    title: 'Calendar Settings',
    subTitles: [
      {
        label: 'Connect My Calendar',
        id: 'connect_calendar',
      },
      {
        label: 'Outcome Automation',
        id: 'outcome_automation',
      },
    ],
  },
  {
    title: 'Integrations',
    restrict: true,
    subTitles: [
      {
        label: 'CRM Integration',
        id: 'crm_integration',
        restrict_crm: 'freshworks',
      },
      {
        label: 'Product Integration',
        id: 'product_integration',
      },
    ],
  },
];

export const subTitles = [
  {
    label: 'My Profile',
    id: 'my_profile',
  },
  {
    label: 'My Notification',
    id: 'my_notification',
  },
  {
    label: 'Reset Password',
    id: 'reset_password',
  },
  {
    label: 'Connect My Calendar',
    id: 'connect_calendar',
  },
  {
    label: 'Outcome Automation',
    id: 'outcome_automation',
  },
  {
    label: 'CRM Integration',
    id: 'crm_integration',
    restrict_crm: 'freshworks',
  },
  {
    label: 'Product Integration',
    id: 'product_integration',
  },
];
export const SettingsNavigation = [
  {
    label: 'Team Management',
    id: 'team_management',
    reference: 'teams_card',
  },
  {
    label: 'Account Setup',
    id: 'configurations',
    reference: 'configurations_card',
  },
];

export default NavigatorTitles;
