import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import { generateTags, saveLastArgsToState } from '../../app/utils/apiUtils';
import { logout } from '../../Authorize/authSlice';

const notesSliceInitialState = {
  all: {},
  accounts: {},
  criteria: {},
};

const notesSlice = createSlice({
  name: 'notes',
  initialState: notesSliceInitialState,
  reducers: {
    saveLastArgs(state, action) {
      state[action.payload.type] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = notesSliceInitialState;
      return state;
    });
  },
});

export const { saveLastArgs } = notesSlice.actions;

const noteAdapter = createEntityAdapter();
const initialState = noteAdapter.getInitialState();

export const updateNotes = async (
  { body },
  { dispatch, queryFulfilled, getState }
) => {
  const cache = getState();
  let patchResultAllNotes;
  let patchResultAccountNotes;
  const patchDraft = (draft) => {
    if (draft.entities[body?.id]) {
      Object.assign(draft.entities[body?.id], body);
    }
  };
  patchResultAllNotes = dispatch(
    extendedNoteSlice.util.updateQueryData(
      'fetchAllNotes',
      cache.notes.all,
      patchDraft
    )
  );
  patchResultAccountNotes = dispatch(
    extendedNoteSlice.util.updateQueryData(
      'fetchNotesByAccount',
      cache.notes.accounts,
      patchDraft
    )
  );
  try {
    await queryFulfilled;
  } catch {
    patchResultAllNotes?.undo();
    patchResultAccountNotes?.undo();
  }
};

export const extendedNoteSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllNotes: builder.query({
      query: (deals) => {
        let queryString = '';
        deals &&
          deals.forEach((dealId) => {
            if (dealId) {
              queryString = queryString + dealId + ',';
            }
          });
        return `${URLS_BUSINESS_SERVICE.notes.getNotes}?deal_id=${queryString}`;
      },
      transformResponse: (responseData) => {
        return noteAdapter.setAll(initialState, responseData);
      },
      onCacheEntryAdded: saveLastArgsToState(saveLastArgs, 'all'),
      providesTags: generateTags('Notes', 'Notes_All'),
    }),
    fetchNotesByAccount: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.notes.getByAccount}?deal_id=${dealId}`;
      },
      transformResponse: (responseData) => {
        return noteAdapter.setAll(initialState, responseData);
      },
      onCacheEntryAdded: saveLastArgsToState(saveLastArgs, 'accounts'),
      providesTags: (_, __, arg) => [
        { type: 'Notes', id: 'Notes_Account' },
        { type: 'Notes', id: arg?.dealId },
      ],
    }),
    fetchNotesByCriteria: builder.query({
      query: ({ dealId, criteriaId }) => {
        return `${URLS_BUSINESS_SERVICE.notes.getByAccount}?deal_id=${dealId}&criteria_id=${criteriaId}`;
      },
      transformResponse: (responseData) => {
        return noteAdapter.setAll(initialState, responseData);
      },
      onCacheEntryAdded: saveLastArgsToState(saveLastArgs, 'criteriaArgs'),
      providesTags: generateTags('Notes', 'Notes_Criteria'),
    }),

    addNote: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.notes.add,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Notes'],
    }),
    updateNote: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.notes.update,
        method: 'POST',
        body,
      }),
      onQueryStarted: updateNotes,
    }),
    deleteNote: builder.query({
      query: ({ noteId, dealId }) =>
        `${URLS_BUSINESS_SERVICE.notes.delete}?id=${noteId}&deal_id=${dealId}`,
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(extendedNoteSlice.util.invalidateTags(['Notes']));
      },
    }),
  }),
});

export const {
  useFetchAllNotesQuery,
  useFetchNotesByAccountQuery,
  useFetchNotesByCriteriaQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useLazyDeleteNoteQuery,
} = extendedNoteSlice;

export default notesSlice.reducer;
