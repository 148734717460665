import React from 'react';
import { ReactComponent as WarningSVG } from '../../Icons/InfoIcon.svg';
import IntegrationCard from './IntegrationCard';

const CrmIntegrationCardContainer = ({
  integration,
  isPicklist,
  adminConnections,
  handleDisconnect,
  primaryFieldExisting,
}) => {
  return (
    <div className="integrations-card__container">
      <IntegrationCard
        integration={integration}
        isPicklist={isPicklist}
        adminConnections={adminConnections}
        handleDisconnect={handleDisconnect}
      />
      {integration?.name === 'Salesforce' &&
      !primaryFieldExisting &&
      (integration?.connection?.token?.acessToken ||
        adminConnections?.token?.acessToken) ? (
        <div className="icon-container">
          <div className="icon">
            <WarningSVG /> <span className="icon-note">Note</span>
          </div>
          <ol className="warning-content">
            <li>
              Please verify the Primary User Field Mapping and Opportunity Field
              Mapping information.
            </li>
            <li>
              {' '}
              Once the Fields are verified, Select the{' '}
              <span className="text-highlight">Confirm Mapping Details.</span>
            </li>
          </ol>
          <div className="warning-text">
            Need assistance setting this up? Please reach out to us at{' '}
            <span className="email-highlight">support@preskale.com.</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CrmIntegrationCardContainer;
