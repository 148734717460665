import React from 'react';
import { Alert, Flex, Typography } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

const { Text, Paragraph } = Typography;

const UnAssignedDealInfo = () => {
  return (
    <Alert
      type="info"
      rootClassName="p-10 b-4"
      description={
        <Flex>
          <InfoCircleTwoTone className="overflow-visible align-start pt-5" />
          <Paragraph className="ml-5">
            <Text strong>Note : </Text>
            <Text strong className="ml-2">
              Primary User Field (PUF){' '}
            </Text>{' '}
            <Text>
              refers to Presales/Technical Sales Owner field within your CRM.
              Once a selection is made, deals associated to you based on the
              selected field will be populated in the pipeline view. Only a
              picklist or lookup field can be associated to the PUF and the
              associated field can’t be changed after selection.
            </Text>
          </Paragraph>
        </Flex>
      }
    />
  );
};
export default UnAssignedDealInfo;
