import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';

const DateField = ({ value, onChange }) => {
  const handleChange = (date) => {
    if (typeof onChange === 'function') {
      onChange(date ? format(date, 'yyyy-MM-dd') : date);
    }
  };

  return (
    <ReactDatePicker
      placeholderText="YYYY-MM-DD"
      className="agGrid-DatePicker"
      portalId="root"
      popperClassName="ag-custom-component-popup"
      dateFormat="yyyy-MM-dd"
      selected={value ? new Date(value) : value}
      onChange={handleChange}
    />
  );
};

export default DateField;
