import React from 'react';

const Switch = ({ enabled = false, onChange = () => {}, round = false }) => {
  return (
    <label className="react-switch">
      <input type="checkbox" checked={enabled} onChange={onChange} />
      <span className={`react-slider ${round ? 'round' : ''}`}></span>
    </label>
  );
};

export default Switch;
