import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Option,
  colourStyles,
} from '../../Constant/MultiSelectStylesAndComponents';
import { MultiSelect } from '../../common/MultiSelect';
import CreatableSelect from 'react-select/creatable';
import { noData } from '../../Utils/common';

export const CustomMultiSelectCellEditor = forwardRef(
  ({ value, isMultiselect, values }, ref) => {
    const [selectedValues, setSelectedValues] = useState(value || []);

    useEffect(() => {
      setSelectedValues(value || []);
    }, [value]);

    const handleValueChange = (selectedOptions) => {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      setSelectedValues(selectedValues);
    };

    useImperativeHandle(ref, () => ({
      getValue() {
        return selectedValues;
      },
    }));

    const options = values
      ? values.map((value) => ({ value, label: value }))
      : [];

    const customStyles = {
      control: (provided) => ({
        ...provided,
        width: 250,
      }),
      ...colourStyles,
    };

    return isMultiselect ? (
      <MultiSelect
        options={options}
        value={selectedValues.map((value) => ({ value, label: value }))}
        onChange={handleValueChange}
        closeMenuOnSelect={false}
        styles={customStyles}
        components={{ Option }}
      />
    ) : (
      //UserDefinedDropdown field
      <CreatableSelect
        components={{
          IndicatorSeparator: () => null,
        }}
        isClearable
        className="form_select"
        noOptionsMessage={() => noData()}
        value={selectedValues.map((value) => ({ value, label: value }))}
        options={options}
        onChange={handleValueChange}
        isMulti
        menuPlacement="auto"
        closeMenuOnSelect={false}
      />
    );
  }
);
