import { createSlice } from '@reduxjs/toolkit';
import authenticationService from '../_services/authentication.service';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) ?? {},
  idToken: localStorage.getItem('idToken') ?? null,
  refreshToken: localStorage.getItem('refreshToken') ?? null,
  authenticated: localStorage.getItem('authenticated') ?? null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { idToken, refreshToken, companyId } = action.payload;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.companyId = companyId;
    },
    setUser: (state, action) => {
      const user = action.payload;
      state.user = user;
      state.authenticated = user?.authenticated;
    },
    logout: (state, action) => {
      console.log('logged out');
      state.user = {};
      state.idToken = null;
      state.refreshToken = null;
      state.authenticated = null;
      state.companyId = null;
      localStorage.clear();
      sessionStorage.clear();
      authenticationService?.resetUserDetails();
    },
  },
});

export const { setCredentials, setUser, logout } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.idToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectAuthenticationState = (state) => state.auth.authenticated;
