import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Card, Col, Flex, Layout, Menu, Row, Typography, Spin } from 'antd';
import {
  UserOutlined,
  TeamOutlined,
  CalendarOutlined,
  HddOutlined,
} from '@ant-design/icons';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import ConfigureDefaultPipeline from './PipelinePreference/ConfigureDefaultPipeline';
import { ReactComponent as KanbanOutlineSVG } from '../Icons/kanban-outline.svg';

const ManageUsers = lazy(() => import('./UserManagement/ManageUsers'));
const Outcome = lazy(() => import('./OutcomeManagement/Outcome'));
const TeamsManagement = lazy(() => import('./TeamsManagement/TeamsManagement'));
const PipelinePreference = lazy(() =>
  import('./PipelinePreference/PipelinePreference')
);

const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;

const Index = () => {
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [activePage, setActivePage] = useState('');
  const scrollRef = useRef({});
  const [showConfigurePipelineDrawer, setShowConfigurePipelineDrawer] =
    useState(false);
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const getComponent = () => {
    switch (activePage) {
      case 'users':
        return (
          <Suspense fallback={<Spin fullscreen />}>
            <ManageUsers setActivePage={setActivePage} />
          </Suspense>
        );
      case 'outcome_automation':
        return (
          <Suspense fallback={<Spin fullscreen />}>
            <Outcome setActivePage={setActivePage} />
          </Suspense>
        );
      case 'teams':
        return (
          <Suspense fallback={<Spin fullscreen />}>
            <TeamsManagement setActivePage={setActivePage} />
          </Suspense>
        );
      case 'pipeline_preferences':
        return (
          <Suspense fallback={<Spin fullscreen />}>
            <PipelinePreference
              setActivePage={setActivePage}
              setShowConfigurePipelineDrawer={setShowConfigurePipelineDrawer}
            />
          </Suspense>
        );
    }
  };
  useEffect(() => {
    if (searchParams.get('activePage')) {
      setActivePage(searchParams.get('activePage'));
    }
  }, []);

  const onCardClick = (page) => {
    setActivePage(activePage === page ? '' : page);
    history.replace(`?activePage=${page}`);
  };

  const label = (title, description, key) => {
    return (
      <Flex component="a" href={key}>
        <Flex vertical className="no-scroll">
          <Flex component="h5" className="text-lg text-weight-semi-bold">
            {title}{' '}
          </Flex>
          <Text className="text-sm white-space-normal text-left">
            {description}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const sideNavBarItems = [
    {
      key: 'teams',
      icon: (
        <TeamOutlined className="preskale-antd-icon-font-20 height-full pt-10 flex-align-end" />
      ),
      label: label('Teams', 'Manage all Users, Roles and Teams', '#teams'),
    },
    {
      key: 'configurations',
      icon: (
        <HddOutlined className="preskale-antd-icon-font-20 height-full pt-10 flex-align-end" />
      ),
      label: label(
        'Account Setup',
        'Map out and manage your complete presales structure',
        '#configurations'
      ),
    },
  ];

  const SettingsCard = [
    {
      title: 'Teams',
      key: 'teams',
      cards: [
        {
          title: 'Users',
          description:
            'Add Users, Edit their information, and deactivate from your account.',
          key: 'users',
          icon: (
            <UserOutlined className="preskale-antd-icon-font-20 pr-12 pt-6 height-full" />
          ),
        },
        {
          title: 'Teams',
          description:
            'Create a team to configure their accessibility to CRM pipelines, and accessibility.',
          key: 'teams',
          icon: (
            <TeamOutlined className="preskale-antd-icon-font-20 pr-12 pt-6 height-full" />
          ),
        },
      ],
    },
    {
      title: 'Account Setup',
      key: 'configurations',
      cards: [
        {
          title: 'Pipeline and Preferences',
          description:
            'Create Presales Pipeline that suits your technical sales process and manage your CRM stages.',
          key: 'pipeline_preferences',
          icon: (
            <KanbanOutlineSVG
              height={20}
              width={30}
              className="mr-12 mt-8 overflow-visible"
            />
          ),
        },
        {
          title: 'Meeting Outcomes',
          description:
            'Setup Meeting outcomes that can best represent the time your team spends on meetings.',
          key: 'outcome_automation',
          icon: (
            <CalendarOutlined className="preskale-antd-icon-font-20 pr-12 pt-6 height-full" />
          ),
        },
      ],
    },
  ];
  return (
    <>
      {activePage && activePage.length ? (
        getComponent()
      ) : (
        <Layout className="admin-settings-layout">
          <Header className="admin-settings-header">
            <Title
              level={5}
              className="flex-align-center height-full text-dark"
            >
              Admin Settings
            </Title>
          </Header>
          <Layout>
            <Sider className="admin-settings-sider">
              <Menu
                rootClassName="preskale-sidebar"
                mode="inline"
                defaultSelectedKeys={['teams']}
                items={sideNavBarItems}
              />
            </Sider>
            <Content className="admin-settings-content">
              {SettingsCard.map((category) => (
                <div key={category.key}>
                  <Title
                    level={3}
                    ref={(scroll) => (scrollRef.current[category.key] = scroll)}
                    id={category.key}
                    className="mb-24 flex-align-center flex-start-direction text-dark"
                  >
                    {category.title}
                  </Title>
                  <Row className="mb-24" gutter={32}>
                    {category.cards.map((card) => (
                      <Col span={8} key={card.key}>
                        <Card
                          onClick={() => onCardClick(card?.key)}
                          className="settings-card"
                        >
                          <Flex>
                            {card.icon}
                            <Flex vertical>
                              <Text className="text-lg flex pb-5" strong>
                                {card.title}
                              </Text>
                              <Text className="text-sm flex text-left">
                                {card.description}
                              </Text>
                            </Flex>
                          </Flex>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </Content>
          </Layout>
        </Layout>
      )}
      <ConfigureDefaultPipeline
        showDrawer={showConfigurePipelineDrawer}
        setShowConfigurePipelineModal={setShowConfigurePipelineDrawer}
        stageView={configFeatures?.data?.stageView}
      />
    </>
  );
};

export default Index;
