import React from 'react';
import Logo from '../Icons/PreskaleLogo.svg';

import CreateCompany from '../Company/CreateCompany';

const PublicLayout = (props) => {
  return (
    <div className="login-container">
      <div className="welcome-bord-wrapper">
        <div className="logo-wrapper">
          <a href="https://www.preskale.com/" target="_blank">
            <img src={Logo} alt="Preskale logo" className="app-logo" />
          </a>
        </div>

        <div className="onboard-content">
          <h3 className="heading_level1"> Welcome onboard! </h3>
          <p className="content-text">
            With PreSkale, presales teams can deliver exceptional value, ease
            product adoption and improve customer-driven revenue.
          </p>
        </div>

        <div className="contact-us-content">
          <p className="content-text">
            Are you looking for an PreSkale support? Have any product questions?{' '}
          </p>
          <a
            href="http://preskale.freshdesk.com"
            target="_blank"
            className="btn"
          >
            {' '}
            Contact Us{' '}
          </a>
        </div>
      </div>

      <div className="actionable-form-wrapper">
        {props?.location.pathname === '/createcompany' && <CreateCompany />}
      </div>
    </div>
  );
};

export default PublicLayout;
