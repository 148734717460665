import { Flex, Form, Select } from 'antd';
import Typography from 'antd/es/typography/Typography';
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { renderDetails } from '../tasks/utils';

const { Text } = Typography;

const AddTaskSideContent = ({
  userMap,
  taskAccount,
  allAccounts,
  accountSummary,
  loggedInUser,
  form,
  initialValues,
  action,
  dealDetails,
}) => {
  useEffect(() => {
    if (initialValues && action === 'update') {
      form.setFieldsValue({
        'task-account': initialValues?.accountId,
        'task-opportunity': initialValues?.dealId,
        'task-owner': initialValues?.userId,
      });
    }

    if (action == 'add') {
      form.setFieldsValue({
        'task-owner': loggedInUser?.userId ?? null,
      });
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (dealDetails) {
      form.setFieldsValue({
        'task-account': dealDetails?.accountId,
        'task-opportunity': dealDetails?.dealId,
      });
    }
  }, [dealDetails]);

  const renderFormItems = () => (
    <>
      <Form.Item
        label={<Text strong>Account</Text>}
        name="task-account"
        rules={[
          {
            required: true,
            message: 'Account field is Required',
          },
        ]}
      >
        <Select
          showSearch
          options={allAccounts}
          placeholder="Select an account"
          size="large"
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item
        label={<Text strong>Opportunity</Text>}
        name="task-opportunity"
        rules={[
          {
            required: true,
            message: 'Opportunity field is Required',
          },
        ]}
      >
        <Select
          options={
            taskAccount
              ? accountSummary?.filter((data) => data.accountId === taskAccount)
              : accountSummary
          }
          placeholder="Select an opportunity"
          size="large"
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
    </>
  );

  return (
    <>
      {action === 'add' ? (
        <Form.Item
          label={<Text strong>Task Owner</Text>}
          name="task-owner"
          className="mb-0"
          rules={[
            {
              required: true,
              message: 'Task Owner field Required',
            },
          ]}
        >
          <Select
            options={userMap}
            placeholder="Select an owner"
            size="large"
            optionFilterProp="label"
            showSearch
          />
        </Form.Item>
      ) : (
        <Flex vertical>
          <Text strong type="secondary">
            Task Owner
          </Text>
          <Text strong>
            {
              userMap.find((data) => data.value === initialValues?.userId)
                ?.label
            }
          </Text>
        </Flex>
      )}
      {action === 'update' || dealDetails
        ? renderDetails(
            allAccounts,
            accountSummary,
            dealDetails ? dealDetails.accountId : initialValues?.accountId,
            dealDetails ? dealDetails.dealId : initialValues?.dealId
          )
        : renderFormItems()}
      <Flex vertical>
        <Text strong type="secondary">
          Task Creator
        </Text>
        <Text strong>
          {' '}
          {initialValues
            ? userMap?.filter(
                (data) => data.value === initialValues?.createdBy
              )?.[0]?.label
            : loggedInUser?.name}
        </Text>
      </Flex>
      <Flex vertical>
        <Text strong type="secondary">
          Task Created On
        </Text>
        <Text strong>
          {initialValues
            ? format(new Date(initialValues?.createdDate), 'dd/MM/yyyy')
            : format(new Date(), 'dd/MM/yyyy')}
        </Text>
      </Flex>
    </>
  );
};

export default AddTaskSideContent;
