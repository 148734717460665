import { Button, Drawer, Flex, Input, Typography } from 'antd';
import React, { useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ReactComponent as SearchSVG } from '../Icons/NewImage/search.svg';
import { ReactComponent as FilterSVG } from '../Icons/NewImage/filter.svg';
import AssignPresalesUserTable from './AssignPresalesUserTable';
import Filter from './Filter';
import useDebounce from '../hooks/useDebounce';

const { Text } = Typography;

const AssignPresalesUser = ({ openDrawer, handleCloseDrawer }) => {
  const [openFilter, setOpenFilter] = useState(false);

  const [filter, setFilter] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchTerm = useDebounce(searchQuery, 1000);
  const handleFilterToggle = () => {
    setOpenFilter(!openFilter);
  };

  const onSubmit = (filterJson) => {
    setFilter(filterJson);
  };

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={handleCloseDrawer}
      closable={false}
      destroyOnClose
      title={'Assign Presales to Unassigned Opportunities'}
      styles={{
        header: {
          borderBottom: '2px solid #d0e2f5',
          paddingLeft: '20px',
          paddingBottom: '6px',
        },
        wrapper: {
          width: '75%',
        },
        body: {
          padding: 0,
          overflow: 'hidden',
        },
      }}
      extra={
        <Flex gap={4} className="mr-10 ml-auto width-full" justify="end">
          <Input
            prefix={<SearchSVG />}
            size="small"
            placeholder="Search Opportunity"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <Button onClick={handleFilterToggle} className="height-full b-4">
            <Flex align="center" gap={4}>
              <FilterSVG height={14} width={14} />
              <Text> Filters</Text>
            </Flex>
          </Button>
          <Button icon={<CloseCircleOutlined />} onClick={handleCloseDrawer}>
            Close
          </Button>
        </Flex>
      }
    >
      <AssignPresalesUserTable
        filter={filter}
        searchTerm={debouncedSearchTerm}
      />
      {openFilter && (
        <Filter
          openDrawer={openFilter}
          handleClose={() => setOpenFilter(false)}
          key="un-assigned-deals-filter"
          onSubmit={onSubmit}
        />
      )}
    </Drawer>
  );
};

export default AssignPresalesUser;
