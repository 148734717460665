import React from 'react';
import ReactModal from 'react-modal';
import { useLazyDeleteProductGapQuery } from './productGapSlice';
import Button from '../../common/Button';

const DeleteProductGap = ({
  showModal,
  handleCloseModal,
  productGap,
  handleProductGapsAfterDeleting,
}) => {
  const [deleteItem] = useLazyDeleteProductGapQuery();

  const handleDeleteProductGap = () => {
    deleteItem({
      id: productGap?.id,
      dealId: productGap?.dealId,
      accountId: productGap?.accountId,
    })
      .unwrap()
      .then(() => {
        handleProductGapsAfterDeleting(productGap);
      });
  };

  return (
    <ReactModal
      isOpen={showModal}
      ariaHideApp={false}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
    >
      <div className="modal-header">
        <h4 className="modal-title-pg">Delete Confirmation</h4>
      </div>

      <div className="modal-body">Are you sure you want to delete?</div>

      <div className="modal-footer flex-justify-end">
        <Button type="secondary" onClick={() => handleCloseModal('delete')}>
          {' '}
          Cancel
        </Button>
        <Button type="danger" onClick={handleDeleteProductGap}>
          {' '}
          Delete{' '}
        </Button>
      </div>
    </ReactModal>
  );
};

export default DeleteProductGap;
