import React, { useState, useEffect } from 'react';
import ProductGapUseCases from '../features/ProductGapUseCases';
import SolutionUseCases from '../features/SolutionUseCases';
import {
  isAdminOrManager as findIsAdminOrManager,
  isProductUser as findIsProductUser,
} from '../Utils/common';
import { useParams, useHistory } from 'react-router-dom';
import PageLayout from '../Layout/PageLayout';
import useDataPersistance from '../hooks/useDataPersistance';
import ManageModuleFeatures from '../features/ModulesManager/ManageModuleFeatures';
import ManageProductIntegrations from '../features/Integrations/ManageProductIntegrations';
import mixpanel from 'mixpanel-browser';
import LibraryFilter from '../common/filters/library/LibraryFilter';
import ModulesFeaturesFilter from '../common/filters/modulesAndFeatures/ModulesFeaturesFilter';
import { useGetSolutionUsecasesMutation } from '../features/Solution/solutionSlice';
import { useGetProductGapUseCasesMutation } from '../features/ProductGap/productGapSlice';
import { handleUseCasesSuccess, processFilter } from '../Utils/libraryUtils';
import IssueManager from '../features/IssueManager';

const PGManager = ({ companyConfig }) => {
  const history = useHistory();
  let { tab } = useParams();
  const [active, setActive] = useState(0);
  const { setStorageData } = useDataPersistance();

  const handleTabClick = (index) => () => {
    if (index === 0) {
      setStorageData('pgManager', 'productgaps', true);
      history.push('/libraries/productgaps');
      return;
    }
    if (index === 1) {
      setStorageData('pgManager', 'solutions', true);
      history.push('/libraries/solutions');
      return;
    }
    if (index === 2) {
      try {
        mixpanel.track('manage_modules_accessed', {
          event: 'Manage modules and features accessed',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      setStorageData('pgManager', 'manage', true);
      history.push('/libraries/manage');
      return;
    }
    if (index === 3) {
      setStorageData('pgManager', 'manage', true);
      history.push('/libraries/integrations');
      return;
    }
    if (index === 4) {
      setStorageData('pgManager', 'manage', true);
      history.push('/libraries/issues');
      return;
    }
  };
  const isAdminOrManager = findIsAdminOrManager();
  const isProductUser = findIsProductUser();
  useEffect(() => {
    if (tab === 'productgaps') {
      setActive(0);
    }
    if (tab === 'solutions') {
      setActive(1);
    }
    if (tab === 'manage') {
      setActive(2);
    }
    if (tab === 'integrations') {
      setActive(3);
    }
    if (tab === 'issues') {
      setActive(4);
    }
  }, [tab]);

  const [opportunitiesForSolutions, setOpportunitiesForSolutions] = useState(); //Iterate the use-cases and store the deals
  const [opportunitiesForPG, setOpportunitiesForPG] = useState(); //Iterate the use-cases and store the deals

  const [
    getSolutionUsecase,
    { data: solutionUseCases, isLoading: solutionUseCasesLoading },
  ] = useGetSolutionUsecasesMutation();

  const [
    getProductGapUseCases,
    { data: productGapUseCases, isLoading: productGapUseCasesLoading },
  ] = useGetProductGapUseCasesMutation();

  const submitMethods = {
    solutions: (filters, handleFinal) => {
      getSolutionUsecase(processFilter('solutions', filters))
        .unwrap()
        .then(handleUseCasesSuccess('solutions', setOpportunitiesForSolutions))
        .finally(() => {
          if (handleFinal) {
            handleFinal();
          }
        });
    },
    productgaps: (filters, handleFinal) => {
      getProductGapUseCases(processFilter('productgaps', filters))
        .unwrap()
        .then(handleUseCasesSuccess('productgaps', setOpportunitiesForPG))
        .finally(() => {
          if (handleFinal) {
            handleFinal();
          }
        });
    },
  };

  return (
    <PageLayout
      headerMainContent={
        <>
          <button
            onClick={handleTabClick(0)}
            className={`tab-button ${active === 0 ? 'tab-active' : ''}`}
          >
            Product-Gap Library
          </button>{' '}
          {companyConfig?.data?.featureList
            ?.is_solution_usecase_manager_enabled && (
            <button
              onClick={handleTabClick(1)}
              className={`tab-button ${active === 1 ? 'tab-active' : ''}`}
            >
              Solution Library
            </button>
          )}
          {(isAdminOrManager || isProductUser) && (
            <button
              onClick={handleTabClick(2)}
              className={`tab-button ${active === 2 ? 'tab-active' : ''}`}
            >
              Manage Modules and Features
            </button>
          )}
          {(isAdminOrManager || isProductUser) && (
            <button
              onClick={handleTabClick(3)}
              className={`tab-button ${active === 3 ? 'tab-active' : ''}`}
            >
              Product Integrations
            </button>
          )}
          {(isAdminOrManager || isProductUser) &&
            companyConfig?.data?.featureList?.is_issue_manager_enabled && (
              <button
                onClick={handleTabClick(4)}
                className={`tab-button ${active === 4 ? 'tab-active' : ''}`}
              >
                Issue Manager
              </button>
            )}
        </>
      }
      extra={
        active === 0 || active === 1 ? (
          <>
            <LibraryFilter
              key={tab}
              filterFor={tab}
              handleSubmit={submitMethods[tab]}
              handleReset={submitMethods[tab]}
            />
            <ModulesFeaturesFilter
              key={`modulesfeatures-filter-${tab}`}
              filterFor={tab}
              handleSubmit={submitMethods[tab]}
            />
          </>
        ) : null
      }
    >
      <section className="tab-content">
        {active === 0 ? (
          <ProductGapUseCases
            productGapUseCases={productGapUseCases}
            productGapUseCasesLoading={productGapUseCasesLoading}
            getProductGapUseCases={submitMethods.productgaps}
            opportunities={opportunitiesForPG}
            companyConfig={companyConfig}
          />
        ) : active === 1 ? (
          <SolutionUseCases
            solutionUseCases={solutionUseCases}
            solutionUseCasesLoading={solutionUseCasesLoading}
            getSolutionUsecase={submitMethods.solutions}
            opportunities={opportunitiesForSolutions}
          />
        ) : active === 2 ? (
          <ManageModuleFeatures />
        ) : active === 3 ? (
          <ManageProductIntegrations />
        ) : (
          <IssueManager />
        )}
      </section>
    </PageLayout>
  );
};

export default PGManager;
