import React, { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getUsersLabelValuePair } from '../utils/adminopsUtils';
import { MultiSelect } from '../../common/MultiSelect';
import { Option } from '../../Constant/MultiSelectStylesAndComponents';
import {
  extendedAdminOpsSlice,
  useUpdateNotificationCenterMutation,
} from '../adminOpsSlice';
import Button from '../../common/Button';

const MonthlyMeetingNotification = forwardRef(
  ({ userPreferences, customStyles, companyId }, ref) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [updateNotificationCenter] = useUpdateNotificationCenterMutation();
    useEffect(() => {
      if (userPreferences) {
        const userLabelValuePair = getUsersLabelValuePair(userPreferences);
        setUsers(userLabelValuePair);
        const filteredUsersForMonthlyNotification = [];
        userLabelValuePair?.map((data) => {
          if (data?.sendMonthlyMeetingNotification) {
            filteredUsersForMonthlyNotification.push(data);
          }
        });
        setSelectedUsers(filteredUsersForMonthlyNotification);
      }
    }, [userPreferences]);

    const onSave = () => {
      toast.info('Updating notification center');
      const usersForMonthlyDigest = selectedUsers.map((data) => data?.value);
      const body = {
        companyId: companyId,
        usersForMonthlyDigest: usersForMonthlyDigest,
        notification: 'monthly_meeting',
      };
      updateNotificationCenter(body)
        .then((data) => {
          toast.success('Updated successfully!');
          dispatch(
            extendedAdminOpsSlice.util.invalidateTags([
              { type: 'AdminOps', id: 'getAllUserPreference' },
            ])
          );
        })
        .catch((error) => {
          toast.error('Update failed!');
          console.log('error:', error);
        });
    };

    return (
      <div ref={ref} className="mb-20">
        <h1 className="text-weight-semi-bold text-lg font-primary">
          Monthly Meeting Notification
        </h1>
        <div className="mt-10">
          <div className="mt-10 mb-10">
            User list who will get the email (Admin, Manager)
          </div>
          <MultiSelect
            className="Dropdown-root"
            options={users}
            value={selectedUsers || []}
            onChange={setSelectedUsers}
            components={{ Option }}
            styles={customStyles}
          />
          <div className="mt-10">
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default MonthlyMeetingNotification;
