import React, { useState, useEffect } from 'react';
import Table from '../../common/Table';

import Loader from '../../common/Loader';
import { ReactComponent as WarningSVG } from '../../Icons/NewImage/warning.svg';
import { teamWorkloadColumn } from '../../Constant/LightningDashBoard';
import {
  addDays,
  getCurrentWeek,
  toISODate,
  getDatesInRange,
  getTodayDiff,
} from '../../Utils/DateUtils';
import { DateFormat } from '../../Utils/DayJs';
import emptyHyphen from '../../_helpers/empty-hyphen';
import LightningDashboardLineChart from './LineChart';
import LightningDashboardBarChart from './BarChart';
import BarChartToolTip from './BarChartToolTip';
import { formatCount } from '../../Utils/Revenue';
import NoContent from '../../Accounts/commons/NoContent';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  fetchActivityTrend,
  fetchOutcomeTrend,
  fetchUpcomingMeetings,
  getAllTeams,
  getParentContribution,
  getStageTrends,
  getTeamWorkload,
} from '../../_services/common.service';
import { isAdminOrManager } from '../../Utils/common';
import { useFetchPipelineAccessibilityQuery } from '../../app/api/teamsSlice';

const dataKey = {
  successItems: '#44D2F2',
  tasks: '#DE350B ',
  productGaps: '#000000',
};

const ManagerDashboard = () => {
  const axios = useAxiosPrivate();
  const { response: activityTrendRes, ...callActivityTrend } = useAxios(axios);
  const {
    response: teamWorkload,
    loading: teamWorkloadLoading,
    ...callTeamWorkload
  } = useAxios(axios);
  const { response: stageTrends, ...fetchStageTrends } = useAxios(axios);
  const { response: outcomeTrendRes, ...callOutcomeTrend } = useAxios(axios);
  const {
    response: upcomingMeetings,
    loading: upcomingMeetingsLoading,
    ...callUpcomingMeetings
  } = useAxios(axios);
  const { response: teamsList, ...fetchAllTeams } = useAxios(axios);
  const getContributions = useAxios(axios);

  const [topMeetings, setTopMeetings] = useState([]);
  const [userList, setUserList] = useState([]);
  const [contribution, setContribution] = useState({});
  const [activityTrend, setActivityTrend] = useState([]);
  const [outcomeTrends, setOutcomeTrends] = useState([]);

  const topMeetingsColumn = [
    {
      Header: 'Meeting',
      id: 'summary',
      Cell: ({ row }) => (
        <span className="flex-align-center">
          {!row.original.is_synced && <WarningSVG />}
          <span> {row.original.summary} </span>
        </span>
      ),
    },
    {
      Header: 'Time',
      accessor: (data) => `${DateFormat(data.time, 'hh:mm A')}`,
    },
    {
      Header: 'Presales',
      accessor: (data) =>
        `${data.presales !== null ? data.presales : userMapping(data.userId)}`,
    },
    {
      Header: 'Outcome',
      accessor: (data) => `${emptyHyphen(contribution[data.contribution])}`,
    },
    {
      Header: 'Opportunity Name',
      accessor: (data) => `${emptyHyphen(data.opportunity)}`,
    },
    {
      Header: 'Revenue',
      accessor: (data) => `${emptyHyphen(formatCount(data.revenue))}`,
    },
  ];

  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();

  useEffect(() => {
    if (isAdminOrManager() || pipelineAccessibility?.data === 'user')
      getAllTeams(fetchAllTeams);
    getAllContributions();
  }, []);

  useEffect(() => {
    getTeamData(teamsList);
  }, [teamsList]);

  useEffect(() => {
    if (userList && userList.length) {
      getTeamWorkloadData();
      getActivityTrend(userList);
      getOutcomeTrends(userList);
      getStageTrends(fetchStageTrends, userList);
      getUpcomingMeetings(userList);
    }
  }, [userList]);

  useEffect(() => {
    getActivityTrendData(activityTrendRes);
  }, [
    activityTrendRes?.successItems,
    activityTrendRes?.productGaps,
    activityTrendRes?.task,
  ]);

  useEffect(() => {
    getOutcomeTrendsData(outcomeTrendRes);
  }, [outcomeTrendRes?.outcomeTypes]);

  useEffect(() => {
    getTopMeetings(upcomingMeetings);
  }, [upcomingMeetings]);

  const getTeamData = (data) => {
    if (data && data.status && data.data && data.data.length > 0) {
      setUserList(data.data[0].teamMembers);
    }
  };

  const getTeamWorkloadData = () => {
    const todayDate = toISODate(new Date());
    const tomDate = addDays(1, new Date());
    getTeamWorkload(callTeamWorkload, todayDate, tomDate, userList);
  };

  const getActivityTrend = (users) => {
    const dateRange = getTodayDiff(1, 6);
    fetchActivityTrend(callActivityTrend, dateRange.from, dateRange.to, users);
  };

  const getActivityTrendData = (data) => {
    const dateRange = getTodayDiff(1, 6);
    if (data && Object.entries(data).length) {
      const dataMap = new Map();
      // modifying from and to date for getting the correct week date range
      const toDateForDateRange = new Date(dateRange.to);
      toDateForDateRange.setDate(toDateForDateRange.getDate() - 1);
      const dateRangeArray = getDatesInRange(
        new Date(dateRange.from),
        toDateForDateRange
      );
      for (let dateIndex = 0; dateIndex < dateRangeArray.length; dateIndex++) {
        dataMap[dateRangeArray[dateIndex]] = {
          date: dateRangeArray[dateIndex],
          successItems: 0,
          tasks: 0,
          productGaps: 0,
        };
      }
      for (const [key, value] of Object.entries(data)) {
        value.forEach((element) => {
          if (dataMap[element._id]) {
            dataMap[element._id][key] = element.count;
          } else {
            dataMap[element._id] = {};
            dataMap[element._id].date = element._id;
            dataMap[element._id][key] = element.count;
          }
        });
      }
      setActivityTrend(Object.values(dataMap));
    }
  };

  const getOutcomeTrends = (users) => {
    const dateRange = getCurrentWeek(new Date());
    const fromDate = dateRange.from;
    const toDate = dateRange.to;
    fetchOutcomeTrend(callOutcomeTrend, fromDate, toDate, users);
  };

  const getOutcomeTrendsData = (data) => {
    if (data) {
      const chartData = [];
      if (data.outcomeTypes && data.outcomeTypes.length) {
        data.outcomeTypes.forEach((element) => {
          const outcome = {};
          outcome.name = contribution[element._id];
          outcome.count = element.count;
          chartData.push(outcome);
        });
      }
      setOutcomeTrends(chartData);
    }
  };

  const getUpcomingMeetings = (users) => {
    const todayData = getTodayDiff();
    fetchUpcomingMeetings(
      callUpcomingMeetings,
      todayData.from,
      todayData.to,
      users
    );
  };

  const getTopMeetings = (data) => {
    /*
        * Here the data contains all meetings within a timerange
        * to cover different timezones and
        * we are filtering the meetings that have the date
        * that matches the timezone sensed by the browser
        // */

    if (Array.isArray(data)) {
      const todaysTopMeetings = data.filter(({ time }) => {
        if (time) {
          const today = new Date().getDate();
          const meetingDay = new Date(time).getDate();
          return today === meetingDay;
        }
      });
      setTopMeetings(todaysTopMeetings);
    }
  };

  const getAllContributions = () => {
    const profileData = JSON.parse(localStorage.getItem('user'));
    const companyId = profileData?.companyId;
    if (!companyId) {
      return;
    }
    getParentContribution(getContributions, {
      params: { company_id: companyId },
      handleSuccess: handleGetAllContributions,
    });
  };

  const handleGetAllContributions = (data) => {
    const contributionMap = {};
    data &&
      data.forEach((item) => {
        contributionMap[item.id] = item.title;
      });
    setContribution(contributionMap);
  };

  const userMapping = (id) => {
    let user = null;
    userList.forEach((item) => {
      if (item.id === id) {
        user = item.name;
      }
    });

    return user;
  };

  return (
    <>
      <div className="chart-container">
        <div className="chart-content-container">
          <div className="chart-header">Activity Trend - This Week</div>
          {activityTrend && activityTrend.length ? (
            <LightningDashboardLineChart
              data={activityTrend}
              dataKey={dataKey}
            />
          ) : (
            ''
          )}
        </div>
        <div className="chart-content-container">
          <div className="chart-header">Outcomes - This Week</div>
          {outcomeTrends && outcomeTrends.length ? (
            <BarChartToolTip>
              <LightningDashboardBarChart
                data={outcomeTrends}
                dataKey={dataKey}
                xKey="name"
                yKey="count"
              />
            </BarChartToolTip>
          ) : null}
        </div>
        <div className="chart-content-container">
          <div className="chart-header">Presales Pipeline Opps</div>
          {stageTrends && stageTrends.length ? (
            <BarChartToolTip>
              <LightningDashboardBarChart
                data={stageTrends}
                dataKey={dataKey}
                xKey="stageName"
                yKey="count"
                tooltipkey="revenue"
              />
            </BarChartToolTip>
          ) : null}
        </div>
      </div>
      <div className="table-container">
        {teamWorkloadLoading || upcomingMeetingsLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="table-flex">
              <div className="table width-40">
                <div className="table-header">Team Workload - Today</div>
                {
                  <div>
                    {' '}
                    <Table
                      data={teamWorkload}
                      columns={teamWorkloadColumn}
                      className={
                        teamWorkload.length === 0
                          ? 'no-items'
                          : 'sales-trend-table'
                      }
                    />{' '}
                  </div>
                }
                {teamWorkload && teamWorkload.length === 0 && (
                  <NoContent
                    className="no-data-table"
                    message="No data to display"
                  />
                )}
              </div>

              <div className="table">
                <div className="table-header">Top Meetings - Today</div>
                {
                  <div>
                    {' '}
                    <Table
                      data={topMeetings}
                      columns={topMeetingsColumn}
                      className={topMeetings.length === 0 ? 'no-items' : ''}
                    />{' '}
                  </div>
                }
                {topMeetings && topMeetings.length === 0 && (
                  <NoContent
                    className="no-data-table"
                    message="No data to display"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ManagerDashboard;
