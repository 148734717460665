import { useEffect } from 'react';
import { axiosBusiness, AXIOS_DOMAIN_NAME } from '../_helpers/axios';
import useRefreshToken from './useRefreshToken';

const useAxiosPrivate = (axiosInstance = axiosBusiness) => {
  const refresh = useRefreshToken();
  const idToken = localStorage.getItem('idToken');
  const user = localStorage.getItem('user');
  let json = JSON.parse(user);

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${idToken}`;
          config.headers['Access-Control-Allow-Origin'] = AXIOS_DOMAIN_NAME;
          config.headers['X-TENANT-ID'] = json.companyId;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest.retried) {
          prevRequest.retried = true; // Property set to avoid looping
          const newIdToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newIdToken}`;
          return axiosInstance(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [refresh, idToken]);

  return axiosInstance;
};

export default useAxiosPrivate;
