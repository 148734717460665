import React from 'react';
import { convertMsToHM } from '../Utils/DateUtils';
import emptyHyphen from '../_helpers/empty-hyphen';
import { formatCount } from '../Utils/Revenue';

const opportunitiesColumn = [
  {
    Header: 'Sales Engineer',
    accessor: 'salesEngineerName',
    enableRowSpan: true,
  },
  {
    Header: 'Opp. name',
    accessor: 'opportunity.name',
  },
  {
    Header: 'Sales Owner',
    accessor: 'opportunity.salesOwner',
  },
  {
    Header: 'Industry',
    accessor: 'opportunity.industry',
  },
  {
    Header: 'Revenue',
    accessor: (data) => `${emptyHyphen(formatCount(data.opportunity.revenue))}`,
  },
  {
    Header: 'CRM Status/Stage',
    accessor: 'opportunity.status',
  },
  {
    Header: 'Solution Book',
    columns: [
      {
        Header: 'Success Items',
        accessor: 'opportunity.successItems',
      },
      {
        Header: 'Product Gaps',
        accessor: 'opportunity.productGaps',
      },
    ],
  },
  {
    Header: 'Workbook',
    columns: [
      {
        Header: 'Tasks',
        accessor: 'opportunity.tasks',
      },
      {
        Header: 'Notes',
        accessor: 'opportunity.notes',
      },
    ],
  },
];

const salesTrendColumn = [
  {
    Header: 'Sales Engineer',
    accessor: 'salesEngineer',
    enableRowSpan: true,
  },
  {
    Header: 'Sales Owner',
    accessor: 'salesOwner',
  },
  {
    Header: 'Opportunities',
    columns: [
      {
        Header: 'Active',
        id: 'active',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.numberOfActiveDeals} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {formatCount(row.original.activeDealsRevenue)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'Won',
        id: 'won',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.numberOfWonDeals} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {formatCount(row.original.wonDealsRevenue)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'Lost',
        id: 'lost',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.numberOfLostDeals} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {formatCount(row.original.lostDealsRevenue)}{' '}
            </span>
          </span>
        ),
      },
    ],
  },
  {
    Header: 'Meetings',
    columns: [
      {
        Header: 'Total Meetings',
        accessor: 'totalMeetings',
      },
      {
        Header: 'Time Spent',
        id: 'timespent',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.timeSpent)}{' '}
            </span>
          </span>
        ),
      },
    ],
  },
  {
    Header: 'Conversion',
    columns: [
      {
        Header: 'Account Conversion',
        id: 'accountc',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            {<span>{emptyHyphen(row.original.accountConversion)}</span>}
          </span>
        ),
      },
      {
        Header: 'Revenue Conversion',
        id: 'revenuec',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            {<span>{emptyHyphen(row.original.revenueConversion)}</span>}
          </span>
        ),
      },
      {
        Header: 'ARPU',
        id: 'arpu',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            {<span>{emptyHyphen(formatCount(row.original.arpu))}</span>}
          </span>
        ),
      },
    ],
  },
];

const trendsEventsColumn = [
  {
    Header: 'Sales Engineer',
    accessor: 'salesEngineer',
  },
  {
    Header: 'Work',
    columns: [
      {
        Header: () => <div className="width-140"> Total Tagged Meetings </div>,
        id: 'total',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.totalMeetings} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.totalMeetingTime)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'Internal',
        id: 'internal',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.internalMeetings} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.internalMeetingTime)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'External',
        id: 'external',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.externalMeetings} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.externalMeetingTime)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: () => (
          <div className="width-140"> Total Untagged External Meetings </div>
        ),
        id: 'unsynced',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.unSyncedMeetings} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.unSyncedMeetingTime)}{' '}
            </span>
          </span>
        ),
      },
    ],
  },

  {
    Header: 'General',
    id: 'presales',
    Cell: ({ row }) => (
      <span className="flex-align-center">
        <span> {row.original.presalesMeetings} </span>
        <span className="tag tag-light-gray opp-cell">
          {' '}
          {convertMsToHM(row.original.presalesMeetingTime)}{' '}
        </span>
      </span>
    ),
  },
  {
    Header: 'Personal',
    id: 'personal',
    Cell: ({ row }) => (
      <span className="flex-align-center">
        <span> {row.original.personalMeetings} </span>
        <span className="tag tag-light-gray opp-cell">
          {' '}
          {convertMsToHM(row.original.personalMeetingTime)}{' '}
        </span>
      </span>
    ),
  },
];

const allMeetingsColumn = [
  {
    Header: 'Sales Engineer',
    accessor: 'salesEngineerName',
    enableRowSpan: true,
  },
  {
    Header: 'Opp. name',
    accessor: 'opportunityName',
    enableRowSpan: true,
  },
  {
    Header: 'Meetings Name',
    accessor: 'meetingName',
  },
  {
    Header: 'Time Spent',
    accessor: 'timeSpent',
  },
  {
    Header: 'Event Type',
    accessor: 'eventType',
  },
  {
    Header: 'Outcome',
    accessor: 'outcome',
  },
];

const trendsProductGapColumn = [{
    Header: "Modules",
    accessor: "module",
},
{
    Header: "Usage Trend",
    columns: [
        {
            Header: "Opportunity Count",
            accessor: "utilization"
        },
        {
            Header: " Product Items",
            accessor: "noOfItems"
        },
        {
            Header: "Lost Rate",
            Cell: ({row}) => (
                <span className='flex-align-center'>
                    {<div>
                        <span>{emptyHyphen(row.original.lostRate)}</span>
                        <span>{row.original.lostRate?"%":null}</span>
                        </div>
                    }
                </span>
            )
        },
        {
            Header: "Product Pulse",
            accessor: "productPulse"
        }
    ]
},
{
    Header: "Stage-Wise",
    columns: [
        {
            Header: "Active",
            accessor: "active"
        },
        {
            Header: "Won",
            accessor: "won"
        },
        {
            Header: "Lost",
            accessor: "lost"
        }
    ]
},
];

const trendsSuccessItemColumn = [
  {
    Header: 'Success Items',
    accessor: 'successItem',
  },
  {
    Header: 'Usage Trend',
    columns: [
      {
        Header: 'Utilization',
        accessor: 'utilization',
      },
      {
        Header: 'Win Rate',
        accessor: 'winRate',
      },
      {
        Header: 'Success Pulse',
        accessor: 'successPulse',
      },
    ],
  },
  {
    Header: 'Stage-Wise',
    columns: [
      {
        Header: 'Active',
        accessor: 'active',
      },
      {
        Header: 'Won',
        accessor: 'won',
      },
      {
        Header: 'Lost',
        accessor: 'lost',
      },
    ],
  },
];

const trendsOutcomesColumn = [
  {
    Header: 'Outcomes',
    accessor: 'outcomeName',
  },
  {
    Header: 'Usage Trend',
    columns: [
      {
        Header: 'Total Meetings',
        id: 'total',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.numberOfOutcomeMeetings} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.timeOfOutcomeMeetings)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'Opportunity Count',
        accessor: 'utilization',
      },
      {
        Header: 'Win Rate',
        accessor: 'winRate',
      },
    ],
  },
  {
    Header: 'Stage-Wise',
    columns: [
      {
        Header: 'Active',
        accessor: 'active',
      },
      {
        Header: 'Won',
        accessor: 'won',
      },
      {
        Header: 'Lost',
        accessor: 'lost',
      },
    ],
  },
];

const managerSalesTrendColumn = [
  {
    Header: 'Presales',
    accessor: 'salesEngineer',
  },
  {
    Header: 'Opportunities',
    columns: [
      {
        Header: 'Active',
        accessor: 'numberOfActiveDeals',
      },
      {
        Header: 'Won',
        accessor: 'numberOfWonDeals',
      },
      {
        Header: 'Lost',
        accessor: 'numberOfLostDeals',
      },
    ],
  },
  {
    Header: 'Meetings',
    columns: [
      {
        Header: 'Total Meetings',
        accessor: 'totalMeetings',
      },
      {
        Header: 'Time Spent',
        id: 'timespent',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.timeSpent)}{' '}
            </span>
          </span>
        ),
      },
    ],
  },
  {
    Header: 'Conversion',
    columns: [
      {
        Header: 'Account Conversion',
        id: 'accountc',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            {<span>{emptyHyphen(row.original.accountConversion)}</span>}
          </span>
        ),
      },
      {
        Header: 'Revenue Conversion',
        id: 'revenuec',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            {<span>{emptyHyphen(row.original.revenueConversion)}</span>}
          </span>
        ),
      },
      {
        Header: 'ARPU',
        id: 'arpu',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            {<span>{emptyHyphen(formatCount(row.original.arpu))}</span>}
          </span>
        ),
      },
    ],
  },
];

const managerOpportunitiesColumn = [
  {
    Header: 'Opp. Name',
    accessor: 'name',
  },
  {
    Header: 'Presales Owner',
    accessor: 'presalesOwner',
  },
  {
    Header: 'Sales Owner',
    accessor: 'salesOwner',
  },
  {
    Header: 'Industry',
    accessor: 'industry',
  },
  {
    Header: 'Revenue',
    accessor: (data) => `${emptyHyphen(formatCount(data.revenue))}`,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Solution Book',
    columns: [
      {
        Header: 'Success Items',
        accessor: 'successItems',
      },
      {
        Header: 'Product Gaps',
        accessor: 'productGaps',
      },
    ],
  },
  {
    Header: 'Workbook',
    columns: [
      {
        Header: 'Tasks',
        accessor: 'tasks',
      },
      {
        Header: 'Notes',
        accessor: 'notes',
      },
    ],
  },
];

export {
  opportunitiesColumn,
  salesTrendColumn,
  trendsEventsColumn,
  allMeetingsColumn,
  trendsOutcomesColumn,
  trendsSuccessItemColumn,
  trendsProductGapColumn,
  managerSalesTrendColumn,
  managerOpportunitiesColumn,
};
