import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { toast } from 'react-toastify';

const PreskaleMultiSelect = ({
  options,
  placeholder,
  maxTagCount,
  value: externalValue,
  onChange,
  loading = false,
  restrictDeselect = false,
  message,
  showSearch = false,
  ...props
}) => {
  const [selectedValues, setSelectedValues] = useState(externalValue || []);

  useEffect(() => {
    setSelectedValues(externalValue || []);
  }, [externalValue]);

  const treeData = [
    {
      label: (
        <span
          onClick={() => {
            const newSelectedValues =
              selectedValues?.length === options?.length ? [] : options;
            setSelectedValues(newSelectedValues);
            onChange(newSelectedValues);
          }}
          className="tree-select-all"
        >
          {selectedValues?.length === options?.length
            ? 'Unselect all'
            : 'Select all'}
        </span>
      ),
      value: 'xxx',
      disableCheckbox: true,
      disabled: true,
    },
    ...options,
  ];

  return (
    <TreeSelect
      allowClear={false}
      placeholder={placeholder || 'Select an option'}
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      dropdownStyle={{ maxHeight: '300px' }}
      rootClassName="preskale-multiselect"
      labelInValue={true}
      onChange={(ids) => {
        if (restrictDeselect && !ids?.length) {
          toast.info(
            message ? message : 'Atleast one item has to be selected!'
          );
          return;
        }
        setSelectedValues(ids);
        onChange(ids);
      }}
      value={selectedValues}
      maxTagCount={maxTagCount || 2}
      maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length}`}
      treeData={treeData}
      loading={loading}
      showSearch={showSearch}
      filterTreeNode={(search, item) => {
        if (showSearch) {
          if (
            item?.value !== 'xxx' &&
            item?.label?.toLowerCase().includes(search?.toLowerCase())
          ) {
            return item;
          }
          if (item.value === 'xxx') {
            return item;
          }
        } else {
          return item;
        }
      }}
      {...props}
    />
  );
};

export default PreskaleMultiSelect;
