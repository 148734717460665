import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Typography,
  Button,
  Form,
  InputNumber,
  Input,
  DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import { CustomOptionSelect } from '../common/Form/CustomOptionSelect';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import DeletePopup from '../common/Modals/DeletePopup';
import dayjs from 'dayjs';
import {
  useCreateOpportunityMutation,
  useLazyDeleteDealQuery,
  useUpdateDealMutation,
} from '../Accounts/accountsSlice';
import useFetchOptionsForCreateOpportunity from '../hooks/useFetchOptionsForCreateOpportunity';
import { businessService } from '../app/api/businessService';
import { customizeRequiredMark } from '../Utils/common';

const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';

const CreateOpportunity = ({
  dealDetail,
  openDrawer,
  edit,
  handleClose = () => {},
  handleSubmit = () => {},
  handleDelete = () => {},
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isExistingAccount, setIsExistingAccount] = useState(true);
  const [isExistingSalesOwner, setIsExistingSalesOwner] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createOpportunity] = useCreateOpportunityMutation();
  const [updatePreskaleDeal] = useUpdateDealMutation();
  const [deleteDeal] = useLazyDeleteDealQuery();

  const { allAccounts, industries, countries, salesOwners, accountsLoading } =
    useFetchOptionsForCreateOpportunity();

  useEffect(() => {
    if (dealDetail && edit) {
      form.setFieldsValue({
        opportunityName: dealDetail?.name,
        accountName: allAccounts ? dealDetail?.accountId : '',
        opportunityValue: dealDetail?.dealValue,
        salesOwner:
          salesOwners?.find((data) => data.label === dealDetail.dealOwner)
            ?.value ?? null,
        contactName: dealDetail?.contacts?.[0]?.displayName ?? '',
        contactEmail: dealDetail?.contacts?.[0]?.email ?? '',
        country: dealDetail?.additionalDetails?.country,
        industry: dealDetail?.additionalDetails?.industry,
        closeDate: dealDetail.expectedClosed
          ? dayjs(dealDetail?.expectedClosed, dateFormat)
          : '',
      });
    }
  }, [dealDetail, salesOwners, form, allAccounts, edit]);

  const handleAddOption = (field, newOption) => {
    if (field === 'accountName') {
      form.setFieldsValue({
        [field]: newOption,
        country: null,
        industry: null,
      });
      setIsExistingAccount(false);
    } else if (field === 'salesOwner') {
      form.setFieldsValue({
        [field]: newOption,
      });
      setIsExistingSalesOwner(false);
    } else {
      form.setFieldsValue({
        [field]: newOption,
      });
    }
  };

  const onFinish = (values) => {
    const getAccountLabel = (accountId) =>
      allAccounts?.find((data) => data.value === accountId)?.label;

    const payload = {
      accountName: isExistingAccount
        ? getAccountLabel(values?.accountName)
        : values?.accountName,
      dealName: values?.opportunityName,
      dealValue: values?.opportunityValue,
      industry: values?.industry,
      region: values?.country,
      primaryContactName: values?.contactName,
      primaryContactEmail: values?.contactEmail,
      salesOwnerEmail: values?.salesOwnerEmail,
      expectedClose: values['closeDate']
        ? values['closeDate'].format(dateFormat)
        : dealDetail?.expectedClosed,
    };

    if (!edit && isExistingAccount) {
      payload.accountId = values?.accountName;
    } else if (
      edit &&
      dealDetail.accountId !== values.accountName &&
      isExistingAccount
    ) {
      payload.newAccountId = values?.accountName;
    }

    const selectedSalesOwner = salesOwners?.find(
      (data) => data.value === values?.salesOwner
    );

    const salesOwnerData = {
      displayName: !isExistingSalesOwner
        ? values?.salesOwner
        : selectedSalesOwner?.label,
      email: !isExistingSalesOwner
        ? values?.salesOwnerEmail
        : selectedSalesOwner?.email,
      id: values?.salesOwner,
    };

    payload.salesOwner = values?.salesOwner ? salesOwnerData : null;

    if (edit) {
      payload.accountId = dealDetail.accountId;
      payload.dealId = dealDetail.dealId;
      payload.primaryContactId = dealDetail?.contacts[0]?.id;
      updateOpportunity(payload);
    } else {
      saveOpportunity(payload);
    }
  };

  const saveOpportunity = (payload) => {
    createOpportunity(payload)
      .unwrap()
      .then((response) => {
        const dealData = {
          ...response?.data,
          update: false,
          deleteDeal: false,
        };
        handleSubmit(dealData);
        handleClose();
        toast.success('Opportunity Created Successfully');
        invalidateTags();
      })
      .catch((error) => {
        console.error('Error creating opportunity:', error);
        handleClose();
        toast.error('Something went wrong!');
      });
  };

  const updateOpportunity = (payload) => {
    updatePreskaleDeal(payload)
      .unwrap()
      .then((response) => {
        const dealData = { ...payload, update: true, deleteDeal: false };
        handleSubmit(dealData);
        handleClose();
        toast.success('Opportunity Updated Successfully');
        invalidateTags();
      });
  };

  const invalidateTags = () => {
    dispatch(
      businessService.util.invalidateTags([
        { type: 'Deals', id: 'salesOwners' },
        { type: 'Deals', id: 'basicAccountDetails' },
        { type: 'Accounts', id: 'All' },
      ])
    );
  };

  const onValueChange = (changedValues, allValues) => {
    if ('accountName' in changedValues) {
      const { industry, country } =
        allAccounts.find(
          (data) => data.value === changedValues['accountName']
        ) || {};
      form.setFieldsValue({ ...allValues, country, industry });
      setIsExistingAccount(true);
    }
    if ('salesOwner' in changedValues) {
      setIsExistingSalesOwner(true);
    }
  };

  const handleDeleteDeal = () => {
    deleteDeal({ dealId: dealDetail?.dealId })
      .unwrap()
      .then((res) => {
        const dealData = { deleteDeal: true, update: false };
        setShowDeleteModal(false);
        handleDelete(dealData);
        toast.success('Deleted Deal successfully');
      });
  };

  return (
    <>
      <Drawer
        open={openDrawer}
        closable={false}
        destroyOnClose={true}
        title={
          <Title level={4}>
            {!edit ? 'Create Opportunity' : 'Update Opportunity'}
          </Title>
        }
        extra={
          <Button icon={<CloseCircleOutlined />} onClick={handleClose}>
            Close
          </Button>
        }
        footer={
          <div className="text-right">
            <Button
              className="mr-8"
              icon={<DeleteOutlined />}
              onClick={() => setShowDeleteModal(true)}
            />
            <Button className="mr-8" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              form="create-opportunity"
              key="submit"
              htmlType="submit"
            >
              {!edit ? 'Create Opportunity' : 'Update Opportunity'}
            </Button>
          </div>
        }
        onClose={handleClose}
      >
        <Form
          form={form}
          layout="vertical"
          id="create-opportunity"
          onFinish={onFinish}
          onValuesChange={onValueChange}
          style={{ maxWidth: 600 }}
          requiredMark={customizeRequiredMark}
        >
          <Form.Item
            label="Opportunity Name"
            name="opportunityName"
            rules={[
              { required: true, message: 'Please enter the opportunity name' },
            ]}
          >
            <Input placeholder="Enter Opportunity Name" />
          </Form.Item>
          <Form.Item
            label="Account Name"
            name="accountName"
            rules={[
              { required: true, message: 'Please select an Account Name' },
            ]}
          >
            <CustomOptionSelect
              options={allAccounts}
              isLoading={accountsLoading}
              placeholder="Select Account"
              onAddItem={handleAddOption}
              field="accountName"
            />
          </Form.Item>
          <Form.Item label="Sales Owner" name="salesOwner">
            <CustomOptionSelect
              options={salesOwners}
              placeholder="Select Sales Owner"
              onAddItem={handleAddOption}
              field="salesOwner"
            />
          </Form.Item>
          {!isExistingSalesOwner && (
            <Form.Item
              label="Sales Owner Email"
              name="salesOwnerEmail"
              rules={[
                {
                  required: true,
                  message: 'Please enter the Sales Owner email',
                },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Enter Primary Contact Email Id" />
            </Form.Item>
          )}
          <Form.Item
            label="Opportunity Value"
            name="opportunityValue"
            rules={[
              {
                required: true,
                message: 'Please Enter Opportunity Value',
              },
            ]}
          >
            <InputNumber
              placeholder="Enter Opportunity Value"
              min={0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              className="width-full"
            />
          </Form.Item>
          <Form.Item
            label="Primary Contact Name"
            name="contactName"
            rules={[
              {
                required: true,
                message: 'Please enter the primary contact name',
              },
            ]}
          >
            <Input placeholder="Enter Primary Contact Name" />
          </Form.Item>
          <Form.Item
            label="Primary Contact Email"
            name="contactEmail"
            rules={[
              {
                required: true,
                message: 'Please enter the primary contact email',
              },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Enter Primary Contact Email Id" />
          </Form.Item>
          <Form.Item label="Industry" name="industry">
            <CustomOptionSelect
              options={industries}
              placeholder="Select Industry"
              onAddItem={handleAddOption}
              field="industry"
              disabled={isExistingAccount}
            />
          </Form.Item>
          <Form.Item label="Country" name="country">
            <CustomOptionSelect
              options={countries}
              placeholder="Select Country"
              onAddItem={handleAddOption}
              field="country"
              disabled={isExistingAccount}
            />
          </Form.Item>
          <Form.Item
            label="Expected Close Date"
            name="closeDate"
            rules={[
              {
                required: true,
                message: 'Please enter the expected close date',
              },
            ]}
          >
            <DatePicker format={dateFormat} className="width-full" />
          </Form.Item>
        </Form>
      </Drawer>
      {showDeleteModal && (
        <DeletePopup
          showModal={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          actionComponents={[
            <Button key="back" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleDeleteDeal}
              danger
            >
              Delete
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default CreateOpportunity;
