import { Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

const TeamsContent = () => {
  return (
    <>
      <Title level={5}>Teams</Title>
      <Text>
        You can organize your users into specific Teams like “Solution
        Architects” and “Sales Engineers”. This lets you easily manage teams,
        team managers, manage pipeline visibility, and the status of the team.
        Note that an agent can be a member of multiple groups.
      </Text>

      <Title level={5} style={{ marginTop: '20px' }}>
        Managing a Team
      </Title>
      <Text>
        Create a new team, assign team managers and team members, and also
        associate unique properties based on the team’s accessibility.
      </Text>
    </>
  );
};

export default TeamsContent;
