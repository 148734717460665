import DualRingLoader from '../../common/DualRingLoader';
import {
  REVENUE_OPERATOR_OPTIONS,
  DATE_OPERATOR_OPTIONS,
  PRESALES_USER_OPTIONS,
} from '../../Constant/FilterOperators';
import { getDateByOperator } from '../../Utils/DateUtils';
import Button from '../../common/Button';
import { ReactComponent as RefreshJira } from '../../Icons/Refresh.svg';
import useDataPersistance from '../../hooks/useDataPersistance';
import {
  isProductUser,
  isSalesUser as findIsSalesUser,
} from '../../Utils/common';
import { saveSelectedColumn } from '../pipelineSlice';

export const resetCurrentPage = (setCurrentPage) =>
  setCurrentPage((prev) => {
    let current = { ...prev };
    Object.keys(prev).forEach((page) => (current[page] = 1));
    return current;
  });

export const _setDateFilter = (filter) => {
  const dateRange = getDateByOperator(filter.expectedCloseDate.operator);
  filter.expectedCloseDate.fromDate = dateRange.fromDate;
  filter.expectedCloseDate.toDate = dateRange.toDate;
  return filter;
};

export const getSelectedTeam = () => {
  const teams = localStorage.getItem('selectedTeams');
  if (teams && teams !== 'undefined') {
    const teamsParsed = JSON.parse(teams);
    const selectedTeam = teamsParsed?.map((team) => team?.value);
    return selectedTeam;
  }
  return null;
};

export const updateLoadingState = (
  laneId,
  state = false,
  {
    lanes = [],
    setBoardData = () => {},
    parentStageMap = {},
    handleRetry = () => {},
    error,
  }
) => {
  if (error) {
    console.error('error', error);
  }
  let updatedLanes = lanes.map((lane) => {
    if (lane.id === laneId) {
      lane.loading = state.toString();
      lane.header =
        error &&
        error?.code !== 'ERR_NETWORK' &&
        error?.response?.data?.status !== 401 ? ( // Checks the type of error encountered and renders the retry button
          <div className="flex">
            {parentStageMap[laneId]}{' '}
            <Button
              icon
              type="text"
              className="btn-refresh-icon"
              onClick={handleRetry}
            >
              <RefreshJira />{' '}
              <span className="text-primary ml-2 text-xxs">Refresh stage</span>
            </Button>
          </div>
        ) : state ? (
          <div>
            {parentStageMap[laneId]} <DualRingLoader dark />
          </div>
        ) : (
          parentStageMap[laneId]
        );
    }
    return lane;
  });
  setBoardData({ lanes: updatedLanes });
};

export const getDefaultFilter = (stages) => {
  const stage = stages && stages?.map((stage) => stage.value);
  const filter = {
    expectedCloseDate: {
      operator: DATE_OPERATOR_OPTIONS[0].value,
      fromDate: '',
      toDate: '',
    },
    presalesUser: PRESALES_USER_OPTIONS[0].value,
    revenue: { operator: REVENUE_OPERATOR_OPTIONS[0].value, value: '' },
    stage,
    page: 1,
    size: 30,
  };
  const dateRange = getDateByOperator(DATE_OPERATOR_OPTIONS[0].value);
  filter.expectedCloseDate.fromDate = dateRange.fromDate;
  filter.expectedCloseDate.toDate = dateRange.toDate;
  return filter;
};

export const populateFilterData = (setPipelineFilter, stages) => {
  // Get common pipeline filters
  let filter = {};
  const localStoreData = localStorage.getItem('pipeline_filter');
  if (localStoreData) {
    filter = JSON.parse(localStoreData);
  } else {
    filter = getDefaultFilter(stages);
  }
  setPipelineFilter(filter);
};

export const populateUserSelection = (setUserFilter) => {
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const productRole = isProductUser();
  if (loggedInUser && !productRole) {
    setUserFilter({ label: loggedInUser?.name, value: loggedInUser?.userId });
  }
  if (productRole) {
    setUserFilter({ label: 'All', value: 'all' });
  }
};

export const resetFilters = ({
  setCurrentPage,
  setPipelineFilter,
  setUserFilter,
  stages,
}) => {
  const filter = getDefaultFilter(stages);
  localStorage.removeItem('pipeline_filter');
  const user = JSON.parse(localStorage.getItem('user'));
  const isProduct = isProductUser();
  if (isProduct) {
    setUserFilter({ value: 'all', label: 'All' });
  } else {
    setUserFilter({ value: user?.userId, label: user?.name });
  }
  resetCurrentPage(setCurrentPage);
  setPipelineFilter(filter);
  return filter;
};

export const getCRMStages = (data, setStages) => {
  let stagesDD = [];
  if (Array.isArray(data)) {
    stagesDD = data.map((stage) => {
      if (stage.label === stage.value) {
        return {
          label: `${stage.label}(Preskale)`,
          value: stage.value,
        };
      }
      return stage;
    });
  }
  setStages(stagesDD);
};

export const getParentStage = (
  data,
  {
    setParentStageMap = () => {},
    setFilteredStage = () => {},
    setCurrentPage = () => {},
    currentPage = {},
  }
) => {
  if (!Array.isArray(data)) {
    return;
  }
  let defaultPage = {};
  let pages = {};
  let parentStages = {};
  const lanes = data.map((lane) => {
    parentStages[lane.id] = lane.name;
    defaultPage[lane.id] = currentPage[lane.id] || 1;
    pages[lane.id] = false;
    return {
      id: lane.id,
      header: lane.name,
      name: lane.name,
      loading: 'false',
      currentPage: currentPage[lane.id] || 1,
      cards: [],
    };
  });
  if (Object.keys(defaultPage).length !== 0) {
    setCurrentPage(defaultPage);
  }
  setParentStageMap(parentStages);
  setFilteredStage(lanes);
};

export const getTeamData = (data, setTeamMembers, setUserDropdownList) => {
  let userData = [{ label: 'All', value: 'all' }]; //adding All option for user dropdown
  let teamData = [];
  if (data && data.status && data.data && data.data.length > 0) {
    data.data[0].teamMembers.forEach((user) => {
      let userStructure = {};
      userStructure.label = user.name;
      userStructure.value = user.id;
      teamData.push(userStructure);
      userData.push(userStructure);
    });
  }
  setTeamMembers(teamData);
  setUserDropdownList(userData);
};

export const getAccountByStageSuccess = (
  data,
  lanes,
  laneId,
  prevPage,
  currentPage,
  setCurrentPage,
  filter,
  setBoardData,
  parentStageMap,
  userFilter,
  updateLanes,
  existingUserList
) => {
  // const newLaneData = [...lanes];
  const userName = JSON.parse(localStorage.getItem('user'))?.userName;
  const presalesUser = userFilter ? userFilter.label : userName;
  lanes.forEach((lane) => {
    if (lane.id === laneId) {
      const cards =
        Array.isArray(data) &&
        data.map((card) => {
          let cardDetails = { ...card };
          cardDetails.laneId = laneId;
          if (Array.isArray(cardDetails.collabrator)) {
            cardDetails.collabratedDeal = checkCollabrator(
              existingUserList,
              presalesUser,
              cardDetails.presalesUserDetails?.email
            );
          }
          return cardDetails;
        });
      lane.currentPage =
        data.length === 0 ? lane.currentPage : filter?.page || 1;
      lane.loading = 'false';
      lane.header = parentStageMap[laneId];
      lane.name = parentStageMap[laneId];
      // Append cards to list only when the current page is different from the previous page
      lane.cards =
        prevPage.current[lane.id] && prevPage.current[lane.id] < filter?.page
          ? [...lane.cards, ...cards]
          : filter?.page !== 1 &&
            prevPage.current[lane.id] &&
            prevPage.current[lane.id] === filter?.page
          ? lane.cards
          : cards;
    }
    if (
      lane.id === laneId &&
      prevPage.current[lane.id] &&
      prevPage.current[lane.id] !== filter?.page &&
      Object.keys(currentPage).length > 0 &&
      data.length !== 0
    ) {
      setCurrentPage((prev) => ({
        ...prev,
        [laneId]: filter?.page,
      }));
    }
  });
  if (updateLanes) {
    updateLanes(lanes);
  }
  // console.log('account:lanes', lanes);
  setBoardData({ lanes });
};

export const getType = (dealTypeOptions, setType) => {
  if (dealTypeOptions) {
    const data = JSON.parse(localStorage.getItem('pipeline_filter'));
    if (data?.type) {
      const typeData = [...data?.type];
      typeData?.find((val) => {
        if (val !== 'NO_TYPE') {
          setType((prev) => [...prev, { label: val, value: val }]);
        }
        if (val === 'NO_TYPE') {
          setType((prev) => [...prev, { label: 'No Type', value: 'NO_TYPE' }]);
        }
      });
    } else {
      setType(dealTypeOptions);
    }
  }
};

export const getSalesOwner = (salesOwners, setSalesOwner) => {
  const data = JSON.parse(localStorage.getItem('pipeline_filter'));
  if (data?.salesPerson) {
    const salesOwnerData = [...data?.salesPerson];
    salesOwners?.find((person) => {
      if (salesOwnerData.includes(person.value)) {
        setSalesOwner((prev) => [
          ...prev,
          { label: person.label, value: person.value },
        ]);
      }
    });
  } else {
    salesOwners.find((person) => {
      setSalesOwner((prev) => [
        ...prev,
        { label: person.label, value: person.value },
      ]);
    });
  }
};

export const getSalesStages = (stages, setSalesStages) => {
  const filter = JSON.parse(localStorage.getItem('pipeline_filter'));
  const stagesHashMap = {};
  console.log('stages', stages);
  stages.forEach((stage) => (stagesHashMap[stage.value] = stage.label));
  let filteredStages = [];
  if (filter?.stage) {
    filter?.stage?.forEach((stage) => {
      filteredStages.push({ label: stagesHashMap[stage], value: stage });
    });
    setSalesStages(filteredStages);
  } else {
    setSalesStages(stages);
  }
};

export const getPreskaleStages = (
  preskaleStages,
  setSelectedPreskaleStages
) => {
  const filter = JSON.parse(localStorage.getItem('pipeline_filter'));
  const stageMap = [];
  preskaleStages?.map((data) => {
    stageMap[data?.label] = data?.value;
  });
  if (filter?.preskaleStage) {
    filter?.preskaleStage?.find((stage) => {
      setSelectedPreskaleStages((prev) => [
        ...prev,
        { label: stage, value: stageMap[stage] },
      ]);
    });
  } else {
    setSelectedPreskaleStages(preskaleStages);
  }
};

export const getUsers = (userMapOptions, setUsers) => {
  const data = JSON.parse(localStorage.getItem('pipeline_filter'));
  if (data?.users) {
    const userData = [...data?.users];
    userMapOptions?.find((user) => {
      if (userData.includes(user.value)) {
        setUsers((prev) => [...prev, { label: user.label, value: user.value }]);
      }
    });
  } else {
    userMapOptions.find((user) => {
      setUsers((prev) => [...prev, { label: user.label, value: user.value }]);
    });
  }
};

export const getUserName = (userList, isProductUser) => {
  //product role => default value is All
  //other roles => default value is loggedin user
  const user = JSON.parse(localStorage.getItem('user'));
  const defaultUser = isProductUser
    ? { label: 'All', value: 'all' }
    : userList?.find((data) => data.value === user?.userId);
  return {
    label: defaultUser?.label,
    value: defaultUser?.value,
  };
};

export const getSelectedUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return [{ label: user?.name, value: user?.userName }];
};

export const populateCrmColumnFilterData = (setFilteredCrmFields) => {
  const { getStorageData } = useDataPersistance();
  let columns = {};
  const localStoreData = getStorageData('pipeline_crm_columns');
  if (localStoreData) {
    columns = [...localStoreData];
  } else {
    columns = [{ label: 'All', value: 'ALL' }];
  }
  setFilteredCrmFields(columns);
};
export const populateProductGapFields = (setFilteredPoductGapFields) => {
  const { getStorageData } = useDataPersistance();
  let columns = {};
  const localStoreData = getStorageData('pipeline_productGap_columns');
  if (localStoreData) {
    columns = [...localStoreData];
  } else {
    columns = [{ label: 'All', value: 'ALL' }];
  }
  setFilteredPoductGapFields(columns);
};
export const populateSolutionFields = (setFilteredSolutionFields) => {
  let columns = {};
  const { getStorageData } = useDataPersistance();
  const localStoreData = getStorageData('pipeline_solutions_fields');
  if (localStoreData) {
    columns = [...localStoreData];
  } else {
    columns = [{ label: 'All', value: 'ALL' }];
  }
  setFilteredSolutionFields(columns);
};

export const populateColumnViewData = (dispatch) => {
  const { getStorageData } = useDataPersistance();
  let columns = {};
  const localStorageData = getStorageData('current_active_column');
  if (localStorageData) {
    columns = localStorageData;
  } else {
    columns = { label: 'Notes', value: 'NOTES' };
  }
  dispatch(saveSelectedColumn(columns));
};

export const populateCustomColumnFilterData = (setFilteredCustomFields) => {
  let columns = {};
  const { getStorageData } = useDataPersistance();
  const localStoreData = getStorageData('pipeline_custom_fields');
  if (localStoreData) {
    columns = [...localStoreData];
  } else {
    columns = [{ label: 'All', value: 'ALL' }];
  }
  setFilteredCustomFields(columns);
};

export const checkCollabrator = (
  userList,
  currentUserName,
  presalesUserEmail
) => {
  const isSalesUser = findIsSalesUser();

  if (currentUserName === 'All' || isSalesUser) {
    return false;
  }

  let currentUserEmail;
  if (userList?.entities && userList?.ids) {
    userList?.ids?.forEach((id) => {
      if (userList.entities[id].name === currentUserName) {
        currentUserEmail = userList?.entities[id].email;
        return;
      }
    });
  }
  if (presalesUserEmail !== currentUserEmail) {
    return true;
  }
  return false;
};

export const isSameTeamAsPresalesUser = (
  teams,
  presalesUserEmail,
  userEmail
) => {
  return (
    Array.isArray(teams) &&
    teams.some((team) => {
      let hasPresalesUser = false;
      let hasLoggedInUser = false;
      for (let i = 0; i < team.teamMembers.length; i++) {
        if (team.teamMembers[i].email === presalesUserEmail) {
          hasPresalesUser = true;
        }
        if (team.teamMembers[i].email === userEmail) {
          hasLoggedInUser = true;
        }
      }
      return hasPresalesUser && hasLoggedInUser;
    })
  );
};
