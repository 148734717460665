import React, { useState } from 'react';
import Button from '../../Button';
import { ReactComponent as ModulesSVG } from '../../../Icons/ModulesIcon.svg';
import ModulesFeaturesFilterDrawer from './ModulesFeaturesFilterDrawer';
import ModulesFeaturesFilterDrawerForSolutions from './ModulesFeaturesFilterDrawerSolutions';

const ModulesFeaturesFilter = ({
  filterFor = 'filter',
  name = 'Modules and Features',
  handleSubmit = () => {},
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const handleClose = () => setShowFilter(false);
  const handleFilterToggle = () => {
    if (!showFilter) {
      // * Close library filter if already open
      const libraryFilter = document.getElementsByClassName(
        'preskale-library--filterOverlay'
      );
      if (libraryFilter.length) {
        libraryFilter[0].click();
      }
    }
    setShowFilter((prev) => !prev);
  };
  return (
    <>
      <Button
        type="ghost"
        className="pipeline-filter-btn"
        onClick={handleFilterToggle}
      >
        <div className="flex-align-center gap-default">
          <ModulesSVG />
          {name}
        </div>
      </Button>
      {showFilter &&
        (filterFor === 'productgaps' ? (
          <ModulesFeaturesFilterDrawer
            filterFor={filterFor}
            showFilters={showFilter}
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        ) : (
          <ModulesFeaturesFilterDrawerForSolutions
            filterFor={filterFor}
            showFilters={showFilter}
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        ))}
    </>
  );
};

export default ModulesFeaturesFilter;
