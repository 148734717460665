import React, { useState, useMemo } from 'react';

import { ReactComponent as IdeationSVG } from '../../Icons/NewImage/ideation.svg';

import Dropdown from 'react-dropdown-select';
import Button from '../../common/Button';
import { useEffect } from 'react';
import {
  useAssignPresalesOwnerMutation,
  useFetchBestResourceQuery,
  useRejectRequestMutation,
} from './RAMSlice';
import { useGetUsersQuery } from '../../app/api/usersSlice';
import { toast } from 'react-toastify';
import RequestRejectPopup from './RequestRejectPopup';
import { isAdminOrManager as findIsAdminOrManager } from '../../Utils/common';
import {
  useFetchGetTeamsByManagerQuery,
  useFetchPipelineAccessibilityQuery,
} from '../../app/api/teamsSlice';
import { MultiSelect } from '../../common/MultiSelect';
import {
  MultiValueRemove,
  Option,
  colourStyles,
} from '../../Constant/MultiSelectStylesAndComponents';

const BestResourceView = ({ activeCard, handleResourceFilter, crmType }) => {
  const [presalesEngineer, setPresalesEngineer] = useState([]);
  const [showRejectPopup, setShowRejectPopup] = useState(false);

  const [userDropdownList, setUserDropdownList] = useState([]);
  const isAdminOrManager = findIsAdminOrManager();
  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();
  const {
    data: { newTeams, adminManagerTeams },
  } = useFetchGetTeamsByManagerQuery(undefined, {
    skip: !isAdminOrManager && pipelineAccessibility?.data !== 'user',

    selectFromResult: (response) => {
      const adminManagerTeams = useMemo(() => {
        return response?.data?.data?.map((team) => {
          return {
            value: team?.id,
            label: team?.teamName,
          };
        });
      }, [response?.data]);
      return {
        data: {
          newTeams: response?.data,
          adminManagerTeams,
        },
      };
    },
  });

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => {
      let userDropDownList = [];
      if (data && data.entities) {
        const userIds = data.ids;
        userIds &&
          userIds.forEach((userId) => {
            const dropDownElement = {
              label: data.entities[userId]?.name,
              value: userId,
            };
            userDropDownList.push(dropDownElement);
          });
      }
      const result = { userIdMap: data?.entities, userDropDownList };
      return {
        data: result,
      };
    },
  });

  const { data: bestResourceResponse } = useFetchBestResourceQuery(activeCard, {
    skip: !activeCard?.dealId,
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => ({
      data: data && data.data ? data.data : [],
    }),
  });

  const [savePresalesOwner, { isLoading }] = useAssignPresalesOwnerMutation();
  const [rejectRequest, { rejectRequestLoading }] = useRejectRequestMutation();
  const [selectedTeam, setSelectedTeam] = useState(adminManagerTeams);

  useEffect(() => {
    if (bestResourceResponse?.resourceId) {
      const selectedUser = userMap.userIdMap[bestResourceResponse.resourceId];
      setPresalesEngineer([
        { label: selectedUser.name, value: selectedUser.id },
      ]);
    }
  }, [bestResourceResponse]);

  useEffect(() => {
    if (selectedTeam?.length > 0) {
      let teamUsers = [];
      const teams = newTeams?.data?.filter(({ id: id1 }) =>
        selectedTeam.some(({ value: id2 }) => id2 === id1)
      );
      teams?.map((team) => {
        team?.teamMembers?.map((teamMember) => {
          if (
            teamMember?.active === true &&
            !teamUsers?.some((user) => user?.value === teamMember?.id)
          ) {
            teamUsers.push({
              value: teamMember?.id,
              label: teamMember?.name,
            });
          }
        });
      });
      setUserDropdownList(teamUsers);
      handlePresalesEngineer(teamUsers);
    }
  }, [selectedTeam]);

  const handlePresalesEngineer = (teamUsers) => {
    // check if best resource is available inside selected team
    if (Array.isArray(teamUsers) && teamUsers.length > 0) {
      let user = teamUsers.find(
        (user) => user.value === bestResourceResponse.resourceId
      );
      if (user) {
        setPresalesEngineer([user]);
      } else {
        setPresalesEngineer([teamUsers[0]]);
      }
    }
  };

  const onDropdownSelect = (option) => {
    setPresalesEngineer(option);
  };

  const assignPresalesUser = async () => {
    const presalesOwnerBody = {
      dealId: activeCard.dealId,
      userId: presalesEngineer[0]?.value,
    };
    const data = await savePresalesOwner({
      body: presalesOwnerBody,
    })
      .unwrap()
      .then(() => {
        handleResourceFilter(activeCard.dealId);
        toast.success('Presales User Assigned Successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Presales user not assigned!');
      });
  };

  const requestRejection = (rejectReason) => {
    setShowRejectPopup(false);

    const rejectRequestBody = {
      dealName: activeCard.dealName,
      dealId: activeCard.dealId,
      reasonForRejection: rejectReason,
      crmType: crmType,
      dealOwner: activeCard.ownerId,
    };

    rejectRequest({
      body: rejectRequestBody,
    })
      .unwrap()
      .then(() => {
        handleResourceFilter(activeCard.dealId);
        toast.success('Request has been rejected!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Request not rejected!');
      });
  };

  return (
    <>
      <div className="top-content">
        <div className="flex top-content-split">
          <div className="icon-container">
            <IdeationSVG />
          </div>
          <div className="content-container">
            <div className="best-resource">
              Best Resource based on Availablity for this Opportunity is{' '}
              <span className="resource-highlight">
                {bestResourceResponse?.resourceName}
              </span>
            </div>
            <div className="metrics-container">
              <span className="metrics">
                Deals Managed:{' '}
                <span className="font-bold">
                  {bestResourceResponse?.dealsManaged}
                </span>
              </span>
              <span className="metrics">
                Deals Won:{' '}
                <span className="font-bold">
                  {bestResourceResponse?.dealsWon}
                </span>
              </span>
              <span className="metrics">
                ARPU:{' '}
                <span className="font-bold">${bestResourceResponse?.arpu}</span>
              </span>
              <span className="metrics">
                Probabilty:{' '}
                <span className="font-bold">
                  {bestResourceResponse?.probablity}%
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="top-content-presales-info width-20">
          <div className="presales-header">Teams</div>
          {/* <div className="dropdown-container"> */}
          <MultiSelect
            options={adminManagerTeams}
            value={selectedTeam}
            onChange={(option) => setSelectedTeam(option)}
            components={{ Option, MultiValueRemove }}
            styles={colourStyles}
          />
          {/* </div> */}
        </div>
        <div className="top-content-presales-info">
          <div className="presales-header">Presales Owner</div>
          <div className="dropdown-container">
            <Dropdown
              options={userDropdownList}
              onChange={onDropdownSelect}
              values={presalesEngineer}
              className="presales-dropdown"
            />
            <div className="button-container">
              <Button
                type="primary"
                onClick={assignPresalesUser}
                className="presales-assign-btn"
              >
                {' '}
                Assign{' '}
              </Button>
            </div>
            <div className="button-container">
              <Button
                type="danger"
                onClick={() => setShowRejectPopup(true)}
                className="request-reject-btn"
              >
                {' '}
                Reject{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showRejectPopup && (
        <RequestRejectPopup
          showModal={showRejectPopup}
          setShowModal={setShowRejectPopup}
          requestRejection={requestRejection}
        />
      )}
    </>
  );
};
export default BestResourceView;
