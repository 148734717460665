import React from 'react';

export default function SquareIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className="svg_icon_root"
      viewBox="0 0 16 16"
    >
      <path d="M0.257812 0H15.3182V15.0603H0.257812V0Z" fill={color} />
    </svg>
  );
}
