import React, { useState, useMemo, useLayoutEffect, useRef } from 'react';
import Button from '../../Button';
// import SearchField from '../../../Accounts/commons/SearchField';
import Loader from '../../Loader';
import useDataPersistance from '../../../hooks/useDataPersistance';
import {
  useGetPGItemsByFeatureListQuery,
  useGetProductGapModulesAndItemsQuery,
} from '../../../features/ProductGap/productGapSlice';
import { selectModulesAndItems } from '../../../features/ModulesManager/moduleFeaturesSlice';
import Drawer from '../../Drawer';
import { MultiSelect } from '../../MultiSelect';
import {
  ValueContainer,
  MultiValueRemove,
  useCaseStyles,
  RemoveIndicatorSeparator,
  Option,
} from '../../../Constant/MultiSelectStylesAndComponents';
import { Flex, Space } from 'antd';

const SELECT_STYLES = {
  ...useCaseStyles,
  control: (base) => {
    return {
      ...base,
      borderRadius: '6px',
      minHeight: '30px',
      height: '30px',
    };
  },
};

const ModulesFeaturesFilterDrawer = ({
  filterFor,
  showFilters,
  onCancel = () => {},
  onSubmit = () => {},
  // onReset = () => {},
}) => {
  const handleSubmit = (filters) => {
    onSubmit(filters);
    return filters;
  };
  const filtersChanged = useRef(false);
  const { getStorageData, setStorageData } = useDataPersistance();
  const [modules, setModules] = useState([]);
  const [features, setFeatures] = useState([]);
  const [featureIDList, setFeatureIDList] = useState();
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedPgItems, setSelectedPgItems] = useState([]);

  const handleFilterApply = () => {
    let itemIds = [];
    let pgItemIds = [];
    selectedFeatures?.forEach((feature) => {
      itemIds.push(feature.value);
    });
    selectedPgItems?.forEach((item) => {
      pgItemIds.push(item.value);
    });
    setStorageData('pgUseCases', {
      modules: selectedModules,
      features: selectedFeatures,
      pgItems: selectedPgItems,
      itemIds,
    });
    handleSubmit({
      itemIds,
      productGapItems: pgItemIds,
    });
  };

  const { data: modulesAndItems, isFetching: modulesAndItemsLoading } =
    useGetProductGapModulesAndItemsQuery(undefined, {
      selectFromResult: ({ data, isFetching }) =>
        useMemo(() => {
          let modules = [];
          let selectedModules = [];
          data?.data.forEach((module) => {
            modules.push({
              label: module.name,
              value: module.id,
            });
          });
          setModules(modules);
          const filtersFromLS = getStorageData('pgUseCases');
          if (filtersFromLS?.modules) {
            selectedModules = filtersFromLS?.modules;
            setSelectedModules(filtersFromLS?.modules);
          } else {
            selectedModules = modules;
            setSelectedModules(modules);
          }

          // Set features and selected features
          if (
            Array.isArray(selectedModules) &&
            data?.normalizedData?.entities
          ) {
            let features = [];
            let featureIds = [];
            selectedModules.forEach((module) => {
              let relatedFeatures =
                data?.normalizedData?.entities[module.value]?.pg;
              Array.isArray(relatedFeatures) &&
                relatedFeatures.forEach((feature) => {
                  if (feature.title !== 'New Feature') {
                    featureIds.push(feature.id);
                    features.push({
                      label: feature.title,
                      value: feature.id,
                    });
                  }
                });
            });
            setFeatures(features);
            const filtersFromLS = getStorageData('pgUseCases');
            if (filtersFromLS?.features) {
              let featureIds = [];
              setSelectedFeatures(filtersFromLS?.features);
              filtersFromLS?.features.forEach((feature) =>
                featureIds.push(feature.value)
              );
              setFeatureIDList(featureIds);
            } else {
              setSelectedFeatures(features);
              setFeatureIDList(featureIds);
            }
          }
          return {
            data: {
              data: selectModulesAndItems(data?.data),
              normalizedData: data?.normalizedData,
            },
            isFetching,
          };
        }, [data?.data]),
    });

  const { data: pgItemsList, isFetching: loadingPgItems } =
    useGetPGItemsByFeatureListQuery(
      {
        featureIDList,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !featureIDList,
        selectFromResult: ({ data, isFetching }) => {
          useMemo(() => {
            const filtersFromLS = getStorageData('pgUseCases');
            if (!filtersChanged.current && filtersFromLS?.pgItems) {
              setSelectedPgItems(filtersFromLS?.pgItems);
              return;
            }
            if (!Array.isArray(data?.data)) {
              return;
            }
            setSelectedPgItems(data?.data);
          }, [data]);
          return { data, isFetching };
        },
      }
    );

  const handleReset = () => {
    setStorageData('pgUseCases', {
      modules: undefined,
      features: undefined,
      pgItems: undefined,
      itemIds: undefined,
    });
    let features = [];
    let featureIds = [];
    modules.forEach((module) => {
      let relatedFeatures =
        modulesAndItems?.normalizedData?.entities[module.value]?.pg;
      Array.isArray(relatedFeatures) &&
        relatedFeatures.forEach((feature) => {
          if (feature.title !== 'New Feature') {
            featureIds.push(feature.id);
            features.push({
              label: feature.title,
              value: feature.id,
            });
          }
        });
    });
    setFeatures(features);
    setFeatureIDList(featureIds);
    setSelectedModules(modules);
    setSelectedFeatures(features);
    handleSubmit({
      itemIds: [],
      productGapItems: [],
    });
  };

  // const handleSearch = (event) => {
  //   if (!event.target.value) {
  //     setSearchTerm('');
  //     return;
  //   }
  //   setSearchTerm(event.target.value.toLowerCase());
  // };

  const [hasBanner, setHasBanner] = useState(false);
  useLayoutEffect(() => {
    setHasBanner(document.getElementsByClassName('layout--has-banner').length);
  }, []);

  return (
    <Drawer
      showDrawer={showFilters}
      className={`preskale-modules--filter${hasBanner ? ' has--banner' : ''}`}
      overlayClassName={`preskale-modules--filterOverlay${
        hasBanner ? ' has--banner' : ''
      }`}
      shouldCloseOnOverlayEscClick={onCancel}
      showHeader={false}
    >
      {modulesAndItemsLoading ? (
        <Loader customClass="pg-usecases-filter" />
      ) : (
        <Flex vertical>
          {/* <SearchField
            placeholder="Search Modules and Features"
            onChange={handleSearch}
          /> */}

          <Flex vertical gap="small" className="pl-16 pr-16">
            <label>
              <span className="display-block text-weight-semi-bold mb-5">
                Modules
              </span>
              <MultiSelect
                noDataRenderer={() => noData()}
                options={modules}
                value={selectedModules}
                onChange={(option) => {
                  filtersChanged.current = true;
                  setSelectedModules(option);
                  if (!option.length) {
                    setSelectedFeatures([]);
                    setFeatures([]);
                    setFeatureIDList([]);
                    setSelectedPgItems([]);
                  } else {
                    let features = [];
                    let featureIds = [];
                    option.forEach((module) => {
                      let relatedFeatures =
                        modulesAndItems?.normalizedData?.entities[module.value]
                          ?.pg;
                      Array.isArray(relatedFeatures) &&
                        relatedFeatures.forEach((feature) => {
                          if (feature.title !== 'New Feature') {
                            featureIds.push(feature.id);
                            features.push({
                              label: feature.title,
                              value: feature.id,
                            });
                          }
                        });
                    });
                    setFeatures(features);
                    setSelectedFeatures(features);
                    setFeatureIDList(featureIds);
                  }
                }}
                isSearchable={true}
                components={{
                  ValueContainer,
                  MultiValueRemove,
                  RemoveIndicatorSeparator,
                  Option,
                }}
                styles={SELECT_STYLES}
              />
            </label>
            <label>
              <span className="display-block text-weight-semi-bold mb-5">
                Features
              </span>
              <MultiSelect
                noDataRenderer={() => noData()}
                options={features}
                value={selectedFeatures}
                onChange={(option) => {
                  filtersChanged.current = true;
                  setSelectedFeatures(option);
                  let featureIds = [];
                  option.forEach((feature) => {
                    if (feature.label !== 'New Feature') {
                      featureIds.push(feature.value);
                    }
                  });
                  setFeatureIDList(featureIds);
                }}
                isSearchable={true}
                components={{
                  ValueContainer,
                  MultiValueRemove,
                  RemoveIndicatorSeparator,
                  Option,
                }}
                styles={SELECT_STYLES}
              />
            </label>
            <label>
              <span className="display-block text-weight-semi-bold mb-5">
                Product Gaps
              </span>
              <MultiSelect
                noDataRenderer={() => noData()}
                options={pgItemsList?.data}
                value={selectedPgItems}
                onChange={(option) => {
                  filtersChanged.current = true;
                  setSelectedPgItems(option);
                }}
                isSearchable={true}
                components={{
                  ValueContainer,
                  MultiValueRemove,
                  RemoveIndicatorSeparator,
                  Option,
                }}
                styles={SELECT_STYLES}
                isLoading={loadingPgItems}
              />
            </label>
          </Flex>
          <Flex gap="small" justify="flex-end" className="pl-16 pr-16 mt-20">
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button type="primary" onClick={handleFilterApply}>
              Apply
            </Button>
          </Flex>
          <div className="reset-button-container mt-10">
            <button type="text" className="reset-btn" onClick={handleReset}>
              Reset to Default
            </button>
          </div>
        </Flex>
      )}
    </Drawer>
  );
};

export default ModulesFeaturesFilterDrawer;
