import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { useGetDetailsQuery } from '../app/api/usersSlice';
import {
  useChangePasswordMutation,
  useLoginMutation,
} from '../app/api/userService';
import { useDispatch } from 'react-redux';
import { logoutAndResetState } from '../Utils/common';
import viewIcon from '../Icons/view.svg';
import hideIcon from '../Icons/hide.svg';

const ChangePassword = ({ setActivePage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const passwordPattern =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).*$/;
  const { data: userDetails } = useGetDetailsQuery();
  const [checkOldPassword] = useLoginMutation();
  const [changePassword] = useChangePasswordMutation();
  const [togglePassword, setTogglePassword] = useState({
    oldPassword: true,
    newPassword: true,
    repeatPassword: true,
  });

  const togglePasswordVisibility = (key) => {
    const passwordVisibility = { ...togglePassword };
    passwordVisibility[key] = !passwordVisibility[key];
    setTogglePassword(passwordVisibility);
  };

  const handlePasswordCancel = () => {
    history.push('/user/profile');
    setActivePage('');
  };

  return (
    <div className="profile-change-password">
      <div className="width-50">
        <Formik
          initialValues={{
            password: '',
            newPassword: '',
            repeatPassword: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.password || values.password.length <= 0) {
              errors.password = 'This field is required ';
            }
            if (!values.newPassword || values.newPassword.length <= 0) {
              errors.newPassword = 'This field is required ';
            } else if (values.newPassword.length < 8) {
              errors.newPassword = 'Password should be more than 8 characters ';
            } else if (!passwordPattern.test(values.newPassword)) {
              errors.newPassword =
                'Password should have atleast one number, one special character, and one alphabet';
            }
            if (!values.repeatPassword || values.repeatPassword.length <= 0) {
              errors.repeatPassword = 'This field is required ';
            }
            if (values.repeatPassword !== values.newPassword) {
              errors.repeatPassword = 'Password should match';
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            checkOldPassword({
              username: userDetails?.email,
              password: values.password,
            })
              .unwrap()
              .then(() => {
                changePassword({
                  username: userDetails?.email,
                  password: values.newPassword,
                })
                  .unwrap()
                  .then(() => {
                    toast.success('Password Changed Successfully');
                    logoutAndResetState(dispatch, history);
                  })
                  .catch((err) => {
                    console.log('changepwd:err', err);
                    toast.error('Error: Something went wrong!');
                  })
                  .finally(() => setSubmitting(false));
              })
              .catch((err) => {
                console.log('err', err);
                setSubmitting(false);
                toast.error('Error: Please check your old password');
              });
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <div className="proflie-fields">
                <div className="flex gap-1">
                  <div className="width-full">
                    <div className="form-field">
                      <label htmlFor="password" className="password-label">
                        {' '}
                        Old Password{' '}
                      </label>
                      <div className="password-visibilty-container">
                        <Field
                          type={
                            togglePassword?.oldPassword ? 'password' : 'text'
                          }
                          name="password"
                          placeholder="Enter New Password"
                          className={
                            'form-control input-field' +
                            (errors.password && touched.password
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <button
                          type="button"
                          className="password-visiblity"
                          onClick={() =>
                            togglePasswordVisibility('oldPassword')
                          }
                        >
                          <img
                            alt="View password"
                            fetchpriority="high"
                            src={
                              togglePassword.oldPassword ? viewIcon : hideIcon
                            }
                          />
                        </button>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="newPassword" className="password-label">
                        {' '}
                        New Password{' '}
                      </label>
                      <div className="password-visibilty-container">
                        <Field
                          type={
                            togglePassword?.newPassword ? 'password' : 'text'
                          }
                          name="newPassword"
                          placeholder="Enter New Password"
                          className={
                            'form-control input-field' +
                            (errors.newPassword && touched.newPassword
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <button
                          type="button"
                          className="password-visiblity"
                          onClick={() =>
                            togglePasswordVisibility('newPassword')
                          }
                        >
                          <img
                            alt="View password"
                            fetchpriority="high"
                            src={
                              togglePassword.newPassword ? viewIcon : hideIcon
                            }
                          />
                        </button>
                      </div>
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-field">
                      <label
                        htmlFor="repeatPassword"
                        className="password-label"
                      >
                        {' '}
                        Re-Enter Password{' '}
                      </label>
                      <div className="password-visibilty-container">
                        <Field
                          type={
                            togglePassword?.repeatPassword ? 'password' : 'text'
                          }
                          name="repeatPassword"
                          placeholder="Enter New Password"
                          className={
                            'form-control input-field' +
                            (errors.repeatPassword && touched.repeatPassword
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <button
                          type="button"
                          className="password-visiblity"
                          onClick={() =>
                            togglePasswordVisibility('repeatPassword')
                          }
                        >
                          <img
                            alt="View password"
                            fetchpriority="high"
                            src={
                              togglePassword.repeatPassword
                                ? viewIcon
                                : hideIcon
                            }
                          />
                        </button>
                      </div>
                      <ErrorMessage
                        name="repeatPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-field submit-btn-container">
                  <button
                    type="submit"
                    className="btn btn-secondary mr-10"
                    onClick={handlePasswordCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={isSubmitting || Object.keys(errors).length}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <div className="flex-align-center m-20">
          <div className="change-password-alert-box">
            <p className="alert-label"> Note: </p>
            <p className="pb-10">
              Please make sure your password adheres to the requirements below,
            </p>
            <p>1. Minimum 8 characters</p>
            <p>2. Should include atleast</p>
            <p className="pl-12">
              a. <span className="font-bold">1 Number</span>
            </p>
            <p className="pl-12">
              b. <span className="font-bold">1 Special Character</span> (
              <span className="font-bold">@ # $ % ^ &</span> are the only
              allowed special characters)
            </p>
            <p className="pl-12">
              c. <span className="font-bold">1 Upper-case Alphabet</span>
            </p>
            <p className="pl-12">
              d. <span className="font-bold">1 Lower case Alphabet</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
