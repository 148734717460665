import React, { useEffect, useState } from 'react';
import { isAdminOrManager, isAdminOrManagerOrProduct } from '../Utils/common';
import {
  DATE_OPERATOR_OPTIONS,
  USER_FILTER_OPTIONS,
} from '../Constant/FilterOperators';
import PageLayout from '../Layout/PageLayout';
import Loader from '../common/Loader';
import Influence from './Influence';
import ProductGapAnalytics from './ProductGap';
import PerformanceUtilisation from './PerformanceUtilisation';
import QuickSightDashboard from './QuicksightDashboard';
import { CalendarReporting } from './CalendarReporting';
import CustomSections from './CustomSections';
import useTabMenu from '../hooks/useTabMenu';
import { useFetchAllTeamsQuery } from '../app/api/teamsSlice';
import mixpanel from 'mixpanel-browser';

const Reports = ({ configFeatures, reportsForUser, tabs, reports }) => {
  const {
    data: teams,
    isLoading: teamsLoading,
    isSuccess: teamsSuccess,
  } = useFetchAllTeamsQuery(undefined, {
    skip: !isAdminOrManagerOrProduct(),
  });

  const [reportingFilter, setReportingFilter] = useState({
    dateRange: DATE_OPERATOR_OPTIONS[2].value,
    fromDate: '',
    toDate: '',
  });

  const [userList, setUserList] = useState([]);
  const [selectedUserFilter, setSelectedUerFilter] = useState(
    USER_FILTER_OPTIONS[0].value
  );
  const [filterUserList, setFilterUserList] = useState([]);

  useEffect(() => {
    if (!teams) {
      return;
    }
    getTeamData(teams);
  }, [teamsSuccess]);

  const setUserFilterData = (teamMembers) => {
    teamMembers.map((user) => {
      let userStructure = {};
      userStructure.label = user.name;
      userStructure.value = user.id;
      USER_FILTER_OPTIONS.push(userStructure);
    });
  };
  const getTeamData = (data) => {
    if (data && data.status && data.data && data.data.length > 0) {
      setUserFilterData(data.data[0].teamMembers);
      setUserList(data.data[0].teamMembers);
      setFilterUserList(data.data[0].teamMembers);
    }
  };

  const updateReportingFilter = (filter) => {
    setReportingFilter(filter);
  };

  const changeFilterUserList = (filter) => {
    if (filter === 'ALL_USERS' || filter === 'OVERALL_TREND') {
      setFilterUserList(userList);
    } else {
      userList.map((user) => {
        if (user.id === filter) {
          setFilterUserList([...user]);
        }
      });
    }
  };
  const changeUserFilter = (filter) => {
    setSelectedUerFilter(filter);
  };

  const handleTabClick = (selected) => {
    try {
      mixpanel.track(`reports_${selected.key}_accessed`, {
        event: `Reports ${selected.label}`,
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  const { tabMenu, active } = useTabMenu(tabs, handleTabClick, {
    addSeparator: true,
  });

  return (
    <PageLayout headerTitle="Reports" headerMainContent={tabMenu}>
      {teamsLoading ? (
        <Loader />
      ) : (
        <div className="reports-right-panel">
          {active === 'performance_and_utilization' &&
            configFeatures?.featureList?.is_performance_utilisation_enabled && (
              <PerformanceUtilisation
                reportingFilter={reportingFilter}
                updateReportingFilter={updateReportingFilter}
                isAdminOrManagerOrProduct={isAdminOrManagerOrProduct()}
                userList={userList}
                userFilter={USER_FILTER_OPTIONS}
                selectedUserFilter={selectedUserFilter}
                updateSelectedUserFilter={changeUserFilter}
                filterUserList={filterUserList}
                changeFilterUserList={changeFilterUserList}
              />
            )}
          {active === 'influence' &&
            configFeatures?.featureList?.is_influence_enabled && (
              <Influence
                reportingFilter={reportingFilter}
                updateReportingFilter={updateReportingFilter}
                isAdminOrManagerOrProduct={isAdminOrManagerOrProduct()}
                userList={userList}
                userFilter={USER_FILTER_OPTIONS}
                selectedUserFilter={selectedUserFilter}
                updateSelectedUserFilter={changeUserFilter}
                filterUserList={filterUserList}
                changeFilterUserList={changeFilterUserList}
              />
            )}
          {Array.isArray(reports) &&
            reports.map((report) =>
              report?.module === 'deals_custom_section'
                ? active === 'custom_sections' &&
                  configFeatures?.featureList?.is_custom_section_enabled && (
                    <CustomSections
                      key={report?.module}
                      report={report}
                      presalesOwners={userList}
                    />
                  )
                : null
            )}
          {active === 'manager_analytics' &&
            configFeatures?.featureList?.is_manager_analytics_enabled &&
            reportsForUser?.managerAnalytics &&
            isAdminOrManager() && (
              <QuickSightDashboard dashboardType="manager_dashboard" />
            )}
          {active === 'product_gap' &&
            configFeatures.featureList?.is_product_gap_enabled && (
              <ProductGapAnalytics reports={reports} />
            )}
          {active === 'calendar' && configFeatures?.navPages?.dashboard && (
            <CalendarReporting reports={reports} />
          )}
          {active === 'deal_velocity_reports' &&
            configFeatures.featureList.is_deal_velocity_reports_enabled &&
            reportsForUser?.dealVelocityReports && (
              <QuickSightDashboard dashboardType="stage_flow" />
            )}
          {active === 'productgap_analytics' &&
            configFeatures.featureList?.is_productgap_analytics_enabled &&
            reportsForUser?.productGapAnalytics && (
              <QuickSightDashboard dashboardType="product_gap" />
            )}
          {active === 'solution_analytics' &&
            configFeatures?.featureList.is_solution_analytics_enabled &&
            reportsForUser?.solutionAnalytics && (
              <QuickSightDashboard dashboardType="solutions" />
            )}
          {active === 'calendar_analytics' &&
            configFeatures?.featureList?.is_calendar_analytics_enabled &&
            reportsForUser?.calendarAnalytics && (
              <QuickSightDashboard dashboardType="calendar" />
            )}
        </div>
      )}
    </PageLayout>
  );
};

export default Reports;
