import React from 'react';
import { ReactComponent as ArrowIcon } from '../../Icons/ArrowRight.svg';
import {
  productGapState,
  saveProductGap,
  useFetchRecentlyAddedPgItemsQuery,
} from './productGapSlice';
import { useDispatch, useSelector } from 'react-redux';
import DualRingLoader from '../../common/DualRingLoader';
import { getSelectedTeam } from '../../Pipeline/utils/pipelineUtils';

const ProductGapItemsList = ({
  filters,
  onListItemClick,
  dealId,
  disabledItems = { modules: {}, features: {} },
}) => {
  const dispatch = useDispatch();
  const { selectedProductGap } = useSelector(productGapState);
  const selectedTeam = getSelectedTeam();
  const { data: pgItems, isFetching } = useFetchRecentlyAddedPgItemsQuery(
    { selectedTeam },
    {
      selectFromResult: ({ data, isFetching }) => ({
        data: data?.data ?? [],
        isFetching,
      }),
    }
  );

  const handleListItemClick = (item) => () => {
    dispatch(saveProductGap(item));
    onListItemClick();
  };

  return (
    <ul className="list_container vertical-scroll">
      {isFetching && (
        <div className="flex-center m-2">
          <DualRingLoader dark />
        </div>
      )}
      {Array.isArray(pgItems) &&
        pgItems.map((item) => {
          const alreadyInList =
            Array.isArray(item.pgItemDealMapping) &&
            item.pgItemDealMapping.some((pgItem) => pgItem.dealId === dealId); // * Already in deal
          let showItem = true;
          if (
            item.productGapType &&
            filters.type &&
            filters.type !== item.productGapType
          ) {
            showItem = false;
          }
          if (filters.module && filters.module !== item.module.label) {
            showItem = false;
          }
          if (filters.feature && filters.feature !== item.feature.label) {
            showItem = false;
          }
          if (
            filters.searchTerm &&
            !item.title
              ?.toLowerCase()
              .trim()
              ?.includes(filters.searchTerm.toLowerCase().trim())
          ) {
            showItem = false;
          }
          if (
            disabledItems?.modules?.[item.module?.value] ||
            disabledItems?.features?.[item.feature?.value]
          ) {
            showItem = false;
          }
          return (
            showItem && (
              <span key={item.id}>
                <li className="listItem_root">
                  <div
                    className="listItem"
                    role="button"
                    selected={selectedProductGap?.id === item.id}
                    onClick={
                      !alreadyInList ? handleListItemClick(item) : () => {}
                    }
                  >
                    <div className="listItemText_root">
                      <span className="list_body2">{item.title}</span>
                    </div>
                    <div className="chip_group">
                      {/* CHIP */}
                      {alreadyInList && (
                        <div className="chip_filled_container chip_disabled">
                          <span className="chip_label">Already in deal</span>
                        </div>
                      )}
                      <div className="chip_filled_container">
                        <span className="chip_label">{item.module.label}</span>
                      </div>
                    </div>
                    <ArrowIcon />
                    <span className="ripple"></span>
                  </div>
                </li>
                <hr className="divider" />
              </span>
            )
          );
        })}
      {/* (
        <ListItem
          alignItems="center"
          sx={{
            height: 'inherit',
            maxHeight: '68.4vh',
            justifyContent: 'center',
            typography: 'h5',
            color: 'text.secondary',
          }}
        >
          No results
        </ListItem>
      ) */}
    </ul>
  );
};

export default ProductGapItemsList;
