import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';

import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
const AgGridDatePicker = forwardRef(({ value, ...props }, ref) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const selectedValueRef = useRef();

  useEffect(() => {
    selectedValueRef.current = value;
    setSelectedValue(
      selectedValueRef?.current
        ? new Date(selectedValueRef?.current)
        : selectedValueRef?.current
    );
  }, [value]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        //new Date(null) will give 1970-01-01
        //checking for null before format
        return selectedValueRef?.current
          ? format(new Date(selectedValueRef?.current), 'yyyy-MM-dd')
          : null;
      },
    };
  });

  const handleChange = (value) => {
    selectedValueRef.current = value;
    setSelectedValue(value);
  };

  const onChange = (selectedDate) => {
    handleChange(selectedDate);
  };

  const onSelect = (selectedDate) => {
    handleChange(selectedDate);
    props.stopEditing();
  };

  return (
    <div>
      <DatePicker
        placeholderText="YYYY-MM-DD"
        className="agGrid-DatePicker"
        portalId="root"
        popperClassName="ag-custom-component-popup"
        selected={selectedValue}
        dateFormat="yyyy-MM-dd"
        onChange={onChange}
        onSelect={onSelect}
      />
    </div>
  );
});
export default AgGridDatePicker;
