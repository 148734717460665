import React from 'react';
import { useSelector } from 'react-redux';
import { productGapState } from './productGapSlice';

const AddUpdatePgDisplay = ({ formikValues, avgRevenueBlocked }) => {
  const { selectedProductGap: values } = useSelector(productGapState);
  return (
    <ul className="info_container">
      <li className="info_group">
        <h6 className="info_label">Type</h6>
        <p className="info_description">
          {(values.id && values?.productGapType) ||
            formikValues?.type?.label ||
            '--'}
        </p>
      </li>
      <li className="info_group">
        <h6 className="info_label">Module</h6>
        <p className="info_description">
          {' '}
          {(values.id && values?.module?.label) ||
            formikValues?.module?.label ||
            '--'}
        </p>
      </li>
      <li className="info_group">
        <h6 className="info_label">Feature</h6>
        <p className="info_description">
          {(values.id && values?.feature?.label) ||
            formikValues?.feature?.label ||
            '--'}
        </p>
      </li>
      <li className="info_group">
        <h6 className="info_label">Average Revenue Blocked</h6>
        <p className="info_description">
          {!values?.revenueBlocked &&
          values?.revenueBlocked !== 0 &&
          !avgRevenueBlocked
            ? '--'
            : avgRevenueBlocked
            ? `$${Math.round(avgRevenueBlocked)}`
            : `$${
                values.pgItemDealMapping.length
                  ? Math.round(
                      values?.revenueBlocked / values.pgItemDealMapping.length
                    )
                  : values.pgItemDealMapping.length
              } (${values.pgItemDealMapping.length} Opps)`}
        </p>
      </li>
    </ul>
  );
};

export default AddUpdatePgDisplay;
