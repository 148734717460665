import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAxios from '../../hooks/useAxios';
import CalendarPopover from './CalendarPopover';
import { ReactComponent as LeftArrowSVG } from '../../Icons/ArrowLeft.svg';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';
import { getParentContribution } from '../../_services/common.service';
import { selectSelectedCalendarEvent } from './calendarSlice';
import Button from '../../common/Button';
// import WorkbooksMeetings from '../../common/WorkbooksMeetings';

const CalendarList = React.forwardRef(
  (
    { account, isAccountView, showEventDetails, handleClose = () => {} },
    ref
  ) => {
    const axios = useAxiosPrivate();
    const getContributions = useAxios(axios);
    // const [workbookMeetings, setWorkbookMeetings] = useState([]);
    const [contribution, setContribution] = useState([]);
    const [outcome, setOutcome] = useState([]);
    const [outcomesForDropdown, setOutcomesForDropdown] = useState([]);
    const selectedCalendarEvent = useSelector(selectSelectedCalendarEvent);

    useEffect(() => {
      if (!contribution || contribution.length == 0) {
        getAllContributions(
          selectedCalendarEvent && selectedCalendarEvent.contribution
            ? selectedCalendarEvent.contribution
            : ''
        );
      }
      // updateTodayMeetingAdditionalInfo(selectedCalendarEvent);
    }, [selectedCalendarEvent]);

    // const updateTodayMeetingAdditionalInfo = (updatedEvent) => {
    //   if (!updatedEvent) {
    //     return;
    //   }
    //   const idx = workbookMeetings.findIndex(
    //     (element) => element.id === updatedEvent.id
    //   );
    //   if (idx >= 0) {
    //     const contributionIdx = contribution.findIndex(
    //       (element) => element.value === updatedEvent.contribution
    //     );
    //     if (contributionIdx > -1) {
    //       setOutcome([contribution[contributionIdx]]);
    //     }
    //   }
    // };

    const getAllContributions = async (outcomeId) => {
      const profileData = JSON.parse(localStorage.getItem('user'));
      const companyId = profileData?.companyId;
      if (!companyId) {
        return;
      }
      getParentContribution(getContributions, {
        params: { company_id: companyId },
        handleSuccess: (data) => handleGetAllContributions(data, outcomeId),
      });
    };

    const handleGetAllContributions = (data, outcomeId) => {
      let contribution = [];
      let outcomesForDropdown = [];
      let flag = true;
      data &&
        data.forEach((item) => {
          contribution = contribution.sort((outcome1, outcome2) =>
            outcome1.label?.toLowerCase() > outcome2.label?.toLowerCase()
              ? 1
              : -1
          );
          contribution.push({
            label: item.title,
            value: item.id,
          });

          if (!item.hideFromUI) {
            outcomesForDropdown.push({
              label: item.title,
              value: item.id,
            });
          }

          if (outcomeId !== null && flag && item.id === outcomeId) {
            flag = false;
            outcome.push({
              label: item.title,
              value: item.id,
            });
            setOutcome(outcome);
          }
        });
      setContribution(contribution);
      setOutcomesForDropdown(outcomesForDropdown);
    };

    // const handleMeetingItemClick = (meeting) => {
    //   showEventDetails(meeting);
    // };

    const handleBackToMeeting = () => {
      showEventDetails(null);
    };

    return (
      <>
        {/* {selectedCalendarEvent === null ? (
        <WorkbooksMeetings
          account={account}
          isAccountView={isAccountView}
          handleClick={handleMeetingItemClick}
          workbookMeetings={workbookMeetings}
          setWorkbookMeetings={setWorkbookMeetings}
        />
      ) : ( */}
        <div
          ref={ref}
          className={`today-meeting-indetail ${
            isAccountView ? 'width-full-imp' : ''
          }`}
        >
          {!isAccountView && (
            <div className="flex">
              <Button
                icon
                className="mt-8 mb-8 btn-exit-popover"
                type="link"
                onClick={handleBackToMeeting}
              >
                <LeftArrowSVG />
                <span> Back to meetings </span>
              </Button>
              <div className="ml-auto pr pt-5">
                <Button
                  icon={true}
                  type="ghost"
                  className="btn-icon-only drawer-btn"
                  onClick={handleClose}
                >
                  <CrossSVG />
                </Button>
              </div>
            </div>
          )}
          <section className="calendar-popover-container">
            <CalendarPopover
              account={account}
              eventInfo={selectedCalendarEvent}
              isAccountView={isAccountView}
              contributionFromParent={contribution}
              outcomesForDropdown={outcomesForDropdown}
              handleClose={handleClose}
              showEventDetails={showEventDetails}
            />
          </section>
        </div>
        {/* )} */}
      </>
    );
  }
);

export default CalendarList;
