import React from 'react';

const customNoRowsOverlay = (props) => {
  return (
    <div className="ag-overlay-loading-center" style={{ height: '9%' }}>
      <div className="far fa-frown"> {props.noRowsMessageFunc()}</div>
    </div>
  );
};

export default customNoRowsOverlay;
