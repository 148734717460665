import React, { useState } from 'react';
import SquareIcon from './SquareIcon';

const customIcons = {
  0: {
    icon: <SquareIcon color="#00000042" />,
    label: '',
    legend: '',
  },
  1: {
    icon: <SquareIcon color="#36B37E" />,
    label: 'Requested',
    legend: '(15% of Deal Value)',
  },
  2: {
    icon: <SquareIcon color="#7FCEAC" />,
    label: 'Good to have',
    legend: '(30% of Deal Value)',
  },
  3: {
    icon: <SquareIcon color="#FFBDAD" />,
    label: 'Must have',
    legend: '(45% of Deal Value)',
  },
  4: {
    icon: <SquareIcon color="#EB6868" />,
    label: 'Critical',
    legend: '(60% of Deal Value)',
  },
  5: {
    icon: <SquareIcon color="#E50C0C" />,
    label: 'Deal Breaker',
    legend: '(75% of Deal Value)',
  },
};

export default function ProductGapPulse({
  value,
  onChange,
  onBlur = () => {},
}) {
  const pulseScore = 0;
  const onPulseChange = (e) => {
    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const [hoverValue, setHoverValue] = useState(0);
  const onPulseHoverChange = (value) => {
    setHoverValue(value);
  };
  const onPulseHoverExit = () => {
    setHoverValue(0);
  };
  const pulseColor = (current) => {
    if (hoverValue) {
      return current <= hoverValue ? hoverValue : 0;
    }
    const selectedValue = Number(value);
    if (selectedValue) {
      return current <= selectedValue ? selectedValue : 0;
    }
    return 0;
  };

  return (
    <div className="pg_pulse_root_container">
      <span className="pg_pulse_root">
        {[...Array(5)].map((_, index) => {
          return (
            <span key={`pulse-${index}`}>
              <label
                key={`label-${index}`}
                onMouseEnter={() => onPulseHoverChange(index + 1)}
                onMouseLeave={onPulseHoverExit}
                htmlFor={`pulse-${[index + 1]}`}
                className="pg_pulse_label"
              >
                <span className="pg_pulse_icon pg_pulse_iconEmpty">
                  {customIcons[pulseColor(index + 1)].icon}
                </span>
                <span className="pg_pulse_visuallyHidden">
                  {customIcons[index + 1].label}
                </span>
              </label>
              <input
                key={`field-${index}`}
                className="pg_pulse_visuallyHidden"
                onChange={onPulseChange}
                onBlur={onBlur}
                id={`pulse-${[index + 1]}`}
                type="radio"
                name="rating"
                value={[index + 1]}
              ></input>
            </span>
          );
        })}
      </span>
      {hoverValue ? (
        <div>
          <div className="pg_pulse_description">
            {customIcons[hoverValue ?? 0].label}
          </div>
          {/* <div className="pg_pulse_description">
            {customIcons[hoverValue ?? 0].legend}
          </div> */}
        </div>
      ) : null}
      {hoverValue === 0 && value !== null && (
        <div>
          <div className="pg_pulse_description">
            {customIcons[Number(value) ?? pulseScore].label}
          </div>
          {/* <div className="pg_pulse_description">
            {customIcons[Number(value) ?? pulseScore].legend}
          </div> */}
        </div>
      )}
    </div>
  );
}
