import React from 'react';

const TextField = ({ value, onChange }) => {
  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  };
  return (
    <textarea
      value={value || ''}
      className="mention-wrapper"
      onChange={handleChange}
    />
  );
};

export default TextField;
