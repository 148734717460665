import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedCollaboratorsSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    addCollaborator: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.collaborators.add,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Deals', id: 'All' },
        { type: 'Accounts', id: 'All' },
      ],
    }),

    addExternalContact: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.collaborators.addExternalContact,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Collaborators', id: 'dealContacts' }],
    }),

    fetchDealContacts: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.collaborators.getExternalContactsEmail}?deal_id=${dealId}`;
      },
      transformResponse: (responseData) => {
        return responseData?.data ? responseData?.data : [];
      },
      providesTags: () => [{ type: 'Collaborators', id: 'dealContacts' }],
    }),
  }),
});

export const {
  useAddCollaboratorMutation,
  useAddExternalContactMutation,
  useFetchDealContactsQuery,
} = extendedCollaboratorsSlice;
