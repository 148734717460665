import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import ProductGapPulse from '../../common/ProductGapPulse';
import RichTextEditor from '../../common/RichTextEditor';
import { useFetchProductGapAnalyticsQuery } from '../Solution/solutionSlice';
import { useCheckIsJiraIntegratedQuery } from '../../app/api/usersSlice';
import DualRingLoader from '../../common/DualRingLoader';
import { noData } from '../../Utils/common';
import DOMPurify from 'dompurify';
import { pgSelectStyles } from '../../Constant/ProuductGapDropdowns';

const MainForm = ({ formik, integrationType, dealValue, handleClose }) => {
  const [disableAdd, setDisableAdd] = useState(false);
  const { data: pgAnalyticsData } = useFetchProductGapAnalyticsQuery(
    undefined,
    { selectFromResult: ({ data }) => ({ data: data?.data }) }
  );
  const { data: isJiraIntegrated } = useCheckIsJiraIntegratedQuery(undefined, {
    skip: integrationType !== 'jira',
  });
  useEffect(() => {
    if (integrationType === 'jira' && !isJiraIntegrated) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  }, [isJiraIntegrated]);

  const ratingDealValue = {
    1: '(15% of Deal Value)',
    2: '(30% of Deal Value)',
    3: '(45% of Deal Value)',
    4: '(60% of Deal Value)',
    5: '(75% of Deal Value)',
  };

  return (
    <section className="pg_main_form">
      {disableAdd && (
        <div className="jira_alert_root_container" role="alert">
          <div className="alert_icon">
            <svg
              className="alert_svg_icon_root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="ErrorOutlineIcon"
            >
              <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
            </svg>
          </div>
          <div className="alert_message">
            <div className="alert_title">JIRA is Disconnected</div>Please
            connect with your PreSkale Admin to re-connect and add the Product
            Gap Use-case.
          </div>
        </div>
      )}
      <label className="form_label">
        Add Customer Feedback/ Product Gap Use-case
        <div className="mb-1 mt-4">
          {integrationType === 'jira' ? (
            <textarea
              name="usecase"
              className="mention-wrapper add-pg"
              value={formik.values.usecase || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
            />
          ) : (
            <RichTextEditor
              value={formik.values.usecase || ''}
              onChange={(value) => {
                const sanitizedUsecase = DOMPurify.sanitize(value);
                formik.setFieldValue(
                  'usecase',
                  sanitizedUsecase === '<p><br></p>' ? null : sanitizedUsecase
                );
                if (!sanitizedUsecase || sanitizedUsecase !== '<p><br></p>') {
                  formik.setFieldError('usecase', '');
                }
              }}
            />
          )}
          {formik.touched.usecase && Boolean(formik.errors.usecase) && (
            <span className="form_error">{formik.errors.usecase}</span>
          )}
        </div>
      </label>
      <div className="pg-row">
        {/* PG PULSE */}
        <label className="form_label pg_group">
          Product Gap Pulse
          <ProductGapPulse
            name="rating"
            value={formik.values.rating}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.rating && Boolean(formik.errors.rating) && (
            <span className="form_error">{formik.errors.rating}</span>
          )}
        </label>
        <section className="pg_group">
          <h4 className="form_label">Revenue Impact on the deal</h4>
          <p className="text_body1">
            {dealValue || dealValue === 0
              ? `$${dealValue * 0.15 * formik.values.rating}`
              : '--'}
          </p>
          <div className="pulse_description">
            {ratingDealValue[formik.values.rating]}
          </div>
        </section>
      </div>
      <label className="form_label">
        Competitors
        <CreatableSelect
          components={{
            IndicatorSeparator: () => null,
          }}
          isClearable
          className="form_select"
          styles={pgSelectStyles}
          noOptionsMessage={() => noData()}
          value={formik.values.competitors}
          options={pgAnalyticsData?.competitors ?? []}
          onChange={(option) => {
            if (option.length === 0) {
              formik.setFieldValue('competitors', []);
              return;
            }
            formik.setFieldValue('competitors', option);
          }}
          isMulti
          menuPlacement="auto"
        />
      </label>
      <label className="form_label">
        Persona Impacted
        <CreatableSelect
          components={{
            IndicatorSeparator: () => null,
          }}
          isClearable
          className="form_select"
          styles={pgSelectStyles}
          noOptionsMessage={() => noData()}
          value={formik.values.impactsWhom}
          options={pgAnalyticsData?.impactsWhom ?? []}
          onChange={(option) => {
            if (option.length === 0) {
              formik.setFieldValue('impactsWhom', []);
              return;
            }
            formik.setFieldValue('impactsWhom', option);
          }}
          isMulti
          menuPlacement="auto"
        />
      </label>
      {(formik.touched.type && formik.errors.type) ||
      (formik.touched.module && formik.errors.module) ||
      (formik.touched.feature && formik.errors.feature) ? (
        <div className="jira_alert_root_container" role="alert">
          <div className="alert_icon">
            <svg
              className="alert_svg_icon_root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="ErrorOutlineIcon"
            >
              <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
            </svg>
          </div>
          <div className="alert_message">
            Please select a product gap on the left
          </div>
        </div>
      ) : null}

      <div className="button_group">
        <button className="btn_cancel" onClick={handleClose} type="button">
          Cancel
        </button>
        <button
          disabled={disableAdd || formik.isSubmitting}
          className="btn_submit"
          type="submit"
        >
          {formik.isSubmitting ? <DualRingLoader /> : 'Submit'}
        </button>
      </div>
    </section>
  );
};

export default MainForm;
