import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';
import { convertHTMLtoPlainText } from '../../Utils/common';
import Button from '../../common/Button';
import {
  useAddNoteMutation,
  useUpdateNoteMutation,
} from '../../common/notes/notesSlice';

const AgGridQuillEditor = forwardRef((props, ref) => {
  const quillRef = useRef();
  const btnRef = useRef();
  const [savedValue, setSavedValue] = useState();
  const [text, setText] = useState(props.value);
  const newNoteObject = useRef();
  const loggedInUser = JSON.parse(localStorage.getItem('user'));

  const [updateNote, { isLoading: updatingNote }] = useUpdateNoteMutation();
  const [addNote, { isLoading: addingNote }] = useAddNoteMutation();

  const handleChange = (content) => {
    setText(content);
  };

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current.focus();
    }
  }, []);

  const onSave = () => {
    setSavedValue(text);
    const sanitizedValue = DOMPurify.sanitize(text);
    const plainText = convertHTMLtoPlainText(sanitizedValue);
    if (props?.addNote && plainText) {
      const data = {
        userId: loggedInUser.userId,
        accountId: props.data.accountId,
        dealName: props?.data?.name,
        dealId: props?.data?.dealId,
        description: sanitizedValue,
        plainText: plainText,
      };

      addNote({
        body: data,
      })
        .unwrap()
        .then((res) => {
          if (!res?.data) {
            toast.error(res?.message);
          } else {
            newNoteObject.current = res.data;
            toast.success('Note added successfully!');
          }
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Note not added!');
        })
        .finally(() => {
          props.stopEditing();
        });
      return;
    }
    if (!props.addNote && plainText) {
      const data = {
        id: props.data.notes.id,
        dealId: props.data.dealId,
        description: sanitizedValue,
        plainText: plainText,
      };
      updateNote({
        body: data,
      })
        .unwrap()
        .then(() => {
          toast.success('Note updated successfully!');
        })
        .catch((error) => {
          console.warn('Error:', error);
          toast.error('Error: Note not updated!');
        })
        .finally(() => {
          props.stopEditing();
        });
      return;
    }
    props.stopEditing();
  };

  const onCancel = () => {
    props.stopEditing();
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      // When new note is created, Object received from addNote api response is sent to value setter
      // to update the ui.
      // If existing note is updated, updated description is sent.
      // newNoteObject : type object
      // savedValue : type string
      return props.addNote
        ? newNoteObject.current
        : savedValue
        ? savedValue
        : props.value;
    },
  }));

  return (
    <div className="Modal desp-confirm-box add-note fixed mt-50">
      <div className="modal-header">
        <h4 className="modal-title">{`${
          props?.addNote ? 'Add' : 'Update'
        } Note`}</h4>
      </div>
      <div className="modal-body form">
        <div className="form-field">
          <div className="description-container">
            <div className="add-notes-description ag-custom-component-popup">
              <ReactQuill value={text} onChange={handleChange} ref={quillRef} />
            </div>
          </div>
          <div className="form-field-group gap-default mt-4">
            <div className="form-field">
              <label> Note Owner </label>
              <input
                className="disabled"
                type="text"
                disabled
                value={
                  props?.data?.notes && props.data?.notes[0]?.createdBy
                    ? props.data?.notes[0]?.createdBy
                    : loggedInUser?.name
                }
              />
            </div>
            <div className="form-field">
              <label> Created Date </label>
              <ReactDatePicker
                className="disabled"
                disabled
                selected={
                  props?.data?.notes && props.data?.notes[0]?.createdDate
                    ? new Date(props.data?.notes[0]?.createdDate)
                    : new Date()
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer flex-justify-end">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          className="ml-5"
          onClick={onSave}
          disabled={updatingNote || addingNote}
          loading={updatingNote || addingNote}
        >
          Add
        </Button>
      </div>
      <button ref={btnRef}></button>
    </div>
  );
});

export default AgGridQuillEditor;
