import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Redirect, useLocation } from 'react-router-dom';
import {
  useLazyGetUserDetailsQuery,
  useRegisterUserQuery,
} from '../app/api/userService';
import { useLazyFetchCompanyDetailsQuery } from '../Company/companySlice';
import authenticationService from '../_services/authentication.service';
import { setCredentials, setUser } from '../Authorize/authSlice';
import { useDispatch } from 'react-redux';
import PreskaleLogo from '../Icons/logo_full.svg';
import styles from '../styles/pages/callback.module.scss';

const SSOCallback = ({ integration, text = 'Verifying User...' }) => {
  const location = useLocation();
  const { data, error, isSuccess } = useRegisterUserQuery(
    { code: location?.search },
    { skip: !location?.search }
  );
  const [getCurrentUser] = useLazyGetUserDetailsQuery();
  const [getCompanyDetails] = useLazyFetchCompanyDetailsQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      loginSuccess(data);
    }
  }, [data]);

  useEffect(() => {
    console.warn('error', error);
    toast.error(error?.data?.error || error?.error);
  }, [error]);

  const loginSuccess = async (response) => {
    if (response.data.idToken) {
      localStorage.setItem('idToken', response.data.idToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('companyId', response.data.companyId);
      dispatch(
        setCredentials({
          idToken: response.data.idToken,
          refreshToken: response.data.refreshToken,
          companyId: response.data.companyId,
        })
      );
      await getCurrentUser()
        .unwrap()
        .then((response) => {
          localStorage.setItem('user', JSON.stringify(response));
          localStorage.setItem('authenticated', response.authenticated);
          dispatch(setUser(response));
          authenticationService.setUserDetails();
          let companyId = response?.data?.companyId;
          getCompanyDetails()
            .unwrap()
            .then((data) => {
              let isCompanyOnMixPanel;
              try {
                isCompanyOnMixPanel = mixpanel
                  .get_group('company', companyId)
                  .set_once('Company_name', data?.data?.name);
              } catch {
                isCompanyOnMixPanel = false;
              }
              if (!isCompanyOnMixPanel && companyId) {
                try {
                  mixpanel
                    .add_group('company', companyId)
                    .set_once('Company_name', data?.data?.name);
                } catch {
                  console.error('Cannot add group to mixpanel.');
                }
              }
              let isCompanyAUserProp;
              try {
                isCompanyAUserProp = mixpanel.get_property('company');
              } catch {
                isCompanyAUserProp = false;
              }
              if (!isCompanyAUserProp) {
                try {
                  mixpanel
                    .set_group('company', companyId)
                    .set_once('Company_name', data?.data?.name);
                } catch {
                  console.error('Cannot set group.');
                }
              }
            });
          try {
            mixpanel.track('user_login', { event: 'User logged in' });
          } catch {
            console.error('Mixpanel error.');
          }
        });
    }
  };

  return isSuccess && data?.data?.idToken ? (
    <Redirect to="/" />
  ) : (
    <div className={styles.redirect_container}>
      <section className={styles.logo_container}>
        <img alt="Preskale Logo" fetchpriority="high" src={PreskaleLogo} />
      </section>
      <div className={styles.content_container}>
        <div className={styles.message}>
          <span className={styles.highlight}>Note : </span>
          Verifying User, Please wait while we redirect you.
        </div>
        <div className={styles.help_content}>
          Need assistance setting this up? Please reach out to us at{' '}
          <span className={styles.email}>support@preskale.com</span>
        </div>
      </div>
    </div>
  );
};

export default SSOCallback;
