import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useLazyUpdateUserCountQuery } from './adminOpsSlice';
import { useLazyActivateOrDeactivateCompanyQuery } from '../app/api/userService';
import useDataPersistance from '../hooks/useDataPersistance';
import Button from '../common/Button';
import ToolTip from '../common/ToolTip';
import UserPreferences from './UserPreferences';
import { STATUS_OPTIONS } from './utils/AdminOpsConstants';
import { ReactComponent as EditSVG } from '../Icons/NewImage/edit.svg';
import { ReactComponent as SaveSVG } from '../Icons/save.svg';
import { ReactComponent as CancelSVG } from '../Icons/cancel.svg';
import { ReactComponent as RedirectSVG } from '../Icons/NewImage/redirect.svg';

export const AdminOpsCard = ({
  companyData,
  setCompanyStatus,
  companyStatus,
}) => {
  const { setStorageData } = useDataPersistance();
  const [numLicenses, setNumLicenses] = useState(companyData?.noOfUsers || '');
  const [isEditing, setIsEditing] = useState(false);
  const [openUserPreference, setOpenUserPreference] = useState(false);

  const [updateUserCount] = useLazyUpdateUserCountQuery();
  const [activateDeactivateCompany, { isFetching }] =
    useLazyActivateOrDeactivateCompanyQuery();

  const onStatusChange = (selectedOption) => {
    toast.info('Updating company and user status');
    activateDeactivateCompany({
      companyId: companyData.id,
      status: selectedOption.value,
    })
      .unwrap()
      .then(() => {
        let data = companyData;
        data.status = selectedOption?.value;
        setStorageData('companyData', data);
        setCompanyStatus(selectedOption);
        toast.success('Updated Successfully');
      })
      .catch((error) => {
        console.log('error:', error);
        toast.error('Update failed');
      });
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    updateUserCount({
      companyId: companyData?.id,
      count: numLicenses,
    })
      .unwrap()
      .then(() => {
        let data = companyData;
        data.noOfUsers = numLicenses;
        setStorageData('companyData', data);
        toast.success('Updated Successfully');
      });
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    setNumLicenses(companyData?.noOfUsers || '');
  };
  const customStyles = {
    control: (base, { isFocused }) => {
      return {
        ...base,
        border: `1px solid ${isFocused ? '#447bf2' : '#e2ebf3'}`,
        '&:hover': {
          outline: '1px solid #e2ebf3',
        },
      };
    },
  };
  return (
    <>
      <div className="admin-ops-card">
        <div className="section-container">
          <div className="section">
            <div className="flex width-full">
              <div className="width-50 flex-align-center">Company ID :</div>
              <div className="font-bold flex-align-center col-gap">
                <div>{companyData.id}</div>
                <ToolTip text="User Preference & Permissions">
                  <Button
                    icon
                    type="ghost"
                    onClick={() => setOpenUserPreference(!openUserPreference)}
                    className="btn-icon-only cal-redirect"
                  >
                    <RedirectSVG />
                  </Button>
                </ToolTip>
              </div>
            </div>
            <div className="flex width-full mt-20">
              <div className="width-50 flex-align-center">Type :</div>{' '}
              <div className="font-bold">{companyData?.instance}</div>
            </div>
          </div>
          <div className="section mt-20">
            <div className="flex width-full">
              <div className="width-50 flex-align-center">
                Number of Licenses :
              </div>
              <div className="flex">
                {isEditing ? (
                  <input
                    type="number"
                    className="number-of-users mr-10"
                    value={numLicenses}
                    onChange={(e) => setNumLicenses(e.target.value)}
                  />
                ) : (
                  <div className="flex-align-center mr-30">{numLicenses}</div>
                )}
                <div className="button-container col-gap">
                  {isEditing ? (
                    <>
                      <Button
                        icon
                        className="btn-icon-only action-btn"
                        type="ghost"
                        onClick={handleSaveClick}
                      >
                        <SaveSVG />
                      </Button>
                      <Button
                        icon
                        className="btn-icon-only action-btn"
                        type="ghost"
                        onClick={handleCancelClick}
                      >
                        <CancelSVG />
                      </Button>
                    </>
                  ) : (
                    <Button
                      icon
                      className="btn-icon-only"
                      type="ghost"
                      onClick={handleEditClick}
                    >
                      <EditSVG />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex width-full mt-15">
              <div className="width-50 flex-align-center">Status :</div>{' '}
              <Select
                options={STATUS_OPTIONS}
                value={companyStatus}
                onChange={onStatusChange}
                isDisabled={isFetching}
                styles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
      <UserPreferences
        companyId={companyData?.id}
        openUserPreference={openUserPreference}
        setOpenUserPreference={setOpenUserPreference}
      />
    </>
  );
};
