import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import IntegrationCard from './IntegrationCard';
import { PRODUCT_INTEGRATIONS } from './constants';
import { useFetchCompanyDetailsQuery } from '../../Company/companySlice';
import { useLazyProductIntegrationDisconnectQuery } from './integrationsSlice';
import { useGetDetailsQuery } from '../../app/api/usersSlice';
import { CustomAgGridLoader } from '../../features/calendar/CustomAgGridLoader';
import { AgGridReact } from 'ag-grid-react';
import AgGridTooltip from '../../common/AgGridTooltip';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSaveJiraAuthenticationMutation } from '../../app/api/dataEngineeringService';
import { toast } from 'react-toastify';

export const PasswordInput = forwardRef((props, ref) => {
  const inputValue = props.data?.value;
  const [value, setValue] = useState(inputValue);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
  }));
  return (
    <input
      className="width-full br-none"
      type="password"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
});

const ProductIntegrations = ({ configFeatures }) => {
  const { data: companyInformation } = useFetchCompanyDetailsQuery();
  const [enabledProductIntegrations, setEnableProductIntegrations] = useState(
    []
  );
  const [productIntegrationDetails, setProductIntegrationDetails] = useState(
    {}
  );
  const [handleProductIntegrationDisconnect] =
    useLazyProductIntegrationDisconnectQuery();
  const { data: userDetails } = useGetDetailsQuery();
  const gridRef = useRef();
  const [jiraAuthentication, setJiraAuthentication] = useState([]);
  const [existingJiraDetails, setExistingJiraDetails] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saveJiraAuthentication] = useSaveJiraAuthenticationMutation();
  const profileData = JSON.parse(localStorage.getItem('user'));
  const companyId = profileData?.companyId;

  useEffect(() => {
    if (userDetails?.connections?.length) {
      const productConnections = {};
      userDetails?.connections.forEach((connection) => {
        if (PRODUCT_INTEGRATIONS[connection?.type]) {
          productConnections[connection.type] = connection;
        }
      });
      setProductIntegrationDetails(productConnections);
    }
  }, [userDetails]);

  useEffect(() => {
    const enabledIntegrations = [];
    if (
      configFeatures?.productGapIntegration &&
      Object.keys(configFeatures?.productGapIntegration).length
    ) {
      configFeatures?.productGapIntegration.map((integration) => {
        if (integration.is_enabled) {
          enabledIntegrations.push(PRODUCT_INTEGRATIONS[integration?.type]);
        }
      });
      setEnableProductIntegrations(enabledIntegrations);
    }
  }, [configFeatures]);

  useEffect(() => {
    const errorList = [];
    const index = jiraAuthentication.findIndex(
      (option) => option.type === 'jira_email'
    );

    if (jiraAuthentication && jiraAuthentication.length) {
      jiraAuthentication.forEach((field) => {
        if (!field.value) {
          const message = `${field.label} is required`;
          const includes = errorList.includes(message);
          if (!includes) {
            errorList.push(message);
          }
        } else if (
          field.type === 'jira_email' &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            jiraAuthentication[index]?.value
          )
        ) {
          const message = 'Enter a valid email id';
          const includes = errorList.includes(message);
          if (!includes) {
            errorList.push(message);
          }
        }
      });
    }
    setErrors(errorList);
  }, [jiraAuthentication]);

  useEffect(() => {
    if (
      configFeatures?.productGapIntegration &&
      Object.keys(configFeatures?.productGapIntegration).length &&
      companyInformation?.data
    ) {
      const JIRA_CONFIG = PRODUCT_INTEGRATIONS['jira']?.fields;
      const jiraDetails = {};
      JIRA_CONFIG.forEach((field) => {
        field.value =
          field.type === 'api_key'
            ? companyInformation.data.jiraApiKey
            : companyInformation.data.jiraAdmin;
        jiraDetails[field.type] =
          field.type === 'api_key'
            ? companyInformation.data.jiraApiKey
            : companyInformation.data.jiraAdmin;
      });
      const indx = configFeatures.productGapIntegration.findIndex(
        (integration) => integration?.type === 'jira'
      );
      if (indx > -1 && configFeatures.jiraAuthentication === 'apiKey') {
        setJiraAuthentication(JIRA_CONFIG);
        setExistingJiraDetails(jiraDetails);
      }
    }
  }, [configFeatures, companyInformation]);

  const onDisconnect = ({ type }) => {
    handleProductIntegrationDisconnect({ type })
      .unwrap()
      .then((result) => {
        // handleCrmDetails();
      });
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 75,
      suppressMovable: true,
    }),
    []
  );

  const columnDefs = [
    {
      field: 'label',
      minWidth: 200,
      maxWidth: 600,
      headerName: 'Field Name',
      tooltipField: 'Field Name',
    },
    {
      field: 'value',
      headerName: 'Details',
      tooltipField: 'Details',
      minWidth: 200,
      maxWidth: 600,
      editable:
        !Object.keys(existingJiraDetails).length ||
        !existingJiraDetails['api_key'] ||
        !existingJiraDetails['jira_email'],
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          value: params.value,
        };
      },
      cellEditorSelector: (params) => {
        return {
          component:
            params?.data?.type === 'api_key'
              ? PasswordInput
              : 'agTextCellEditor',
          popup: !!params?.node?.isSelected(),
        };
      },
      valueSetter: (params) => {
        if (
          params.newValue.label &&
          params.oldValue !== params.newValue?.label
        ) {
          const newData = { ...params.data };
          newData.value = params.newValue.label;
          params.node.setData(newData);
          return true;
        }
      },
      cellRenderer: (params) => {
        return params?.data?.type === 'api_key' &&
          params?.data?.value?.length > 0
          ? '**********************'
          : params.data?.value;
      },
    },
  ];

  const onCellEditingStopped = (params) => {
    if (params.newValue && params.oldValue !== params?.newValue?.label) {
      const index = jiraAuthentication.findIndex(
        (option) => option.label === params?.data?.label
      );
      if (index > -1) {
        const fields = [...jiraAuthentication];
        fields[index] = {
          label: params?.data?.label,
          value: params?.newValue,
          type: params?.data?.type,
        };
        setJiraAuthentication(fields);
      }
    }
  };

  const onClick = () => {
    setSubmitted(true);
    const body = {
      companyId,
      jiraAdminEmail: '',
      apiKey: '',
    };
    jiraAuthentication.forEach((field) => {
      if (field.type === 'api_key') {
        body['apiKey'] = field.value;
      }
      if (field.type === 'jira_email') {
        body['jiraAdminEmail'] = field.value;
      }
    });
    saveJiraAuthentication(body)
      .unwrap()
      .then(() => {
        toast.success('Successfully Saved');
      });
  };

  return (
    <div className="integration__wrapper">
      {enabledProductIntegrations.map((integration) => (
        <div key={integration.type}>
          <div className="integrations-card__container" key={integration.type}>
            <IntegrationCard
              companyInfo={companyInformation?.data}
              integration={integration}
              adminConnections={productIntegrationDetails[integration.type]}
              handleDisconnect={onDisconnect}
              enableJiraNote={
                integration.type === 'jira' &&
                configFeatures?.jiraAuthentication === 'apiKey'
              }
            />
          </div>
          {integration.type === 'jira' &&
          configFeatures?.jiraAuthentication === 'apiKey' ? (
            <div className="primary-field__table">
              <div className="flex justify-space-between">
                <div className="heading">
                  <h4>JIRA Integration via API Key</h4>
                </div>
                <div>
                  {errors?.length
                    ? errors.map((error, index) => {
                        return (
                          <span className="font-red pr-5" key={error}>
                            {error} {index !== errors.length - 1 ? ',' : ''}
                          </span>
                        );
                      })
                    : null}
                  <button
                    className={`btn btn-primary ${
                      errors.length ||
                      (existingJiraDetails['api_key'] &&
                        existingJiraDetails['jira_email']) ||
                      submitted
                        ? 'disable'
                        : ''
                    }`}
                    onClick={onClick}
                    disabled={
                      errors.length ||
                      (existingJiraDetails['api_key'] &&
                        existingJiraDetails['jira_email']) ||
                      submitted
                    }
                  >
                    Confirm Details
                  </button>
                </div>
              </div>
              <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  ref={gridRef}
                  domLayout={'autoHeight'}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableCellTextSelection={true}
                  rowData={jiraAuthentication}
                  singleClickEdit="true"
                  onCellEditingStopped={onCellEditingStopped}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductIntegrations;
