import React, { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Button from '../../common/Button';
import { getUsersLabelValuePair } from '../utils/adminopsUtils';
import { MultiSelect } from '../../common/MultiSelect';
import { Option } from '../../Constant/MultiSelectStylesAndComponents';
import {
  extendedAdminOpsSlice,
  useUpdateNotificationCenterMutation,
} from '../adminOpsSlice';

const DealBreakerNotification = forwardRef(
  ({ userPreferences, customStyles, companyId }, ref) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [updateNotificationCenter] = useUpdateNotificationCenterMutation();

    useEffect(() => {
      if (userPreferences) {
        const userLabelValuePair = getUsersLabelValuePair(userPreferences);
        setUsers(userLabelValuePair);
        const filteredUsersForDealBreaker = [];
        userLabelValuePair?.map((data) => {
          if (data?.sendDealBreakerNotification) {
            filteredUsersForDealBreaker.push(data);
          }
        });
        setSelectedUsers(filteredUsersForDealBreaker);
      }
    }, [userPreferences]);

    const onSave = () => {
      toast.info('Updating notification center');
      const usersForDealBreakerNotification = selectedUsers.map(
        (data) => data?.value
      );
      const body = {
        companyId: companyId,
        usersForDealBreakerNotification: usersForDealBreakerNotification,
        notification: 'deal_breaker',
      };
      updateNotificationCenter(body)
        .then((data) => {
          toast.success('Updated successfully!');
          dispatch(
            extendedAdminOpsSlice.util.invalidateTags([
              { type: 'AdminOps', id: 'getAllUserPreference' },
            ])
          );
        })
        .catch((error) => {
          toast.error('Update failed!');
          console.log('error:', error);
        });
    };

    return (
      <div ref={ref} className="mb-20">
        <h1 className="text-weight-semi-bold text-lg font-primary">
          Deal Breaker Notification
        </h1>
        <div className="mt-10">User list who will get the email</div>
        <div className="mt-10">
          <MultiSelect
            className="Dropdown-root"
            options={users}
            value={selectedUsers || []}
            onChange={setSelectedUsers}
            components={{ Option }}
            styles={customStyles}
          />
          <div className="mt-10">
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default DealBreakerNotification;
