import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CustomForm from '../features/CustomSections/CustomForm';
import PresentationView from '../features/DealPresentationView/PresentationView';
import useAxios from '../hooks/useAxios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
  getSectionByType,
  isRequestFormSubmitted,
} from '../_services/common.service';

const Index = () => {
  const [section, setSection] = useState({});
  const axios = useAxiosPrivate();
  const { loading: sectionLoading, ...sectionService } = useAxios(axios);
  const [deal, setDeal] = useState({});
  const { accountId, dealId } = useParams();
  const [isRequestSubmitted, setRequestSubmitted] = useState(false);

  useEffect(() => {
    isRequestFormSubmitted(sectionService, accountId, dealId, handleSuccess);
    const dealInformation = { dealId, accountId };
    setDeal(dealInformation);
  }, []);

  const handleSuccess = (response) => {
    if (response && response.data) {
      setRequestSubmitted(response.data);
    } else {
      getSectionByType(sectionService, handleSectionSuccess);
    }
  };

  const handleSectionSuccess = (response) => {
    if (response.data) {
      setSection(response.data);
    }
  };

  const handleSaveCustomFieldSuccess = () => {
    setRequestSubmitted(true);
  };

  return (
    <>
      {!isRequestSubmitted ? (
        <CustomForm
          section={section}
          deal={deal}
          crmType={'SALESFORCE'}
          customClassName="custom-form"
          handleSaveCustomFieldSuccess={handleSaveCustomFieldSuccess}
        />
      ) : (
        <PresentationView dealId={dealId} showHeader salesforceView />
      )}
    </>
  );
};
export default Index;
