import { extendedTaskSliceUtil } from './tasksSlice';
import { DateFormat } from '../../Utils/DayJs';
import isBefore from 'date-fns/isBefore';
import { extendedAccountsSlice } from '../../Accounts/accountsSlice';
import { Flex, Typography } from 'antd';

const { Text } = Typography;

// Task Slice Utils
export const updateTasks =
  (status) =>
  async ({ body }, { dispatch, queryFulfilled, getState }) => {
    const bodyCopy = { ...body };
    const cache = getState();
    let patchResultRecentTasks;
    let patchResultAccountTasks;
    let patchResultTaskManager;
    const patchDraft = (draft) => {
      if (draft.entities[body?.id]) {
        const isOverdue = isBefore(
          new Date(bodyCopy?.dueDate),
          new Date().setHours(0, 0, 0, 0)
        );
        Object.assign(
          draft.entities[body?.id],
          status === 'COMPLETED'
            ? {
                ...body,
                status,
              }
            : {
                ...body,
                dueDate: bodyCopy.dueDate,
                status: isOverdue ? 'OVERDUE' : 'OPEN',
              }
        );
      }
    };
    patchResultRecentTasks = dispatch(
      extendedTaskSliceUtil.updateQueryData(
        'fetchRecentTasks',
        cache.tasks.recentArgs,
        patchDraft
      )
    );
    patchResultAccountTasks = dispatch(
      extendedTaskSliceUtil.updateQueryData(
        'fetchTasksForAccount',
        cache.tasks.accountArgs,
        patchDraft
      )
    );
    patchResultTaskManager = dispatch(
      extendedTaskSliceUtil.updateQueryData(
        'fetchTasksForTaskManager',
        cache.tasks.taskManagerArgs,
        patchDraft
      )
    );
    let patchResultActivity;
    if (bodyCopy?.status === 'COMPLETED') {
      patchResultActivity = dispatch(
        extendedAccountsSlice.util.updateQueryData(
          'fetchAccountSummary',
          {
            accountId: body?.accountId,
            dealId: body?.dealId,
          },
          (draft) => {
            draft.completedTasks = draft.completedTasks + 1;
          }
        )
      );
    }
    if (!status && body.timeSpent === 0) {
      patchResultActivity = dispatch(
        extendedAccountsSlice.util.updateQueryData(
          'fetchAccountSummary',
          {
            accountId: body?.accountId,
            dealId: body?.dealId,
          },
          (draft) => {
            draft.completedTasks = draft.completedTasks - 1;
          }
        )
      );
    }
    try {
      await queryFulfilled;
      if (status !== 'COMPLETED') {
        dispatch(
          extendedTaskSliceUtil.invalidateTags([
            { type: 'Tasks', id: 'Tasks_TaskManager' },
          ])
        );
      }
      if (status === 'COMPLETED') {
        dispatch(
          extendedTaskSliceUtil.invalidateTags([
            { type: 'Tasks', id: 'Tasks_TaskManager' },
            { type: 'Tasks', id: 'Tasks_Recent' },
          ])
        );
      }
      if (bodyCopy.dueDate) {
        dispatch(
          extendedTaskSliceUtil.invalidateTags([
            { type: 'Tasks', id: 'Tasks_Recent' },
          ])
        );
      }
    } catch {
      patchResultRecentTasks?.undo();
      patchResultAccountTasks?.undo();
      patchResultTaskManager?.undo();
    }
  };

export const renderDetails = (
  allAccounts,
  accountSummary,
  accountId,
  dealId
) => (
  <>
    <Flex vertical>
      <Text strong type="secondary">
        Account
      </Text>
      <Text strong>
        {allAccounts
          ? allAccounts?.find((data) => data.value === accountId)?.label
          : '--x--'}
      </Text>
    </Flex>
    <Flex vertical>
      <Text strong type="secondary">
        Opportunity
      </Text>
      <Text strong>
        {accountSummary
          ? accountSummary?.find((data) => data.value === dealId)?.label
          : '--x--'}
      </Text>
    </Flex>
  </>
);
