import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { noData } from '../../Utils/common';

const UserDefinedDropdownField = ({ value, values, onChange }) => {
  const handleChange = (options) => {
    if (typeof onChange === 'function') {
      onChange(options);
    }
  };
  return (
    <CreatableSelect
      components={{
        IndicatorSeparator: () => null,
      }}
      isClearable
      className="form_select"
      noOptionsMessage={() => noData()}
      value={
        Array.isArray(value) && value.length
          ? value[0].label
            ? value
            : value.map((val) => ({ value: val, label: val }))
          : []
      }
      options={values}
      onChange={handleChange}
      isMulti
      menuPlacement="auto"
      closeMenuOnSelect={false}
    />
  );
};

export default UserDefinedDropdownField;
