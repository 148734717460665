import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { toast } from 'react-toastify';

const Chart = ({
  chartID,
  chartURL,
  filter,
  setChart = () => {},
  setChartError,
  height = '100%',
  width = '100%',
}) => {
  const sdk = new ChartsEmbedSDK({ baseUrl: chartURL });
  const chartDiv = useRef(null);
  const [rendered, setRendered] = useState(false);
  const [chart] = useState(
    sdk.createChart({
      chartId: chartID,
      height: height,
      width: width,
      showAttribution: false,
      autoRefresh: false,
      maxDataAge: 0,
    })
  );

  useEffect(() => {
    chart
      .render(chartDiv.current)
      .then(() => {
        setRendered(true);
        setChartError(null);
      })
      .catch((err) => setChartError(err?.message || 'Error rendering chart'));
    setChart(chart);
  }, [chart]);

  useEffect(() => {
    if (rendered) {
      chart
        .setFilter(filter)
        .catch((err) =>
          toast.error(`Error while filtering. ${err?.message || ''}`)
        );
    }
  }, [chart, filter, rendered]);

  return <div className="chart" ref={chartDiv} />;
};

export default Chart;
