import React from 'react';
import { useTable } from 'react-table';
import { noData } from '../../Utils/common';
import DualRingLoader from '../DualRingLoader';

const SimpleTable = ({ columns, data, loading }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {!loading &&
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        {(loading || (!loading && !data.length)) && (
          <tr>
            <td colSpan={columns.length}>
              <div className="loader-container">
                {loading && <DualRingLoader dark />}
                {!loading && !data.length && noData()}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SimpleTable;
