import { Drawer } from 'antd';
import React, { useState } from 'react';
import IssueManager from '.';

const DealIssuesDrawer = ({ dealDetails, showDrawer, closeDrawer }) => {
  return (
    <Drawer
      open={showDrawer}
      title={null}
      closeIcon={null}
      className="issues-drawer"
      closeOnOverlayClick={false}
      width="90%"
    >
      <IssueManager
        showFilter={true}
        dealDetails={dealDetails}
        showCloseIcon={true}
        closeDrawer={closeDrawer}
      />
    </Drawer>
  );
};

export default DealIssuesDrawer;
