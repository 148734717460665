import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import KanbanView from './components/KanbanView';
import PipelineTable from './components/PipelineTable';
import { PIPELINE_VIEWS } from '../Constant/FilterOperators';

const PipelineViews = ({
  pipelineFilter,
  setPipelineView,
  userFilter,
  teamFilter,
  parentStages,
  presalesStages,
  parentStagesLoading,
  handleCardClick,
  currentPage,
  setCurrentPage,
  filteredCrmFields,
  filteredCustomFields,
  refetchCardDetails,
  setRefetchCardDetails,
  isCRMStageView,
  refreshForCreateOpportunity,
  setRefreshForCreateOpportunity,
  stages,
  collapseEmptyLanes,
}) => {
  const { tab } = useParams();

  useEffect(() => {
    setPipelineView(PIPELINE_VIEWS?.filter((data) => data.value === tab));
  }, [tab]);

  return (
    <>
      {tab === 'table' && (
        <PipelineTable
          filter={pipelineFilter}
          userId={userFilter?.value}
          selectedUserName={userFilter?.label}
          stages={presalesStages}
          crmStages={stages}
          handleTitleClick={handleCardClick}
          filteredCrmFields={filteredCrmFields}
          filteredCustomFields={filteredCustomFields}
          isCRMStageView={isCRMStageView}
          refreshForCreateOpportunity={refreshForCreateOpportunity}
          setRefreshForCreateOpportunity={setRefreshForCreateOpportunity}
          teamFilter={teamFilter}
        />
      )}

      {tab === 'kanban' && (
        <KanbanView
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pipelineFilter={pipelineFilter}
          handleCardClick={handleCardClick}
          userFilter={userFilter}
          teamFilter={teamFilter}
          parentStages={parentStages}
          parentStagesLoading={parentStagesLoading}
          refetchCardDetails={refetchCardDetails}
          setRefetchCardDetails={setRefetchCardDetails}
          isCRMStageView={isCRMStageView}
          refreshForCreateOpportunity={refreshForCreateOpportunity}
          setRefreshForCreateOpportunity={setRefreshForCreateOpportunity}
          stages={stages}
          collapseEmptyLanes={collapseEmptyLanes}
        />
      )}
    </>
  );
};

export default PipelineViews;
