import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedTeamSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    //used in adminops
    createTeam: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.createTeamByCompanyId,
        method: 'POST',
        body,
      }),
    }),
    createTeamAdminSettings: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.create,
        method: 'POST',
        body,
      }),
    }),
    fetchAllTeams: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.teams.getAll;
      },
    }),
    fetchActiveInactiveTeams: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.teams.getAllTeams;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Team', id: 'fetchActiveInactiveTeams' },
      ],
    }),
    deleteTeam: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.deleteTeam,
        method: 'POST',
        body,
      }),
    }),
    editTeam: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.editTeam,
        method: 'POST',
        body,
      }),
    }),
    fetchAdminManagers: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.teams.getUsersForAdminManager;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Team', id: 'fetchAdminManagers' },
      ],
    }),
    manageTeamMembers: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.manageTeamMembers,
        method: 'POST',
        body,
      }),
    }),
    updateGeneralSettings: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.teams.updateGeneralSettings,
        method: 'POST',
        body,
      }),
    }),
    fetchTeamById: builder.query({
      query: (teamId) => {
        return `${URLS_BUSINESS_SERVICE.teams.getById}/${teamId}`;
      },
      providesTags: (result = [], error, arg) => [
        { type: 'Team', id: 'fetchTeamById' },
      ],
    }),
  }),
});

export const {
  useCreateTeamMutation,
  useCreateTeamAdminSettingsMutation,
  useFetchAllTeamsQuery,
  useFetchActiveInactiveTeamsQuery,
  useDeleteTeamMutation,
  useEditTeamMutation,
  useFetchAdminManagersQuery,
  useManageTeamMembersMutation,
  useUpdateGeneralSettingsMutation,
  useFetchTeamByIdQuery,
} = extendedTeamSlice;
export default extendedTeamSlice.reducer;
