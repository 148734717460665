import emptyHyphen from '../_helpers/empty-hyphen';

const getActiveUsecase = (params, isText) => {
  if (isText && params?.data?.active_usecase === null) {
    return emptyHyphen(params?.data?.active_usecase);
  } else if (!isText && params?.data?.active_usecase === null) {
    return;
  } else {
    if (isText) {
      return params?.data?.active_usecase;
    }
  }
};

const getTotalUsecase = (params, isText) => {
  if (isText && params?.data?.total_usecase === null) {
    return emptyHyphen(params?.data?.total_usecase);
  } else if (!isText && params?.data?.total_usecase === null) {
    return;
  } else {
    if (isText) {
      return params?.data?.total_usecase;
    }
  }
};

const getActivePulseScore = (params, isText) => {
  if (
    (isText && !params?.data?.total_rating) ||
    (isText && params?.data?.active_usecase === 0)
  ) {
    return emptyHyphen(null);
  }
  if (params?.data?.total_rating >= 1 && params?.data?.total_rating < 2) {
    if (isText) {
      return `Requested (${params?.data?.total_rating} / 5)`;
    } else {
      return { color: '#36B37E', fontWeight: 500 };
    }
  }
  if (params?.data?.total_rating >= 2 && params?.data?.total_rating < 3) {
    if (isText) {
      return `Good to have (${params?.data?.total_rating} / 5)`;
    } else {
      return { color: '#7FCEAC', fontWeight: 500 };
    }
  }
  if (params?.data?.total_rating >= 3 && params?.data?.total_rating < 4) {
    if (isText) {
      return `Must-have (${params?.data?.total_rating} / 5)`;
    } else {
      return { color: '#FFBDAD', fontWeight: 500 };
    }
  }
  if (params?.data?.total_rating >= 4 && params?.data?.total_rating < 5) {
    if (isText) {
      return `Critical (${params?.data?.total_rating} / 5)`;
    } else {
      return { color: '#FF7452', fontWeight: 500 };
    }
  }
  if (params?.data?.total_rating >= 5) {
    if (isText) {
      return `Deal Breaker (${params?.data?.total_rating} / 5)`;
    } else {
      return { color: '#DE350B', fontWeight: 500 };
    }
  }
};

const getIssueType = (params, isText) => {
  if (isText && params?.data?.total_usecase === null) {
    return emptyHyphen(params?.data?.total_usecase);
  } else if (!isText && params?.data?.total_usecase === null) {
    return;
  } else {
    if (isText) {
      return params?.data?.total_usecase;
    }
  }
};

export { getActiveUsecase, getTotalUsecase, getActivePulseScore, getIssueType };
