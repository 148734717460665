export const dropdownStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: '4px',
      border: '1px solid #E2EBF3',
      maxHeight: '38px',
    };
  },
  indicatorsContainer: (base) => {
    return {
      ...base,
      padding: '0px',
    };
  },
  dropdownIndicator: (base) => {
    return { ...base, padding: '0px 4px' };
  },
  clearIndicator: (base) => {
    return { ...base, padding: '0px 4px' };
  },
  valueContainer: (base) => {
    return { ...base, fontWeight: '400', padding: '0px', flexWrap: 'no-wrap' };
  },
  multiValue: (base) => {
    return {
      ...base,
      fontWeight: '400',
      backgroundColor: '#e5effa',
      fontSize: '14px',
    };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};

export const borderlessDropdownStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: '4px',
      border: 'none',
      maxHeight: '38px',
    };
  },
  indicatorsContainer: (base) => {
    return {
      ...base,
      padding: '0px',
    };
  },
  dropdownIndicator: (base) => {
    return { ...base, padding: '0px 4px' };
  },
  clearIndicator: (base) => {
    return { ...base, padding: '0px 4px' };
  },
  valueContainer: (base) => {
    return { ...base, fontWeight: '400', padding: '0px', flexWrap: 'no-wrap' };
  },
  multiValue: (base) => {
    return {
      ...base,
      fontWeight: '400',
      backgroundColor: '#e5effa',
      fontSize: '14px',
    };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f2f9fc' : null,
      color: '#333333',
    };
  },
};
