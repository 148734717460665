import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { DE_DOMAIN_URL } from '../../_helpers/axios';
import { businessService } from './businessService';
import { URLS_DE_SERVICE } from '../../Constant/urls';
import { extendedCompanySlice } from '../../Company/companySlice';
import { extendedIntegrationSlice } from '../../Settings/integrations/integrationsSlice';

export const dataEngineeringService = createApi({
  reducerPath: 'dataEngineeringService',
  baseQuery: fetchBaseQuery({ baseUrl: DE_DOMAIN_URL }),
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  endpoints: (builder) => ({
    callback: builder.query({
      query: ({ integration, search }) => {
        return `${DE_DOMAIN_URL}callback/${integration}${search}`;
      },
      providesTags: (result = [], error, arg) => {
        return ['Callback', arg?.integration];
      },
    }),
    syncCRM: builder.query({
      query: ({ integration, state }) => {
        return `callback/${integration}/pull?state=${state}`;
      },
      providesTags: (result, error, arg) => {
        return ['Callback', 'SyncCRM', arg?.integration];
      },
    }),
    disconnect: builder.query({
      query: ({ integration }) => {
        const profileData = JSON.parse(localStorage.getItem('user'));
        const userId = profileData?.userId;
        const companyId = profileData?.companyId;
        return `${DE_DOMAIN_URL}callback/${integration}_disconnect?user_id=${userId}&company_id=${companyId}`;
      },
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          businessService.util.invalidateTags([
            { type: 'Users', id: 'UserDetails' },
            { type: 'Company', id: 'crmDetails' },
          ])
        );
      },
      providesTags: (result, error, arg) => {
        return ['Callback', 'Disconnect', arg?.integration];
      },
    }),
    createFreshsalesLead: builder.mutation({
      query: (body) => ({
        url: URLS_DE_SERVICE.callback.createFreshsalesLead,
        method: 'POST',
        body: {
          companyId: process.env.REACT_APP_CREATE_LEAD_COMPANY_ID,
          fullName: body?.fullName,
          email: body?.email,
        },
      }),
    }),
    fetchCrmMetadata: builder.query({
      query: ({ companyId }) => {
        return `${URLS_DE_SERVICE.callback.getCrmMetadata}?companyId=${companyId}`;
      },
      transformResponse: (responseData) => {
        const result = {};
        //This map is used to fetch the field label to display in UI
        //This map's structure is {objecttype : {field name : field label}}
        const metaDataMap = {};
        responseData &&
          responseData.length &&
          responseData.forEach((element) => {
            const fieldResult = [];
            if (!metaDataMap[element.objectType]) {
              metaDataMap[element.objectType] = {};
            }
            element.fields.forEach((field) => {
              const transformedData = {};
              transformedData.label = field.label;
              transformedData.value = field.name;
              transformedData.type = field.type;
              fieldResult.push(transformedData);
              metaDataMap[element.objectType][field.name] = field.label;
            });
            result[element.objectType] = fieldResult;
          });
        return { crmMetadata: result, metaDataMap };
      },
    }),
    associatePresalesField: builder.mutation({
      query: (body) => ({
        url: URLS_DE_SERVICE.callback.associatePresalesField,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'Company', id: 'companyDetails' },
        { type: 'Crm', id: 'CrmMapper' },
      ],
    }),
    refreshJira: builder.query(
      builder.query({
        query: ({ userId, companyId }) => {
          return `${URLS_DE_SERVICE.callback.refreshJira}?user_id=${userId}&company_id=${companyId}`;
        },
      })
    ),
    pullZohoBugs: builder.query(
      builder.query({
        query: ({ state }) => {
          return `${URLS_DE_SERVICE.callback.pullZohoBugs}?state=${state}`;
        },
      })
    ),
    pullEventsFromCalendar: builder.query({
      query: ({ userId, companyId, calendar }) => {
        const urlMap = {
          google: URLS_DE_SERVICE.callback.pullGoogleEvents,
          microsoft: URLS_DE_SERVICE.callback.pullMicrosoftEvents,
        };
        const url = urlMap[calendar];
        return `${url}?userId=${userId}&companyId=${companyId}`;
      },
    }),
    saveJiraAuthentication: builder.mutation({
      query: (body) => ({
        url: URLS_DE_SERVICE.callback.saveJiraAuthentication,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useCallbackQuery,
  useLazyDisconnectQuery,
  useLazySyncCRMQuery,
  useCreateFreshsalesLeadMutation,
  useFetchCrmMetadataQuery,
  useAssociatePresalesFieldMutation,
  useLazyRefreshJiraQuery,
  useSaveJiraAuthenticationMutation,
  useLazyPullEventsFromCalendarQuery,
  useLazyPullZohoBugsQuery,
} = dataEngineeringService;
