import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react';
import { Button, Flex, Drawer as AntdDrawer, Typography, Tooltip } from 'antd';
import Drawer from '../../Drawer';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import { MultiSelect } from '../../MultiSelect';
import {
  ValueContainer,
  Option,
  useCaseStyles,
} from '../../../Constant/MultiSelectStylesAndComponents';
import {
  PULSE_SCORE,
  PG_USECASES_DATE_RANGE,
  REVENUE_OPERATOR_OPTIONS,
  DATE_OPERATOR_OPTIONS,
} from '../../../Constant/FilterOperators';
import { SOLUTION_SCORE } from '../../Rating';
import { useFetchProductGapAnalyticsQuery } from '../../../features/Solution/solutionSlice';
import {
  useFetchSalesOwnersQuery,
  useFetchAllDealTypesQuery,
  useFetchCRMStagesQuery,
} from '../../../Accounts/accountsSlice';
import { useFetchParentStagesQuery } from '../../../Pipeline/pipelineSlice';
import {
  getDateByOperator,
  toISODateForFilter,
} from '../../../Utils/DateUtils';
import useDataPersistance from '../../../hooks/useDataPersistance';
import { getFilterKey } from '../../../Utils/libraryUtils';
import { useFetchGetAllTeamsPlaybookQuery } from '../../../app/api/teamsSlice';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as ReopenSVG } from '../../../Icons/Refresh.svg';

const { Text, Title } = Typography;

const SELECT_STYLES = {
  ...useCaseStyles,
  control: (base) => {
    return {
      ...base,
      borderRadius: '6px',
      minHeight: '30px',
      height: '30px',
    };
  },
};

const LibraryFilterDrawer = (
  {
    filterFor,
    showFilters,
    onCancel = () => {},
    onSubmit = () => {},
    onReset = () => {},
    view = 'defaultDrawer',
  },
  ref
) => {
  const { getStorageData, setStorageData, removeStorageData } =
    useDataPersistance();

  const setInitialValues = (key, defaultValue, setter) => {
    const filtersFromLS = getStorageData(getFilterKey(filterFor));
    if (filtersFromLS?.[key]) {
      setter(filtersFromLS?.[key]);
      return;
    }
    if (defaultValue) {
      setter(defaultValue);
    }
  };

  const handleChange = (triggeredAction, setter, option, defaultValue) => {
    if (triggeredAction.action === 'clear') {
      setter(defaultValue);
      return;
    }
    setter(option);
  };

  // * CREATED DATE * //
  const [dateRangeFilter, setDateRangeFilter] = useState(
    PG_USECASES_DATE_RANGE[4]
  );
  const onDateRangeSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setDateRangeFilter,
      option,
      PG_USECASES_DATE_RANGE[4]
    );
  };

  // * PULSE/SOLUTION SCORE * //
  const [pulseScoreFilter, setPulseScoreFilter] = useState(
    filterFor === 'solutions' ? SOLUTION_SCORE : PULSE_SCORE
  );
  const onPulseScoreSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setPulseScoreFilter,
      option,
      filterFor === 'solutions' ? SOLUTION_SCORE : PULSE_SCORE
    );
  };

  // * TEAMS * //
  const {
    data: { newTeams, adminManagerTeams } = {
      newTeams: [],
      adminManagerTeams: [],
    },
    isLoading: loadingTeams,
  } = useFetchGetAllTeamsPlaybookQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => {
      const adminManagerTeams = useMemo(() => {
        return data?.data?.map((team) => {
          return {
            value: team?.id,
            label: team?.teamName,
          };
        });
      }, [data?.data]);
      return {
        data: {
          newTeams: data?.data,
          adminManagerTeams,
        },
        isLoading,
      };
    },
  });
  const [selectedTeam, setSelectedTeam] = useState([]);
  useEffect(() => {
    setInitialValues('teamFilter', adminManagerTeams, setSelectedTeam);
  }, [adminManagerTeams]);

  const onTeamSelect = (option, triggeredAction) => {
    handleChange(triggeredAction, setSelectedTeam, option, adminManagerTeams);
  };

  // * USECASE/PRESALES OWNER * //
  const [userMapOptions, setUserMapOptions] = useState([]);
  useEffect(() => {
    if (selectedTeam?.length > 0) {
      let teamUsers = [];
      const teams = newTeams?.filter((team) =>
        selectedTeam.some((selectedTeam) => selectedTeam?.value === team?.id)
      );
      teams?.map((team) => {
        team?.teamMembers?.map((teamMember) => {
          if (
            teamMember?.active === true &&
            !teamUsers?.some((user) => user?.value === teamMember?.id)
          ) {
            teamUsers.push({
              value: teamMember?.id,
              label: teamMember?.name,
            });
          }
        });
      });
      setUserMapOptions(teamUsers);
    }
  }, [selectedTeam]);
  const [presalesOwnerFilter, setPresalesOwnerFilter] = useState([]);
  useEffect(() => {
    setInitialValues('presalesOwner', userMapOptions, setPresalesOwnerFilter);
  }, [userMapOptions]);
  const onPresalesOwnerSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setPresalesOwnerFilter,
      option,
      userMapOptions
    );
  };

  // * COMPETITORS * //
  const { data: pgAnalyticsData, isLoading: loadingCompetitorsAndPersona } =
    useFetchProductGapAnalyticsQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        data: data?.data,
        isLoading,
      }),
    });
  const [competitorsFilter, setCompetitorsFilter] = useState([]);
  useEffect(() => {
    setInitialValues(
      'competitors',
      pgAnalyticsData?.competitors,
      setCompetitorsFilter
    );
  }, [pgAnalyticsData?.competitors]);
  const onCompetitorsSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setCompetitorsFilter,
      option,
      pgAnalyticsData?.competitors
    );
  };

  // * LINKED PERSONA * //
  const [personaFilter, setPersonaFilter] = useState([]);
  useEffect(() => {
    setInitialValues(
      'impactsWhom',
      pgAnalyticsData?.impactsWhom,
      setPersonaFilter
    );
  }, [pgAnalyticsData?.impactsWhom]);
  const onPersonaSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setPersonaFilter,
      option,
      pgAnalyticsData?.impactsWhom
    );
  };

  // * REVENUE * //
  const [revenue, setRevenue] = useState({
    ...REVENUE_OPERATOR_OPTIONS[0],
    amount: '',
  });
  const onRevenueOperatorSelect = (option, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      const option = { ...REVENUE_OPERATOR_OPTIONS[0], amount: '' };
      setRevenue(option);
      return;
    }
    const revenue = option;
    if (revenue.value === 'All') {
      revenue.amount = '';
    }
    setRevenue((prev) => ({ ...prev, ...revenue }));
  };
  const handleRevenueChange = (value) =>
    setRevenue((prev) => ({ ...prev, amount: value }));

  // * SALES STAGES * //
  const { data: salesStages, isLoading: loadingSalesStages } =
    useFetchCRMStagesQuery();
  const [salesStageFilter, setSalesStageFilter] = useState(null);
  useEffect(() => {
    setInitialValues('stage', salesStages?.stages, setSalesStageFilter);
  }, [salesStages?.stages]);
  const onSalesStageSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setSalesStageFilter,
      option,
      salesStages?.stages
    );
  };

  // * SALES OWNERS * //
  const { data: salesOwners, isLoading: loadingSalesOwners } =
    useFetchSalesOwnersQuery();
  const [salesOwner, setSalesOwner] = useState([]);
  useEffect(() => {
    setInitialValues('salesPerson', salesOwners, setSalesOwner);
  }, [salesOwners]);
  const onSalesOwnerSelect = (option, triggeredAction) => {
    handleChange(triggeredAction, setSalesOwner, option, salesOwners);
  };

  // * TYPES * //
  const { data: dealTypes, isLoading: loadingDealTypes } =
    useFetchAllDealTypesQuery();
  const [type, setType] = useState([]);
  useEffect(() => {
    setInitialValues('type', dealTypes, setType);
  }, [dealTypes]);
  const onTypeselect = (option, triggeredAction) => {
    handleChange(triggeredAction, setType, option, dealTypes);
  };

  // * PRESALES STAGES * //
  const { data: stages, isLoading: loadingPresalesStages } =
    useFetchParentStagesQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => {
        const options = useMemo(() => {
          const options = data?.ids?.map((stage) => ({
            label: data?.entities[stage]?.name,
            value: stage,
          }));
          return options;
        }, [data]);

        return { data: options, isLoading };
      },
    });
  const [presalesStage, setPresalesStage] = useState([]);
  useEffect(() => {
    setInitialValues('preskaleStage', stages, setPresalesStage);
  }, [stages]);
  const onPresalesStageSelect = (option, triggeredAction) => {
    handleChange(triggeredAction, setPresalesStage, option, stages);
  };

  // * EXPECTED CLOSE DATE * //
  const [expectedCloseDate, setExpectedCloseDate] = useState(
    DATE_OPERATOR_OPTIONS[0]
  );
  const onCloseDateOperatorSelect = (option, triggeredAction) => {
    handleChange(
      triggeredAction,
      setExpectedCloseDate,
      option,
      DATE_OPERATOR_OPTIONS[0]
    );
  };
  const handleCloseDateChange = (range) => (date) => {
    const closeDate = { ...expectedCloseDate };
    if (range === 'from') {
      closeDate.fromDate = toISODateForFilter(date);
    } else {
      closeDate.toDate = toISODateForFilter(date);
    }
    setExpectedCloseDate(closeDate);
  };

  // * POPULATE DATA * //
  useEffect(() => {
    /*
     * Populate all filter except multiselect from local storage
     * For multiselect, this is taken care of in their own useeffect hook
     */
    const filtersFromLS = getStorageData(getFilterKey(filterFor));
    if (filtersFromLS?.createdDate) {
      setDateRangeFilter(filtersFromLS?.createdDate);
    }
    if (filtersFromLS?.pulseScoreList) {
      const pulseScore = filtersFromLS?.pulseScoreList.map((score) =>
        filterFor === 'solutions'
          ? SOLUTION_SCORE[5 - score]
          : PULSE_SCORE[score - 1]
      );
      setPulseScoreFilter(pulseScore);
    }
    if (filtersFromLS?.revenue) {
      setRevenue(filtersFromLS?.revenue);
    }
    if (filtersFromLS?.expectedCloseDate) {
      setExpectedCloseDate(filtersFromLS?.expectedCloseDate);
    }
  }, []);

  const handleSubmit = (filters) => {
    onSubmit(filters);
    onCancel();
    return filters;
  };

  const handleReset = () => {
    setDateRangeFilter(PG_USECASES_DATE_RANGE[4]);
    setPulseScoreFilter(
      filterFor === 'solutions' ? SOLUTION_SCORE : PULSE_SCORE
    );
    setSelectedTeam(adminManagerTeams);
    setPresalesOwnerFilter(userMapOptions);
    setCompetitorsFilter(pgAnalyticsData?.competitors);
    setPersonaFilter(pgAnalyticsData?.impactsWhom);
    const option = { ...REVENUE_OPERATOR_OPTIONS[0], amount: '' };
    setRevenue(option);
    setSalesStageFilter(salesStages?.stages);
    setSalesOwner(salesOwners);
    setType(dealTypes);
    setPresalesStage(stages);
    setExpectedCloseDate(DATE_OPERATOR_OPTIONS[0]);
    removeStorageData(getFilterKey(filterFor));
    if (typeof onReset === 'function') {
      onReset({});
    }
    return {};
  };

  // * FILTER FUNCTIONALITY * //
  const handleFilterApply = () => {
    const filtersForPayload = {
      createdDate:
        dateRangeFilter.value === PG_USECASES_DATE_RANGE[4].value
          ? undefined
          : getDateByOperator(dateRangeFilter.value),
      pulseScoreList:
        !Array.isArray(pulseScoreFilter) ||
        (filterFor === 'solutions'
          ? pulseScoreFilter.length === SOLUTION_SCORE.length
          : pulseScoreFilter.length === PULSE_SCORE.length)
          ? undefined
          : pulseScoreFilter.map((item) => item.value),
      teamFilter:
        selectedTeam.length === adminManagerTeams.length
          ? undefined
          : selectedTeam.map((team) => team.value),
      presalesOwner:
        !Array.isArray(presalesOwnerFilter) ||
        presalesOwnerFilter.length === userMapOptions.length
          ? undefined
          : presalesOwnerFilter.map((item) => item.value),
      competitors:
        !Array.isArray(competitorsFilter) ||
        competitorsFilter.length === pgAnalyticsData?.competitors?.length
          ? undefined
          : competitorsFilter.map((item) => item.value),
      impactsWhom:
        !Array.isArray(personaFilter) ||
        personaFilter.length === pgAnalyticsData?.impactsWhom?.length
          ? undefined
          : personaFilter.map((item) => item.value),
      revenue: !revenue.amount
        ? undefined
        : { operator: revenue.value, value: revenue.amount },
      stage:
        !Array.isArray(salesStageFilter) ||
        salesStageFilter.length === salesStages?.stages?.length
          ? undefined
          : salesStageFilter.map((item) => item.value),
      salesPerson:
        !Array.isArray(salesOwner) || salesOwner.length === salesOwners?.length
          ? undefined
          : salesOwner.map((item) => item.value),
      type:
        !Array.isArray(type) || type.length === dealTypes?.length
          ? undefined
          : type.map((item) => item.value),
      preskaleStage:
        !Array.isArray(presalesStage) || presalesStage.length === stages?.length
          ? undefined
          : presalesStage.map((item) => item.label),
      expectedCloseDate:
        expectedCloseDate.value === 'ALL'
          ? undefined
          : expectedCloseDate.value === 'CUSTOM'
          ? {
              fromDate: expectedCloseDate?.fromDate,
              toDate: expectedCloseDate?.toDate,
            }
          : getDateByOperator(expectedCloseDate.value),
    };
    const filters = {}; // * Storing values in localStorage in a format ready for UI
    for (let filter in filtersForPayload) {
      switch (filter) {
        case 'createdDate':
          filters.createdDate = filtersForPayload[filter]
            ? dateRangeFilter
            : undefined;
          break;
        case 'pulseScoreList': // * We are not storing this score as it is in local storage because SOLUTION_SCORE's label contains a React element and it does not store well
          filters.pulseScoreList = filtersForPayload[filter]
            ? filtersForPayload[filter]
            : undefined;
          break;
        case 'teamFilter':
          filters.teamFilter = filtersForPayload[filter]
            ? selectedTeam
            : undefined;
          break;
        case 'presalesOwner':
          filters.presalesOwner = filtersForPayload[filter]
            ? presalesOwnerFilter
            : undefined;
          break;
        case 'competitors':
          filters.competitors = filtersForPayload[filter]
            ? competitorsFilter
            : undefined;
          break;
        case 'impactsWhom':
          filters.impactsWhom = filtersForPayload[filter]
            ? personaFilter
            : undefined;
          break;
        case 'revenue':
          filters.revenue = filtersForPayload[filter] ? revenue : undefined;
          break;
        case 'stage':
          filters.stage = filtersForPayload[filter]
            ? salesStageFilter
            : undefined;
          break;
        case 'salesPerson':
          filters.salesPerson = filtersForPayload[filter]
            ? salesOwner
            : undefined;
          break;
        case 'type':
          filters.type = filtersForPayload[filter] ? type : undefined;
          break;
        case 'preskaleStage':
          filters.preskaleStage = filtersForPayload[filter]
            ? presalesStage
            : undefined;
          break;
        case 'expectedCloseDate':
          filters.expectedCloseDate = filtersForPayload[filter]
            ? expectedCloseDate
            : undefined;
          break;
        default:
          break;
      }
    }
    setStorageData(getFilterKey(filterFor), filters);
    handleSubmit(filtersForPayload);
  };

  const [hasBanner, setHasBanner] = useState(false);
  useLayoutEffect(() => {
    setHasBanner(document.getElementsByClassName('layout--has-banner').length);
  }, []);

  const filterContentDefault = (
    <section className="filters">
      <label className="filter-container">
        <span>Created Date</span>
        <Select
          options={PG_USECASES_DATE_RANGE}
          onChange={onDateRangeSelect}
          value={dateRangeFilter}
          styles={SELECT_STYLES}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>{`${
          filterFor === 'solutions' ? 'Solution' : 'Pulse'
        } score`}</span>
        <MultiSelect
          key={filterFor}
          options={filterFor === 'solutions' ? SOLUTION_SCORE : PULSE_SCORE}
          value={pulseScoreFilter}
          onChange={onPulseScoreSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Teams</span>
        <MultiSelect
          options={adminManagerTeams}
          value={selectedTeam}
          onChange={onTeamSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingTeams}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Usecase owner</span>
        <MultiSelect
          options={userMapOptions}
          onChange={onPresalesOwnerSelect}
          value={presalesOwnerFilter}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingTeams}
          menuPlacement="auto"
        />
      </label>

      <label className="filter-container">
        <span>Competitors</span>
        <MultiSelect
          options={pgAnalyticsData?.competitors ?? []}
          onChange={onCompetitorsSelect}
          value={competitorsFilter}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingCompetitorsAndPersona}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Linked Persona</span>
        <MultiSelect
          options={pgAnalyticsData?.impactsWhom ?? []}
          onChange={onPersonaSelect}
          value={personaFilter}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingCompetitorsAndPersona}
          menuPlacement="auto"
        />
      </label>

      {/* OPPPORTUNITY FILTERING */}
      {/* All Filters from PIPELINE */}
      <label className="filter-container">
        <span>Sales Owner</span>
        <MultiSelect
          options={salesOwners}
          value={salesOwner}
          onChange={onSalesOwnerSelect}
          styles={SELECT_STYLES}
          components={{ ValueContainer, Option }}
          isLoading={loadingSalesOwners}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Type</span>
        <MultiSelect
          options={dealTypes}
          value={type}
          onChange={onTypeselect}
          styles={SELECT_STYLES}
          components={{ ValueContainer, Option }}
          isLoading={loadingDealTypes}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Revenue</span>
        <div className=" revenue-filter">
          <Select
            name="revenue"
            options={REVENUE_OPERATOR_OPTIONS}
            styles={SELECT_STYLES}
            value={revenue}
            onChange={onRevenueOperatorSelect}
            menuPlacement="auto"
          />
          <CurrencyInput
            className="currency-input"
            decimalsLimit={2}
            prefix="$"
            placeholder="Enter a number"
            value={revenue.amount}
            disabled={revenue.value === 'ALL'}
            onValueChange={handleRevenueChange}
          />
        </div>
      </label>
      <label className="filter-container">
        <span>Sales Stage</span>
        <MultiSelect
          options={salesStages?.stages}
          value={salesStageFilter || []}
          onChange={onSalesStageSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingSalesStages}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Presales Stage</span>
        <MultiSelect
          options={stages}
          value={presalesStage || []}
          onChange={onPresalesStageSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingPresalesStages}
          menuPlacement="auto"
        />
      </label>
      <label className="filter-container">
        <span>Expected Close Date</span>
        <Select
          name="date"
          type="dropdown"
          options={DATE_OPERATOR_OPTIONS}
          value={expectedCloseDate}
          onChange={onCloseDateOperatorSelect}
          styles={SELECT_STYLES}
          menuPlacement="auto"
        />
        {expectedCloseDate.value === 'CUSTOM' && (
          <div className="expected-close-date">
            <div className="filter-date-container">
              <label className="filter-container">From</label>
              <DatePicker
                selected={
                  expectedCloseDate?.fromDate
                    ? new Date(expectedCloseDate?.fromDate)
                    : new Date(getDateByOperator('THIS_QUARTER')?.fromDate)
                }
                onChange={handleCloseDateChange('from')}
                disabled={expectedCloseDate.label !== 'CUSTOM'}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="filter-date-container">
              <label className="filter-container">To</label>
              <DatePicker
                selected={
                  expectedCloseDate?.toDate
                    ? new Date(expectedCloseDate?.toDate)
                    : new Date(getDateByOperator('THIS_QUARTER')?.toDate)
                }
                onChange={handleCloseDateChange('to')}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
        )}
      </label>
    </section>
  );

  const filterContentAntdDrawer = (
    <Flex vertical gap={10}>
      <Flex vertical>
        <Text strong>Created Date</Text>
        <Select
          options={PG_USECASES_DATE_RANGE}
          onChange={onDateRangeSelect}
          value={dateRangeFilter}
          styles={SELECT_STYLES}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Pulse Score</Text>
        <MultiSelect
          key={filterFor}
          options={filterFor === 'solutions' ? SOLUTION_SCORE : PULSE_SCORE}
          value={pulseScoreFilter}
          onChange={onPulseScoreSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Teams</Text>
        <MultiSelect
          options={adminManagerTeams}
          value={selectedTeam}
          onChange={onTeamSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingTeams}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Usecase owner</Text>
        <MultiSelect
          options={userMapOptions}
          onChange={onPresalesOwnerSelect}
          value={presalesOwnerFilter}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingTeams}
          menuPlacement="auto"
        />
      </Flex>

      <Flex vertical>
        <Text strong>Competitors</Text>
        <MultiSelect
          options={pgAnalyticsData?.competitors ?? []}
          onChange={onCompetitorsSelect}
          value={competitorsFilter}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingCompetitorsAndPersona}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Linked Persona</Text>
        <MultiSelect
          options={pgAnalyticsData?.impactsWhom ?? []}
          onChange={onPersonaSelect}
          value={personaFilter}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingCompetitorsAndPersona}
          menuPlacement="auto"
        />
      </Flex>

      {/* OPPPORTUNITY FILTERING */}
      {/* All Filters from PIPELINE */}
      <Flex vertical>
        <Text strong>Sales Owner</Text>
        <MultiSelect
          options={salesOwners}
          value={salesOwner}
          onChange={onSalesOwnerSelect}
          styles={SELECT_STYLES}
          components={{ ValueContainer, Option }}
          isLoading={loadingSalesOwners}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Type</Text>
        <MultiSelect
          options={dealTypes}
          value={type}
          onChange={onTypeselect}
          styles={SELECT_STYLES}
          components={{ ValueContainer, Option }}
          isLoading={loadingDealTypes}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Revenue</Text>
        <Flex>
          <Select
            name="revenue"
            options={REVENUE_OPERATOR_OPTIONS}
            styles={SELECT_STYLES}
            value={revenue}
            onChange={onRevenueOperatorSelect}
            menuPlacement="auto"
            className="width-40 mr-20"
          />
          <CurrencyInput
            className="currency-input"
            decimalsLimit={2}
            prefix="$"
            placeholder="Enter a number"
            value={revenue.amount}
            disabled={revenue.value === 'ALL'}
            onValueChange={handleRevenueChange}
          />
        </Flex>
      </Flex>
      <Flex vertical>
        <Text strong>Sales Stage</Text>
        <MultiSelect
          options={salesStages?.stages}
          value={salesStageFilter || []}
          onChange={onSalesStageSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingSalesStages}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Presales Stage</Text>
        <MultiSelect
          options={stages}
          value={presalesStage || []}
          onChange={onPresalesStageSelect}
          components={{ ValueContainer, Option }}
          styles={SELECT_STYLES}
          isSearchable={false}
          isLoading={loadingPresalesStages}
          menuPlacement="auto"
        />
      </Flex>
      <Flex vertical>
        <Text strong>Expected Close Date</Text>
        <Select
          name="date"
          type="dropdown"
          options={DATE_OPERATOR_OPTIONS}
          value={expectedCloseDate}
          onChange={onCloseDateOperatorSelect}
          styles={SELECT_STYLES}
          menuPlacement="auto"
        />
        {expectedCloseDate.value === 'CUSTOM' && (
          <div className="expected-close-date">
            <Flex>
              <Text strong>From</Text>
              <DatePicker
                selected={
                  expectedCloseDate?.fromDate
                    ? new Date(expectedCloseDate?.fromDate)
                    : new Date(getDateByOperator('THIS_QUARTER')?.fromDate)
                }
                onChange={handleCloseDateChange('from')}
                disabled={expectedCloseDate.label !== 'CUSTOM'}
                dateFormat="yyyy-MM-dd"
              />
            </Flex>
            <Flex className="filter-date-container">
              <Text strong>To</Text>
              <DatePicker
                selected={
                  expectedCloseDate?.toDate
                    ? new Date(expectedCloseDate?.toDate)
                    : new Date(getDateByOperator('THIS_QUARTER')?.toDate)
                }
                onChange={handleCloseDateChange('to')}
                dateFormat="yyyy-MM-dd"
              />
            </Flex>
          </div>
        )}
      </Flex>
    </Flex>
  );

  const footerContent = () => {
    return (
      <>
        <div className="filter-button-container">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleFilterApply}
          >
            Apply
          </button>
        </div>
        <div className="reset-button-container">
          <button type="text" className="reset-btn" onClick={handleReset}>
            Reset to Default
          </button>
        </div>
      </>
    );
  };

  const getParentElement = (children) => {
    switch (view) {
      case 'defaultDrawer':
        return (
          <Drawer
            ref={ref}
            showDrawer={showFilters}
            className={`preskale-library--filter${
              hasBanner ? ' has--banner' : ''
            }`}
            overlayClassName={`preskale-library--filterOverlay${
              hasBanner ? ' has--banner' : ''
            }`}
            shouldCloseOnOverlayEscClick={onCancel}
            showHeader={false}
          >
            {children}
            {footerContent()}
          </Drawer>
        );
      case 'antdDrawer':
        return (
          <AntdDrawer
            open={showFilters}
            onClose={onCancel}
            title={<Title level={4}>Filters</Title>}
            closable={false}
            footer={
              <Flex gap={3} justify="end">
                <Tooltip title="Reset to Default">
                  <Button
                    icon={<ReopenSVG height={16} width={16} />}
                    onClick={handleReset}
                  />
                </Tooltip>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" onClick={handleFilterApply}>
                  Apply
                </Button>
              </Flex>
            }
            extra={<Button icon={<CloseOutlined />} onClick={onCancel} />}
            styles={{
              wrapper: {
                width: '35%',
              },
            }}
          >
            {children}
          </AntdDrawer>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {getParentElement(
        view === 'defaultDrawer'
          ? filterContentDefault
          : filterContentAntdDrawer
      )}
    </>
  );
};

export default React.forwardRef(LibraryFilterDrawer);
