import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Suspense,
  lazy,
  useMemo,
} from 'react';
import Button from '../common/Button';
import Select from 'react-select';
import { ReactComponent as FilterSVG } from '../Icons/NewImage/filter.svg';
import { ReactComponent as KanbanOutlineSVG } from '../Icons/kanban-outline.svg';
import { PIPELINE_VIEWS } from '../Constant/FilterOperators';
import PipelineFilter from './components/PipelineFilter';
import CrmBanner from './components/banner';
import {
  isAdminOrManager as findIsAdminOrManager,
  isSalesUser as findIsSalesUser,
  isProductUser as findIsProductUser,
} from '../Utils/common';
import {
  populateFilterData,
  resetFilters,
  resetCurrentPage,
  populateCrmColumnFilterData,
  populateCustomColumnFilterData,
  getDefaultFilter,
  populateColumnViewData,
} from './utils/pipelineUtils';
import { UserOutlined, TeamOutlined } from '@ant-design/icons';

import { useFetchCRMDetailsQuery } from '../Company/companySlice';
import mixpanel from 'mixpanel-browser';
import { MultiSelect } from '../common/MultiSelect';
import { ReactComponent as AddSVG } from '../Icons/NewImage/add.svg';
import { ReactComponent as TableSVG } from '../Icons/NewImage/table.svg';
import { ReactComponent as KanbanSVG } from '../Icons/NewImage/kanban.svg';
import { ReactComponent as CustomizeSVG } from '../Icons/NewImage/customizeTable.svg';
import PageLayout from '../Layout/PageLayout';
import usePipelineSearchProps from './components/PipelineSearchField';
import PipelineViews from './PipelineViews';
import CreateOpportunity from '../Dashboard/CreateOpportunity';
import {
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { saveSelectedCard, selectedDealDetail } from './pipelineSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnFilter } from './components/ColumnFilter';
import {
  useFetchAllDealTypesQuery,
  useFetchSalesOwnersQuery,
  useFetchAccountsBySearchQuery,
} from '../Accounts/accountsSlice';
import PipelineDropdownMenu from './components/PipelineDropdownMenu';
import { selectUserEmailMap, useGetUsersQuery } from '../app/api/usersSlice';
import ExtraActionsMenu from './components/ExtraActionsMenu';
import {
  customBorderlessDropdownStyles,
  customPipelineViewDropdownStyles,
} from './utils/PipelineDropdownStyles';
import useCloseComponentOnOutsideClick from '../hooks/useCloseComponentOnOutsideClick';
import {
  useFetchGetAllTeamsPlaybookQuery,
  useFetchGetTeamsByManagerQuery,
  useFetchPipelineAccessibilityQuery,
} from '../app/api/teamsSlice';
import DualRingLoader from '../common/DualRingLoader';
import Drawer from '../common/Drawer';
import {
  MultiValueRemove,
  Option,
} from '../Constant/MultiSelectStylesAndComponents';
import ToolTip from '../common/ToolTip';
const DealPresentationView = lazy(() =>
  import('../features/DealPresentationView')
);
import { borderlessDropdownStyles } from './utils/dropdownStyles';
import useDebounce from '../hooks/useDebounce';
import useSetUpPipelines from './useSetUpPipelines';
import { Flex, Tooltip } from 'antd';

const PresentationViewContent = (props) => {
  const { dealID } = useParams();
  return (
    dealID && (
      <Suspense
        fallback={
          <Drawer showDrawer={true} className="presentationViewDrawer">
            <div className="loader-container--suspense">
              <DualRingLoader dark />
            </div>
          </Drawer>
        }
      >
        <DealPresentationView
          showPresentationView={dealID ? true : false}
          closePresentationView={props.closePresentationView}
          dealId={dealID}
          presalesUser={props.presalesUser}
          crmStage={props.crmStage}
          preskaleCreated={props.preskaleCreated}
          handlePresalesUserChange={props.handlePresalesUserChange}
          refreshView={props.refreshView}
          setRefreshForCreateOpportunity={props.setRefreshForCreateOpportunity}
          iscrmAccount={props.iscrmAccount}
          selectedUser={props.selectedUserFilter}
        />
      </Suspense>
    )
  );
};

const Index = () => {
  const addOpportunityRef = useRef();
  const dispatch = useDispatch();
  const isAdminOrManager = findIsAdminOrManager();
  const isProductUser = findIsProductUser();
  const isSalesUser = findIsSalesUser();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const selectedDeal = useSelector(selectedDealDetail);
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const [pollingInterval, setPollingInterval] = useState(0);
  const [refetchCardDetails, setRefetchCardDetails] = useState(null); // * To update card details after changing the presales user/to trigger lane updates
  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 1500);
  const [openCreateOpportunity, setOpenCreateOpportunity] = useState(false);
  const [adminManagerTeams, setAdminManagerTeams] = useState();

  const {
    configFeatures,
    loadingConfig,
    isCRMStageView,
    setIsCRMStageView,
    pipelines,
    parentStages,
    crmParentStagesLoading,
    presalesStages,
    pipelinePresalesStages,
    psParentStagesLoading,
    selectedPipeline,
    onPipelineSelect,
    salesStages,
    selectedTeam,
    setSelectedTeam,
  } = useSetUpPipelines();

  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery(
    undefined,
    {
      skip: isProductUser,
    }
  );

  const { data: newTeams } = useFetchGetTeamsByManagerQuery(undefined, {
    skip: !isAdminOrManager && pipelineAccessibility?.data !== 'user',
  });

  const { data: salesOwners, isLoading: salesOwnersLoading } =
    useFetchSalesOwnersQuery();

  const {
    data: { productTeams, productUserTeams } = {
      productTeams: [],
      productUserTeams: [],
    },
  } = useFetchGetAllTeamsPlaybookQuery(undefined, {
    skip: !isProductUser,
    selectFromResult: ({ data, isLoading }) => {
      const productUserTeams = useMemo(() => {
        return data?.data?.map((team) => {
          return {
            value: team?.id,
            label: team?.teamName,
          };
        });
      }, [data?.data]);
      return {
        data: {
          productTeams: data?.data,
          productUserTeams,
        },
        isLoading,
      };
    },
  });

  const updatePollingInterval = (data) => {
    /*
     * Polling interval starts with 0
     * Update polling interval to 5secs on crmSync when data is not synced
     * Reset to 0 once synced
     */
    if (data && data?.crmType && data?.crmType?.token?.manual_sync) {
      if (!pollingInterval) {
        setPollingInterval(5000);
      }
      return;
    }
    if (
      data &&
      data?.crmType &&
      !data?.crmType?.token?.manual_sync &&
      pollingInterval > 0
    ) {
      setPollingInterval(0);
      console.log('pipeline view', pipelineView);
      setRefetchCardDetails(true); // * Trigger pipeline refresh
      return;
    }
    if (data && !data?.crmType && pollingInterval > 0) {
      setPollingInterval(0);
    }
  };
  const { data: userMapOptions } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserEmailMap,
  });

  const { data: crmDetails } = useFetchCRMDetailsQuery(undefined, {
    selectFromResult: ({ data, isFetching }) => {
      updatePollingInterval(data);
      return { data, isFetching };
    },
    pollingInterval,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const [currentPage, setCurrentPage] = useState({});
  const [userFilter, setUserFilter] = useState(
    isProductUser
      ? { label: 'All', value: 'all' }
      : { label: loggedInUser?.name, value: loggedInUser?.userId }
  );
  const [teamMembers, setTeamMembers] = useState([]);
  const [pipelineFilter, setPipelineFilter] = useState(null);
  const [userDropdownList, setUserDropdownList] = useState([]);
  // const [preskaleStages, setPreskaleStages] = useState([]);
  const [pipelineView, setPipelineView] = useState(PIPELINE_VIEWS[0]);
  const [selectedCrmFields, setSelectedCrmFields] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [filteredCrmFields, setFilteredCrmFields] = useState([]);
  const [filteredCustomFields, setFilteredCustomFields] = useState([]);

  const [collapseEmptyLanes, setCollapseEmptyLanes] = useState(false);
  const toggleLaneCollapse = () => setCollapseEmptyLanes(!collapseEmptyLanes);

  const {
    containerRef: tableSettingsContainerRef,
    openComponent: showColummFilterModal,
    setOpenComponent: setShowColummFilterModal,
  } = useCloseComponentOnOutsideClick();

  const {
    containerRef: crmActionsContainerRef,
    openComponent: showStageModal,
    setOpenComponent: setShowStageModal,
  } = useCloseComponentOnOutsideClick();

  const {
    containerRef: filtersContainerRef,
    openComponent: showFilterModal,
    setOpenComponent: setShowFilterModal,
  } = useCloseComponentOnOutsideClick();

  const { data: dealTypes } = useFetchAllDealTypesQuery();
  const [selectedCard, setSelectedCard] = useState(null);
  const [openPresentationViewDrawer, setOpenPresentationViewDrawer] =
    useState(false);
  const [refreshForCreateOpportunity, setRefreshForCreateOpportunity] =
    useState(null);

  useEffect(() => {
    // Replacing default pathname
    if (location.pathname === '/pipeline') {
      history.replace('/pipeline/kanban');
    }
  }, [location]);

  const getSelectedTeams = () => {
    if (
      (!isProductUser && selectedTeam?.length === newTeams?.data?.length) ||
      (isProductUser && selectedTeam?.length === productTeams?.length)
    ) {
      return ['all'];
    } else {
      let selectedTeamIdList = [];
      selectedTeam?.map((team) => {
        selectedTeamIdList.push(team?.value);
      });
      return selectedTeamIdList;
    }
  };

  useEffect(() => {
    if (!isProductUser) {
      if (newTeams?.data?.length > 0) {
        let teams = newTeams?.data?.map((team) => {
          return {
            value: team?.id,
            label: team?.teamName,
          };
        });

        const initialTeamSelection = JSON.parse(
          localStorage.getItem('selectedTeams')
        )
          ? false
          : true;
        let teamSelected =
          JSON.parse(localStorage.getItem('selectedTeams')) ?? teams;
        let allTeamUsers = [];

        newTeams?.data?.map((team) => {
          if (
            initialTeamSelection ||
            teamSelected?.some((user) => user?.value === team?.id)
          ) {
            team?.teamMembers?.map((teamMember) => {
              if (
                teamMember?.active === true &&
                !allTeamUsers?.some((user) => user?.value === teamMember?.id)
              ) {
                allTeamUsers.push({
                  value: teamMember?.id,
                  label: teamMember?.name,
                });
              }
            });
          }
        });

        setAdminManagerTeams(teams);
        setSelectedTeam(teamSelected);
        setUserDropdownList([{ value: 'all', label: 'All' }, ...allTeamUsers]);
        setTeamMembers(allTeamUsers);
        onTeamSelect(teamSelected);
      }
    }
  }, [newTeams]);

  const resetPipelineFilter = () => {
    localStorage.removeItem('pipeline_filter');
    const filter = getDefaultFilter(null);
    setPipelineFilter(filter);
  };

  useEffect(() => {
    if (isProductUser) {
      if (productTeams?.length > 0) {
        let teams = productTeams?.map((team) => {
          return {
            value: team?.id,
            label: team?.teamName,
          };
        });
        let teamSelected =
          JSON.parse(localStorage.getItem('selectedTeams')) ?? teams;
        let allTeamUsers = [];
        teamSelected?.map((team) => {
          team?.teamMembers?.map((teamMember) => {
            if (
              teamMember?.active === true &&
              !allTeamUsers?.some((user) => user?.value === teamMember?.id)
            ) {
              allTeamUsers.push({
                value: teamMember?.id,
                label: teamMember?.name,
              });
            }
          });
        });
        setAdminManagerTeams(teams);
        setSelectedTeam(teamSelected);
        localStorage.setItem('selectedTeams', JSON.stringify(teamSelected));
        setUserDropdownList([{ value: 'all', label: 'All' }, ...allTeamUsers]);
        setTeamMembers(allTeamUsers);
        onTeamSelect(teamSelected);
      }
    }
  }, [productTeams]);

  const excludeCallback = (clickedElement) => {
    return (
      addOpportunityRef.current &&
      (addOpportunityRef.current === clickedElement ||
        addOpportunityRef.current.contains(clickedElement))
    );
  };

  const refreshDealPresentationView = (cardDetails) => {
    dispatch(saveSelectedCard(cardDetails));
    setSelectedCard(cardDetails);
  };
  const openDealPresentationView = useCallback(
    (props) => {
      try {
        mixpanel.track('pipeline_card_accessed', {
          event: 'Pipeline opportunity clicked',
        });
      } catch {
        console.error('Cannot add event to mixpanel');
      }
      let tab = props.tab;
      if (!tab) {
        tab = location?.pathname?.includes('table') ? 'table' : 'kanban';
      }
      const cardDetails = {
        cardId: props.id,
        laneId: props.laneId,
        dealId: props.dealId,
        dealName: props.name,
        presalesUser: props.presalesUser,
        crmStage: props.crmStage,
        currentPreskaleStageName: props.currentPreskaleStageName,
        dealValue: props.dealValue,
        preskaleCreated: props.preskaleCreated,
        collaborators: props.collabrator,
        collaboratedDeal: props.collabratedDeal,
      };

      refreshDealPresentationView(cardDetails);
      history.push(`${tab}/${props.dealId}`);
      setOpenCreateOpportunity(false);
    },
    [selectedCard, openPresentationViewDrawer, location]
  );

  const closeDealPresentationView = useCallback(() => {
    const tab = location?.pathname?.includes('table') ? 'table' : 'kanban';
    dispatch(saveSelectedCard(null));
    setSelectedCard(null);
    history.replace(`/pipeline/${tab}`);
  }, [selectedCard, openPresentationViewDrawer]);

  const { data: searchedAccounts } = useFetchAccountsBySearchQuery(
    {
      searchTerm: debouncedSearchTerm,
      teamMembers:
        isAdminOrManager ||
        isProductUser ||
        pipelineAccessibility?.data === 'user'
          ? teamMembers
          : false,
    },
    {
      skip: !debouncedSearchTerm || debouncedSearchTerm.length < 3,
    }
  );

  const handleSearch = (event) => {
    if (!event.target.value || event.target.value.length < 3) {
      setSearchTerm(null);
      return;
    }
    setSearchTerm(event.target.value);
  };

  const handlePipelineFilterModalToggle = useCallback(
    () => setShowFilterModal((prev) => !prev),
    []
  );
  const handleColumnFilterModalToggle = useCallback(
    () => setShowColummFilterModal((prev) => !prev),
    []
  );

  const handlePipelineStageModalToggle = useCallback(() =>
    setShowStageModal((prev) => !prev)
  );

  const handleStageView = useCallback(() => {
    localStorage.setItem(
      'crm_stage_view',
      isCRMStageView === 'crm' ? 'preskale' : 'crm'
    );
    setIsCRMStageView((prev) => (prev === 'crm' ? 'preskale' : 'crm'));
    resetCurrentPage(setCurrentPage);
  });

  const onUserSelect = useCallback((option) => {
    setUserFilter(option);
    setRefetchCardDetails({ userFilter: option });
  }, []);

  useEffect(() => {
    // Populate filter data from localStorage
    // If localStorage is empty process pipeline filter from state
    populateCrmColumnFilterData(setFilteredCrmFields);
    populateCustomColumnFilterData(setFilteredCustomFields);
    populateFilterData(setPipelineFilter);
    populateColumnViewData(dispatch);
  }, []);

  const searchProps = usePipelineSearchProps(
    handleSearch,
    searchedAccounts,
    openDealPresentationView
  );

  const handlePresalesUserChange = (newPresalesUser) => {
    const cardInfo = { ...selectedCard };
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const userName = userFilter?.label || userDetails?.userName;
    cardInfo['collaboratorDeal'] = userName !== cardInfo?.presalesUser;
    cardInfo.presalesUser = newPresalesUser?.value;
    setRefetchCardDetails(cardInfo);
    setSelectedCard(cardInfo);
    // dispatch(saveSelectedCard(cardInfo));
  };

  const handleCreateOpportunitySubmit = (createdDeal = null) => {
    //handles create opportunity
    if (!createdDeal?.deleteDeal) {
      setRefreshForCreateOpportunity(createdDeal);
    }
  };
  const handleCreateOpportunityDelete = (createdDeal = null) => {
    //handles delete deal
    if (createdDeal?.deleteDeal) {
      const data = { ...createdDeal, ...selectedCard };
      handleCreateOpportunityModalToggle();
      setRefreshForCreateOpportunity(data);
      closeDealPresentationView();
    }
  };

  const handleCreateOpportunityModalToggle = useCallback(() =>
    setOpenCreateOpportunity((prev) => !prev)
  );
  const handleCreateOpportunityClose = useCallback(() =>
    setOpenCreateOpportunity(false)
  );

  const switchView = (selectedOption) => {
    history.push(`/pipeline/${selectedOption.value}`);
  };

  const onTeamSelect = (selectedTeam) => {
    let allTeamUsers = [];
    // * onUserSelect triggers the pipeline kanban view to reload with new data.
    // For multi pipeline acc, Avoid triggering api on team change since pipeline options is refetched
    // on change and that will re-trigger the accounts & revenue api.
    if (selectedTeam?.length > 0) {
      if (!isProductUser) {
        if (pipelines?.length) {
          setUserFilter({
            value: loggedInUser?.userId,
            label: loggedInUser?.name,
          });
        } else {
          onUserSelect({
            value: loggedInUser?.userId,
            label: loggedInUser?.name,
          });
        }
      }

      if (isProductUser && pipelines?.length) {
        onUserSelect({ value: 'all', label: 'All' });
      }
      if (isProductUser && !pipelines?.length) {
        setUserFilter({ value: 'all', label: 'All' });
      }

      let teams = [];
      if (isProductUser) {
        teams = productTeams?.filter(({ id: id1 }) =>
          selectedTeam.some(({ value: id2 }) => id2 === id1)
        );
      } else {
        teams = newTeams?.data?.filter(({ id: id1 }) =>
          selectedTeam.some(({ value: id2 }) => id2 === id1)
        );
      }

      teams?.map((team) => {
        team?.teamMembers?.map((teamMember) => {
          if (
            teamMember?.active === true &&
            !allTeamUsers?.some((user) => user?.value === teamMember?.id)
          ) {
            allTeamUsers.push({
              value: teamMember?.id,
              label: teamMember?.name,
            });
          }
        });
      });
      setUserDropdownList([{ value: 'all', label: 'All' }, ...allTeamUsers]);
      setTeamMembers(allTeamUsers);
    }
  };

  const onTeamChange = (option) => {
    onTeamSelect(option);
    setSelectedTeam(option);
    localStorage.setItem('selectedTeams', JSON.stringify(option));
  };

  const handlePipelineChange = (option) => {
    onPipelineSelect(option);
    resetPipelineFilter();
  };

  return (
    <PageLayout
      headerMainContent={
        <Flex gap="small" align="center">
          <Select
            options={PIPELINE_VIEWS}
            onChange={switchView}
            value={pipelineView}
            styles={customPipelineViewDropdownStyles}
            isSearchable={false}
            components={{
              Option: ({ innerProps, label, data, isFocused }) => (
                <div className="flex-align-center pb-2 mt-8" {...innerProps}>
                  {data.label === 'Kanban' ? <KanbanSVG /> : <TableSVG />}
                  <span className="ml-8">{label}</span>
                </div>
              ),
              SingleValue: ({ innerProps, label, data }) => (
                <div className="flex-align-center" {...innerProps}>
                  {data.label === 'Kanban' ? <KanbanSVG /> : <TableSVG />}
                  <div className="ml-8 pointer text-weight-semi-bold">
                    {data.label}
                  </div>
                </div>
              ),
            }}
          />

          {(isAdminOrManager ||
            isProductUser ||
            pipelineAccessibility?.data === 'user') &&
            (adminManagerTeams?.length > 1 || productUserTeams?.length > 1) &&
            userDropdownList &&
            userDropdownList.length > 0 && (
              <Flex gap={4} align="center" className="icon-select-grp">
                <Tooltip title="Teams">
                  <TeamOutlined className="icon-label" />{' '}
                </Tooltip>
                <MultiSelect
                  className="width-140"
                  options={adminManagerTeams ?? productUserTeams}
                  value={selectedTeam}
                  onChange={(option) => onTeamChange(option)}
                  components={{ Option, MultiValueRemove }}
                  styles={borderlessDropdownStyles}
                />
              </Flex>
            )}
          {(isAdminOrManager ||
            isProductUser ||
            pipelineAccessibility?.data === 'user') &&
            userDropdownList &&
            userDropdownList.length > 0 && (
              <Flex align="center" className="icon-select-grp">
                <Tooltip title="User">
                  <UserOutlined className="icon-label" />{' '}
                </Tooltip>
                <Select
                  options={userDropdownList}
                  onChange={onUserSelect}
                  value={userFilter}
                  disabled={userDropdownList?.length === 1}
                  styles={customBorderlessDropdownStyles}
                  isSearchable={false}
                  className="user-filter-dropdown"
                />
              </Flex>
            )}

          {pipelines?.length > 1 && (
            <Flex align="center" className="icon-select-grp">
              <Tooltip title="Pipelines">
                <span className="icon-label flex-align-center">
                  <KanbanOutlineSVG height={16} width={20} />
                </span>
              </Tooltip>
              <Select
                options={pipelines}
                onChange={handlePipelineChange}
                value={selectedPipeline}
                disabled={pipelines?.length === 1}
                styles={customBorderlessDropdownStyles}
                isSearchable={false}
                className="user-filter-dropdown"
              />
            </Flex>
          )}
        </Flex>
      }
      banner={
        crmDetails?.crmType?.type === 'salesforce' &&
        crmDetails?.crmType?.token.manual_sync &&
        !isProductUser &&
        !isSalesUser && <CrmBanner />
      }
      searchProps={searchProps}
      extra={[
        <div key="customize-table" className="flex-align-center gap-4px">
          {pipelineView[0]?.value === 'table' && (
            <ToolTip
              text="Customize Table"
              tooltipClass={
                pipelines?.length
                  ? 'multi-pipeline-tooltip'
                  : 'pipeline-tooltip'
              }
            >
              <Button
                type="ghost"
                className="pipeline-filter-btn pipeline-filter-btn--kanban"
                onClick={handleColumnFilterModalToggle}
              >
                <div className="flex-align-center gap-default">
                  <CustomizeSVG />
                  <div
                    className={
                      pipelines?.length
                        ? 'includes-multi-pipeline-header'
                        : 'pipeline-button-text'
                    }
                  >
                    Customize Table
                  </div>
                </div>
              </Button>
            </ToolTip>
          )}
          {/* </div>,
        <div key="pipeline-filter"> */}
          <ToolTip
            text="Pipeline Filter"
            tooltipClass={
              pipelines?.length ? 'multi-pipeline-tooltip' : 'pipeline-tooltip'
            }
          >
            <Button
              key="filter"
              type="ghost"
              className="pipeline-filter-btn pipeline-filter-btn--kanban"
              onClick={handlePipelineFilterModalToggle}
            >
              <div className="flex-align-center gap-default">
                <FilterSVG />
                <div
                  className={
                    pipelines?.length
                      ? 'includes-multi-pipeline-header'
                      : 'pipeline-button-text'
                  }
                >
                  Pipeline Filter
                </div>
              </div>
            </Button>
          </ToolTip>
        </div>,
        <div ref={addOpportunityRef} key="create-opportunity">
          {!isSalesUser && !isProductUser && (
            <button
              className="create-opportunity-icon ml-8"
              onClick={() => {
                handleCreateOpportunityModalToggle();
              }}
            >
              <AddSVG />
            </button>
          )}
        </div>,
        <ExtraActionsMenu
          key="extra-actions-menu"
          showMenu={showStageModal}
          toggleMenu={handlePipelineStageModalToggle}
        >
          {showStageModal && (
            <PipelineDropdownMenu
              closePopup={() => setShowStageModal(false)}
              changeView={handleStageView}
              isCRMStageView={isCRMStageView}
              crmSyncSuccessCb={setPollingInterval}
              crmDetails={crmDetails}
              crm={configFeatures?.data?.crm}
              ref={crmActionsContainerRef}
              pipelineView={pipelineView}
              collapseEmptyLanes={collapseEmptyLanes}
              toggleLaneCollapse={toggleLaneCollapse}
              disable={presalesStages?.length > 0 ? false : true}
            />
          )}
        </ExtraActionsMenu>,
      ]}
    >
      <div className="app-kanban-view">
        {showFilterModal && (
          <PipelineFilter
            ref={filtersContainerRef}
            pipelineFilter={pipelineFilter}
            setPipelineFilter={setPipelineFilter}
            handlePipelineFilterModalToggle={handlePipelineFilterModalToggle}
            salesOwners={salesOwners}
            salesOwnersLoading={salesOwnersLoading}
            stages={salesStages ?? []}
            preskaleStages={presalesStages ?? []}
            preskaleStagesLoading={psParentStagesLoading}
            isCRMStageView={isCRMStageView}
            pipelineView={pipelineView}
            resetPage={() => resetCurrentPage(setCurrentPage)}
            dealTypes={dealTypes}
            isSalesUser={isSalesUser}
            userMapOptions={userMapOptions}
            handleReset={() =>
              resetFilters({
                setCurrentPage,
                setPipelineFilter,
                setUserFilter,
                stages: salesStages ?? [],
              })
            }
            handleApply={(filter, user) =>
              setRefetchCardDetails(
                user?.value ? { filter, userFilter: user } : { filter }
              )
            }
          />
        )}

        {showColummFilterModal && pipelineView[0]?.value === 'table' && (
          <ColumnFilter
            ref={tableSettingsContainerRef}
            selectedCrmFields={selectedCrmFields}
            setSelectedCrmFields={setSelectedCrmFields}
            filteredCrmFields={filteredCrmFields}
            setFilteredCrmFields={setFilteredCrmFields}
            selectedCustomFields={selectedCustomFields}
            setSelectedCustomFields={setSelectedCustomFields}
            filteredCustomFields={filteredCustomFields}
            setFilteredCustomFields={setFilteredCustomFields}
            handleColumnFilterModalToggle={handleColumnFilterModalToggle}
          />
        )}
        <Route path={`${path}/:tab/:dealID?`}>
          <PipelineViews
            pipelineFilter={pipelineFilter}
            userFilter={userFilter}
            teamFilter={getSelectedTeams()}
            parentStages={parentStages}
            presalesStages={pipelinePresalesStages}
            parentStagesLoading={
              isCRMStageView === 'crm'
                ? crmParentStagesLoading
                : psParentStagesLoading
            }
            handleCardClick={openDealPresentationView}
            setPipelineView={setPipelineView}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filteredCrmFields={filteredCrmFields}
            filteredCustomFields={filteredCustomFields}
            refetchCardDetails={refetchCardDetails}
            setRefetchCardDetails={setRefetchCardDetails}
            isCRMStageView={isCRMStageView}
            refreshForCreateOpportunity={refreshForCreateOpportunity}
            setRefreshForCreateOpportunity={setRefreshForCreateOpportunity}
            stages={salesStages}
            collapseEmptyLanes={collapseEmptyLanes}
          />
          <PresentationViewContent
            closePresentationView={closeDealPresentationView}
            presalesUser={selectedCard?.presalesUser}
            crmStage={selectedCard?.crmStage}
            preskaleCreated={selectedCard?.preskaleCreated}
            handlePresalesUserChange={handlePresalesUserChange}
            refreshView={refreshDealPresentationView}
            setRefreshForCreateOpportunity={setRefreshForCreateOpportunity}
            iscrmAccount={configFeatures?.data?.crm}
            selectedUserFilter={userFilter}
          />
        </Route>
      </div>
      {openCreateOpportunity && (
        <CreateOpportunity
          dealDetail={selectedDeal}
          handleSubmit={handleCreateOpportunitySubmit}
          handleDelete={handleCreateOpportunityDelete}
          handleClose={handleCreateOpportunityClose}
          excludeCallback={excludeCallback}
          openDrawer={openCreateOpportunity}
          key="pipeline-create-opportunity"
        />
      )}
    </PageLayout>
  );
};

export default Index;
