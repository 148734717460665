import React from 'react';
import SearchField from '../Accounts/commons/SearchField';

const PageLayout = ({
  children,
  headerTitle,
  headerMainContent,
  headerMainExtra,
  searchProps,
  extra,
  banner,
  sidebar,
  sidebarSize = 'S', // S, M, L
  contentClassname,
}) => {
  return (
    <>
      {banner}
      {(headerTitle ||
        headerMainContent ||
        headerMainExtra ||
        searchProps ||
        extra) && (
        <header className="app-main-header">
          {(headerTitle || headerMainContent) && (
            <section className="header-container">
              {headerTitle && (
                <h2 className="heading_level4 text-dark mr-15">
                  {' '}
                  {headerTitle}{' '}
                </h2>
              )}
              {headerMainContent}
            </section>
          )}
          {headerMainExtra}
          <section className="flex-align-center">
            {searchProps && <SearchField {...searchProps} />}
            {extra}
          </section>
        </header>
      )}

      <section
        className={`app-main-content ${
          sidebar ? `has-sidebar-${sidebarSize}` : ''
        } ${
          !headerTitle &&
          !headerMainContent &&
          !headerMainExtra &&
          !searchProps &&
          !extra
            ? 'no-header'
            : ''
        } ${contentClassname || ''} ${banner ? 'has-banner' : ''}`}
      >
        {sidebar && (
          <aside className={`app-sidebar app-sidebar-${sidebarSize}`}>
            {sidebar}
          </aside>
        )}
        {children}
      </section>
    </>
  );
};

export default PageLayout;
