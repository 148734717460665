import React, { useEffect } from 'react';
import { Flex, Form, Select, Typography } from 'antd';
import { format } from 'date-fns';
import { renderDetails } from '../tasks/utils';

const { Text } = Typography;

const AddActivitySideContent = ({
  userMap,
  activityAccount,
  allAccounts,
  accountSummary,
  loggedInUser,
  initialValues,
  form,
  action,
  dealDetails = '',
}) => {
  useEffect(() => {
    if (initialValues && action === 'update') {
      form.setFieldsValue({
        'activity-account': initialValues?.accountId ?? null,
        'activity-opportunity': initialValues?.dealId ?? null,
        'activity-owner': initialValues?.userId ?? null,
      });
    }
    if (action == 'add') {
      form.setFieldsValue({
        'activity-owner': loggedInUser?.userId ?? null,
      });
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (dealDetails) {
      form.setFieldsValue({
        'activity-account': dealDetails?.accountId,
        'activity-opportunity': dealDetails?.dealId,
      });
    }
  }, [dealDetails]);

  const renderFormItems = () => (
    <>
      <Form.Item
        label={<Text strong>Account</Text>}
        name="activity-account"
        rules={[{ required: true, message: 'Account field is Required' }]}
      >
        <Select
          options={allAccounts}
          placeholder="Select an account"
          size="large"
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item
        label={<Text strong>Opportunity</Text>}
        name="activity-opportunity"
        rules={[{ required: true, message: 'Opportunity field is Required' }]}
      >
        <Select
          options={
            activityAccount
              ? accountSummary.filter(
                  (data) => data.accountId === activityAccount
                )
              : accountSummary
          }
          placeholder="Select an opportunity"
          size="large"
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </>
  );

  return (
    <>
      {action === 'add' ? (
        <Form.Item
          label={<Text strong>Activity Owner</Text>}
          name="activity-owner"
          className="mb-0"
          rules={[
            {
              required: true,
              message: 'Activity owner field is Required',
            },
          ]}
        >
          <Select
            options={userMap}
            placeholder="Select an owner"
            size="large"
            optionFilterProp="label"
            showSearch
          />
        </Form.Item>
      ) : (
        <Flex vertical>
          <Text strong type="secondary">
            Activity Owner
          </Text>
          <Text strong>
            {
              userMap.find((data) => data.value === initialValues?.userId)
                ?.label
            }
          </Text>
        </Flex>
      )}
      {action === 'update' || dealDetails
        ? renderDetails(
            allAccounts,
            accountSummary,
            dealDetails ? dealDetails?.accountId : initialValues?.accountId,
            dealDetails ? dealDetails?.dealId : initialValues?.dealId
          )
        : renderFormItems()}
      <Flex vertical>
        <Text strong type="secondary">
          Activity Creator
        </Text>
        <Text strong>
          {' '}
          {initialValues
            ? userMap?.filter(
                (data) => data.value === initialValues?.createdBy
              )?.[0]?.label
            : loggedInUser?.name}
        </Text>
      </Flex>
      <Flex vertical>
        <Text strong type="secondary">
          Activity Created On
        </Text>
        <Text strong>
          {initialValues
            ? format(new Date(initialValues?.createdDate), 'dd/MM/yyyy')
            : format(new Date(), 'dd/MM/yyyy')}
        </Text>
      </Flex>
    </>
  );
};

export default AddActivitySideContent;
