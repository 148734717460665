import React from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import { useLazyDeleteTaskQuery } from './tasksSlice';

const DeleteTask = ({
  task,
  showModal,
  handleClose = () => {},
  handleSubmit = () => {},
}) => {
  const handleCancel = () => {
    handleClose();
  };

  const [deleteTask, { isLoading }] = useLazyDeleteTaskQuery();

  const handleDeleteTask = () => {
    if (!task?.id || !task?.dealId) {
      console.error('Missing task ID or dealID');
    }
    deleteTask({ taskId: task.id, dealId: task.dealId })
      .unwrap()
      .then((res) => {
        handleSubmit('delete');
        toast.success('Task deleted successfully!');
      })
      .catch((error) => toast.error('Failed to delete task!'));
    handleCancel();
  };

  return (
    <ReactModal
      isOpen={showModal}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Delete Confirmation</h4>
      </div>

      <div className="modal-body">Are you sure you want to delete?</div>

      <div className="modal-footer flex-justify-end">
        <Button type="secondary" disabled={isLoading} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="ml-5"
          type="danger"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleDeleteTask}
        >
          Delete
        </Button>
      </div>
    </ReactModal>
  );
};

export default DeleteTask;
