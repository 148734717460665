import React, { useEffect } from 'react';
import { ReactComponent as WarningSVG } from '../../Icons/NewImage/warning.svg';
import { ReactComponent as AutoTagSVG } from '../../Icons/autoTag.svg';
import {
  saveSelectedCalendarEvent,
  selectSelectedCalendarEvent,
} from './calendarSlice';
import { useSelector, useDispatch } from 'react-redux';

const CalendarEvent = ({ eventInfo, openCalendarInSidebar }) => {
  const dispatch = useDispatch();
  const selectedCalendarEvent = useSelector(selectSelectedCalendarEvent);
  const handleCalendarPopup = async (calendarEventInfo, ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (openCalendarInSidebar && typeof openCalendarInSidebar === 'function') {
      openCalendarInSidebar(calendarEventInfo);
    }
  };

  useEffect(() => {
    if (
      selectedCalendarEvent &&
      selectedCalendarEvent?.eventId === eventInfo?.eventId
    ) {
      dispatch(saveSelectedCalendarEvent(eventInfo));
    }
  }, [eventInfo]);

  const addExtraClass = () => {
    let totalTime = Math.floor(eventInfo.totalTime / 60000);
    return totalTime <= 15 ? 'fs-less-event' : '';
  };

  return (
    <div
      className={`fc-event-container ${addExtraClass()} ${
        openCalendarInSidebar ? 'pointer' : ''
      }`}
    >
      <div
        className="flex event-content"
        onClick={handleCalendarPopup.bind(this, eventInfo)}
      >
        {!eventInfo.synced && (
          <div className="warning-icon">
            <WarningSVG />
          </div>
        )}
        {eventInfo.synced && !eventInfo.manualSynced && (
          <div className="autotag-icon">
            <AutoTagSVG />
          </div>
        )}
        {eventInfo.title}
      </div>
    </div>
  );
};

export default CalendarEvent;
