import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';
import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../../Authorize/authSlice';
import { extendedAccountsSlice } from '../../Accounts/accountsSlice';

const solutionSliceInitialState = {
  selectedModule: [],
};

const solutionSlice = createSlice({
  name: 'solution',
  initialState: solutionSliceInitialState,
  reducers: {
    saveSelectedModule(state, action) {
      state.selectedModule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = solutionSliceInitialState;
      return state;
    });
  },
});

export const extendedSolutionSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchSolution: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.solution.getSolution}?deal_id=${dealId}`;
      },
      transformResponse: (response) => response?.data || [],
      providesTags: () => {
        return [{ type: 'Solutions', id: 'AllSolutions' }];
      },
    }),
    fetchSimilarUsecases: builder.query({
      query: ({ dealId, moduleId, industry, region }) => {
        return `${URLS_BUSINESS_SERVICE.solution.getSimilarUsecases}?deal_id=${dealId}&module_id=${moduleId}&industry=${industry}&region=${region}`;
      },
      providesTags: () => {
        return [{ type: 'Solutions', id: 'Similar-usecases' }];
      },
    }),
    fetchSolutionByModule: builder.query({
      query: ({ dealId, moduleId }) => {
        return `${URLS_BUSINESS_SERVICE.solution.getSolutionByModule}?deal_id=${dealId}&module_id=${moduleId}`;
      },
      providesTags: () => {
        return [{ type: 'Solutions', id: 'SolutionByModule' }];
      },
    }),
    fetchProductGapAnalytics: builder.query({
      query: () => {
        return URLS_BUSINESS_SERVICE.productGap.getPgAnalytics;
      },
      providesTags: () => {
        return [{ type: 'Solutions', id: 'competitors-persona' }];
      },
    }),
    addSolution: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.solution.addSolution,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        // * Optimistic updates
        const patchResult = dispatch(
          extendedAccountsSlice.util.updateQueryData(
            'fetchAccountSummary',
            {
              accountId: body?.accountId,
              dealId: body?.dealId,
            },
            (draft) => {
              let siPulse = draft.totalCriteria * draft.successPulseScore;
              draft.totalCriteria = draft.totalCriteria + 1;
              if (body?.rating) {
                draft.successPulseScore = Math.floor(
                  (siPulse + body.rating) / draft.totalCriteria
                );
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result) => [
        { type: 'Solutions', id: 'AllSolutions' },
        {
          type: 'AccountSummary',
          id: `${result?.data?.dealId}`,
        },
        { type: 'Solutions', id: 'Similar-usecases' },
        { type: 'Solutions', id: 'SolutionByModule' },
      ],
    }),
    updateSolution: builder.mutation({
      query: ({ body }) => ({
        url: URLS_BUSINESS_SERVICE.solution.updateSolution,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: 'Solutions', id: 'AllSolutions' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
        { type: 'Solutions', id: 'Similar-usecases' },
        { type: 'Solutions', id: 'SolutionByModule' },
      ],
    }),
    completeSolution: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.solution.completeSolution,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: 'Solutions', id: 'AllSolutions' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
      ],
    }),
    reopenSolution: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.solution.reopenSolution,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: 'Solutions', id: 'AllSolutions' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
      ],
    }),
    timeSpentSolution: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.solution.timeSpentSolution,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: 'Solutions', id: 'AllSolutions' },
        {
          type: 'AccountSummary',
          id: arg?.dealId,
        },
        {
          type: 'ActivitySummary',
          id: arg?.dealId,
        },
      ],
    }),
    deleteUsecase: builder.query({
      query: ({ id, dealId }) => {
        return `${URLS_BUSINESS_SERVICE.solution.deleteUsecase}?id=${id}&deal_id=${dealId}`;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const tagsToInvalidate = [
          { type: 'Solutions', id: 'AllSolutions' },
          {
            type: 'AccountSummary',
            id: arg?.dealId,
          },
          { type: 'Solutions', id: 'Similar-usecases' },
          { type: 'Solutions', id: 'SolutionByModule' },
        ];
        try {
          await queryFulfilled;
          dispatch(businessService.util.invalidateTags(tagsToInvalidate));
        } catch {
          dispatch(businessService.util.invalidateTags(tagsToInvalidate));
        }
      },
    }),
    getSolutionUsecases: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.solution.useCases,
        method: 'POST',
        body,
      }),
    }),
    fetchClosedSolution: builder.query({
      query: ({ dealId }) => {
        return `${URLS_BUSINESS_SERVICE.solution.getClosedAccount}?deal_id=${dealId}`;
      },
    }),
  }),
});

export const {
  useLazyFetchSolutionQuery,
  useFetchSolutionQuery,
  useLazyFetchSimilarUsecasesQuery,
  useLazyFetchSolutionByModuleQuery,
  useFetchProductGapAnalyticsQuery,
  useAddSolutionMutation,
  useUpdateSolutionMutation,
  useCompleteSolutionMutation,
  useReopenSolutionMutation,
  useTimeSpentSolutionMutation,
  useLazyDeleteUsecaseQuery,
  useGetSolutionUsecasesMutation,
  useFetchClosedSolutionQuery,
} = extendedSolutionSlice;

export const solutionState = (state) => state.solutions;

export const { saveSelectedModule } = solutionSlice.actions;

export default solutionSlice.reducer;
