import React from 'react';

export default function NoModulePgData(props) {
  return (
    <div
      className={
        props.className
          ? `no_data__modulePg ${props.className}`
          : 'no_data__modulePg'
      }
    >
      {props.message}
    </div>
  );
}
