export const INTEGRATIONS_DATA = {
  google: {
    name: 'Google Calendar',
    type: 'google',
    description:
      'Integrate your Google Workspace account with PreSkale to centralize operations and sync your contributions.',
    isActive: true,
  },
  freshsales: {
    name: 'Freshsales',
    type: 'freshsales',
    description: 'Full-fledged Sales CRM software for your business.',
    isActive: false,
    isContactSales: true,
  },
  microsoft: {
    name: 'Outlook',
    type: 'microsoft',
    description:
      'Integrate your Microsoft - Outlook Platform with PreSkale to centralize operations and sync your contributions.',
    isActive: true,
  },
  jira: {
    name: 'Jira',
    type: 'jira',
    description:
      'Integrate your Atlassian Jira Platform with PreSkale to create, link, and access issues and tickets. Connect button to initiate integration',
    isActive: true,
  },
  hubspot: {
    name: 'Hubspot',
    type: 'hubspot',
    description:
      'Integrate your Hubspot platform with PreSkale to manage your presales operations and manage data.',
    isActive: true,
  },
  productboard: {
    name: 'Productboard',
    type: 'productboard',
    description:
      'Enable Productboard in PreSkale to push out Product gap items as Insights.',
    isActive: true,
  },
  zoho: {
    name: 'Zoho',
    type: 'zoho',
    description:
      'Integrate your Zoho platform with PreSkale to manage your presales operations and manage data.',
    isActive: true,
  },
};

// ,
//   {
//     name: 'Zoom',
//     type:'zoom',
//     description: 'Connect with Zoom to capture the conversations between pre-sales engineers and potential prospects.',
//     isActive: true,
//     isContactSales: true
//   },
//   {
//     name: 'Freshsales',
//     description: 'Full-fledged Sales CRM software for your business.',
//     isActive: false,
//     isContactSales: true
//   },
//   {
//     name: 'Zoom',
//     description: 'Connect with Zoom to capture the conversations between pre-sales engineers and potential prospects.',
//     isActive: false,
//     isContactSales: true
//   }

export const DEFAULT_FIELDS = [
  {
    label: 'Account Name',
    value: 'Name',
    preskaleField: 'name',
    type: 'ACCOUNT',
  },
  {
    label: 'Deal Name',
    value: 'Name',
    preskaleField: 'name',
    type: 'OPPORTUNITY',
  },
  {
    label: 'Deal Value',
    value: 'Amount',
    preskaleField: 'deal_value',
    type: 'OPPORTUNITY',
  },
  {
    label: 'Deal Close Date',
    value: 'CloseDate',
    preskaleField: 'expected_close',
    type: 'OPPORTUNITY',
  },
  {
    label: 'Industry',
    value: 'Industry',
    preskaleField: 'industry',
    type: 'ACCOUNT',
  },
  {
    label: 'Region',
    value: 'BillingCountry',
    preskaleField: 'country',
    type: 'ACCOUNT',
  },
  {
    label: 'Deal Created Date',
    value: 'CreatedDate',
    preskaleField: 'start_time',
    type: 'OPPORTUNITY',
  },
];
export const CRM_INTEGRATIONS = {
  salesforce: {
    name: 'Salesforce',
    type: 'salesforce',
    description:
      'Integrate your Salesforce platform with PreSkale to manage your presales operations and manage data.',
    isActive: true,
  },
  zoho: {
    name: 'Zoho',
    type: 'zoho',
    description:
      'Integrate your Zoho platform with PreSkale to manage your presales operations and manage data.',
    isActive: true,
  },
  hubspot: {
    name: 'Hubspot',
    type: 'hubspot',
    description:
      'Integrate your Hubspot platform with PreSkale to manage your presales operations and manage data.',
    isActive: true,
  },
};

export const PRODUCT_INTEGRATIONS = {
  jira: {
    name: 'Jira',
    type: 'jira',
    description:
      'Integrate your Atlassian Jira Platform with PreSkale to create, link, and access issues and tickets. Connect button to initiate integration',
    isActive: true,
    fields: [
      {
        label: 'API Key',
        value: '',
        type: 'api_key',
      },
      {
        label: 'JIRA Admin Email ID',
        value: '',
        type: 'jira_email',
      },
    ],
  },
  productboard: {
    name: 'Productboard',
    type: 'productboard',
    description:
      'Enable Productboard in PreSkale to push out Product gap items as Insights.',
    isActive: true,
  },
  zohobugs: {
    name: 'Zoho Bugs',
    type: 'zohobugs',
    description:
      'Enable Zoho Bugs in PreSkale to push out Product gap items as Insights.',
    isActive: true,
  },
};

export const MS_ADMIN_SCOPES =
  'Calendars.Read+Group.Read.All+GroupMember.Read.All+Directory.Read.All+User.Read';
export const MS_USER_SCOPES = 'Calendars.Read+User.Read';
