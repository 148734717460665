import React from 'react';

const InputField = ({ type, value, onChange }) => {
  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      if (type === 'number' && e.target.value) {
        onChange(Number(e.target.value));
      } else {
        onChange(e.target.value);
      }
    }
  };

  return (
    <input
      type={type}
      className="ag-input-field-input ag-text-field-input"
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputField;
