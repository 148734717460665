import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Button from '../common/Button';
import PageLayout from '../Layout/PageLayout';
import Loader from '../common/Loader';
import { useGetAllCompaniesQuery } from '../Company/companySlice';
import useDataPersistance from '../hooks/useDataPersistance';

import { ReactComponent as AddSVG } from '../Icons/NewImage/add-green.svg';
import { ReactComponent as SearchSVG } from '../Icons/NewImage/search.svg';

export const AdminOps = () => {
  const gridRef = useRef();
  const filterTextBoxRef = useRef(null);
  const history = useHistory();
  const { setStorageData } = useDataPersistance();
  const { data: companies, isLoading } = useGetAllCompaniesQuery();
  const columnDefs = [
    {
      headerName: '#',
      maxWidth: 100,
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: (params) => params?.node?.id,
      cellRenderer: (props) => {
        if (props.value !== undefined) {
          return parseInt(props?.value) + 1;
        } else if (props?.value === undefined) {
          return <DualRingLoader dark />;
        }
      },
    },
    {
      headerName: 'Company Name',
      field: 'name',
      cellRenderer: (props) => (
        <CustomCellRenderer {...props} openDetailView={openDetailView} />
      ),
    },
    {
      headerName: 'Company ID',
      field: 'id',
    },
    { headerName: 'Type', field: 'instance', sortable: true },
    {
      headerName: 'Status',
      field: 'status',
      cellClassRules: {
        'font-green-500 font-bold': (params) => params.value === 'ACTIVE',
        'font-red font-bold': (params) => params.value === 'IN_ACTIVE',
      },
    },
    {
      headerName: 'Number of Licenses',
      field: 'noOfUsers',
    },
  ];

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const openDetailView = (data) => {
    setStorageData('companyData', data);
    history.push({
      pathname: `/detail/${data?.id}`,
      state: { companyData: data },
    });
  };

  const onCreateCompany = () => {
    history.push({
      pathname: `/createcompany`,
    });
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      cellStyle: () => ({
        borderRight: '1px solid #d8e4fd',
      }),
      sortable: true,
    };
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(filterTextBoxRef?.current?.value);
  }, []);

  return (
    <>
      <PageLayout headerTitle="AdminOps - Preskale">
        <div className="adminops">
          <div className="flex">
            <div className="flex-align-center mr-30 text-lg text-weight-semi-bold">
              Dashboard
            </div>
            <div className={`search-container dashboard-search-container`}>
              <span>
                <SearchSVG className="searchsvg" />
              </span>
              <input
                type="text"
                ref={filterTextBoxRef}
                id="filter-text-box"
                className="company-searchbox"
                placeholder="Filter..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
            <div className="add-company">
              <Button
                icon
                className="flex-center"
                onClick={onCreateCompany}
                type="text"
              >
                <span className="add-icon">
                  <AddSVG />
                </span>
              </Button>
              <span className="text-primary flex-center">Add new company</span>
            </div>
          </div>
          {isLoading && <Loader />}
          <div
            className="ag-theme-alpine mt-16"
            style={{ height: '600px', minWidth: '100%' }}
          >
            <AgGridReact
              rowData={companies?.data}
              ref={gridRef}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              pagination={true}
            />
          </div>
        </div>
      </PageLayout>
    </>
  );
};

function CustomCellRenderer(props) {
  const { value, data } = props;

  const handleClick = () => {
    props?.openDetailView(data);
  };

  return (
    <span className="pointer text-primary" onClick={handleClick}>
      {value}
    </span>
  );
}
