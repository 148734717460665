import React from 'react';
import { ReactComponent as G2 } from '../common/images/g2.svg';
import { ReactComponent as Netradyne } from '../common/images/logo_netradyne.svg';
import { ReactComponent as Adaptiva } from '../common/images/logo_adaptiva.svg';
import { ReactComponent as Betterplace } from '../common/images/logo_betterplace.svg';
import { ReactComponent as Katalon } from '../common/images/logo_katalon.svg';
import { ReactComponent as Nauto } from '../common/images/logo_nauto.svg';
import Posist from '../common/images/logo_posist.png';
import { ReactComponent as Vymo } from '../common/images/logo_vymo.svg';
import styles from '../styles/pages/signup.module.scss';

const Landing = () => {
  return (
    <>
      <section>
        <section className={styles.quote}>
          <G2 />
          <q className={styles.sub_heading}>
            Rated as the{' '}
            <span className={styles.highlight}>easiest to use</span>
            <br /> Pre Sales tool{' '}
            <span className={styles.highlight}>with best ROI !</span>
          </q>
        </section>
        <section className={styles.about}>
          <p>
            PreSkale helps your teams streamline PreSales operations and
            <br /> manage product market gaps across all sales opportunities.
            Setup in minutes to
          </p>
          <ul className={styles.list}>
            <li>Unblock hidden revenue</li>
            <li>Revive deals to pipeline</li>
            <li>Accelerate product sales wins</li>
          </ul>
        </section>
      </section>
      <section className={styles.trusted}>
        <p className={styles.text}>
          Trusted by successful businesses around the globe
        </p>
        <section className={styles.logos}>
          <Netradyne />
          <Adaptiva />
          <Betterplace />
          <Katalon />
          <Nauto />
          <img src={Posist} width="62" height="17" />
          <Vymo />
        </section>
      </section>
    </>
  );
};

export default Landing;
