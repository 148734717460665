import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NoContent from '../Accounts/commons/NoContent';
import Table from '../common/Table';

export default function PerformanceUtilisationTable(props) {
  return (
    <div>
      <Tabs
        className="react-tabs__tab-btn"
        selectedIndex={props.tabIndex}
        onSelect={(index) => props.setTabIndex(index)}
      >
        <TabList>
          <Tab>PreSales + Sales Trends </Tab>
          <Tab>All Opportunities</Tab>
        </TabList>

        <TabPanel>
          <div className="table-scroll">
            <Table
              data={props.salesTrends}
              columns={props.salesTrendsColumns}
              className={
                props.salesTrends.length === 0
                  ? 'no-items'
                  : 'sales-trend-table'
              }
            />
          </div>
          {props.salesTrends && props.salesTrends.length == 0 && (
            <NoContent className="no-data-table" message="No data to display" />
          )}
        </TabPanel>
        <TabPanel>
          <div className="table-scroll">
            <Table
              data={
                Array.isArray(props.opportunities) ? props.opportunities : []
              }
              columns={props.opportunitiesColumns}
              className={
                !props.opportunities || props.opportunities?.length === 0
                  ? 'no-items'
                  : 'sales-trend-table'
              }
            />
          </div>
          {(Array.isArray(props.opportunities) &&
            props.opportunities?.length === 0) ||
          !props.opportunities ? (
            <NoContent className="no-data-table" message="No data to display" />
          ) : null}
        </TabPanel>
      </Tabs>
    </div>
  );
}
