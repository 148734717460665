import React, { useState, useMemo } from 'react';
import IssuesTable from './index';
import UseCaseHeader from '../../common/UseCaseHeader';
import DealIssuesDrawer from './DealIssuesDrawer';

const DealIssues = ({ account, isPresentationView, readOnly = false }) => {
  const [filters, setFilters] = useState({
    modules: { all: true },
    features: { all: true },
    score: { all: true },
    searchTerm: '',
  });
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawerOpen = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <div className="productgap--container">
        {/* <UseCaseHeader
          account={account}
          handleAdd={toggleDrawerOpen}
          setFilters={setFilters}
          type="Issues"
          addButtonlabel="Add / Edit Issues"
        /> */}
        <IssuesTable
          isPresentationView={true}
          dealDetails={account}
          handleAdd={toggleDrawerOpen}
        />
      </div>
      {showDrawer && (
        <DealIssuesDrawer
          dealDetails={account}
          showDrawer={showDrawer}
          closeDrawer={() => setShowDrawer(false)}
        />
      )}
    </>
  );
};
export default DealIssues;
