import React, { useState } from 'react';
import ProductGapItem from './ProductGapItem';
// import UpdateProductGap from './UpdateProductGap_legacy';
import UpdateProductGap from './UpdateProductGap';
import DeleteProductGap from './DeleteProductGap';
import AddNote from '../../common/notes/AddNote';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedCard,
  updateDealStage,
} from '../../Pipeline/pipelineSlice';
import { saveProductGap } from './productGapSlice';

const ProductGapList = ({
  list = [],
  filters = {},
  account,
  isPresentationView,
  isJiraIntegrated,
  handleUpdateSuccessCriteria,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const selectedCard = useSelector(selectSelectedCard);
  const [currentSelectedproductGap, setCurrentSelectedproductGap] =
    useState(null);
  const [showUpdatePgModal, setShowUpdatePgModal] = useState(false);
  const [showDeletePgModal, setShowDeletePgModal] = useState(false);
  // const [showAddNoteModal, setShowAddNoteModal] = useState(false);

  const updateSortingInPipeline = () => {
    if (selectedCard?.cardId) {
      dispatch(
        updateDealStage({
          smartSort: true,
          cardId: selectedCard?.cardId,
          sourceLaneId: selectedCard?.laneId,
        })
      );
    }
  };

  const editProductGap = (criteriaObj, ev) => {
    ev.stopPropagation();
    setShowUpdatePgModal(true);
    // setCurrentSelectedproductGap(criteriaObj);
    dispatch(saveProductGap({ ...criteriaObj, action: 'edit' }));
  };

  const deleteProductGap = (criteria, ev) => {
    ev.stopPropagation();
    setShowDeletePgModal(true);
    setCurrentSelectedproductGap(criteria);
  };

  const handleProductGapsAfterUpdating = () => {
    setShowUpdatePgModal(false);
    // setCurrentSelectedproductGap(null);
    dispatch(saveProductGap({}));
    updateSortingInPipeline();
  };

  const handleProductGapsAfterDeleting = () => {
    setShowDeletePgModal(false);
    setCurrentSelectedproductGap(null);
    updateSortingInPipeline();
    handleUpdateSuccessCriteria('pg-delete', {
      accountId: account?.accountId,
      dealId: account?.dealId,
    });
  };

  // const handleAddNoteOpen = (productGap) => {
  //   setShowAddNoteModal(true);
  //   setCurrentSelectedproductGap(productGap);
  // };

  // const handleAddNoteCancel = () => {
  //   setShowAddNoteModal(false);
  //   setCurrentSelectedproductGap(null);
  // };

  const handleCloseModal = (operation) => {
    if (operation === 'update') {
      setShowUpdatePgModal(false);
    } else if (operation === 'delete') {
      setShowDeletePgModal(false);
    }
    dispatch(saveProductGap({}));
  };

  return (
    <>
      <div className="productgap-list--container">
        {Array.isArray(list) &&
          list.length > 0 &&
          list.map((productGap) => {
            let productGapCopy = { ...productGap };
            let filteredUsecases = productGap.usecases
              .filter(
                (usecase) =>
                  filters?.features[
                    `${productGap.id}-${
                      Array.isArray(usecase.pgItem) &&
                      usecase.pgItem.length &&
                      usecase.pgItem[0].feature?.value
                    }`
                  ] || filters.features.all
              )
              .filter(
                (usecase) =>
                  filters?.score[usecase?.rating] || filters.score.all
              )
              .filter(({ useCase = '' }) =>
                useCase.toLowerCase().includes(filters.searchTerm.toLowerCase())
              );
            productGapCopy.usecases = filteredUsecases;

            return productGapCopy?.usecases?.length > 0 &&
              (filters?.modules.all || filters?.modules[productGap?.id]) ? (
              <ProductGapItem
                key={productGap?.id}
                productGap={productGapCopy}
                filters={filters}
                account={account}
                isPresentationView={isPresentationView}
                // handleAddNoteOpen={readOnly ? false : handleAddNoteOpen}
                editProductGap={readOnly ? false : editProductGap}
                deleteProductGap={readOnly ? false : deleteProductGap}
              />
            ) : null;
          })}
      </div>
      {showUpdatePgModal && (
        <UpdateProductGap
          showModal={showUpdatePgModal}
          isJiraIntegrated={isJiraIntegrated}
          account={account}
          // productGap={currentSelectedproductGap}
          handleProductGapsAfterUpdating={handleProductGapsAfterUpdating}
          handleCloseModal={handleCloseModal}
        />
      )}

      {showDeletePgModal && (
        <DeleteProductGap
          showModal={showDeletePgModal}
          productGap={currentSelectedproductGap}
          handleProductGapsAfterDeleting={handleProductGapsAfterDeleting}
          handleCloseModal={handleCloseModal}
        />
      )}
      {/* {showAddNoteModal && (
        <AddNote
          showModal={showAddNoteModal}
          handleClose={handleAddNoteCancel}
          accounts={[
            {
              ...account,
              productGap: currentSelectedproductGap,
            },
          ]}
          activeAccounts={[]}
        />
      )} */}
    </>
  );
};

export default ProductGapList;
