import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { ReactComponent as RightSVG } from '../../../Icons/right.svg';
import { ReactComponent as DownSVG } from '../../../Icons/NewImage/down.svg';
import Button from '../../Button';
import SearchField from '../../../Accounts/commons/SearchField';
import Loader from '../../Loader';
import useDataPersistance from '../../../hooks/useDataPersistance';
import { useGetProductGapModulesAndItemsQuery } from '../../../features/ProductGap/productGapSlice';
import { selectModulesAndItems } from '../../../features/ModulesManager/moduleFeaturesSlice';
import Drawer from '../../Drawer';

const ModulesFeaturesFilterDrawerForSolutions = ({
  filterFor,
  showFilters,
  onCancel = () => {},
  onSubmit = () => {},
  // onReset = () => {},
}) => {
  const handleSubmit = (filters) => {
    onSubmit(filters);
    return filters;
  };

  const { getStorageData, setStorageData } = useDataPersistance();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedModulesAndItems, setSelectedModulesAndItems] = useState({});
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  //TODO: selectAll and allModulesSelected stores the same data,
  //remove the duplicate state
  const allModulesSelected = useRef('selectedAll');
  const itemCountByModule = useRef({});

  const { data: modulesAndItems, isFetching: modulesAndItemsLoading } =
    useGetProductGapModulesAndItemsQuery(undefined, {
      selectFromResult: ({ data }) =>
        useMemo(() => selectModulesAndItems(data), [data]),
    });

  useEffect(() => {
    if (!filterFor) {
      return;
    }
    const filter = getStorageData(
      filterFor === 'solutions'
        ? 'solutionUseCases'
        : filterFor === 'productgaps'
        ? 'pgUseCases'
        : filterFor
    );
    if (filter?.itemIds?.length) {
      allModulesSelected.current = null;
    }

    const deselectAll = filter?.deselectALLModAndItems;
    allModulesSelected.current = deselectAll ? 'deselectedAll' : 'selectedAll';
    if (deselectAll) {
      setSelectAll(true);
    }
  }, []);

  //To retain the checked state of checkboxes
  useEffect(() => {
    const filterKey =
      filterFor === 'solutions'
        ? 'solutionUseCases'
        : filterFor === 'productgaps'
        ? 'pgUseCases'
        : filterFor;

    const filtersFromLS =
      filterKey && getStorageData(filterKey)?.modulesAndItems;
    if (filtersFromLS) {
      setSelectedModulesAndItems(filtersFromLS);
      return;
    }
    const selectedModulesAndItems = {};
    modulesAndItems.forEach((module) => {
      itemCountByModule.current[module.id] = module.pg.length;
      const pgmap = {};
      const pg = module.pg.map((item) => {
        pgmap[item.id] = true;
        return [item.id, true];
      });
      selectedModulesAndItems[module.id] = {
        state: 'checked',
        itemsShown: module.itemsShown,
        pg,
        pgmap,
      };
    });
    setSelectedModulesAndItems(selectedModulesAndItems);
  }, [modulesAndItems]);

  const handleSearch = (event) => {
    if (!event.target.value) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDropdown = (module) => {
    const selectedModulesAndItemsCopy = { ...selectedModulesAndItems };
    if (selectedModulesAndItemsCopy[module.id]) {
      selectedModulesAndItemsCopy[module.id] = {
        ...selectedModulesAndItemsCopy[module.id],
        itemsShown: !selectedModulesAndItemsCopy[module.id].itemsShown,
      };
    }
    setSelectedModulesAndItems(selectedModulesAndItemsCopy);
  };

  const handleModuleClick = (module, ev) => {
    /*
     * TODO: We will need to check the length of the modules to truly decide
     * if all modules have been selected
     * Now, if the user manually clicks all the modules or all the items
     * we will be sending all the item ids to the payload
     */
    allModulesSelected.current = null;
    let selectedMod = selected && selected[module.id];
    if (selectedMod) {
      selectedMod = {
        ...selectedMod,
        state: ev.target.checked ? 'checked' : false,
      };
    } else {
      selectedMod = {
        state: ev.target.checked ? 'checked' : false,
        itemsShown: module.itemsShown,
        pg: [],
        pgmap: {},
      };
    }

    Array.isArray(module.pg) &&
      module.pg.forEach((item) => {
        selectedMod.pgmap[item.id] = ev.target.checked;
        selectedMod.pg = Object.entries(selectedMod.pgmap);
      });
    setSelected((prev) =>
      prev
        ? { ...prev, [module.id]: selectedMod }
        : { [module.id]: selectedMod }
    );
  };

  const handleItemClick = (module, item, ev) => {
    /*
     * TODO: We will need to check the length of the modules to truly decide
     * if all modules have been selected
     * Now, if the user manually clicks all the modules or all the items
     * we will be sending all the item ids to the payload
     */
    allModulesSelected.current = null;
    let selectedMod = selected && selected[module.id];
    if (selectedMod) {
      selectedMod = {
        ...selectedMod,
        state: 'checked',
      };
    } else {
      selectedMod = {
        state: 'checked',
        pg: [],
        pgmap: {},
      };
    }

    selectedMod.pgmap[item.id] = ev.target.checked;
    selectedMod.pg = Object.entries(selectedMod.pgmap);

    let checkedItemsCount = 0;
    let uncheckedItemsCount = 0;
    selectedMod.pg.forEach((item) => {
      if (item[1]) {
        checkedItemsCount++;
      } else {
        uncheckedItemsCount++;
      }
    });

    let checked = false;
    let indeterminate = false;
    if (uncheckedItemsCount === itemCountByModule.current[module.id]) {
      checked = false;
      indeterminate = false;
    } else if (checkedItemsCount === itemCountByModule.current[module.id]) {
      checked = true;
      indeterminate = false;
    } else {
      checked = false;
      indeterminate = true;
    }

    selectedMod.state = indeterminate
      ? 'indeterminate'
      : checked
      ? 'checked'
      : false;
    setSelected((prev) =>
      prev
        ? { ...prev, [module.id]: selectedMod }
        : { [module.id]: selectedMod }
    );
  };

  const handleFilterApply = () => {
    const selectedModulesAndItemsCopy = { ...selectedModulesAndItems };
    let itemIds = [];
    for (let moduleId in selected) {
      selectedModulesAndItemsCopy[moduleId] = selected[moduleId];
    }
    if (allModulesSelected.current === 'selectedAll') {
      itemIds = undefined;
    }
    if (allModulesSelected.current === 'deselectedAll') {
      itemIds = [];
    }
    for (let moduleId in selectedModulesAndItemsCopy) {
      if (
        allModulesSelected.current === 'selectedAll' ||
        allModulesSelected.current === 'deselectedAll'
      ) {
        break;
      }
      selectedModulesAndItemsCopy[moduleId].pg.forEach((item) => {
        if (item[1]) {
          itemIds.push(item[0]);
        }
      });
    }
    setSelectedModulesAndItems(selectedModulesAndItemsCopy);
    setSelected(null);
    const filterKey =
      filterFor === 'solutions'
        ? 'solutionUseCases'
        : filterFor === 'productgaps'
        ? 'pgUseCases'
        : filterFor;
    setStorageData(filterKey, {
      modulesAndItems: selectedModulesAndItemsCopy,
      itemIds,
      deselectALLModAndItems: selectAll,
    });
    handleSubmit({
      itemIds,
    });
  };

  const handleSelectAll = () => {
    const isSelectAll = !!selectAll;
    const selectALLModAndItems = {};
    modulesAndItems.forEach((module) => {
      const pgmap = {};
      const pg = module.pg.map((item) => {
        pgmap[item.id] = isSelectAll;
        return [item.id, isSelectAll];
      });
      selectALLModAndItems[module.id] = {
        state: isSelectAll ? 'checked' : false,
        itemsShown: selectedModulesAndItems[module.id].itemsShown,
        pg,
        pgmap,
      };
    });
    if (isSelectAll) {
      allModulesSelected.current = 'selectedAll';
    } else {
      allModulesSelected.current = 'deselectedAll';
    }
    setSelectedModulesAndItems(selectALLModAndItems);
    setSelected(null);
    setSelectAll(!selectAll);
  };

  const [hasBanner, setHasBanner] = useState(false);
  useLayoutEffect(() => {
    setHasBanner(document.getElementsByClassName('layout--has-banner').length);
  }, []);

  return (
    <Drawer
      showDrawer={showFilters}
      className={`preskale-modules--filter${hasBanner ? ' has--banner' : ''}`}
      overlayClassName={`preskale-modules--filterOverlay${
        hasBanner ? ' has--banner' : ''
      }`}
      shouldCloseOnOverlayEscClick={onCancel}
      showHeader={false}
    >
      {modulesAndItemsLoading ? (
        <Loader customClass="pg-usecases-filter" />
      ) : (
        <>
          <div className="filter-button-container">
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button type="primary" onClick={handleFilterApply}>
              Apply
            </Button>
          </div>

          <SearchField
            placeholder="Search Modules and Features"
            onChange={handleSearch}
          />

          <Button type="link" onClick={handleSelectAll}>
            {selectAll ? 'Select All' : 'Deselect All'}
          </Button>

          <div className="module-and-items">
            {modulesAndItems.map((module) => {
              let searchedMod = searchTerm
                ? module.name && module.name.toLowerCase().includes(searchTerm)
                : true;
              const searchedItem = searchTerm
                ? !searchedMod &&
                  Array.isArray(module.pg) &&
                  module.pg.filter((item) =>
                    item.title.toLowerCase().includes(searchTerm)
                  )
                : [];
              const items = searchedItem.length
                ? searchedItem
                : Array.isArray(module.pg) && module.pg;
              return (
                (searchedMod || searchedItem.length > 0) && (
                  <div
                    key={module?.id}
                    className={
                      'module-list ' +
                      (selectedModulesAndItems[module.id]?.itemsShown
                        ? 'selected '
                        : '')
                    }
                  >
                    <div className="flex gap-1">
                      <div>
                        {' '}
                        <input
                          type="checkbox"
                          ref={(input) => {
                            if (input) {
                              input.indeterminate =
                                selected && selected[module.id]
                                  ? selected[module.id]?.state ===
                                    'indeterminate'
                                  : selectedModulesAndItems[module.id]
                                      ?.state === 'indeterminate';
                            }
                          }}
                          checked={
                            selected && selected[module.id]
                              ? selected[module.id]?.state ?? false
                              : selectedModulesAndItems[module.id]?.state ??
                                false
                          }
                          onChange={(ev) => handleModuleClick(module, ev)}
                        />
                      </div>
                      <div className="module-name"> {module.name} </div>
                      <button onClick={() => handleDropdown(module)}>
                        <>
                          {selectedModulesAndItems[module.id]?.itemsShown ? (
                            <span className="btn-icon down-btn">
                              {' '}
                              <DownSVG />{' '}
                            </span>
                          ) : (
                            <span className="btn-icon">
                              {' '}
                              <RightSVG />{' '}
                            </span>
                          )}
                        </>
                      </button>
                    </div>
                    {selectedModulesAndItems[module.id]?.itemsShown &&
                      items.map((item) => (
                        <div key={item.id} className="item-list flex gap-1">
                          <div>
                            {' '}
                            <input
                              type="checkbox"
                              checked={
                                selected && selected[module.id]
                                  ? selected[module.id]?.pgmap[item.id] ?? false
                                  : selectedModulesAndItems[module.id]?.pgmap[
                                      item.id
                                    ] ?? false
                              }
                              onChange={(ev) =>
                                handleItemClick(module, item, ev)
                              }
                            />
                          </div>
                          {item.title}
                        </div>
                      ))}
                  </div>
                )
              );
            })}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default ModulesFeaturesFilterDrawerForSolutions;
