import React, { useState, useEffect } from 'react';
import useDebounce from '../hooks/useDebounce';
import Chart from './Chart';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { REPORTING_DATE_OPERATOR_OPTIONS } from '../Constant/FilterOperators';
import { addDays } from 'date-fns';
import {
  getCurentWeekStartEnd,
  getCurrentMonthDates,
  getCurrentQuarterStartEnd,
  getNextMonthDates,
  getNextQuarterStartEnd,
  getNextWeekStartEnd,
  getPreviousQuarterStartEnd,
  getPreviousWeekStartEnd,
  PreviousMonthDates,
} from '../Utils/DateUtils';

export const CalendarReporting = ({ reports }) => {
  const [chartsInfo, setChartsInfo] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [filter, setFilter] = useState({});
  const debouncedFilter = useDebounce(filter, 2000);
  const [chartError, setChartError] = useState({});

  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState(null);
  const charts = [
    'Total Events',
    'Event vs Outcomes',
    'Opportunity Win-Loss',
    'Revenue Win-Loss',
    'Outcomes vs Users',
    'Outcome Win-Loss',
  ];

  //set CurrentMonth as default filter
  useEffect(() => {
    const { currentMonthstart, currentMonthEnd } = getCurrentMonthDates();
    currentMonthstart && currentMonthstart?.setHours(0, 0, 0, 0);
    const to =
      currentMonthEnd &&
      addDays(new Date(currentMonthEnd?.setHours(0, 0, 0, 0)), 1);
    setStartDate(currentMonthstart);
    setEndDate(currentMonthEnd);

    updateFilter({
      start_time: {
        $gte: currentMonthstart,
        $lte: to,
      },
    });
  }, []);

  useEffect(() => {
    startDate && endDate?.setHours(0, 0, 0, 0);
    const to = endDate && addDays(new Date(endDate?.setHours(0, 0, 0, 0)), 1);
    updateFilter({
      start_time: {
        $gte: startDate && new Date(startDate),
        $lte: endDate && new Date(to),
      },
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (!reports || !Array.isArray(reports)) {
      return;
    }
    const charts = {};
    reports.forEach(({ module, chart_id, chart_url }) => {
      charts[module] = { chartID: chart_id, chartURL: chart_url };
    });
    setChartsInfo(charts);
  }, [reports]);

  const handleChartError = (chartID) => (err) =>
    setChartError((prev) => ({ ...prev, [chartID]: err }));

  const handleOnChange = (option) => {
    setSelectedRange(option);
    if (option?.value === 'THIS_QUARTER') {
      const { startOfCurrentQuarter, endOfCurrentQuarter } =
        getCurrentQuarterStartEnd();
      setStartDate(startOfCurrentQuarter);
      setEndDate(endOfCurrentQuarter);
    }
    if (option?.value === 'NEXT_QUARTER') {
      const { nextQuarterStart, nextQuarterEnd } = getNextQuarterStartEnd();
      setStartDate(nextQuarterStart);
      setEndDate(nextQuarterEnd);
    }
    if (option?.value === 'PREVIOUS_QUARTER') {
      const { previousQuarterStart, previousQuarterEnd } =
        getPreviousQuarterStartEnd();
      setStartDate(previousQuarterStart);
      setEndDate(previousQuarterEnd);
    }
    if (option?.value === 'THIS_MONTH') {
      const { currentMonthstart, currentMonthEnd } = getCurrentMonthDates();
      setStartDate(currentMonthstart);
      setEndDate(currentMonthEnd);
    }
    if (option?.value === 'PREVIOUS_MONTH') {
      const { previousMonthStart, previousMonthEnd } = PreviousMonthDates();
      setStartDate(previousMonthStart);
      setEndDate(previousMonthEnd);
    }
    if (option?.value === 'NEXT_MONTH') {
      const { nextMonthStartDate, nextMonthEndDate } = getNextMonthDates();
      setStartDate(nextMonthStartDate);
      setEndDate(nextMonthEndDate);
    }
    if (option?.value === 'PREVIOUS_WEEK') {
      const { startOfPreviousWeek, endOfPreviousWeek } =
        getPreviousWeekStartEnd();
      setStartDate(startOfPreviousWeek);
      setEndDate(endOfPreviousWeek);
    }
    if (option?.value === 'THIS_WEEK') {
      const { currentWeekStartDate, currentWeekEndDate } =
        getCurentWeekStartEnd();
      setStartDate(currentWeekStartDate);
      setEndDate(currentWeekEndDate);
    }
    if (option?.value === 'NEXT_WEEK') {
      const { startOfNextWeek, endOfNextWeek } = getNextWeekStartEnd();
      setStartDate(startOfNextWeek);
      setEndDate(endOfNextWeek);
    }
    if (option?.value === 'CUSTOM') {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const updateFilter = (values = {}) => {
    const filterCopy = { ...filter };
    for (let key in values) {
      if (!values[key]) {
        filterCopy[key] && delete filterCopy[key];
      } else {
        filterCopy[key] = values[key];
      }
    }
    setFilter(filterCopy);
  };

  return (
    <>
      <div className="dashboard-reporting">
        <div className="flex gap-default mb-30">
          <div>
            <label className="flex mb-10"> Date Range</label>
            <Select
              isClearable={true}
              isSearchable={false}
              placeholder="Date Range"
              className="dropdown"
              options={REPORTING_DATE_OPERATOR_OPTIONS}
              values={selectedRange}
              onChange={handleOnChange}
              defaultValue={REPORTING_DATE_OPERATOR_OPTIONS[4]}
            />
          </div>
          {selectedRange?.value === 'CUSTOM' && (
            <div>
              <div className="datepicker mt-22">
                <DatePicker
                  selected={startDate || new Date()}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  isClearable={true}
                />
              </div>
            </div>
          )}
        </div>

        <div className="charts-container">
          {chartsInfo &&
            charts.map((module, index) => {
              return !chartError[chartsInfo[module]?.chartID] &&
                chartsInfo[module]?.chartURL ? (
                <div
                  className={
                    index === 4 || index === 5 ? 'width-full' : 'width-50'
                  }
                  key={module}
                >
                  <Chart
                    height="500px"
                    width="100%"
                    filter={debouncedFilter}
                    chartURL={chartsInfo[module]?.chartURL}
                    chartID={chartsInfo[module]?.chartID}
                    chartError={chartError[chartsInfo[module]?.chartID]}
                    setChartError={handleChartError(
                      chartsInfo[module]?.chartID
                    )}
                  />
                </div>
              ) : (
                <div key={module} className="alert">
                  Failed to load chart: Invalid chart url
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
