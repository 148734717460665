import React, { useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import { ReactComponent as LeftArrowSVG } from '../../Icons/ArrowLeft.svg';
import {
  useFetchJiraIssueTypesQuery,
  useFetchJiraProjectsQuery,
  saveRetainValueBoolean,
  useFetchJiraUsersQuery,
  productGapState,
  useUpdateProductGapItemJiraMutation,
} from '../../features/ProductGap/productGapSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import Button from '../../common/Button';
import { axiosDE } from '../../_helpers/axios';
import { createJiraIssue } from '../../_services/common.service';
import { noData } from '../../Utils/common';
import { toast } from 'react-toastify';

const JiraForm = ({
  handleJiraSubmit,
  handleBacktoJiralist,
  isJiraReporterDisable,
  onSelect,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { loading: submitting, ...submitNewJiraIssue } = useAxios(axiosDE);
  const profileData = JSON.parse(localStorage.getItem('user'));
  const userId = profileData?.userId;
  const companyId = profileData?.companyId;
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [jiraModel, setJiraModel] = useState({
    project: '',
    issueType: '',
    summary: null,
    description: null,
  });

  const { data: projects, isFetching: loadingProjects } =
    useFetchJiraProjectsQuery();

  const { data: issueTypes, isFetching: loadingIssueTypes } =
    useFetchJiraIssueTypesQuery(
      { projectId: jiraModel?.project?.projectId },
      { skip: !jiraModel?.project, refetchOnMountOrArgChange: true }
    );

  const { data: jiraUsers, isFetching: loadingJiraUsers } =
    useFetchJiraUsersQuery();

  const { selectedProductGap } = useSelector(productGapState);
  const [updatePgItemJira] = useUpdateProductGapItemJiraMutation();

  const selectJira = (selectedJiraIssue) => {
    if (onSelect) {
      onSelect({
        jiraTitle: selectedJiraIssue?.summary,
        jiraType: selectedJiraIssue?.issuetype?.name,
        jiraIssueId: selectedJiraIssue?.issue_id,
        jiraIssueKey: selectedJiraIssue?.key,
      });
      onSuccess();
      return;
    }
    if (!selectedProductGap?.id) {
      toast.error('Error: Product gap item not selected!');
      return;
    }
    updatePgItemJira({
      id: selectedProductGap?.id,
      jiraTitle: selectedJiraIssue?.summary,
      jiraType: selectedJiraIssue?.issuetype?.name,
      jiraIssueId: selectedJiraIssue?.issue_id,
      jiraIssueKey: selectedJiraIssue?.key,
    })
      .unwrap()
      .then(() => {
        toast.success('Jira associated successfully!');
        onSuccess();
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Jira not associated!');
      });
  };

  useEffect(() => {
    // Check if the submit button to add product gap should be disabled
    shouldDisableSubmit(Object.values(jiraModel));
    return () => {
      setDisableSubmit(true);
    };
  }, [Object.values(jiraModel)]);

  const shouldDisableSubmit = (requiredFieldValues = []) => {
    const requiredValues = [...requiredFieldValues];
    const isTrue = requiredValues.every((value) => value);
    setDisableSubmit(!isTrue);
  };

  const handleOnChange = (key, value) =>
    setJiraModel((prev) => ({ ...prev, [key]: value }));

  const handleSubmit = (e) => {
    dispatch(saveRetainValueBoolean(true));
    e.preventDefault();

    let data = {
      userId: userId,
      company_id: companyId,
      fields: {
        project: {
          key: jiraModel.project.key,
        },
        summary: jiraModel.summary,
        description: jiraModel.description,
        issuetype: {
          name: jiraModel.issueType.name,
        },
      },
    };

    if (jiraModel.reporter && !isJiraReporterDisable) {
      data.fields.reporter = {
        id: jiraModel.reporter.accountId,
      };
    }

    const handleSuccess = (data) => {
      if (Array.isArray(data) && data.length > 0) {
        let { key, summary, project, issuetype, issue_id } = data[0];
        let responseData = {
          radioSelected: 'checked', // This data is to preslect the radio button
          issue_id: issue_id,
          key: key,
          summary,
          project: project,
          issuetype: issuetype,
        };

        if (data[0].reporter) {
          responseData.reporter = data[0].reporter;
        }

        handleJiraSubmit(responseData);
        selectJira(data[0]);
      }
    };

    createJiraIssue(submitNewJiraIssue, data, handleSuccess);
  };

  return (
    <>
      <div className="modal-header">
        <button
          className="btn btn-secondary pg-jira-back"
          onClick={handleBacktoJiralist}
        >
          <LeftArrowSVG />
        </button>
        <h2 className="heading_level5 text-dark flex-align-center">
          Associate Jira Ticket
        </h2>
      </div>
      <h4 className="heading_level3 text-dark">Create new Jira Ticket</h4>
      <div className="modal-body form create-jira-ticket">
        <div className="pg-jira-issue-details">
          <div className="form-field">
            <label> Project </label>

            <Select
              options={projects}
              value={jiraModel.project}
              loading={loadingProjects}
              labelField="name"
              valueField="key"
              onChange={(data) => handleOnChange('project', data[0])}
              keepSelectedInList={false}
            />
          </div>

          <div className="form-field">
            <label> Issue Type </label>

            <Select
              options={issueTypes}
              value={jiraModel.issueType}
              loading={loadingIssueTypes}
              disabled={!jiraModel.project}
              className={
                !jiraModel.project ? 'disabled opacity-full-forced' : ''
              }
              labelField="name"
              valueField="name"
              onChange={(data) => {
                handleOnChange('issueType', data[0]);
              }}
              keepSelectedInList={false}
            />
          </div>

          {!isJiraReporterDisable && (
            <div className="form-field">
              <label> Reporter </label>
              <Select
                options={jiraUsers}
                value={jiraModel.reporter}
                loading={loadingJiraUsers}
                disabled={!jiraModel.project || isJiraReporterDisable}
                className={
                  !jiraModel.project || isJiraReporterDisable
                    ? 'disabled opacity-full-forced'
                    : ''
                }
                labelField="displayName"
                valueField="accountId"
                onChange={(data) => {
                  handleOnChange('reporter', data[0]);
                }}
                keepSelectedInList={false}
                noDataRenderer={() => noData('No users to select')}
              />
            </div>
          )}
          <div className="form-field">
            <label> Summary </label>
            <input
              type="text"
              value={jiraModel.summary || ''}
              disabled={!jiraModel.issueType}
              className={
                !jiraModel.issueType ? 'disabled opacity-full-forced' : ''
              }
              onChange={(event) =>
                handleOnChange('summary', event.target.value)
              }
              required
            />
          </div>

          <div className="form-field">
            <label> Description </label>
            <textarea
              value={jiraModel.description || ''}
              disabled={!jiraModel.issueType}
              className={
                !jiraModel.issueType ? 'disabled opacity-full-forced' : ''
              }
              onChange={(event) =>
                handleOnChange('description', event.target.value)
              }
              rows={6}
            />
          </div>
        </div>
        <div className="pg-jira-btn-wrapper flex-justify-end gap-default mt-15">
          <Button disabled={submitting} onClick={handleBacktoJiralist}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={submitting}
            disabled={submitting || disableSubmit}
            className=""
            onClick={(e) => handleSubmit(e)}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  );
};

export default JiraForm;
