import { axiosUser } from '../_helpers/axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAndResetState } from '../Utils/common';
import authenticationService from '../_services/authentication.service';
import { setCredentials } from '../Authorize/authSlice';

const useRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const refresh = () =>
    axiosUser
      .post('refresh', {
        userName: userDetails?.email,
        refreshToken: refreshToken,
      })
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem('idToken', res?.data?.data?.idToken);
          authenticationService.setRefreshedIdToken(res?.data?.data?.idToken);
          dispatch(setCredentials(res?.data?.data));
          return res?.data?.data?.idToken;
        }
      })
      .catch((err) => {
        console.error('refresh-token: response', err?.response);
        console.error('refresh-token: data', err?.response?.data);
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.errorMessage === 'REFRESH_TOKEN_EXPIRED'
        ) {
          logoutAndResetState(dispatch, history);
          history.push('/login', { state: { from: location }, replace: true });
        }
        if (err?.response?.status === 500) {
          console.error('Something went wrong on our end!');
        }
      });
  return refresh;
};

export default useRefreshToken;
