import { createSlice } from '@reduxjs/toolkit';
import { businessService } from '../../app/api/businessService';
import { logout } from '../../Authorize/authSlice';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

const workbookSliceInitialState = {
  tasksDealList: [
    {
      label: 'All Accounts',
      value: 'ALL',
    },
  ],
};

const workbookSlice = createSlice({
  name: 'workbook',
  initialState: workbookSliceInitialState,
  reducers: {
    saveDealList(state, action) {
      // Dropdown in task manager
      state.tasksDealList = [
        {
          label: 'All Accounts',
          value: 'ALL',
        },
        ...action.payload,
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state = { tasksDealList: [] };
      return state;
    });
  },
});

export const extendedWorkbookSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllDeals: builder.query({
      query: () => URLS_BUSINESS_SERVICE.accounts.allDeals,
      transformResponse: (responseData) => {
        const accountSummary = [];
        const opportunityLabelMap = {};
        const activeAccounts = [];

        Array.isArray(responseData?.data) &&
          responseData?.data?.forEach((deal) => {
            const accountObject = {};
            accountObject.value = deal.id;
            accountObject.label = deal.display;
            accountObject.accountId = deal.accountId;
            accountSummary.push(accountObject);

            if (deal.status !== 'Closed Won' && deal.status !== 'Closed Lost') {
              const activeDeal = {};
              activeDeal.id = deal.id;
              activeDeal.value = deal.display;
              activeDeal.status = deal.status;
              activeDeal.accountId = deal.accountId;
              activeAccounts.push(activeDeal);
            }

            const opportunityObject = {};
            opportunityObject[deal.id] = deal.display;
            Object.assign(opportunityLabelMap, opportunityObject);
          });
        return {
          accountSummary,
          activeAccounts,
          opportunityLabelMap,
          accounts: responseData?.data || [],
        };
      },
      providesTags: (result = []) => {
        return Array.isArray(result?.accounts)
          ? [
              ...result?.accounts.map(({ id }) => ({
                type: 'Deals',
                id,
              })),
              { type: 'Deals', id: 'All' },
            ]
          : [{ type: 'Deals', id: 'All' }];
      },
    }),
    fetchDealOverviewAccounts: builder.query({
      query: () => URLS_BUSINESS_SERVICE.accounts.dealOverview,
      providesTags: (result = []) => {
        return result
          ? [
              ...result.map(({ accountId, dealId }) => ({
                type: 'Deals',
                id: `${accountId}-${dealId}`,
              })),
              { type: 'Deals', id: 'Overview' },
            ]
          : [{ type: 'Deals', id: 'Overview' }];
      },
      transformResponse: (responseData) => responseData?.dealOverviewList || [],
    }),
    fetchAllAccounts: builder.query({
      query: ({ includeMetadata } = {}) => {
        return `${URLS_BUSINESS_SERVICE.accounts.getAllAccounts}${
          includeMetadata ? `?createOpportunity=${includeMetadata}` : ''
        }`;
      },
      transformResponse: (responseData) => {
        const accountLabelMap = {};
        Array.isArray(responseData?.data) &&
          responseData?.data?.forEach((account) => {
            const accountObject = {};
            accountObject[account.value] = account;
            Object.assign(accountLabelMap, accountObject);
          });
        return {
          accountLabelMap,
          allAccounts: responseData?.data || [],
        };
      },
      providesTags: () => [{ type: 'Accounts', id: 'All' }],
    }),
  }),
});

export const {
  useFetchDealOverviewAccountsQuery,
  useFetchAllDealsQuery,
  useFetchAllAccountsQuery,
  util,
} = extendedWorkbookSlice;

export const selectDealList = (state) => state.workbook.tasksDealList;

export const { saveDealList } = workbookSlice.actions;
export default workbookSlice.reducer;
