export default function emptyHyphen(data) {
  return data ? data : '--';
}

export function emptyHyphenWithKey(data, key) {
  return data && data[key] ? data[key] : '--';
}

export function emptyHyphenForArray(data) {
  return data && data.length > 0 ? data : '--';
}

export function emptyHyphenForRevenue(data) {
  return data ? '$' + data : '--';
}
