import React, { useState, useEffect } from 'react';
import Table from '../common/Table';

import {
  trendsEventsColumn,
  allMeetingsColumn,
  trendsSuccessItemColumn,
  trendsProductGapColumn,
  trendsOutcomesColumn,
} from '../Constant/PerformanceUtilisation';
import { convertMsToHM, getDateByOperator } from '../Utils/DateUtils';
import { DATE_OPERATOR_OPTIONS } from '../Constant/FilterOperators';
import Dropdown from 'react-dropdown';
import Loader from '../common/Loader';
import NoContent from '../Accounts/commons/NoContent';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxios from '../hooks/useAxios';
import {
  getManagerTrendsEvents,
  getManagerTrendsOutcomes,
  getManagerTrendsSuccessItems,
  getTrendsEvents,
  getTrendsOutcomes,
  getTrendsProductGap,
  getTrendsSuccessItems,
} from '../_services/reports.service';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';

export default function Influence(props) {
  const axios = useAxiosPrivate();
  const {
    response: trendsEvents,
    loading: trendsEventsLoading,
    ...eventsService
  } = useAxios(axios);
  const {
    response: trendsOutcome,
    loading: trendsOutcomesLoading,
    ...outcomesService
  } = useAxios(axios);
  const {
    response: trendsSuccessItem,
    loading: trendsSuccessItemLoading,
    ...successItemService
  } = useAxios(axios);
  const {
    response: trendsProductGap,
    loading: trendsProductGapLoading,
    ...productGapService
  } = useAxios(axios);
  const {
    response: managerTrendsSuccessItem,
    loading: manSuccessItemsLoading,
    ...manSuccessItemsService
  } = useAxios(axios);
  const {
    response: managerTrendsEvents,
    loading: eventsManagerLoading,
    ...eventsManagerService
  } = useAxios(axios);
  const {
    response: managerTrendsOutcomes,
    loading: outcomesManagerLoading,
    ...outcomesManagerService
  } = useAxios(axios);

  const [reportingFilter, setReportingFilter] = useState(props.reportingFilter);
  const [loader, setLoader] = useState(false);
  const [isAdminOrManagerOrProduct, setAdminOrManagerOrProduct] = useState(
    props.isAdminOrManagerOrProduct
  );
  const [userList, setUserList] = useState(props.userList);
  const [filterUserList, setFilterUserList] = useState(props.filterUserList);
  const [userFilter, setUserFilter] = useState(props.userFilter);
  const [selectedUserFilter, setSelectedUserFilter] = useState(
    props.selectedUserFilter
  );
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  useEffect(() => {
    let filter = _setDateFilter(reportingFilter);
    _setUserFilter();
    if (isAdminOrManagerOrProduct) {
      getManagerTrendsEvents(eventsManagerService, getFilter());
      if (selectedUserFilter === 'ALL_USERS') {
        constructSuccessItemColumn();
        constructOutcomesColumn();
        getManagerTrendsSuccessItems(manSuccessItemsService, getFilter());
        getManagerTrendsOutcomes(outcomesManagerService, getFilter());
      } else {
        getTrendsOutcomes(outcomesService, getFilter());
        getTrendsSuccessItems(successItemService, getFilter());
        getTrendsProductGap(productGapService, getFilter());
      }
    } else {
      getTrendsEvents(eventsService, constructDate());
      getTrendsOutcomes(outcomesService, getFilter());
      getTrendsSuccessItems(successItemService, getFilter());
      getTrendsProductGap(productGapService, getFilter());
    }
    props.updateReportingFilter(filter);
  }, [reportingFilter, selectedUserFilter]);

  const getFilter = () => {
    if (isAdminOrManagerOrProduct) return constructFilter();
    else return constructDate();
  };

  const constructSuccessItemColumn = () => {
    let columnData = [];
    let columnJson = {};
    let initialHeader = {};
    let overallTrend = {};

    initialHeader.Header = 'Success Items';
    initialHeader.accessor = 'successItemName';

    columnData.push(initialHeader);

    overallTrend.Header = 'Overall Trend';
    overallTrend.columns = [
      {
        Header: 'Utilization',
        accessor: 'utilization[0]',
      },
      {
        Header: 'Success Pulse',
        id: 'successPulse' + 0,
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span className="tag tag-green opp-cell">
              {' '}
              {row.original.successPulse[0]}{' '}
            </span>
          </span>
        ),
      },
    ];
    columnData.push(overallTrend);

    filterUserList.map((user, i) => {
      i++;
      columnJson.Header = user.name;
      columnJson.columns = [
        {
          Header: 'Utilization',
          accessor: 'utilization[' + i + ']',
        },
        {
          Header: 'Success Pulse',
          id: 'successPulse' + i,
          Cell: ({ row }) => (
            <span className="flex-align-center">
              <span className="tag tag-green opp-cell">
                {' '}
                {row.original.successPulse[i]}{' '}
              </span>
            </span>
          ),
        },
      ];
      columnData.push({ ...columnJson });
    });

    return columnData;
  };

  const constructOutcomesColumn = () => {
    let columnData = [];
    let columnJson = {};
    let initialHeader = {};
    let overallTrend = {};

    initialHeader.Header = 'Outcomes';
    initialHeader.accessor = 'outcomeName';

    columnData.push(initialHeader);

    overallTrend.Header = 'Overall Trend';
    overallTrend.columns = [
      {
        Header: 'Utilization',
        accessor: 'utilization[0]',
      },
      {
        Header: 'Total Meetings',
        id: 'totalMeeting' + 0,
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.totalMeetings[0]} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.timeOfTotalMeetings[0])}{' '}
            </span>
          </span>
        ),
      },
    ];
    columnData.push(overallTrend);

    filterUserList.map((user, i) => {
      i++;
      columnJson.Header = user.name;
      columnJson.columns = [
        {
          Header: 'Utilization',
          accessor: 'utilization[' + i + ']',
        },
        {
          Header: 'Total Meetings',
          id: 'totalMeeting' + i,
          Cell: ({ row }) => (
            <span className="flex-align-center">
              <span> {row.original.totalMeetings[i]} </span>
              <span className="tag tag-light-gray opp-cell">
                {' '}
                {convertMsToHM(row.original.timeOfTotalMeetings[i])}{' '}
              </span>
            </span>
          ),
        },
      ];
      columnData.push({ ...columnJson });
    });

    return columnData;
  };

  const onDropdownSelect = (objectType, option) => {
    let filter = { ...reportingFilter };
    switch (objectType) {
      case 'date-range':
        filter.dateRange = option.value;
        let temp = _setDateFilter(filter);
        setReportingFilter(temp);
        break;
      case 'user-filter':
        setSelectedUserFilter(option.value);
        props.updateSelectedUserFilter(option.value);
        updateUserListByUserFilter(option);
        break;
    }
  };

  const updateUserListByUserFilter = (option) => {
    switch (option.value) {
      case 'ALL_USERS':
        setFilterUserList(userList);
        break;
      case 'OVERALL_TREND':
        setFilterUserList(userList);
        break;
      default:
        userList.map((user) => {
          if (user.id === option.value) {
            setFilterUserList([user]);
          }
        });
        break;
    }

    props.changeFilterUserList(option.value);
  };

  const _setDateFilter = (filter) => {
    const dateRange = getDateByOperator(filter.dateRange);
    filter.fromDate = dateRange.fromDate;
    filter.toDate = dateRange.toDate;
    setReportingFilter(filter);
    return filter;
  };

  const _setUserFilter = () => {
    userList.map((user) => {
      if (user.id === selectedUserFilter) {
        setFilterUserList([user]);
      }
    });
  };

  const constructDate = () => {
    let filter = {
      fromDate: reportingFilter.fromDate,
      toDate: reportingFilter.toDate,
    };
    return filter;
  };

  const constructFilter = () => {
    let filter = {
      fromDate: reportingFilter.fromDate,
      toDate: reportingFilter.toDate,
      userList: setupFilterUserList(),
    };
    return filter;
  };

  const setupFilterUserList = () => {
    let userIdList = [];
    filterUserList.map((user) => {
      userIdList.push(user.id);
    });
    return userIdList;
  };

  return (
    <div className="performance-style">
      {loader ? (
        <Loader></Loader>
      ) : (
        <div>
          <div className="header">
            <div className="heading">Influence</div>

            {isAdminOrManagerOrProduct && (
              <div className="dropdown team-header">
                <Dropdown
                  options={userFilter}
                  value={selectedUserFilter}
                  onChange={onDropdownSelect.bind(this, 'user-filter')}
                />
              </div>
            )}

            <div className="date-range">Date Range</div>
            <div className="dropdown">
              <Dropdown
                options={DATE_OPERATOR_OPTIONS}
                value={reportingFilter.dateRange}
                onChange={onDropdownSelect.bind(this, 'date-range')}
              />
            </div>
          </div>
          <div className="influence-style">
            <div className="heading">Trends</div>
            {configFeatures?.data?.navPages?.dashboard && (
              <>
                <div className="sub-heading">Events</div>
                <div className="trends-event-table">
                  {isAdminOrManagerOrProduct
                    ? managerTrendsEvents !== null && (
                        <Table
                          data={managerTrendsEvents}
                          columns={trendsEventsColumn}
                          className="sales-trend-table"
                        />
                      )
                    : trendsEvents !== null && (
                        <Table
                          data={[trendsEvents]}
                          columns={trendsEventsColumn}
                          className="sales-trend-table"
                        />
                      )}
                </div>

                <div className="sub-heading">Outcomes</div>

                {isAdminOrManagerOrProduct &&
                selectedUserFilter === 'ALL_USERS' ? (
                  <div className="trends-event-table">
                    {managerTrendsOutcomes && (
                      <div className="table-scroll">
                        <Table
                          data={managerTrendsOutcomes || []}
                          columns={constructOutcomesColumn()}
                          className={
                            managerTrendsOutcomes.length === 0
                              ? 'no-items'
                              : 'sales-trend-table'
                          }
                        />{' '}
                      </div>
                    )}
                    {managerTrendsOutcomes &&
                      managerTrendsOutcomes.length === 0 && (
                        <NoContent
                          className="no-data-table"
                          message="No data to display"
                        />
                      )}
                  </div>
                ) : (
                  <div className="trends-event-table">
                    {trendsOutcome && (
                      <div className="table-scroll">
                        <Table
                          data={trendsOutcome?.reportingOutcome || []}
                          columns={trendsOutcomesColumn}
                          className={
                            trendsOutcome.length === 0
                              ? 'no-items'
                              : 'sales-trend-table'
                          }
                        />{' '}
                      </div>
                    )}
                    {trendsOutcome && trendsOutcome.length === 0 && (
                      <NoContent
                        className="no-data-table"
                        message="No data to display"
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {/* {configFeatures?.data?.featureList?.is_solution_enabled && (
              <>
                <div className="sub-heading">Success Item Manager</div>

                {isAdminOrManagerOrProduct &&
                selectedUserFilter === 'ALL_USERS' ? (
                  <div className="trends-event-table">
                    {managerTrendsSuccessItem && (
                      <div className="table-scroll">
                        <Table
                          data={managerTrendsSuccessItem || []}
                          columns={constructSuccessItemColumn()}
                          className={
                            managerTrendsSuccessItem.length === 0
                              ? 'no-items'
                              : 'sales-trend-table'
                          }
                        />{' '}
                      </div>
                    )}
                    {managerTrendsSuccessItem &&
                      managerTrendsSuccessItem.length == 0 && (
                        <NoContent
                          className="no-data-table"
                          message="No data to display"
                        />
                      )}
                  </div>
                ) : (
                  <div className="trends-event-table">
                    {trendsSuccessItem && (
                      <div className="table-scroll">
                        <Table
                          data={trendsSuccessItem || []}
                          columns={trendsSuccessItemColumn}
                          className={
                            trendsSuccessItem.length === 0
                              ? 'no-items'
                              : 'sales-trend-table'
                          }
                        />{' '}
                      </div>
                    )}
                    {trendsSuccessItem && trendsSuccessItem.length == 0 && (
                      <NoContent
                        className="no-data-table"
                        message="No data to display"
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {configFeatures?.data?.featureList?.is_product_gap_enabled && (
              <>
                <div className="sub-heading">Product Gap Manager</div>

                {!isAdminOrManagerOrProduct ||
                selectedUserFilter != 'ALL_USERS' ? (
                  <div className="trends-event-table">
                    {trendsProductGap && (
                      <div className="table-scroll">
                        <Table
                          data={trendsProductGap || []}
                          columns={trendsProductGapColumn}
                          className={
                            trendsProductGap.length === 0
                              ? 'no-items'
                              : 'sales-trend-table'
                          }
                        />{' '}
                      </div>
                    )}
                    {trendsProductGap && trendsProductGap.length == 0 && (
                      <NoContent
                        className="no-data-table"
                        message="No data to display"
                      />
                    )}
                  </div>
                ) : null}
              </>
            )} */}
            {/* <div className='trends-event-table'>
                    {allMeetings != null && <Table data={allMeetings} columns={allMeetingsColumn} className="sales-trend-table" />}
                </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
