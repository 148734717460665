const getRtfProperties = (activeAccounts) => {
  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'align',
    'link',
    'mention',
  ];

  const modules = {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
        ['link'],
        ['clean'],
      ],
    },

    mention: {
      allowedChars: /^[A-Za-z]*/,
      mentionDenotationChars: ['@'],
      positioningStrategy: 'fixed',
      source: function (searchTerm, renderItem, mentionChar) {
        if (activeAccounts) {
          let values;
          if (mentionChar === '@') {
            values = activeAccounts;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderItem(matches, searchTerm);
          }
        }
      },
    },
  };

  return { formats, modules };
};

export default getRtfProperties;
