import ReactModal from 'react-modal';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Dropdown from 'react-dropdown';
import { useTimeSpentSolutionMutation } from './solutionSlice';
import {
  HOURS_OPTIONS,
  MINUTES_OPTIONS,
  HOURS_DEFAULT_OPTIONS,
  MINUTES_DEFAULT_OPTIONS,
} from '../../Constant/HoursAndMinutes';

const DEFAULT_TIME = {
  hours: 0,
  minutes: 0,
};

const TimeSpentPopUp = ({
  timeSpentPopUp,
  selectedSolution,
  account,
  handlePopUpCancel,
  handleSuccess,
}) => {
  const [timeSpent] = useTimeSpentSolutionMutation();
  const [completionDate, setCompletionDate] = useState(DEFAULT_TIME);

  const handleHoursChange = (option) => {
    setCompletionDate((prev) => ({ ...prev, hours: option.value }));
  };

  const handleMinutesChange = (option) => {
    setCompletionDate((prev) => ({ ...prev, minutes: option.value }));
  };

  const handleTimeSpentPopoverCancel = () => {
    handlePopUpCancel(false);
  };

  const handleTimeSpentPopUp = () => {
    const requestData = {
      moduleId: selectedSolution?.id,
      accountId: account?.accountId,
      dealId: account?.dealId,
      timeSpent:
        parseInt(completionDate.hours) * 60 + parseInt(completionDate.minutes),
    };
    timeSpent(requestData)
      .unwrap()
      .then((res) => {
        if (res?.status) {
          if (typeof handleSuccess === 'function') {
            handleSuccess();
          }
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
    handlePopUpCancel(false);
  };

  return (
    <ReactModal
      isOpen={timeSpentPopUp}
      className="Modal confirm-box task-time-spent"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Add time spent</h4>
      </div>

      <div className="modal-body form">
        <div className="flex-align-center">
          <Dropdown
            className="task-hours"
            options={HOURS_OPTIONS}
            onChange={(option) => handleHoursChange(option)}
            value={HOURS_DEFAULT_OPTIONS}
          />
          <span> Hrs. </span>

          <Dropdown
            className="task-min time-min"
            options={MINUTES_OPTIONS}
            onChange={(option) => handleMinutesChange(option)}
            value={MINUTES_DEFAULT_OPTIONS}
          />
          <span> Min. </span>
        </div>
      </div>

      <div className="modal-footer">
        <button
          className="btn btn-secondary"
          onClick={handleTimeSpentPopoverCancel}
        >
          {' '}
          Cancel{' '}
        </button>
        <button className="btn btn-primary" onClick={handleTimeSpentPopUp}>
          {' '}
          Done{' '}
        </button>
      </div>
    </ReactModal>
  );
};

export default TimeSpentPopUp;
