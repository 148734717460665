import React from 'react';
import ReactModal from 'react-modal';
import Select from 'react-dropdown-select';
import { roles } from '../Constant/Users';
import 'react-dropdown/style.css';

const AddUserModal = ({
  isOpen,
  onSubmit,
  onChange,
  onSelect,
  onCancel = () => {},
  isLoading,
  input,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className="Modal confirm-box"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h4 className="modal-title">Add User Detail</h4>
      </div>
      <form onSubmit={onSubmit}>
        <div className="modal-body form">
          <div className="form-field">
            <label> Username </label>
            <input
              type="text"
              name="username"
              value={input.username}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-field">
            <label> Email </label>
            <input
              type="text"
              name="email"
              value={input.email}
              onChange={onChange}
              required
            />
          </div>

          <div className="form-field">
            <label> Role </label>
            <Select
              options={roles}
              value={input.role}
              onChange={onSelect}
              keepSelectedInList={false}
              required
            />
          </div>
        </div>

        <div className="modal-footer">
          <button
            disabled={isLoading}
            className="btn btn-secondary"
            onClick={onCancel}
          >
            {' '}
            Cancel{' '}
          </button>
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-primary"
          >
            {' '}
            Add Item{' '}
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default AddUserModal;
