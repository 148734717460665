import React from 'react';

export default (props) => {
  return props.value ? (
    <div
      className="aggrid-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>{props.value}</span>
      </p>
      <div />
    </div>
  ) : null;
};
