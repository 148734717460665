import React, { useMemo } from 'react';
import {
  useFetchBasicAccountDetailsQuery,
  useFetchSalesOwnersQuery,
} from '../Accounts/accountsSlice';
import { useFetchAllAccountsQuery } from '../features/workbook/workbookSlice';

const useFetchOptionsForCreateOpportunity = () => {
  const { data: salesOwners, isLoading: salesOwnersLoading } =
    useFetchSalesOwnersQuery({
      needEmail: true,
    });

  const { data: basicAccountDetails } = useFetchBasicAccountDetailsQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const options = useMemo(() => {
          let optionIndustry = [];
          let optionCountry = [];
          optionIndustry = data?.data?.industry?.map((industry) => ({
            label: industry,
            value: industry,
          }));
          optionCountry = data?.data?.region?.map((country) => ({
            label: country,
            value: country,
          }));
          return { optionIndustry, optionCountry };
        }, [data]);
        return { data: options };
      },
    }
  );

  const {
    data: { allAccounts = [], accountLabelMap = {} } = {
      allAccounts: null,
      accountLabelMap: null,
    },
    isLoading: accountsLoading,
  } = useFetchAllAccountsQuery({ includeMetadata: true });

  return {
    allAccounts,
    industries: basicAccountDetails?.optionIndustry,
    countries: basicAccountDetails?.optionCountry,
    salesOwners,
    accountsLoading,
  };
};

export default useFetchOptionsForCreateOpportunity;
