import React from 'react';
import stringToHslColor from '../_helpers/stringToHslColor';

const Avatar = (props) => {
  const name = props?.userName || props?.user?.userName || '--';
  const letter =
    name &&
    name
      .split(' ')
      .map((w) => w.charAt(0))
      .join('');

  const hexColor = name && stringToHslColor(name);
  const borderColor = name && stringToHslColor(name, 30, 50);

  const size = props?.size || 'medium';

  const positionLeft = props?.positionLeft;

  return (
    <div
      className={`user-avatar ${size}`}
      style={{
        backgroundColor: hexColor,
        border: `1px solid ${borderColor}`,
        left: `${positionLeft}px`,
      }}
    >
      <div className="initials">{letter}</div>
    </div>
  );
};

export default Avatar;
