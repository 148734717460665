import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AdminOpsCard } from './AdminOpsCard';
import { AdminOpsTabs } from './AdminOpsTabs';
import useDataPersistance from '../hooks/useDataPersistance';
import { STATUS_OPTIONS } from './utils/AdminOpsConstants';

const DetailView = () => {
  const location = useLocation();
  const { getStorageData } = useDataPersistance();
  const selectedCompany = getStorageData('companyData')
    ? getStorageData('companyData')
    : location?.state?.companyData;
  const initialCompanyStatus = STATUS_OPTIONS.find(
    (data) => data.value === selectedCompany?.status
  );
  const [companyStatus, setCompanyStatus] = useState(
    initialCompanyStatus || null
  );

  return (
    <>
      <div className="admin-ops-header font-18 font-bold">
        Detail View for Company - {selectedCompany?.name}
      </div>
      {selectedCompany?.id && (
        <AdminOpsCard
          companyData={selectedCompany}
          setCompanyStatus={setCompanyStatus}
          companyStatus={companyStatus}
        />
      )}
      <div className="admin-ops-tabs">
        <AdminOpsTabs
          companyData={selectedCompany}
          companyStatus={companyStatus}
        />
      </div>
    </>
  );
};

export default DetailView;
