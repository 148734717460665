import ReactQuill from 'react-quill';

var Link = ReactQuill.Quill.import('formats/link');

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (!(value.startsWith('http') || value.startsWith('https'))) {
      value = 'https://' + value;
    }
    node.setAttribute('href', value);
    return node;
  }
}

export default MyLink;
