import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Fallback from '../../Utils/FallBack';
import AuthorizationService from '../../_services/authentication.service';

export const FeatureRouteAccess = ({
  children,
  component,
  featureAccess,
  landingPage,
  ...rest
}) => {
  const { authenticated } = AuthorizationService.getSessionData() || {};

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => {
        if (!authenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
        /*
         * We are checking for a boolean specifically to wait for the data from config features
         * This way the fallback will not render on initial load when featureAccess will be undefined
         * Ideally we should show a loading indicator if config data is not available yet
         */
        let hasAccess = false;
        if (typeof featureAccess === 'function') {
          hasAccess = featureAccess(props);
        } else {
          hasAccess = featureAccess;
        }
        if (hasAccess === false) {
          return <Fallback landingPage={landingPage} />;
        }
        return component;
      }}
    ></Route>
  );
};
