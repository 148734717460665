import React from 'react';
import { convertMsToHM } from '../Utils/DateUtils';
import { DateFormat } from '../Utils/DayJs';

const teamWorkloadColumn = [
  {
    Header: 'Presales',
    accessor: 'salesEngineer',
  },
  {
    Header: 'Focus Tasks',
    accessor: 'focusTasks',
  },
  {
    Header: 'Focus Meetings',
    columns: [
      {
        Header: 'Total Meetings',
        id: 'commitedHours',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.totalMeetCount} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.totalMeetDuration)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'External',
        id: 'external',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.externalCount} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.externalDuration)}{' '}
            </span>
          </span>
        ),
      },
      {
        Header: 'Internal',
        id: 'internal',
        Cell: ({ row }) => (
          <span className="flex-align-center">
            <span> {row.original.internalCount} </span>
            <span className="tag tag-light-gray opp-cell">
              {' '}
              {convertMsToHM(row.original.internalDuration)}{' '}
            </span>
          </span>
        ),
      },
    ],
  },
];

export { teamWorkloadColumn };
