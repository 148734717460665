import { businessService } from '../../app/api/businessService';
import { URLS_BUSINESS_SERVICE } from '../../Constant/urls';

export const extendedCustomSectionSlice = businessService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllSectionsData: builder.query({
      query: () => URLS_BUSINESS_SERVICE.customSection.gellAllSections,
      transformResponse: (response) => response?.data || [],
      providesTags: () => {
        return [{ type: 'CustomSection', id: 'allSections' }];
      },
    }),
    saveCustomFieldValues: builder.mutation({
      query: (body) => ({
        url: URLS_BUSINESS_SERVICE.customSection.saveCustomFieldValues,
        method: 'POST',
        body,
      }),
    }),
    checkCustomSectionEnabled: builder.query({
      query: () => URLS_BUSINESS_SERVICE.customSection.isCustomSectionEnabled,
    }),
  }),
});

export const {
  useFetchAllSectionsDataQuery,
  useSaveCustomFieldValuesMutation,
  useCheckCustomSectionEnabledQuery,
} = extendedCustomSectionSlice;
