import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessItemsRatingReadOnly } from '../../common/Rating';
import { useLazyFetchSimilarUsecasesQuery } from './solutionSlice';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';
import { createMarkup } from '../../Utils/common';
import { selectUserMap, useGetUsersQuery } from '../../app/api/usersSlice';
import Button from '../../common/Button';
import { solutionState, saveSelectedModule } from './solutionSlice';

const SimilarUsecases = ({ account, moduleId, handleAddModuleCancel }) => {
  const dispatch = useDispatch();
  const { selectedModule } = useSelector(solutionState);
  const [similarUsecases, setSimilarUsecases] = useState([]);
  const handleCancel = () => {
    handleAddModuleCancel();
    dispatch(saveSelectedModule([]));
  };

  const [fetchSimilarUsecases] = useLazyFetchSimilarUsecasesQuery();

  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });

  useEffect(() => {
    if (selectedModule) getSimilarUsecases();
  }, [selectedModule]);

  const getSimilarUsecases = () => {
    fetchSimilarUsecases({
      dealId: account?.dealId,
      moduleId: selectedModule[0]?.value ? selectedModule[0]?.value : moduleId,
      industry: account?.additionalDetails?.industry
        ? account?.additionalDetails?.industry
        : '',
      region: account?.additionalDetails?.country
        ? account?.additionalDetails?.country
        : '',
    })
      .unwrap()
      .then((res) => {
        setSimilarUsecases(res?.data);
      });
  };

  return (
    <div className="modal-right-pane">
      <div className="modal-header pg-header flex">
        <h4 className="modal-title-pg">Similar Use-cases</h4>
        <div className="b-4 ml-auto">
          <Button onClick={handleCancel} className="close-icon">
            <CrossSVG />
          </Button>
        </div>
      </div>
      <div className="flex justify-space-between">
        <div className="flex flex-wrap">
          <h4 className="modal-title-pg mr-5">Industry: </h4>
          <span className="mr-10">{account?.additionalDetails?.industry}</span>
        </div>
        <div className="flex flex-wrap">
          <h4 className="modal-title-pg mr-5">Region: </h4>
          <span className="mr-10">{account?.additionalDetails?.country}</span>
        </div>
        <div className="flex flex-wrap">
          <h4 className="modal-title-pg mr-5">Module: </h4>
          <span className="mr-10">
            {selectedModule && selectedModule[0]?.label}
          </span>
        </div>
      </div>
      <div className="similar-usecases mt-10 vertical-scroll  modal-body">
        {similarUsecases &&
          similarUsecases?.map((usecase, i) => (
            <div
              key={`similar-usecase${i}`}
              className="similar-usecase mt-10 p-10 b-4 pointer"
            >
              <div className="flex justify-space-between">
                <span className="deal-heading">
                  {usecase?.usecases?.dealName}
                </span>
                <div className="rating">
                  <span className="flex-align-center usecase-rating">
                    <SuccessItemsRatingReadOnly
                      rating={usecase?.usecases?.rating}
                    />
                  </span>
                </div>
              </div>
              <div className="vertical-border mt-15 mb-5"></div>
              <div
                className="usecase-comments"
                dangerouslySetInnerHTML={createMarkup(
                  usecase?.usecases?.usecase
                )}
              ></div>
              <div className="vertical-border mt-15 mb-5"></div>
              <div className="flex">
                <span>{usecase?.usecases?.createdDate}</span>
                <span className="ml-auto">
                  {userMap[usecase?.usecases?.createdBy] &&
                    userMap[usecase?.usecases?.createdBy].name}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SimilarUsecases;
