import React, { useEffect } from 'react';
import { useState } from 'react';
import { ReactComponent as UpArrowSVG } from '../../Icons/ArrowUpSmall.svg';
import { ReactComponent as DownArrowSVG } from '../../Icons/ArrowDownSmall.svg';
import { ReactComponent as UnionSVG } from '../../Icons/Union.svg';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import Button from '../../common/Button';
import ToolTip from '../../common/ToolTip';

const Accordion = ({
  activeModule,
  accordionData,
  featureChange,
  selectModule,
  selectedModuleFeature,
  activeFeature,
  pgItemsCount,
}) => {
  const [isActive, setIsActive] = useState(accordionData?.id === activeModule);

  useEffect(() => {
    if (activeModule === accordionData?.id) {
      setupInitialModuleFeature();
    }
    if (
      activeModule === accordionData?.id ||
      (activeModule !== accordionData?.id && isActive)
    ) {
      setIsActive(accordionData?.id === activeModule);
    }
  }, [activeModule]);

  const handleModuleClick = () => {
    let moduleFeatureJson = {
      moduleTitle: accordionData?.moduleTitle,
      moduleId: accordionData?.id,
    };
    selectedModuleFeature(moduleFeatureJson);
  };

  const setupInitialModuleFeature = () => {
    if (accordionData?.features?.length > 0) {
      handleFeatureChange(
        accordionData?.features[0]?.value,
        accordionData?.features[0]?.label
      );
    }
  };

  const handleFeatureChange = (featureId, featureTitle) => {
    // setActiveFeature(featureId);
    featureChange(featureId);
    let moduleFeatureJson = {
      moduleTitle: accordionData?.moduleTitle,
      moduleId: accordionData?.id,
      featureId,
      featureTitle,
    };
    selectedModuleFeature(moduleFeatureJson);
  };

  const handleUpdateModule = () => {
    selectModule(accordionData);
  };

  return (
    <div className="accordion-parent">
      <div
        className={`module flex pointer ${isActive ? 'module-active' : ''}`}
        onClick={handleModuleClick}
      >
        <div className="title">
          {isActive && <UnionSVG />}
          <div
            className={`ml-10 ${
              accordionData?.disabled ? 'disabled-item' : ''
            } `}
          >
            {accordionData?.moduleTitle} ({accordionData?.features?.length}){' '}
            {accordionData?.disabled ? '(Disabled)' : ''}
          </div>
        </div>
        <div className="ml-auto flex-align-center gap-default  mr-10">
          {isActive && (
            <ToolTip text="Edit module">
              <Button
                onClick={handleUpdateModule}
                type="text"
                icon
                className="btn-icon-only"
              >
                <EditSVG />
              </Button>
            </ToolTip>
          )}

          <button>{isActive ? <UpArrowSVG /> : <DownArrowSVG />}</button>
        </div>
      </div>
      <>
        {isActive && (
          <ul className="items_container">
            {accordionData &&
              accordionData?.features?.map((moduleFeatures) => (
                <li
                  key={moduleFeatures?.value}
                  className={`feature pointer ${
                    moduleFeatures?.disabled || accordionData?.disabled
                      ? 'disabled-item'
                      : ''
                  } ${
                    activeFeature === moduleFeatures?.value
                      ? 'feature-active'
                      : ''
                  }`}
                  onClick={() =>
                    handleFeatureChange(
                      moduleFeatures?.value,
                      moduleFeatures?.label
                    )
                  }
                >
                  {moduleFeatures?.label} {} (
                  {pgItemsCount[moduleFeatures?.value] ?? 'No Product Gaps'})
                  {moduleFeatures?.disabled ? ' (Disabled)' : ''}
                </li>
              ))}
          </ul>
        )}
      </>
    </div>
  );
};

export default Accordion;
