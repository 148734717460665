import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { ReactComponent as ProfileSVG } from '../Icons/profile.svg';
import { ReactComponent as CalendarLinkSVG } from '../Icons/NewImage/calendar-link.svg';
import { ReactComponent as GlobeSVG } from '../Icons/NewImage/globe.svg';
import { useUpdateProfileMutation } from '../app/api/usersSlice';
import { toast } from 'react-toastify';
import TimezoneSelect from 'react-timezone-select';
import emptyHyphen from '../_helpers/empty-hyphen';
import Button from '../common/Button';
import PreskaleToolTip from '../common/PreskaleToolTip';

export const ProfileInfoCard = forwardRef(({ profile, isLoading }, ref) => {
  const [updateProfile] = useUpdateProfileMutation();
  const [calendarLink, setCalendarLink] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  // Holds saved timezone.
  const timeZone = useRef(null);
  // Holds saved calendly link.
  const calendlyRef = useRef(null);
  const [submittedTimeZone, setSubmittedTimeZone] = useState(false);
  const [submittedCalendarLink, setSubmittedCalendarLink] = useState(false);

  useEffect(() => {
    if (profile?.calendlyLink) {
      calendlyRef.current = profile?.calendlyLink;
      setCalendarLink(profile?.calendlyLink);
    }
    if (profile?.timeZone) {
      timeZone.current = profile?.timeZone;
      setSelectedTimezone(profile?.timeZone);
    }
  }, [profile]);

  const saveProfileInformation = (calendarLinkInput, timeZoneInput) => {
    const body = { id: profile?.id };
    if (calendarLinkInput) {
      setSubmittedCalendarLink(true);
      body.calendarLink = calendarLinkInput;
    }
    if (timeZoneInput) {
      setSubmittedTimeZone(true);
      body.timeZone = timeZoneInput;
    }
    updateProfile(body)
      .unwrap()
      .then((data) =>
        handleSaveProfileInfoSuccess(data, timeZoneInput, calendarLinkInput)
      )
      .catch(() => toast.error('Unable to update profile!'));
  };

  const handleSaveProfileInfoSuccess = (
    data,
    timeZoneInput,
    calendarLinkInput
  ) => {
    if (calendarLinkInput) {
      calendlyRef.current = calendarLinkInput;
      setSubmittedCalendarLink(false);
    }
    if (timeZoneInput) {
      timeZone.current = timeZoneInput;
      setSubmittedTimeZone(false);
    }
    toast.success(data?.message);
  };

  const handleCalendarLinkChange = (event) => {
    setCalendarLink(event.target.value);
  };

  const handleTimeZoneChange = (event) => {
    setSelectedTimezone(event?.value);
  };

  return (
    <div className="main-content" ref={ref} id="my_profile">
      <div className="content-title">
        <ProfileSVG />
        <span className="content-label">My Profile</span>
      </div>
      <div className="profile-info" id="my_profile">
        <div className="profile-left-info">
          <div className="profile-content">
            <label>Full Name</label>
            <div className="text-weight-semi-bold text-lg pt-10">
              {emptyHyphen(profile?.fullName)}
            </div>
          </div>
          <div className="profile-content">
            <label>Company Name</label>
            <div className="text-weight-semi-bold text-lg pt-10">
              {emptyHyphen(profile?.companyName)}
            </div>
          </div>
          <div className="profile-content">
            <label>Role</label>
            <div className="text-weight-semi-bold text-lg pt-10">
              {emptyHyphen(profile?.roles)}
            </div>
          </div>
          <div className="profile-content">
            <label>Team</label>
            <div className="text-weight-semi-bold text-lg pt-10">
              {(profile &&
                Array.isArray(profile?.teamName) &&
                profile?.teamName.join(',')) ||
                '--'}
            </div>
          </div>
        </div>
        <div className="profile-right-info">
          <div className="profile-content">
            <label>Email Address</label>
            <div className="email pt-10">{emptyHyphen(profile?.email)}</div>
          </div>
          <div className="profile-content flex flex-column ">
            <span className="flex-align-center">
              <CalendarLinkSVG />
              <label className="pl-5">Calendly Link</label>
              <PreskaleToolTip toolTipMessage="If Resource Assignment has been enabled, Sales Owners will receive this link over email on assignment." />
            </span>
            <div className="flex-align-center mt-10">
              <input
                type="text"
                className="input-field width-80"
                value={calendarLink || ''}
                onChange={(event) => handleCalendarLinkChange(event)}
              />
              {calendarLink !== calendlyRef.current && (
                <Button
                  type="primary"
                  className="ml-10"
                  onClick={() => saveProfileInformation(calendarLink)}
                  disabled={submittedCalendarLink}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
          <div className="profile-content  flex flex-column ">
            <span className="flex-align-center">
              <GlobeSVG />
              <label className="pl-5">Time Zone</label>
            </span>
            <div className="flex-align-center mt-10">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={handleTimeZoneChange}
                className="text-weight-semi-bold"
              />
              {selectedTimezone !== timeZone.current && (
                <Button
                  type="primary"
                  className="ml-10"
                  onClick={() => saveProfileInformation(null, selectedTimezone)}
                  disabled={submittedTimeZone}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
