import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import axiosService from '../_services/axios.service';
import { useHistory } from 'react-router-dom';
import authenticationService from '../_services/authentication.service';
import { createCompany } from '../_services/common.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxios from '../hooks/useAxios';
import { useCreateTeamMutation } from '../Settings/Teams/teamSlice';

const INSTANCE_NAME =
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? 'DEMO'
    : process.env.REACT_APP_ENV === 'SANDBOX'
    ? 'TRIAL'
    : process.env.REACT_APP_ENV === 'APPUAT'
    ? 'UAT'
    : 'PROD';

export default function CreateCompany() {
  const history = useHistory();
  const axios = useAxiosPrivate();
  const createCompanyService = useAxios(axios);

  const [userpool, setUserpool] = useState(false);

  const [createTeam] = useCreateTeamMutation();

  const addCompany = (values) => {
    const adminValues = {
      userName: values.userName,
      email: values.email,
      role: 'admin',
      company: '',
      communityEdition: values.isCommunityEdition,
      userpoolId: values?.userpoolId,
      userpoolClientId: values?.userpoolClientId,
      userpoolClientSecret: values?.userpoolClientSecret,
    };
    const data = {
      name: values.companyName,
      noOfUsers: values.noOfUsers,
      presalesUserField: values.presalesUserField,
      isCustomFieldPicklist:
        values.isCustomFieldPicklist === 'true' ? true : false,
      instance: INSTANCE_NAME,
      communityEdition: values.isCommunityEdition,
    };
    createCompany(createCompanyService, data, (response) => {
      adminValues.company = response?.data?.id;
      if (!adminValues.company) {
        toast.error('Company id not present. Cannot register admin user.');
        return;
      }
      authenticationService.register(adminValues).then((data) => {
        console.log(' -------- success page ---- ', data);
        if (data?.status) {
          createTeam({
            teamName: values.companyName,
            teamDescription: values.companyName,
            companyId: data?.data?.companyId,
            userId: data?.data?.id,
            teamManagers: [
              {
                id: data?.data?.id,
                email: data?.data?.email,
                userName: data?.data?.userName,
                name: data?.data?.name,
                roles: data?.data?.roles,
              },
            ],
          })
            .unwrap()
            .then((res) => {
              history.push('/login');
            });
        }
      });
      toast.success('Company Added');
    });
  };

  return (
    <div className="signup-content create-company-content">
      <h2 className="layout-title">Create Company</h2>

      <Formik
        initialValues={{
          companyName: '',
          noOfUsers: 0,
          userName: '',
          email: '',
          presalesUserField: '',
          isCustomFieldPicklist: 'false',
          isCommunityEdition: false,
          userpool: false,
          userpoolId: '',
          userpoolClientId: '',
          userpoolClientSecret: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.companyName) {
            errors.companyName = 'Company Name is Required';
          }
          if (values.noOfUsers === 0 || !values.noOfUsers) {
            errors.noOfUsers = 'No. of Users Invalid';
          }
          if (!values.userName) {
            errors.userName = 'Username field is Required';
          }
          if (!values.email) {
            errors.email = 'Email field is Required';
          }
          // if (!values.presalesUserField) {
          //   errors.presalesUserField = 'Presales User Field is Required';
          // }
          if (!values.isCustomFieldPicklist) {
            errors.isCustomFieldPicklist = 'Custom field type is Required';
          }
          if (values.userpool && !values.userpoolId) {
            errors.userpool = 'UserpoolId is Required';
          }
          if (values.userpool && !values.userpoolClientId) {
            errors.userpool = 'Userpool client id is Required';
          }
          if (values.userpool && !values.userpoolClientSecret) {
            errors.userpool = 'Userpool client secret is Required';
          }
          return errors;
        }}
        onSubmit={(values, { setStatus }) => {
          setStatus();
          addCompany(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form>
            <div className="login-fields required-field">
              <label htmlFor="companyName"> Company Name </label>
              <Field
                type="text"
                name="companyName"
                className={
                  'form-control' +
                  (errors.companyName && touched.companyName
                    ? ' is-invalid'
                    : '')
                }
              />
              <ErrorMessage
                name="companyName"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="login-fields required-field">
              <label htmlFor="noOfUsers"> Number Of Users </label>
              <Field
                type="number"
                name="noOfUsers"
                className={
                  'form-control' +
                  (errors.noOfUsers && touched.noOfUsers ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name="noOfUsers"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="login-fields">
              <label htmlFor="presalesUserField"> Presales User Field </label>
              <Field
                type="text"
                name="presalesUserField"
                className={
                  'form-control' +
                  (errors.presalesUserField && touched.presalesUserField
                    ? ' is-invalid'
                    : '')
                }
              />
              {/* <ErrorMessage
                name="presalesUserField"
                component="div"
                className="invalid-feedback"
              /> */}
            </div>
            <div className="login-fields required-field">
              <label htmlFor="isCustomFieldPicklist"> Custom Field Type </label>
              <div className="radio-container">
                <Field
                  type="radio"
                  name="isCustomFieldPicklist"
                  value="false"
                  className={
                    'form-control preskale-radio mr-5' +
                    (errors.isCustomFieldPicklist &&
                    touched.isCustomFieldPicklist
                      ? ' is-invalid'
                      : '')
                  }
                />
                <span className="mr-5">Userlist</span>
                <Field
                  type="radio"
                  name="isCustomFieldPicklist"
                  value="true"
                  className={
                    'form-control preskale-radio mr-5' +
                    (errors.isCustomFieldPicklist &&
                    touched.isCustomFieldPicklist
                      ? ' is-invalid'
                      : '')
                  }
                />
                <span>Picklist</span>
              </div>
              <ErrorMessage
                name="isCustomFieldPicklist"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="login-fields required-field">
              <label htmlFor="userName"> Admin Username </label>
              <Field
                type="text"
                name="userName"
                className={
                  'form-control' +
                  (errors.userName && touched.userName ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name="userName"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="login-fields required-field">
              <label htmlFor="email"> Admin Email </label>
              <Field
                type="text"
                name="email"
                className={
                  'form-control' +
                  (errors.email && touched.email ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="">
              <label htmlFor="userpool"> Userpool </label>
              <div className="radio-container">
                <input
                  type="radio"
                  name="jira-issue"
                  checked={!userpool}
                  className="mb-0 width-unset"
                  value={userpool}
                  onChange={() => setUserpool(false)}
                />{' '}
                <span className="ml-5">Common Userpool</span>
                <input
                  type="radio"
                  name="jira-issue"
                  checked={userpool}
                  className="mb-0 ml-20 width-unset"
                  value={userpool}
                  onChange={() => setUserpool(true)}
                />{' '}
                <span className="ml-5">Separate Userpool</span>
              </div>
              <ErrorMessage
                name="userpool"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {userpool && (
              <div>
                <div className="login-fields required-field">
                  <label htmlFor="userpoolId"> Userpool Id </label>
                  <Field
                    type="text"
                    name="userpoolId"
                    className={
                      'form-control' +
                      (errors.userpoolId && touched.userpoolId
                        ? ' is-invalid'
                        : '')
                    }
                  />
                  <ErrorMessage
                    name="userpoolId"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="login-fields required-field">
                  <label htmlFor="userpoolClientId"> Userpool Client Id </label>
                  <Field
                    type="text"
                    name="userpoolClientId"
                    className={
                      'form-control' +
                      (errors.userpoolClientId && touched.userpoolClientId
                        ? ' is-invalid'
                        : '')
                    }
                  />
                  <ErrorMessage
                    name="userpoolClientId"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="login-fields required-field">
                  <label htmlFor="userpoolClientSecret">
                    {' '}
                    Userpool Client Secret{' '}
                  </label>
                  <Field
                    type="text"
                    name="userpoolClientSecret"
                    className={
                      'form-control' +
                      (errors.userpoolClientSecret &&
                      touched.userpoolClientSecret
                        ? ' is-invalid'
                        : '')
                    }
                  />
                  <ErrorMessage
                    name="userpoolClientSecret"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            )}

            <div className="flex">
              <label className="community-edition">
                <Field type="checkbox" name="isCommunityEdition" />
              </label>
              Community Edition
            </div>

            <div className="login-fields">
              <button
                type="submit"
                className="submit-btn"
                disabled={isSubmitting}
              >
                {' '}
                Create Company{' '}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
