import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import Avatar from '../../common/Avatar';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as AddGreenSVG } from '../../Icons/NewImage/add-green.svg';
import { ReactComponent as SetTeamSVG } from '../../Icons/Team-Setting.svg';
import { ReactComponent as FilterSVG } from '../../Icons/Filter.svg';
import UserPanel from '../../common/Modals/UserPanel';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { addTeamMember, getTeam } from '../../_services/common.service';
import { useGetUsersQuery } from '../../app/api/usersSlice';
import { toast } from 'react-toastify';
import {
  useFetchPipelineAccessibilityQuery,
  useUpdatePipelineAccessibilityMutation,
} from '../../app/api/teamsSlice';
import { PIPELINE_ACCESSIBILITY } from '../../Constant/PipelineAccessibilityConstants';

export default function TeamsDescription(props) {
  const axios = useAxiosPrivate();
  const fetchTeam = useAxios(axios);
  const addUserToTeam = useAxios(axios);
  const location = useLocation();
  const teamId = location.state.teamId;
  const [teamData, setTeamData] = useState([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [showAddUserPanel, setShowAddUserPanel] = useState(false);
  const [addUserList, setAddUserList] = useState([]);
  const [lastLoggedIn, setLastLoggedIn] = useState(new Date().toString());

  const [updatePipelineAccessibility] =
    useUpdatePipelineAccessibilityMutation();

  const { data: users } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => {
      const usersData =
        data?.entities &&
        Object.values(data?.entities).map((item) => {
          return {
            value: item.id,
            label: item.email,
            userName: item.userName,
            name: item.name,
            roles: item.roles,
          };
        });
      return { data: usersData || [] };
    },
  });
  const [usersList, setUsersList] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [selectedPipelineAccessibility, setSelectedPipelineAccessibility] =
    useState();

  const territoriesList = [
    {
      id: 1,
      name: 'New York',
    },
    {
      id: 2,
      name: 'Delhi',
    },
    {
      id: 3,
      name: 'Gujarat',
    },
    {
      id: 4,
      name: 'Tamil Nadu',
    },
    {
      id: 5,
      name: 'Rohtak',
    },
    {
      id: 6,
      name: 'Vadodara',
    },
  ];

  const { data: pipelineAccessibility } = useFetchPipelineAccessibilityQuery();

  const onChangePipelineAccessibility = (option) => {
    setSelectedPipelineAccessibility(option);

    updatePipelineAccessibility({
      pipelineAccessibility: option?.value,
    })
      .unwrap()
      .then(() => {
        toast.success('Pipeline Accessibility updated successfully!');
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Pipeline Accessibility Updated!');
      });
  };

  useEffect(() => {
    if (pipelineAccessibility?.data === 'user') {
      setSelectedPipelineAccessibility([
        {
          label: 'User',
          value: 'user',
        },
      ]);
    } else {
      setSelectedPipelineAccessibility([
        {
          label: 'Manager',
          value: 'manager',
        },
      ]);
    }
  }, [pipelineAccessibility]);

  useEffect(() => {
    getTeam(fetchTeam, teamId, getTeamData);
  }, [teamId]);

  useEffect(() => {
    setUsersList(users);
    setFilteredUsersList(users);
  }, [users.length]);

  const getTeamData = (data) => {
    setTeamData(data.data);
    setFilteredTeamMembers(data.data.teamMembers);
  };

  const handleAddUSer = () => {
    let updatedUsers = [];
    updatedUsers = usersList.filter(
      (val) => !teamData.teamMembers.some((val2) => val.value === val2.id)
    );
    setUsersList(updatedUsers);
    setFilteredUsersList(updatedUsers);
    setShowAddUserPanel(true);
  };
  const handleCancel = () => {
    setShowAddUserPanel(false);
    setAddUserList([]);
  };
  const handleCheckBoxClick = (user) => {
    const userInfo = addUserList;
    const index = addUserList.findIndex(
      (existingUser) => existingUser.value === user.value
    );
    if (index < 0) {
      userInfo.push(user);
    } else {
      userInfo.splice(index, 1);
    }
    setAddUserList(userInfo);
  };

  const handleSearch = async (event) => {
    if (!event.target.value) {
      setFilteredTeamMembers(teamData.teamMembers);
      return;
    }
    setSearchString(event.target.value);
    let text = searchString.trim().toLowerCase();
    let users = teamData.teamMembers;
    if (text.length > 0) {
      users = users.filter((user) => {
        return (
          user.userName.toLowerCase().match(text) ||
          user.email.toLowerCase().match(text)
        );
      });
    }
    setFilteredTeamMembers(users);
  };

  const handleAddUserSearch = async (event) => {
    if (!event.target.value) {
      setFilteredUsersList(usersList);
      return;
    }
    let text = event.target.value.trim().toLowerCase();
    if (text.length > 0) {
      const filteredUsers = usersList.filter((user) => {
        return (
          user.name.toLowerCase().match(text) ||
          user.label.toLowerCase().match(text)
        );
      });
      setFilteredUsersList(filteredUsers);
    }
  };

  const addUser = async () => {
    let teamMembers = [];
    addUserList.map((user) => {
      teamMembers.push({
        id: user.value,
        email: user.label,
        userName: user.userName,
        name: user.name,
        roles: user.roles,
      });
    });
    let data = {
      id: teamId,
      teamMembers: teamMembers,
    };
    addTeamMember(addUserToTeam, data, () => {
      setShowAddUserPanel(false);
      setAddUserList([]);
      getTeam(fetchTeam, teamId, getTeamData);
    });
  };

  return (
    <div className="team-desc-parent">
      <div className="app-secondery-header"></div>
      <div className="team-content-parent">
        <div className="team-container">
          <div className="teams-title">{teamData.teamName}</div>
          <div className="team-desc">{teamData.teamDescription}</div>

          <div className="team-user-details">
            <div className="team-members">
              <span className="team-members-number">
                {teamData &&
                  teamData.teamMembers &&
                  teamData.teamMembers.length}
              </span>{' '}
              <div>Total Members</div>
            </div>
            <div className="team-members">
              <span className="team-members-number">0</span> <div>Admin</div>
            </div>
            <div className="team-members">
              <span className="team-members-number">0</span> <div>Agents</div>
            </div>
          </div>

          <div className="team-handler">
            <div className="search-container">
              <SearchSVG />{' '}
              <input
                placeholder="Search by email or name"
                onChange={(event) => handleSearch(event)}
              />
            </div>
            <div className="team-tools">
              <button
                className="btn btn-icon btn-transparent"
                onClick={() => handleAddUSer()}
              >
                <span>
                  <AddGreenSVG />
                </span>
                <span className="btn-txt"> Add new member</span>
              </button>

              <button className="btn btn-icon btn-transparent">
                <span className="btn-txt"> Export CSV</span>
              </button>

              <div className="team-setting">
                <button className="btn-setting">
                  <span>
                    <SetTeamSVG />
                  </span>
                </button>
              </div>

              <div className="team-setting">
                <button className="btn-setting">
                  <span>
                    <FilterSVG />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <UserPanel
            showAddUserPanel={showAddUserPanel}
            handleAddUserSearch={handleAddUserSearch}
            handleCheckBoxClick={handleCheckBoxClick}
            handleCancel={handleCancel}
            usersList={filteredUsersList}
            addUser={addUser}
          />
          {/* <ReactModal
                        isOpen={showAddUserPanel}
                        className="Modal confirm-box"
                        overlayClassName="Overlay confirm-box-overlay"
                    >
                        <div className="modal-header">
                            <h4 className="modal-title">
                                Add new member
                            </h4>
                            <div className="team-create-user-heading">Create new user</div>
                        </div>
                        <form>
                            <div className="form add-user-modal">
                                <div className='search-container add-user-search'>
                                    <div><SearchSVG /> <input placeholder="Search by email or name" onChange={(event) => handleAddUserSearch(event)} /></div>
                                </div>
                                <div className="form-field">
                                    {filteredUsersList.map((user) =>
                                        <div className='fields'>
                                            <input name="completed" type="checkbox" className="checkbox-position" onClick={() => handleCheckBoxClick(user)} />
                                            <Avatar userName={user.name} />
                                            <div className='add-user-list'><span className='add-user-name-heading'>{user.name}</span> ({user.label})</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => handleCancel()}> Cancel </button>
                                <button type="button" className="btn btn-primary" onClick={() => addUser()}> Add </button>
                            </div>
                        </form>
                    </ReactModal> */}

          <div className="team-members-table">
            <table className="list-view">
              <tbody>
                <tr className="list-view-head">
                  <th className="width-20 team-td">Name </th>
                  <th className="width-20 team-td"> Email </th>
                  <th className="width-20 team-td">Role</th>
                  <th className="width-20 team-td">Last log-in</th>
                </tr>
                {filteredTeamMembers &&
                  filteredTeamMembers.map((member) => (
                    <tr className="list-view-row" key={member?.id}>
                      <td>
                        <div className="font-bold">{member?.name}</div>
                      </td>
                      <td>
                        <div className="date-content-time teams-description">
                          {member?.email}
                        </div>
                      </td>
                      <td>
                        {Array.isArray(member?.roles) &&
                          member?.roles.length > 0 &&
                          member?.roles[0]}
                      </td>
                      <td>{lastLoggedIn}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div className="team-desc-sidebar">
          <h2>
            Team Managers (
            {teamData && teamData.teamManagers && teamData.teamManagers.length})
          </h2>
          <div className="team-manager-list">
            {teamData.teamManagers &&
              teamData.teamManagers.map((manager) => (
                <div className="manager-img">
                  <Avatar userName={manager?.name} />
                  <div className="manager-name">
                    {manager?.name}
                    <div className="manager-email">{manager?.email}</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="team-territories">
            <div className="territories-header">
              <h2>Territories (4)</h2>
              <div className="add-territories">Add New</div>
            </div>
            <div className="territories-list">
              {territoriesList.map((ter) => (
                <div className="territory">
                  {ter.name} <span className="cross">x</span>
                </div>
              ))}
            </div>
          </div>

          <div className="team-territories">
            <div className="territories-header">
              <h2>Product (2)</h2>
              <div className="add-territories">Add New</div>
            </div>
            <div className="territories-list">
              {territoriesList.map((ter) => (
                <div className="territory">
                  {ter.name} <span className="cross">x</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-30">
            <div className="text-weight-bold">Pipeline Accessibility</div>
            <Select
              className="mt-20 p-5"
              options={PIPELINE_ACCESSIBILITY || []}
              onChange={(option) => onChangePipelineAccessibility(option)}
              value={selectedPipelineAccessibility}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
