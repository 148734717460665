import { getDateByOperator } from './DateUtils';

export const getFilterKey = (filterFor) => {
  return filterFor === 'solutions'
    ? 'solutionUseCases'
    : filterFor === 'productgaps'
    ? 'pgUseCases'
    : filterFor;
};

export const processFilter = (filterFor, filters) => {
  if (!filterFor) {
    console.warn('Filter type is not defined');
    return;
  }
  let filter = {};
  // * Process filter from local storage
  const storedData = localStorage.getItem(getFilterKey(filterFor));
  const filtersFromLS = storedData ? JSON.parse(storedData) : {};

  if (filtersFromLS?.sort) {
    filter.sort = filtersFromLS?.sort;
  }

  if (filtersFromLS?.createdDate) {
    filter.createdDate = getDateByOperator(filtersFromLS?.createdDate.value);
  }
  if (filtersFromLS?.pulseScoreList) {
    filter.pulseScoreList = filtersFromLS?.pulseScoreList;
  }
  if (Array.isArray(filtersFromLS?.teamFilter)) {
    filter.teamFilter = filtersFromLS?.teamFilter.map((item) => item.value);
  }
  if (Array.isArray(filtersFromLS?.presalesOwner)) {
    filter.presalesOwner = filtersFromLS?.presalesOwner.map(
      (item) => item.value
    );
  }
  if (Array.isArray(filtersFromLS?.competitors)) {
    filter.competitors = filtersFromLS?.competitors.map((item) => item.value);
  }
  if (Array.isArray(filtersFromLS?.impactsWhom)) {
    filter.impactsWhom = filtersFromLS?.impactsWhom.map((item) => item.value);
  }
  if (Array.isArray(filtersFromLS?.salesPerson)) {
    if (!filter.dealFilter) {
      filter.dealFilter = {};
    }
    filter.dealFilter.salesPerson = filtersFromLS?.salesPerson.map(
      (item) => item.value
    );
  }
  if (Array.isArray(filtersFromLS?.type)) {
    if (!filter.dealFilter) {
      filter.dealFilter = {};
    }
    filter.dealFilter.type = filtersFromLS?.type.map((item) => item.value);
  }
  if (filtersFromLS?.revenue) {
    if (!filter.dealFilter) {
      filter.dealFilter = {};
    }
    filter.dealFilter.revenue = {
      operator: filtersFromLS?.revenue.value,
      value: filtersFromLS?.revenue.amount,
    };
  }
  if (Array.isArray(filtersFromLS?.stage)) {
    if (!filter.dealFilter) {
      filter.dealFilter = {};
    }
    filter.dealFilter.stage = filtersFromLS?.stage.map((item) => item.value);
  }
  if (Array.isArray(filtersFromLS?.preskaleStage)) {
    if (!filter.dealFilter) {
      filter.dealFilter = {};
    }
    filter.dealFilter.preskaleStage = filtersFromLS?.preskaleStage.map(
      (item) => item.label
    );
  }
  if (filtersFromLS?.expectedCloseDate) {
    if (!filter.dealFilter) {
      filter.dealFilter = {};
    }
    filter.dealFilter.expectedCloseDate =
      filtersFromLS?.expectedCloseDate.value === 'CUSTOM'
        ? {
            fromDate: filtersFromLS?.expectedCloseDate?.fromDate,
            toDate: filtersFromLS?.expectedCloseDate?.toDate,
          }
        : getDateByOperator(filtersFromLS?.expectedCloseDate.value);
  }

  if (filtersFromLS?.itemIds) {
    filter.moduleIdWithItems = filtersFromLS?.itemIds;
  }

  if (filtersFromLS?.pgItems) {
    filter.productGapItems = filtersFromLS?.pgItems.map((data) => data?.value);
  }

  // * Process filter from currently passed filters
  if (filters.itemIds) {
    filter = {
      ...filter,
      moduleIdWithItems: !filters?.itemIds.length
        ? undefined
        : filters?.itemIds,
    };
  }
  if (filters.productGapItems) {
    filter = {
      ...filter,
      productGapItems: !filters?.productGapItems.length
        ? undefined
        : filters?.productGapItems,
    };
  }
  if (!filters.itemIds) {
    const dealFilter = filter.dealFilter ? { ...filter.dealFilter } : {};
    const dealFilterKeys = [
      'preskaleStage',
      'expectedCloseDateFilter',
      'type',
      'salesPerson',
      'stage',
      'revenue',
    ];
    dealFilterKeys.forEach((key) => {
      if (filters[key]) {
        dealFilter[key] = filters[key];
      }
    });
    for (let key in filters) {
      if (!dealFilter[key]) {
        filter[key] = filters[key];
      }
    }
  }
  return filter;
};

export const handleUseCasesSuccess =
  (filterFor, setOpportunities) => (response) => {
    let dealMap = {};
    response?.data &&
      response.data.map((deal) => {
        const dealId = filterFor === 'solutions' ? 'deal_id' : 'dealId';
        const dealName = filterFor === 'solutions' ? 'deal_name' : 'dealName';
        const dealValue = filterFor === 'solutions' ? 'revenue' : 'dealValue';

        if (deal[dealId]) {
          if (dealMap[deal[dealId]] == null) {
            dealMap[deal[dealId]] = {
              dealId: deal[dealId],
              name: deal[dealName],
              dealValue: deal[dealValue],
            };
            if (filterFor === 'solutions') {
              dealMap[deal[dealId]] = { ...dealMap[deal[dealId]], solution: 1 };
            } else {
              dealMap[deal[dealId]] = {
                ...dealMap[deal[dealId]],
                productGap: 1,
              };
            }
          } else {
            if (filterFor === 'solutions') {
              dealMap[deal[dealId]] = {
                ...dealMap[deal[dealId]],
                solution: dealMap[deal[dealId]].solution + 1, //This variable is for the count of solution usecase under each deal
              };
            } else {
              dealMap[deal[dealId]] = {
                ...dealMap[deal[dealId]],
                productGap: dealMap[deal[dealId]].productGap + 1, //This variable is for the count of product gaps under each deal
              };
            }
          }
        }
      });

    let opportunityArray = [
      {
        dealId: 'All',
        name: 'All',
      },
    ];

    Object.keys(dealMap).map(function (key) {
      opportunityArray.push(dealMap[key]);
    });

    setOpportunities(opportunityArray);
  };
