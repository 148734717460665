import React from 'react';
import { formatCount } from '../../Utils/Revenue';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';
import { useTooltipContext } from './BarChartToolTip';

//Example data for reference
//Refer to https://codesandbox.io/s/recharts-horizontal-bar-with-dual-y-axis-forked-rvq0j2?file=/src/index.js
//https://recharts.org/en-US/examples
/*
const data = [
  {
    stageName: "Proposal/Price Quote",
    count: 1,
    revenue: 100
  }
]
*/

const LightningDashboardBarChart = ({ data, yKey, xKey, tooltipkey }) => {
  const { openTooltip, closeTooltip } = useTooltipContext();

  //This is where you create content to go inside of the tooltip
  const tooltipContent = (e) => {
    const payload = e.payload;
    const value = e.value;
    if (payload) {
      return (
        <div className="barchart-tooltip">
          {tooltipkey ? (
            <p className="label">{`${formatCount(
              payload[tooltipkey],
              true
            )} | ${value} Accounts`}</p>
          ) : (
            <p className="label">{`${value} Meetings`}</p>
          )}
        </div>
      );
    }
  };

  //This is where you style the tooltip wrapper
  const tooltipStyle = {
    backgroundColor: '#172B4D',
    borderRadius: '3px',
    fontSize: '14px',
    padding: '.5%',
  };

  return (
    <ResponsiveContainer width={'100%'} height={300} debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: 10, top: 10, right: 10, bottom: 10 }}
        barGap={0}
        barCategoryGap={'10%'}
      >
        <XAxis type="number" />
        <YAxis
          yAxisId={0}
          dataKey={xKey}
          type="category"
          axisLine={false}
          tickLine={false}
          interval={0}
          // tick={YAxisLeftTick}
          width={100}
        />
        {/* <Tooltip content={<CustomTooltip tooltipkey={tooltipkey}/>} /> */}
        <Bar
          dataKey={yKey}
          minPointSize={2}
          onMouseEnter={(e) =>
            openTooltip({
              content: tooltipContent(e),
              style: tooltipStyle,
            })
          }
          onMouseLeave={() => closeTooltip()}
          radius={[2.37, 2.37, 2.37, 2.37]}
        >
          {data.map((d, idx) => {
            return <Cell key={d[xKey]} fill={'#855CF8'} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LightningDashboardBarChart;
