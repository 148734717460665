import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AgGridLoader from '../../common/AgGridLoader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AgGridTooltip from '../../common/AgGridTooltip';
import CustomPinnedRowRenderer from '../calendar/PinnedRowRenderer';
import { BulkUpdatePopup } from '../calendar/BulkUpdatePopup';
import { AgGridCustomSelect } from '../calendar/AgGridCustomSelect';
import {
  useFetchAllAccountsQuery,
  useFetchAllDealsQuery,
} from '../workbook/workbookSlice';
import Button from '../../common/Button';
import { useFetchModulesFeaturesQuery } from '../ModulesManager/moduleFeaturesSlice';
import { getCategoryValue, transformModuleFeatureResult } from './utils';
import { DEFAULT_FILTER, RATING_LIST } from './constants';
import { Button as AntButton, Flex, Input, Space, Typography } from 'antd';
import { ReactComponent as SearchSVG } from '../../Icons/NewImage/search.svg';
import { ReactComponent as FilterSVG } from '../../Icons/NewImage/filter.svg';
import Select from 'react-select';
import Filter from './Filter';
import 'antd/dist/reset.css';
import {
  extendedIssuesSlice,
  useBulkUpdateIssuesMutation,
  useGetAllIssuesMutation,
  useGetFilterOptionsQuery,
  useLazyGetJiraConfigQuery,
} from './issueSlice';
import { getDateByOperator } from '../../Utils/DateUtils';
import { noData } from '../../Utils/common';
// import customNoRowsOverlay from '../../Pipeline/utils/customNoRowsOverlay';
import useDebounce from '../../hooks/useDebounce';
// import { CustomHeaderComponent } from '../../Pipeline/components/CustomHeaderComponent';
import { toast } from 'react-toastify';
import { CloseCircleOutlined } from '@ant-design/icons';
import DualRingLoader from '../../common/DualRingLoader';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import { ReactComponent as AddSVG } from '../../Icons/NewImage/add-green.svg';

const IssueManager = ({
  isPresentationView = false,
  showCloseIcon = false,
  dealDetails = null,
  closeDrawer,
  handleAdd,
}) => {
  const gridRef = useRef(null);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [alteredRows, setAlteredRows] = useState([]);
  const [changedCells, setChangedCells] = useState({});
  const [selectedIssues, setSelectedIssues] = useState({});
  const [filteredIssues, setFilteredIssues] = useState([]);
  const filterTextBoxRef = useRef();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchTerm = useDebounce(searchQuery, 1500);
  const { Text } = Typography;
  const [openFilter, setOpenFilter] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const defaultValue = { label: 'All', value: 'ALL' };
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_FILTER);
  // const [selectAll, setSelectAll] = useState(false);
  // const [checkingMasterBox, setCheckingMasterBox] = useState(false);
  // const [totalSelected, setTotalSelected] = useState(false);
  // const currentTableState = useRef({
  //   selectAll: false,
  //   totalSelected: false,
  // });
  let filterFromStorage = localStorage.getItem('issues_filter');
  const [bulkUpdateIssues, { isLoading: bulkUserLoading }] =
    useBulkUpdateIssuesMutation();
  const [getAllIssues, { data: issues, isLoading: issuesLoading }] =
    useGetAllIssuesMutation();
  const { data: filterOptions, isLoading: filerOptionsLoading } =
    useGetFilterOptionsQuery();
  const {
    data: configFeatures,
    isLoading: loadingConfig,
    isError: configError,
    refetch: refetchConfig,
  } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });
  const [getJiraConfig, { data: jiraConfig, isLoading: configApiLoading }] =
    useLazyGetJiraConfigQuery();
  const [isJiraEnabled, setJiraEnabled] = useState(false);
  const buildOpportunityDropdown = (accountSummary = []) => {
    // ==== Building options for opportunity dropdown ==== //
    const accountSummarySorted = [...accountSummary];
    return accountSummarySorted?.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
  };

  const {
    data: { accountSummary: opportunityOptions = [], opportunityLabelMap = {} },
  } = useFetchAllDealsQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        data: {
          accountSummary: useMemo(
            () => buildOpportunityDropdown(data?.accountSummary),
            [data?.accountSummary]
          ),
          opportunityLabelMap: data?.opportunityLabelMap,
        },
      };
    },
  });

  const {
    data: { allAccounts = [], accountLabelMap = {} } = {
      allAccounts: null,
      accountLabelMap: null,
    },
  } = useFetchAllAccountsQuery();

  const {
    data: moduleData,
    moduleMap,
    featureMap,
    featureList,
  } = useFetchModulesFeaturesQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        return useMemo(() => transformModuleFeatureResult(data), [data]);
      },
    }
  );

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(
    () => ({
      filter: true,
      resizable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      tooltipComponent: AgGridTooltip,
      minWidth: 75,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = (params) => {
    gridRef.current = params;
    // Show loading overlay initially
    if (issuesLoading) {
      gridRef.current.api.showLoadingOverlay();
    }
  };

  useEffect(() => {
    if (configFeatures?.data?.productGapIntegration) {
      const productGapIntegrations =
        configFeatures?.data?.productGapIntegration;
      let enabled = false;
      productGapIntegrations.forEach((integration) => {
        if (integration?.type === 'jira' && integration?.is_enabled) {
          enabled = true;
          getJiraConfig();
        }
      });
      setJiraEnabled(enabled);
    }
  }, [configFeatures]);

  useEffect(() => {
    let filter = filterFromStorage ? filterFromStorage : null;
    if (filter) {
      const parsedFilter = JSON.parse(filter);
      filter = transformFilter(parsedFilter);
      setSelectedFilter(parsedFilter);
    } else {
      filter = transformFilter();
    }
    getAllIssues(filter);
  }, []);

  useEffect(() => {
    let filter = filterFromStorage ? filterFromStorage : null;
    if (filter) {
      const parsedFilter = JSON.parse(filter);
      filter = transformFilter(parsedFilter);
      setSelectedFilter(parsedFilter);
    } else {
      filter = transformFilter();
    }
    filter.searchTerm = debouncedSearchTerm;
    getAllIssues(filter);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const existingFilter = filterFromStorage
      ? JSON.parse(filterFromStorage)
      : null;

    if (filterOptions?.projectList.length) {
      const projects = [defaultValue];
      filterOptions.projectList.map((project) => {
        projects.push({ label: project, value: project });
        setProjectList(projects);
      });
      if (!(existingFilter && existingFilter?.project)) {
        setSelectedFilter((prevState) => ({
          ...prevState,
          project: defaultValue,
        }));
      }
    }
    if (filterOptions?.creatorList.length) {
      const creators = [defaultValue];
      filterOptions.creatorList.map((project) => {
        creators.push({ label: project, value: project });
        setCreatorList(creators);
      });
      if (!(existingFilter && existingFilter?.creator)) {
        setSelectedFilter((prevState) => ({
          ...prevState,
          creator: defaultValue,
        }));
      }
    }
  }, [filterOptions]);

  useEffect(() => {
    if (!issuesLoading) {
      gridRef?.current?.api?.hideOverlay();
    }
    if (issuesLoading) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [issuesLoading]);

  useEffect(() => {
    if (issues?.data) {
      let issueList = issues?.data;
      //Sort deal details for issues drawer view
      if (issueList?.length && dealDetails && !isPresentationView) {
        const dealIssues = [];
        const otherIssues = [];
        issues?.data.forEach((issue) => {
          if (issue.opportunity === dealDetails.dealId) {
            dealIssues.push(issue);
          } else {
            otherIssues.push(issue);
          }
        });
        issueList = dealIssues.concat(otherIssues);
      }
      setFilteredIssues(issueList);
    }
  }, [issues]);

  useEffect(() => {
    // * Shows buttons pinned to the bottom of the table
    if (Object.keys(alteredRows).length > 0) {
      gridRef.current?.api?.setPinnedTopRowData(showButtons());
    }
  }, [alteredRows]);

  const handleSaveIssues = async () => {
    const selectedIssuesArray = Object.values(alteredRows);

    let issues = JSON.parse(JSON.stringify(selectedIssuesArray));
    try {
      await bulkUpdateIssues(issues)
        .unwrap()
        .then((data) => {
          setChangedCells({});
          setSelectedIssues({});
          setAlteredRows([]);
          gridRef.current?.api?.setPinnedTopRowData(hideButtons());
          toast.success(data?.data);
        });
    } catch (err) {
      console.error('Failed to save the issues', err);
    }
    console.log('save');
  };

  const cancelIssuesEdit = () => {
    setSelectedIssues({});
    setAlteredRows([]);
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.setPinnedTopRowData(hideButtons());
    setChangedCells({});
  };

  const showButtons = () => {
    return [
      {
        checkbox: (
          <section className="save-button-container">
            <p className="alert-text">Click Save to update data</p>
            <div className="save-btns">
              <Button
                loading={issuesLoading}
                type="secondary"
                onClick={cancelIssuesEdit}
              >
                Cancel
              </Button>
              <Button
                loading={issuesLoading}
                type="primary"
                onClick={handleSaveIssues}
              >
                Save
              </Button>
            </div>
          </section>
        ),
      },
    ];
  };

  const hideButtons = () => {
    return [];
  };

  const IndexCellRenderer = useCallback((props) => {
    if (props.value !== undefined) {
      return parseInt(props?.value) + 1;
    } else if (props?.value === undefined) {
      return <DualRingLoader dark />;
    }
  }, []);

  let columnDefs = [
    {
      field: 'checkbox',
      headerName: '',
      checkboxSelection: true,
      // headerCheckboxSelection: true,
      hide: dealDetails && isPresentationView,
      // headerComponent: CustomHeaderComponent,
      // headerComponentParams: CustomHeaderComponentParams,
      showDisabledCheckboxes: true,
      maxWidth: 52,
      colSpan: (params) => {
        if (params.node.rowPinned) {
          // have all pinned columns span 6 columns
          return 10;
        } else {
          // all other rows should be just normal
          return 1;
        }
      },
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {
                backgroundColor: '#447BF2',
                lineHeight: 'initial',
                height: 'inherit',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { padding: 0, border: 0 };
        } else {
          return {
            borderRight: '1px solid #d8e4fd',
          };
        }
      },
    },
    {
      headerName: 'S.No.',
      maxWidth: 85,
      sortable: false,
      resizable: false,
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: (params) => params?.node?.id,
      cellRenderer: IndexCellRenderer,
    },
    {
      field: 'key',
      minWidth: 80,
      headerName: 'Issue ID',
      tooltipField: 'Issue Id',
      cellStyle: (params) => {
        if (isJiraEnabled && !jiraConfig?.data?.url) {
          return;
        }
        if (params?.data?.self) {
          return {
            cursor: 'pointer',
            color: '#447bf2',
            fontWeight: '600',
            textDecoration: 'underline',
          };
        }
        return null;
      },
      onCellClicked: (params) => {
        if (isJiraEnabled) {
          if (jiraConfig?.data?.url) {
            const issueUrl = `${jiraConfig?.data?.url}/browse/${params.data.key}`;
            window.open(issueUrl, '_blank', 'noopener,noreferrer');
          }
        } else if (params?.data?.self) {
          window.open(params.data.self, '_blank', 'noopener,noreferrer');
        }
        return null;
      },
    },
    {
      field: 'summary',
      minWidth: 250,
      headerName: 'Title',
      tooltipField: 'title',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 80,
      tooltipField: 'Status',
    },
    {
      field: 'issueType',
      headerName: 'Type',
      tooltipField: 'Issue Type',
      cellStyle: (params) => {
        // Check if the cell has been changed and apply background color
        const cellKey = `${params?.data?.id}_${params.colDef.field}`;
        const textColor = (params) => {
          if (params.value === 'Bug') return '#447bf2';
          if (params.value === 'Task') return '#36B37E';
          if (params.value === 'Story') return '#7F75B1';
          return '#2f4e74';
        };
        return {
          backgroundColor: changedCells[cellKey] ? 'rgb(250, 255, 189)' : null,
          borderRight: '1px solid #d8e4fd',
          color: textColor(params),
          fontWeight: 500,
        };
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      tooltipField: 'Category',
      valueGetter: (params) => getCategoryValue(params),
      cellStyle: (params) => {
        // Check if the cell has been changed and apply background color
        const cellKey = `${params?.data?.id}_${params.colDef.field}`;
        const textColor = (params) => {
          if (params.value === 'Opportunity') return '#447bf2';
          if (params.value === 'Skipped') return '#DE350B';
          if (params.value === 'Unassociated') return '#7F75B1';
          return '#2f4e74';
        };
        return {
          backgroundColor: changedCells[cellKey] ? 'rgb(250, 255, 189)' : null,
          borderRight: '1px solid #d8e4fd',
          color: textColor(params),
          fontWeight: 500,
        };
      },
    },
    {
      field: 'moduleName',
      headerName: 'Module',
      editable: !(dealDetails && isPresentationView),
      minWidth: 100,
      cellStyle: (params) => {
        // Check if the cell has been changed and apply background color
        const cellKey = `${params?.data?.id}_${params.colDef.field}`;

        return {
          backgroundColor: changedCells[cellKey] ? 'rgb(250, 255, 189)' : null,
          borderRight: '1px solid #d8e4fd',
        };
      },
      cellEditorSelector: (params) => {
        return {
          component: Object.keys(selectedIssues).includes(params?.data?.id)
            ? BulkUpdatePopup
            : AgGridCustomSelect,
          popup: true,
        };
      },
      valueGetter: (params) => {
        if (
          moduleMap &&
          Object.keys(moduleMap).length &&
          params?.data?.moduleId !== null
        ) {
          return moduleMap[params?.data?.moduleId]?.moduleTitle;
        }
        return null;
      },
      valueSetter: (params) => {
        if (params.oldValue !== params?.newValue?.label) {
          const newData = { ...params.data };
          newData.moduleId = params?.newValue?.value;
          newData.moduleName = params?.newValue?.label;
          params.node.setData(newData);

          return true;
        }
      },
      singleClickEdit: true,
      cellEditorParams: (params) => {
        const account = moduleData.filter(
          (data) => data.label === params.value
        );
        return {
          callback: (data) => bulkEdit(data),
          values: moduleData,
          bulkSelectValue: account[0],
          numberOfSelectedCheckbox:
            selectedIssues && Object.keys(selectedIssues)?.length,
        };
      },
    },
    {
      field: 'featureName',
      headerName: 'Feature',
      editable: !(dealDetails && isPresentationView),
      minWidth: 100,
      cellStyle: (params) => {
        // Check if the cell has been changed and apply background color
        const cellKey = `${params?.data?.id}_${params.colDef.field}`;

        return {
          backgroundColor: changedCells[cellKey] ? 'rgb(250, 255, 189)' : null,
          borderRight: '1px solid #d8e4fd',
        };
      },
      cellEditorSelector: (params) => {
        return {
          component: Object.keys(selectedIssues).includes(params?.data?.id)
            ? BulkUpdatePopup
            : AgGridCustomSelect,
          popup: true,
        };
      },
      valueGetter: (params) => {
        if (
          featureMap &&
          Object.keys(featureMap).length &&
          params?.data?.featureId !== null
        ) {
          return featureMap[params?.data?.featureId]?.label;
        }
        return null;
      },
      valueSetter: (params) => {
        if (params.oldValue !== params?.newValue?.label) {
          const newData = { ...params.data };
          newData.featureId = params?.newValue?.value;
          newData.featureName = params?.newValue?.label;
          params.node.setData(newData);
          return true;
        }
      },
      singleClickEdit: true,
      cellEditorParams: (params) => {
        const feature = featureList.filter(
          (data) => data.value === params.value
        );
        const moduleId = params?.data?.moduleId;

        return {
          callback: (data) => bulkEdit(data),
          //features might not be a part of moduleMap, so check before passing values
          values:
            moduleId && moduleMap[moduleId]
              ? moduleMap[moduleId]?.moduleFeatures
              : featureList,
          bulkSelectValue: feature[0],
          numberOfSelectedCheckbox:
            selectedIssues && Object.keys(selectedIssues)?.length,
        };
      },
    },
    {
      field: 'accountName',
      headerName: 'Accounts',
      minWidth: 100,
      editable: true,
      hide: dealDetails && isPresentationView,
      flex: 1,
      cellStyle: (params) => {
        // Check if the cell has been changed and apply background color
        const cellKey = `${params?.data?.id}_${params.colDef.field}`;

        return {
          backgroundColor: changedCells[cellKey] ? 'rgb(250, 255, 189)' : null,
          borderRight: '1px solid #d8e4fd',
        };
      },
      cellEditorSelector: (params) => {
        return {
          component: Object.keys(selectedIssues).includes(params?.data?.id)
            ? BulkUpdatePopup
            : AgGridCustomSelect,
          popup: true,
        };
      },
      valueGetter: (params) => {
        if (accountLabelMap && params?.data?.accountId !== null) {
          return (
            accountLabelMap[params.data?.accountId]?.label ||
            params?.data?.accountName
          );
        }
        return null;
      },
      valueSetter: (params) => {
        if (params.oldValue !== params?.newValue?.label) {
          const newData = { ...params.data };
          newData.accountId = params?.newValue?.value;
          newData.accountName = params?.newValue?.label;
          params.node.setData(newData);

          return true;
        }
      },
      singleClickEdit: true,
      cellEditorParams: (params) => {
        const account = allAccounts.filter(
          (data) => data.label === params.value
        );
        return {
          callback: (data) => bulkEdit(data),
          account: true,
          values: allAccounts,
          bulkSelectValue: account[0],
          numberOfSelectedCheckbox:
            selectedIssues && Object.keys(selectedIssues)?.length,
        };
      },
    },
    {
      field: 'opportunity',
      editable: true,
      headerName: 'Opportunity',
      flex: 1,
      hide: dealDetails && isPresentationView,
      cellStyle: (params) => {
        // Check if the cell has been changed and apply background color
        const cellKey = `${params?.data?.id}_${params.colDef.field}`;

        return {
          backgroundColor: changedCells[cellKey] ? 'rgb(250, 255, 189)' : null,
          borderRight: '1px solid #d8e4fd',
        };
      },
      cellEditorSelector: (params) => {
        return {
          component: Object.keys(selectedIssues).includes(params?.data?.id)
            ? BulkUpdatePopup
            : AgGridCustomSelect,
          popup: true,
        };
      },
      valueGetter: (params) => {
        return (
          opportunityLabelMap[params?.data?.opportunity] ||
          params?.data?.opportunityName
        );
      },
      valueSetter: (params) => {
        if (params.oldValue !== params?.newValue?.label) {
          const newData = { ...params.data };
          newData.opportunity = params?.newValue?.value;
          params.node.setData(newData);

          return true;
        }
      },
      singleClickEdit: true,
      cellEditorParams: (params) => {
        const opportunity = opportunityOptions.filter(
          (data) => data.label === params.value
        );
        const accId = params?.data?.accountId;
        return {
          callback: (data) => bulkEdit(data),
          opportunity: true,
          //account might not be a part of accountLabelMap, so check before passing values
          values:
            accId && accountLabelMap[accId]
              ? accountLabelMap[accId]?.deals
              : opportunityOptions,
          bulkSelectValue: opportunity[0],
          numberOfSelectedCheckbox:
            selectedIssues && Object.keys(selectedIssues)?.length,
        };
      },
    },
    {
      field: 'rating',
      headerName: 'Gap Pulse Score',
      tooltipField: 'Gap Pulse Score',
      editable: true,
      valueGetter: (params) => {
        const rating = RATING_LIST.filter(
          (data) => params.data?.rating === data.value
        );
        return rating && rating.length ? rating[0].label : null;
      },
      singleClickEdit: true,
      cellEditorSelector: (params) => {
        return {
          component: Object.keys(selectedIssues).includes(params?.data?.id)
            ? BulkUpdatePopup
            : AgGridCustomSelect,
          popup: true,
        };
      },
      valueSetter: (params) => {
        if (params.oldValue !== params?.newValue?.value) {
          const newData = { ...params.data };
          newData.rating = params?.newValue?.value;
          params.node.setData(newData);
          return true;
        }
      },
      cellEditorParams: (params) => {
        const rating = RATING_LIST.filter(
          (data) => data.label === params.value
        );
        return {
          callback: (data) => bulkEdit(data),
          values: RATING_LIST,
          bulkSelectValue: rating,
          numberOfSelectedCheckbox:
            selectedIssues && Object.keys(selectedIssues)?.length,
        };
      },
    },
  ];

  const loadingOverlayComponent = useMemo(() => {
    return AgGridLoader;
  }, []);

  const bulkEdit = (props) => {
    let cells = changedCells;
    gridRef.current?.api?.forEachNode((rowNode) => {
      const rowData = JSON.parse(JSON.stringify(rowNode?.data));

      if (Object.keys(selectedIssues).includes(rowData?.id)) {
        const cellKey = `${rowData?.id}_${props.params.colDef.field}`;
        cells = { ...cells, [cellKey]: true };
      }
    });

    let issue = JSON.parse(JSON.stringify(selectedIssues));
    if (props) {
      let issuesData = JSON.parse(JSON.stringify(filteredIssues));
      Object.keys(selectedIssues).forEach((key) => {
        issuesData = issuesData.map((data) => {
          let dataCopy = { ...data };
          if (data?.id === key) {
            if (props.params.colDef.field === 'rating') {
              dataCopy.rating = props?.option?.value;
              issue[key].rating = props?.option?.value;
            }
            if (props.params.colDef.field === 'opportunity') {
              dataCopy.opportunity = props?.option?.value;
              dataCopy.opportunityName = props?.option?.label;
              issue[key].opportunity = props?.option?.value;
              issue[key].opportunityName = props?.option?.label;

              //Auto selecting account value
              let account = accountLabelMap[props?.option?.accountId];
              if (account) {
                dataCopy.accountId = account?.value;
                dataCopy.accountName = account?.label;
                issue[key].accountId = account?.value;
                issue[key].accountName = account?.label;
              }
            }
            if (props.params.colDef.field === 'featureName') {
              dataCopy.featureId = props?.option?.value;
              dataCopy.featureName = props?.option?.label;
              issue[key].featureId = props?.option?.value;
              issue[key].featureName = props?.option?.label;

              //Auto select module value
              let feature = featureMap[props?.option?.value];
              if (feature) {
                dataCopy.moduleId = feature.module.value;
                dataCopy.moduleName = feature.module.label;
                issue[key].moduleId = feature.module.value;
                issue[key].moduleName = feature.module.label;
              }
            }
            if (props.params.colDef.field === 'accountName') {
              dataCopy.accountId = props?.option?.value;
              dataCopy.accountName = props?.option?.label;
              issue[key].accountId = props?.option?.value;
              issue[key].accountName = props?.option?.label;

              //Flush selected opportunity value if it is not under the selected account
              const account = accountLabelMap[props?.option?.value];
              const deals = account?.deals;
              if (
                dataCopy.opportunity &&
                deals &&
                !deals.some((deal) => deal.value === dataCopy.opportunity)
              ) {
                dataCopy.opportunity = null;
                dataCopy.opportunityName = null;
                issue[key].opportunity = null;
                issue[key].opportunityName = null;
              }
            }
            if (props.params.colDef.field === 'moduleName') {
              dataCopy.moduleId = props?.option?.value;
              dataCopy.moduleName = props?.option?.label;
              issue[key].moduleId = props?.option?.value;
              issue[key].moduleName = props?.option?.label;

              //Flush selected feature value if it is not under the selected module
              const module = moduleMap[props?.option?.value];
              const features = module?.moduleFeatures;
              if (
                dataCopy.featureId &&
                features &&
                !features.some(
                  (feature) => feature.value === dataCopy.featureId
                )
              ) {
                dataCopy.featureId = null;
                dataCopy.featureName = null;
                issue[key].featureId = null;
                issue[key].featureName = null;
              }
            }
            //highlighting the cell
            if (
              props.params.colDef.field === 'opportunity' ||
              props.params.colDef.field === 'accountName' ||
              props.params.colDef.field === 'featureName' ||
              props.params.colDef.field === 'moduleName'
            ) {
              const currentIssueKey = `${data?.id}_${props.params.colDef.field}`;
              cells[currentIssueKey] = false;
            }
          }
          return dataCopy;
        });
      });
      setFilteredIssues(issuesData);
    }
    setChangedCells(cells);
    const data = { ...alteredRows, ...issue };
    setAlteredRows(data);
    setSelectedIssues(issue);
  };

  const onCellEditingStopped = (params) => {
    let editedRows = JSON.parse(JSON.stringify(alteredRows));
    if (params.newValue && params.oldValue !== params?.newValue?.label) {
      if (Object.keys(alteredRows).includes(params?.data?.id)) {
        let parsedIssues = JSON.parse(JSON.stringify(filteredIssues));
        if (params?.colDef?.field === 'rating') {
          editedRows[params.data.id].rating = params.newValue.value;
          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.rating = params?.newValue?.value;
            }
            return data;
          });
        }
        if (params.colDef.field === 'opportunity') {
          editedRows[params.data.id].opportunity = params.newValue.value;
          editedRows[params.data.id].opportunityName = params.newValue.label;

          //Auto select account value
          let account = accountLabelMap[params.newValue.accountId];
          if (!editedRows[params.data.id].accountId) {
            if (account) {
              editedRows[params.data.id].accountId = account.value;
              editedRows[params.data.id].accountName = account.label;
            }
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.opportunity = params?.newValue?.value;
              dataCopy.opportunityName = params?.newValue?.value;
              if (account) {
                dataCopy.accountId = account.value;
                dataCopy.accountName = account.label;
              }
            }
            return data;
          });
        }
        if (params.colDef.field === 'accountName') {
          editedRows[params.data.id].accountId = params.newValue.value;
          editedRows[params.data.id].accountName = params.newValue.label;

          //Flush selected opportunity value if it is not under the selected account
          const account = accountLabelMap[params.newValue.value];
          const deals = account?.deals;
          let dealNotUnderAccount = false;
          if (
            params.data.opportunity &&
            deals &&
            !deals.some((deal) => deal.value === params.data.opportunity)
          ) {
            dealNotUnderAccount = true;
            editedRows[params.data.id].opportunity = null;
            editedRows[params.data.id].opportunityName = null;
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.accountId = params?.newValue?.value;
              dataCopy.accountName = params?.newValue?.label;
              if (dealNotUnderAccount) {
                dataCopy.opportunity = null;
                dataCopy.opportunityName = null;
              }
            }
            return data;
          });
        }
        if (params.colDef.field === 'featureName') {
          editedRows[params.data.id].featureId = params.newValue.value;
          editedRows[params.data.id].featureName = params.newValue.label;

          //Auto select module value
          let feature = featureMap[params.newValue.value];
          if (!editedRows[params.data.id].moduleId && feature?.module) {
            editedRows[params.data.id].moduleId = feature.module.value;
            editedRows[params.data.id].moduleName = feature.module.label;
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.featureId = params?.newValue?.value;
              if (feature && feature?.module) {
                dataCopy.moduleId = feature.module.value;
                dataCopy.moduleName = feature.module.label;
              }
            }
            return data;
          });
        }
        if (params.colDef.field === 'moduleName') {
          editedRows[params.data.id].moduleId = params.newValue.value;
          editedRows[params.data.id].moduleName = params.newValue.label;

          //Flush selected feature value if it is not under the selected module
          const module = moduleMap[params.newValue.value];
          const features = module?.moduleFeatures;
          let featureNotUnderModule = false;
          if (
            params.data.featureId &&
            features &&
            !features.some((feature) => feature.value === params.data.featureId)
          ) {
            featureNotUnderModule = true;
            editedRows[params.data.id].featureId = null;
            editedRows[params.data.id].featureName = null;
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.moduleId = params?.newValue?.value;
              dataCopy.moduleName = params?.newValue?.label;
              if (featureNotUnderModule) {
                dataCopy.featureId = null;
                dataCopy.featureName = null;
              }
            }
            return data;
          });
        }
        if (params?.colDef?.field === 'category') {
          editedRows[params.data.id].category = params.newValue.value;
          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.category = params?.newValue?.value;
            }
            return data;
          });
        }
        setAlteredRows({ ...editedRows });
        setFilteredIssues(parsedIssues);
      } else {
        let newData = {};
        let parsedIssues = JSON.parse(JSON.stringify(filteredIssues));
        newData[params.data.id] = JSON.parse(JSON.stringify(params.data));
        if (params?.colDef?.field === 'rating') {
          newData[params.data.id].rating = params.newValue.value;
          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.rating = params?.newValue?.value;
            }
            return data;
          });
        }
        if (params.colDef.field === 'opportunity') {
          newData[params.data.id].opportunity = params.newValue.value;
          newData[params.data.id].opportunityName = params.newValue.label;

          let account = accountLabelMap[params.newValue.accountId];
          if (!newData[params.data.id].accountId) {
            if (account) {
              newData[params.data.id].accountId = account.value;
              newData[params.data.id].accountName = account.label;
            }
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.opportunity = params?.newValue?.value;
              if (account) {
                dataCopy.accountId = account.value;
                dataCopy.accountName = account.label;
              }
            }
            return data;
          });
        }
        if (params.colDef.field === 'accountName') {
          newData[params.data.id].accountId = params.newValue.value;
          newData[params.data.id].accountName = params.newValue.label;

          //Flush selected opportunity value if it is not under the selected account
          const account = accountLabelMap[params.newValue.value];
          const deals = account?.deals;
          let dealNotUnderAccount = false;
          if (
            params.data.opportunity &&
            deals &&
            !deals.some((deal) => deal.value === params.data.opportunity)
          ) {
            dealNotUnderAccount = true;
            newData[params.data.id].opportunity = null;
            newData[params.data.id].opportunityName = null;
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.accountId = params?.newValue?.value;
              dataCopy.accountName = params?.newValue?.label;
              if (dealNotUnderAccount) {
                dataCopy.opportunity = null;
                dataCopy.opportunityName = null;
              }
            }
            return data;
          });
        }
        if (params.colDef.field === 'featureName') {
          newData[params.data.id].featureId = params.newValue.value;
          newData[params.data.id].featureName = params.newValue.label;

          //Auto select account value
          let feature = featureMap[params.newValue.value];
          if (!newData[params.data.id].moduleId && feature?.module) {
            newData[params.data.id].moduleId = feature.module.value;
            newData[params.data.id].moduleName = feature.module.label;
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.featureId = params?.newValue?.value;
              if (feature && feature?.module) {
                dataCopy.moduleId = feature.module.value;
                dataCopy.moduleName = feature.module.label;
              }
            }
            return data;
          });
        }
        if (params.colDef.field === 'moduleName') {
          newData[params.data.id].moduleId = params.newValue.value;
          newData[params.data.id].moduleName = params.newValue.label;

          //Flush selected feature value if it is not under the selected module
          const module = moduleMap[params.newValue.value];
          const features = module?.moduleFeatures;
          let featureNotUnderModule = false;
          if (
            params.data.featureId &&
            features &&
            !features.some((feature) => feature.value === params.data.featureId)
          ) {
            featureNotUnderModule = true;
            newData[params.data.id].featureId = null;
            newData[params.data.id].featureName = null;
          }

          parsedIssues = parsedIssues.map((data) => {
            let dataCopy = data;
            if (dataCopy.id === params.data.id) {
              dataCopy.moduleId = params?.newValue?.value;
              dataCopy.moduleName = params?.newValue?.label;
              if (featureNotUnderModule) {
                dataCopy.featureId = null;
                dataCopy.featureName = null;
              }
            }
            return data;
          });
        }
        setAlteredRows({ ...alteredRows, ...newData });
        setFilteredIssues(parsedIssues);
      }
      const cellKey = `${params?.data?.id}_${params.colDef.field}`;
      setChangedCells({ ...changedCells, [cellKey]: true });
    }
  };

  const handleMultiSelectCheckbox = (issue, checked) => {
    // if (readOnly) {
    //   console.warn('Not allowed write access');
    //   return;
    // }
    setSelectedIssues((prev) => {
      const existingIssue = { ...prev };
      if (!checked && existingIssue[issue.id]) {
        delete existingIssue[issue.id];
      }
      if (checked) {
        existingIssue[issue.id] = issue;
      }
      return existingIssue;
    });
  };

  const onRowSelected = useCallback((event) => {
    handleMultiSelectCheckbox(event.node.data, event.node.isSelected());
  }, []);

  const handleSearchInputChange = (event) => {
    if (searchQuery.length && !event?.target?.value) {
      const existingFilter = filterFromStorage
        ? JSON.parse(filterFromStorage)
        : null;
      const filter = transformFilter(existingFilter);
      getAllIssues(filter)
        .unwrap()
        .then((result) => {
          setFilteredIssues(result?.data);
          localStorage.setItem(
            'issues_filter',
            JSON.stringify(existingFilter ? existingFilter : selectedFilter)
          );
        });
    }
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleFilterToggle = () => {
    setOpenFilter(!openFilter);
  };

  const transformFilter = (existingFilter) => {
    const filter = existingFilter
      ? { ...existingFilter }
      : { ...selectedFilter };
    Object.entries(filter).forEach(([key, item]) => {
      filter[key] = item?.value;
      if (key === 'createdDate') {
        filter[key] = getDateByOperator(item?.value);
      }
      if (key === 'rating') {
        filter[key] = item?.value === 'ALL' ? -1 : item?.value;
      }
      if (key === 'page' || key === 'size') {
        filter[key] = item;
      }
    });
    if (dealDetails && isPresentationView) {
      filter['opportunity'] = dealDetails.dealId;
    }
    return filter;
  };

  const handleFilterSubmit = (event, existingFilter) => {
    const filter = transformFilter(existingFilter);
    getAllIssues(filter)
      .unwrap()
      .then((result) => {
        localStorage.setItem(
          'issues_filter',
          JSON.stringify(existingFilter ? existingFilter : selectedFilter)
        );
      });
    if (!existingFilter) handleFilterToggle();
  };

  const handleChange = (property, option) => {
    const existingFilter = { ...selectedFilter };
    if (option) {
      existingFilter[property] = option;
    } else {
      existingFilter[property] = defaultValue;
    }
    setSelectedFilter(existingFilter);
    if (property === 'creator' || property === 'project') {
      handleFilterSubmit(null, existingFilter);
    }
  };

  const handleReset = () => {
    setSelectedFilter(DEFAULT_FILTER);
    const filter = transformFilter(DEFAULT_FILTER);
    getAllIssues(filter)
      .unwrap()
      .then((result) => {
        localStorage.removeItem('issues_filter');
      });
    handleFilterToggle();
  };

  return (
    <div className="manage-issues">
      {showCloseIcon && (
        <Flex justify="end">
          <AntButton icon={<CloseCircleOutlined />} onClick={closeDrawer}>
            Close
          </AntButton>
          {/* <Button
            icon={true}
            type="ghost"
            className="btn-icon-only drawer-btn"
            onClick={closeDrawer}
          >
            {' '}
            <CrossSVG />
          </Button> */}
        </Flex>
      )}
      {/* {isPresentationView ? ( */}
      <Flex align="center" className="header">
        <div className="filter-container flex">
          <span
            className={`mr-20 ${
              !showCloseIcon ? 'ml-20' : ''
            } flex-align-center text-weight-bold`}
          >
            Project :
          </span>
          <Space className="mr-20  select-container">
            <Select
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isClearable
              backspaceRemovesValue
              noOptionsMessage={() => noData()}
              options={projectList}
              value={selectedFilter?.project}
              onChange={(option) => handleChange('project', option)}
              menuPlacement="auto"
              loading={filerOptionsLoading}
            />
          </Space>
          <span className="mr-20 ml-auto flex-align-center text-weight-bold">
            Creator :
          </span>
          <Space className="mr-20 select-container">
            <Select
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isClearable
              backspaceRemovesValue
              noOptionsMessage={() => noData()}
              options={creatorList}
              value={selectedFilter?.creator}
              onChange={(option) => handleChange('creator', option)}
              menuPlacement="auto"
              loading={filerOptionsLoading}
            />
          </Space>
        </div>
        <Space className="mr-20 ml-auto">
          <Input
            prefix={<SearchSVG />}
            ref={filterTextBoxRef}
            size="medium"
            className="search-container min-width-250"
            value={searchQuery}
            onChange={handleSearchInputChange}
            placeholder="Search for Issue ID/ Issue Title "
          />
        </Space>
        {isPresentationView && (
          <Button icon onClick={handleAdd} type="text">
            <AddSVG />{' '}
            <span>
              <span className="usecase-type">Associate Issue</span>
            </span>
          </Button>
        )}
        <Button
          type="ghost"
          className={`height-full b-4  ${!showCloseIcon ? 'mr-20' : ''}`}
          onClick={handleFilterToggle}
        >
          <Flex align="center" gap={4}>
            <FilterSVG height={14} width={14} />
            {!isPresentationView && <Text> Issues Filter</Text>}
          </Flex>
        </Button>
      </Flex>
      {/* ) : null} */}
      <div className="manage-issues-table">
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={filteredIssues}
            loadingOverlayComponent={loadingOverlayComponent}
            onCellEditingStopped={onCellEditingStopped}
            suppressRowClickSelection={true}
            onRowSelected={onRowSelected}
            pinnedTopRowData={hideButtons()}
            tooltipShowDelay={1000}
            tooltipHideDelay={2000}
            onGridReady={onGridReady}
            cacheBlockSize={20}
            // rowModelType={'infinite'}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={500}
            rowSelection="multiple"
          />
        </div>
      </div>
      {openFilter && (
        <Filter
          openDrawer={openFilter}
          handleClose={handleFilterToggle}
          key="un-assigned-deals-filter"
          onSubmit={handleFilterSubmit}
          filterOptions={filterOptions}
          moduleList={moduleData}
          featureList={featureList}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          handleChange={handleChange}
          handleReset={handleReset}
        />
      )}
    </div>
  );
};

export default IssueManager;
