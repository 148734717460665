const getCategoryValue = (params) => {
  if (!params?.data) {
    return '';
  }
  if (params.value) {
    return params.value;
  }
  if (params?.data?.opportunity) {
    return 'Opportunity';
  }
  //Default value
  return 'Unassociated';
};

const transformModuleFeatureResult = (data) => {
  if (!data) {
    return {};
  }
  const moduleMap = {};
  const featureMap = {};
  const featureList = [];
  data?.forEach((module) => {
    moduleMap[module.id] = module;
    module?.features?.forEach((feature) => {
      if (feature?.disabled) {
        return;
      }
      const featureCopy = { ...feature };
      featureCopy.module = { label: module.moduleTitle, value: module.id };
      featureMap[feature.value] = featureCopy;
      featureList.push(featureCopy);
    });
  });
  return {
    moduleMap,
    featureMap,
    featureList,
    data,
  };
};

export { getCategoryValue, transformModuleFeatureResult };
