import React from 'react';
import { ReactComponent as ErrorIcon } from './../Icons/errorIcon.svg';
import { mapRoute } from './common';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import { useHistory } from 'react-router-dom';

const Fallback = () => {
  const history = useHistory();
  const user = localStorage.getItem('user');
  const parsedUser = user && JSON.parse(user);
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !parsedUser,
  });

  const navigateTo = () => {
    history.push(
      configFeatures?.data?.navPages?.dashboard
        ? '/dashboard/calendar'
        : configFeatures?.data?.landingPage
        ? mapRoute(configFeatures?.data?.landingPage)
        : '/'
    );
    history.go(0);
  };

  const hardRefresh = () => {
    if ('caches' in window) {
      // * Remove cache
      caches.keys().then(async (keyList) => {
        // * For zipy to catch the error. Will remove in next release
        console.error('keyList', keyList);
        await Promise.all(
          keyList.map((key) => {
            console.log('key', key);
            caches.delete(key);
          })
        )
          .catch(() => {
            console.log('err:reload');
          })
          .finally(() => {
            console.log('reload');
            // * Reload page after cache is cleared
            window.location.reload();
          });
      });
      return;
    } else {
      console.log('no-cache:reload');
      // * Reload page after cache is cleared
      window.location.reload();
    }
  };

  return (
    <>
      <div className="error-page">
        <div className="flex-center">
          <ErrorIcon />
        </div>
        <div className="fallBack-heading"> Something went wrong!</div>
        <div className="fallBack-content">
          We are sorry for the inconvenience. We recommend refreshing the page
          or retrying after sometime. If the issue persists, please contact
          support.
        </div>
      </div>

      <div className="error-page-btn">
        <button className="btn btn-primary" onClick={hardRefresh}>
          Refresh
        </button>
        <button className="btn btn-primary" onClick={navigateTo}>
          {' '}
          Go to Workspace
        </button>
        <a
          href="http://preskale.freshdesk.com"
          target="_blank"
          className="btn btn-secondary"
        >
          {' '}
          Contact Support
        </a>
      </div>
    </>
  );
};

export default Fallback;
