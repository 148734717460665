import React, { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import {
  CALENDAR_TYPE_OPTIONS,
  CRM_TYPE_OPTIONS,
  DEFAULT_STAGE_VIEW_OPTIONS,
  FEATURE_LIST_OPTIONS,
  LANDING_PAGE_OPTIONS,
  NAV_PAGE_OPTIONS,
  PRODUCTGAP_INTEGRATION_OPTIONS,
  PRODUCT_INTEGRATIONS,
} from './utils/FeatureAllocationOptions';
import { useLazyFetchCompanyConfigDatabyIdQuery } from './adminOpsSlice';
import Button from '../common/Button';
import { useUpdateCompanyConfigDataMutation } from '../Company/companySlice';
import Loader from '../common/Loader';
import { toast } from 'react-toastify';

export const FeatureAllocation = ({ companyId }) => {
  const [calendarType, setCalendarType] = useState([]);
  const [crmType, setCrmType] = useState([]);
  const [productGapIntegrations, setProductGapIntegrations] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [navPages, setNavPages] = useState([]);
  const [landingPage, setLandingPage] = useState([]);
  const [defaultStageView, setDefaultStageView] = useState([]);
  const [configFeatures, setConfigFeatures] = useState([]);
  const [newRequest, setNewRequest] = useState([]);
  const [enhancementBug, setEnhancementBug] = useState([]);
  const [companyIdValue, setCompanyIdValue] = useState('');
  const [isCommunityEdition, setIsCommunityEdition] = useState(false);

  const [featureAccess, { isLoading, data: configData }] =
    useLazyFetchCompanyConfigDatabyIdQuery();
  const [updateConfig, { isLoading: updatingCompanyConfig }] =
    useUpdateCompanyConfigDataMutation();

  useEffect(() => {
    if (companyId) {
      featureAccess({
        companyId: companyId,
      })
        .unwrap()
        .then((res) => {
          populateDefaultValue(res);
        });
    }
  }, [companyId]);

  //Restructure api data and populate defaultValue for dropdown
  const populateDefaultValue = (configFeatures) => {
    //companyId
    setCompanyIdValue(configFeatures?.data?.companyId || '');

    //community edition
    setIsCommunityEdition(configFeatures?.data?.communityEdition || false);

    //calendarType
    let selectedcalendar = [];
    CALENDAR_TYPE_OPTIONS.forEach((data) => {
      if (data.value === configFeatures?.data?.calendar) {
        selectedcalendar.push(data);
      }
    });
    setCalendarType([...selectedcalendar]);

    //crm
    let crm =
      CRM_TYPE_OPTIONS?.filter(
        (data) => data.value === configFeatures?.data?.crm
      ) || [];
    setCrmType(crm);

    //productGapIntegration
    let productGapIntegrationList = [];
    configFeatures?.data?.productGapIntegration?.forEach((val) => {
      if (val && val.is_enabled) {
        const selectedOption = PRODUCTGAP_INTEGRATION_OPTIONS.find(
          (option) => option.value === val.type
        );
        if (selectedOption) {
          productGapIntegrationList.push(selectedOption);
        }
      }
    });
    setProductGapIntegrations(productGapIntegrationList);

    //featureList
    const featureListValues =
      (configFeatures?.data?.featureList &&
        Object.keys(configFeatures.data.featureList).filter(
          (key) => configFeatures.data.featureList[key]
        )) ||
      [];
    const features = FEATURE_LIST_OPTIONS?.filter((option) =>
      featureListValues.includes(option.value)
    );
    setFeatureList(features);

    //navPages
    const navPageValues =
      (configFeatures?.data?.navPages &&
        Object.keys(configFeatures.data.navPages).filter(
          (key) => configFeatures.data.navPages[key]
        )) ||
      [];
    const navData = NAV_PAGE_OPTIONS?.filter((option) =>
      navPageValues.includes(option?.value)
    );
    setNavPages(navData);

    //LandingPage
    let defaultPage =
      LANDING_PAGE_OPTIONS?.filter(
        (data) => data.value === configFeatures?.data?.landingPage
      ) || [];
    setLandingPage(defaultPage);

    //newRequest
    let newRequest =
      PRODUCT_INTEGRATIONS?.filter(
        (data) => data.value === configFeatures?.data?.newRequestIntegration
      ) || [];
    setNewRequest(newRequest);

    //enhancementBugs
    let enhancementBugs =
      PRODUCT_INTEGRATIONS?.filter(
        (data) => data.value === configFeatures?.data?.enhancementBugIntegration
      ) || [];
    setEnhancementBug(enhancementBugs);

    //DefaultStageView
    let stage = [];
    DEFAULT_STAGE_VIEW_OPTIONS?.forEach((data) => {
      if (data.value === configFeatures?.data?.stageView) {
        stage.push(data);
      }
    });
    setDefaultStageView([...stage]);
  };

  const handleCalendarTypeChange = (option) => {
    setCalendarType([option]);
  };
  const handleCrmTypeChange = (option) => {
    setCrmType([option]);
  };
  const handleProductGapInterationChange = (option) => {
    setProductGapIntegrations(option);
  };
  const handleFeatureListChange = (option) => {
    setFeatureList(option);
  };
  const handleNavPageChange = (option) => {
    setNavPages(option);
  };
  const handleLandingPageChange = (option) => {
    setLandingPage([option]);
  };
  const handleDefaultStageViewChange = (option) => {
    setDefaultStageView([option]);
  };
  const handleNewRequestChange = (option) => {
    setNewRequest([option]);
  };
  const handleEnhancementBugsChange = (option) => {
    setEnhancementBug([option]);
  };
  const handleCompanyIdChange = (option) => {
    setCompanyIdValue(option?.target?.value);
  };

  const constructSelectedFeatures = () => {
    let selectedFeature = {};
    selectedFeature.companyId = companyIdValue;
    selectedFeature.communityEdition = isCommunityEdition;
    selectedFeature.calendar = calendarType[0]?.value || '';
    selectedFeature.crm = crmType[0]?.value || '';
    selectedFeature.landingPage = landingPage[0]?.value;
    selectedFeature.stageView = defaultStageView[0]?.value;
    selectedFeature.newRequestIntegration = newRequest[0]?.value;
    selectedFeature.enhancementBugIntegration = enhancementBug[0]?.value;
    selectedFeature.featureList = {};
    FEATURE_LIST_OPTIONS?.forEach((option) => {
      if (featureList.find((feature) => feature.value === option.value)) {
        selectedFeature.featureList[option.value] = true;
      } else {
        selectedFeature.featureList[option.value] = false;
      }
    });

    selectedFeature.navPages = {};
    NAV_PAGE_OPTIONS?.forEach((option) => {
      if (navPages.find((page) => page.value === option.value)) {
        selectedFeature.navPages[option.value] = true;
      } else {
        selectedFeature.navPages[option.value] = false;
      }
    });
    selectedFeature.productGapIntegration = PRODUCTGAP_INTEGRATION_OPTIONS?.map(
      (data) => {
        if (
          productGapIntegrations.find(
            (integration) => integration.value === data.value
          )
        ) {
          return {
            is_enabled: true,
            type: data?.value,
          };
        } else {
          return {
            is_enabled: false,
            type: data?.value,
          };
        }
      }
    );
    return selectedFeature;
  };

  //On Save => Restructure dropdown state Data and call the save api
  const handleCompanyConfigSave = () => {
    const selectedFeature = constructSelectedFeatures();
    if (selectedFeature) {
      updateConfig({
        body: selectedFeature,
      })
        .unwrap()
        .then(() => {
          toast.success('Updated Successfully');
        })
        .catch((error) => {
          console.log(error);
          toast.error('Something went wrong!');
        });
    }
  };

  const customStyles = {
    control: (base, { isFocused }) => {
      return {
        ...base,
        border: `1px solid ${isFocused ? '#447bf2' : '#e2ebf3'}`,
        '&:hover': {
          outline: '1px solid #e2ebf3',
        },
      };
    },
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="feature-allocation">
          <Button
            type="primary"
            onClick={handleCompanyConfigSave}
            disabled={updatingCompanyConfig}
          >
            Save
          </Button>
          <div className="event-checkbox pt-10">
            <input
              type="checkbox"
              checked={isCommunityEdition}
              onChange={(event) => setIsCommunityEdition(event.target.checked)}
            />
            <label className="checkbox">Community Edition</label>
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Company Id : </div>
            <div>
              <input
                type="text"
                id="filter-text-box"
                value={configData?.data?.companyId}
                onChange={handleCompanyIdChange}
                className="companyId-input-box"
              />
            </div>
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Calendar Type : </div>
            <Select
              className="Dropdown-root"
              options={CALENDAR_TYPE_OPTIONS}
              onChange={handleCalendarTypeChange}
              value={calendarType}
              styles={customStyles}
              isClearable={true}
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">CRM Type : </div>
            <Select
              className="Dropdown-root"
              options={CRM_TYPE_OPTIONS}
              onChange={handleCrmTypeChange}
              value={crmType}
              styles={customStyles}
              isClearable={true}
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Product Gap Integration : </div>
            <Select
              className="Dropdown-root"
              options={PRODUCTGAP_INTEGRATION_OPTIONS}
              onChange={handleProductGapInterationChange}
              value={productGapIntegrations}
              closeMenuOnSelect={false}
              styles={customStyles}
              isMulti
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Feature List : </div>
            <Select
              className="Dropdown-root"
              options={FEATURE_LIST_OPTIONS}
              onChange={handleFeatureListChange}
              value={featureList}
              closeMenuOnSelect={false}
              styles={customStyles}
              isMulti
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Navigation Pages : </div>
            <Select
              className="Dropdown-root"
              options={NAV_PAGE_OPTIONS}
              onChange={handleNavPageChange}
              value={navPages}
              closeMenuOnSelect={false}
              styles={customStyles}
              isMulti
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Landing Page : </div>
            <Select
              className="Dropdown-root"
              options={LANDING_PAGE_OPTIONS}
              onChange={handleLandingPageChange}
              styles={customStyles}
              value={landingPage}
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Default Crm Stage : </div>
            <Select
              className="Dropdown-root"
              options={DEFAULT_STAGE_VIEW_OPTIONS}
              onChange={handleDefaultStageViewChange}
              styles={customStyles}
              value={defaultStageView}
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">New Request : </div>
            <Select
              className="Dropdown-root"
              options={PRODUCT_INTEGRATIONS}
              onChange={handleNewRequestChange}
              styles={customStyles}
              value={newRequest}
              menuPosition="fixed"
            />
          </div>
          <div className="mt-10 flex-align-center">
            <div className="width-10 font-bold">Enhancemnet/Bugs : </div>
            <Select
              className="Dropdown-root"
              options={PRODUCT_INTEGRATIONS}
              onChange={handleEnhancementBugsChange}
              value={enhancementBug}
              styles={customStyles}
              menuPosition="fixed"
            />
          </div>
        </div>
      )}
    </div>
  );
};
