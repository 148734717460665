import React from 'react';
import { Card, Typography } from 'antd';

const { Text } = Typography;

export const TeamInfoCard = ({ title, value }) => (
  <Card className="team-info-card">
    <Text className="flex-center width-full text-xl text-weight-semi-bold text-primary">
      {value}
    </Text>
    <Text className="flex-center width-full text-weight-semi-bold">
      {title}
    </Text>
  </Card>
);
