import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import Select from 'react-select';
import { colourStyles } from '../../Constant/MultiSelectStylesAndComponents';

export const AgGridCustomSelect = forwardRef(
  ({ value, values, account = false, opportunity = false, ...props }, ref) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const selectedValueRef = useRef();

    useEffect(() => {
      let data = values.filter((option) => option?.label === value);
      //checking if data from props is available to avoid setting null value in label/value
      if (
        opportunity &&
        !data?.length &&
        props?.data?.opportunityName &&
        props?.data?.opportunity
      ) {
        data = [
          {
            label: props.data.opportunityName,
            value: props.data.opportunity,
          },
        ];
      }
      if (
        account &&
        !data?.length &&
        props?.data?.accountName &&
        props?.data?.accountId
      ) {
        data = [
          {
            label: props.data.accountName,
            value: props.data.accountId,
          },
        ];
      }
      selectedValueRef.current = data[0];
      setSelectedOption(data[0]);
    }, [value, values]);

    const handleValueChange = (option) => {
      selectedValueRef.current = option;
      setSelectedOption(option);
      props.stopEditing();
    };

    useImperativeHandle(ref, () => ({
      getValue() {
        return selectedValueRef?.current;
      },
    }));

    const customStyles = {
      control: (provided) => ({
        ...provided,
        width: 250,
      }),
      ...colourStyles,
    };

    return (
      <Select
        value={selectedOption}
        onChange={handleValueChange}
        options={values}
        styles={customStyles}
        menuPosition="fixed"
      />
    );
  }
);
