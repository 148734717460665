import React, { useState, useEffect, Suspense } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Select from 'react-dropdown-select';
import { ReactComponent as WarningSVG } from '../../Icons/NewImage/warning.svg';
import { ReactComponent as CalendarViewSVG } from '../../Icons/NewImage/calender.svg';
import { ReactComponent as LinkSVG } from '../../Icons/LinkIcon.svg';
import { ReactComponent as PEOPLESVG } from '../../Icons/NewImage/people.svg';
import { ReactComponent as PersonSVG } from '../../Icons/Person.svg';
import { ReactComponent as AddPersonSVG } from '../../Icons/AddPerson.svg';
import { ReactComponent as AddPersonFilledSVG } from '../../Icons/AddPersonFilled.svg';
import { ReactComponent as RedirectSVG } from '../../Icons/NewImage/redirect.svg';
import { ReactComponent as AutoTagSVG } from '../../Icons/autoTagForPopover.svg';
import { ReactComponent as CrossSVG } from '../../Icons/accounts-close.svg';
import { formatDate } from '../../Utils/DateUtils';
import Button from '../../common/Button';
import {
  useSaveAdditionalEventInfoMutation,
  extendedCalendarSlice,
} from './calendarSlice';
import { EVENT_TYPE } from '../../Constant/listView';
import {
  convertHTMLtoPlainText,
  filterOutcome,
  getBackgroundColor,
  handleFormValidation,
} from './../../Utils/common';
import {
  useFetchAllDealsQuery,
  useFetchAllAccountsQuery,
} from '../workbook/workbookSlice';
import {
  useAddExternalContactMutation,
  useFetchDealContactsQuery,
} from '../../Accounts/Collabrators/collaboratorsSlice';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
import useDealDetails from '../../hooks/useDealDetails';
import RichTextEditor from '../../common/RichTextEditor';
import DualRingLoader from '../../common/DualRingLoader';
import {
  useAddNoteMutation,
  useUpdateNoteMutation,
} from '../../common/notes/notesSlice';
import DOMPurify from 'dompurify';

const CalendarPopover = ({
  account,
  eventInfo,
  isAccountView,
  contributionFromParent,
  outcomesForDropdown,
  handleClose = () => {},
  showEventDetails,
}) => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('user'));
  const userDomain = user?.email?.substring(user.email.indexOf('@') + 1);

  const eventTypeOptions = EVENT_TYPE;
  const [uniqueAttendees, setUniqueAttendees] = useState([]);

  const [addExternalContact] = useAddExternalContactMutation();

  const [allOpportunities, setAllOpportunities] = useState([]);
  const [opportunityOptions, setOpportunityOptions] = useState([]);
  const [uriLink, setUriLink] = useState();
  const [disableChange, setDisableChange] = useState(false); // * Disable if populating calendar details fails
  const [checked, setChecked] = useState(false);
  const [calendarTab, setCalendarTab] = useState('');
  const [eventTypeValue, setEventTypeValue] = useState([]);
  const [opportunityValue, setOpportunityValue] = useState([]);
  const [accountValue, setAccountValue] = useState([]);
  const [outcomeValue, setOutcomeValue] = useState([]);
  const [hover, setHover] = useState(null);
  const [value, setValue] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [addNote, { isLoading: addNoteLoading }] = useAddNoteMutation();
  const [updateNote, { isLoading: updateNoteLoading }] =
    useUpdateNoteMutation();

  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !JSON.parse(localStorage.getItem('user')),
  });

  const { data: contacts } = useFetchDealContactsQuery(
    { dealId: eventInfo?.opportunity },
    { skip: !eventInfo?.opportunity }
  );

  const { goToDealDetails, loading: dealDetailsLoading } = useDealDetails();

  const {
    data: { accountSummary = [], opportunityLabelMap = {} } = {
      accountSummary: null,
      opportunityLabelMap: null,
    },
  } = useFetchAllDealsQuery();

  const {
    data: { allAccounts = [], accountLabelMap = {} } = {
      allAccounts: null,
      accountLabelMap: null,
    },
  } = useFetchAllAccountsQuery();

  useEffect(() => {
    //Sort the attendees list (external contacts followed by internal contacts)
    const attendees = [
      ...new Set(eventInfo?.attendees?.map((attendee) => attendee?.email)),
    ];
    setUniqueAttendees(
      attendees?.sort((a, b) => {
        if (a.includes(userDomain) && !b.includes(userDomain)) return 1;
        if (!a.includes(userDomain) && b.includes(userDomain)) return -1;
        if (a < b) {
          return -1;
        }
        return 1;
      })
    );
    setValue(eventInfo?.notes?.description ? eventInfo.notes.description : '');
  }, [eventInfo]);

  const handleAddContacts = (email) => {
    let contactName = email?.substring(0, email.indexOf('@')).split('.');
    contactName = contactName.join(' ');

    const contactPayLoad = {};
    contactPayLoad.displayName = contactName;
    contactPayLoad.addedBy = {};
    contactPayLoad.addedBy.id = user?.userId;
    contactPayLoad.addedBy.email = user?.email;
    contactPayLoad.addedBy.displayName = user?.name;
    contactPayLoad.addedInternally = true;
    contactPayLoad.dealId = [eventInfo?.opportunity];
    contactPayLoad.accountId = eventInfo?.accountId;
    contactPayLoad.email = email?.toLowerCase();
    const externalContact = {
      contact: contactPayLoad,
      accountId: eventInfo?.accountId,
      dealId: eventInfo?.opportunity,
      relatedContacts: [],
    };

    addExternalContact({ body: externalContact })
      .unwrap()
      .then((data) => {
        if (data && data.length > 0) {
          toast.success('Contact added to deal!');
        }
      })
      .catch((error) => {
        console.warn('error', error);
        toast.error('Error: Collaborator not added!');
      });
  };

  useEffect(() => {
    setChecked(false);
  }, [eventInfo?.id]);

  useEffect(() => {
    if (
      contributionFromParent &&
      accountSummary &&
      opportunityLabelMap &&
      accountLabelMap
    ) {
      if (disableChange) {
        setDisableChange(false);
      }
      populateCalendarOutcomes(eventInfo);
      return;
    }
    if (
      !contributionFromParent ||
      !accountSummary ||
      !opportunityLabelMap ||
      !accountLabelMap
    ) {
      if (!disableChange) {
        setDisableChange(true);
      }
      return;
    }
  }, [
    eventInfo,
    contributionFromParent,
    accountSummary,
    opportunityLabelMap,
    accountLabelMap,
  ]);

  const [saveAdditionalEventInfo, { isLoading }] =
    useSaveAdditionalEventInfoMutation();

  // To populate data from eventInfo on mount
  const populateCalendarOutcomes = (calendarInfo) => {
    if (!calendarInfo) {
      return;
    }

    // ==== Building options for opportunity dropdown ==== //
    const accountSummaryCopy = [...accountSummary];
    const options = accountSummaryCopy
      ?.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
      ?.map((option) => ({
        label: option.label,
        value: option.value,
        accountId: option.accountId,
      }));
    setAllOpportunities(options);
    setOpportunityOptions(options);

    // ==== Setting opportunity value ==== //
    let opportunity;
    if (calendarInfo?.opportunity !== null) {
      opportunity = [
        {
          label:
            opportunityLabelMap[calendarInfo?.opportunity] ||
            calendarInfo?.opportunityName,
          value: calendarInfo?.opportunity,
        },
      ];
    } else {
      opportunity = [];
    }
    setOpportunityValue(opportunity);

    // ==== Setting account value ==== //
    if (calendarInfo?.accountId !== null) {
      const account = accountLabelMap[calendarInfo?.accountId];
      if (account) {
        setAccountValue([account]);
        setOpportunityOptions(account.deals);
      }
      //If user is not a part of the account
      if (!account) {
        const account = [
          {
            label: calendarInfo?.accountName,
            value: calendarInfo?.accountId,
            deals: [],
          },
        ];
        setAccountValue(account);
        setOpportunityOptions([]);
      }
    } else {
      setAccountValue([]);
    }

    // ==== Setting outcome value ==== //
    const outcomeIdx = contributionFromParent.findIndex((element) => {
      return element.value === eventInfo?.contribution;
    });
    if (outcomeIdx > -1) {
      setOutcomeValue([contributionFromParent[outcomeIdx]]);
    } else {
      setOutcomeValue([]);
    }

    if (calendarInfo.eventType) {
      const eventTypeCapitalize =
        calendarInfo.eventType[0] +
        calendarInfo.eventType.substring(1).toLowerCase();
      setEventTypeValue([
        { label: eventTypeCapitalize, value: calendarInfo.eventType },
      ]);
    }
    if (calendarInfo?.selectedTab) {
      setCalendarTab(calendarInfo?.selectedTab);
    } else {
      setCalendarTab(calendarInfo?.synced ? 'details' : 'outcome');
    }
  };

  // To update eventInfo on submit
  const handleMeetingSync = async () => {
    const updatedEventInfo = { ...eventInfo };
    updatedEventInfo.args = undefined;

    if (eventTypeValue && eventTypeValue[0]) {
      updatedEventInfo.eventType = eventTypeValue[0]?.value;
    }

    if (opportunityValue[0]) {
      updatedEventInfo.opportunity = opportunityValue[0]?.value;
      updatedEventInfo.opportunityName = opportunityValue[0]?.label;
    }

    if (!opportunityValue || opportunityValue?.length < 1) {
      updatedEventInfo.opportunity = null;
      updatedEventInfo.opportunityName = null;
    }

    if (accountValue[0]) {
      updatedEventInfo.accountId = accountValue[0]?.value;
      updatedEventInfo.accountName = accountValue[0]?.label;
    }

    if (!accountValue || accountValue?.length < 1) {
      updatedEventInfo.accountId = null;
      updatedEventInfo.accountName = null;
    }

    if (outcomeValue[0]) {
      updatedEventInfo.contribution = outcomeValue[0]?.value;
    }

    if (eventTypeValue && eventTypeValue[0]?.value === 'PERSONAL') {
      updatedEventInfo.opportunity = null;
      updatedEventInfo.contribution = null;
      updatedEventInfo.accountId = null;
      updatedEventInfo.accountName = null;
    }

    const meetingObject = {
      events: updatedEventInfo,
      checked,
      id: eventInfo.id,
    };

    try {
      const data = await saveAdditionalEventInfo({
        params: eventInfo?.args || {}, // Information for optimistic updates
        body: meetingObject,
      }).unwrap();

      if (data && data[0].eventType === 'PERSONAL') {
        setOpportunityValue([]);
        setAccountValue([]);
        setOutcomeValue([]);
      }
      // ---- To handle focus cards ---- //
      //  ! Focus cards have been removed. Commenting this code out for now
      // if (!isAccountView && data && data[0]) {
      //   if (
      //     toISODate(data[0].start) === new Date().toLocaleDateString('en-CA') &&
      //     data[0].opportunity !== null
      //   ) {
      //     getDealOverView();
      //   }
      // }

      handleClose();
      dispatch(
        extendedCalendarSlice.util.invalidateTags([
          { type: 'Calendar', id: 'Account-events' },
        ])
      );
      toast.success('Saved Successfully');
    } catch (err) {
      console.error('Failed to save the event', err);
    }
  };

  // To change tabs
  const makeCalendarOutcomeActive = () => setCalendarTab('outcome');
  const makeCalendarDetailsActive = () => setCalendarTab('details');
  const makeCalendarNotesActive = () => {
    showEventDetails(eventInfo, 'notes');
    setCalendarTab('notes');
  };

  // On change handler for calendar outcomes fields
  const onDropdownSelect = (type, option) => {
    switch (type) {
      case 'opportunity':
        if (typeof option[0] !== 'undefined') {
          setOpportunityValue([option[0]]);
          //Auto select account dropdown
          if (accountValue.length === 0) {
            const account = accountLabelMap[option[0].accountId];
            if (account) {
              setAccountValue([account]);
              setOpportunityOptions(account.deals);
            } else {
              setAccountValue([{ label: 'NA', value: 'NA' }]);
            }
          }
        } else {
          setOpportunityValue([]);
        }
        break;
      case 'contribution':
        if (typeof option[0] !== 'undefined') {
          setOutcomeValue([option[0]]);
        }
        break;
      case 'eventType':
        if (typeof option[0] !== 'undefined') {
          setEventTypeValue([option[0]]);
        }
        break;
      case 'account':
        if (typeof option[0] !== 'undefined') {
          setAccountValue([option[0]]);
          const deals = option[0].deals;
          //Flush selected opportunity value if it is not under the selected account
          if (
            deals?.length &&
            opportunityValue.length &&
            !deals.some((deal) => deal.value === opportunityValue[0].value)
          ) {
            setOpportunityValue([]);
          }
          //Filter out opportunity options based on account selected
          setOpportunityOptions(deals);
        }
        //Flushing selected account value and filtered opportunity options when account value gets flushed
        else {
          setAccountValue([]);
          setOpportunityOptions(allOpportunities);
        }
        break;
    }
  };

  const handleCheckboxChange = (event) => setChecked(event.target.checked);

  const handleAccountRedirection = (eventData) => {
    if (eventData && eventData.length > 0) {
      account = {
        dealId: eventData[0].value,
        name: eventData[0].label,
      };
      goToDealDetails({
        dealId: account?.dealId,
        name: account?.name ?? 'No name',
      });
    }
  };

  useEffect(() => {
    if (eventInfo?.conference_data) {
      const link = JSON.parse(eventInfo.conference_data)?.entryPoints?.[0].uri;
      setUriLink(link);
    }
  }, [eventInfo?.conference_data]);

  const handleSaveNotes = (isUpdate) => {
    isUpdate ? handleUpdateNote() : handleAddNote();
  };

  const handleChange = (value) => setValue(value);

  const handleAddNote = () => {
    let sanitizedValue = DOMPurify.sanitize(value);
    const plainText = convertHTMLtoPlainText(sanitizedValue);
    const formValidation = handleFormValidation(
      { description: value },
      null,
      false
    );
    if (formValidation?.isFormValid) {
      let parser = document.createElement('div');
      parser.innerHTML = sanitizedValue;
      // to get only the description
      sanitizedValue = parser.innerHTML;

      const data = {
        userId: user?.userId,
        accountId: eventInfo?.accountId,
        dealName: eventInfo?.opportunityName,
        dealId: eventInfo?.opportunity,
        eventId: eventInfo?.eventId,
        eventTitle: eventInfo?.title,
        description: sanitizedValue,
        plainText,
      };

      addNote({ body: data })
        .unwrap()
        .then((res) => {
          if (eventInfo?.selectedTab === 'notes') {
            dispatch(
              extendedCalendarSlice.util.invalidateTags([
                { type: 'Calendar', id: 'Calendar_Dashbaord' },
              ])
            );
            handleClose();
          } else {
            dispatch(
              extendedCalendarSlice.util.invalidateTags([
                { type: 'Calendar', id: 'Account-events' },
              ])
            );
          }
          if (!res?.data) {
            toast.error(res?.message);
          } else {
            toast.success('Note added successfully!');
          }
        })
        .catch((error) => {
          console.warn('error', error);
          toast.error('Error: Note not added!');
        });
    } else {
      formValidation?.formErrors && setFormErrors(formValidation?.formErrors);
    }
  };

  const handleUpdateNote = () => {
    const formValidation = handleFormValidation(
      { description: value },
      true,
      false
    );
    if (formValidation?.isFormValid) {
      const sanitizedValue = DOMPurify.sanitize(value);
      const note = eventInfo?.notes;
      const data = {
        id: note.id,
        dealId: note.dealId,
        description: sanitizedValue,
        plainText: convertHTMLtoPlainText(sanitizedValue),
      };
      updateNote({
        body: data,
      })
        .unwrap()
        .then(() => {
          if (eventInfo?.selectedTab === 'notes') {
            dispatch(
              extendedCalendarSlice.util.invalidateTags([
                { type: 'Calendar', id: 'Calendar_Dashbaord' },
              ])
            );
            handleClose();
          } else {
            dispatch(
              extendedCalendarSlice.util.invalidateTags([
                { type: 'Calendar', id: 'Account-events' },
              ])
            );
          }
          toast.success('Note updated successfully!');
        })
        .catch((error) => {
          console.warn('Error:', error);
          toast.error('Error: Note not updated!');
        });
    } else {
      formValidation?.formErrors && setFormErrors(formValidation?.formErrors);
    }
  };

  return (
    <>
      {eventInfo && (
        <div className="calendar-popup">
          <div className="popup-title flex-align-center">
            <div className="popup-title-icon">
              {' '}
              <CalendarViewSVG />{' '}
            </div>
            <div>
              <div className="font-bold">{eventInfo.title}</div>
              <div className="mb-5 text-xs text-weight-regular">
                {/* if start date is not same as end date display start & end date ,
               else display start date */}
                {eventInfo.start && (
                  <>
                    {formatDate(
                      new Date(eventInfo?.start),
                      'd MMM, y , h:mm a'
                    )}

                    {formatDate(new Date(eventInfo?.end), 'd MMM,y') !==
                    formatDate(new Date(eventInfo?.start), 'd MMM,y') ? (
                      <>
                        {'-'}
                        {formatDate(
                          new Date(eventInfo?.end),
                          'd MMM, y, h:mm a'
                        )}
                      </>
                    ) : (
                      <>
                        {'-'}
                        {formatDate(new Date(eventInfo?.end), 'h:mm a')}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="flex-align-center col-gap">
                {!eventInfo.synced && (
                  <div className="flex-align-center tag tag-orange col-gap">
                    <WarningSVG />
                    Untagged Meetings
                  </div>
                )}
                {eventInfo.synced && !eventInfo.manualSynced && (
                  <div className="flex-align-center tag tag-amber col-gap autotag">
                    <AutoTagSVG />
                    Meeting Auto-Tagged
                  </div>
                )}

                {eventInfo?.contribution &&
                  eventInfo?.eventType !== 'PERSONAL' && (
                    <div className={`tag ${getBackgroundColor(eventInfo)}`}>
                      {filterOutcome(
                        outcomesForDropdown,
                        eventInfo?.contribution
                      )}
                    </div>
                  )}
              </div>
            </div>
            {eventInfo.eventType !== 'PERSONAL' &&
              opportunityValue &&
              opportunityValue.length > 0 &&
              !isAccountView &&
              opportunityLabelMap[eventInfo?.opportunity] && (
                <div className="cal-redirect-btn">
                  <Button
                    icon
                    type="ghost"
                    className="btn-icon-only cal-redirect"
                    onClick={(ev) =>
                      handleAccountRedirection(opportunityValue, ev)
                    }
                    loading={dealDetailsLoading}
                    disabled={dealDetailsLoading}
                  >
                    <RedirectSVG />
                  </Button>
                </div>
              )}
            {isAccountView && (
              <div className="ml-auto">
                <Button
                  icon={true}
                  type="ghost"
                  className="btn-icon-only drawer-btn"
                  onClick={handleClose}
                >
                  {' '}
                  <CrossSVG />
                </Button>
              </div>
            )}
          </div>

          <div className="calendar-popup-content--header">
            <button
              onClick={makeCalendarOutcomeActive}
              className={`tab-button ${
                calendarTab === 'outcome' ? 'tab-active' : ''
              }`}
            >
              Outcomes
            </button>
            <button
              onClick={makeCalendarDetailsActive}
              className={`tab-button ${
                calendarTab === 'details' ? 'tab-active' : ''
              }`}
            >
              Details
            </button>
            {eventInfo?.opportunity && (
              <button
                onClick={makeCalendarNotesActive}
                className={`tab-button ${
                  calendarTab === 'notes' ? 'tab-active' : ''
                }`}
              >
                Notes
              </button>
            )}
          </div>
          {calendarTab === 'details' && (
            <div className="calender-popup-body">
              <div className="flex-align-center">
                <LinkSVG className="link-icon" />
                {eventInfo?.hangoutLink ||
                (configFeatures.data.calendar === 'microsoft'
                  ? JSON.parse(eventInfo.location)?.displayName
                  : eventInfo.location) ||
                eventInfo?.conference_data ? (
                  <div className="ml-16 mt-16">
                    <a
                      className="gcal-link"
                      target="_blank"
                      href={
                        eventInfo?.hangoutLink ||
                        (configFeatures.data.calendar === 'microsoft'
                          ? JSON.parse(eventInfo.location)?.displayName
                          : eventInfo.location) ||
                        uriLink
                      }
                    >
                      <div className="btn btn-primary">Open Meeting Link</div>
                      <br />
                      <div className="gcal-text">
                        {eventInfo?.hangoutLink ||
                          (configFeatures.data.calendar === 'microsoft'
                            ? JSON.parse(eventInfo.location)?.displayName
                            : eventInfo.location) ||
                          uriLink}
                      </div>
                    </a>
                  </div>
                ) : (
                  <>
                    <span className="gcal-text"> No meeting available</span>
                  </>
                )}
              </div>

              <div className="calendar-details-container">
                <div className="flex-align-center p-10">
                  <PEOPLESVG className="mr-10" />
                  <span className="details-title">
                    Guests(
                    {uniqueAttendees && uniqueAttendees.length > 0
                      ? uniqueAttendees?.length
                      : 1}
                    )
                  </span>
                </div>
                <ul>
                  {uniqueAttendees && uniqueAttendees.length > 0 ? (
                    uniqueAttendees?.map((email, index) => (
                      <div
                        className="calendar-details-content"
                        key={`${email}-${index}`}
                      >
                        <div className="column left">{email}</div>
                        <div className="column right">
                          {eventInfo?.opportunity &&
                            !email.includes(userDomain) &&
                            (contacts?.includes(email) ? (
                              <span className="pl-4">
                                {' '}
                                <PersonSVG />{' '}
                              </span>
                            ) : (
                              <span
                                title="Add as an External Collaborator"
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(null)}
                                onClick={() => handleAddContacts(email)}
                              >
                                {hover === index ? (
                                  <AddPersonFilledSVG />
                                ) : (
                                  <AddPersonSVG />
                                )}
                              </span>
                            ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <li className="calendar-details-content">
                      {eventInfo.organizer}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          {/* -------------------------------------------------------------------------------- */}
          {calendarTab === 'outcome' && (
            <div className="calender-popup-body">
              <div className="calender-sync-details">
                <div className="Dropdown-container">
                  <div className="Dropdown-label">Event</div>
                  <Select
                    options={eventTypeOptions}
                    onChange={(options) =>
                      onDropdownSelect('eventType', options)
                    }
                    values={eventTypeValue}
                    disabled={disableChange}
                  />
                </div>
                {(!eventTypeValue ||
                  eventTypeValue[0]?.value !== 'PERSONAL') && (
                  <div className="Dropdown-container">
                    <div className="Dropdown-label">Outcome</div>
                    <Select
                      options={outcomesForDropdown ? outcomesForDropdown : []}
                      onChange={(options) =>
                        onDropdownSelect('contribution', options)
                      }
                      values={outcomeValue}
                      disabled={disableChange}
                    />
                  </div>
                )}
                {(!eventTypeValue ||
                  eventTypeValue[0]?.value !== 'PERSONAL') && (
                  <>
                    <div className="Dropdown-container">
                      <div className="Dropdown-label">Account</div>
                      <Select
                        options={allAccounts}
                        onChange={(options) =>
                          onDropdownSelect('account', options)
                        }
                        values={accountValue}
                        disabled={disableChange}
                      />
                    </div>

                    <div className="Dropdown-container">
                      <div className="Dropdown-label">Opportunity</div>
                      <Select
                        options={opportunityOptions}
                        onChange={(options) =>
                          onDropdownSelect('opportunity', options)
                        }
                        values={opportunityValue}
                        disabled={disableChange}
                      />
                    </div>
                  </>
                )}

                <div className="event-checkbox">
                  <input
                    type="checkbox"
                    id="event-checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="event-checkbox" className="checkbox">
                    {' '}
                    Update calendar outcomes to all meetings with similar title{' '}
                  </label>
                </div>
              </div>
              <div className="flex popup-button-wrapper">
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={handleMeetingSync}
                  disabled={isLoading || disableChange}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
          {calendarTab === 'notes' && (
            <div className="calendar-notes">
              <div className="notes-rich__text___container">
                <div className="font-bold pb-10">Notes :</div>
                <Suspense
                  fallback={
                    <div className="loader-container--suspense">
                      <DualRingLoader dark />
                    </div>
                  }
                >
                  <RichTextEditor
                    placeholder={'Add new note'}
                    value={value}
                    onChange={handleChange}
                    activeAccounts={account}
                  />
                  <span className="error">{formErrors['description']}</span>
                  <div className="button-container">
                    <Button
                      type={'primary'}
                      onClick={() =>
                        handleSaveNotes(eventInfo?.notes?.description)
                      }
                      disabled={addNoteLoading || updateNoteLoading}
                      loading={addNoteLoading || updateNoteLoading}
                    >
                      {!eventInfo?.notes?.description ? 'Save' : 'Update'}
                    </Button>
                  </div>
                </Suspense>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CalendarPopover;
