import React from 'react';
import emptyHyphen from '../../../_helpers/empty-hyphen';
import { createMarkup } from '../../../Utils/common';
import { selectUserMap, useGetUsersQuery } from '../../../app/api/usersSlice';
import { formatDate } from '../../../Utils/DateUtils';
import Button from '../../../common/Button';
import { ReactComponent as EditSVG } from '../../../Icons/NewImage/edit.svg';
import { ReactComponent as DeleteSVG } from '../../../Icons/NewImage/delete.svg';

const NoteItem = ({
  item,
  handleUpdate,
  handleDelete,
  isReadOnly,
  eventInfo,
}) => {
  const { dealName, description, createdDate } = item;
  const { data: userMap } = useGetUsersQuery(undefined, {
    selectFromResult: selectUserMap,
  });
  const editNote = () => handleUpdate(item);
  const deleteNote = () => handleDelete(item);
  return (
    <div className="notes-item_container">
      <div className="notes-metadata__container">
        <div className="notes-user__metadata">
          <div>
            {eventInfo && eventInfo.length > 0 ? 'Meeting ' : null}
            Notes By{' '}
            <span className="font-bold">
              {userMap[item.userId] && userMap[item.userId].name}
            </span>
          </div>
          <div>{emptyHyphen(createdDate)}</div>
        </div>
        {eventInfo && eventInfo.length > 0 ? (
          <div className="notes-event__metadata">
            <span className="font-bold">{eventInfo[0]?.title}</span> on{' '}
            {eventInfo[0].start && (
              <>
                {formatDate(new Date(eventInfo[0]?.start), 'd MMM, y , h:mm a')}

                {formatDate(new Date(eventInfo[0]?.end), 'd MMM,y') !==
                formatDate(new Date(eventInfo[0]?.start), 'd MMM,y') ? (
                  <>
                    {'-'}
                    {formatDate(
                      new Date(eventInfo[0]?.end),
                      'd MMM, y, h:mm a'
                    )}
                  </>
                ) : (
                  <>
                    {'-'}
                    {formatDate(new Date(eventInfo[0]?.end), 'h:mm a')}
                  </>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
      <div className="notes-description__container">
        <div className="notes-item_description">
          <span dangerouslySetInnerHTML={createMarkup(description)} />
        </div>
        {!isReadOnly && (
          <div className="notes-action">
            <Button
              icon
              className={`btn-icon btn-icon-only`}
              type="ghost"
              onClick={editNote}
            >
              <EditSVG />
            </Button>
            <Button
              icon
              className={`btn-icon btn-icon-only`}
              type="ghost"
              onClick={deleteNote}
            >
              <DeleteSVG />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoteItem;
