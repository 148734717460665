import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '../../common/Drawer';
import PresentationView from './PresentationView';
import {
  isSalesUser as findIsSalesUser,
  getLaneId,
  isProductUser as findIsProductUser,
} from '../../Utils/common';
import useDealDetails from '../../hooks/useDealDetails';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ReactComponent as EditSVG } from '../../Icons/NewImage/edit.svg';
import Button from '../../common/Button';
import SyncCRMDrawer from '../../Accounts/SyncCRMDrawer/SyncCRMDrawer';
import {
  selectSelectedCard,
  useFetchParentStagesQuery,
} from '../../Pipeline/pipelineSlice';
import EditOpportunity from '../../Dashboard/CreateOpportunity';
import ToolTip from '../../common/ToolTip';

const DealPresentationViewDrawer = ({
  showPresentationView,
  closePresentationView,
  dealId,
  presalesUser,
  redirect,
  handlePresalesUserChange,
  preskaleCreated,
  refreshView,
  setRefreshForCreateOpportunity,
  iscrmAccount,
  selectedUser,
  backToPreviousState,
  productGapDetailView = false,
}) => {
  const history = useHistory();
  const isSalesUser = findIsSalesUser();
  const isProductUser = findIsProductUser();
  const selectedCard = useSelector(selectSelectedCard);
  const [showSyncCRMModal, setShowSyncCRMModal] = useState(false);
  const [openEditOpportunity, setOpenEditOpportunity] = useState(false);
  const showEditOpportunity = () => setOpenEditOpportunity(true);
  const closeEditOpportunity = () => setOpenEditOpportunity(false);
  const {
    dealDetails,
    error,
    loading: dealDetailsLoading,
    refetch: fetchDealDetails,
  } = useDealDetails(dealId, false);
  const { data: stages, isLoading: stagesLoading } = useFetchParentStagesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const options = useMemo(() => {
          const options = data?.ids?.map((stage) => ({
            label: data?.entities[stage]?.name,
            value: stage,
          }));
          return { ...data, options };
        }, [data]);

        return { data: options };
      },
    }
  );

  const handleEditOpportunitySubmit = (createdDeal = null) => {
    //handles create opportunity
    if (setRefreshForCreateOpportunity) {
      setRefreshForCreateOpportunity(createdDeal);
    }
    fetchDealDetails();
  };
  const handleEditOpportunityDelete = (createdDeal = null) => {
    //handles delete deal
    closeEditOpportunity();
    const data = { ...createdDeal, ...selectedCard };
    if (setRefreshForCreateOpportunity) {
      setRefreshForCreateOpportunity(data);
    }
    closePresentationView();
  };

  //Update selected card with new deal info and
  //reopen the presentation view with updated deal data.
  const handleSyncSuccess = (activeCard) => {
    const laneId = getLaneId(stages, activeCard.currentPreskaleStageName);

    //Constructing an object with synced CRM deal information.
    let cardData = {};
    cardData.laneId = laneId && laneId[0];
    cardData.dealId = activeCard.dealId;
    cardData.name = activeCard.dealName;
    cardData.presalesUser = activeCard?.presalesUser?.displayName;
    cardData.crmStage = activeCard?.crmStage;
    cardData.currentPreskaleStageName = activeCard?.currentPreskaleStageName;
    cardData.dealValue = activeCard?.dealValue;
    cardData.preskaleCreated = activeCard?.preskaleCreated;
    const selectedDeal = { ...selectedCard };
    selectedDeal.deleteDeal = true;
    selectedDeal.update = false;

    // * Load the deal that the preskale stage was synced to
    history.replace(cardData.dealId);

    if (setRefreshForCreateOpportunity) {
      setRefreshForCreateOpportunity(selectedDeal);
    }

    if (refreshView) {
      // * Update view data
      refreshView(cardData);
    }
  };

  const redirection = () => {
    if (error) {
      toast.error('Error: Deal Details Loading Error');
      console.error(error);
      return;
    }
    closePresentationView();
    if (!dealDetails?.dealId) {
      console.error('Cannot redirect: Missing deal ID!');
      return;
    }
    history.push(`/dealDetails/${dealId}`);
  };
  const toggleSyncCRMModal = () => {
    setShowSyncCRMModal(!showSyncCRMModal);
  };

  return (
    <>
      <Drawer
        showDrawer={showPresentationView}
        closeDrawer={closePresentationView}
        redirect={redirect && !isSalesUser && !isProductUser}
        redirecting={dealDetailsLoading}
        backToPreviousState={
          productGapDetailView && !dealDetailsLoading
            ? backToPreviousState
            : false
        }
        backToPreviousClassName="fill-colors-primary"
        title={
          <div>
            <ToolTip text={dealDetails?.name} trigger="click">
              <h1 className="heading_level3">{dealDetails?.name}</h1>
            </ToolTip>
            <h4 className="heading_level5 account-name">
              {dealDetails?.accountName}
            </h4>
          </div>
        }
        custom={[
          <div key="sync-deal">
            {(preskaleCreated || dealDetails?.preskaleCreated) &&
              !isSalesUser &&
              iscrmAccount &&
              !isProductUser && (
                <Button
                  type="primary"
                  className="sync-deal-btn mr-10"
                  onClick={toggleSyncCRMModal}
                >
                  Sync CRM Deal
                </Button>
              )}
          </div>,
        ]}
        extra={[
          <div key="edit-deal">
            {(preskaleCreated || dealDetails?.preskaleCreated) &&
              !isSalesUser &&
              !isProductUser && (
                <Button
                  icon
                  className="btn-icon-only mr-10"
                  type="ghost"
                  onClick={showEditOpportunity}
                >
                  <EditSVG />
                </Button>
              )}
          </div>,
        ]}
        className="presentationViewDrawer"
        headerClassName="presentationViewHeader"
        onRedirect={redirection}
        customClassName="presentationViewCloseBtn"
        closeOnOverlayClick={false}
        shouldCloseOnOverlayEscClick={closePresentationView}
      >
        <PresentationView
          dealId={dealId}
          presalesUser={presalesUser}
          reloadLaneOnSEUserChange={handlePresalesUserChange}
          selectedUser={selectedUser}
        />
      </Drawer>
      <SyncCRMDrawer
        showDrawer={showSyncCRMModal}
        closeDrawer={toggleSyncCRMModal}
        existingDeal={dealDetails}
        handleSyncSuccess={handleSyncSuccess}
        title={'Sync Opportunity'}
      />
      {openEditOpportunity && (
        <EditOpportunity
          dealDetail={dealDetails}
          edit
          handleDelete={handleEditOpportunityDelete}
          handleSubmit={handleEditOpportunitySubmit}
          handleClose={closeEditOpportunity}
          openDrawer={openEditOpportunity}
          key="edit-opportunity"
        />
      )}
    </>
  );
};
export default DealPresentationViewDrawer;
