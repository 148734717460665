import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { _setDateFilter } from '../utils/pipelineUtils';
import Button from '../../common/Button';
import { useLazySyncCRMQuery } from '../../app/api/dataEngineeringService';
import { toast } from 'react-toastify';
import styles from '../../styles/components/preskaleDropdownMenu.module.scss';

const PipelineDropdownMenu = (
  {
    closePopup,
    changeView,
    isCRMStageView,
    crmSyncSuccessCb,
    crmDetails,
    crm,
    pipelineView,
    collapseEmptyLanes = false,
    toggleLaneCollapse,
    disable,
  },
  ref
) => {
  const [syncCRM, { isFetching: isSyncing }] = useLazySyncCRMQuery();
  const handleInvokeCRMSync = () => {
    const profileData = JSON.parse(localStorage.getItem('user'));
    const state = profileData.userId + ':' + profileData.companyId;
    syncCRM({ integration: crm?.toLowerCase(), state })
      .unwrap()
      .then(handleCRMSyncSuccess)
      .catch(handleCRMSyncError);
  };

  const handleCRMSyncSuccess = async () => {
    toast.success('Sync initiated successfully');
    if (crm === 'salesforce') {
      crmSyncSuccessCb(5000); // * Trigger lane refresh by checking for completion of sync by polling crmDetails at an interval of 5secs
    }
  };

  const handleCRMSyncError = (error) => {
    console.warn('error', error);
    if (error?.response && !error.response?.data?.status) {
      toast.error(error.response);
      return;
    }
    if (error?.data?.message) {
      toast.error(error.data.message);
      return;
    }
    toast.error('Sync initiation failed');
  };
  const syncCRMCb = () => {
    handleInvokeCRMSync();
    closePopup();
  };
  const switchView = () => {
    changeView();
    closePopup();
  };

  return (
    <div ref={ref} className={styles.container}>
      <Button
        type="text"
        data-tip="Sync Deals"
        disabled={
          isSyncing ||
          (crmDetails?.crmType?.type === 'salesforce' &&
            crmDetails?.crmType?.token.manual_sync) ||
          (!crmDetails?.crmType?.type && crm !== 'freshworks')
        }
        loading={isSyncing}
        onClick={syncCRMCb}
      >
        Sync CRM
      </Button>
      {pipelineView[0]?.value === 'kanban' && (
        <Button
          type="text"
          data-tip="Switch View"
          disabled={
            disable ||
            (crmDetails?.crmType?.type === 'salesforce' &&
              crmDetails?.crmType?.token.manual_sync)
          }
          onClick={switchView}
        >
          {isCRMStageView === 'crm'
            ? 'Switch to Preskale Stage View'
            : 'Switch to CRM Stage View'}
        </Button>
      )}
      <Button
        type="text"
        data-tip={`${collapseEmptyLanes ? 'Expand' : 'Collapse'} empty lanes`}
        onClick={toggleLaneCollapse}
      >
        {collapseEmptyLanes ? 'Expand' : 'Collapse'} empty lanes
      </Button>
    </div>
  );
};

export default React.forwardRef(PipelineDropdownMenu);
