import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../Icons/accounts-close.svg';
import ProductGapSearch from './ProductGapSearch';
import AddUpdatePgDisplay from './AddUpdatePgDisplay';
import MainForm from './MainForm';
import NewProductGapForm from './NewProductGapForm';
import {
  productGapState,
  saveNewPGTitle,
  saveProductGap,
  saveRefetchPgUsecase,
  // useAddJiraProductGapMutation,
  useAddProductGapMutation,
  useFetchAllProductGapsQuery,
} from './productGapSlice';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { convertHTMLtoPlainText } from '../../Utils/common';
import mixpanel from 'mixpanel-browser';
import { useFetchCompanyConfigDataQuery } from '../../Company/companySlice';
function AddPg(props) {
  const [integrationType, setIntegrationType] = useState(null);
  const dispatch = useDispatch();
  const { onClose, account, open, onSuccess } = props;
  const { selectedProductGap } = useSelector(productGapState);
  const [addNewPgItem, setAddNewPgItem] = useState(false);
  const { data: productGaps } = useFetchAllProductGapsQuery(
    {
      dealId: account?.dealId,
    },
    {
      skip: !account?.dealId,
    }
  );

  const reset = () => {
    setAddNewPgItem(false);
    dispatch(saveProductGap({}));
    dispatch(saveNewPGTitle(''));
    formik.resetForm();
  };

  const handleClose = () => {
    onClose(account);
    reset();
  };

  const handleNext = (title) => {
    closeNewPGForm();
    dispatch(saveNewPGTitle(title));
  };

  const closeNewPGForm = () => {
    setAddNewPgItem(false);
    dispatch(saveNewPGTitle(''));
  };

  const [add] = useAddProductGapMutation();
  // const [jiraAdd] = useAddJiraProductGapMutation();
  const { data: configCompanyResponse } = useFetchCompanyConfigDataQuery(
    undefined,
    {
      skip: !JSON.parse(localStorage.getItem('user')),
      selectFromResult: ({ data }) => ({ data: data?.data }),
    }
  );

  const triggerMixpanelEvent = () => {
    try {
      mixpanel.track('productgap_created', { event: 'Product gap added' });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
  };

  const validation = (values) => {
    const requiredFields = [
      'module',
      'feature',
      'type',
      'productGapItem',
      'usecase',
      'rating',
    ];

    const errors = {};
    requiredFields.forEach((key) => {
      if (key === 'module' || key === 'feature' || key === 'type') {
        if (!values[key].label) {
          errors[key] = `Select ${key}`;
        }
      } else {
        if (!values[key]) {
          if (key === 'productGapItem') {
            errors[key] = 'Enter product gap';
          } else if (key === 'usecase') {
            errors[key] = 'Enter Use-case';
          } else {
            errors[key] = `Select ${key}`;
          }
        }
      }
    });
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      type: {
        label: selectedProductGap?.id ? selectedProductGap?.productGapType : '',
      },
      feature: selectedProductGap?.id
        ? {
            value: selectedProductGap.feature?.value,
            label: selectedProductGap.feature?.label,
          }
        : { label: '' },
      module: selectedProductGap?.id
        ? {
            value: selectedProductGap.module?.value,
            label: selectedProductGap.module?.label,
          }
        : { label: '' },
      productGapItem: selectedProductGap?.title || '',
      usecase: '',
      rating: '',
      competitors: [],
      impactsWhom: [],

      jiraTitle: selectedProductGap?.jiraTitle ?? '',
      jiraIssueId: selectedProductGap?.jiraIssueId ?? '',
      jiraIssueKey: selectedProductGap?.jiraIssueKey ?? '',
      jiraType: selectedProductGap?.jiraType ?? '',
    },
    enableReinitialize: true,
    validate: validation,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      const userDetails = JSON.parse(localStorage.getItem('user'));
      const userId = userDetails?.userId;

      let data = {
        accountId: account?.accountId,
        dealId: account?.dealId,
        dealName: account?.name,
        dealValue: account?.dealValue,
        rating: values.rating,
        useCase: values.usecase,
        productGapType: values.type.label,
        title: values.productGapItem?.trim(),
        feature: values.feature,
        module: values.module,
        productGapItem: {
          value: values.productGapItem?.trim(),
          label: values.productGapItem?.trim(),
        },
        competitors: values.competitors,
        impactsWhom: values.impactsWhom,
        createdBy: userId,
      };
      if (integrationType === 'jira') {
        data = {
          ...data,
          jiraTitle: values.jiraTitle,
          jiraIssueId: values.jiraIssueId,
          jiraIssueKey: values.jiraIssueKey,
          jiraType: values.jiraType,
        };
      } else {
        const sanitizedUsecase = DOMPurify.sanitize(values.usecase);
        data = {
          ...data,
          useCase: sanitizedUsecase,
          plainText: convertHTMLtoPlainText(sanitizedUsecase),
        };
      }
      add(data)
        .unwrap()
        .then((response) => {
          // ! May need to be removed/changed to reflect 3 step PG
          // To concat data to existing pg cards according to the format
          const pgItemData = [
            {
              feature: data.feature,
              module: data.module,
              productGap: data.productGap,
              title: data.title,
              jiraIssueKey: data.jiraIssueKey,
              productGapType: data.productGapType,
            },
          ];
          data['pgItem'] = pgItemData;
          data['id'] = response?.data?.id;
          triggerMixpanelEvent();
          dispatch(saveRefetchPgUsecase(true));
          onSuccess(data, response.status);
          reset();
        });
    },
  });

  useEffect(() => {
    if (!formik.values.type.label) {
      setIntegrationType(null);
      return;
    }
    formik.values.type.label === 'New Request'
      ? setIntegrationType(configCompanyResponse.newRequestIntegration)
      : setIntegrationType(configCompanyResponse.enhancementBugIntegration);
  }, [
    formik.values.type?.label,
    configCompanyResponse?.newRequestIntegration,
    configCompanyResponse?.enhancementBugIntegration,
  ]);

  return (
    <ReactModal
      isOpen={open}
      className="product-gap-form"
      overlayClassName="Overlay confirm-box-overlay"
      ariaHideApp={false}
    >
      <section className="pg-root">
        <section className="pg-left bg-light p-26">
          <ProductGapSearch
            filters={addNewPgItem ? formik.values : null}
            handleAdd={() => {
              setAddNewPgItem(true);
            }}
            onListClick={() => setAddNewPgItem(false)}
            integrationType={integrationType}
            dealId={account?.dealId}
            formik={formik}
          />
        </section>
        <section className="pg-right p-26">
          {/* HEADER */}
          <div className="header-container">
            <h3 className="title">Add Product Gap Use-case</h3>
            <button
              className="btn-pg-close"
              type="button"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </button>
          </div>
          {/* PG DISPLAY */}
          {!addNewPgItem && <AddUpdatePgDisplay formikValues={formik.values} />}
          {/* FORM */}
          <form onSubmit={formik.handleSubmit}>
            {!addNewPgItem ? (
              <MainForm
                formik={formik}
                handleClose={handleClose}
                integrationType={integrationType}
                dealValue={account?.dealValue}
              />
            ) : (
              <NewProductGapForm
                integrationType={integrationType}
                formik={formik}
                onClose={closeNewPGForm}
                onNext={handleNext}
                productGaps={productGaps}
              />
            )}
          </form>
        </section>
      </section>
    </ReactModal>
  );
}

AddPg.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
};

export default AddPg;
