import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import Button from '../../../common/Button';
import { TASK_OPTIONS } from '../../../Constant/FilterOperators';
import { useFetchTasksForAccountQuery } from '../../../common/tasks/tasksSlice';
import TaskList from './TaskList';
import { ReactComponent as AddSVG } from '../../../Icons/NewImage/add-green.svg';
import styles from '../../../styles/features/presentationListContainer.module.scss';
import AddTaskNoteDescriptionDrawer from '../../../common/AddDrawerContent/AddTaskNoteDescriptionDrawer';

const taskDDList = [
  {
    value: 'ALL',
    label: 'All',
  },
  ...TASK_OPTIONS,
];

const DealTasks = ({ dealId, accountId, readOnly, dealDetails }) => {
  const [status, setStatus] = useState('ALL');
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const { data: tasks, isLoading } = useFetchTasksForAccountQuery(
    {
      status,
      dealId,
    },
    {
      skip: !dealId,
      selectFromResult: ({ data, isLoading }) => ({
        data: data ? Object.values(data?.entities) : [],
        isLoading,
      }),
    }
  );

  const handleAddTaskOpen = () => setShowAddTaskModal(true);
  const handleAddTaskCancel = () => setShowAddTaskModal(false);

  const onFilterSelect = (option) => setStatus(option.value);

  return (
    <section className={styles.presentation_list__container}>
      <section className={styles.tasks_header}>
        <h3 className={styles.tasks_title}>Tasks</h3>
        <section className="flex">
          <div className={styles.task_filter}>
            <span> Show: </span>
            <Dropdown
              options={taskDDList}
              onChange={onFilterSelect}
              value={taskDDList[0]}
              className={styles.dropdown}
            />
          </div>
          {!readOnly && (
            <Button icon onClick={handleAddTaskOpen} type="text">
              <AddSVG />
              Add Task
            </Button>
          )}
        </section>
      </section>
      <TaskList
        data={tasks}
        isReadOnly={readOnly}
        isLoading={isLoading}
        dealDetails={dealDetails}
      />
      {showAddTaskModal && (
        <AddTaskNoteDescriptionDrawer
          showDrawer={showAddTaskModal}
          closeDrawer={handleAddTaskCancel}
          tab="Task"
          action="add"
          key="presentation-add-task"
          dealDetails={dealDetails}
        />
      )}
    </section>
  );
};

export default DealTasks;
