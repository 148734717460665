import React, { useMemo, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import { useFetchParentStagesQuery } from '../pipelineSlice';
import { getLaneId } from '../../Utils/common';

const usePipelineSearchProps = (
  handleSearch,
  searchResponse,
  openDealPresentationView
) => {
  const { data: stages, isLoading: stagesLoading } = useFetchParentStagesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const options = useMemo(() => {
          const options = data?.ids?.map((stage) => ({
            label: data?.entities[stage]?.name,
            value: stage,
          }));
          return { ...data, options };
        }, [data]);

        return { data: options };
      },
    }
  );
  const inputRef = useRef(null);
  const navigateToDealsPage = (dealItem) => {
    const laneId = getLaneId(stages, dealItem?.currentPreskaleStageName);
    try {
      mixpanel.track('pipeline_searchbar_accessed', {
        event: 'Pipeline global search access',
      });
    } catch {
      console.error('Cannot add event to mixpanel');
    }
    const dealDetails = {
      dealId: dealItem.dealId,
      name: dealItem.dealName,
      presalesUser: dealItem.presalesUser,
      presalesUserId: dealItem.presalesUserId,
      preskaleCreated: dealItem.preskaleCreated,
      currentPreskaleStageName: dealItem.currentPreskaleStageName,
      dealValue: dealItem?.dealValue,
      laneId: laneId && laneId[0],
      id: dealItem.cardId,
    };
    openDealPresentationView(dealDetails);
  };

  const searchProps = {
    ref: inputRef,
    placeholder: 'Search Deals(Atleast three characters required)',
    onChange: handleSearch,
    options: searchResponse,
    className: 'width-300px',
    renderOption: (item) => (
      <>
        <div className="heading">{item['dealName']}</div>
        <div className="flex col-gap-20">
          <div className="sub-text" title={item['presalesUser']}>
            {' '}
            {item['presalesUser']}
          </div>
          <div className="sub-text" title={item?.accountInfo[0]?.name}>
            {item?.accountInfo[0]?.name}
          </div>
        </div>
      </>
    ),
    onSelect: (item) => navigateToDealsPage(item),
  };

  return searchProps;
};

export default usePipelineSearchProps;
