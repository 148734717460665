import React from 'react';
import ReactModal from 'react-modal';
import JiraPane from '../../Accounts/Jira/JiraPane';
import { ReactComponent as AddOutlineIcon } from '../../Icons/AddOutline.svg';
import { ReactComponent as CloseIcon } from '../../Icons/accounts-close.svg';

const AssociateToJiraButton = ({
  btnText = 'Associate Product Gap Item with JIRA',
  btnVariant = 'outlined',
  icon = true,
  isJiraReporterDisable,
  onOpen,
  onClose,
  onSelect,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  return (
    <>
      <button
        className={`btn_jira_associate ${btnVariant}`}
        onClick={handleOpen}
        type="button"
        disabled={disabled}
      >
        {icon && (
          <span className="icon_container">
            <AddOutlineIcon />
          </span>
        )}
        {btnText}
      </button>
      <ReactModal
        isOpen={open}
        className="jira_pane_standalone"
        overlayClassName="Overlay confirm-box-overlay"
        ariaHideApp={false}
      >
        <button
          aria-label="close"
          onClick={handleClose}
          className="btn_close"
          type="button"
        >
          <CloseIcon />
        </button>
        <div className="jira_pane__Modal">
          <JiraPane
            isCreateForm={true}
            bubbleUpSelectedIssue={handleClose}
            standalone
            onJiraSelect={onSelect}
            isJiraReporterDisable={isJiraReporterDisable}
          />
        </div>
      </ReactModal>
    </>
  );
};

export default AssociateToJiraButton;
