import React from 'react';
import { Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Dashboard from '../Dashboard/index';
import AccountsPipeline from '../Pipeline/index';
import Profile from '../Profile';
import ConfigureCompany from '../Company/ConfigureCompany';
import AddUser from '../Company/AddUser';
import AddStages from '../Company/AddStages';
import AddContributions from '../Company/AddContributions';
import Teams from '../Settings/Teams/Teams';
import TeamsDescription from '../Settings/Teams/TeamsDescription';
import ManagerDashboard from '../LightningDashboard';
import { FeatureRouteAccess } from '../common/routes/FeatureRouteAccess';
import RequestFormPage from '../features/RequestForm/index';
import ProductGapManager from '../ProductGapManager';
import RequireAuth from '../common/RequireAuth';
import ReportingManager from '../Reports';
import { useFetchCompanyConfigDataQuery } from '../Company/companySlice';
import { AdminOps } from '../AdminOps/AdminOps';
import DetailView from '../AdminOps/DetailView';
import Settings from '../AdminSettings';
// import Fallback from '../Utils/FallBack';
import {
  isAdminOrManager,
  isAdminUser,
  isProductUser,
  isSalesUser,
} from '../Utils/common';
import TeamDetailView from '../AdminSettings/TeamsManagement/TeamDetailView';

const RouteSwitch = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { data: configFeatures } = useFetchCompanyConfigDataQuery(undefined, {
    skip: !user,
  });

  return (
    <Switch>
      <FeatureRouteAccess
        path="/pipeline"
        headerTitle="Account"
        component={
          <div>
            <Helmet>
              <title>Accounts</title>
            </Helmet>
            <AccountsPipeline />
          </div>
        }
        featureAccess={configFeatures?.data?.navPages?.pipeline}
        landingPage={configFeatures?.data?.landingPage}
      />
      <FeatureRouteAccess
        path="/adminOps"
        headerTitle="AdminOps"
        component={
          <div>
            <Helmet>
              <title>Admin Ops</title>
            </Helmet>
            <AdminOps />
          </div>
        }
        featureAccess={user?.roles?.includes('super_admin')}
        landingPage={configFeatures?.data?.landingPage}
      />

      <FeatureRouteAccess
        path="/reports"
        headerTitle="Reports"
        component={
          <div>
            <Helmet>
              <title>Reports</title>
            </Helmet>
            <ReportingManager />
          </div>
        }
        featureAccess={
          configFeatures?.data?.navPages?.statistics === true
            ? !isSalesUser()
            : configFeatures?.data?.navPages?.statistics
        }
        landingPage={configFeatures?.data?.landingPage}
      />

      <FeatureRouteAccess
        path="/settings"
        headerTitle="Settings"
        component={
          <div>
            <Helmet>
              <title>Settings</title>
            </Helmet>
            <Settings />
          </div>
        }
        featureAccess={isAdminUser()}
        landingPage={configFeatures?.data?.landingPage}
      />

      {/* <RequireAuth exact path="/configurecompany">
        <ConfigureCompany />
      </RequireAuth> */}

      <RequireAuth exact path="/teams">
        <Teams />
      </RequireAuth>

      <RequireAuth
        exact
        path="/teamdescription/:teamId"
        headerTitle="Team Description"
      >
        <TeamsDescription />
      </RequireAuth>

      <RequireAuth exact path="/addusers">
        <AddUser />
      </RequireAuth>

      <RequireAuth exact path="/addstages">
        <AddStages />
      </RequireAuth>

      <RequireAuth exact path="/addcontributions">
        <AddContributions />
      </RequireAuth>

      <RequireAuth exact path="/user/:tab" headerTitle="Profile">
        <Helmet>
          <title>Profile</title>
        </Helmet>

        <Profile />
      </RequireAuth>
      <RequireAuth exact path="/detail/:companyId">
        <DetailView />
      </RequireAuth>

      <RequireAuth exact path="/teamdetail">
        <TeamDetailView />
      </RequireAuth>

      <FeatureRouteAccess
        path="/manager"
        headerTitle="LightningDashboard"
        component={
          <div>
            <Helmet>
              <title>ManagerDashboard</title>
            </Helmet>
            <ManagerDashboard />
          </div>
        }
        featureAccess={
          configFeatures?.data?.navPages?.manager_dashboard === true
            ? isAdminOrManager()
            : configFeatures?.data?.navPages?.manager_dashboard
        }
        landingPage={configFeatures?.data?.landingPage}
      />

      <FeatureRouteAccess
        path={['/dashboard', '/dealDetails']}
        headerTitle="Dashboard"
        component={
          <div>
            <Helmet>
              <title>Dashboard</title>
            </Helmet>
            <Dashboard />
          </div>
        }
        landingPage={configFeatures?.data?.landingPage}
        featureAccess={
          configFeatures?.data?.navPages?.dashboard === true
            ? !isSalesUser() && !isProductUser()
            : configFeatures?.data?.navPages?.dashboard
        }
      />
      <FeatureRouteAccess
        path="/libraries"
        headerTitle="ProductGapUseCases"
        featureAccess={
          configFeatures?.data?.navPages?.product_gap_use_case === true
            ? !isSalesUser()
            : configFeatures?.data?.navPages?.product_gap_use_case
        }
        landingPage={configFeatures?.data?.landingPage}
        component={
          <div>
            <Helmet>
              <title>Libraries</title>
            </Helmet>
            <ProductGapManager />
          </div>
        }
      />
      <FeatureRouteAccess
        path="/requestForm"
        headerTitle="Resource Request Form"
        featureAccess={
          configFeatures?.data?.navPages?.request_form === true
            ? isSalesUser() && !isProductUser()
            : configFeatures?.data?.navPages?.request_form
        }
        landingPage={configFeatures?.data?.landingPage}
        component={
          <div>
            <Helmet>
              <title>Resource Request Form</title>
            </Helmet>
            <RequestFormPage />
          </div>
        }
      />
      {/* TODO: If config features is not loading and the values are undefined, we need to show a loader instead of showing a flash of the UI(for users who do not have access) */}
      {/* TODO: Only match this when no other routes match and show a preskale loading indicator */}
      {/* <Route path="/">
        <Fallback landingPage={configFeatures?.data?.landingPage} />
      </Route> */}
      {/* ! Uncomment the route below when the above route is taken care of.*/}
      {/*  Only match this when no other routes match */}
      {/* <Route path="*">
        <Fallback landingPage={configFeatures?.data?.landingPage} />
      </Route> */}
    </Switch>
  );
};

export default RouteSwitch;
