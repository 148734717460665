import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const dayjsWithRelativeTime = dayjs.extend(relativeTime);

function DateFormat(date, format) {
  return dayjs(date).format(format);
}

function DateRelativeTime(date) {
  return dayjsWithRelativeTime(date).fromNow();
}

export { DateFormat, DateRelativeTime };
